import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { MenuItem, Select, InputLabel, Box, FormControl, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { Formik } from "formik";
import {
  ABHA_MOBILE_NUMBER,
  CREATE_ABHA_VIA_AADHAAR,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  UPDATE_MOBILE_NUMBER,
  VERIFY_AADHAAR_OTP,
  currentActiveUser,
  VERIFICATION_VIA_MOBILE,
  VERIFICATION_VIA_ADHAAR,
  VERIFICATION_VIA_ABHA,
  VERIFICATION_VIA_ABHA_USING_ADHAAR,
  VERIFICATION_VIA_ABHA_USING_MOBILE,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_ADHAAR,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
  VERIFICATION_ABHA_USING_AADHAAR_OTP,
  VERIFICATION_ABHA_USING_MOBILE_OTP,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR,
  VERIFY_SELECTED_ABHA_NUMBER,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  AbcOutlined,
  NumbersOutlined,
  FingerprintOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import { useLocation } from "react-router";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  generateAbdmAadhaarOtp,
  resendAbdmAadhaarOtp,
  updateAbdmDetail,
  verifyAbdmAadhaarOtp,
  verifyGetAbhaOtpViaMobile,
  verifyGetAbhaOtpViaAbhaEntities,
  getLinkedAbha,
} from "services/AbhaService";
import { getPatientByUserId } from "services/patientService";
import Reveal from "views/utilities/Reveal";
import { createAbhaValidation } from "views/Components/Common/ValidationSchema/createAbhaValidation";
import AbhaDetails from "./AbhaDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";

const ManageAbhaNumber = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const { state } = useLocation();
  const [patientId, setpatientId] = useState(state?.patientId || null);
  const patientIdRef = useRef(state?.patientId ? state.patientId : null);

  const [checkedStates, setCheckedStates] = useState([false, false, false, false, false]);
  const [isSelectedIndex, setIsSelectedIndex] = useState(0);
  const [patientAbhaDetails, setPatientAbhaDetails] = useState({});
  const [isCreateAbha, setIsCreateAbha] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");
  const [txnId, setTxnId] = useState("");
  const [timer, setTimer] = useState(60);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [otpSentMessage, setOtpSentMessage] = useState(null);
  const [getAbhaScope, setGetAbhaScope] = useState(VERIFICATION_VIA_MOBILE);
  const [getAbhaScope2, setGetAbhaScope2] = useState("");
  const [getAbhaEntityValue, setGetAbhaEntityValue] = useState("");
  const [getAbhaText, setGetAbhaText] = useState("Enter Mobile Number");
  const formikRef = useRef(null);
  const [accountOptions, setAccountOptions] = useState([]);
  const [viewLinkedAbha, setViewLinkedAbha] = useState(false);
  const linkedAbhaNumberRef = useRef(null);
  const [abhaImage, setAbhaImage] = useState(null);
  const [token, setToken] = useState(null);
  const [backBtnStack, setBackBtnStack] = useState([0]);

  const handleCheckboxChange = (index) => (e) => {
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = e.target.checked;
      return newStates;
    });
  };

  const handleFetchData = async () => {
    const payload = state?.patientUserId ? state?.patientUserId : currentActiveUser().id;
    try {
      const response = await getPatientByUserId(payload);
      setpatientId(response.data.user.roleBasedId);
      patientIdRef.current = response.data.user.roleBasedId;
      linkedAbhaNumberRef.current = response.data.abhaNumber;

      ViewLinkedAbha();
    } catch (error) {
      handleClick("error", error.response.data.message);
    }
  };

  const handleAbhaCreationRouting = () => {
    setIsSelectedIndex(0);
    setViewLinkedAbha(false);
    setIsCreateAbha(true);
    setBackBtnStack([0]);
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (state?.abhaCreationPending) {
      handleAbhaCreationRouting();
    }
  }, [state?.abhaCreationPending]);

  const handleRadioBtnChange = () => {
    setIsCreateAbha(!isCreateAbha);
    setIsSelectedIndex(0);
    setBackBtnStack([0]);
  };

  useEffect(() => {
    if (isSelectedIndex === 2) {
      setTimer(60);
      setResendEnabled(false);
    }
  }, [isSelectedIndex]);

  useEffect(() => {
    let interval;
    if (!resendEnabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
    }

    return () => clearInterval(interval);
  }, [timer, resendEnabled]);

  const handleBackButton = (value) => {
    setIsSelectedIndex(value);
    if (!isCreateAbha || value > 2) {
      setBackBtnStack([0]);
      return;
    } else if (backBtnStack.length > 0) {
      const lastIndexValue = backBtnStack[backBtnStack.length - 1];
      if (lastIndexValue === value) {
        return;
      }
      setBackBtnStack((prevStack) => [...prevStack, value]);
    }
  };

  const handleSubmitForGenerateAadharOtp = async (aadharNumber) => {
    const trimmedAadharNumber = aadharNumber.replace(/\s+/g, "");
    if (!trimmedAadharNumber) {
      handleClick("error", "Please enter a valid Aadhar Number.");
      return;
    }
    const data = { loginId: trimmedAadharNumber, scope: CREATE_ABHA_VIA_AADHAAR, txnId };
    try {
      const res = await generateAbdmAadhaarOtp(data);
      if (res.status === 200 && res.data) {
        setTxnId(res.data.txnId);
        setOtpSentMessage(res?.data?.message);
        handleClick("success", "Otp has been successfully sent.");
        handleBackButton(2);
      } else {
        handleClick("error", "There seems to be an error generating the Aadhar-OTP.");
      }
    } catch (error) {
      // as per the abdm excel sheet this message is mandatory so adding this one
      if (error?.response?.data === "Invalid LoginId\n") {
        handleClick("error", "Aadhaar Number is not valid");
      } else {
        handleClick("error", error?.response?.data);
      }
    }
  };

  const handleSubmitForVerifyAadharOtp = async (otp, number) => {
    // Validate mobile number format
    if (!/^[6-9][0-9]{9}$/.test(number)) {
      handleClick("error", "Please enter a valid mobile number");
      return;
    }

    // Ensure OTP is provided
    if (!otp) {
      handleClick("error", "Please enter valid otp.");
      return;
    }

    const data = {
      scope: VERIFY_AADHAAR_OTP,
      aadhaar_otp: {
        txnId,
        otpValue: otp,
        mobile: number,
        includeAbhaAddressSuggestions: true,
        includeProfileQr: true,
      },
    };

    try {
      // Verify Aadhaar OTP
      const res = await verifyAbdmAadhaarOtp(patientId, data);
      if (!res.data.isNew) {
        handleClick("info", "Abha is already created for this Aadhaar Number.");
        await handleFetchData();
        setIsCreateAbha(false);
        setIsSelectedIndex(0);
        setBackBtnStack([0]);
      } else {
        setPatientAbhaDetails(res?.data);
        if (res?.data?.profile?.mobile === null) {
          const updateData = {
            loginId: number,
            scope: UPDATE_MOBILE_NUMBER,
            txnId,
          };
          await generateAbdmAadhaarOtp(updateData);
          handleBackButton(3);
        } else {
          handleClick("success", "User has been successfully linked.");
          handleBackButton(4);
        }
      }
    } catch (e) {
      handleClick("error", e?.response?.data);
      if (e?.response?.data === "User is already linked.") {
        await handleFetchData();
      }
    }
  };

  const handleResendOtp = async () => {
    if (resendCount < 1) {
      const data = { txnId: txnId };
      try {
        const res = await resendAbdmAadhaarOtp(data);
        if (res.data) {
          setTxnId(res.data.txnId);
          handleClick("success", "Otp has been successfully sent.");
        }
        setResendEnabled(false);
        setTimer(60);
        setResendCount((prev) => prev + 1);
      } catch (e) {
        handleClick("error", e.response?.data?.message);
      }
    } else {
      setResendEnabled(false);
      setTimer(-1);
      handleClick("error", "You have tried maximum times for receiving the OTP!");
    }
  };

  const handleSubmitForVerifyMobileOtp = async (mobileotp) => {
    if (!mobileotp) {
      return;
    }

    const data = { txnId: txnId, scope: ABHA_MOBILE_NUMBER, updateDetail: mobileotp };
    try {
      const res = await updateAbdmDetail(data);
      if (res?.data?.mobile_number?.authResult === "success") {
        const updatedAbhaMobile = mobileNumber;
        const updatedPatientAbhaDetails = {
          ...patientAbhaDetails,
          profile: {
            ...patientAbhaDetails.profile,
            mobile: updatedAbhaMobile,
          },
        };
        setTxnId(res.data.txnId);
        setPatientAbhaDetails(updatedPatientAbhaDetails);
        handleClick("success", "Abha Created and Mobile number has been successfully linked.");
        handleBackButton(4);
      }
    } catch (e) {
      handleClick("error", "There seems to be an error in Mobile OTP Verification.");
    }
  };

  const handleGetAbhaRadioBtnChange = (event, handleChange) => {
    const selectedValue = event.target.value;
    handleChange(event);
    setGetAbhaScope(selectedValue);
    switch (selectedValue) {
      case VERIFICATION_VIA_MOBILE:
        setGetAbhaText("Enter Mobile Number");
        break;
      case VERIFICATION_VIA_ADHAAR:
        setGetAbhaText("Enter Adhaar Number");
        break;
      case VERIFICATION_VIA_ABHA:
        setGetAbhaText("Enter Abha Number/Address");
        break;
      default:
        setGetAbhaText("Something Went Wrong, Please Relogin!");
        break;
    }
  };

  const validateGetAbhaEntityText = async (getAbhaEntityText, getAbhaRadioBtn) => {
    const fieldName = "getAbhaEntityText";
    try {
      const values = { [fieldName]: getAbhaEntityText, getAbhaRadioBtn };
      await createAbhaValidation.validateAt(fieldName, values);
      return true;
    } catch (error) {
      return false;
    }
  };

  const formatNumber = (num) => {
    const numStr = num.toString();
    return `${numStr.slice(0, 2)}-${numStr.slice(2, 6)}-${numStr.slice(6, 10)}-${numStr.slice(
      10,
      14
    )}`;
  };

  const handleSearchThroughAbha = async (value) => {
    const abhaNumberPattern = /^\d{14}$/;
    const abhaAddressPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{2,}$/;

    if (formikRef.current) {
      const currentErrors = { ...formikRef.current.errors };

      if (abhaNumberPattern.test(value)) {
        const formattedNumber = formatNumber(value);
        setGetAbhaEntityValue(formattedNumber);
        setGetAbhaScope("abhaNumber");
        handleBackButton(1);
      } else if (abhaAddressPattern.test(value)) {
        setGetAbhaScope("abhaAddress");
        handleBackButton(1);
      } else {
        currentErrors.getAbhaEntityText = "Invalid ABHA Number or Address format";
      }
      formikRef.current.setErrors(currentErrors);
    }
  };

  const handleSearchAbha = async (value, radioBtn) => {
    setGetAbhaEntityValue(value);
    try {
      if (radioBtn === VERIFICATION_VIA_ABHA) {
        handleSearchThroughAbha(value);
      } else {
        const validation = await validateGetAbhaEntityText(value, radioBtn);
        if (validation) {
          const data = { scope: getAbhaScope, loginId: value, txnId: "" };

          try {
            const response = await generateAbdmAadhaarOtp(data);
            setTxnId(response?.data?.txnId);
            handleBackButton(2);
          } catch (error) {
            handleClick("error", error.response?.data);
          }
        }
      }
    } catch (e) {
      handleClick("error", "Unknown Error. Please try again later.");
    }
  };

  const handleAuthWithAbha = async () => {
    const data = { scope: getAbhaScope2, loginId: getAbhaEntityValue };
    try {
      const res = await generateAbdmAadhaarOtp(data);
      if (res) {
        setTxnId(res.data.txnId);
        handleBackButton(2);
      }
    } catch (e) {
      handleClick("error", e.response?.data);
    }
  };

  const handleRadioBtnChangeForAuthMethod = (event, setFieldValue) => {
    const selectedValue = event.target.value;
    setFieldValue("authMethodBtn", event.target.value);

    if (getAbhaScope === "abhaNumber") {
      setGetAbhaScope2(
        selectedValue === "mobileOtp"
          ? VERIFICATION_VIA_ABHA_USING_MOBILE
          : VERIFICATION_VIA_ABHA_USING_ADHAAR
      );
    } else {
      setGetAbhaScope2(
        selectedValue === "mobileOtp"
          ? VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE
          : VERIFICATION_VIA_ABHA_ADDRESS_USING_ADHAAR
      );
    }
  };

  const handleVerifyOtp = async (otp) => {
    if (!otp) {
      return;
    }

    try {
      if (getAbhaScope === VERIFICATION_VIA_MOBILE) {
        const data = { otp: otp, txnId: txnId };
        const response = await verifyGetAbhaOtpViaMobile(data, patientId);
        setTxnId(response.data.txnId);
        setToken(response.data.token);
        setAccountOptions(response.data.accounts);
        handleBackButton(3);
      } else {
        let scope;
        if (getAbhaScope2 !== "") {
          switch (getAbhaScope2) {
            case VERIFICATION_VIA_ABHA_USING_ADHAAR:
              scope = VERIFICATION_ABHA_USING_AADHAAR_OTP;
              break;
            case VERIFICATION_VIA_ABHA_USING_MOBILE:
              scope = VERIFICATION_ABHA_USING_MOBILE_OTP;
              break;
            case VERIFICATION_VIA_ABHA_ADDRESS_USING_ADHAAR:
              scope = VERIFICATION_VIA_ABHA_ADDRESS_USING_AADHAAR;
              break;
            case VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE:
              scope = VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE;
              break;
          }
        } else {
          scope = VERIFICATION_VIA_ADHAAR;
        }
        const data = {
          verificationId: otp,
          txnId: txnId,
          scope: scope,
          token: "",
          fingerPrintAuthPid: "",
          includeProfileQr: true,
        };
        const response = await verifyGetAbhaOtpViaAbhaEntities(data, patientId);
        if (response.status === 200) {
          handleClick("success", "Abha has been successfully linked!");
          await handleFetchData();
          handleBackButton(1);
          setViewLinkedAbha(true);
        }
      }
    } catch (e) {
      handleClick("error", e.response?.data);
      if (e.response?.data === "User is already linked.") {
        await handleFetchData();
        handleBackButton(1);
        setViewLinkedAbha(true);
      }
    }
  };

  const handleSelectedAccount = async (value) => {
    try {
      const data = {
        verificationId: value,
        txnId: txnId,
        scope: VERIFY_SELECTED_ABHA_NUMBER,
        token: token,
        fingerPrintAuthPid: "",
        includeProfileQr: true,
      };
      const response = await verifyGetAbhaOtpViaAbhaEntities(data, patientId);
      if (response.status === 200) {
        handleClick("success", "Abha has been successfully linked!");
        await handleFetchData();
        handleBackButton(1);
        setViewLinkedAbha(true);
      }
    } catch (e) {
      handleClick("success", e.response?.data);
      if (e.response?.data === "User is already linked.") {
        await handleFetchData();
        handleBackButton(1);
        setViewLinkedAbha(true);
      }
    }
  };

  const ViewLinkedAbha = async () => {
    try {
      setCheckedStates([false, false, false, false, false]);
      setViewLinkedAbha(true);
      if (linkedAbhaNumberRef.current === null) {
        setIsSelectedIndex(0);
        setBackBtnStack([0]);
        return;
      } else {
        const res = await getLinkedAbha(patientIdRef.current);
        if (res) {
          const blob = new Blob([res.data], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          if (res) {
            setAbhaImage(url);
          }
        }
        handleBackButton(1);
      }
    } catch (e) {
      console.error(e);
      handleClick("error", "There seems to be an error! Please try again later.");
    }
  };

  const DownloadAbhaImage = (dataLink) => {
    if (!dataLink) {
      handleClick("error", "There seems to be an error in Downloading! Please try again later.");
      return;
    }
    try {
      const downloadLink = document.createElement("a");
      downloadLink.href = dataLink;
      downloadLink.download = "abha_card.png";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      console.error(e);
      handleClick("error", "Downloading error! Please try again later.");
    }
  };

  const popBackBtnStack = () => {
    try {
      let poppedValue = 0;

      setBackBtnStack((prevStack) => {
        if (prevStack.length === 1) {
          setCheckedStates([false, false, false, false, false]);
          formikRef.current.resetForm();
          poppedValue = 0;
          return [0];
        }
        poppedValue = prevStack[prevStack.length - 2];
        return prevStack.slice(0, -1);
      });

      return poppedValue;
    } catch (e) {
      console.error(e);
      return 0;
    }
  };

  return (
    <PatientAbhaSkeleton>
      <>
        {!viewLinkedAbha || state?.abhaCreationPending ? (
          <>
            <Reveal>
              <div className="section-heading">
                {isCreateAbha ? (
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#007390",
                      display: "inline-block",
                      margin: "10px",
                    }}
                  >
                    Abha Creation
                  </h2>
                ) : (
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "#007390",
                      display: "inline-block",
                      margin: "10px",
                    }}
                  >
                    Link Abha
                  </h2>
                )}
                <div style={{ marginLeft: "auto", gap: "10px", display: "flex" }}>
                  {!viewLinkedAbha && isSelectedIndex !== 0 && (
                    <CustomButton
                      disabled={isSelectedIndex === 0}
                      sx={{ margin: "15px" }}
                      className="btn--primary"
                      onClick={() => {
                        const num = popBackBtnStack();
                        setIsSelectedIndex(num);
                      }}
                    >
                      <ArrowBackIcon />
                    </CustomButton>
                  )}
                  {!isCreateAbha && isSelectedIndex === 0 && (
                    <CustomButton
                      sx={{ margin: "15px", marginLeft: "-5px" }}
                      className="btn--primary"
                      onClick={() => {
                        setIsCreateAbha(true);
                        setCheckedStates([false, false, false, false, false]);
                      }}
                    >
                      <ArrowBackIcon />
                    </CustomButton>
                  )}
                </div>
              </div>
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                  aadharNumber: "",
                  otp: "",
                  mobileNumber: "",
                  mobileotp: "",
                  firstName: "",
                  middleName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  healthId: "",
                  healthIdForSearch: "",
                  mobileNumberForSearch: "",
                  otpForVerification: "",
                  submit: "",
                  getAbhaEntityText: "",
                  getAbhaRadioBtn: getAbhaScope,
                  getAbhaOtp: "",
                  authMethodBtn: "",
                  selectedOption: "",
                }}
                validationSchema={createAbhaValidation}
                onSubmit={async (values) => {}}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...others}>
                    {isCreateAbha && isSelectedIndex === 0 && (
                      <FormControl sx={{ width: "30%", mr: 3 }}>
                        <FormLabel id="abhaNumberRadioBtn">Abha Number</FormLabel>
                        <RadioGroup
                          row
                          defaultValue="createAbha"
                          aria-labelledby="abhaNumberRadioBtn"
                          name="abhaNumberRadioBtn"
                          value={values.abhaNumberRadioBtn}
                          onChange={handleRadioBtnChange}
                        >
                          <FormControlLabel
                            name="abhaNumberRadioBtn"
                            value="createAbha"
                            control={<Radio />}
                            label="Create Abha Number"
                          />
                          <FormControlLabel
                            name="abhaNumberRadioBtn"
                            value="linkAbha"
                            control={<Radio />}
                            label="Get Abha"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                    {isCreateAbha && isSelectedIndex === 0 && (
                      <>
                        <p style={{ margin: "10px 0px 20px 10px" }}>I hereby declare that:</p>
                        <FormControlLabel
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            marginBottom: "10px",
                            alignItems: "flex-start",
                          }}
                          label="I am voluntarily sharing my Aadhaar Number / Virtual ID issued by the Unique Identification Authority of India (“UIDAI”), and my demographic information for the purpose of
creating an Ayushman Bharat Health Account number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”). I authorize NHA to use my
Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies,
Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication."
                          control={
                            <Checkbox
                              checked={checkedStates[0]}
                              onChange={handleCheckboxChange(0)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            marginBottom: "10px",
                            alignItems: "flex-start",
                          }}
                          label="I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) government health records and those which will be generated during this encounter."
                          control={
                            <Checkbox
                              checked={checkedStates[1]}
                              onChange={handleCheckboxChange(1)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            marginBottom: "10px",
                            alignItems: "flex-start",
                          }}
                          label="I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter."
                          control={
                            <Checkbox
                              checked={checkedStates[2]}
                              onChange={handleCheckboxChange(2)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            marginBottom: "10px",
                            alignItems: "flex-start",
                          }}
                          label="I intend to create Ayushman Bharat Health Account Number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”) using document other than
Aadhaar."
                          control={
                            <Checkbox
                              checked={checkedStates[3]}
                              onChange={handleCheckboxChange(3)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            marginBottom: "10px",
                            alignItems: "flex-start",
                          }}
                          label="I consent to the anonymization and subsequent use of my government health records for public health purposes."
                          control={
                            <Checkbox
                              checked={checkedStates[4]}
                              onChange={handleCheckboxChange(4)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                        {/* <div style={{ marginTop: "15px" }}>
                            <p style={{ marginTop: "15px" }}>
                              I understand that my ABHA (number) can be used and shared for purposes
                              as may be notified by Ayushman Bharat Digital Mission (ABDM) from time
                              to time including provision of healthcare services. Further, I am aware
                              that my personal identifiable information (Name, Address, Age, Date of
                              Birth, Gender and Photograph) may be made available to the entities
                              working in the National Digital Health Ecosystem (NDHE) which inter alia
                              may include stakeholders and entities such as healthcare professionals
                              (e.g. doctors), facilities (e.g. hospitals, laboratories) and data
                              fiduciaries (e.g. health programmes), which are registered with or
                              linked to the Ayushman Bharat Digital Mission (ABDM), and various
                              processes there under.
                            </p>
                            <p style={{ marginTop: "15px" }}>
                              I authorize NHA to use my Aadhaar number / Virtual ID for performing
                              Aadhaar based authentication with UIDAI as per the provisions of the
                              Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits
                              and Services) Act, 2016 for the aforesaid purpose. I understand that
                              UIDAI will share my e-KYC details, or response of “Yes” with NHA upon
                              successful authentication.
                            </p>
                            <p style={{ marginTop: "15px" }}>
                              I have been duly informed about the option of using other IDs apart from
                              Aadhaar; however, I consciously choose to use Aadhaar number / Virtual
                              ID for the purpose of availing benefits across the NDHE.
                            </p>
                            <p style={{ marginTop: "15px" }}>
                              I am aware that my personal identifiable information excluding Aadhaar
                              number / Virtual ID number can be used and shared for purposes as
                              mentioned above. I reserve the right to revoke the given consent at any
                              point of time as per provisions of Aadhar Act and Regulations.
                            </p>
                          </div> */}
                        <Box sx={{ mt: 4 }}>
                          <CustomButton
                            disabled={!checkedStates.every(Boolean)}
                            className="btn--primary"
                            onClick={() => {
                              handleBackButton(1);
                            }}
                            label="Next"
                          />
                        </Box>
                      </>
                    )}
                    {isCreateAbha && isSelectedIndex === 1 && (
                      <Reveal>
                        <h3>
                          An OTP will be sent to the mobile number linked to this Aadhaar number.
                        </h3>
                        <FormInputField
                          style={{ width: "45%", marginRight: "30px", marginTop: "15px" }}
                          label="Aadhar Number"
                          type={"tel"}
                          name="aadharNumber"
                          value={values.aadharNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          startAdornment={<PermIdentityOutlined />}
                          size={"big"}
                        />
                        <Box sx={{ mt: 4 }}>
                          <CustomButton
                            disabled={!(values.aadharNumber.toString().length === 14)}
                            className="btn--primary"
                            onClick={() => handleSubmitForGenerateAadharOtp(values.aadharNumber)}
                            label="Generate"
                          ></CustomButton>
                        </Box>
                      </Reveal>
                    )}
                    {isCreateAbha && isSelectedIndex === 2 && (
                      <Reveal>
                        <div style={{ display: "flex" }}>
                          <div>
                            <h3>Enter Aadhar Otp</h3>
                            <FormInputField
                              style={{ width: "80%", marginRight: "30px", marginTop: "15px" }}
                              label="Otp"
                              type={"tel"}
                              name="otp"
                              inputProps={{
                                maxLength: 6,
                              }}
                              value={values.otp}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              startAdornment={<FingerprintOutlined />}
                              size={"big"}
                            />
                          </div>
                          <div>
                            <h3>Enter Mobile Number</h3>
                            <FormInputField
                              style={{ width: "80%", marginRight: "30px", marginTop: "15px" }}
                              label="Mobile Number"
                              type={"tel"}
                              name="mobileNumber"
                              inputProps={{
                                maxLength: 10,
                              }}
                              value={values.mobileNumber}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              startAdornment={<i className="ri-phone-line ri-xl" />}
                              size={"big"}
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <span>{otpSentMessage}</span>
                        </div>
                        <Box sx={{ mt: 2 }}>
                          <CustomButton
                            disabled={!resendEnabled}
                            className="btn--primary"
                            label={`Resend Otp${
                              !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                            }`}
                            onClick={() => handleResendOtp()}
                            style={{ marginRight: "20px" }}
                          ></CustomButton>
                          <CustomButton
                            disabled={values?.otp?.length !== 6}
                            className="btn--primary"
                            label="Verify"
                            onClick={() =>
                              handleSubmitForVerifyAadharOtp(values.otp, values.mobileNumber)
                            }
                          ></CustomButton>
                        </Box>
                      </Reveal>
                    )}
                    {isCreateAbha && isSelectedIndex === 3 && (
                      <Reveal>
                        <h3>Mobile Verification</h3>
                        <p>
                          Entered Mobile Number doesn't match with the number linked with Aadhaar.
                          <span style={{ fontWeight: 600 }}> Please Enter OTP to verify</span>
                        </p>

                        <FormInputField
                          style={{ width: "35%", marginRight: "30px", marginTop: "15px" }}
                          label="Otp"
                          type={"tel"}
                          name="mobileotp"
                          inputProps={{
                            maxLength: 6,
                          }}
                          value={values.mobileotp}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setMobileNumber(e.target.value);
                          }}
                          startAdornment={<FingerprintOutlined />}
                          size={"big"}
                        />
                        <Box sx={{ mt: 2 }}>
                          <CustomButton
                            disabled={values?.mobileotp?.length !== 6}
                            className="btn--primary"
                            label="Verify"
                            onClick={() => handleSubmitForVerifyMobileOtp(values.mobileotp)}
                          ></CustomButton>
                        </Box>
                      </Reveal>
                    )}

                    {isCreateAbha && isSelectedIndex === 4 && (
                      <AbhaDetails
                        patientId={patientId}
                        patientAbhaDetails={patientAbhaDetails}
                        setPatientAbhaDetails={setPatientAbhaDetails}
                        txnId={txnId}
                      />
                    )}

                    {!isCreateAbha && isSelectedIndex === 0 && (
                      <Reveal>
                        <h3 style={{ margin: "1%" }}>Search Abha</h3>
                        <FormInputField
                          style={{ width: "50%", marginRight: "30px", marginTop: "15px" }}
                          label={getAbhaText}
                          type={
                            values.getAbhaRadioBtn === VERIFICATION_VIA_ABHA ? "text" : "number"
                          }
                          name="getAbhaEntityText"
                          value={values.getAbhaEntityText}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.getAbhaEntityText && errors.getAbhaEntityText)}
                          errorText={errors.getAbhaEntityText}
                          startAdornment={
                            getAbhaScope === VERIFICATION_VIA_ABHA ? (
                              <AbcOutlined />
                            ) : (
                              <NumbersOutlined />
                            )
                          }
                          size={"big"}
                        />
                        <RadioGroup
                          row
                          defaultValue="mobileNoBtn"
                          aria-labelledby="getAbhaRadioBtn"
                          name="getAbhaRadioBtn"
                          value={values.getAbhaRadioBtn}
                          onChange={(e) => handleGetAbhaRadioBtnChange(e, handleChange)}
                          style={{ display: "flex" }}
                        >
                          <FormControlLabel
                            name="getAbhaRadioBtn"
                            value={VERIFICATION_VIA_MOBILE}
                            control={<Radio />}
                            label="Search via Mobile Number"
                          />
                          <FormControlLabel
                            name="getAbhaRadioBtn"
                            value={VERIFICATION_VIA_ADHAAR}
                            control={<Radio />}
                            label="Search via Adhaar Number"
                          />
                          <FormControlLabel
                            name="getAbhaRadioBtn"
                            value={VERIFICATION_VIA_ABHA}
                            control={<Radio />}
                            label="Search via Abha Number/Address"
                          />
                        </RadioGroup>
                        <Box sx={{ mt: 2 }}>
                          <CustomButton
                            disabled={!(values.getAbhaEntityText.toString().length >= 5)}
                            className="btn--primary"
                            label="Search"
                            onClick={() =>
                              handleSearchAbha(values.getAbhaEntityText, values.getAbhaRadioBtn)
                            }
                          ></CustomButton>
                        </Box>
                      </Reveal>
                    )}
                    {!isCreateAbha && isSelectedIndex === 1 && (
                      <Reveal>
                        <h3>Select Method</h3>
                        <FormControl sx={{ width: "30%", mt: 3, mr: 3 }}>
                          <FormLabel id="authMethodBtn">Select Method</FormLabel>
                          <RadioGroup
                            row
                            defaultValue="mobileOtp"
                            aria-labelledby="authMethodBtn"
                            name="authMethodBtn"
                            value={values.authMethodBtn}
                            onChange={(e) => {
                              handleRadioBtnChangeForAuthMethod(e, setFieldValue);
                            }}
                          >
                            <FormControlLabel
                              name="authMethodBtn"
                              value="mobileOtp"
                              control={<Radio />}
                              label="Mobile Otp"
                            />
                            <FormControlLabel
                              name="authMethodBtn"
                              value="aadharOtp"
                              control={<Radio />}
                              label="Aadhar Otp"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Box sx={{ mt: 2 }}>
                          <CustomButton
                            className="btn--primary"
                            label="Next"
                            onClick={() => handleAuthWithAbha()}
                          ></CustomButton>
                        </Box>
                      </Reveal>
                    )}
                    {!isCreateAbha && isSelectedIndex === 2 && (
                      <Reveal>
                        <h3 style={{ margin: "10px" }}>Otp details</h3>
                        <FormInputField
                          style={{ width: "50%", marginRight: "30px", marginTop: "15px" }}
                          label="OTP"
                          type={"tel"}
                          name="otpForVerification"
                          value={values.otpForVerification}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          startAdornment={<FingerprintOutlined />}
                          size={"big"}
                        />
                        <Box sx={{ mt: 2 }}>
                          <CustomButton
                            disabled={!values.otpForVerification}
                            className="btn--primary"
                            label="Verify"
                            onClick={() => handleVerifyOtp(values.otpForVerification)}
                          ></CustomButton>
                        </Box>
                      </Reveal>
                    )}
                    {!isCreateAbha && isSelectedIndex === 3 && (
                      <Reveal>
                        <h3>Select an Account:</h3>
                        <FormControl fullWidth sx={{ mt: 3 }}>
                          <InputLabel id="dropdown-label">Select Account</InputLabel>
                          <Select
                            labelId="dropdown-label"
                            id="select-option"
                            value={values.selectedOption}
                            onChange={(e) => {
                              const selectedId = e.target.value;
                              setFieldValue("selectedOption", selectedId);
                            }}
                          >
                            {accountOptions.map((account) => (
                              <MenuItem key={account.ABHANumber} value={account.ABHANumber}>
                                {account.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <CustomButton
                          sx={{
                            margin: "10px",
                          }}
                          disabled={!values.selectedOption}
                          className="btn--primary"
                          label="Select"
                          onClick={() => handleSelectedAccount(values.selectedOption)}
                        ></CustomButton>
                      </Reveal>
                    )}
                    {!isCreateAbha && isSelectedIndex === 4 && (
                      <AbhaDetails
                        patientId={patientId}
                        patientAbhaDetails={patientAbhaDetails}
                        setPatientAbhaDetails={setPatientAbhaDetails}
                        txnId={txnId}
                      />
                    )}
                  </form>
                )}
              </Formik>
            </Reveal>
          </>
        ) : (
          <>
            <div className="section-heading">
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#007390",
                  display: "inline-block",
                  margin: "10px",
                }}
              >
                View Abha
              </h2>
              <div style={{ marginLeft: "auto", gap: "10px", display: "flex" }}>
                <CustomButton onClick={() => DownloadAbhaImage(abhaImage)}>
                  <DownloadIcon />
                </CustomButton>
              </div>
            </div>
            <img src={abhaImage} alt="ABHA" style={{ width: "73vw", height: "80vh" }} />
          </>
        )}
      </>
    </PatientAbhaSkeleton>
  );
};

const PatientAbhaSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid size={{ xs: 12 }} display={"felx"}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageAbhaNumber;
