// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointment-container {
  display: flex;
  flex-direction: column;
}
.appointment-container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.appointment-container .section-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.appointment-container .section-heading .heading-text {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.appointment-container .section-heading .buttons {
  display: flex;
}
.appointment-container .section-heading .buttons .button {
  margin-left: 10px;
}
.appointment-container .entries-table {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
}
.appointment-container .entries-table .itemsPerPage-label {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}
.appointment-container .entries-table .custom-select {
  width: 4rem;
  font-size: 2rem;
}
.appointment-container .entries-table .select {
  margin-top: 0.3rem;
  border-radius: 1px;
  margin-left: 0.5rem;
  height: 2rem;
  width: 4rem;
  font-size: 0.75rem;
  background: transparent;
}
.appointment-container .entries-table .select .MuiSelect-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/appointment.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAFJ;AAII;EACE,eAAA;EACA,iBAAA;EACA,SAAA;AAFN;AAKI;EACE,aAAA;AAHN;AAKM;EACE,iBAAA;AAHR;AAQE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AANJ;AAQI;EACE,kBAAA;EACA,iBAAA;AANN;AASI;EACE,WAAA;EACA,eAAA;AAPN;AASI;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;AAPN;AASM;EACE,mBAAA;EACA,sBAAA;AAPR","sourcesContent":[".appointment-container {\n  display: flex;\n  flex-direction: column;\n  // padding: 20px;\n  //border: 1px solid #ccc;\n\n  .header {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  }\n\n  .section-heading {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n\n    .heading-text {\n      font-size: 24px;\n      font-weight: bold;\n      margin: 0;\n    }\n\n    .buttons {\n      display: flex;\n\n      .button {\n        margin-left: 10px;\n      }\n    }\n  }\n\n  .entries-table {\n    display: flex;\n    margin-bottom: 0.5rem;\n    align-items: center;\n\n    .itemsPerPage-label {\n      margin-top: 0.5rem;\n      font-size: 0.9rem;\n    }\n\n    .custom-select {\n      width: 4rem;\n      font-size: 2rem;\n    }\n    .select {\n      margin-top: 0.3rem;\n      border-radius: 1px;\n      margin-left: 0.5rem;\n      height: 2rem;\n      width: 4rem;\n      font-size: 0.75rem;\n      background: transparent;\n\n      .MuiSelect-select {\n        padding-top: 0.5rem;\n        padding-bottom: 0.5rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
