import React, { useMemo, useState, useEffect } from "react";
import { api } from "services/AxiosInterceptor";
import { Box, Grid, Input, Skeleton } from "@mui/material";
import { useNavigate } from "react-router";
import { ACTIVE_STATE, SKELETON_LOADING_TIME_IN_MILLISECONDS, accessToken } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { deleteUser, getUserByMobile } from "services/userService";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "name", label: "Name" },
  { field: "roleName", label: "Role" },
  { field: "mobileNumber", label: "Mobile No" },
  { field: "dateOfBirth", label: "Date Of Birth" },
  { field: "status", label: "Status" },
  { field: "Actions", label: "Actions" },
];

const User = () => {
  const navigate = useNavigate();
  // toast related constants
  const { handleClick } = useContext(ToastContext);

  // state to hold the users data
  const [users, setUsers] = useState([]);
  const accessTokenValue = accessToken();

  // fetch the users data from the backend
  useEffect(() => {
    const apiUrl = "/api/users";
    const fetchData = async () => {
      try {
        const response = await api.get(apiUrl);
        const sortedResponse = response.data.sort((a, b) => {
          // If both users have the same status, maintain the original order
          if (a.status === b.status) {
            return 0;
          }
          return 0;
        });
        setUsers(sortedResponse);
      } catch (error) {
        handleClick("error", "Error fetching users");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  const handleUpdate = (user) => {
    navigate("/home/manageUser", { state: { userId: user.id } });
  };

  const handleMarkInactive = async (user) => {
    if (user.status === ACTIVE_STATE) {
      try {
        await deleteUser(user.id);
        handleClick("success", "User marked Inactive!");
        const index = users.findIndex((el) => el.id === user.id);
        users.splice(index, 1);
        // marking inactive means user wants to delete the entry do commenting this one
        // const response = await getUserByMobile(user.mobileNumber);
        // setUsers((prevRows) => [...prevRows, response.data]);
      } catch (error) {
        handleClick("error", "Error deleting user!");
      }
    } else {
      handleClick("info", "User is already inactive!");
    }
  };

  const navigatoToManageUser = () => {
    navigate("/home/manageUser", { state: { userId: null } });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const filteredRows = useMemo(() => {
    if (!searchTerm) return users;

    if (users.length > 0) {
      const attributes = Object.keys(users[0]);
      const list = [];
      const keyArr = ["userName", "name", "roleName"];
      for (const current of users) {
        for (const attribute of attributes) {
          if (attribute === "id") {
            continue;
          }
          if (keyArr.includes(attribute)) {
            const value = current[attribute];
            if (value && value.toLowerCase().includes(searchTerm.toLowerCase())) {
              const found = users.find((row) => row.id === current.id);
              if (found && !list.includes(found)) {
                list.push(found);
              }
            }
          }
        }
      }
      return list;
    }
    return [];
  }, [searchTerm, users]);

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleMarkInactive,
    },
  ];

  return (
    <UsersSkeleton>
      <Reveal>
        <div style={{ display: "flex" }}>
          <h2 style={{ display: "inline" }}>Users</h2>
          <Input
            sx={{ ml: 5, mb: 2 }}
            size="lg"
            placeholder="Search an user..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <CustomButton
            className="ri-add-fill ri-lg btn--primary"
            label="Add User"
            style={{ marginLeft: "auto" }}
            onClick={() => navigatoToManageUser()}
          />
        </div>
        <CustomizedTable columns={columns} tableData={filteredRows} actions={actions} />
      </Reveal>
    </UsersSkeleton>
  );
};

export const UsersSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={5}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default User;
