// import React from 'react';
// import { Doughnut } from 'react-chartjs-2';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// // Register the necessary components for Chart.js
// ChartJS.register(ArcElement, Tooltip, Legend);

// const HeartTest = () => {
//   // Donut Chart Data and Options
//   const data = {
//     labels: ['Red', 'Blue', 'Yellow'],
//     datasets: [
//       {
//         data: [30, 50, 100,40],
//         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56',"#fff"],
//         hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56',"#fff"],
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//   };

//   return (
//     <div style={{ width: '325px', height: '200px' }}>
//       <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
//         <p style={{color:"#004c70", fontWeight:600}}>Heart Test</p>
//         <div style={{gap:"5px"}}>
//         <i className="ri-qr-scan-2-line ri-xl" />
//         <i className="ri-more-2-fill ri-xl" />
//         </div>
//       </div>
//       <div></div>
//       <div></div>

//       <div style={{ width: '325px', height: '200px' }}>
//         <Doughnut data={data} options={options} />
//       </div>
//     </div>
//   );
// };

// export default HeartTest;

import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const BloodTest = () => {
  const [chartOptions, setChartOptions] = useState({
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 180,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <div>
      <h1>Blood Test Dashboard</h1>
      <div style={{ width: "320px", height: "200px" }}>
        <h2>Donut Chart Example</h2>
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="donut"
          height="350"
        />
      </div>
    </div>
  );
};

export default BloodTest;
