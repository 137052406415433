import {
  SET_ABHA_DATA,
  SET_HFR_DATA,
  SET_HPR_DATA,
  SET_SHOW_ONBOARDING,
  SET_SHOW_VERIFY,
} from "store/Actions/doctorOnboardingActions";

const initialState = {
  hprData: null,
  hfrData: null,
  abhaData: null,
  showVerify: false,
  showOnboarding: false,
};

const doctorOnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HPR_DATA: {
      return { ...state, hprData: action.payload };
    }
    case SET_HFR_DATA: {
      return { ...state, hfrData: action.payload };
    }
    case SET_ABHA_DATA: {
      return { ...state, abhaData: action.payload };
    }
    case SET_SHOW_VERIFY: {
      return { ...state, showVerify: action.payload };
    }
    case SET_SHOW_ONBOARDING: {
      return { ...state, showOnboarding: action.payload };
    }

    default:
      return state;
  }
};

export const getHprData = (state) => state.doctorOnboarding.hprData;
export const getHfrData = (state) => state.doctorOnboarding.hfrData;
export const getAbhaData = (state) => state.doctorOnboarding.abhaData;
export const getShowVerify = (state) => state.doctorOnboarding.showVerify;
export const getShowOnboarding = (state) => state.doctorOnboarding.showOnboarding;

export default doctorOnboardingReducer;
