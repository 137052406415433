import * as Yup from "yup";
import dayjs from "dayjs";

export const blockCalendarSchema = Yup.object().shape({
  fromDate: Yup.date()
    .required("Please select start date.")
    .min(dayjs().startOf("day"), "Start date cannot be in the past."),
  toDate: Yup.date()
    .required("Please select end date.")
    .min(Yup.ref("fromDate"), "End date cannot be earlier than start date.")
    .test(
      "is-greater-than-fromDate",
      "End date must be greater than start date.",
      function (value, { parent }) {
        if (parent.blockingType === "CUSTOM_DATE_TIME_RANGE") {
          return dayjs(value).isAfter(dayjs(parent.fromDate));
        }
        return true;
      }
    ),
  fromTime: Yup.string()
    .required("Please select start time.")
    .test(
      "is-after-current-time",
      "From time must be greater than current time.",
      (value, { parent }) => {
        if (
          (parent.timeRange === "CUSTOM_TIME_RANGE" ||
            parent.blockingType === "CUSTOM_DATE_TIME_RANGE") &&
          dayjs(parent.fromDate).isSame(dayjs(), "day")
        ) {
          return value > new Date().toLocaleTimeString();
        }
        return true;
      }
    )
    .test("is-before-to-time", "From time must be before to time.", (value, { parent }) => {
      if (parent.timeRange === "CUSTOM_TIME_RANGE") {
        return value < parent.toTime;
      }
      return true;
    }),
  toTime: Yup.string()
    .required("Please select end time.")
    .test("is-after-from-time", "To time must be after from time.", (value, { parent }) => {
      if (parent.timeRange === "CUSTOM_TIME_RANGE") {
        return value > parent.fromTime;
      }
      return true;
    }),
  appointmentOption: Yup.string().required("Please select appointment option."),
  blockingType: Yup.string().required("Please select TYPE option."),
  timeRange: Yup.string().required("Please select RANGE option."),
});
