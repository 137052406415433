import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ChartDisplay from "./ChartDisplay";
import { useEffect } from "react";
import { createDashboard, checkQuery, updateDashboard } from "services/DashboardService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "ui-component/custom-components/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import PreviewDashboard from "./previewDashboard";
import { SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import dayjs from "dayjs";
import Reveal from "views/utilities/Reveal";
import ModalUI from "ui-component/ModalUI";
import "../../../assets/scss/style.scss";
import { useLocation, useNavigate } from "react-router";
import { dashboardTitleSchema } from "../Common/ValidationSchema/generateDashboardValidation";
import { Parser } from "node-sql-parser";

const initialDroppableAreas = [
  {
    id: uuidv4(),
    rows: [],
  },
];

const MyDialog = ({ open, onClose, droppableAreas, dashboardTitle }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ display: "flex", alignItems: "center", height: "70px" }}>
        <h2>Dashboard Preview</h2>
        <CustomButton
          onClick={onClose}
          iconButton={<CloseIcon></CloseIcon>}
          style={{ marginLeft: "auto" }}
        ></CustomButton>
      </DialogTitle>

      <DialogContent style={{ backgroundColor: "#f2f6f8" }}>
        <DialogContentText>
          <PreviewDashboard droppableAreas={droppableAreas} dashboardTitle={dashboardTitle} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const CreateEditDashboard = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { handleClick } = useContext(ToastContext);

  const [queryText, setQueryText] = useState("");
  const [xAxis, setXAxis] = useState("");
  const [yAxis, setYAxis] = useState("");
  const [filters, setFilters] = useState("");
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [chartType, setChartType] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [queryData, setQueryData] = useState([]);
  const [params, setParams] = useState({});
  const [checkedQuery, setCheckedQuery] = useState(false);
  const [chartList, setChartList] = useState([]);
  const [saveChartsEnable, setSaveChartsEnable] = useState(false);
  const [addWidget, setAddWidget] = useState(false);
  const [chartTypeOptions, setchartTypeOptions] = useState([
    "Bar Chart",
    "Line Chart",
    "Pie Chart",
    "Table",
    "Count Card",
  ]);
  const [droppableAreas, setDroppableAreas] = useState(initialDroppableAreas);
  const [isDateValid, setIsDateValid] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(location.state ? location.state : "");
  const [titleError, setTitleError] = useState("");
  const [titleTouched, setTitleTouched] = useState(false);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      proceedWithQueryCheck(params);
    }
  }, [params]);

  const handleCheckQuery = async () => {
    try {
      let newParams = {};
      if (filters === "dateRange") {
        const variableNames = extractFilterVariableNames(queryText);
        newParams = { ...newParams, dateRange: {} };
        newParams.dateRange[variableNames[0]] = fromDate;
        newParams.dateRange[variableNames[1]] = toDate;

        setParams(newParams);
        setFromDate("");
        setToDate("");
      } else {
        proceedWithQueryCheck(params);
      }
    } catch (error) {
      handleClick("error", "Error");
    }
  };

  const proceedWithQueryCheck = async (params) => {
    try {
      const { groupByColumns, aliasColumns, columns } = parseSQLQuery(queryText);

      let queryParams = Object.keys(params).reduce((acc, filter) => {
        return { ...acc, ...params[filter] };
      }, {});
      const data = {
        query: queryText,
        params: queryParams,
        status: "ACTIVE",
      };
      const response = await checkQuery(data);
      if (response) {
        handleClick("success", "You can generate chart");
        const column = columns ? columns.split("") : response.data.headers;
        const cols = !aliasColumns.includes(null) ? aliasColumns : column;

        if (cols !== null && (cols[0] === "*" || cols.length === 1)) {
          cols[0] !== "*"
            ? setchartTypeOptions(["Table", "Count Card"])
            : setchartTypeOptions(["Table"]);
        } else if (!groupByColumns) {
          setchartTypeOptions(["Bar Chart", "Line Chart", "Table"]);
        } else {
          setchartTypeOptions(["Bar Chart", "Line Chart", "Pie Chart", "Table"]);
        }
        setCheckedQuery(true);
        setQueryData(response.data);
      }
    } catch (error) {
      handleClick("error", "Kindly check entered query");
    }
  };

  const validateDashboardTitle = async (title) => {
    try {
      await dashboardTitleSchema.validate({ dashboardTitle: title });
      setTitleError("");
    } catch (e) {
      setTitleError(e.message);
    }
  };

  const saveCharts = async () => {
    await validateDashboardTitle(dashboardTitle);
    if (titleError || !dashboardTitle) {
      handleClick("error", "Please enter a dashboard title.");
      return;
    } else {
      try {
        const updatedDroppableAreas = droppableAreas.map((area) => ({
          ...area,
          rows: area.rows.map((row) => ({
            ...row,
            columns: row.columns.map((column) => {
              if (column.charts.length === 0) {
                return {
                  columnId: column.columnId,
                  charts: [],
                };
              } else {
                const { title, queryText, xAxis, yAxis, Section, Value, chartType, Columns } =
                  column.charts[0];

                let options = column.charts[0].params
                  ? { params: column.charts[0].params }
                  : column.charts[0].options;
                if (chartType === "Bar Chart" || chartType === "Line Chart") {
                  options["xAxis"] = xAxis;
                  options["yAxis"] = yAxis;
                }

                if (chartType === "Pie Chart") {
                  options["Section"] = Section;
                  options["Value"] = Value;
                }

                if (chartType === "Table") {
                  options["Columns"] = Columns;
                }

                return {
                  columnId: column.columnId,
                  charts: [
                    {
                      type: chartType,
                      name: title,
                      widgetId: column.charts[0].widgetId,
                      options,
                      query: queryText,
                    },
                  ],
                };
              }
            }),
          })),
        }));

        try {
          if (!selectedDashboard) {
            throw new Error("Dashboard does not exist...Create one");
          }
          selectedDashboard.widgets.rows = [...updatedDroppableAreas[0].rows];
          selectedDashboard.widgets.screenId = updatedDroppableAreas[0].id;

          let responsedata = await updateDashboard(selectedDashboard.id, selectedDashboard);
          setSelectedDashboard(responsedata.data);
          handleClick("success", "Dashboard saved successfully");
        } catch (error) {
          if (
            error.response?.status === 400 ||
            error.message === "Dashboard is not active" ||
            error.message === "Dashboard does not exist...Create one"
          ) {
            const dashboarddata = {
              name: dashboardTitle,
              description: "This is the main dashboard for monitoring key metrics.",
              widgets: {
                title: "Health Dashboard",
                screenId: updatedDroppableAreas[0].id,
                rows: updatedDroppableAreas[0].rows,
              },
              url: "/dashboard",
              status: "ACTIVE",
            };
            try {
              let responsedata = await createDashboard(dashboarddata);
              setSelectedDashboard(responsedata.data);
              handleClick("success", "Dashboard created successfully");
            } catch (createError) {
              handleClick("error", createError);
            }
          }
        }
      } catch (error) {
        handleClick("error", "There seems to be an error saving dashboard.");
      }
    }
  };

  const parseSQLQuery = (query) => {
    try {
      const parser = new Parser();
      const ast = parser.astify(query); // SQL -> AST

      const response = Array.isArray(ast) ? ast[0] : ast;

      const aliasColumns = response?.columns?.map((col) => col.as);
      const groupByColumns = response?.groupBy;
      let columns = null;
      if (response.columns.length === 1 && response.columns[0].expr.column === "*") {
        columns = response.columns[0].expr.column;
      }

      return { aliasColumns, groupByColumns, columns };
    } catch (err) {
      console.error("Error parsing SQL:", err);
    }
  };

  const extractFilterVariableNames = (query) => {
    const variableNames = [];
    const regex = /#(\w+)#/g;
    let match;

    while ((match = regex.exec(query)) !== null) {
      variableNames.push(match[1]);
    }

    return variableNames;
  };

  const handleGenerateChart = () => {
    setOpen(false);
    let chart = {
      title,
      params,
      queryText,
      chartType,
      queryData,
    };

    if (chartType === "Bar Chart" || chartType === "Line Chart") {
      const { columns, aliasColumns } = parseSQLQuery(queryText);
      const enteredColumns =
        aliasColumns.length !== 0
          ? aliasColumns.map((column) => column.toLowerCase())
          : columns.map((column) => column.toLowerCase());

      if (!enteredColumns.includes(xAxis.toLowerCase())) {
        handleClick("error", `${xAxis} column is not present`);
        return;
      }
      if (!enteredColumns.includes(yAxis.toLowerCase())) {
        handleClick("error", `${yAxis} column is not present`);
        return;
      }
      chart["xAxis"] = xAxis;
      chart["yAxis"] = yAxis;
    } else if (chartType === "Pie Chart") {
      const { groupByColumns, aliasColumns } = parseSQLQuery(queryText);

      if (aliasColumns.length !== 0) {
        const { columns } = parseSQLQuery(queryText);
        chart["Section"] = aliasColumns.filter((alias) =>
          groupByColumns.includes(columns[aliasColumns.indexOf(alias)])
        );
        chart["Value"] = aliasColumns.filter((alias) => alias !== chart["Section"][0]);
      } else {
        const columnsQuery = Object.keys(queryData.data[0]);
        chart["Section"] = columnsQuery.filter((column) => groupByColumns.includes(column));
        chart["Value"] = columnsQuery.filter((column) => !groupByColumns.includes(column));
      }
    } else if (chartType === "Table") {
      queryData.data.length !== 0
        ? (chart["Columns"] = Object.keys(queryData.data[0]))
        : (chart["Columns"] = []);
    }
    chart = { ...chart, widgetId: uuidv4() };
    setChartList([...chartList, chart]);

    handleReset();
    setParams({});
    setSaveChartsEnable(true);
    setShowChart(true);
  };

  const handleReset = () => {
    setQueryText("");
    setXAxis("");
    setYAxis("");
    setFilters("");
    setChartType("");
    setTitle("");
    setCheckedQuery(false);
    setFromDate("");
    setToDate("");
  };

  const resetCharts = () => {
    handleReset();
    setShowChart(false);
    setChartList([]);
    setSaveChartsEnable(false);
  };

  const removeChartFromChartList = (widgetId) => {
    const updatedList = chartList.filter((chartItem) => chartItem.widgetId !== widgetId);
    setChartList(updatedList);
  };

  const initializeComponent = async (data) => {
    try {
      if (data && Object.keys(data).length !== 0) {
        const updatedAreaRows = await Promise.all(
          data.widgets.rows.map(async (row) => {
            const updatedColumns = await Promise.all(
              row.columns.map(async (column) => {
                if (column.charts.length === 0) {
                  return {
                    columnId: column.columnId,
                    charts: [],
                  };
                } else {
                  const { name, options, query, type, widgetId } = column.charts[0];
                  let queryParams = {};
                  if (options && options.params) {
                    queryParams = Object.keys(options.params).reduce((acc, filter) => {
                      return { ...acc, ...options.params[filter] };
                    }, {});
                  }

                  const { data } = await checkQuery({
                    query,
                    params: queryParams,
                  });
                  const chartdata = {
                    chartType: type,
                    title: name,
                    queryText: query,
                    queryData: data,
                    widgetId,
                    options,
                  };

                  if (type === "Bar Chart" || type === "Line Chart") {
                    chartdata["xAxis"] = options.xAxis;
                    chartdata["yAxis"] = options.yAxis;
                  }
                  if (type === "Pie Chart") {
                    chartdata["Section"] = options.Section;
                    chartdata["Value"] = options.Value;
                  }
                  if (type === "Table") {
                    chartdata["Columns"] = options.Columns;
                  }
                  return {
                    columnId: column.columnId,
                    charts: [chartdata],
                  };
                }
              })
            );
            row.columns = updatedColumns;
            return row;
          })
        );
        setDroppableAreas([
          {
            id: data.widgets.screenId,
            rows: updatedAreaRows.length !== 0 ? updatedAreaRows : initialDroppableAreas[0].rows,
          },
        ]);
        setDashboardTitle(data.name);
      } else {
        setDroppableAreas(initialDroppableAreas);
        setDashboardTitle("");
        setSelectedDashboard("");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    initializeComponent(location.state);
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      setIsDateValid(dayjs(fromDate) <= dayjs(toDate));
    } else {
      setIsDateValid(true);
    }
  }, [fromDate, toDate]);
  const addRow = (areaId, rowIndex) => {
    setDroppableAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === areaId
          ? {
              ...area,
              rows: [
                ...area.rows.slice(0, rowIndex + 1),
                {
                  rowId: uuidv4(),
                  columns: [{ columnId: uuidv4(), charts: [] }],
                },
                ...area.rows.slice(rowIndex + 1),
              ],
            }
          : area
      )
    );
  };

  const addColumn = (areaId, rowId) => {
    const newAreas = JSON.parse(JSON.stringify(droppableAreas));

    const areaIndex = newAreas.findIndex((area) => area.id === areaId);
    if (areaIndex === -1) return;

    const rowIndex = newAreas[areaIndex].rows.findIndex((row) => row.rowId === rowId);
    if (rowIndex === -1) return;

    const row = newAreas[areaIndex].rows[rowIndex];

    row.columns.push({
      columnId: uuidv4(),
      charts: [],
    });

    setDroppableAreas(newAreas);
  };

  const removeColumn = (areaId, rowId, columnId) => {
    const newAreas = JSON.parse(JSON.stringify(droppableAreas));

    const areaIndex = newAreas.findIndex((area) => area.id === areaId);
    if (areaIndex === -1) return;

    const rowIndex = newAreas[areaIndex].rows.findIndex((row) => row.rowId === rowId);
    if (rowIndex === -1) return;

    const row = newAreas[areaIndex].rows[rowIndex];
    const columnIndex = row.columns.findIndex((column) => column.columnId === columnId);
    if (columnIndex === -1) return;

    row.columns.splice(columnIndex, 1);
    if (row.columns.length === 0) newAreas[areaIndex].rows.splice(rowIndex, 1);
    setDroppableAreas(newAreas);
  };

  const deleteRow = (areaId, rowIndex) => {
    setDroppableAreas((prevAreas) =>
      prevAreas.map((area) =>
        area.id === areaId
          ? {
              ...area,
              rows: area.rows.filter((_, index) => index !== rowIndex),
            }
          : area
      )
    );
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return; // If dropped outside a droppable area

    const newPreviousCharts = [...chartList];
    const newAreas = JSON.parse(JSON.stringify(droppableAreas));

    const sourceIds = source.droppableId.split("-");
    const destinationIds = destination.droppableId.split("-");

    if (source.droppableId === "chartList") {
      const [movedChart] = newPreviousCharts.splice(source.index, 1); // Remove chart from previousCharts

      const [destinationAreaIndex, destinationRowIndex, destinationColumnIndex] =
        destinationIds.map(Number);

      const destinationArea = newAreas[destinationAreaIndex];
      const destinationRow = destinationArea?.rows?.[destinationRowIndex];
      const destinationColumn = destinationRow?.columns?.[destinationColumnIndex];

      if (!destinationColumn) {
        return;
      }
      if (movedChart.chartType === "Count Card" && destinationRowIndex !== 0) {
        handleClick("error", "Count card charts can only be dropped in the first row");
        return;
      } else if (movedChart.chartType !== "Count Card" && destinationRowIndex === 0) {
        handleClick("error", "Charts other than cards can not be dropped in the first row");
        return;
      }
      if (destinationColumn.charts.length === 0) {
        destinationColumn.charts.splice(destination.index, 0, movedChart);
      } else {
        return;
      }
    } else {
      // Moving within the droppable areas
      if (sourceIds.length < 3 || destinationIds.length < 3) return;

      const [sourceAreaIndex, sourceRowIndex, sourceColumnIndex] = sourceIds.map(Number);
      const [destinationAreaIndex, destinationRowIndex, destinationColumnIndex] =
        destinationIds.map(Number);

      const sourceArea = newAreas[sourceAreaIndex];
      const sourceRow = sourceArea?.rows?.[sourceRowIndex];
      const sourceColumn = sourceRow?.columns?.[sourceColumnIndex];

      if (!sourceColumn) {
        return;
      }

      const destinationArea = newAreas[destinationAreaIndex];
      const destinationRow = destinationArea?.rows?.[destinationRowIndex];
      const destinationColumn = destinationRow?.columns?.[destinationColumnIndex];

      if (!destinationColumn) {
        return;
      }

      // Move the chart within the grid
      const [movedChart] = sourceColumn.charts.splice(source.index, 1);
      if (movedChart.chartType === "Count Card" && destinationRowIndex !== 0) {
        handleClick("error", "Count card can only be dropped in the first row");
        return;
      } else if (movedChart.chartType !== "Count Card" && destinationRowIndex === 0) {
        handleClick("error", "Charts other than cards can not be dropped in the first row");
        return;
      }
      if (destinationColumn.charts.length === 0) {
        destinationColumn.charts.splice(destination.index, 0, movedChart);
      } else {
        return;
      }
    }

    setChartList(newPreviousCharts);
    setDroppableAreas(newAreas);
  };

  const updateDataBasedOnFilterParams = (widgetId, chartData, newParams) => {
    setDroppableAreas((prevDroppableAreas) =>
      prevDroppableAreas.map((area) => ({
        ...area,
        rows: area.rows.map((row) => ({
          ...row,
          columns: row.columns.map((column) => ({
            ...column,
            charts: column.charts.map((chart) =>
              chart.widgetId === widgetId
                ? {
                    ...chart,
                    queryData: { ...chart.queryData, data: chartData },
                    ...(chart.options && {
                      options: { ...chart.options, params: newParams },
                    }),
                    ...(chart.params && { params: newParams }),
                  }
                : chart
            ),
          })),
        })),
      }))
    );
  };

  const backToGenerateDash = () => {
    navigate(-1);
  };

  return (
    <GenerateDashBoardSkeleton>
      <Reveal>
        <MyDialog
          open={openDialog}
          onClose={handleCloseDialog}
          droppableAreas={droppableAreas}
          dashboardTitle={dashboardTitle}
        />
        <Grid container spacing={2}>
          <Grid size={{ xs: 6 }} alignContent="center">
            <i
              className="ri-arrow-left-line ri-xl"
              style={{ color: "#004c70", fontSize: "25px", cursor: "pointer" }}
              onClick={backToGenerateDash}
            />
          </Grid>
          <Grid container size={{ xs: 6 }} spacing={2} display="flex" justifyContent="flex-end">
            <Grid>
              <CustomButton className="btn--primary" onClick={handleOpenDialog} label={"Preview"} />
            </Grid>
            <Grid>
              <CustomButton
                className="btn--primary"
                onClick={saveCharts}
                label={
                  Object.keys(selectedDashboard).length === 0
                    ? "Create Dashboard"
                    : "Save Dashboard"
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Paper elevation={3} style={{ padding: "16px", marginTop: "10px" }}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h4" style={{ color: "#004c70" }} gutterBottom>
              Dashboard Title
            </Typography>
            <TextField
              fullWidth
              label="Dashboard Title"
              value={dashboardTitle}
              error={Boolean(titleError)}
              helperText={titleError}
              onBlur={(e) => {
                setTitleTouched(true);
                validateDashboardTitle(e.target.value);
              }}
              onChange={(e) => {
                setDashboardTitle(e.target.value);
                if (titleTouched) {
                  validateDashboardTitle(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid size={{ xs: 12 }} container spacing={2}>
            <Grid sx={{ marginTop: "10px" }}>
              <CustomButton
                className="btn--primary"
                onClick={() => {
                  setAddWidget(true);
                  setOpen(true);
                }}
                label={"Add Widget"}
              />
            </Grid>
            {saveChartsEnable && (
              <Grid sx={{ marginTop: "10px" }}>
                <CustomButton
                  className="btn--primary"
                  onClick={resetCharts}
                  label={"Reset All Charts"}
                />
              </Grid>
            )}
          </Grid>
          {addWidget && (
            <ModalUI
              visible={open}
              close={() => {
                setAddWidget(false);
                setOpen(false);
              }}
              title="Add Widget"
              style={{
                width: "1000px",
                padding: "20px",
              }}
              component={
                <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                  {/* <Grid item xs={6}>
              <Typography variant="h4" style={{ color: "#004c70" }} gutterBottom>
                Generate Chart
              </Typography>
            </Grid> */}
                  <Grid size={{ xs: 12 }}>
                    <TextField
                      fullWidth
                      label="Query Text"
                      value={queryText}
                      onChange={(e) => setQueryText(e.target.value)}
                    />
                  </Grid>
                  <Grid container size={{ xs: 12 }}>
                    <Grid size={{ xs: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel id="filter-label">Filter</InputLabel>
                        <Select
                          labelId="filter-label"
                          value={filters}
                          onChange={(e) => setFilters(e.target.value)}
                        >
                          <MenuItem value="">No filter</MenuItem>
                          <MenuItem value="dateRange">Date Range</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {filters === "dateRange" && (
                      <Grid size={{ xs: 6, sm: 6 }}>
                        <TextField
                          fullWidth
                          label="From Date"
                          type="date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                          style={{ marginBottom: "5px" }}
                          error={!isDateValid}
                          helperText={
                            !isDateValid ? "From Date should be less than or equal to To Date" : ""
                          }
                        />
                        <TextField
                          fullWidth
                          label="To Date"
                          type="date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                          error={!isDateValid}
                          helperText={
                            !isDateValid
                              ? "To Date should be greater than or equal to From Date"
                              : ""
                          }
                        />
                      </Grid>
                    )}
                  </Grid>

                  {checkedQuery && (
                    <Grid size={{ xs: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel id="chart-type-label">Chart Types</InputLabel>
                        <Select
                          labelId="chart-type-label"
                          id="chart-type-select"
                          value={chartType}
                          onChange={(e) => setChartType(e.target.value)}
                          fullWidth
                        >
                          {chartTypeOptions.map((chart) => (
                            <MenuItem key={chart} value={chart}>
                              {chart}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {(chartType.includes("Line Chart") || chartType.includes("Bar Chart")) && (
                    <Grid size={{ xs: 6 }}>
                      <TextField
                        fullWidth
                        label="X-axis"
                        value={xAxis}
                        onChange={(e) => setXAxis(e.target.value)}
                        style={{ marginBottom: "5px" }}
                      />
                      <TextField
                        fullWidth
                        label="Y-axis"
                        value={yAxis}
                        onChange={(e) => setYAxis(e.target.value)}
                      />
                    </Grid>
                  )}

                  {chartType !== "" && (
                    <Grid size={{ xs: 6 }}>
                      <TextField
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ marginBottom: "5px" }}
                      />
                    </Grid>
                  )}

                  <Grid container size={{ xs: 12 }} spacing={2}>
                    {!checkedQuery && (
                      <Grid item>
                        <CustomButton
                          className="btn--primary"
                          onClick={handleCheckQuery}
                          label={"Execute query"}
                        />
                      </Grid>
                    )}
                    {checkedQuery && (
                      <Grid item>
                        <CustomButton
                          className="btn--primary"
                          onClick={handleGenerateChart}
                          label={"Generate Chart"}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <CustomButton
                        className="btn--primary"
                        onClick={handleReset}
                        label={"Reset"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          )}
        </Paper>
        <DragDropContext onDragEnd={onDragEnd}>
          {showChart && (
            <Droppable droppableId="chartList" direction="horizontal">
              {(provided) => (
                <Grid container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                  {chartList.map((chart, index) => (
                    <Draggable key={chart.widgetId} draggableId={chart.widgetId} index={index}>
                      {(provided) => (
                        <Grid
                          size={{ xs: 6, sm: 6, md: 4 }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="chart-container"
                        >
                          <Grid sx={{ padding: 2, cursor: "move" }}>
                            <Box display="flex" justifyContent="flex-end">
                              <CustomButton
                                className="btn--primary"
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  border: "none",
                                  boxShadow: "none",
                                  color: "#000000",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                onClick={() => removeChartFromChartList(chart.widgetId)}
                              >
                                <span style={{ marginLeft: "auto" }}>X</span>
                              </CustomButton>
                            </Box>
                            <ChartDisplay querydata={chart} />
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          )}

          {droppableAreas.map((area, areaIndex) => (
            <Reveal key={area.id} style={{ marginTop: "15px" }}>
              {area.rows.map((row, rowIndex) => (
                <Reveal key={row.rowId}>
                  <Grid container spacing={1} style={{ marginTop: "5px" }}>
                    <Grid size={{ xs: 12 }}>
                      <Paper elevation={3} sx={{ padding: 0.5, borderRadius: 2 }}>
                        <Grid container spacing={1}>
                          {row.columns.map((column, columnIndex) => (
                            <Droppable
                              key={column.columnId}
                              droppableId={`${areaIndex}-${rowIndex}-${columnIndex}`}
                              direction="vertical"
                              isDropDisabled={column.charts.length > 0}
                            >
                              {(provided) => (
                                <Grid
                                  size={{ xs: 12, sm: 6, md: 4 }}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <Paper
                                    elevation={1}
                                    sx={{
                                      padding: 0,
                                      minHeight: "100px",
                                      borderRadius: 2,
                                    }}
                                  >
                                    <Box display="flex" justifyContent="flex-end">
                                      <CustomButton
                                        className="btn--primary"
                                        onClick={() =>
                                          removeColumn(area.id, row.rowId, column.columnId)
                                        }
                                        label={"X"}
                                      />
                                    </Box>
                                    {column.charts.map((chart, chartIndex) => (
                                      <Draggable
                                        key={chart.widgetId}
                                        draggableId={chart.widgetId}
                                        index={chartIndex}
                                      >
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <Grid sx={{ padding: 0, cursor: "move" }}>
                                              <ChartDisplay
                                                querydata={chart}
                                                editChart={true}
                                                onFilterParamsChange={(chartData, newParams) =>
                                                  updateDataBasedOnFilterParams(
                                                    chart.widgetId,
                                                    chartData,
                                                    newParams
                                                  )
                                                }
                                              />
                                            </Grid>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </Paper>
                                </Grid>
                              )}
                            </Droppable>
                          ))}
                          <Grid
                            size={{ xs: 4 }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CustomButton
                              className="btn--primary"
                              onClick={() => addColumn(area.id, row.rowId)}
                              label={"+ Column"}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    display="flex"
                    justifyContent="flex-end"
                    marginTop="5px"
                  >
                    <Grid item>
                      <CustomButton
                        className="btn--primary"
                        onClick={() => addRow(area.id, rowIndex)}
                        label={"+ Row"}
                      />
                    </Grid>
                    <Grid item>
                      <CustomButton
                        className="btn--primary"
                        onClick={() => deleteRow(area.id, rowIndex)}
                        label={"- Above Row"}
                      />
                    </Grid>
                  </Grid>
                </Reveal>
              ))}
              {area.rows.length === 0 && (
                <Grid item display="flex" justifyContent="flex-end">
                  <CustomButton
                    className="btn--primary"
                    onClick={() => addRow(area.id, -1)}
                    label={"+ Row"}
                  />
                </Grid>
              )}
            </Reveal>
          ))}
        </DragDropContext>
      </Reveal>
    </GenerateDashBoardSkeleton>
  );
};

export const GenerateDashBoardSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={125}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={125}
          />
        </Grid>
        <Grid size={{ xs: 12 }} display={"flex"} justifyContent={"end"}>
          <Grid size={{ xs: 3 }} display={"flex"}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={30}
            />
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateEditDashboard;
