import React, { useState, useEffect } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  COMPLETED,
  DOCTOR,
  PATIENT,
  accessToken,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
} from "store/constant";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import {
  IconButton,
  Popper,
  Rating,
  Grid,
  Typography,
  Switch,
  Card,
  CardContent,
  Skeleton,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import { getAppointmentsByPatientId, getFilteredAppointments } from "services/Appointments";
import { useContext } from "react";
import { getAllDoctors, getDoctorByUserId } from "services/doctorService";
import { getAllPatients, getPatientByUserId } from "services/patientService";
import {
  getAllReviews,
  getReviewsByDocId,
  getReviewsByPatientId,
  deleteReview,
} from "services/ReviewService";
import SettingsIcon from "@mui/icons-material/Settings";
import { useRef } from "react";
import { updateDoctors } from "services/doctorService";
import Reveal from "views/utilities/Reveal";

const ReviewList = () => {
  const { handleClick } = useContext(ToastContext);

  const navigate = useNavigate();

  const [reviews, setReviews] = useState([]);
  const [showAddButtion, setshowAddButtion] = useState(false);
  const currUser = JSON.parse(localStorage.getItem("currentActiveUser"));
  const currRole = currUser?.roleName;
  const accessTokenValue = accessToken();
  const [iconRotate, setIconRotate] = useState(false);
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [displayReviews, setDisplayReviews] = useState(null);
  const [acceptReviews, setAcceptReviews] = useState(null);

  const getColumns = () => {
    let columns;
    let c = [
      { field: "Sr. No.", label: "Sr. No" },
      { field: "rating", label: "Rating" },
      { field: "title", label: "Title" },
      { field: "description", label: "Description" },
    ];

    switch (currRole) {
      case PATIENT:
        columns = [
          ...c.slice(0, 2),
          { field: "docName", label: "Doctor" },
          ...c.slice(2),
          { field: "Actions", label: "Actions" },
        ];
        break;
      case DOCTOR:
        columns = [...c.slice(0, 2), { field: "patientName", label: "Patient" }, ...c.slice(2)];
        break;
      default:
        columns = [
          ...c.slice(0, 2),
          { field: "patientName", label: "Patient" },
          { field: "docName", label: "Doctor" },
          ...c.slice(2),
        ];
        break;
    }
    return columns;
  };
  const handleIconClick = () => {
    setSettingModalOpen(!settingModalOpen);
    setIconRotate(!iconRotate);
  };

  const settingsRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = [];
        let fetchedDoctors = [];
        let fetchedPatients = [];

        if (currRole === PATIENT) {
          const tempResponse = await getPatientByUserId(currUser.id);
          const patientId = tempResponse?.data?.id;
          const tempAppointmentsRes = await getAppointmentsByPatientId(patientId);
          const appRes = tempAppointmentsRes.data.filter(
            (el) => el?.appointmentStatus === COMPLETED && el?.doctor?.acceptReviews
          );
          setshowAddButtion(appRes.length !== 0);

          fetchedDoctors = appRes.map((el) => ({
            id: el.doctor?.id,
            user: { name: el.doctor.userResponse.name },
          }));

          response = await getReviewsByPatientId(patientId);
        } else if (currRole === DOCTOR) {
          const tempResponse = await getDoctorByUserId(currUser?.id);
          setAcceptReviews(tempResponse?.data.acceptReviews);
          setDisplayReviews(tempResponse?.data.displayReviews);
          const docId = tempResponse?.data?.id;
          const tempAppointmentsRes = await getFilteredAppointments({ doctorId: docId });
          const appRes = tempAppointmentsRes.data.content.filter(
            (el) => el.appointmentStatus === COMPLETED
          );

          fetchedPatients = appRes.map((el) => ({
            id: el?.patientId,
            user: { name: el.patientName },
          }));
          response = await getReviewsByDocId(docId);
          setshowAddButtion(false);
        } else {
          const docResponse = await getAllDoctors();
          fetchedDoctors = docResponse.data;

          const patientResponse = await getAllPatients();
          fetchedPatients = patientResponse.data;

          response = await getAllReviews();
          setshowAddButtion(false);
        }

        let reviewsData = [];
        response.data.forEach((el) => {
          const obj = {
            ...el,
            docName: fetchedDoctors.find((d) => d.id === el.doctorId)?.user?.name,
            patientName: fetchedPatients.find((p) => p.id === el.patientId)?.user?.name,
            rating: <Rating name={`rating-${el.id}`} value={el.rating} size="large" />,
          };
          reviewsData.push(obj);
        });
        setReviews(reviewsData);
      } catch (error) {
        handleClick("error", "Error fetching data");
      }
    };

    fetchData();
  }, [accessTokenValue, currRole, currUser?.id, handleClick]);

  const navigatoToAddReview = () => {
    navigate("/home/review");
  };

  const handleUpdate = (review) => {
    navigate("/home/review", { state: { reviewId: review?.id } });
  };

  const handleDelete = async (review) => {
    const reviewId = review?.id;
    try {
      const res = await deleteReview(reviewId);
      if (res) {
        const tempReviews = reviews.filter((el) => el.id !== reviewId);
        setReviews(tempReviews);
        handleClick("success", "Review deleted successfully!!");
      }
    } catch (error) {
      handleClick("error", "Error review can't be deleted!!");
    }
  };

  const isDisable = () => {
    //  delete functionality should only allow for patient
    if (currRole === PATIENT) {
      return false;
    } else {
      return true;
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDelete,
      checkDisable: isDisable,
    },
  ];

  const handleSettingsSave = async () => {
    try {
      const data = {
        displayReviews: displayReviews,
        acceptReviews: acceptReviews,
      };
      const response = await updateDoctors(currUser?.roleBasedId, data);
      setSettingModalOpen(false);
      handleClick("success", "Settings updated");
    } catch (error) {
      handleClick("error", "Error updating settingds");
    }
  };

  return (
    <ReviewListSkeletonStructure>
      <Reveal>
        <div className="section-heading">
          <h2 className="page-title">Reviews</h2>
          <div className="buttons">
            {showAddButtion && (
              <CustomButton
                className="ri-add-fill ri-lg btn--primary"
                style={{ marginLeft: "auto" }}
                onClick={() => navigatoToAddReview()}
                label="Add Review"
              />
            )}
            {currRole === "DOCTOR" && (
              <>
                <IconButton
                  onClick={handleIconClick}
                  ref={settingsRef}
                  sx={{
                    marginLeft: "auto",
                    transform: iconRotate ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                    "&:hover, &:focus": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <SettingsIcon />
                </IconButton>
                <Popper
                  placement="bottom-end"
                  open={settingModalOpen}
                  disablePortal
                  anchorEl={settingsRef.current}
                  style={{ zIndex: "10" }}
                >
                  <Card
                    sx={{
                      bgcolor: "white",
                      height: "200px",
                      width: "250px",
                      padding: "10px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={3} direction="column">
                        <Grid item>
                          <Grid item container alignItems="center" justifyContent="space-between">
                            <Grid item>
                              <Typography variant="subtitle1">Display Reviews</Typography>
                            </Grid>
                            <Grid item>
                              <Switch
                                color="primary"
                                checked={displayReviews}
                                onChange={(e) => setDisplayReviews(e.target.checked)}
                                name="displayReviews"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid item container alignItems="center" justifyContent="space-between">
                            <Grid item>
                              <Typography variant="subtitle1">Accept Reviews</Typography>
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={acceptReviews}
                                onChange={(e) => setAcceptReviews(e.target.checked)}
                                name="acceptReviews"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <CustomButton
                            label={"Save"}
                            onClick={handleSettingsSave}
                            className={"btn--secondary-light"}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Popper>
              </>
            )}
          </div>
        </div>
        <CustomizedTable columns={getColumns()} tableData={reviews} actions={actions} />
      </Reveal>
    </ReviewListSkeletonStructure>
  );
};

const ReviewListSkeletonStructure = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewList;
