import React, { useContext, useEffect, useReducer, useState } from "react";
import CustomOtpField from "ui-component/custom-components/CustomOtpField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getInfoFromInvitationLink, verifyUserInvitation } from "services/organizationService";
import { verifyComplete, verifyInit } from "services/AuthService";
import { DATE_TIME_FORMAT, DOCTOR, getRoleIdByName, VERIFY_MOBILE_NUMBER } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useDispatch } from "react-redux";
import {
  setInvitationLinkData,
  setOrganizationData,
  setShowOnboarding,
} from "store/Slices/doctorOnboardingSlice";
import dayjs from "dayjs";
import "assets/scss/userInvitation.scss";

const UserInvitationVerification = () => {
  const [searchParams] = useSearchParams();
  const linkId = searchParams.get("param");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showInvitation, setShowInvitation] = useState(true);

  const { handleClick } = useContext(ToastContext);
  const [invitationInfo, setInvitationInfo] = useState(null);

  const handleResendOtp = async () => {
    const payload = {
      contactField: invitationInfo.user.mobileNumber,
      verificationType: VERIFY_MOBILE_NUMBER,
      isOtpRequired: true,
    };
    await verifyInit(payload);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getInfoFromInvitationLink(linkId);
        setInvitationInfo(response.data);
        const payload = {
          contactField: response.data.user.mobileNumber,
          verificationType: VERIFY_MOBILE_NUMBER,
          isOtpRequired: true,
        };
        await verifyInit(payload);
        dispatch(
          setInvitationLinkData({
            type: response.data.invitationType,
            link: linkId,
          })
        );
      } catch (error) {
        setShowInvitation(false);
      }
    };
    fetchData();
  }, []);

  const handleOtpFilled = async (otp) => {
    try {
      const payload = {
        contactField: invitationInfo?.user.mobileNumber,
        verificationType: VERIFY_MOBILE_NUMBER,
        otp: otp,
      };
      const verifyResponse = await verifyComplete(payload);
      if (!verifyResponse.data.isVerified) {
        handleClick("error", "Invalid OTP");
        return;
      }

      if (invitationInfo?.isExistingUser) {
        const paramPayload = {
          param: linkId,
          otp: otp,
        };
        const userInviteResponse = await verifyUserInvitation(paramPayload);
        handleClick("success", userInviteResponse.data);
        navigate("/home/dashboard");
      } else {
        dispatch(setShowOnboarding(true));
        dispatch(setOrganizationData(invitationInfo.organization));
        const doctorDetail = {
          fullName: [
            invitationInfo.user.firstName,
            invitationInfo.user.middleName,
            invitationInfo.user.lastName,
          ]
            .filter(Boolean)
            .join(" "),
          mobileNumber: invitationInfo.user.mobileNumber,
          emailId: invitationInfo.user.emailId,
          gender: invitationInfo.user.gender,
          roleId: await getRoleIdByName(DOCTOR, handleClick),
          dateOfBirth: invitationInfo.user.dateOfBirth || dayjs().format(DATE_TIME_FORMAT),
        };
        navigate("/onboarding", {
          state: { validatedBy: "VERIFY_INVITATION_LINK", doctorDetail: doctorDetail },
          replace: true,
        });
      }
    } catch (error) {
      if (error.response?.status === 404) {
        handleClick("error", error.response.data.message);
      }
    }
  };

  return (
    <div className="user-invitation-verification">
      <div className="invite-container">
        <div>
          <img src="/images/email/logo.png"></img>
        </div>
        <div>
          <img src="/images/email/invite-banner.png"></img>
        </div>

        {showInvitation ? (
          <>
            <h1 className="invite-clinic-name">{invitationInfo?.organization.name}</h1>

            <p className="invite-invited-by">Invited by {invitationInfo?.invitedByUser.name}</p>

            <h2 className="invite-welcome-message">
              Welcome, Dr. {invitationInfo?.user.firstName}
            </h2>

            <p
              style={{ fontStyle: "italic", marginBottom: "10px", color: "#9e9e9e" }}
            >{`Please enter the OTP sent to ${
              "x".repeat(invitationInfo?.user.mobileNumber?.length - 4) +
              invitationInfo?.user.mobileNumber?.slice(-4)
            } :`}</p>

            <div className="invite-otp-container">
              <CustomOtpField
                onOtpSubmit={handleOtpFilled}
                handleResendOtp={handleResendOtp}
              ></CustomOtpField>
            </div>
          </>
        ) : (
          <>
            <p
              style={{
                fontStyle: "italic",
                fontSize: "16px",
                marginBottom: "10px",
                color: "#9e9e9e",
              }}
            >
              {"Your Invitation has been expired."}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default UserInvitationVerification;
