export const SET_APPOINTMENT_DATA = "SET_APPOINTMENT_DATA";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_APPOINTMENT_TIME = "SET_APPOINTMENT_TIME";
export const SET_PATIENT_ID = "SET_PATIENT_ID";
export const SET_DOCTOR_ID = "SET_DOCTOR_ID";
export const SET_APPOINTMENT_DATE = "SET_APPOINTMENT_DATE";
export const SET_NOTES = "SET_NOTES";
export const SET_TYPE = "SET_TYPE";
export const SET_SELECTED_APPOINTMENT_DATA = "SET_SELECTED_APPOINTMENT_DATA";
export const SET_UHI_APPOINTMENT_DATA = "SET_UHI_APPOINTMENT_DATA";

export const setAppointmentdata = (data) => {
  return { type: SET_APPOINTMENT_DATA, payload: data };
};
export const setSelectedAppointmentData = (data) => {
  return { type: SET_SELECTED_APPOINTMENT_DATA, payload: data };
};
export const setOrgId = (data) => {
  return { type: SET_ORG_ID, payload: data };
};
export const setAppointmentTime = (data) => {
  return { type: SET_APPOINTMENT_TIME, payload: data };
};
export const setPatientId = (data) => {
  return { type: SET_PATIENT_ID, payload: data };
};
export const setDoctorId = (data) => {
  return { type: SET_DOCTOR_ID, payload: data };
};
export const setAppointmentDate = (data) => {
  return { type: SET_APPOINTMENT_DATE, payload: data };
};
export const setNotes = (data) => {
  return { type: SET_NOTES, payload: data };
};
export const setType = (data) => {
  return { type: SET_TYPE, payload: data };
};
export const setUhiAppointmentData = (data) => {
  return { type: SET_UHI_APPOINTMENT_DATA, payload: data };
};
