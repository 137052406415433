import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loggedIn } from "store/constant";

function Protected(props) {
  const navigate = useNavigate();
  const { Component } = props;

  useEffect(() => {
    if (!loggedIn() || loggedIn() === "false") {
      localStorage.clear();
      navigate("/");
    }
  });

  return (
    <>
      <Component />
    </>
  );
}

export default Protected;
