export const SET_IS_DEMO = "SET_IS_DEMO";

export const SET_DEMO_DATA_SET = "SET_DEMO_DATA_SET";

export const SET_IS_OVERLAY_OPEN = "SET_IS_OVERLAY_OPEN";

export const SET_GUIDED_DEMO_ON = "SET_GUIDED_DEMO_ON";

export const setIsDemo = (payload) => {
  return { type: SET_IS_DEMO, payload };
};

export const setDemoDataSet = (payload) => {
  return { type: SET_DEMO_DATA_SET, payload };
};

export const setIsOverlayOpen = (payload) => {
  return { type: SET_IS_OVERLAY_OPEN, payload };
};

export const setGuidedModeOne = (payload) => {
  return { type: SET_GUIDED_DEMO_ON, payload };
};

export const dummyToken = {
  access_token:
    "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5NjAxOTg3Nzg0IiwiaWF0IjoxNzI1NTE4MDMxLCJleHAiOjE3Mjg5NzQwMzF9.9dqzu0ZvKHLcOzzsiwQe93A_qH5vjyC3a2s5cy9u9tI",
  refresh_token:
    "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5NjAxOTg3Nzg0IiwiaWF0IjoxNzI1NTE4MDMxLCJleHAiOjE3MjgxMTAwMzF9.lbIxxvYUu2ykcyz_n4YxwmU4SYt6zSMRzGW3l3Gla70",
};

export const PatientData = [
  {
    label: "Data Set 1",
    offlineAppointmentId: "10b2c0dd-b3c9-4efb-807e-24ed6e3962bb",
    onlineAppointmentId: "ca75202e-751b-4534-a6b0-b182e1ee6c43",
    patientId: "3fd7111a-b960-4b03-bf1d-7d4f62128b8b",
  },
  {
    label: "Data Set 2",
    offlineAppointmentId: "48f7e1d0-02fb-4c52-8f2e-1d0deb554296",
    onlineAppointmentId: "281a5b51-77e5-482d-828e-113948730d5e",
    patientId: "60a71836-4654-42d7-be91-a55094782a2f",
  },
  {
    label: "Data Set 3",
    offlineAppointmentId: "594b8ccc-5226-4deb-a473-3ba8d792e289",
    onlineAppointmentId: "e2a63ab0-d433-4c66-bcb3-ab9ae60be38f",
    patientId: "2901a60e-fd76-4744-9eee-15b0b9a72897",
  },
];
