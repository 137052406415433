import * as Yup from "yup";

export const languageSchema = Yup.object().shape({
  language: Yup.string()
    .required("Name is required!")
    .max(50, "Name too long")
    .matches(/^(?!\s*$).+$/, "Name must not contain empty spaces")
    .matches(/^[^0-9]*$/, "No numeric digits are allowed")
    .matches(/^[A-Za-z0-9\s]*$/, "No special characters are allowed"),
});
