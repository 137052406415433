import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserFeatures } from "store/Reducers/userReducer";
import { setUserFeatures } from "store/Actions/userActions";
import { featureMapper, isDemo } from "../store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { ShepherdJourneyProvider } from "react-shepherd";

function ValidateFeature(props) {
  const navigate = useNavigate();
  const { Component, url } = props;
  const dispatch = useDispatch();
  let features = useSelector(getUserFeatures);
  const { handleClick } = useContext(ToastContext);

  useEffect(() => {
    if (!features) {
      features = JSON.parse(localStorage.getItem("userFeatures"));
      dispatch(setUserFeatures(features));
    }
    const featureName = featureMapper[url];
    const isExist = features.hasOwnProperty(featureName);
    // if feature is assigned
    if (!isExist && !isDemo()) {
      // to its dashboard
      navigate("/home/dashboard");
      handleClick("info", "Sorry, you don't have access to this page@");
    }
  });

  return (
    <>
      {["prescriptionPad", "ehr"].includes(url) ? (
        <ShepherdJourneyProvider>
          <Component />
        </ShepherdJourneyProvider>
      ) : (
        <Component />
      )}
    </>
  );
}

export default ValidateFeature;
