import * as Yup from "yup";

export const doctorSchemaForClinicAdmin = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .min(2, "Minimum 2 characters allowed")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Required")
    .min(2, "Minimum 2 characters allowed")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Required"),
  specialization: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .required("Required")
    .max(255, "Maximum 255 characters allowed"),
});

export const doctorSchemaForSuperAdmin = doctorSchemaForClinicAdmin.concat(
  Yup.object().shape({
    doctorOrganizationAssociation: Yup.array().min(1, "Select at least one organization"),
  })
);
