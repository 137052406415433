// reducers.js
import {
  SET_PRESCRIPTION_PAD_DATA,
  SET_MEDICATIONS,
  SET_SYMPTOMS,
  SET_DIAGNOSIS,
  SET_LAB_INVESTIGATIONS,
  SET_ADVICES,
  SET_PRESCRIPTION_ID,
} from "store/Actions/PrescriptionPadDataActions";

const initialState = {
  medicationsArr: [],
  symptomsArr: [],
  diagnosisArr: [],
  labInvestigationsArr: [],
  advicesArr: [],
  prescriptionId: null,
};

const prescriptionPadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRESCRIPTION_PAD_DATA:
      return {
        ...state,
        medicationsArr: [...action.payload.medicationsArr],
        symptomsArr: [...action.payload.symptomsArr],
        diagnosisArr: [...action.payload.diagnosisArr],
        labInvestigationsArr: [...action.payload.labInvestigationsArr],
        advicesArr: [...action.payload.advicesArr],
      };
    case SET_MEDICATIONS:
      return {
        ...state,
        medicationsArr: state.medicationsArr.map((item, i) =>
          i === action.payload.index
            ? { ...item, [action.payload.field]: action.payload.value }
            : item
        ),
      };
    case SET_SYMPTOMS:
      return {
        ...state,
        symptomsArr: state.symptomsArr?.map((item, i) =>
          i === action.payload.index
            ? { ...item, [action.payload.field]: action.payload.value }
            : item
        ),
      };
    case SET_DIAGNOSIS:
      return {
        ...state,
        diagnosisArr: state.diagnosisArr.map((item, i) =>
          i === action.payload.index
            ? { ...item, [action.payload.field]: action.payload.value }
            : item
        ),
      };
    case SET_LAB_INVESTIGATIONS:
      return {
        ...state,
        labInvestigationsArr: state.labInvestigationsArr.map((item, i) =>
          i === action.payload.index
            ? { ...item, [action.payload.field]: action.payload.value }
            : item
        ),
      };
    case SET_ADVICES:
      return {
        ...state,
        advicesArr: state.advicesArr.map((item, i) =>
          i === action.payload.index ? { ...item, displayName: action.payload.value } : item
        ),
      };
    case SET_PRESCRIPTION_ID:
      return {
        ...state,
        prescriptionId: action.payload.value,
      };

    default:
      return state;
  }
};
export const getPrescriptionPadData = (state) => state.prescriptionPadReducer;
export const getFieldData = (state, key) => state.prescriptionPadReducer[key];
export const getSymptoms = (state) => state.prescriptionPadReducer.symptomsArr;
export const getDiagnosis = (state) => state.prescriptionPadReducer.diagnosisArr;
export const getLabInvestigations = (state) => state.prescriptionPadReducer.labInvestigationsArr;
export const getAdvices = (state) => state.prescriptionPadReducer.advicesArr;
export const getPrescriptionId = (state) => state.prescriptionPadReducer.prescriptionId;
export default prescriptionPadReducer;
