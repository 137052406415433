export const getColorAndBackgroundColor = (value) => {
  // Ensure the value is within the 0 to 1 range
  const normalizedValue = Math.max(0, Math.min(1, value));

  // Start, middle, and end RGB values for text color
  const startColor = { r: 41, g: 191, b: 145 };
  const midColor = { r: 221, g: 107, b: 32 };
  const endColor = { r: 221, g: 32, b: 37 };

  // Start, middle, and end RGB values for background color
  const startBgColor = { r: 222, g: 241, b: 238 };
  const midBgColor = { r: 243, g: 232, b: 224 };
  const endBgColor = { r: 243, g: 224, b: 225 };

  let color, backgroundColor;

  if (normalizedValue <= 0.5) {
    // Interpolation between startColor and midColor
    const t = normalizedValue / 0.5;
    color = {
      r: Math.round(startColor.r + t * (midColor.r - startColor.r)),
      g: Math.round(startColor.g + t * (midColor.g - startColor.g)),
      b: Math.round(startColor.b + t * (midColor.b - startColor.b)),
    };
    backgroundColor = {
      r: Math.round(startBgColor.r + t * (midBgColor.r - startBgColor.r)),
      g: Math.round(startBgColor.g + t * (midBgColor.g - startBgColor.g)),
      b: Math.round(startBgColor.b + t * (midBgColor.b - startBgColor.b)),
    };
  } else {
    // Interpolation between midColor and endColor
    const t = (normalizedValue - 0.5) / 0.5;
    color = {
      r: Math.round(midColor.r + t * (endColor.r - midColor.r)),
      g: Math.round(midColor.g + t * (endColor.g - midColor.g)),
      b: Math.round(midColor.b + t * (endColor.b - midColor.b)),
    };
    backgroundColor = {
      r: Math.round(midBgColor.r + t * (endBgColor.r - midBgColor.r)),
      g: Math.round(midBgColor.g + t * (endBgColor.g - midBgColor.g)),
      b: Math.round(midBgColor.b + t * (endBgColor.b - midBgColor.b)),
    };
  }

  // Return the RGB strings
  return {
    color: `rgb(${color.r}, ${color.g}, ${color.b})`,
    backgroundColor: `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`,
  };
};

export const getColorAndBackgroundColorBySeverity = (severity) => {
  if (severity === "MILD") {
    return getColorAndBackgroundColor(0);
  } else if (severity === "MODERATE") {
    return getColorAndBackgroundColor(0.33);
  } else if (severity === "SEVERE") {
    return getColorAndBackgroundColor(0.5);
  } else if (severity === "CRITICAL") {
    return getColorAndBackgroundColor(1);
  }
};
