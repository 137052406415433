import React from "react";
import { MOUTH } from "utils/ehr-data-utils";
import "assets/scss/humanBody.scss";

const HumanMouth = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  const isSelected = selectedOrgan.includes(MOUTH);

  return (
    <div
      id="human-body-mouth"
      className={`human-mouth ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <img
        src="/images/mouth.svg"
        alt="Outer Body"
        className={`mouth hover body-part ${isSelected ? "highlight" : ""}`}
      />
    </div>
  );
};

export default HumanMouth;
