import React from "react";
import "assets/scss/humanBody.scss";
import { LUNGS } from "utils/ehr-data-utils";

const HumanLungs = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  const isSelected = selectedOrgan.includes(LUNGS);

  return (
    <div
      className={`human-body-lungs ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
      style={{ display: "flex" }}
      id="human-body-lungs"
    >
      <img
        style={{ height: "66px", width: "41px" }}
        src="/images/trachea.svg"
        alt="Outer Body"
        className={`trachea hover body-part ${isSelected ? "highlight" : ""}`}
      />
      <div className="lungs-container" style={{ display: "flex" }}>
        <img
          style={{ height: "75px", width: "42px" }}
          src="/images/rightLung.svg"
          alt="Outer Body"
          className={`right-lung hover body-part ${isSelected ? "highlight" : ""}`}
        />
        <div
          className={`${isSelected ? " lungs hover body-part highlight" : "lungs hover body-part"}`}
        >
          <img
            // style={{ height: "300px", width: "228px" }}
            src="/images/leftLung.svg"
            alt="Outer Body"
            className={`left-lung hover`}
          />
          <img
            // style={{ height: "300px", width: "228px" }}
            src="/images/leftLungLobe.svg"
            alt="Outer Body"
            className={`left-lung-lobe`}
          />
        </div>
      </div>
    </div>
  );
};

export default HumanLungs;
