import * as Yup from "yup";

export const patientGeneralInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .min(3, "Minimum 3 characters required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Minimum 2 characters allowed")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  address: Yup.string()
    .min(3, "Minimum 3 characters allowed")
    .max(255, "Maximum 255 characters allowed"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .required("Required")
    .max(255, "Maximum 255 characters allowed"),
  gender: Yup.string().required("Required"),
  height: Yup.number()
    .min(0, "Please enter valid height in cm")
    .max(250, "Please enter valid height in cm"),
  weight: Yup.number()
    .min(0, "Please enter valid weight in kg")
    .max(635, "Please enter valid weight in kg"),
  dateOfBirth: Yup.date().required("Required"),
  latitude: Yup.number()
    .min(-90, "Latitude must be greater than or equal to -90")
    .max(90, "Latitude must be less than or equal to 90"),
  longitude: Yup.number()
    .min(-180, "Longitude must be greater than or equal to -180")
    .max(180, "Longitude must be less than or equal to 180"),
});
