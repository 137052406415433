import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

const FormSelectField = ({
  label,
  name,
  onBlur,
  onChange,
  startAdornment,
  menuItems,
  error,
  value,
  style,
  outlinedInputStyle,
  inputPropStyle,
  inputProps,
  size = "big",
  background,
  border,
  borderColorOnFocus,
  disableBorder,
  noArrow,
  borderRadius = "6px",
  errorText,
  readOnly = false,
  required = false,
  defaultValue,
  disabled,
  ...restProps
}) => {
  return (
    <FormControl error={error} style={style}>
      {label && (
        <InputLabel
          id={name}
          required={required}
          shrink={Array.isArray(value) ? Boolean(value.length > 0) : Boolean(value)}
          style={{
            marginLeft: (Array.isArray(value) ? Boolean(value.length > 0) : Boolean(value))
              ? "0px"
              : "35px",
            transform:
              size === "small" &&
              !(Array.isArray(value) ? Boolean(value.length > 0) : Boolean(value))
                ? "translate(14px, 10px)"
                : undefined,
            fontSize: inputPropStyle?.fontSize ?? undefined,
            fontWeight: inputPropStyle?.fontWeight ?? undefined,
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: disableBorder ? "none" : border ? border : undefined,
            borderRadius: `${borderRadius} !important`,
          },
          "&:hover fieldset": {
            border: `${border} !important`,
          },
          "&.Mui-focused fieldset": {
            borderColor: `${borderColorOnFocus} !important`,
            transition: "0.5s ease",
          },
          "& .MuiSelect-outlined": {
            cursor: disabled ? "not-allowed" : undefined,
          },
          "& .MuiSelect-icon": {
            display: noArrow ? "none" : undefined,
          },
          background: background,
          paddingLeft: startAdornment ? "15px" : "0px",
          ...outlinedInputStyle,
        }}
        defaultValue={""}
        startAdornment={
          <InputAdornment position="start" style={{ marginRight: "0px" }}>
            {startAdornment}
          </InputAdornment>
        }
        inputProps={{
          // Pass inputProps to customize the input element
          sx: {
            paddingLeft: startAdornment ? "10px !important" : "14px !important", // Include other inputProps passed from outside
            background: background,
            cursor: readOnly ? "text" : "pointer",
            ...inputPropStyle,
          },
          ...inputProps,
        }}
        labelId={name}
        id={`for${name}`}
        label={(Array.isArray(value) ? Boolean(value.length > 0) : Boolean(value)) ? label : ""}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        size={size}
        readOnly={readOnly}
        disabled={disabled}
        {...restProps}
      >
        {menuItems?.map((row, index) => {
          return (
            <MenuItem name={name} key={index} value={row.value}>
              {row.menuLabel}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText error id={`standard-weight-helper-text-${name}`}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormSelectField;
