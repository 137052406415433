import { api as API } from "./AxiosInterceptor";

export const medicationReminderNotify = async (data) => API.post(`/api/medication-reminders`, data);

export const medicationReminderNotifyOff = async (medicationId) => {
  API.delete(`/api/medication-reminders/${medicationId}`);
};

export const getMedicationReminderByUserId = async (userId) => {
  return await API.get(`/api/medication-reminders/user/${userId}`);
};

export const updateMedicationReminder = async (medicationId, data) => {
  return await API.put(`/api/medication-reminders/${medicationId}`, data);
};
