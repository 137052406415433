import React, { useContext, useEffect, useState } from "react";
import { checkQuery, getDashboard, getAllDashboards } from "services/DashboardService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import PreviewDashboard from "../Chart/previewDashboard";
import { useParams } from "react-router";

const SuperAdminDashboard = ({ dashboardid }) => {
  const { id } = useParams();
  const [droppableAreas, setDroppableAreas] = useState([]);
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [dashboardId, setDashboardId] = useState(dashboardid ?? id);
  const { handleClick } = useContext(ToastContext);

  const initializeComponent = async () => {
    try {
      let data;
      if (dashboardId) {
        const response = await getDashboard(dashboardId);
        data = response.data;
      } else {
        throw new Error("Dashboard does not exist...Create one");
      }
      setDashboardTitle(data.name);
      const updatedAreaRows = await Promise.all(
        data.widgets.rows.map(async (row) => {
          const updatedColumns = await Promise.all(
            row.columns.map(async (column) => {
              if (column.charts.length === 0) {
                return {
                  columnId: column.columnId,
                  charts: [],
                };
              } else {
                const { name, options, query, type, widgetId } = column.charts[0];
                let queryParams = {};
                if (options && options.params) {
                  queryParams = Object.keys(options.params).reduce((acc, filter) => {
                    return { ...acc, ...options.params[filter] };
                  }, {});
                }

                const { data } = await checkQuery({
                  query,
                  params: queryParams,
                });
                const chartdata = {
                  chartType: type,
                  title: name,
                  queryText: query,
                  queryData: data,
                  widgetId,
                  options,
                };

                if (type === "Bar Chart" || type === "Line Chart") {
                  chartdata["xAxis"] = options.xAxis;
                  chartdata["yAxis"] = options.yAxis;
                }
                if (type === "Pie Chart") {
                  chartdata["Section"] = options.Section;
                  chartdata["Value"] = options.Value;
                }
                if (type === "Table") {
                  chartdata["Columns"] = options.Columns;
                }
                return {
                  columnId: column.columnId,
                  charts: [chartdata],
                };
              }
            })
          );
          row.columns = updatedColumns;
          return row;
        })
      );
      setDroppableAreas([{ id: data.widgets.screenId, rows: updatedAreaRows }]);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    initializeComponent();
  }, [dashboardId]);
  return <PreviewDashboard droppableAreas={droppableAreas} dashboardTitle={dashboardTitle} />;
};

export default SuperAdminDashboard;
