import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Card,
  Skeleton,
  Tooltip,
  tooltipClasses,
  Paper,
} from "@mui/material";
import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import { Outlet, useLocation, useNavigate } from "react-router";
import * as React from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  DAY_GRID_CALENDAR,
  MONTH_GRID_CALENDAR,
  SCHEDULED,
  currentActiveUser,
  RESCHEDULED,
  TELE_CONSULTATION,
  CLINIC_VISIT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  DATE_FORMAT,
  TIME_FORMAT,
  CHECKED_IN,
  CANCELLED,
  COMPLETED,
  PRESCRIPTION_DONE,
  NO_SHOW,
  convertTimeForTimeFormat,
  orgId,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "assets/scss/doctorAppointments.scss";
import ModalUI from "ui-component/ModalUI";
import {
  CancelOutlined,
  Close,
  DomainVerification,
  EventOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { getDoctorAvailability, getDoctorByUserId } from "services/doctorService";
import {
  updateAppointmentStatus,
  getAppointmentById,
  getFilteredAppointments,
} from "services/Appointments";
import { getBlockAvailabilities } from "services/Availability";
import { useContext } from "react";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import PatientCard from "./PatientDTO/PatientCard";
import PatientHealthDetails from "./PatientDTO/PatientHealthDetails";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCallback } from "react";
import { Menu, MenuList } from "@mui/material";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import Reveal from "views/utilities/Reveal";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Slices/userSlice";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";
import { setPrescriptionId, setPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import { SidebarUtilContext } from "layout/SidebarUtilContext";
import { styled } from "@mui/material/styles";
import { getPrescriptionByAppointmentId } from "services/PrescriptionsService";
import {
  getAppointmentEvents,
  handleMonthViewMoreClick,
  updateFilteredAppointment,
} from "utils/appointment-utils";
import { getPatientsByDoctorOrOrgId, getProfilePicture } from "services/patientService";
import EhrSummary from "./doctor-components/EhrSummary";
import { calculateAge } from "utils/calculate-age";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import advancedFormat from "dayjs/plugin/advancedFormat";
import PatientPrescription from "../Patient/PatientPrescription";
import SuperAdminDashboard from "../Dashboard/SuperAdminDashboard";
import { motion } from "framer-motion";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";

dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

const DoctorAppointmentsNew = ({ ...others }) => {
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [todaysAppointments, setTodaysAppointments] = useState(null);
  const { tabValue } = useContext(SidebarUtilContext);

  const handleFilteredAppointmentsChange = async (appointments, info, appointmentResponse) => {
    try {
      await fetchAndFilterAppointments(doctorData?.id, doctorOrgId, appointmentResponse, info);
      const sortedAppointments = [...appointments].sort(
        (a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
      );

      appointments.forEach((appointment) => {
        if (appointment.appointmentId === sortedAppointments[0].appointmentId) {
          appointment.eventSelected = true;
        } else {
          appointment.eventSelected = false;
        }
      });

      if (sortedAppointments.length > 0) {
        const appointmentDetails = await getAppointmentById(sortedAppointments[0].appointmentId);
        setSelectedAppointment(appointmentDetails.data);
        // await fetchPatientLatestVitalSigns(
        //   appointmentDetails?.data?.patientId,
        //   setPatientLatestVitals,
        //   handleClick
        // );
      } else {
        setSelectedAppointment(undefined);
      }
    } catch (error) {
      console.error("Error fetching appointment");
    }
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [doctorData, setDoctorData] = useState({});
  // eslint-disable-next-line
  const [doctorAvailability, setDoctorAvailability] = useState([]);
  const [doctorAppointments, setDoctorAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const user = currentActiveUser();
  const docId = currentActiveUser()?.roleBasedId;
  // const [patientVitals, setPatientVitals] = useState([]);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);
  const [appointmentResponse, setAppointmentResponse] = useState(null);
  const [patients, setPatients] = useState(null);

  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const [filteredDoctorEvents, setFilteredDoctorEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const [calendarView, setCalendarView] = useState(DAY_GRID_CALENDAR);
  const [viewInfo, setViewInfo] = useState(null);

  const [appointmentList, setAppointmentList] = useState([]);
  const [showAppointmentList, setShowAppointmentList] = useState(false);

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const doctorOrgId = useSelector(getUserOrgId);

  const [appointmentModalTitle, setAppointmentModalTitle] = useState(null);
  const [isReschedule, setIsRechedule] = useState(false);
  const { setAppointmentInfo } = useContext(SidebarUtilContext);

  const [selectedVitalKey, setSelectedVitalKey] = useState(null);

  const pageFlipVariants = {
    hidden: { rotateY: -180, opacity: 0 },
    enter: { rotateY: 0, opacity: 1, transition: { duration: 1.0 } },
    exit: { rotateY: 180, opacity: 0, transition: { duration: 1.0 } },
  };

  useEffect(() => {
    const fetchTodaysAppointment = async () => {
      if (doctorAppointments && tabValue === "0") {
        let patientResponse;
        try {
          patientResponse = await getPatientsByDoctorOrOrgId(doctorOrgId, docId);
          setPatients(patientResponse.data.content);
        } catch (error) {
          console.error("Error fetching patients");
        }
        const currentTime = dayjs().format(TIME_FORMAT);

        const TodaysAppointments = doctorAppointments
          .filter((appointment) =>
            [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(appointment.appointmentStatus)
          )
          .filter((appointment) =>
            dayjs(appointment.appointmentDate).isSameOrAfter(dayjs(), "day")
          );

        if (TodaysAppointments.length > 0) {
          TodaysAppointments.sort((a, b) => {
            // First, sort by date (day)
            const dateComparison = dayjs(a.appointmentDate).diff(dayjs(b.appointmentDate), "day");
            if (dateComparison !== 0) {
              return dateComparison; // If dates are different, return the result
            }

            // If the dates are the same, sort by time
            return a.appointmentTime.localeCompare(b.appointmentTime);
          });

          const upcomingAppointments = TodaysAppointments.filter(
            (appointment) => appointment.appointmentTime > currentTime
          );

          const selectedAppointment =
            upcomingAppointments.length > 0
              ? upcomingAppointments[0]
              : TodaysAppointments[TodaysAppointments.length - 1];

          try {
            const appointment = await getAppointmentById(selectedAppointment.id);
            setSelectedAppointment(appointment.data);
          } catch (error) {
            console.error("Error fetching appointment");
          }
        } else {
          setSelectedAppointment(patientResponse.data.content[0]);
        }
        setTodaysAppointments(TodaysAppointments);
      }
    };

    fetchTodaysAppointment();
  }, [doctorAppointments, tabValue]);

  const handleNewModalOpenForBookingAppointment = () => {
    const appointment = {
      doctorId: docId,
      orgId: doctorOrgId,
      appointmentDate: dayjs().format(DATE_FORMAT),
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
  };

  const handlePrescriptionClick = async (appointmentId) => {
    try {
      const response = await getPrescriptionByAppointmentId(appointmentId);
      const prescriptionId = response?.data?.id;

      const diagnosisArr = [
        ...response?.data?.prescriptionDiagnosisResponses?.map((diagnosis) => {
          return {
            ...diagnosis,
            displayName: diagnosis?.diagnosisResponse?.name,
            category: "DX",
          };
        }),
      ];
      const symptomsArr = [
        ...response?.data?.prescriptionSymptomResponses?.map((symptom) => {
          return {
            ...symptom,
            displayName: symptom?.symptomResponse?.name,
            category: "CC",
          };
        }),
      ];

      const medicationsArr = [
        ...response?.data?.prescriptionMedicationResponses?.map((medication) => {
          return {
            ...medication,
            displayName: medication?.medicationResponse?.name,
            category: "RX",
          };
        }),
      ];
      const labInvestigationsArr = [
        ...response?.data?.prescriptionLabReportResponses?.map((labTest) => {
          return {
            ...labTest,
            displayName: labTest?.labTestResponse?.name,
            category: "LAB",
          };
        }),
      ];

      const advice = response?.data?.additionalRecommendations;
      let advicesArr = [];
      if (advice !== "") {
        advicesArr = response?.data?.additionalRecommendations.split(",").map((advice) => {
          return {
            category: "ADV",
            displayName: advice,
          };
        });
      }
      dispatch(setPrescriptionId(prescriptionId));
      dispatch(
        setPrescriptionPadData({
          diagnosisArr,
          symptomsArr,
          medicationsArr,
          labInvestigationsArr,
          advicesArr,
        })
      );
      navigate(`/home/prescriptionPad/${appointmentId}`, {
        state: {
          isEditable: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNewModalOpenForReschedulingAppointment = (selectedAppointment) => {
    const appointment = {
      id: selectedAppointment?.id,
      patientMobile:
        selectedAppointment?.patient?.user?.mobileNumber ||
        selectedAppointment?.patientMobileNumber,
      patientName: selectedAppointment?.patient?.user?.name || selectedAppointment?.patientName,
      orgId: selectedAppointment?.orgId,
      appointmentTime: selectedAppointment?.appointmentTime.slice(0, -3),
      patientId: selectedAppointment?.patientId,
      doctorId: selectedAppointment?.doctorId,
      appointmentDate: selectedAppointment?.appointmentDate,
      notes: selectedAppointment?.notes,
      type: selectedAppointment?.type,
    };

    dispatch(setSelectedAppointmentData(appointment));
    setIsRechedule(true);
    setAppointmentModalTitle("Reschedule Appointment");
    setIsNewModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsNewModalOpen(false);
    parentAppointmentsRefresh(doctorData);
    dispatch(setSelectedAppointmentData({}));
    // handleGetAppointmentEvents(doctorData);
  };

  const [doctorBlockCalendar, setDoctorBlockCalendar] = useState([]);
  const [doctorBlockCalendarEvents, setDoctorBlockCalendarEvents] = useState([]);

  const formatDateTime = (date, time) => {
    return `${date}T${time}`;
  };

  const blockEventsByTime = (blockedEvents, startTimeTobeChecked, endTimeTobeChecked) => {
    while (startTimeTobeChecked <= endTimeTobeChecked) {
      const endDateTime30Min = dayjs(startTimeTobeChecked).add(30, "minute");
      blockedEvents.push({
        start: dayjs(startTimeTobeChecked),
        end: dayjs(endDateTime30Min),
        appointmentType: "Blocked",
        display: "background",
      });
      startTimeTobeChecked = `${endDateTime30Min.format(DATE_FORMAT)}T${endDateTime30Min.format(
        TIME_FORMAT
      )}`;
    }
    return blockedEvents;
  };

  const createBlockCalendarEvents = (blockedDetails) => {
    let blockedEvents = [];
    blockedDetails.forEach((blocked) => {
      let startDateTime = formatDateTime(blocked.startDate, blocked.startTime);
      const endDateTime = formatDateTime(blocked.endDate, blocked.endTime);
      if (blocked.blockingType === "CUSTOM_DATE_TIME_RANGE") {
        let startDay = dayjs(blocked.startDate).date();
        let endDay = dayjs(blocked.endDate).date();
        let dateToBeBlocked = blocked.startDate; // by default start date
        for (let index = startDay; index <= endDay; index++) {
          let startTimeForADay = formatDateTime(dateToBeBlocked, blocked.startTime);
          let endDateTimeForADay = formatDateTime(dateToBeBlocked, blocked.endTime);
          blockedEvents = blockEventsByTime(blockedEvents, startTimeForADay, endDateTimeForADay);
          dateToBeBlocked = dayjs(dateToBeBlocked).add(1, "d").format(DATE_FORMAT);
        }
      } else {
        blockedEvents = blockEventsByTime(blockedEvents, startDateTime, endDateTime);
      }
    });
    return blockedEvents;
  };

  const getBlockCalendar = async () => {
    try {
      const response = await getBlockAvailabilities(user?.roleBasedId, doctorOrgId);
      const doctorBlockedDetails = response.data.filter(
        (entry) =>
          entry.blockingType !== "SINGLE_DATE" || entry.blockingType !== "CUSTOM_DATE_TIME_RANGE"
      );
      setDoctorBlockCalendar(doctorBlockedDetails);
      const events = createBlockCalendarEvents(doctorBlockedDetails);
      setDoctorBlockCalendarEvents(events);
    } catch (error) {
      if (error.response?.status === 404) {
      } else {
        console.error("Error fetching block calendar details");
      }
    }
  };

  useEffect(() => {
    getBlockCalendar();
  }, []);

  // useEffect(() => {
  //   fetchAndFilterAppointments(doctorData?.id, doctorOrgId);
  // }, [viewInfo]);

  const handleGetAppointmentEvents = async (doctorDetail) => {
    try {
      const appointmentResponse = await getFilteredAppointments({
        doctorId: docId,
        orgId: doctorOrgId,
      });
      setAppointmentResponse(appointmentResponse);
      // await fetchAndFilterAppointments(docId, doctorOrgId, appointmentResponse);
      const appointmentInfoData = await getAppointmentEvents(
        doctorDetail,
        doctorOrgId,
        appointmentResponse
      );
      setDoctorAppointments([...appointmentInfoData.doctorAppointments]);
      setAllEvents([...appointmentInfoData.allEvents]);
      // const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
      // handleFilteredAppointmentsChange(filteredAppointments, viewInfo, appointmentResponse);
      // setFilteredDoctorEvents(filteredAppointments);
      return appointmentResponse;
    } catch (error) {
      console.error("Error fetching appointments");
    }
  };

  const parentAppointmentsRefresh = async (doctorData) => {
    if (location.pathname === "/home/dashboard") {
      try {
        const appointmentResponse = await handleGetAppointmentEvents(doctorData);
        // await fetchAndFilterAppointments(docId, doctorOrgId, appointmentResponse);
        const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
        handleFilteredAppointmentsChange(filteredAppointments, viewInfo, appointmentResponse);
        setFilteredDoctorEvents(filteredAppointments);
      } catch (error) {
        console.error("Error fethcing refreshed appointments");
      }
    }
  };

  const fetchAndFilterAppointments = async (doctorId, doctorOrgId, appointmentResponse, info) => {
    try {
      if (!appointmentResponse) {
        appointmentResponse = await getFilteredAppointments({
          doctorId: docId,
          orgId: doctorOrgId,
        });
      }

      const allowedStatuses = [SCHEDULED, RESCHEDULED, CHECKED_IN, PRESCRIPTION_DONE, COMPLETED];
      const allAppointments = appointmentResponse?.data?.content
        ?.filter((app) => allowedStatuses.includes(app.appointmentStatus))
        .filter((app) => app.appointmentDate === dayjs().format(DATE_FORMAT));

      const offline = allAppointments?.filter((app) => app.type === CLINIC_VISIT);
      const online = allAppointments?.filter((app) => app.type === TELE_CONSULTATION);
      const checkedIn = allAppointments?.filter((app) => app.appointmentStatus === CHECKED_IN);
      const prescriptionDone = allAppointments?.filter(
        (app) => app.appointmentStatus === COMPLETED || app.appointmentStatus === PRESCRIPTION_DONE
      );

      setAppointmentInfo({
        totalAppointments: allAppointments.length?.toString(),
        onlineAppointments: online?.length?.toString(),
        offlineAppointments: offline?.length?.toString(),
        checkedIn: checkedIn?.length?.toString(),
        prescriptionDone: prescriptionDone,
      });
    } catch (error) {
      console.error("Error filtering appointments", error);
      return null;
    }
  };

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const docResponse = await getDoctorByUserId(user.id);
        if (docResponse?.data) {
          const docId = docResponse?.data.id;
          setDoctorData(docResponse.data);

          const response = await getDoctorAvailability(docId, doctorOrgId);
          setDoctorAvailability(response);

          parentAppointmentsRefresh(docResponse.data);

          intervalId = setInterval(() => {
            parentAppointmentsRefresh(docResponse.data);
          }, 300000);

          // const appointmentData = await fetchAndFilterAppointments(docId, doctorOrgId);

          // if (appointmentData) {
          //   setAppointmentInfo(appointmentData);
          // }
        }
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line
  }, []);

  const eventClick = async (eventInfo) => {
    if (eventInfo.event.extendedProps.appointmentType === "Blocked") {
      return;
    }
    try {
      const appointmentId = doctorAppointments.find(
        (appointment) => appointment.id === eventInfo.event.extendedProps.appointmentId
      )?.id;

      const appointmentResponse = await getAppointmentById(appointmentId);
      setSelectedAppointment(appointmentResponse.data);
      // await fetchPatientLatestVitalSigns(
      //   appointmentResponse?.data?.patientId,
      //   setPatientLatestVitals,
      //   handleClick
      // );

      const updatedEvents = filteredDoctorEvents.map((item) => {
        if (item.appointmentId === eventInfo.event.extendedProps.appointmentId) {
          return { ...item, eventSelected: true };
        } else {
          return { ...item, eventSelected: false };
        }
      });

      setFilteredDoctorEvents(updatedEvents);
    } catch (error) {
      console.error("Error fetching appointment detail");
    }
  };
  const location = useLocation();

  const handleCancelAppointment = () => {
    handleDialogOpen();
  };

  // dialog box
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogYes = async () => {
    setOpen(false);
    // delete appointment, and fetch current appointments as events
    // todo : currently all appointments are shown, need to filter based on active/inactive once dto changed
    try {
      await updateAppointmentStatus(selectedAppointment.id, "CANCELLED");
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the appointment");
    }
    setSelectedAppointment(undefined);
    parentAppointmentsRefresh(doctorData);
  };

  const handleDialogNo = () => {
    setOpen(false);
  };

  const handleMonthViewMore = async (eventInfo) => {
    const appointmentDetails = await handleMonthViewMoreClick(eventInfo, doctorAppointments);
    setAppointmentList(appointmentDetails);
    setShowAppointmentList(true);
    setSelectedAppointment("");
  };

  const handleViewInfoClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowAppointmentList(false);
  };

  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [iframeAppointmentID, setIframeAppointmentId] = useState(null);

  const handleJoinRoom = useCallback(() => {
    try {
      if (selectedAppointment) {
        const room = selectedAppointment.id?.toString();
        navigate(`/home/prescriptionPad/${room}`, {
          state: {
            roomId: room,
          },
        });
      } else {
        handleClick("error", "Please select an appointment to join");
      }
    } catch (e) {
      handleClick("error", "Not able to join the call");
    }
  }, [selectedAppointment, navigate, handleClick]);

  const [anchorEl, setAnchorEl] = useState(null);
  const actions = [
    {
      label: "Cancel",
      icon: <CancelOutlined style={{ color: "#f5574c" }} />,
      onClick: handleCancelAppointment,
    },
    // ...([SCHEDULED, RESCHEDULED, CHECKED_IN].includes(selectedAppointment?.appointmentStatus)
    //   ? [
    //       {
    //         label: "Reschedule",
    //         icon: <EventOutlined style={{ color: "#004c70" }} />,
    //         onClick: handleNewModalOpenForReschedulingAppointment,
    //       },
    //     ]
    //   : []),
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const CustomizedToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#004c70",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      height: "2rem",
      fontSize: "1rem",
      textAlign: "center",
      color: "#fff",
      border: "0.5px solid #004c70",
      backgroundColor: "#004c70",
      display: "flex",
      alignItems: "center",
      padding: "8px",
    },
  }));

  const PatientsComponent = (
    <Reveal className="doctor-appointments" style={{ overflowX: "hidden" }}>
      <Dialog
        open={open}
        onClose={handleDialogNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" fontSize={16}>
          Cancel Patient appointment?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Appointment will be cancelled and patient will be notified.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
            No
          </Button>
          <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* <div
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "auto", padding: "0.5rem" }}
        >
          <div className="col breadcrumb-row">
            <h6 className="page-title">My Appointments</h6>
          </div>
          <div className="col">
            <ul className="horizontal-ul" style={{ marginTop: 0 }}>
              <li className="main-actions">
                <CustomButton
                  className={"mui-btn--primary"}
                  height="36px"
                  startIcon={<AddOutlined />}
                  label={"New Appointment"}
                  onClick={handleNewModalOpenForBookingAppointment}
                ></CustomButton>
              </li>
              <li className="main-actions">
                <CustomButton
                  height="36px"
                  className={"mui-btn--secondary"}
                  startIcon={<TimerSharpIcon></TimerSharpIcon>}
                  label={"Manage Availability"}
                  onClick={() => {
                    navigate("/home/doctorAvailability");
                  }}
                ></CustomButton>
              </li>
              <li className="main-actions" style={{ marginLeft: "0px" }}>
                <CustomButton
                  iconButton={<Autorenew style={{ color: "#004C70" }}></Autorenew>}
                  onClick={() => {
                    parentAppointmentsRefresh(doctorData);
                  }}
                  style={{ padding: "2px 5px", marginRight: "5px" }}
                  customBackGroundColor="#29BF911A"
                ></CustomButton>
              </li>
            </ul>
          </div>
        </div> */}
      {/* <div className="tab-bar">
          <Reveal className="mui-tabs__bar">
            {appointmentInfo &&
              [
                TOTAL,
                TEXT_FOR_CLINIC_VISIT,
                TEXT_FOR_TELECONSULTATION,
                TEXT_FOR_CHECKED_IN,
                COMPLETED,
              ].map((slab, i) => (
                <CustomTabCard
                  key={i}
                  title={slab}
                  value={getAppointmentLen(i)}
                  // isActive={activeTab === slab}
                  // onClick={() => handleTabChange(slab)}
                />
              ))}
            {user?.roleName === FRONT_DESK && (
              <CustomButton
                className="ri-add-fill ri-lg btn--secondary"
                label="New Appointment"
                // onClick={handleModalOpen}
                style={{ marginLeft: "auto", marginTop: "auto" }}
              ></CustomButton>
            )}
          </Reveal>
        </div> */}

      <div className="col justify-content-space-between align-items-center roll-content mb-0">
        {/* <Grid container sx={{ justifyContent: "start", mb: "10px" }}>
          <Grid
            item
            xs={12}
            md={5.6}
            sx={{ display: "flex", justifyContent: "end", pl: "0px !important" }}
          >
            <Grid sx={{ mr: "10px" }}>
              {!isCalendarView && (
                <CustomButton
                  startIcon={<i className="ri-list-check-line" />}
                  label={"View all"}
                  className={"btn--primary-light"}
                  onClick={() => {
                    setIsCalendarView(!isCalendarView);
                  }}
                />
              )}
            </Grid>
            <Grid>
              <CustomButton
                startIcon={<i className="ri-calendar-line" />}
                label={"Calendar view"}
                className={isCalendarView ? "btn--secondary" : "btn--secondary-light"}
                onClick={() => {
                  setIsCalendarView(!isCalendarView);
                }}
              />
            </Grid>
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={2}
          // paddingRight={"15px"}
          sx={{ height: "calc(100vh - 68px) !important", position: "absolute", width: "100%" }}
          className="mx-0 align-items-center mb-0 "
        >
          <Grid
            item
            md={4}
            xs={6}
            sx={{
              pl: "0px !important",
              pr: "15px !important",
              transition: "width 0.5s ease-in-out",
            }}
            style={{ height: "100%" }}
          >
            {/* todo : some details are static, integrate api call and fetch required data */}
            <Reveal style={{ height: "100%" }}>
              <Card
                className="custom-card-ui common-scrollbar"
                style={{ backgroundColor: "white", height: "100%", scrollbarWidth: "none" }}
              >
                <div className="row justify-content-space-between align-items-center">
                  {selectedAppointment &&
                    calendarView === MONTH_GRID_CALENDAR &&
                    appointmentList.length > 0 && (
                      <Reveal
                        className="btn back-btn"
                        onClick={() => {
                          setShowAppointmentList(true);
                          setSelectedAppointment("");
                        }}
                      >
                        <img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>
                      </Reveal>
                    )}

                  <div className="col card-title">
                    {!selectedAppointment ? "Patients List" : "Patient's Details"}
                  </div>

                  {!selectedAppointment && showAppointmentList && (
                    <div className="col">
                      <i
                        className={`ri-${
                          appointmentList[0]?.type === CLINIC_VISIT
                            ? "walk-fill"
                            : "video-chat-line"
                        }`}
                        style={{
                          width: "30px",
                          height: "30px",
                          fontSize: "20px",
                          textAlign: "center",
                          paddingTop: "5px",
                          color: appointmentList[0]?.type === CLINIC_VISIT ? "#29bf91" : "#004c70",
                        }}
                      />
                    </div>
                  )}
                  <div className="col filters">
                    {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
                      selectedAppointment?.appointmentStatus
                    ) &&
                      selectedAppointment?.patientId &&
                      location.pathname === "/home/dashboard" && (
                        <>
                          <CustomButton
                            iconButton={<i className="ri-more-2-fill" />}
                            onClick={(event) => handleMenuOpen(event)}
                          ></CustomButton>
                          <Menu
                            elevation={1}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{ style: { borderRadius: "4px" } }}
                          >
                            <MenuList dense sx={{ p: 0 }}>
                              {actions.map((action, actionIndex) => {
                                return (
                                  <CustomMenuItem
                                    key={actionIndex}
                                    text={action.label}
                                    icon={action.icon}
                                    onClick={() => {
                                      handleMenuClose();
                                      action.onClick();
                                    }}
                                  />
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </>
                      )}

                    {!selectedAppointment && showAppointmentList && (
                      <div className="heading-date">{appointmentList[0]?.appointmentDate}</div>
                    )}
                  </div>
                </div>
                {selectedAppointment && (
                  <Reveal>
                    {/* Patients basic details */}
                    <PatientCard
                      patientId={selectedAppointment?.patientId || selectedAppointment?.id}
                      patientData={selectedAppointment?.patient || selectedAppointment}
                      viewData={{
                        basicDetails: true,
                        lifeStyle: true,
                        chronicDiseases: true,
                        lastVisited: true,
                      }}
                      handleClick={handleClick}
                    ></PatientCard>
                    <Grid container className="row mb-0 mx-0 column-patient-details">
                      <Grid item md={12} className="px-0">
                        <div className="row w-100 mb-0 align-items-left flex-direction-column">
                          {selectedAppointment && (
                            <>
                              <div
                                style={{
                                  flexWrap: "wrap",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "5px",
                                }}
                              >
                                {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
                                  selectedAppointment?.appointmentStatus
                                ) &&
                                  selectedAppointment?.patientId &&
                                  location.pathname === "/home/dashboard" && (
                                    <CustomizedToolTip title={"Reschedule"} arrow>
                                      <CustomButton
                                        className={"btn--primary-light"}
                                        height="32px"
                                        label={"Reschedule"}
                                        // width={"150px"}
                                        gap={"0px"}
                                        style={{ padding: "0px 18.165px" }}
                                        startIcon={<EventOutlined />}
                                        onClick={() => {
                                          handleNewModalOpenForReschedulingAppointment(
                                            selectedAppointment
                                          );
                                        }}
                                        // style={{ padding: "0px 20px" }}
                                      />
                                    </CustomizedToolTip>
                                  )}
                                <CustomizedToolTip title={"View EHR"} arrow>
                                  <CustomButton
                                    className={"btn--primary-light"}
                                    height="32px"
                                    label={"View EHR"}
                                    style={{ padding: "0px 27.5px" }}
                                    // width={"150px"}
                                    gap={"0px"}
                                    startIcon={<VisibilityOutlined />}
                                    onClick={() => {
                                      navigate(
                                        `/home/ehr/${
                                          selectedAppointment?.patientId || selectedAppointment?.id
                                        }`
                                      );
                                    }}
                                  />
                                </CustomizedToolTip>
                                {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
                                  selectedAppointment?.appointmentStatus
                                ) ? (
                                  // <CustomButton
                                  //     className={"mui-btn--primary"}
                                  //     height="32px"
                                  //     gap={"0px"}
                                  //     label={"Edit Prescription"}
                                  //     startIcon={<i className="ri-eye-line ri-xl"></i>}
                                  //     onClick={() => {
                                  //       handlePrescriptionClick(selectedAppointment.id);
                                  //     }}
                                  //   />
                                  <>
                                    {selectedAppointment?.patientId &&
                                      location.pathname === "/home/dashboard" && (
                                        <CustomButton
                                          className={"mui-btn--primary"}
                                          height="32px"
                                          // width={"150px"}
                                          style={{ padding: "0px 14.905px" }}
                                          label={"Consult Now"}
                                          gap={"0px"}
                                          endIcon={
                                            <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                                          }
                                          onClick={() => {
                                            // handleVideoCall();
                                            dispatch(
                                              setPrescriptionPadData({
                                                diagnosisArr: [],
                                                symptomsArr: [],
                                                medicationsArr: [],
                                                labInvestigationsArr: [],
                                                advicesArr: [],
                                              })
                                            );
                                            dispatch(setClearInvoiceState());
                                            dispatch(setPrescriptionId(null));
                                            if (selectedAppointment.type === TELE_CONSULTATION) {
                                              handleJoinRoom();
                                            } else {
                                              navigate(
                                                `prescriptionPad/${selectedAppointment?.id}`
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                  </>
                                ) : (
                                  [COMPLETED, PRESCRIPTION_DONE].includes(
                                    selectedAppointment?.appointmentStatus
                                  ) &&
                                  dayjs(selectedAppointment?.appointmentDate).isAfter(
                                    dayjs().subtract(15, "day")
                                  ) &&
                                  selectedAppointment?.patientId && (
                                    <CustomButton
                                      className={"mui-btn--primary"}
                                      height="32px"
                                      gap={"0px"}
                                      label={"Edit Prescription"}
                                      startIcon={<i className="ri-eye-line ri-xl"></i>}
                                      onClick={() => {
                                        handlePrescriptionClick(selectedAppointment.id);
                                      }}
                                    />
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <PatientHealthDetails
                      selectedVitalKey={selectedVitalKey}
                      setSelectedVitalKey={setSelectedVitalKey}
                      // patientVitals={patientVitals}
                      patientId={selectedAppointment?.patientId || selectedAppointment?.id}
                      handleClick={handleClick}
                      patientAbhaId={
                        selectedAppointment?.patient?.abhaId || selectedAppointment?.abhaId
                      }
                      setIframeOpen={setIframeOpen}
                      setIframeSrc={setIframeSrc}
                      setIframeAppointmentId={setIframeAppointmentId}
                    ></PatientHealthDetails>
                  </Reveal>
                )}
                {!selectedAppointment && !showAppointmentList && (
                  <Grid>No Appointment selected...</Grid>
                )}

                {!selectedAppointment &&
                  showAppointmentList &&
                  appointmentList
                    .filter(
                      (appointment) => ![CANCELLED, NO_SHOW].includes(appointment.appointmentStatus)
                    )
                    .map((appointment, index) => (
                      <Reveal>
                        <Card key={index} className="appointment-card">
                          <PatientCard
                            patientId={appointment?.patientId || appointment?.id}
                            patientData={appointment?.patient || appointment}
                            viewData={{
                              basicDetails: true,
                              lifeStyle: true,
                              chronicDiseases: true,
                              lastVisited: true,
                            }}
                            handleClick={handleClick}
                          ></PatientCard>
                          <Grid container className="row mb-0 mx-0 column-patient-details">
                            <Grid item md={12} className="px-0">
                              <div className="row w-100 mb-0 align-items-left flex-direction-column">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "5px",
                                  }}
                                >
                                  <CustomButton
                                    className={"btn--secondary"}
                                    style={{ marginRight: "12px", height: "50px" }}
                                    label={"More Details"}
                                    startIcon={<InfoOutlinedIcon />}
                                    onClick={() => {
                                      handleViewInfoClick(appointment);
                                    }}
                                  ></CustomButton>
                                  {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
                                    appointment?.appointmentStatus
                                  ) ? (
                                    <CustomButton
                                      className={"mui-btn--primary"}
                                      style={{ height: "50px" }}
                                      label={"Consult Now"}
                                      endIcon={
                                        <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                                      }
                                      onClick={() => {
                                        dispatch(
                                          setPrescriptionPadData({
                                            diagnosisArr: [],
                                            symptomsArr: [],
                                            medicationsArr: [],
                                            labInvestigationsArr: [],
                                            advicesArr: [],
                                          })
                                        );
                                        dispatch(setPrescriptionId(null));
                                        dispatch(setClearInvoiceState());
                                        navigate(`/home/prescriptionPad/${appointment?.id}`);
                                      }}
                                    ></CustomButton>
                                  ) : (
                                    [COMPLETED, PRESCRIPTION_DONE].includes(
                                      appointment?.appointmentStatus
                                    ) &&
                                    dayjs(appointment.appointmentDate).isAfter(
                                      dayjs().subtract(15, "day")
                                    ) && (
                                      <CustomButton
                                        className={"mui-btn--primary"}
                                        style={{ height: "50px" }}
                                        label={"Edit Prescription"}
                                        startIcon={<i className="ri-eye-line ri-xl"></i>}
                                        onClick={() => {
                                          handlePrescriptionClick(appointment.id);
                                        }}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </Reveal>
                    ))}
              </Card>
            </Reveal>
          </Grid>
          <Grid
            id="prescription-clinic-ehr-details"
            item
            md={4}
            sx={{
              height: "100%",
              pl: "0px !important",
              pr: "15px !important",
              width:
                viewInfo == null || viewInfo?.view?.type === DAY_GRID_CALENDAR ? "33.33%" : "0%",
              transition: "width 0.5s ease-in-out",
            }}
          >
            <Card
              className="custom-card-ui common-scrollbar"
              style={{
                backgroundColor: "white",
                scrollbarWidth: "none",
              }}
            >
              <EhrSummary
                // setLatestVitalsGraphData={setLatestVitalsGraphData}
                selectedVitalKey={selectedVitalKey}
                key={selectedAppointment?.id}
                patientId={selectedAppointment?.patientId || selectedAppointment?.id}
              ></EhrSummary>
            </Card>
          </Grid>
          <Grid
            item
            sx={{
              height: "100%",
              pl: "12px !important",
              pr: "15px !important",
              position: "absolute",
              right: "0px",
              marginRight: "12px",
              transition: "width 0.5s ease-in-out",
              width:
                viewInfo !== null && viewInfo?.view?.type !== DAY_GRID_CALENDAR
                  ? "66.66%"
                  : "33.33%",
            }}
          >
            <Reveal style={{ height: "100%" }}>
              <motion.div
                key={location.pathname}
                initial="hidden"
                animate="enter"
                exit="exit"
                variants={pageFlipVariants}
                style={{ height: "100%" }}
              >
                <Box className="left-box" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <Outlet
                    context={{
                      patientListSharedValue: {
                        handleNewModalOpenForBookingAppointment,
                        isCalendarView,
                        setIsCalendarView,
                        calendarView,
                        setCalendarView,
                        viewInfo,
                        setViewInfo,
                        appointmentResponse,
                        setFilteredDoctorEvents,
                        allEvents,
                        filteredDoctorEvents,
                        eventClick,
                        doctorBlockCalendar,
                        doctorBlockCalendarEvents,
                        doctorData,
                        handleFilteredAppointmentsChange,
                        handleMonthViewMore,
                        setShowAppointmentList,
                        handlePrescriptionClick,
                        todaysAppointments,
                        patients,
                        selectedAppointment,
                        setSelectedAppointment,
                        parentAppointmentsRefresh,
                        handleNewModalOpenForReschedulingAppointment,
                        setAppointmentModalTitle,
                        setIsNewModalOpen,
                      },
                    }}
                  ></Outlet>
                </Box>
              </motion.div>
            </Reveal>
          </Grid>
        </Grid>

        <ModalUI
          visible={isNewModalOpen}
          close={() => {
            dispatch(setSelectedAppointmentData({}));
            setIsNewModalOpen(false);
          }}
          title={appointmentModalTitle}
          style={{
            overflowY: "auto",
            height: "550px",
            width: "610px",
          }}
          component={
            <BookAppointmentModal
              reschedule={isReschedule}
              handleClick={handleClick}
              closeModal={handleNewModalClose}
            />
          }
        />
        {/* <Fab
          onClick={handleNewModalOpenForBookingAppointment}
          variant="extended"
          sx={{
            ml: "auto",
            right: "20px",
            bottom: "20px",
            position: "absolute",
            backgroundColor: "#004c70 !important",
            color: "#FFF",
          }}
        >
          <i className="ri-add-circle-fill" style={{ fontSize: "24px", marginRight: "4px" }}></i>
          Appointment
        </Fab> */}
        {iframeOpen && (
          <div className="iframe-div">
            {iframeSrc ? (
              <iframe
                title="consent form"
                src={iframeSrc}
                target="_blank"
                className="iframe"
              ></iframe>
            ) : (
              <div className="iframe">
                <PatientPrescription appId={iframeAppointmentID} />
              </div>
            )}

            <CustomButton
              iconButton={<Close />}
              onClick={() => {
                setIframeOpen(false);
                setIframeSrc(null);
              }}
              className="iframe-close-button"
            />
          </div>
        )}
      </div>
    </Reveal>
  );

  const items = [
    {
      label: "My Patients",
      value: 0,
      component: PatientsComponent,
    },
    {
      label: "Dashboard",
      value: 1,
      component: <SuperAdminDashboard dashboardid={"5389993d-9c4b-4b62-92c9-a897f29c8e73"} />,
    },
  ];

  const [isOffline, setIsOffline] = useState(false);

  return (
    <DoctorAppointmentSkeleton>
      <Box>
        <Grid>{items[tabValue].component}</Grid>
      </Box>
    </DoctorAppointmentSkeleton>
  );
};

export default DoctorAppointmentsNew;

export const PatientAppointmentCard = ({
  item,
  selectedAppointment,
  index,
  setSelectedAppointment,
  isPatientCard,
  itemsLength,
  setAppointmentModalTitle,
  setIsNewModalOpen,
  handleNewModalOpenForReschedulingAppointment,
  parentAppointmentsRefresh,
  doctorData,
}) => {
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchProfilePic = async () => {
      setIsLoading(true);
      try {
        const profilePicResponse = await getProfilePicture(item?.patientUserId || item?.userId);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setProfileImageFileWithContent(profilePicWithContent);
      } catch (error) {
        setProfileImageFileWithContent(null);
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfilePic();
  }, [item?.patientUserId, item?.userId]);

  const handleBookAppointment = () => {
    const appointment = {
      patientMobile: item.mobileNumber,
      isUhiDoctor: false,
      orgId: orgId(),
      appointmentDate: dayjs().format(DATE_FORMAT),
      appointmentTime: null,
      patientId: item?.id,
      doctorId: currentActiveUser().roleBasedId,
      notes: "",
      type: null,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
    // setisModalOpen(true);
  };

  const actions = isPatientCard
    ? [
        {
          label: "Book Appointment",
          icon: <i className="ri-contract-line ri-xl icon-primary-blue" />,
          onClick: handleBookAppointment,
        },
      ]
    : [
        {
          label: "Cancel",
          icon: <CancelOutlined style={{ color: "#f5574c" }} />,
          onClick: async () => {
            try {
              await updateAppointmentStatus(item?.id, CANCELLED);
              parentAppointmentsRefresh(doctorData);
            } catch {
              console.error("Error cancelling appointment");
            }
          },
        },
        {
          label: "Reschedule",
          icon: <EventOutlined style={{ color: "#004c70" }} />,
          onClick: () => {
            handleNewModalOpenForReschedulingAppointment(item);
          },
        },
        {
          label: "Check-In",
          icon: <DomainVerification style={{ color: "#29bf91" }} />,
          onClick: async () => {
            try {
              await updateAppointmentStatus(item?.id, CHECKED_IN);
              // parentAppointmentsRefresh(doctorData);
            } catch {
              console.error("Error cancelling appointment");
            }
          },
        },
      ];

  return (
    <Paper
      elevation={selectedAppointment && item.id === selectedAppointment.id ? 3 : 0}
      sx={{
        border:
          selectedAppointment && item.id === selectedAppointment.id ? "" : "0.5px solid lightgray",
        borderRadius: "12px",
        p: "14px",
        mb: `${index !== itemsLength - 1 ? 10 : 0}px`,
        // mt:"10px",
        backgroundColor:
          selectedAppointment && (item.id === selectedAppointment.id ? "#e6eef1" : "#ffffff"),
      }}
    >
      <Grid
        // spacing={2}
        // container
        sx={{
          display: "flex",
          gap: "10px",
          // justifyContent: "space-between",
          borderRadius: "25px",
          // height: "40px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            cursor: "pointer",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            width: "90%",
          }}
          onClick={async () => {
            try {
              if (isPatientCard) {
                setSelectedAppointment(item);
              } else {
                const appointment = await getAppointmentById(item.id);
                setSelectedAppointment(appointment.data);
              }
            } catch (error) {
              console.error("Error fetching appointment");
            }
          }}
        >
          <Grid sx={{ width: "45px" }} display={"flex"} alignItems={"center"}>
            {isLoading ? (
              <Skeleton variant="circular" animation="wave" width={"45px"} height={"45px"} />
            ) : (
              <img
                style={{
                  borderRadius: "50%",
                  // marginRight: "6px",
                  width: "45px",
                  height: "45px",
                }}
                className="patient-avatar"
                src={
                  profileImageFileWithContent
                    ? profileImageFileWithContent.content
                    : item?.genedr === "FEMALE"
                    ? "/images/woman.png"
                    : "/images/man.png"
                }
                alt=""
              ></img>
            )}
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                mb: "0px !important",
              }}
            >
              {item?.patientName && (
                <Tooltip title={item?.patientName}>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // minWidth: "200px",
                      maxWidth: "200px", // Adjust this value as needed
                      display: "inline-block",
                    }}
                  >
                    {item?.patientName}
                  </span>
                </Tooltip>
              )}
              {item?.patientName && item?.dateOfBirth && (
                <span>
                  &nbsp;<strong>{"|"}</strong>&nbsp;
                </span>
              )}
              {item?.dateOfBirth && <span>{`${calculateAge(item?.dateOfBirth)} years`}</span>}

              {((item?.dateOfBirth && item?.gender) || (item?.patientName && item?.gender)) && (
                <span>
                  &nbsp;<strong>{"|"}</strong>&nbsp;
                </span>
              )}
              {item?.gender && <span>{item?.gender}</span>}
            </Grid>
            {item?.appointmentTime && item?.type && item.appointmentStatus && (
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  mt: "7px",
                  justifyContent: "space-between",
                }}
              >
                <Grid sx={{ width: "45%" }}>
                  {item?.type === CLINIC_VISIT ? (
                    <>
                      {/* <i
                        className="ri-walk-fill ri-xl"
                        style={{
                          width: "18px",
                          height: "18px",
                          color: "#29bf91",
                          marginRight: "5px"
                        }}
                      /> */}
                      {"In-Clinic"}
                    </>
                  ) : (
                    <>
                      {/* <i
                      className="ri-video-chat-line ri-xl"
                      style={{
                        width: "16px",
                        height: "16px",
                        color: "#004C70",
                        marginRight: "5px"
                      }}
                    /> */}
                      {"Tele-Consultation"}
                    </>
                  )}
                </Grid>
                <Grid sx={{ width: "27.5%", textAlign: "center" }}>
                  {dayjs(item?.appointmentDate).format(DATE_FORMAT) === dayjs().format(DATE_FORMAT)
                    ? "Today"
                    : dayjs(item?.appointmentDate).format(DATE_FORMAT) ===
                      dayjs().add(1, "day").format(DATE_FORMAT)
                    ? "Tomorrow"
                    : dayjs(item?.appointmentDate).format("Do MMM")}
                </Grid>
                <Grid sx={{ width: "27.5%", textAlign: "right" }}>
                  {convertTimeForTimeFormat(item?.appointmentTime)}
                </Grid>

                {/* <Grid >
                  {item?.appointmentStatus}
                </Grid> */}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid sx={{ width: "auto" }}>
          <>
            <CustomButton
              iconButton={<i className="ri-more-2-fill" />}
              onClick={(event) => handleMenuOpen(event)}
              style={{ padding: "3px" }}
            ></CustomButton>
            <Menu
              elevation={1}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{ style: { borderRadius: "4px" } }}
            >
              <MenuList dense sx={{ p: 0 }}>
                {actions.map((action, actionIndex) => {
                  return (
                    <CustomMenuItem
                      key={actionIndex}
                      text={action.label}
                      icon={action.icon}
                      onClick={() => {
                        handleMenuClose();
                        action.onClick();
                      }}
                    />
                  );
                })}
              </MenuList>
            </Menu>
          </>
        </Grid>
      </Grid>
    </Paper>
  );
};

const DoctorAppointmentSkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>

          <Grid display={"flex"} item xs={6}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="10%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={8} pr={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
          <Grid item xs={4}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
