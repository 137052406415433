import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid2";
// project imports
import AuthWrapper1 from "../AuthWrapper1";
import AuthCardWrapper from "../AuthCardWrapper";
import AuthLogin from "../auth-forms/AuthLogin";
import AuthFooter from "ui-component/cards/AuthFooter";
import SyncModal from "views/Components/Sync/SyncModal";
import { startCloudSync } from "services/SyncService";
import { useEffect } from "react";
import { useLoader } from "layout/LoaderContext";
import {
  setShowSyncModal,
  setSyncStatus,
  setIsSyncing,
  setLastSyncInfo,
  getLastSyncInfo,
} from "store/Slices/cloudSyncSlice";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { appEnv } from "store/constant";
import RIf from "ui-component/RIf";
import CustomButton from "ui-component/custom-components/CustomButton";
import { getAppDwnldLinks } from "services/AuthService";
import { useState } from "react";
// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const dispatcher = useDispatch();
  const { showLoader, hideLoader } = useLoader();
  const { handleClick: showToast } = useContext(ToastContext);
  const lastSyncInfo = useSelector(getLastSyncInfo);
  const [downloadsMenu, setDownloadsMenu] = useState([]);
  const [isDemo, setIsDemo] = useState(false);

  const isRunningOnElectron = appEnv === "electron";

  const getDwnldLinks = async () => {
    try {
      const response = await getAppDwnldLinks();
      setIsDemo(response.data.isDemo);
      setDownloadsMenu([
        { link: response.data.doctorAppLink, label: "Doctor App" },
        { link: response.data.patientAppLink, label: "Patient App" },
        { link: response.data.desktopAppWindowsLink, label: "Desktop App for Windows" },
        { link: response.data.desktopAppMacLink, label: "Desktop App for Mac" },
      ]);
    } catch (error) {
      console.error("Error fetching links: ", error);
    }
  };

  const handledownloads = async (e) => {
    const a = document.createElement("a");
    a.href = e.link;
    document.body.appendChild(a);
    a.click();
    a.target = "_blank";
    a.remove();
    window.URL.revokeObjectURL(e.link);
  };

  useEffect(() => {
    if (isRunningOnElectron) {
      showLoader();
      return () => hideLoader();
    }
    getDwnldLinks();
  }, []);

  useEffect(() => {
    if (!isRunningOnElectron) return;
    if (lastSyncInfo.push !== "loading") hideLoader();
    if (!lastSyncInfo.push || !lastSyncInfo.pull) {
      dispatcher(setShowSyncModal(true));
      syncData();
    }
  }, [lastSyncInfo]);

  const syncData = async () => {
    dispatcher(setIsSyncing(true));
    try {
      await startCloudSync();
      dispatcher(setSyncStatus("SUCCESS"));
      const lSD = dayjs();
      dispatcher(
        setLastSyncInfo({
          push: lSD.toISOString(),
          pull: lSD.toISOString(),
        })
      );
      setTimeout(() => {
        dispatcher(setShowSyncModal(false));
      }, 1500);
    } catch (e) {
      dispatcher(setSyncStatus("FAILED"));
      showToast("warning", "Sync failed, app might not function properly");
    }
    dispatcher(setIsSyncing(false));
  };

  return (
    <AuthWrapper1>
      <RIf show={isRunningOnElectron}>
        <SyncModal />
      </RIf>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: "100vh" }}>
        {downloadsMenu.length !== 0 ? (
          <Grid size={{ xs: 12 }} display="flex" justifyContent="flex-end" sx={{ mr: 0.7 }}>
            <CustomButton
              label={"Downloads"}
              style={{ marginLeft: "auto" }}
              className={"btn--secondary-light"}
              endIcon={<DownloadIcon />}
              dropDownMenuItems={downloadsMenu}
              onMenuItemClick={handledownloads}
              menuItemHoverColor="#29BF91"
            />
          </Grid>
        ) : (
          <Grid size={{ xs: 12 }} sx={{ m: 3 }}></Grid>
        )}

        <Grid size={{ xs: 12 }} sx={{ m: -1 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid>
                    <Link to="#">
                      {/* <Logo /> */}
                      <img
                        src="/images/arog-logo.svg"
                        alt="logo"
                        className="img-fluid"
                        height="100px"
                        width="160px"
                      />
                    </Link>
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? "h3" : "h2"}
                          >
                            Welcome To Arog
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={matchDownSM ? "center" : "inherit"}
                          >
                            Please Enter your details to continue
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <AuthLogin />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ m: 3, mt: 1, mb: 0, alignContent: "center" }}>
          <AuthFooter isDemo={isDemo} />
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default Login;
