// TODO: Define actions for the doctor registration process
export const SET_HPR_DATA = "SET_HPR_DATA";
export const SET_ABHA_DATA = "SET_ABHA_DATA";
export const SET_SHOW_VERIFY = "SET_SHOW_VERIFY";
export const SET_SHOW_ONBOARDING = "SET_SHOW_ONBOARDING";
export const SET_HFR_DATA = "SET_HFR_DATA";

// TODO: Implement setter method
export const setHprData = (data) => {
  return { type: SET_HPR_DATA, payload: data };
};
export const setHfrData = (data) => {
  return { type: SET_HFR_DATA, payload: data };
};
export const setAbhaData = (data) => {
  return { type: SET_ABHA_DATA, payload: data };
};
export const setShowVerify = (data) => {
  return { type: SET_SHOW_VERIFY, payload: data };
};
export const setShowOnboarding = (data) => {
  return { type: SET_SHOW_ONBOARDING, payload: data };
};
