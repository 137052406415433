export const SET_PRESCRIPTION_PAD_DATA = "SET_PRESCRIPTION_PAD_DATA";
export const SET_DIAGNOSIS = "SET_DIAGNOSIS";
export const SET_SYMPTOMS = "SET_SYMPTOMS";
export const SET_MEDICATIONS = "SET_MEDICATIONS";
export const SET_LAB_INVESTIGATIONS = "SET_LAB_INVESTIGATIONS";
export const SET_ADVICES = "SET_ADVICES";
export const SET_PRESCRIPTION_ID = "SET_PRESCRIPTION_ID";

export const setPrescriptionPadData = (data) => {
  return {
    type: SET_PRESCRIPTION_PAD_DATA,
    payload: data,
  };
};

export const setDiagnosis = (index, field, value) => {
  return {
    type: SET_DIAGNOSIS,
    payload: { index, field, value },
  };
};

export const setSymptoms = (index, field, value) => {
  return {
    type: SET_SYMPTOMS,
    payload: { index, field, value },
  };
};

export const setMedications = (index, field, value) => {
  return {
    type: SET_MEDICATIONS,
    payload: { index, field, value },
  };
};

export const setLabInvestigations = (index, field, value) => {
  return {
    type: SET_LAB_INVESTIGATIONS,
    payload: { index, field, value },
  };
};

export const setAdvices = (index, field, value) => {
  return {
    type: SET_ADVICES,
    payload: { index, field, value },
  };
};
export const setPrescriptionId = (value) => {
  return {
    type: SET_PRESCRIPTION_ID,
    payload: { value },
  };
};
