import { Box, Divider, IconButton, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect, useContext, useCallback } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateAllergy from "./UpdateAllergy";
import { allergyValidation } from "../../Common/ValidationSchema/allergyValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  entitiesInfo,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { createAllergies, deleteAllergies, getAllergies } from "services/EntitiesServices";
import Reveal from "views/utilities/Reveal";
import InfoIcon from "@mui/icons-material/Info";
import InfoModal from "../InfoModal";
import { Formik } from "formik";

const Allergy = () => {
  const [allergy, setAllergy] = useState([]);
  const [displayAllergy, setDisplayAllergy] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const [openDialog, setOpenDialog] = useState(false);
  const entityInfo = entitiesInfo.find((entity) => entity.id === "Allergy");

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllergies();
      setAllergy(response?.data);
    } catch (error) {
      console.error("Error fetching allergies");
    }
  }, []);

  const handleDelete = async (allergy) => {
    if (allergy.status === ACTIVE_STATE) {
      try {
        await deleteAllergies(allergy.id);
        handleClick("success", "Allergy has been marked Inactive!");
        fetchData();
      } catch (e) {
        handleClick("error", "There seems to be an error marking the Allergy as Inactive.");
      }
    } else {
      handleClick("info", "Allergy is already inactive!");
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const addAllergy = async (values) => {
    const payload = { name: values.allergyName, description: values.allergyDescription };

    if (allergy.some((t) => t.name.toLowerCase() === payload.name.toLowerCase())) {
      handleClick("error", "Allergy already exists");
      return;
    }

    try {
      const res = await createAllergies(payload);
      handleClick("success", "Allergy has been successfully added!");
      setAllergy([...allergy, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the allergy.");
    }
  };

  useEffect(() => {
    setDisplayAllergy(
      allergy.filter((s) => s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
    );
  }, [allergy, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "description", label: "Description" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <AllergySkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>Allergy</h2>
          {entityInfo && (
            <>
              <InfoIcon
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={() => setOpenDialog(true)}
              />
              <InfoModal
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                entityInfo={{ ...entityInfo, title: "Allergy" }}
              />
            </>
          )}
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any allergy
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "50%" }}
                label={"Search allergy"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  <IconButton onClick={clearSearch} edge="end">
                    {<ClearOutlinedIcon fontSize="small" />}
                  </IconButton>
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Add a new allergy
            </Typography>
            <Formik
              validateOnMount={true}
              initialValues={{ allergyName: "", allergyDescription: "" }}
              validationSchema={allergyValidation}
              onSubmit={async (values, { resetForm }) => {
                addAllergy(values);
                resetForm();
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid size={{ xs: 5 }} sx={{ mr: 2 }}>
                      <FormInputField
                        label={"Allergy Name"}
                        value={values.allergyName}
                        name={"allergyName"}
                        inputProps={{ maxLength: 50 }}
                        error={Boolean(touched.allergyName && errors.allergyName)}
                        errorText={errors.allergyName}
                        onChange={handleChange}
                        startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                        size="small"
                      ></FormInputField>
                    </Grid>
                    <Grid size={{ xs: 5 }} sx={{ mr: 2 }}>
                      <FormInputField
                        label={"Allergy Description"}
                        value={values.allergyDescription}
                        name={"allergyDescription"}
                        inputProps={{ maxLength: 255 }}
                        error={Boolean(touched.allergyDescription && errors.allergyDescription)}
                        errorText={errors.allergyDescription}
                        onChange={handleChange}
                        startAdornment={<DescriptionOutlinedIcon />}
                        size="small"
                      ></FormInputField>
                    </Grid>
                    <Grid sx={{ mt: 0.5 }}>
                      <CustomButton
                        className="btn--primary"
                        disabled={values.allergyName === ""}
                        onClick={handleSubmit}
                        label="Add"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>

        <CustomizedTable columns={columns} tableData={displayAllergy} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Allergy"
          component={
            <UpdateAllergy
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </AllergySkeleton>
  );
};

const AllergySkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="90%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Allergy;
