import { NavigateBefore } from "@mui/icons-material";
import { Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useContext } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { submitFacility } from "services/HfrService";
import { hprProfile, orgId } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import Reveal from "views/utilities/Reveal";

const HfrSubmit = () => {
  const { handleClick } = useContext(ToastContext);
  const state = useLocation();
  const navigate = useNavigate();
  const name = hprProfile()?.name;

  const goBack = () => {
    if (state?.state?.facilityOperationalStatus === "F") {
      navigate("/home/hfrDetailedInfo");
    } else {
      navigate("/home/hfrBasicInfo");
    }
  };

  const [checked, setChecked] = useState(false);
  const organizationId = orgId();

  return (
    <Reveal>
      <Formik
        enableReinitialize={true}
        initialValues={{
          trackingId: state?.state?.trackingId,
          sourceOfInformation: "",
          sourceUniqueID: "",
          facilitySuperUser: "",
        }}
        onSubmit={async (values) => {
          try {
            await submitFacility(organizationId, values);
            navigate("/home/HprLogin");
            handleClick("success", "Facility Info updated successfully !!");
          } catch (error) {
            handleClick("error", error.response.data.message);
          }
        }}
      >
        {({ handleSubmit }) => (
          <Box>
            <form onSubmit={handleSubmit}>
              <h2>Register Your Health Facility (HFR)</h2>

              <div className="otp-container" style={{ width: "auto" }}>
                <FormControl component="fieldset" sx={{ width: "100%", mr: 3 }}>
                  <FormControlLabel
                    sx={{ alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setChecked(e?.target?.checked);
                        }}
                        sx={{
                          marginTop: 0.5,
                        }}
                      />
                    }
                    label={
                      <Typography>
                        <p>
                          I {name}, am the applicant of the above facility, and do hereby verify
                          that the details as submitted on the portal pertaining to the above
                          facility are true to my personal knowledge and nothing material has been
                          concealed or falsely stated. I request you to kindly verify that the
                          health facility as stated actually exists and give approval to that effect
                          so that the facility can be 'validated for existence' on the portal.
                        </p>
                        <p>
                          I am aware that the facility ID and related information can be used and
                          shared with the entities working in the National Digital Health Ecosystem
                          (NDHE) which inter alia includes stakeholders and entities such as
                          healthcare professionals (e.g. doctors), facilities (e.g. hospitals,
                          laboratories) and data fiduciaries (e.g. health programmes), which are
                          registered with or linked to the Ayushman Bharat Digital Mission (ABDM),
                          and various processes there under. I reserve the right to revoke the given
                          consent at any point of time, subject to applicable laws, rules and
                          regulations.
                          <span className="required-asterisk">*</span>
                        </p>
                      </Typography>
                    }
                  />
                </FormControl>
              </div>
              <div style={{ width: "auto", marginTop: "20px", display: "flex" }}>
                <CustomButton
                  label="Previous"
                  style={{ marginRight: "auto" }}
                  className="btn--primary"
                  startIcon={<NavigateBefore />}
                  onClick={goBack}
                />
                <CustomButton
                  disabled={!checked}
                  type="submit"
                  label="Submit"
                  style={{ marginLeft: "auto" }}
                  className="btn--primary"
                />
              </div>
            </form>
          </Box>
        )}
      </Formik>
    </Reveal>
  );
};

export default HfrSubmit;
