import * as React from "react";
import { useState, useEffect } from "react";
import { Box, FormControl, Grid, Skeleton } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { Formik } from "formik";
import {
  ABHA_MOBILE_NUMBER,
  CREATE_ABHA_VIA_AADHAAR,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  UPDATE_MOBILE_NUMBER,
  VERIFY_AADHAAR_OTP,
  accessToken,
  currentActiveUser,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { AbcOutlined, FingerprintOutlined, PermIdentityOutlined } from "@mui/icons-material";
import { useLocation } from "react-router";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  generateAbdmAadhaarOtp,
  getAadhaarOtpDownload,
  getAunthentication,
  getMobileOtpDownload,
  resendAbdmAadhaarOtp,
  searchHealthIdExist,
  updateAbdmDetail,
  verifyAbdmAadhaarOtp,
} from "services/AbhaService";
import { getPatientByUserId } from "services/patientService";
import Reveal from "views/utilities/Reveal";
import { createAbhaValidation } from "views/Components/Common/ValidationSchema/createAbhaValidation";
import AbhaDetails from "./AbhaDetails";

const ManageAbhaNumber = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const { state } = useLocation();
  const [patientId, setpatientId] = useState(state?.patientId ? state.patientId : null);
  const accessTokenValue = accessToken();

  useEffect(() => {
    if (!state?.patientId) {
      const fetchData = async () => {
        try {
          const response = await getPatientByUserId(currentActiveUser().id);
          setpatientId(response.data.id);
        } catch (error) {
          handleClick("error", "GET API Issue");
        }
      };
      fetchData();
    }
  }, [accessTokenValue, handleClick, state?.patientId]);

  const [isCreateAbha, setIsCreateAbha] = useState(true);
  const handleRadioBtnChange = () => {
    setIsCreateAbha(!isCreateAbha);
    setisSelectedIndex(0);
  };

  const [firstChecked, setFirstChecked] = useState(false);
  const [secondChecked, setSecondChecked] = useState(false);
  const [isSelectedIndex, setisSelectedIndex] = useState(0);
  const [patientAbhaDetails, setPatientAbhaDetails] = useState({});

  const [mobileNumber, setMobileNumber] = useState("");
  const [txnId, settxnId] = useState("");
  const [timer, setTimer] = useState(60);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [otpSentMessage, setOtpSentMessage] = useState(null);

  useEffect(() => {
    if (isSelectedIndex === 2) {
      setTimer(60);
      setResendEnabled(false);
    }
  }, [isSelectedIndex]);

  useEffect(() => {
    let interval;
    if (!resendEnabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
    }

    return () => clearInterval(interval);
  }, [timer, resendEnabled]);

  const handleheckboxChange = (index, event) => {
    if (index === 0) {
      setFirstChecked(event.target.checked);
    } else {
      setSecondChecked(event.target.checked);
    }
  };

  const handleSubmitForGenerateAadharOtp = async (aadharNumber) => {
    const trimmedAadharNumber = aadharNumber.replace(/\s+/g, "");
    if (!trimmedAadharNumber) {
      handleClick("error", "Invalid Aadhar Number");
      return;
    }
    const data = { loginId: trimmedAadharNumber, scope: CREATE_ABHA_VIA_AADHAAR, txnId };
    try {
      const res = await generateAbdmAadhaarOtp(data);
      if (res.status === 200 && res.data) {
        settxnId(res.data.txnId);
        setOtpSentMessage(res?.data?.message);
        handleClick("success", "Otp sent successfully!");
        setisSelectedIndex(2);
      } else {
        handleClick("error", "Aadhar-OTP is not generated");
      }
    } catch (error) {
      // as per the abdm excel sheet this message is mandatory so adding this one
      if (error?.response?.data === "Invalid LoginId\n") {
        handleClick("error", "Aadhaar Number is not valid");
      } else {
        handleClick("error", error?.response?.data);
      }
    }
  };

  const handleSubmitForVerifyAadharOtp = async (otp, number) => {
    // Validate mobile number format
    if (!/^[6-9][0-9]{9}$/.test(number)) {
      handleClick("error", "Please enter a valid mobile number");
      return;
    }

    // Ensure OTP is provided
    if (!otp) {
      handleClick("error", "OTP is required");
      return;
    }

    const data = {
      scope: VERIFY_AADHAAR_OTP,
      aadhaar_otp: {
        txnId,
        otpValue: otp,
        mobile: number,
        includeAbhaAddressSuggestions: true,
        includeProfileQr: true,
      },
    };

    try {
      // Verify Aadhaar OTP
      const res = await verifyAbdmAadhaarOtp(patientId, data);

      // Update state based on API response
      setPatientAbhaDetails(res?.data);

      // Check if the mobile number in profile is null
      if (res?.data?.profile?.mobile === null) {
        // Prepare data for generating Aadhaar OTP
        const updateData = {
          loginId: number,
          scope: UPDATE_MOBILE_NUMBER,
          txnId,
        };

        // Generate Aadhaar OTP
        await generateAbdmAadhaarOtp(updateData);
        setisSelectedIndex(3);
      } else {
        handleClick("success", "User linked successfully");
        // Update UI if mobile number is not null
        setisSelectedIndex(4);
      }
    } catch (e) {
      handleClick("error", e?.response?.data?.message || "Unknown error occurred");
    }
  };

  const handleResendOtp = async () => {
    if (resendCount < 1) {
      const data = { txnId: txnId };
      try {
        const res = await resendAbdmAadhaarOtp(data);
        if (res.data) {
          settxnId(res.data.txnId);
          handleClick("success", "Otp sent successfully!");
        }
        setResendEnabled(false);
        setTimer(60);
        setResendCount((prev) => prev + 1);
      } catch (e) {
        handleClick("error", "Resend OTP process failed");
      }
    } else {
      setResendEnabled(false);
      setTimer(-1);
      handleClick("error", "You have tried maximum times for receiving the OTP!");
    }
  };

  const handleSubmitForVerifyMobileOtp = async (mobileotp) => {
    if (!mobileotp) {
      return;
    }

    const data = { txnId: txnId, scope: ABHA_MOBILE_NUMBER, updateDetail: mobileotp };
    try {
      const res = await updateAbdmDetail(data);
      if (res?.data?.mobile_number?.authResult === "success") {
        const updatedAbhaMobile = mobileNumber;
        const updatedPatientAbhaDetails = {
          ...patientAbhaDetails,
          profile: {
            ...patientAbhaDetails.profile,
            mobile: updatedAbhaMobile,
          },
        };
        // settxnId(res.data.txnId);
        setPatientAbhaDetails(updatedPatientAbhaDetails);
        handleClick("success", "Abha Created and Mobile number linked successfully");
        setisSelectedIndex(4);
      }
    } catch (e) {
      handleClick("error", "Mobile OTP Verification failed");
    }
  };

  // const handleGetAbhaCard = async () => {
  //   try {
  //     const res = await getAbhaCard(token);
  //     if (res) {
  //       //  download abha card
  //       convertBlobAndDownloadImage(res.data);
  //     }
  //   } catch (e) {
  //     handleClick("error", "Get Abha card failed");
  //   }
  // };

  const convertBlobAndDownloadImage = (data) => {
    const blob = new Blob([data], { type: "image/png" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "abha_card.png";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setisSelectedIndex(0);
  };

  const handleSearchHEALTHId = async (values) => {
    if (values.healthIdForSearch && values.mobileNumberForSearch) {
      const data = {
        healthId: values.healthIdForSearch,
        mobile: values.mobileNumberForSearch,
      };
      try {
        const res = await searchHealthIdExist(data);
        if (res) {
          setisSelectedIndex(1);
        }
      } catch (e) {
        handleClick("error", "Search health id failed");
      }
    }
  };

  const [verificationTxnId, setVerificationTxnId] = useState("");
  const handleAuthInit = async (values) => {
    const data = {
      authMethod: isMobileOtp ? "MOBILE_OTP" : "AADHAAR_OTP",
      healthid: values.healthIdForSearch,
    };
    try {
      const res = await getAunthentication(data);
      if (res) {
        setVerificationTxnId(res.data.txnId);
        setisSelectedIndex(2);
      }
    } catch (e) {
      handleClick("error", "Auth Init failed");
    }
  };

  const [isMobileOtp, setisMobileOtp] = useState(true);
  const handleRadioBtnChangeForAuthMethod = () => {
    setisMobileOtp(!isMobileOtp);
  };

  const handleVerifyAndDownload = async (otp) => {
    if (!otp) {
      return; // Early exit if OTP is empty
    }

    try {
      const data = { txnId: verificationTxnId, otp };
      const response = isMobileOtp
        ? await getMobileOtpDownload(data)
        : await getAadhaarOtpDownload(data);

      if (response) {
        await convertBlobAndDownloadImage(response.data);
      }
    } catch (error) {
      handleClick("error", "Error generating and downloading document");
    }
  };

  return (
    <PatientAbhaSkeleton>
      <Reveal>
        <div className="section-heading">
          <h2 className="page-title">Abha Creation </h2>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            aadharNumber: "",
            otp: "",
            mobileNumber: "",
            mobileotp: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            password: "",
            healthId: "",
            healthIdForSearch: "",
            mobileNumberForSearch: "",
            otpForVerification: "",
            submit: "",
          }}
          validationSchema={createAbhaValidation}
          onSubmit={async (values) => {}}
        >
          {({ values, touched, errors, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              {isCreateAbha && isSelectedIndex === 0 && (
                <FormControl sx={{ width: "30%", mr: 3 }}>
                  <FormLabel id="abhaNumberRadioBtn">Abha Number</FormLabel>
                  <RadioGroup
                    row
                    defaultValue="createAbha"
                    aria-labelledby="abhaNumberRadioBtn"
                    name="abhaNumberRadioBtn"
                    value={values.abhaNumberRadioBtn}
                    onChange={handleRadioBtnChange}
                  >
                    <FormControlLabel
                      name="abhaNumberRadioBtn"
                      value="createAbha"
                      control={<Radio />}
                      label="Create Abha Number"
                    />
                    <FormControlLabel
                      name="abhaNumberRadioBtn"
                      value="linkAbha"
                      control={<Radio />}
                      label="Get Abha"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {isCreateAbha && isSelectedIndex === 0 && (
                <>
                  <FormControlLabel
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    label="I have duly informed the beneficiary of their ABHA creation and 
                              communicated the contents of the consent applicable for the said purpose."
                    control={
                      <Checkbox
                        checked={firstChecked}
                        onChange={(e) => handleheckboxChange(0, e)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    label="I, hereby declare that I am voluntarily sharing my Aadhaar Number / Virtual ID 
                                      issued by UIDAI and demographic information, with National Health Authority 
                                      (NHA) for the sole purpose of creation of an Ayushman Bharat Health Account (ABHA (number)) ."
                    control={
                      <Checkbox
                        checked={secondChecked}
                        onChange={(e) => handleheckboxChange(1, e)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                  <div style={{ marginTop: "15px" }}>
                    <p style={{ marginTop: "15px" }}>
                      I understand that my ABHA (number) can be used and shared for purposes as may
                      be notified by Ayushman Bharat Digital Mission (ABDM) from time to time
                      including provision of healthcare services. Further, I am aware that my
                      personal identifiable information (Name, Address, Age, Date of Birth, Gender
                      and Photograph) may be made available to the entities working in the National
                      Digital Health Ecosystem (NDHE) which inter alia may include stakeholders and
                      entities such as healthcare professionals (e.g. doctors), facilities (e.g.
                      hospitals, laboratories) and data fiduciaries (e.g. health programmes), which
                      are registered with or linked to the Ayushman Bharat Digital Mission (ABDM),
                      and various processes there under.
                    </p>
                    <p style={{ marginTop: "15px" }}>
                      I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar
                      based authentication with UIDAI as per the provisions of the Aadhaar (Targeted
                      Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016
                      for the aforesaid purpose. I understand that UIDAI will share my e-KYC
                      details, or response of “Yes” with NHA upon successful authentication.
                    </p>
                    <p style={{ marginTop: "15px" }}>
                      I have been duly informed about the option of using other IDs apart from
                      Aadhaar; however, I consciously choose to use Aadhaar number / Virtual ID for
                      the purpose of availing benefits across the NDHE.
                    </p>
                    <p style={{ marginTop: "15px" }}>
                      I am aware that my personal identifiable information excluding Aadhaar number
                      / Virtual ID number can be used and shared for purposes as mentioned above. I
                      reserve the right to revoke the given consent at any point of time as per
                      provisions of Aadhar Act and Regulations.
                    </p>
                  </div>
                  <Box sx={{ mt: 4 }}>
                    <CustomButton
                      disabled={!(firstChecked && secondChecked)}
                      className="btn--primary"
                      onClick={() => setisSelectedIndex(1)}
                      label="Next"
                    />
                  </Box>
                </>
              )}
              {isCreateAbha && isSelectedIndex === 1 && (
                <Reveal>
                  <h3>An OTP will be sent to the mobile number linked to this Aadhaar number.</h3>
                  <FormInputField
                    style={{ width: "45%", marginRight: "30px" }}
                    label="Aadhar Number"
                    type={"tel"}
                    name="aadharNumber"
                    value={values.aadharNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={<PermIdentityOutlined />}
                    size={"big"}
                  />
                  <Box sx={{ mt: 4 }}>
                    <CustomButton
                      disabled={!(values.aadharNumber.toString().length === 14)}
                      className="btn--primary"
                      onClick={() => handleSubmitForGenerateAadharOtp(values.aadharNumber)}
                      label="Generate"
                    ></CustomButton>
                  </Box>
                </Reveal>
              )}
              {isCreateAbha && isSelectedIndex === 2 && (
                <Reveal>
                  <div style={{ display: "flex" }}>
                    <div>
                      <h3>Enter Aadhar Otp</h3>
                      <FormInputField
                        style={{ width: "80%", marginRight: "30px" }}
                        label="Otp"
                        type={"tel"}
                        name="otp"
                        inputProps={{
                          maxLength: 6,
                        }}
                        value={values.otp}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        startAdornment={<FingerprintOutlined />}
                        size={"big"}
                      />
                    </div>
                    <div>
                      <h3>Enter Mobile Number</h3>
                      <FormInputField
                        style={{ width: "80%", marginRight: "30px" }}
                        label="Mobile Number"
                        type={"tel"}
                        name="mobileNumber"
                        inputProps={{
                          maxLength: 10,
                        }}
                        value={values.mobileNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        startAdornment={<i className="ri-phone-line ri-xl" />}
                        size={"big"}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <span>{otpSentMessage}</span>
                  </div>
                  <Box sx={{ mt: 2 }}>
                    <CustomButton
                      disabled={!resendEnabled}
                      className="btn--primary"
                      label={`Resend Otp${
                        !resendEnabled && timer > 0 ? ` in ${timer} Seconds` : ""
                      }`}
                      onClick={() => handleResendOtp()}
                      style={{ marginRight: "20px" }}
                    ></CustomButton>
                    <CustomButton
                      disabled={values?.otp?.length !== 6}
                      className="btn--primary"
                      label="Verify"
                      onClick={() =>
                        handleSubmitForVerifyAadharOtp(values.otp, values.mobileNumber)
                      }
                    ></CustomButton>
                  </Box>
                </Reveal>
              )}
              {isCreateAbha && isSelectedIndex === 3 && (
                <Reveal>
                  <h3>Mobile Verification</h3>
                  <p>
                    Entered Mobile Number doesn't match with the number linked with Aadhaar.
                    <span style={{ fontWeight: 600 }}> Please Enter OTP to verify</span>
                  </p>

                  <FormInputField
                    style={{ width: "35%", marginRight: "30px" }}
                    label="Otp"
                    type={"tel"}
                    name="mobileotp"
                    inputProps={{
                      maxLength: 6,
                    }}
                    value={values.mobileotp}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange();
                      setMobileNumber(e.target.value);
                    }}
                    startAdornment={<FingerprintOutlined />}
                    size={"big"}
                  />
                  <Box sx={{ mt: 2 }}>
                    <CustomButton
                      disabled={values?.mobileotp?.length !== 6}
                      className="btn--primary"
                      label="Verify"
                      onClick={() => handleSubmitForVerifyMobileOtp(values.mobileotp)}
                    ></CustomButton>
                  </Box>
                </Reveal>
              )}

              {isCreateAbha && isSelectedIndex === 4 && (
                <AbhaDetails
                  patientId={patientId}
                  patientAbhaDetails={patientAbhaDetails}
                  setPatientAbhaDetails={setPatientAbhaDetails}
                  txnId={txnId}
                />
              )}

              {!isCreateAbha && isSelectedIndex === 0 && (
                <Reveal>
                  <h3>Search Health Id.</h3>
                  <FormInputField
                    style={{
                      width: "45%",
                      marginRight: "30px",
                      marginBottom: "25px",
                    }}
                    label="Health Id"
                    type="text"
                    name="healthIdForSearch"
                    value={values.healthIdForSearch}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={<AbcOutlined />}
                    size={"big"}
                  />

                  <FormInputField
                    style={{
                      width: "45%",
                      marginRight: "30px",
                      marginBottom: "25px",
                    }}
                    label="Mobile Number"
                    type={"tel"}
                    name="mobileNumberForSearch"
                    value={values.mobileNumberForSearch}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={<i className="ri-phone-line ri-xl" />}
                    size={"big"}
                    maxLength="10"
                  />
                  <Box sx={{ mt: 2 }}>
                    <CustomButton
                      disabled={
                        !(
                          values.healthIdForSearch &&
                          values.mobileNumberForSearch.toString().length === 10
                        )
                      }
                      className="btn--primary"
                      label="Search"
                      onClick={() => handleSearchHEALTHId(values)}
                    ></CustomButton>
                  </Box>
                </Reveal>
              )}
              {!isCreateAbha && isSelectedIndex === 1 && (
                <Reveal>
                  <h3>Select Method</h3>
                  <FormControl sx={{ width: "30%", mt: 3, mr: 3 }}>
                    <FormLabel id="authMethodBtn">Select Method</FormLabel>
                    <RadioGroup
                      row
                      defaultValue="mobileOtp"
                      aria-labelledby="authMethodBtn"
                      name="authMethodBtn"
                      value={values.authMethodBtn}
                      onChange={handleRadioBtnChangeForAuthMethod}
                    >
                      <FormControlLabel
                        name="authMethodBtn"
                        value="mobileOtp"
                        control={<Radio />}
                        label="Mobile Otp"
                      />
                      <FormControlLabel
                        name="authMethodBtn"
                        value="aadharOtp"
                        control={<Radio />}
                        label="Aadhar Otp"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Box sx={{ mt: 2 }}>
                    <CustomButton
                      className="btn--primary"
                      label="Next"
                      onClick={() => handleAuthInit(values)}
                    ></CustomButton>
                  </Box>
                </Reveal>
              )}
              {!isCreateAbha && isSelectedIndex === 2 && (
                <Reveal>
                  <h3>Otp details</h3>
                  <FormInputField
                    style={{
                      width: "45%",
                      marginRight: "30px",
                      marginBottom: "25px",
                    }}
                    label="OTP"
                    type={"tel"}
                    name="otpForVerification"
                    value={values.otpForVerification}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={<FingerprintOutlined />}
                    size={"big"}
                  />
                  <Box sx={{ mt: 2 }}>
                    <CustomButton
                      disabled={!values.otpForVerification}
                      className="btn--primary"
                      label="Verify & Download"
                      onClick={() => handleVerifyAndDownload(values.otpForVerification)}
                    ></CustomButton>
                  </Box>
                </Reveal>
              )}
            </form>
          )}
        </Formik>
      </Reveal>
    </PatientAbhaSkeleton>
  );
};

const PatientAbhaSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item xs={12} display={"felx"}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="30%"
            height={30}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={50}
          />
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mt: 1 }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={1.5}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageAbhaNumber;
