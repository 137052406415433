import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import ChartDisplay from "./ChartDisplay";
import Reveal from "views/utilities/Reveal";

const PreviewDashboard = ({ droppableAreas, dashboardTitle }) => {
  return (
    <Reveal className="super-admin-dashboard">
      <div className="heading" style={{ marginTop: 10 }}>
        <Typography className="dashboard-title">{dashboardTitle}</Typography>
      </div>

      {droppableAreas.map((area) => (
        <Reveal key={area.id} style={{ marginTop: "5px" }}>
          {area.rows.map((row) => {
            const validColumns = row.columns.filter(
              (column) => column.charts.length > 0 && column.charts[0].queryData.data.length > 0
            );
            const columnCount = validColumns.length;
            const columnSize = Math.floor(12 / columnCount);
            return (
              <Grid container spacing={1} flex={1} style={{ marginTop: "5px" }} key={row.rowId}>
                {row.columns.map(
                  (column) =>
                    column.charts[0] &&
                    column.charts[0].queryData.data.length !== 0 && (
                      <Grid item xs={12} sm={columnSize} md={columnSize} key={column.columnId}>
                        {column.charts.map((chart) => (
                          <Paper
                            key={chart.widgetId}
                            style={{
                              width: "100%",
                              minHeight: "100%",
                              borderRadius: "10px",
                              padding: "17px 24px",
                            }}
                          >
                            <ChartDisplay querydata={chart} />
                          </Paper>
                        ))}
                      </Grid>
                    )
                )}
              </Grid>
            );
          })}
        </Reveal>
      ))}
    </Reveal>
  );
};

export default PreviewDashboard;
