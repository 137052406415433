import React, { useState, useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CustomButton from "ui-component/custom-components/CustomButton";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const generateMonthLabels = () => {
  const labels = [];
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  for (let i = 0; i <= currentMonth; i++) {
    const date = new Date(currentYear, i);
    const monthName = date.toLocaleString("default", { month: "short" });
    labels.push(monthName);
  }
  return labels;
};

const BloodTest = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const monthLabels = useMemo(() => generateMonthLabels(), []);
  const monthData = useMemo(
    () => ({
      Jan: [65, 59, 80],
      Feb: [72, 81, 77],
      Mar: [90, 85, 70],
      Apr: [88, 95, 85],
      May: [76, 80, 88],
      Jun: [82, 90, 78],
      Jul: [75, 85, 80],
    }),
    []
  );

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  const chartData = useMemo(() => {
    const labels = selectedMonth ? ["Test 1", "Test 2", "Test 3"] : monthLabels;
    const values = selectedMonth
      ? monthData[selectedMonth] || []
      : monthLabels.map((month) => (monthData[month] ? monthData[month][0] : 0));

    return {
      labels: labels,
      datasets: [
        {
          label: "Blood Test Results",
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          data: values,
        },
      ],
    };
  }, [selectedMonth, monthLabels, monthData]);

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: selectedMonth ? "Test Number" : "Month",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Value",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  return (
    <div>
      <h2>Blood Test Line Chart</h2>
      <div style={{ width: "325px", height: "200px" }}>
        <Line data={chartData} options={options} />
      </div>
      <div className="month-name" style={{ marginBottom: "20px", display: "flex" }}>
        {monthLabels.map((month, index) => (
          <div
            key={index}
            onClick={() => handleMonthClick(month)}
            style={{
              margin: "0 5px",
              padding: "8px",
              border: "none",
              // backgroundColor: selectedMonth === month ? '#007bff' : '#e0e0e0',
              backgroundColor: selectedMonth === month ? "#007bff" : "#f2f6f8",
              // color: selectedMonth === month ? '#fff' : '#000',
              color: selectedMonth === month ? "#fff" : "#B5BBC2",
              borderRadius: "2em",
              cursor: "pointer",
            }}
          >
            {month}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BloodTest;
