import React from "react";
import { INTESTINES } from "utils/ehr-data-utils";

const HumanIntestines = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  const isSelected = selectedOrgan.includes(INTESTINES);
  return (
    <div
      id="human-body-intestines"
      className={`intestines ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <img
        src="/images/largeIntestine.svg"
        alt="Large Intestine"
        className={`large-intestine hover body-part ${isSelected ? "highlight" : ""}`}
      />
      <img
        src="/images/smallIntestine.svg"
        alt="Small Intestine"
        className={`small-intestine hover body-part ${isSelected ? "highlight" : ""}`}
      />
    </div>
  );
};

export default HumanIntestines;
