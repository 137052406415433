import {
  PatientData,
  SET_DEMO_DATA_SET,
  SET_GUIDED_DEMO_ON,
  SET_IS_DEMO,
  SET_IS_OVERLAY_OPEN,
} from "store/Actions/DemoAction";
import { isDemo, isGuidedDemoOn, isOverlayOpen } from "store/constant";

const initialState = {
  isDemo: isDemo() ?? false,
  demoDataSet: PatientData[0],
  isOverlayOpen: isOverlayOpen() ?? false,
  isGuidedDemo: isGuidedDemoOn() ?? false
};

const demoReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_IS_DEMO: {
      localStorage.setItem("isDemo", action.payload);
      return { ...state, isDemo: action.payload };
    }
    case SET_DEMO_DATA_SET: {
      localStorage.setItem("demoDataSet", JSON.stringify(action.payload));
      return { ...state, demoDataSet: action.payload };
    }
    case SET_IS_OVERLAY_OPEN: {
      localStorage.setItem("isOverlayOpen", JSON.stringify(action.payload));
      return { ...state, isOverlayOpen: action.payload };
    }
    case SET_GUIDED_DEMO_ON: {
      localStorage.setItem("isGuidedDemoOn", JSON.stringify(action.payload));
      return { ...state, isGuidedDemo: action.payload };
    }
    default:
      return state;
  }
};

export default demoReducer;

export const getIsDemo = (state) => state.demo.isDemo;

export const getDemoDataSet = (state) => state.demo.demoDataSet;

export const getIsOverlayOpen = (state) => state.demo.isOverlayOpen;

export const getIsGuidedDemoOn = (state) => state.demo.isGuidedDemo;
