import { startsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const reviewSchema = Yup.object().shape({
  title: Yup.string()
    .required("Required")
    .min(3, "Title too short")
    .max(50, "Title too long")
    .test("contains-alphabetic", "Must start with alphabet", startsWithAlphabetic),
  description: Yup.string()
    .required("Required")
    .min(3, "Description too short")
    .max(255, "Description too long")
    .test("contains-alphabetic", "Must start with alphabet", startsWithAlphabetic),
});
