import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Typography } from "@mui/material";
import {
  appointmentTypeMapper,
  CANCELLED,
  CLINIC_VISIT,
  DATE_FORMAT,
  DAY_GRID_CALENDAR,
  MONTH_GRID_CALENDAR,
  NO_SHOW,
  roleName,
  FRONT_DESK,
  TELE_CONSULTATION,
  WEEK_GRID_CALENDAR,
} from "store/constant";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";

const CustomFullCalendar = ({
  handleMonthViewMore,
  setCalendarView,
  calendarView,
  viewInfo,
  setViewInfo,
  filteredDoctorEvents,
  setFilteredDoctorEvents,
  allEvents,
  eventClick,
  blockedDays,
  slotDuration,
  blockedEvents,
  onFilteredAppointmentsChange,
  setShowAppointmentList,
  handlePrescriptionClick,
  appointmentResponse,
}) => {
  const [calendarEvents, setCalendarEvents] = useState(allEvents);
  const [currentTime, setCurrentTime] = useState(dayjs().subtract(60, "minute").format("HH:mm:ss"));
  const calendarRef = useRef();
  const [selectedDayAppointments, setSelectedDayAppointments] = useState([]);

  const isDateBlocked = (info) => {
    if (info.view.type === MONTH_GRID_CALENDAR) {
      const formattedDate = dayjs(info.date).format(DATE_FORMAT);
      return blockedDays.some(
        (blockedDay) => blockedDay.startDate <= formattedDate && blockedDay.endDate >= formattedDate
      );
    }
    return false;
  };

  const updateScrollTime = (info) => {
    if (calendarRef.current) {
      const now = dayjs();
      const startDate = dayjs(info?.start);
      const endDate = dayjs(info?.end);
      if (now.isAfter(startDate) && now.isBefore(endDate)) {
        calendarRef.current.getApi().scrollToTime(currentTime);
      } else {
        calendarRef.current.getApi().scrollToTime("08:00:00");
      }
    }
  };

  const handleDateChange = (info) => {
    updateScrollTime(info);
    const selectedStartDate = dayjs(info.start).format(DATE_FORMAT);
    const selectedEndDate = dayjs(info.end).format(DATE_FORMAT);
    let filteredAppointments = [];
    setShowAppointmentList(false);
    if (info.view.type === DAY_GRID_CALENDAR) {
      setViewInfo(info);
      setCalendarView(info.view.type);
      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) === selectedStartDate &&
          event.appointmentStatus !== CANCELLED &&
          event.appointmentStatus !== NO_SHOW
      );
    } else if (info.view.type === WEEK_GRID_CALENDAR) {
      setViewInfo(info);
      setCalendarView(info.view.type);
      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) >= selectedStartDate &&
          dayjs(event.end).format(DATE_FORMAT) < selectedEndDate &&
          event.appointmentStatus !== CANCELLED &&
          event.appointmentStatus !== NO_SHOW
      );
    } else if (info.view.type === MONTH_GRID_CALENDAR) {
      setViewInfo(info);
      setCalendarView(info.view.type);
      const monthStartDate = dayjs(info.view.currentStart).format(DATE_FORMAT);
      const monthEndDate = dayjs(info.view.currentEnd).format(DATE_FORMAT);

      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) >= monthStartDate &&
          dayjs(event.end).format(DATE_FORMAT) < monthEndDate &&
          event.appointmentStatus !== CANCELLED &&
          event.appointmentStatus !== NO_SHOW
      );

      const events = allEvents
        .filter(
          (event) => event.appointmentStatus !== CANCELLED && event.appointmentStatus !== NO_SHOW
        )
        .reduce((acc, appointment) => {
          const date = dayjs(appointment.start).format(DATE_FORMAT);

          if (!acc[date]) {
            acc[date] = {
              clinicVisitCount: 0,
              teleconsultationCount: 0,
              start: appointment.start,
              end: appointment.end,
            };
          }

          if (appointment.appointmentType === "CLINIC_VISIT") {
            acc[date].clinicVisitCount++;
          } else if (appointment.appointmentType === "TELE_CONSULTATION") {
            acc[date].teleconsultationCount++;
          }

          return acc;
        }, {});
      const eventsList = [];
      Object.keys(events).forEach((date) => {
        eventsList.push({
          start: events[date].start,
          end: events[date].end,
          appointmentType: TELE_CONSULTATION,
          count: events[date].teleconsultationCount,
        });
        eventsList.push({
          start: events[date].start,
          end: events[date].end,
          appointmentType: CLINIC_VISIT,
          count: events[date].clinicVisitCount,
        });
      });
      setCalendarEvents(eventsList);
    } else {
      setViewInfo(info);
      setCalendarView(info.view.type);
      const monthStartDate = dayjs(info.view.currentStart).format(DATE_FORMAT);
      const monthEndDate = dayjs(info.view.currentEnd).format(DATE_FORMAT);
      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) >= monthStartDate &&
          dayjs(event.end).format(DATE_FORMAT) < monthEndDate
      );
    }
    onFilteredAppointmentsChange(filteredAppointments, info, appointmentResponse);
    setFilteredDoctorEvents(filteredAppointments);
    setSelectedDayAppointments(filteredAppointments);
  };

  useEffect(() => {
    if (viewInfo) {
      handleDateChange(viewInfo);
    }
  }, [allEvents]);

  useEffect(() => {
    if (filteredDoctorEvents.length > 0) {
      setSelectedDayAppointments(filteredDoctorEvents);
    } else {
      setSelectedDayAppointments([]);
    }
  }, [filteredDoctorEvents]);

  return (
    <Reveal style={{ height: "100%", paddingBottom: "10px" }}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          end: "today prev,next",
          start: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
          center: "title",
        }}
        initialView="timeGridDay"
        allDaySlot={false}
        weekends={true}
        slotDuration={{ minute: slotDuration }}
        slotLabelInterval={{ minute: slotDuration }}
        scrollTime={currentTime}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "long",
        }}
        buttonText={{
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "All",
        }}
        slotMinTime={"00:00:00"}
        slotMaxTime={"23:59:59"}
        events={
          calendarView === MONTH_GRID_CALENDAR
            ? [...calendarEvents]
            : [...selectedDayAppointments, ...blockedEvents]
        }
        eventContent={(eventInfo) => renderEventContent(eventInfo, handlePrescriptionClick)}
        dayMaxEventRows={2}
        fixedWeekCount={false}
        views={{
          day: {},
          week: { eventMaxStack: 1 },
        }}
        height="100%"
        noEventsText="There are no appointments to show !"
        eventClick={calendarView === MONTH_GRID_CALENDAR ? handleMonthViewMore : eventClick}
        dayCellClassNames={(cellInfo) => (isDateBlocked(cellInfo) ? ["blocked_day"] : [])}
        datesSet={handleDateChange}
        eventDidMount={(info) => {
          if (info.view.type === "listWeek") {
            info.el.style.backgroundColor = info.event.extendedProps?.eventSelected
              ? "#E6EEf1"
              : "default";
          }
        }}
      />
    </Reveal>
  );
};

const renderEventContent = (eventInfo, handlePrescriptionClick) => {
  return (
    <>
      {eventInfo.view.type === MONTH_GRID_CALENDAR ? (
        <>
          {eventInfo.event.extendedProps.count > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            >
              <div
                className="appointment-count"
                style={{
                  backgroundColor:
                    eventInfo.event.extendedProps.appointmentType === CLINIC_VISIT
                      ? "#29bf91"
                      : "#004c70",
                }}
              >
                {eventInfo.event.extendedProps.count}{" "}
              </div>
              <i
                className={`ri-${
                  eventInfo.event.extendedProps.appointmentType === CLINIC_VISIT
                    ? "walk-fill"
                    : "video-chat-line"
                }`}
                style={{
                  width: "30px",
                  height: "30px",
                  fontSize: "20px",
                  textAlign: "center",
                  paddingTop: "5px",
                  color:
                    eventInfo.event.extendedProps.appointmentType === CLINIC_VISIT
                      ? "#29bf91"
                      : "#004c70",
                }}
              />
            </div>
          ) : (
            <div style={{ display: "none" }}></div>
          )}
        </>
      ) : eventInfo.view.type === "listWeek" ? (
        <div
          style={{
            display: "flex",
            padding: "5px",
          }}
        >
          {roleName() === FRONT_DESK && (
            <Typography
              variant="h6"
              className="list-patient-name"
              style={{ fontWeight: "500", fontSize: "14px", flex: "1.5" }}
            >
              Dr. {eventInfo.event.extendedProps.doctorName}
            </Typography>
          )}
          <Typography
            variant="h6"
            className="list-patient-name"
            style={{ fontWeight: "500", fontSize: "14px", flex: "2.5" }}
          >
            {eventInfo.event.title}
          </Typography>
          <div style={{ flex: "1" }}>
            {eventInfo.event.extendedProps.appointmentType === CLINIC_VISIT ? (
              <i
                className="ri-walk-fill ri-xl"
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#29bf91",
                }}
              />
            ) : (
              <i
                className="ri-video-chat-line ri-xl"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "#004C70",
                }}
              />
            )}
          </div>
          <Typography
            variant="body2"
            className="list-patient-detail"
            style={{ marginLeft: "10px", flex: "1.5" }}
          >
            {appointmentTypeMapper[eventInfo.event.extendedProps.appointmentStatus]}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            maxWidth: "100%",
          }}
        >
          {eventInfo.event.extendedProps.appointmentType === "Blocked" ? (
            <div
              style={{
                backgroundColor: "#b2c9d4",
                width: "auto",
                height: "100%",
              }}
            ></div>
          ) : (
            <div
              style={{
                display: "flex",
                backgroundColor: eventInfo.event.extendedProps?.eventSelected
                  ? "#29bf91"
                  : "#004C70",
                padding: "2px 4px",
                width: "100%",
                borderRadius: "10px",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              {eventInfo.event.extendedProps.appointmentType !== CLINIC_VISIT ? (
                <i
                  className="ri-video-chat-line"
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "white",
                  }}
                />
              ) : (
                <i
                  className="ri-walk-fill"
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "white",
                  }}
                />
              )}
              <Typography
                className="fc-patient-name"
                style={{
                  color: "white",
                  width: "150px",
                }}
              >
                {eventInfo.event.title}
              </Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomFullCalendar;
