import {
  AddOutlined,
  ClearOutlined,
  CoronavirusOutlined,
  DescriptionOutlined,
  SevereColdOutlined,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getAllergies } from "services/EntitiesServices";
import {
  deletePatientAllergy,
  editPatientAllergies,
  getPatientAllergyByPatientId,
  savePatientAllergies,
} from "services/patientService";
import { SEVERITY_TYPES, startsAndEndsWithAlphabetic, startsWithAlphabetic } from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import Reveal from "views/utilities/Reveal";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "allergyName", label: "Name" },
  { field: "displaySeverity", label: "Severity" },
  { field: "reactionDetails", label: "Reaction Details" },
  { field: "Actions", label: "Actions" },
];

const PatientAllergy = ({ patientId }) => {
  const [patientAllergies, setPatientAllergies] = useState([]);
  const [displayPatientAllergies, setDisplayPatientAllergies] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientAllergy(row.id);
      handleClick("success", "Patient Allergy deleted successfully");
      const index = patientAllergies.findIndex((el) => el.id === row.id);
      patientAllergies.splice(index, 1);
      setDisplayPatientAllergies(patientAllergies);
    } catch (error) {
      handleClick("error", "Patient Allergy deletion failed");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pAllergy) => {
    setSelected(pAllergy);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchAllergy = async () => {
    try {
      const Allergies = await getAllergies();
      setAllergies(Allergies.data);
    } catch (error) {
      handleClick("error", "Error fetching Allergies");
    }
  };

  const fetchPatientAllergies = async () => {
    try {
      const response = await getPatientAllergyByPatientId(patientId);
      const PatientAllergies = response.data.map((pAllergy) => {
        return {
          ...pAllergy,
          allergyName: pAllergy?.allergyResponse.name,
        };
      });
      setPatientAllergies(PatientAllergies);
    } catch (error) {
      handleClick("error", "Error fetching patientAllergy data");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-1x icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    if (allergies.length > 0) {
      fetchPatientAllergies();
    }
  }, [allergies]);

  useEffect(() => {
    fetchAllergy();
  }, []);

  useEffect(() => {
    setDisplayPatientAllergies(
      patientAllergies.filter((item) => {
        item["displaySeverity"] = SEVERITY_TYPES.find((e) => e.value === item.severity)?.menuLabel;
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (typeof value === "string" && index !== 5) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return false;
        });
      })
    );
  }, [patientAllergies, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search allergy"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"btn--primary"}
          startIcon={<AddOutlined />}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomizedTable
        columns={columns}
        tableData={displayPatientAllergies}
        actions={actions}
        rowsPerPageInTable={10}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Allergy" : "Create Patient Allergy"}
        component={
          <PatientAllergyModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientAllergies();
              } else {
                closeModal();
              }
            }}
            patientId={patientId}
            allergies={allergies}
          />
        }
      />
    </Reveal>
  );
};

const PatientAllergyModal = ({ allergies, patientId, selected, handleClick, close }) => {
  const [severity, setSeverity] = useState(selected?.severity ? selected?.severity : "");
  const [severityTouched, setSeverityTouched] = useState(false);

  const [reactionDetails, setReactionDetails] = useState(
    selected?.reactionDetails ? selected?.reactionDetails : ""
  );
  const [reactionDetailsTouched, setReactionDetailsTouched] = useState(false);

  const [allergy, setAllergy] = useState(null);
  const [, setNewAllergy] = useState(null);
  const [errors, setErrors] = useState({});

  const validateSeverity = (errors, severity) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!severity) {
      errors.severity = "Required";
    } else if (severity.length < 3) {
      errors.severity = "Must be at least 3 characters long";
    } else if (severity.length > 30) {
      errors.severity = "Cannot be longer than 30 characters";
    } else if (!pattern.test(severity)) {
      errors.severity = "can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(severity)) {
      errors.severity = "Name must start and end with alphabet";
    }
  };

  const validateReactionDetails = (errors, reactionDetails) => {
    if (!reactionDetails) {
      errors.reactionDetails = "Required";
    } else if (reactionDetails.length < 3) {
      errors.reactionDetails = "Must be at least 3 characters long";
    } else if (reactionDetails.length > 255) {
      errors.reactionDetails = "Cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(reactionDetails)) {
      errors.reactionDetails = "Name must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setSeverityTouched(true);
    validateSeverity(errors, severity);

    setReactionDetailsTouched(true);
    validateReactionDetails(errors, reactionDetails);

    if (!allergy) {
      errors.allergy = "Required";
    }

    return errors;
  };

  useEffect(() => {
    if (selected && allergies) {
      const Allergy = allergies.find((allergy) => {
        return allergy.id === selected.allergyId;
      });
      setAllergy(Allergy);
    }
  }, [selected, allergies]);

  const saveAllergy = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        severity,
        reactionDetails,
      };

      if (selected) {
        try {
          await editPatientAllergies(selected.id, data);
          handleClick("success", "Patient allergy updated successfully!!");
          close("save");
        } catch (error) {
          handleClick("error", "Error updating patient allergy");
        }
      } else {
        try {
          const postData = { ...data, allergyId: allergy.id };
          await savePatientAllergies(postData);
          handleClick("success", "Patient allergy created successfully!!");
          close("save");
        } catch (error) {
          handleClick("error", "Error creating allergy");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <CustomAutoComplete
            options={allergies}
            readOnly={selected}
            label={"Allergy"}
            placeholder="Select"
            iconStart={<CoronavirusOutlined />}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => {
              setAllergy(value);
              const newErrors = { ...errors };
              delete newErrors.allergy;
              setErrors(newErrors);
            }}
            required
            onInput={(event, newInputValue) => {
              setNewAllergy(newInputValue);
            }}
            value={allergy}
            error={Boolean(errors.allergy)}
            errorText={errors.allergy}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormSelectField
            style={{ width: "100%", marginTop: "7px" }}
            label="Severity"
            name="severity"
            required
            value={severity}
            onBlur={(e) => {
              setSeverityTouched(true);
              const newError = {};
              validateSeverity(newError, severity);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setSeverity(e.target.value);
              if (severityTouched) {
                const newError = {};
                validateSeverity(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.severity;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<SevereColdOutlined />}
            size={"big"}
            error={Boolean(errors.severity)}
            errorText={errors.severity}
            menuItems={SEVERITY_TYPES.filter((type) => type.menuLabel)}
          ></FormSelectField>
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Reaction Detail"
            name="reactionDetail"
            required
            value={reactionDetails}
            onBlur={(e) => {
              setReactionDetailsTouched(true);
              const newError = {};
              validateReactionDetails(newError, reactionDetails);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setReactionDetails(e.target.value);
              if (reactionDetailsTouched) {
                const newError = {};
                validateReactionDetails(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.reactionDetails;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<DescriptionOutlined />}
            size={"big"}
            error={Boolean(errors.reactionDetails)}
            errorText={errors.reactionDetails}
          />
        </Grid>
        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={saveAllergy}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientAllergy;
