const SkeletonHead = () => {
  return (
    <div className="custom-skeleton-head">
      {/* todo:image */}
      <div className="group-1">
        <div className="head-lower-teeth body-part-container">
          <img
            src="/images/human-skeleton/human-head/head-lower-teeth.svg"
            className="body-part hover"
            alt="head-lower-teeth"
          ></img>
        </div>
      </div>
      {/* todo:image */}
      <div className="group-2">
        <div className="head-upper-teeth body-part-container">
          <img
            src="/images/human-skeleton/human-head/head-upper-teeth.svg"
            className="body-part hover"
            alt="head-upper-teeth"
          ></img>
        </div>
      </div>

      <div className="subtract-1">
        <div className="head-skull body-part-container">
          <img
            src="/images/human-skeleton/human-head/head-skull.svg"
            className="body-part hover"
            alt="head-skull"
          ></img>
        </div>
      </div>
      <div className="head-lower-jaw body-part-container">
        <img
          src="/images/human-skeleton/human-head/head-lower-jaw.svg"
          className="body-part hover"
          alt="head-lower-jaw"
        ></img>
      </div>
      <div className="group-3">
        <div className="head-left-temporal-bone body-part-container">
          <img
            src="/images/human-skeleton/human-head/head-left-temporal-bone.svg"
            className="body-part hover"
            alt="head-left-temporal-bone"
          ></img>
        </div>
        <div className="head-right-temporal-bone body-part-container">
          <img
            src="/images/human-skeleton/human-head/head-right-temporal-bone.svg"
            className="body-part hover"
            alt="head-right-temporal-bone"
          ></img>
        </div>
      </div>
    </div>
  );
};
export default SkeletonHead;
