import React from "react";

function DataPrivacyHelp() {
  return (
    <div className="dp-body">
      <h1>Data Ownership & Security: Ensuring Full Control for Doctors</h1>
      <hr />
      <p>
        At Arog, we prioritize the security, privacy, and ownership of your data. With features
        tailored to put you in control, we ensure that doctors have complete authority over patient
        records, financial data, and operational information. Here's how Arog safeguards your data
        and offers flexibility while keeping security at the forefront:
      </p>
      <section>
        <h2>1. Offline Support with Selective Data Syncing</h2>
        <hr />
        <p>
          Arog is designed to support your practice, even when internet connectivity is limited. Our
          system includes offline functionality, allowing doctors to access essential patient
          records, view appointment schedules, and create prescriptions without needing a constant
          internet connection.
        </p>
        <ul>
          <li>
            <strong>Selective Data Syncing:</strong> As a doctor, you have complete control over
            which data gets synced once you're back online. You can choose to sync only specific
            patient files, prescriptions, or encounter notes, ensuring that only the necessary data
            is shared across devices, minimizing bandwidth usage and ensuring privacy over sensitive
            information. This feature guarantees that you can continue providing care without
            interruption while maintaining full control over when and how your data is synchronized.
          </li>
        </ul>
      </section>
      <section>
        <h2>2. Complete Security for Financial Data</h2>
        <hr />
        <p>
          Managing the financial aspects of your practice is as critical as delivering quality
          healthcare. Arog ensures <strong>total privacy and control over financial data</strong> by
          implementing strict security protocols:
        </p>
        <ul>
          <li>
            <strong>Encrypted Financial Transactions:</strong> Arog’s payment gateway integrations
            use advanced encryption to protect all financial transactions, ensuring that billing and
            invoicing data remain secure from unauthorized access.
          </li>
          <li>
            <strong>Role-Based Access Control:</strong> Only authorized users within your practice
            can access financial data. You have the power to grant and restrict permissions,
            ensuring that sensitive financial records are only visible to those with the proper
            clearance.
          </li>
          <li>
            <strong>Full Privacy of Financial Data:</strong> Arog does not access or control your
            financial records. All billing, invoicing, and payment details remain fully under your
            ownership, and no data is shared with third parties unless explicitly authorized by you.
          </li>
        </ul>
      </section>
      <section>
        <h2>3. Exclusive Data Control</h2>
        <hr />
        <p>
          As a healthcare provider, you own all the patient data stored and managed in Arog. The
          system is designed to give you total autonomy, ensuring no third party—including Arog
          itself—can access, modify, or share your data without your explicit consent.
        </p>
      </section>
      <section>
        <h2>4. End-to-End Data Encryption</h2>
        <hr />
        <p>
          Security is a top priority at Arog. All data—whether it’s patient health records,
          appointment details, or financial transactions—is protected by{" "}
          <strong>end-to-end encryption</strong>, both in storage and during transmission. This
          ensures that sensitive information remains secure at every stage, accessible only to you
          and authorized personnel.
        </p>
      </section>
      <section>
        <h2>5. Doctor-Patient Confidentiality & Data Ownership</h2>
        <hr />
        <p>
          We understand the importance of maintaining trust in the doctor-patient relationship. Arog
          ensures that patient data is securely handled, giving doctors full control over who can
          access and view sensitive health information. No data is shared or accessed without the
          doctor’s explicit approval, ensuring complete confidentiality.
        </p>
      </section>
      <section>
        <h2>6. Data Portability & No Vendor Lock-In</h2>
        <hr />
        <p>
          Should you choose to move to another system, Arog provides seamless{" "}
          <strong>data export capabilities</strong>, ensuring that your patient records and
          financial data are fully portable. We believe in empowering doctors with freedom—there are
          no proprietary constraints, so you can take your data with you, no questions asked.
        </p>
      </section>
    </div>
  );
}

export default DataPrivacyHelp;
