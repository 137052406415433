import axios from "axios";
import { api as API } from "./AxiosInterceptor";

export const getAllAmenities = () => API.get(`/api/amenities`);

export const getAllOrganizations = () => API.get(`/api/organizations`);

export const getOrgById = (orgId) => API.get(`/api/organizations/${orgId}`);

export const updateOrganization = (orgId, orgDto) => API.put(`/api/organizations/${orgId}`, orgDto);

export const createOrganization = (data) => API.post(`/api/organizations`, data);

export const deleteOrganization = (orgId) => API.delete(`/api/organizations/${orgId}`);

export const createDoctorLinkedToOrg = (orgId, data) =>
  API.post(`/api/organizations/${orgId}/link-doctor`, data);

export const createImageLInkedToOrg = (orgId, data) =>
  API.post(`/api/organizations/${orgId}/uploadDocuments`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getImagesByOrg = (orgId) => API.get(`/api/organizations/${orgId}/documents`);

export const deleteOrgImage = (docId) => API.delete(`/api/organizations/${docId}/delete-document`);

export const getDoctorsByOrg = (orgId) => API.get(`/api/doctors/organization/${orgId}`);

export const updateDoctorUser = (userId, userDto) => API.put(`/api/users/${userId}`, userDto);

export const deleteDoctor = (doctorId) => API.delete(`/api/doctors/${doctorId}`);

export const createAmenity = (data) => API.post(`/api/amenities`, data);

export const getStates = () => API.get(`/api/abdm/hfr/utility/states`);

export const getDistrictByState = (stateCode) =>
  API.get(`/api/abdm/hfr/utility/districts`, {
    params: { stateCode: stateCode },
  });

export const getSubDistrictByDistrict = (districtCode) =>
  API.get(`/api/abdm/hfr/utility/sub-districts`, {
    params: { districtCode: districtCode },
  });

const api = axios.create();

export const searchByPincode = (pincode) =>
  api.get(`https://api.postalpincode.in/pincode/${pincode}`);

export const fetchLocationByPincode = (pincode) => API.get(`/api/locations/pincode/${pincode}`);

export const getAmenitiesByOrgId = (orgId) => API.get(`/api/organizations/amenities/${orgId}`);
