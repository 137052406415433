import { Box, Card, CardContent, CardHeader, Popover, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditSelectField from "ui-component/custom-components/prescription-pad/EditSelectField";
import { useState } from "react";

import "assets/scss/prescriptionPad.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import EditInputField from "ui-component/custom-components/prescription-pad/EditInputField";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  setAdvices,
  setDiagnosis,
  setLabInvestigations,
  setMedications,
  setPrescriptionPadData,
  setSymptoms,
  getPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import { useDispatch, useSelector } from "react-redux";
import Reveal from "views/utilities/Reveal";
import { SEVERITY_TYPES, DOSE_TIMINGS } from "store/constant";

/* responsible for creating cards with different category, Dx, Cc, Rx, Lab, Adv */

const PrescriptionCard = ({
  cardHeaderTitle,
  values,
  onDelete,
  isEditable,
  isTeleconsultation = false,
  patientView = false,
  hideActions = false,
}) => {
  const currentPrescription = useSelector(getPrescriptionPadData);

  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const handlePopoverOpen = (event) => {
    setCopied(false);
    setOpenPopup(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenPopup(false);
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  // methods to update prescription items
  const handleDiagnosisUpdate = (index, field, value, key) => {
    dispatch(setDiagnosis(index, field, value));
  };

  const handleSymptomUpdate = (index, field, value) => {
    dispatch(setSymptoms(index, field, value));
  };
  const handleMedicationUpdate = (index, field, value, key) => {
    dispatch(setMedications(index, field, value));
  };
  const handleLabInvestigationUpdate = (index, field, value, key) => {
    dispatch(setLabInvestigations(index, field, value));
  };

  const handleAdvicesUpdate = (index, field, value, key) => {
    dispatch(setAdvices(index, field, value));
  };

  const handleCopyClicked = () => {
    switch (cardHeaderTitle.toLowerCase()) {
      case "cc":
        const updatedSymptomsArr = [...currentPrescription.symptomsArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            symptomsArr: updatedSymptomsArr,
          })
        );
        break;
      case "dx":
        const updatedDiagnosisArr = [...currentPrescription.diagnosisArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            diagnosisArr: updatedDiagnosisArr,
          })
        );
        break;
      case "rx":
        const updatedMedicationsArr = [...currentPrescription.medicationsArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            medicationsArr: updatedMedicationsArr,
          })
        );
        break;
      case "lab":
        const updatedLabInvestigationsArr = [
          ...currentPrescription.labInvestigationsArr,
          ...values,
        ];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            labInvestigationsArr: updatedLabInvestigationsArr,
          })
        );
        break;
      case "adv":
        const updatedAdvicesArr = [...currentPrescription.advicesArr, ...values];
        dispatch(
          setPrescriptionPadData({
            ...currentPrescription,
            advicesArr: updatedAdvicesArr,
          })
        );
        break;
      default:
        break;
    }
    setCopied(true);
    setTimeout(() => {
      handlePopoverClose();
    }, 2000);
  };

  return (
    values &&
    values.length > 0 && (
      <Reveal>
        <Card
          sx={{
            borderRadius: isTeleconsultation ? "6px" : "3px 3px 0 0",
            backgroundColor: isTeleconsultation ? "rgba(0, 0, 0, 0)" : "white",
          }}
        >
          {cardHeaderTitle && (
            <CardHeader
              title={cardHeaderTitle}
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.8)"
                  : isEditable
                  ? "#004C70"
                  : "#D7B975",
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
              }}
              titleTypographyProps={{ className: "cardHeaderTitle" }}
              action={
                !isEditable &&
                !hideActions && (
                  <div>
                    <IconButton onClick={handlePopoverOpen}>
                      <i
                        className="ri-more-2-fill"
                        style={{ color: isTeleconsultation ? "white" : "" }}
                      />
                    </IconButton>
                    <Popover
                      open={openPopup}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        horizontal: "right",
                      }}
                    >
                      <Box>
                        <MenuItem onClick={handleCopyClicked}>
                          {copied ? (
                            <DoneAllIcon style={{ color: "green", fontSize: 20 }} />
                          ) : (
                            "Copy"
                          )}
                        </MenuItem>
                      </Box>
                    </Popover>
                  </div>
                )
              }
            ></CardHeader>
          )}
          {cardHeaderTitle === "Dx" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div style={{}}>
                {values.map((value, index) => (
                  <Reveal
                    key={`${value.diagnosisId}${value.prescriptionId}`}
                    style={{
                      borderTop: index === 0 ? "none" : "0.5px solid",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid className="prescription-dx">
                      <Grid container>
                        <Grid size={{ md: 6 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              margin: isTeleconsultation ? "7px" : "",
                            }}
                          >
                            <span
                              className={
                                isTeleconsultation
                                  ? "cardContentFieldNameTele"
                                  : "cardContentFieldName"
                              }
                            >
                              Diagnosis
                            </span>
                            <span
                              className={`${
                                isTeleconsultation
                                  ? "cardContentFieldValueTele"
                                  : "cardContentFieldValue"
                              } item-title`}
                            >
                              {" "}
                              {value.displayName}
                            </span>
                          </div>
                        </Grid>
                        {!(!value.conclusions && patientView) && (
                          <Grid size={{ md: 6 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: isTeleconsultation ? "7px" : "",
                              }}
                            >
                              <span
                                className={
                                  isTeleconsultation
                                    ? "cardContentFieldNameTele"
                                    : "cardContentFieldName"
                                }
                              >
                                Notes
                              </span>
                              {isEditable ? (
                                <EditInputField
                                  name="conclusions"
                                  error={false}
                                  style={{ width: "100%" }}
                                  errorText="Error message"
                                  initialValue={value.conclusions || ""}
                                  index={index}
                                  field="conclusions"
                                  handleUpdate={handleDiagnosisUpdate}
                                  textLimitations={{
                                    maxLength: 100,
                                    multiline: true,
                                    rowMaxs: 3,
                                  }}
                                  isTeleconsultation={isTeleconsultation}
                                />
                              ) : (
                                <span
                                  className={
                                    isTeleconsultation
                                      ? "cardContentFieldValueTele"
                                      : "cardContentFieldValue"
                                  }
                                >
                                  {value.conclusions}
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon />}
                        style={{
                          padding: "0px",
                          marginTop: "10px",
                          height: "18px",
                          marginLeft: "10px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                  </Reveal>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Cc" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div style={{}}>
                {values.map((value, index) => (
                  <div
                    key={`${value.symptomId}${value.prescriptionId}`}
                    style={{
                      borderTop: index === 0 ? "none" : "0.5px solid",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid className="prescription-cc">
                      <Grid container>
                        <Grid size={{ md: 12 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              margin: isTeleconsultation ? "7px" : "",
                            }}
                          >
                            <span
                              className={
                                isTeleconsultation
                                  ? "cardContentFieldNameTele"
                                  : "cardContentFieldName"
                              }
                            >
                              Complaints
                            </span>
                            <span
                              className={`${
                                isTeleconsultation
                                  ? "cardContentFieldValueTele"
                                  : "cardContentFieldValue"
                              } item-title`}
                            >
                              {value.displayName}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        {!(value.since === "0 Day" && patientView) && (
                          <Grid size={{ md: 6 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: isTeleconsultation ? "7px" : "",
                              }}
                            >
                              <span
                                className={
                                  isTeleconsultation
                                    ? "cardContentFieldNameTele"
                                    : "cardContentFieldName"
                                }
                              >
                                Since
                              </span>
                              {isEditable ? (
                                <EditInputField
                                  name="since"
                                  error={false}
                                  style={{ width: "50%" }}
                                  errorText="Error message"
                                  initialValue={value.since}
                                  index={index}
                                  field="since"
                                  handleUpdate={handleSymptomUpdate}
                                  textLimitations={{
                                    maxLength: 15,
                                    multiline: false,
                                    rowMaxs: null,
                                  }}
                                  isTeleconsultation={isTeleconsultation}
                                />
                              ) : (
                                <span
                                  className={
                                    isTeleconsultation
                                      ? "cardContentFieldValueTele"
                                      : "cardContentFieldValue"
                                  }
                                >
                                  {value.since}
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                        <Grid size={{ md: 6 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              margin: isTeleconsultation ? "7px" : "",
                            }}
                          >
                            <span
                              className={
                                isTeleconsultation
                                  ? "cardContentFieldNameTele"
                                  : "cardContentFieldName"
                              }
                            >
                              Severity
                            </span>
                            <EditSelectField
                              name={"severity"}
                              style={{ width: "100%" }}
                              size={"small"}
                              initialValue={value.severity}
                              menuItems={SEVERITY_TYPES.filter((type) => type.menuLabel)}
                              isEditable={isEditable}
                              index={index}
                              field="severity"
                              handleUpdate={handleSymptomUpdate}
                              isTeleconsultation={isTeleconsultation}
                            ></EditSelectField>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon />}
                        style={{
                          padding: "0px",
                          marginTop: "10px",
                          height: "18px",
                          marginLeft: "10px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Rx" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div style={{}}>
                {values.map((value, index) => (
                  <div
                    key={`${value.medicationId}${value.prescriptionId}`}
                    style={{
                      borderTop: index === 0 ? "none" : "0.5px solid",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid className="prescription-rx">
                      <Grid container>
                        <Grid size={{ md: 6 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              margin: isTeleconsultation ? "7px" : "",
                            }}
                          >
                            <span
                              className={
                                isTeleconsultation
                                  ? "cardContentFieldNameTele"
                                  : "cardContentFieldName"
                              }
                            >
                              Name and Strength
                            </span>
                            <span
                              className={`${
                                isTeleconsultation
                                  ? "cardContentFieldValueTele"
                                  : "cardContentFieldValue"
                              } item-title`}
                            >
                              {value.displayName}
                            </span>
                          </div>
                        </Grid>
                        {!(!value.frequency && patientView) && (
                          <Grid size={{ md: 3 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: isTeleconsultation ? "7px" : "",
                              }}
                            >
                              <span
                                className={
                                  isTeleconsultation
                                    ? "cardContentFieldNameTele"
                                    : "cardContentFieldName"
                                }
                              >
                                Frequency
                              </span>
                              {isEditable ? (
                                <EditInputField
                                  name="frequency"
                                  error={false}
                                  style={{ width: "100%" }}
                                  errorText="Error message"
                                  initialValue={value.frequency || ""}
                                  index={index}
                                  field="frequency"
                                  handleUpdate={handleMedicationUpdate}
                                  textLimitations={{
                                    maxLength: 15,
                                    multiline: false,
                                    rowMaxs: null,
                                  }}
                                  isTeleconsultation={isTeleconsultation}
                                />
                              ) : (
                                <span
                                  className={
                                    isTeleconsultation
                                      ? "cardContentFieldValueTele"
                                      : "cardContentFieldValue"
                                  }
                                >
                                  {value.frequency}
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                        {!(value.duration === "0 Day" && patientView) && (
                          <Grid size={{ md: 3 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: isTeleconsultation ? "7px" : "",
                              }}
                            >
                              <span
                                className={
                                  isTeleconsultation
                                    ? "cardContentFieldNameTele"
                                    : "cardContentFieldName"
                                }
                              >
                                Duration
                              </span>
                              {isEditable ? (
                                <EditInputField
                                  name="duration"
                                  error={false}
                                  style={{ width: "100%" }}
                                  errorText="Error message"
                                  initialValue={value.duration || ""}
                                  index={index}
                                  field="duration"
                                  handleUpdate={handleMedicationUpdate}
                                  textLimitations={{
                                    maxLength: 15,
                                    multiline: false,
                                    rowMaxs: null,
                                  }}
                                  isTeleconsultation={isTeleconsultation}
                                />
                              ) : (
                                <span
                                  className={
                                    isTeleconsultation
                                      ? "cardContentFieldValueTele"
                                      : "cardContentFieldValue"
                                  }
                                >
                                  {value.duration}
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid size={{ md: 6 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              margin: isTeleconsultation ? "7px" : "",
                            }}
                          >
                            <span
                              className={
                                isTeleconsultation
                                  ? "cardContentFieldNameTele"
                                  : "cardContentFieldName"
                              }
                            >
                              Time of intake
                            </span>
                            <EditSelectField
                              name={"doseTiming"}
                              style={{ width: "100%" }}
                              size={"small"}
                              menuItems={DOSE_TIMINGS.filter((type) => type.menuLabel)}
                              isEditable={isEditable}
                              initialValue={value.doseTiming}
                              index={index}
                              field="doseTiming"
                              handleUpdate={handleMedicationUpdate}
                              isTeleconsultation={isTeleconsultation}
                            ></EditSelectField>
                          </div>
                        </Grid>
                        {!(!value.instructions && patientView) && (
                          <Grid size={{ md: 6 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: isTeleconsultation ? "7px" : "",
                              }}
                            >
                              <span
                                className={
                                  isTeleconsultation
                                    ? "cardContentFieldNameTele"
                                    : "cardContentFieldName"
                                }
                              >
                                Instructions
                              </span>
                              {isEditable ? (
                                <EditInputField
                                  name="instructions"
                                  error={false}
                                  style={{ width: "100%" }}
                                  errorText="Error message"
                                  initialValue={value.instructions || ""}
                                  index={index}
                                  field="instructions"
                                  handleUpdate={handleMedicationUpdate}
                                  textLimitations={{
                                    maxLength: 100,
                                    multiline: true,
                                    rowMaxs: 3,
                                  }}
                                  isTeleconsultation={isTeleconsultation}
                                />
                              ) : (
                                <span
                                  className={
                                    isTeleconsultation
                                      ? "cardContentFieldValueTele"
                                      : "cardContentFieldValue"
                                  }
                                >
                                  {value.instructions}
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon />}
                        style={{
                          padding: "0px",
                          marginTop: "10px",
                          height: "18px",
                          marginLeft: "10px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Lab" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div>
                {values.map((value, index) => (
                  <div
                    key={`${value.labTestId}${value.prescriptionId}`}
                    style={{
                      borderTop: index === 0 ? "none" : "0.5px solid",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid className="prescription-lab">
                      <Grid container>
                        <Grid size={{ md: 6 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              margin: isTeleconsultation ? "7px" : "",
                            }}
                          >
                            <span
                              className={
                                isTeleconsultation
                                  ? "cardContentFieldNameTele"
                                  : "cardContentFieldName"
                              }
                            >
                              Test
                            </span>
                            <span
                              className={`${
                                isTeleconsultation
                                  ? "cardContentFieldValueTele"
                                  : "cardContentFieldValue"
                              } item-title`}
                            >
                              {value.displayName}
                            </span>
                          </div>
                        </Grid>
                        {!(!value.instructions && patientView) && (
                          <Grid size={{ md: 6 }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: isTeleconsultation ? "7px" : "",
                              }}
                            >
                              <span
                                className={
                                  isTeleconsultation
                                    ? "cardContentFieldNameTele"
                                    : "cardContentFieldName"
                                }
                              >
                                Instructions
                              </span>
                              {isEditable ? (
                                <EditInputField
                                  name="test-instructions"
                                  error={false}
                                  style={{ width: "100%" }}
                                  errorText="Error message"
                                  initialValue={value.instructions || ""}
                                  index={index}
                                  field="instructions"
                                  handleUpdate={handleLabInvestigationUpdate}
                                  textLimitations={{
                                    maxLength: 100,
                                    multiline: true,
                                    rowMaxs: 3,
                                  }}
                                  isTeleconsultation={isTeleconsultation}
                                />
                              ) : (
                                <span
                                  className={
                                    isTeleconsultation
                                      ? "cardContentFieldValueTele"
                                      : "cardContentFieldValue"
                                  }
                                >
                                  {value.instructions}
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon />}
                        style={{
                          padding: "0px",
                          marginTop: "10px",
                          height: "18px",
                          marginLeft: "10px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
          {cardHeaderTitle === "Adv" && (
            <CardContent
              sx={{
                backgroundColor: isTeleconsultation
                  ? "rgba(0, 0, 0, 0.6)"
                  : isEditable
                  ? "#004C700D"
                  : "#DD6B200D",
              }}
            >
              <div>
                {values.map((value, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      marginLeft: "10px",
                      padding: 0,
                      marginBottom: "5px",
                      width: "100%",
                    }}
                  >
                    <span className="custom-marker">&#8226;</span>{" "}
                    {isEditable ? (
                      <EditInputField
                        name="advices"
                        error={false}
                        style={{ width: "100%" }}
                        fullWidth
                        errorText="Error message"
                        initialValue={value.displayName}
                        index={index}
                        field="advices"
                        handleUpdate={handleAdvicesUpdate}
                        textLimitations={{
                          maxLength: 100,
                          multiline: true,
                          rowMaxs: 3,
                        }}
                        isTeleconsultation={isTeleconsultation}
                      />
                    ) : (
                      <span
                        className={
                          isTeleconsultation ? "cardContentFieldValueTele" : "cardContentFieldValue"
                        }
                      >
                        {value.displayName}
                      </span>
                    )}
                    {isEditable && (
                      <CustomButton
                        iconButton={<CloseIcon />}
                        style={{
                          padding: "0px",
                          height: "18px",
                          marginRight: "10px",
                        }}
                        textAndIconColor="red"
                        onClick={() => {
                          onDelete(index);
                        }}
                      ></CustomButton>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          )}
        </Card>
        <br></br>
      </Reveal>
    )
  );
};

export default PrescriptionCard;
