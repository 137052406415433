import * as Yup from "yup";

export const familyMemberSchema = (isDateValid) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required("Required")
      .min(3, "Minimum 2 characters allowed")
      .max(50, "Maximum 50 characters allowed")
      .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
    middleName: Yup.string()
      .min(1, "Minimum 1 character required")
      .max(50, "Maximum 50 characters allowed")
      .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
    lastName: Yup.string()
      .required("Required")
      .min(3, "Minimum 2 characters allowed")
      .max(50, "Maximum 50 characters allowed")
      .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
    gender: Yup.string().required("Gender is required"),
    address: Yup.string()
      .min(3, "Minimum 3 characters allowed")
      .max(256, "Maximum 256 characters allowed"),
    emailId: Yup.string()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
      .required("email is required")
      .max(255, "Maximum 255 characters allowed"),
    relationship: Yup.string().required("Relationship is required"),
    ...(isDateValid && {
      mobileNumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number"),
    }),
  });

export const familyMemberSchemaForExistingUser = Yup.object().shape({
  relationship: Yup.string().required("Relationship is required"),
});
