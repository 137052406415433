import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { forwardRef } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { useCallback } from "react";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomToast = () => {
  const { handleClose, toastState } = useContext(ToastContext);

  const { open, vertical, horizontal, severity, message } = toastState;

  return (
    <Snackbar
      autoHideDuration={3000}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toastState, setToastState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "success",
    message: "",
  });

  // const handleClick = (severity, toastMessage) => {
  //   setToastState({ ...toastState, open: true, severity, message: toastMessage });
  // };

  // const handleClose = () => {
  //   setToastState({ ...toastState, open: false });
  // };

  const handleClick = useCallback((severity, toastMessage) => {
    setToastState((prevState) => ({
      ...prevState,
      open: true,
      severity,
      message: toastMessage,
    }));
  }, []);

  const handleClose = useCallback(() => {
    setToastState((prevState) => ({
      ...prevState,
      open: false,
    }));
  }, []);

  return (
    <ToastContext.Provider value={{ handleClick, handleClose, toastState }}>
      {children}
    </ToastContext.Provider>
  );
};

export default CustomToast;
