import * as Yup from "yup";

export const createAbhaValidation = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(3, "Minimum 3 characters required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .required("Middle name is required")
    .min(1, "Minimum 1 character required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(3, "Minimum 3 characters required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  healthId: Yup.string().required("healthId is required"),
  password: Yup.string().required("password is required"),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .required("email is required")
    .max(255, "Maximum 255 characters allowed"),
});
