import { useState, useEffect } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import AddAvailability from "../Appointment/AddAvailability";
import { CLINIC_VISIT, SKELETON_LOADING_TIME_IN_MILLISECONDS, orgId } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import "assets/scss/availability.scss";
import EditAvailabilityCard from "../Availability/EditAvailabilityCard";
import { getDoctorAvailability, getDoctorByOrgId } from "services/doctorService";
import { deleteAvailabilities } from "services/Availability";
import { useContext } from "react";
import { getOrgById } from "services/organizationService";
import Reveal from "views/utilities/Reveal";
import { Box, Grid, Skeleton } from "@mui/material";

const Availability = () => {
  const { handleClick } = useContext(ToastContext);
  const [availabilities, setAvailabilities] = useState([]);
  const [organizationDoctors, setOrganizationDoctors] = useState([]);
  const [open, setOpen] = useState(false);
  const [editAvailabilityData, seteditAvailabilityData] = useState({});
  const [isUpdate, setisUpdate] = useState(false);

  const [currOrg, setcurrOrg] = useState({});
  const userOrgId = orgId();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setisUpdate(false);
    seteditAvailabilityData({});
  };

  const handleDeleteAvailability = async (availability) => {
    try {
      await deleteAvailabilities(availability.id);
      handleAvailabilities();
    } catch (error) {
      handleClick("error", "Error deleting availability");
    }
  };

  const handleAvailabilities = async () => {
    try {
      const doctorListResponse = await getDoctorByOrgId(userOrgId);
      setOrganizationDoctors(doctorListResponse.data);

      const tempAvailability = await Promise.all(
        doctorListResponse.data.map(async (doctor) => {
          const availabilityResponse = await getDoctorAvailability(doctor.id);
          return availabilityResponse;
        })
      );
      setAvailabilities([].concat(...tempAvailability));
    } catch (error) {
      handleClick("error", "Error fetching availabilities.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationResponse = await getOrgById(userOrgId);
        setcurrOrg(organizationResponse.data);

        const doctorListResponse = await getDoctorByOrgId(userOrgId);
        setOrganizationDoctors(doctorListResponse.data);

        const tempAvailability = await Promise.all(
          doctorListResponse.data.map(async (doctor) => {
            const availabilityResponse = await getDoctorAvailability(doctor.id);
            return availabilityResponse;
          })
        );
        setAvailabilities([].concat(...tempAvailability));
      } catch (error) {
        handleClick("error", "Error fetching availabilities.");
      }
    };
    fetchData();
  }, [userOrgId, handleClick]);

  const getDataForEditAvailability = (availability) => {
    seteditAvailabilityData(availability);
    setisUpdate(true);
  };

  const manageAvailabilities = (doctor) => {
    let clinicVisitAvailabilities = [],
      teleconsultationAvailabilities = [];
    availabilities.forEach((element) => {
      if (element.organizationId === userOrgId && element.doctorId === doctor.id) {
        if (element.consultationType === CLINIC_VISIT) {
          clinicVisitAvailabilities.push(element);
        } else {
          teleconsultationAvailabilities.push(element);
        }
      }
    });
    return {
      clinicArr: clinicVisitAvailabilities,
      teleArr: teleconsultationAvailabilities,
    };
  };

  return (
    <AllDoctorsAvailabilitySkeleton>
      <div className="doctor-availability">
        <Reveal
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <h6 className="page-title">Doctor's Availability</h6>
          </div>
          <div className="col">
            <CustomButton
              className="ri-add-fill ri-lg btn--secondary"
              style={{ marginLeft: "10px" }}
              onClick={handleOpen}
              label="Add Availability"
            />
          </div>
        </Reveal>

        {/* card to display data in edit mode */}
        {organizationDoctors.map((doctor, index) => (
          <Reveal key={index}>
            <EditAvailabilityCard
              cardInfo={{
                name: doctor?.userResponse.name,
                address: doctor?.userResponse.address,
              }}
              handleOpen={handleOpen}
              getDataForEditAvailability={getDataForEditAvailability}
              handleDeleteAvailability={handleDeleteAvailability}
              clinicVisitAvailabilities={manageAvailabilities(doctor).clinicArr}
              teleconsultationAvailabilities={manageAvailabilities(doctor).teleArr}
            ></EditAvailabilityCard>
            <br></br>
          </Reveal>
        ))}
      </div>
      <AddAvailability
        isOpen={open}
        onClose={handleCloseModal}
        docOrganizations={[currOrg]}
        doctors={organizationDoctors}
        availabilities={availabilities}
        handleAvailabilities={handleAvailabilities}
        columns={[]}
        actionsForAvailabilities={[]}
        isUpdate={isUpdate}
        isDelete={false}
        editAvailabilityData={editAvailabilityData}
        isDoctorAdding={false}
      ></AddAvailability>
    </AllDoctorsAvailabilitySkeleton>
  );
};

const AllDoctorsAvailabilitySkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        {Array.from({ length: 5 }, (_, index) => index).map((el, index) => (
          <Grid key={index} item xs={12}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={135}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Availability;
