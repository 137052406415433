import { useState } from "react";
import { useEffect } from "react";
import {
  getEhrData,
  getEhrSummary,
  getMedicationsByPatientId,
} from "services/PrescriptionsService";
import { getChartByVitalType, getEhrDataPoints } from "utils/ehr-data-utils";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";
import PropTypes from "prop-types";
import Reveal from "views/utilities/Reveal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import {
  getPatientAllergyByPatientId,
  getPatientPastProceduresByPatientId,
} from "services/patientService";
import Grid from "@mui/material/Grid2";
import { api } from "services/AxiosInterceptor";
import { appEnv } from "store/constant";
import { getIsOnline } from "store/Slices/cloudSyncSlice";
import { useSelector } from "react-redux";
import axios from "axios";

const EhrSummary = ({ patientId, selectedVitalKey }) => {
  const [ehrSummary, setEhrSummary] = useState("");
  const [ehrData, setEhrData] = useState(null);
  const [loading, setLoading] = useState(true);
  const isOnline = useSelector(getIsOnline);

  const isRunningOnElectron = appEnv === "electron";

  useEffect(() => {
    const VitalArray = [
      "BODY_TEMPERATURE",
      "BLOOD_PRESSURE",
      "BLOOD_GLUCOSE",
      // "BODY_MASS_INDEX",
      "BLOOD_OXYGEN",
      "HEART_RATE",
      "RESPIRATORY_RATE",
    ];

    const fetchData = async () => {
      setLoading(true);

      try {
        if (patientId) {
          try {
            const ehrSummaryResponse = await getEhrSummary(patientId);
            if (!ehrSummaryResponse) {
              setEhrSummary(null);
            }
            const indexOfFirstDiv = ehrSummaryResponse.data.indexOf("<div>");
            const indexOflastDiv = ehrSummaryResponse.data.lastIndexOf("</div>") + 5;

            setEhrSummary(ehrSummaryResponse.data.substring(indexOfFirstDiv, indexOflastDiv + 1));
          } catch {
            console.error("Error fetching EHR summary");
          }

          const ehrResponse = await getEhrData(patientId);

          const ehrDataPoints = getEhrDataPoints(ehrResponse.data);

          let newVitalData = ehrDataPoints.filter((d) => VitalArray.includes(d.key));
          // const latestVitalsValue = getLatestVitals(newVitalData);
          // const latestVitalsGraphData = newVitalData.map(item1 => {
          //   // Find the matching object from arr2 by key
          //   const matchingItem = latestVitalsValue.find(item2 => item2.key === item1.key);

          //   if (matchingItem) {
          //     return { ...item1, ...matchingItem };
          //   }
          //   return null;
          // });

          setEhrData(newVitalData);
        }
      } catch (error) {
        console.error("Error fetching details");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [patientId, isOnline]);

  const [activeMedications, setActiveMedications] = useState([]);
  const [pastProcedures, setPastProcedures] = useState([]);
  const [allergies, setAllergies] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (patientId) {
          const activeMedicationResponse = await getMedicationsByPatientId(patientId);
          setActiveMedications(activeMedicationResponse.data);

          const pastProcedureResponse = await getPatientPastProceduresByPatientId(patientId);
          setPastProcedures(pastProcedureResponse.data);

          const allergyResponse = await getPatientAllergyByPatientId(patientId);
          setAllergies(allergyResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data");
      }
    };

    fetchData();
  }, [patientId]);

  if (loading) {
    return <ComponentLoader></ComponentLoader>;
  }
  return (
    <div className="justify-content-space-between align-items-center">
      <div
        style={{
          display: "flex",
          paddingLeft: "0px",
          minHeight: "34px",
          marginBottom: "10px",
          alignItems: "center",
        }}
        className="card-title"
      >
        EHR Summary
      </div>
      <Grid container spacing={1} sx={{ alignItems: "center" }}>
        {ehrSummary && (
          <Grid sx={{ minHeight: "250px", maxHeight: "250px" }} size={{ md: 6 }}>
            <div
              style={{ padding: "0px 15px 15px 20px" }}
              dangerouslySetInnerHTML={{ __html: ehrSummary }}
            ></div>
          </Grid>
        )}
        {isRunningOnElectron && !isOnline && !ehrSummary && (
          <Grid sx={{ minHeight: "250px", maxHeight: "250px" }} size={{ md: 6 }}>
            <div style={{ padding: "0px 15px 15px 20px" }}>
              Ehr Summery no available, please check your network connection and retry.
            </div>
          </Grid>
        )}

        {allergies?.length > 0 && (
          <Grid sx={{ minHeight: "250px", maxHeight: "250px" }} size={{ md: 6 }}>
            <Accordion
              defaultExpanded
              sx={{
                margin: "0px !important",
                ":: before": { opacity: 0 },
                borderRadius: "8px",
                // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px !important",
              }}
            >
              <AccordionSummary
                sx={{
                  minHeight: "30px !important",
                  "& .MuiAccordionSummary-content": {
                    margin: "0px !important",
                    justifyContent: "center",
                  },
                  borderRadius: "8px",
                  "&.Mui-expanded": {
                    borderRadius: "8px 8px 0px 0px",
                  },
                  padding: "8px 16px",
                  background: "#e6eef1",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography className={"about-title"}>Allergies</Typography>
              </AccordionSummary>
              <AccordionDetails
                className="common-scrollbar"
                sx={{
                  p: "7px  5px",
                  backgroundColor: "#f2f6f8",
                  borderRadius: "0px 0px 8px 8px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {allergies?.map((item, index) => (
                  <Card
                    sx={{
                      fontSize: "12px",
                      mb: index !== allergies.length - 1 ? "6px" : "0px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent sx={{ padding: "8px 0px" }}>
                      <div
                        className="w-100"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginRight: "8px" }}>{item?.allergyResponse?.name}</div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {pastProcedures?.length > 0 && (
          <Grid sx={{ minHeight: "250px", maxHeight: "250px" }} size={{ md: 6 }}>
            <Accordion
              defaultExpanded
              sx={{
                margin: "0px !important",
                ":: before": { opacity: 0 },
                borderRadius: "8px",
                // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px !important",
              }}
            >
              <AccordionSummary
                sx={{
                  minHeight: "30px !important",
                  "& .MuiAccordionSummary-content": {
                    margin: "0px !important",
                    justifyContent: "center",
                  },
                  borderRadius: "8px",
                  "&.Mui-expanded": {
                    borderRadius: "8px 8px 0px 0px",
                  },
                  padding: "8px 16px",
                  background: "#e6eef1",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography className={"about-title"}>Past Procedures</Typography>
              </AccordionSummary>
              <AccordionDetails
                className="common-scrollbar"
                sx={{
                  p: "7px 5px",
                  backgroundColor: "#f2f6f8",
                  borderRadius: "0px 0px 8px 8px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {pastProcedures?.map((item, index) => (
                  <Card
                    sx={{
                      fontSize: "12px",
                      mb: index !== pastProcedures.length - 1 ? "6px" : "0px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent sx={{ padding: "8px 0px" }}>
                      <div
                        className="w-100"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginRight: "8px" }}>{item?.procedureResponse.name}</div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {activeMedications?.length > 0 && (
          <Grid sx={{ minHeight: "250px", maxHeight: "250px" }} size={{ md: 6 }}>
            <Accordion
              defaultExpanded
              sx={{
                margin: "0px !important",
                ":: before": { opacity: 0 },
                borderRadius: "8px",
                // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px !important",
              }}
            >
              <AccordionSummary
                sx={{
                  minHeight: "30px !important",
                  "& .MuiAccordionSummary-content": {
                    margin: "0px !important",
                    justifyContent: "center",
                  },
                  borderRadius: "8px",
                  "&.Mui-expanded": {
                    borderRadius: "8px 8px 0px 0px",
                  },
                  padding: "8px 16px",
                  background: "#e6eef1",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography className={"about-title"}>Active Medication</Typography>
              </AccordionSummary>
              <AccordionDetails
                className="common-scrollbar"
                sx={{
                  p: "7px 5px",
                  backgroundColor: "#f2f6f8",
                  borderRadius: "0px 0px 8px 8px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {activeMedications?.map((item, index) => (
                  <Card
                    sx={{
                      fontSize: "12px",
                      mb: index !== activeMedications.length - 1 ? "6px" : "0px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent sx={{ padding: "8px 0px" }}>
                      <div
                        className="w-100"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginRight: "8px" }}>{item?.medicationResponse.name}</div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "50%", marginRight: "5%" }}>
          {ehrSummary && patientId ? (
            <>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "0px",
                  minHeight: "34px",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
                className="card-title"
              >
                EHR Summary
              </div>
              <div
                style={{ padding: "0px 15px 15px 15px" }}
                dangerouslySetInnerHTML={{ __html: ehrSummary }}
              ></div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  paddingLeft: "0px",
                  minHeight: "34px",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
                className="card-title"
              >
                EHR Summary
              </div>
            </>
          )}
        </div>
        <div style={{ width: "45%" }}>
          <div
            style={{
              display: "flex",
              paddingLeft: "0px",
              minHeight: "34px",
              marginBottom: "10px",
              alignItems: "center",
              width: "100%",
            }}
            className="card-title"
          >
            <Reveal style={{ width: "100%" }}>
              {allergies?.length > 0 && (
                <Accordion
                  defaultExpanded
                  sx={{
                    margin: "0px !important",
                    ":: before": { opacity: 0 },
                    borderRadius: "8px",
                    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    marginBottom: "20px !important",
                  }}
                >
                  <AccordionSummary
                    sx={{
                      minHeight: "30px !important",
                      "& .MuiAccordionSummary-content": {
                        margin: "0px !important",
                        justifyContent: "center",
                      },
                      borderRadius: "8px",
                      "&.Mui-expanded": {
                        borderRadius: "8px 8px 0px 0px",
                      },
                      padding: "8px 16px",
                      background: "#e6eef1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography className={"about-title"}>Allergies</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="common-scrollbar"
                    sx={{
                      p: "7px  5px",
                      backgroundColor: "#f2f6f8",
                      borderRadius: "0px 0px 8px 8px",
                      maxHeight: "100px",
                      overflowY: "auto",
                    }}
                  >
                    {allergies?.map((item, index) => (
                      <Card
                        sx={{
                          fontSize: "12px",
                          mb: index !== allergies.length - 1 ? "6px" : "0px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <CardContent sx={{ padding: "8px 0px" }}>
                          <div
                            className="w-100"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ marginRight: "8px" }}>{item?.allergyResponse?.name}</div>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}

              {pastProcedures?.length > 0 && (
                <Accordion
                  defaultExpanded
                  sx={{
                    margin: "0px !important",
                    ":: before": { opacity: 0 },
                    borderRadius: "8px",
                    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    marginBottom: "20px !important",
                  }}
                >
                  <AccordionSummary
                    sx={{
                      minHeight: "30px !important",
                      "& .MuiAccordionSummary-content": {
                        margin: "0px !important",
                        justifyContent: "center",
                      },
                      borderRadius: "8px",
                      "&.Mui-expanded": {
                        borderRadius: "8px 8px 0px 0px",
                      },
                      padding: "8px 16px",
                      background: "#e6eef1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography className={"about-title"}>Past Procedures</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="common-scrollbar"
                    sx={{
                      p: "7px 5px",
                      backgroundColor: "#f2f6f8",
                      borderRadius: "0px 0px 8px 8px",
                      maxHeight: "100px",
                      overflowY: "auto",
                    }}
                  >
                    {pastProcedures?.map((item, index) => (
                      <Card
                        sx={{
                          fontSize: "12px",
                          mb: index !== pastProcedures.length - 1 ? "6px" : "0px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <CardContent sx={{ padding: "8px 0px" }}>
                          <div
                            className="w-100"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ marginRight: "8px" }}>{item?.procedureResponse.name}</div>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}

              {activeMedications?.length > 0 && (
                <Accordion
                  defaultExpanded
                  sx={{
                    margin: "0px !important",
                    ":: before": { opacity: 0 },
                    borderRadius: "8px",
                    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    marginBottom: "20px !important",
                  }}
                >
                  <AccordionSummary
                    sx={{
                      minHeight: "30px !important",
                      "& .MuiAccordionSummary-content": {
                        margin: "0px !important",
                        justifyContent: "center",
                      },
                      borderRadius: "8px",
                      "&.Mui-expanded": {
                        borderRadius: "8px 8px 0px 0px",
                      },
                      padding: "8px 16px",
                      background: "#e6eef1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography className={"about-title"}>Active Medication</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className="common-scrollbar"
                    sx={{
                      p: "7px 5px",
                      backgroundColor: "#f2f6f8",
                      borderRadius: "0px 0px 8px 8px",
                      maxHeight: "100px",
                      overflowY: "auto",
                    }}
                  >
                    {activeMedications?.map((item, index) => (
                      <Card
                        sx={{
                          fontSize: "12px",
                          mb: index !== pastProcedures.length - 1 ? "6px" : "0px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <CardContent sx={{ padding: "8px 0px" }}>
                          <div
                            className="w-100"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ marginRight: "8px" }}>
                              {item?.medicationResponse.name}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </Reveal>
          </div>
        </div>
      </div> */}

      {ehrData
        ?.filter((vital) => vital.key === selectedVitalKey)
        ?.map((vital) => {
          return (
            <div style={{ marginTop: "10px", minHeight: "300px" }}>
              {getChartByVitalType(
                vital?.key,
                vital?.value,
                vital?.value[0]?.vitalSignTypeResponse?.displayName,
                {},
                false,
                10,
                false,
                { minHeight: "350px" },
                { height: "300px" }
              )}
            </div>
          );
        })}
    </div>
  );
};

EhrSummary.propTypes = {
  patientId: PropTypes.string.isRequired, // patient Id of the patient, you need ehr summary for
};

export default EhrSummary;
