import { Apartment } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Paper, Rating, Skeleton, Tab, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getActiveMember } from "store/Slices/userSlice";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import DoctorExperience from "./DoctorProfile/DoctorExperience";
import DoctorRegistrationAndMemberships from "./DoctorProfile/DoctorRegistrationAndMembership";
import { useContext } from "react";
import { getDoctorById } from "services/doctorService";
import { getProfilePicture } from "services/patientService";
import Reveal from "views/utilities/Reveal";
import { FEMALE, SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import CustomBackButton from "ui-component/custom-components/CustomBackButton";

const DoctorProfessionalDetails = () => {
  const activeMember = useSelector(getActiveMember);
  const [doctorDetails, setDoctorDetails] = useState(null);
  const { handleClick } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);
  const genderBasedImage =
    doctorDetails?.userResponse?.gender === FEMALE ? "/images/woman.png" : "/images/man.png";

  const fetchData = async () => {
    try {
      const doctorResponse = await getDoctorById(activeMember?.roleBasedId);
      setDoctorDetails(doctorResponse.data);
      try {
        setIsLoading(true);
        const profilePicResponse = await getProfilePicture(activeMember.id);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setProfileImageFileWithContent(profilePicWithContent);
      } catch (error) {
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching doctor data");
    }
  };

  const tablist = [
    {
      value: "one",
      label: "Experience",
      component: (
        <DoctorExperience
          doctorDetails={doctorDetails}
          setDoctorDetails={setDoctorDetails}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          refreshData={fetchData}
        />
      ),
    },
    {
      value: "two",
      label: "Registration And Membership",
      component: (
        <DoctorRegistrationAndMemberships
          doctorDetails={doctorDetails}
          setDoctorDetails={setDoctorDetails}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          refreshData={fetchData}
        />
      ),
    },
  ];

  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (activeMember) {
      fetchData();
    }
  }, [activeMember]);

  return (
    <DoctorProfessionalDetailsSkeleton>
      <Reveal>
        <Grid sx={{ mt: 0 }}>
          <Grid display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomBackButton />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#004C70",
                }}
              >
                Professional Details
              </Typography>
            </div>
          </Grid>

          <Grid>
            <Paper
              variant="outlined"
              sx={{
                borderRadius: "12px",
                width: "100%",
                height: "fit-content",
                padding: "23px",
              }}
            >
              <Grid container spacing={3} sx={{ mb: "30px", alignItems: "center" }}>
                <Grid>
                  {isLoading ? (
                    <Skeleton variant="circular" animation="wave" width={124} height={124} />
                  ) : (
                    <Avatar
                      alt="Patient"
                      src={
                        profileImageFileWithContent
                          ? profileImageFileWithContent.content
                          : genderBasedImage
                      } // Provide the path to the profile picture
                      sx={{
                        width: 124,
                        height: 124,
                        borderRadius: "50%",
                      }} // Adjust the size as needed
                      variant="square"
                    />
                  )}
                </Grid>
                <Grid>
                  <Typography
                    sx={{
                      color: "#194B5A",
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                  >
                    Dr. {doctorDetails?.userResponse?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {doctorDetails?.specialization?.name} |{" "}
                    {doctorDetails?.highestQualificationName}
                  </Typography>
                  <Grid style={{ display: "flex", gap: "10px", marginTop: "7px" }}>
                    {doctorDetails?.avgRating && (
                      <>
                        {doctorDetails && (
                          <Rating
                            name={doctorDetails?.id.toString()}
                            value={doctorDetails?.avgRating}
                            size="small"
                          />
                        )}
                      </>
                    )}
                    <Typography style={{ marginTop: "1px", fontWeight: "500" }}>
                      {doctorDetails?.avgRating ? doctorDetails?.avgRating : "- No Ratings"}
                    </Typography>
                  </Grid>
                  <p style={{ marginTop: "5px" }}>{doctorDetails?.biography}</p>
                </Grid>
              </Grid>

              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{
                        sx: {
                          backgroundColor: "#004c70",
                        },
                      }}
                    >
                      {tablist.map((item, index) => {
                        return (
                          <Tab
                            key={index}
                            sx={{
                              backgroundColor: value === item.value ? "#e6eef1" : "inherit",
                              borderRadius: "8px 8px 0px 0px",
                            }}
                            label={item.label}
                            value={item.value}
                          />
                        );
                      })}
                    </TabList>
                  </Box>
                  {tablist.map((item, index) => {
                    return (
                      <TabPanel
                        key={index}
                        value={item.value}
                        sx={{
                          padding: "10px",
                        }}
                      >
                        {item.component}
                      </TabPanel>
                    );
                  })}
                </TabContext>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Reveal>
    </DoctorProfessionalDetailsSkeleton>
  );
};

const DoctorProfessionalDetailsSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid container size={{ xs: 12 }} sx={{ flexDirection: "row", ml: 3, mt: 1.5 }}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={125}
            />
          </Grid>
          <Grid size={{ xs: 10 }} flexDirection={"column"}>
            <Skeleton
              sx={{ borderRadius: "4px", mb: 1 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={17}
            />
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={17}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 3 }}
              animation="wave"
              variant="rectangular"
              width="90%"
              height={25}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 1 }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={25}
            />
          </Grid>
        </Grid>

        <Grid sx={{ ml: 3 }} size={{ xs: 12 }} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="25%"
            height={40}
          />
        </Grid>

        <Grid sx={{ ml: 3 }} size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DoctorProfessionalDetails;
