import { FormHelperText } from "@mui/material";
import { useState } from "react";
import { genderList } from "store/constant";

const OnboardingStep1 = ({ handleBlur, handleChange, values, errors, touched, setFieldValue }) => {
  const [selectedGender, setSelectedGender] = useState(values.gender);

  return (
    <>
      <div className="mb-3">
        <label htmlFor="fullName" className="form-label onboarding-label">
          How do you identify yourself
        </label>
        <div className="d-flex w-100 flex-wrap gap-4 mb-3 align-items-center">
          {genderList.map((gender, index) => (
            <p
              key={index}
              className={`mb-0 ${
                selectedGender === gender.value ? "badge-selected" : "custom-options-badge"
              }`}
              onClick={() => {
                setFieldValue("gender", gender.value);
                setSelectedGender(gender.value);
              }}
            >
              {gender.menuLabel}
            </p>
          ))}
        </div>
        {errors.gender && touched.gender && (
          <FormHelperText sx={{ ml: 1, mt: 0.3 }} error>
            {errors.gender}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

export default OnboardingStep1;
