import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import CloseIcon from "@mui/icons-material/Close";

const InfoModal = ({ open, onClose, entityInfo }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{ display: "flex", alignItems: "center", height: "70px" }}>
        {entityInfo.icon}&nbsp;{" "}
        <Typography variant="h3" style={{ color: "#004c70" }}>
          {entityInfo.title}
        </Typography>
        <CustomButton
          onClick={onClose}
          iconButton={<CloseIcon></CloseIcon>}
          style={{ marginLeft: "auto" }}
        ></CustomButton>
      </DialogTitle>

      <DialogContent>
        <Typography
          sx={{
            marginTop: 1,
            fontFamily: "Poppins, sans-serif",
            color: "#555",
            fontSize: "1rem",
          }}
        >
          {entityInfo.info}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
