import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "assets/scss/OtpScreen.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomTypography from "ui-component/custom-components/CustomTypography";
import {
  demographicsAuthmobile,
  generateAadhaarOtp,
  generateMobileOtp,
  isHprIdExist,
  regenerateAdhaarOtp,
  regenerateMobileOtp,
  verifyAdhaarOtp,
  verifyMobileOtp,
} from "services/HprService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useNavigate } from "react-router";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { PermIdentityOutlined } from "@mui/icons-material";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import { Online } from "react-detect-offline";
import OfflineAlert from "OfflineAlert";
import { loggedInUser, SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";

const OtpScreen = () => {
  const navigate = useNavigate();
  const [aadharNumber, setAadharNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [mobileOtp, setMobileOtp] = useState(new Array(6).fill(""));
  const [txnId, setTxnId] = useState("");
  const [countdown, setCountdown] = useState(30);
  const [mobileCountdown, setMobileCountdown] = useState(30);
  const [aadhaarOtpGenerated, setAadhaarOtpGenerated] = useState(false);
  const [mobileOtpGenerated, setMobileOtpGenerated] = useState(false);
  const [isAadhaarverified, setIsAadhaarVerified] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const [apiData, setApiData] = useState({
    aadhaar: "",
  });

  const handleAadhaarChange = (event) => {
    let value = event.target.value;

    let rawValue = value.replace(/\D/g, "");

    if (rawValue.length > 12) {
      rawValue = rawValue.slice(0, 12);
    }

    if (rawValue.length > 4) {
      value = `${rawValue.slice(0, 4)} ${rawValue.slice(4)}`;
    } else {
      value = rawValue;
    }
    if (rawValue.length > 8) {
      value = `${value.slice(0, 9)} ${value.slice(9)}`;
    }

    setAadharNumber(value);
    setApiData((prevData) => ({ ...prevData, aadhaar: rawValue }));
  };

  const startCountdown = () => {
    const timer = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
    }, 30000);
  };
  const startMobileCountdown = () => {
    const timer = setInterval(() => {
      setMobileCountdown((mobileCountdown) => mobileCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
    }, 30000);
  };

  //Aadhaar Otp Flow

  const isGenerateOTPEnabled = aadharNumber.length === 14 && isChecked;
  const isVerifyOTPEnabled = otp.length === 6;

  const handleGenerateOTP = async () => {
    const user = loggedInUser();

    // Check if user is already linked with HPR
    if (user?.hprId) {
      handleClick("info", `User is already linked with Hpr ${user.hprId}`);
      return;
    }
    if (isGenerateOTPEnabled) {
      try {
        const response = await generateAadhaarOtp(apiData);
        setTxnId(response?.data?.txnId);
        if (response?.status === 200) {
          setAadhaarOtpGenerated(true);

          setOtpVerification(true);
        }
      } catch (error) {
        console.error("Error fetching data");
      }

      startCountdown();
    }
  };

  const handleRegenerateOTP = async () => {
    try {
      const requestData = {
        txnId: txnId,
      };
      await regenerateAdhaarOtp(requestData);
    } catch (error) {
      console.error("Error fetching data");
    }
  };

  const handleVerifyAadhaarOTP = async () => {
    if (isVerifyOTPEnabled) {
      try {
        const verifyData = {
          domainName: null,
          idType: null,
          otp: otp.join(""),
          restrictions: null,
          txnId,
        };
        const verifyResponse = await verifyAdhaarOtp(verifyData);
        if (verifyResponse?.status === 200) {
          setIsAadhaarVerified(true);
        }
      } catch (error) {
        handleClick("error", "There seems to be an error in verification");

        setOtp(new Array(6).fill(""));
      }
    }
  };

  // Mobile Otp Flow
  const pattern = /^[6-9][0-9]{9}$/;
  const isMobileNumbervalid = mobileNumber.length === 10 && pattern.test(mobileNumber);
  const isVerifyMobileOTPEnabled = mobileOtp.length === 6;

  const handleMobileOTP = async () => {
    try {
      const requestdata = {
        mobileNumber,
        mobile: null,
        txnId,
      };
      const response = await demographicsAuthmobile(requestdata);
      if (response?.data?.verified === true) {
        try {
          const isHprIdExistResponse = await isHprIdExist({ txnId });
          if (isHprIdExistResponse?.data?.new === true) {
            localStorage.setItem("adhaarNewUser", JSON.stringify(isHprIdExistResponse?.data));
            navigate(`/home/OtpScreen/create/${txnId}`);
          } else {
            handleClick("success", "User Already Exists!");
          }
        } catch (error) {
          console.error("Error fetching data");
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching data");
      if (error?.response?.data?.errorcode === 422) {
        setMobileOtpGenerated(true);
        try {
          const requestData = {
            mobile: mobileNumber,
            txnId,
          };
          await generateMobileOtp(requestData);
        } catch (error) {
          console.error("Error fetching data");
        }
      }
    }
    startMobileCountdown();
  };

  const handleRegenerateMobileOTP = async () => {
    try {
      const requestData = {
        txnId: txnId,
      };
      await regenerateMobileOtp(requestData);
    } catch (error) {
      console.error("Error fetching data");
    }
  };

  const handleVerifyMobileOTP = async () => {
    if (isVerifyMobileOTPEnabled) {
      try {
        const verifyData = {
          otp: mobileOtp.join(""),
          txnId,
        };
        const verifyResponse = await verifyMobileOtp(verifyData);
        if (verifyResponse?.status === 200) {
          const isHprIdExistResponse = await isHprIdExist({ txnId });

          if (isHprIdExistResponse?.data?.new === true) {
            localStorage.setItem("adhaarNewUser", JSON.stringify(isHprIdExistResponse?.data));
            navigate(`/home/OtpScreen/create/${txnId}`);
          } else {
            handleClick("success", "User Already Exists!");
          }
        }
      } catch (error) {
        handleClick("error", "There seems to be some error in verification");
        if (error?.response?.status === 400) {
          setMobileOtp(new Array(6).fill(""));
          handleClick("error", "The OTP that you have entered is incorrect. Please try again");
        }
      }
    }
    setIsAadhaarVerified(true);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.value === "") {
      element.previousSibling.focus();
      return;
    }
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleMobileOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setMobileOtp([...mobileOtp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.value === "") {
      element.previousSibling.focus();
      return;
    }
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const consentText = `
    I, hereby declare that I am voluntarily sharing my Aadhaar Number / Virtual ID and demographic information issued by UIDAI, with National Health Authority (NHA) for the sole purpose of creation of Healthcare Professional ID. I understand that my Healthcare Professional ID can be used and shared for purposes as may be notified by Ayushman Bharat Digital Mission (ABDM) from time to time including provision of healthcare services. Further, I am aware that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph) may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which inter alia includes stakeholders and entities such as healthcare professional (e.g. doctors), facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there under. I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication.
    I consciously choose to use Aadhaar number / Virtual ID for the purpose of availing benefits across the NDHE. I am aware that my personal identifiable information excluding Aadhaar number / VID number can be used and shared for purposes as mentioned above. I reserve the right to revoke the given consent at any point of time as per provisions of Aadhar Act and Regulations and other laws, rules and regulations.
  `;

  const truncatedText = showFullText ? consentText : `${consentText.slice(0, 300)}...`;

  return (
    <>
      <OfflineAlert />
      <Online>
        <OtpScreenSkeleton>
          <div>
            <h2>Register your Health Professional ID (HPR) </h2>
          </div>
          <Grid container justifyContent="center" alignItems="center">
            {!isAadhaarverified && (
              <Reveal className="otp-container">
                <CustomTypography variant="h2">Generate Aadhar OTP</CustomTypography>
                <div className="aadhar-input-container">
                  <FormInputField
                    style={{ width: "100%", marginTop: "10px", marginRight: "30px" }}
                    label="Aadhaar Number"
                    type="tel"
                    // maxLength={"12"}
                    name="aadharNumber"
                    value={aadharNumber}
                    onChange={handleAadhaarChange}
                    startAdornment={<PermIdentityOutlined />}
                    size={"big"}
                  />
                  <div className="helperText">
                    <div className="numbering">
                      <CustomTypography>{`${
                        aadharNumber.replace(/\s+/g, "").length
                      }/12`}</CustomTypography>
                    </div>

                    <div className="resend-aadhaar">
                      {aadhaarOtpGenerated && countdown > 0 && (
                        <CustomTypography variant="body2">
                          Resend OTP in {countdown} seconds
                        </CustomTypography>
                      )}
                      {aadhaarOtpGenerated && countdown === 0 && (
                        <CustomButton
                          variant="text"
                          label="Resend OTP"
                          onClick={handleRegenerateOTP}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="consent-container">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="consentCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <>
                        <CustomTypography>{truncatedText}</CustomTypography>
                        <CustomButton
                          onClick={handleToggleText}
                          variant="text"
                          label={showFullText ? "Show Less" : "Show More"}
                        />
                      </>
                    }
                  />
                </div>

                <CustomButton
                  className="btn--primary"
                  label="Generate OTP"
                  onClick={() => {
                    handleGenerateOTP();
                  }}
                  disabled={!isGenerateOTPEnabled}
                ></CustomButton>

                {isGenerateOTPEnabled && otpVerification && (
                  <Reveal className="otp-verify-container">
                    <Typography variant="body1">
                      A 6-digit OTP has been sent to your registered Mobile number.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        margin: "1rem 0 1rem 0",
                      }}
                    >
                      {otp.map((data, index) => {
                        return (
                          <input
                            className="otp-field"
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={(e) => handleOtpChange(e.target, index)}
                            onFocus={(e) => e.target.select()}
                          />
                        );
                      })}

                      <CustomButton
                        className="btn--primary"
                        label="Verify OTP"
                        onClick={handleVerifyAadhaarOTP}
                        disabled={!isVerifyOTPEnabled}
                      ></CustomButton>
                    </div>
                  </Reveal>
                )}
              </Reveal>
            )}
            {isAadhaarverified && (
              <Reveal className="otp-container">
                <CustomTypography variant="h2">Generate Moblile OTP</CustomTypography>
                <div className="aadhaar-input-container">
                  <FormInputField
                    style={{ width: "50%", marginTop: "10px", marginRight: "30px" }}
                    label="Mobile Number"
                    name="status"
                    value={mobileNumber}
                    type="text"
                    maxLength={"10"}
                    onChange={(e) => {
                      const input = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
                      setMobileNumber(input);
                    }}
                    error={mobileNumber && !isMobileNumbervalid}
                    errorText={!isMobileNumbervalid ? "Please enter valid mobile number" : ""}
                    startAdornment={<i className="ri-phone-line ri-xl" />}
                    size={"big"}
                  />
                  <div className="resend-aadhaar">
                    {mobileOtpGenerated && mobileCountdown > 0 && (
                      <CustomTypography variant="body2">
                        Resend OTP in {mobileCountdown} seconds
                      </CustomTypography>
                    )}{" "}
                    {mobileOtpGenerated && mobileCountdown === 0 && (
                      <CustomButton
                        className="btn--primary"
                        variant="text"
                        label="Resend OTP"
                        onClick={handleRegenerateMobileOTP}
                      />
                    )}
                  </div>
                  <div className="numbering">
                    <CustomTypography>{`${mobileNumber.length}/10`}</CustomTypography>
                  </div>
                  <CustomButton
                    label="Proceed Generate OTP"
                    onClick={() => {
                      handleMobileOTP();
                    }}
                    disabled={!isMobileNumbervalid}
                    className="btn--primary"
                  ></CustomButton>
                  {mobileOtpGenerated && isMobileNumbervalid && (
                    <Reveal>
                      <Typography variant="body1">
                        A 6-digit OTP has been sent to your registered Mobile number.
                      </Typography>
                      {mobileOtp.map((data, index) => {
                        return (
                          <input
                            className="otp-field"
                            type="text"
                            name="mobileOtp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={(e) => handleMobileOtpChange(e.target, index)}
                            onFocus={(e) => e.target.select()}
                          />
                        );
                      })}

                      <CustomButton
                        className="btn--primary"
                        label="Verify OTP"
                        onClick={handleVerifyMobileOTP}
                        disabled={mobileNumber && !isVerifyMobileOTPEnabled}
                      ></CustomButton>
                    </Reveal>
                  )}
                </div>
              </Reveal>
            )}
          </Grid>
          <div style={{ marginTop: "50px" }}>
            <h3>What is HPR?</h3>
            <Typography>
              Healthcare Professionals Registry (HPR) is a comprehensive repository of registered
              and verified different system of medicines (Modern medicine, Dentistry, Ayurveda,
              Unani, Siddha, Sowa-Rigpa, Homeopathy) and Nurses practitioners delivering healthcare
              services across India. The Ayushman Bharat Digital Mission (ABDM) empowers healthcare
              professionals and encourages to be part of India’s digital health ecosystem through a
              unique Healthcare professional ID. With last mile coverage, people will be able to
              interact with healthcare practitioners or vice versa.
            </Typography>
            <br></br>
            <Typography>
              Healthcare professional profile visible in the ABDM ecosystem is verified and
              authorized to practice medicine in the country. The HPR ensures that healthcare
              practitioners suitably trained and qualified to practice medicine with competence and
              ethical conformity are allowed to register with the HPR.
            </Typography>
            <br></br>
            <Typography>
              To know more
              <a
                href="https://nhpr.abdm.gov.in/home"
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#007bff", marginLeft: "5px", textDecoration: "none" }}
              >
                Click Here
              </a>
            </Typography>
          </div>
        </OtpScreenSkeleton>
      </Online>
    </>
  );
};

const OtpScreenSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2} mt={3} justifyContent={"center"}>
        <Grid size={{ xs: 10 }}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={325}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OtpScreen;
