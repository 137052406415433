export const convertPrescriptionDataToUIFormat = async (prescriptionData) => {
  try {
    const diagnosisArr = prescriptionData.prescriptionDiagnosisResponses.map((diagnosis) => ({
      ...diagnosis,
      displayName: diagnosis.diagnosisResponse.name,
      category: "DX",
    }));

    const symptomsArr = prescriptionData.prescriptionSymptomResponses.map((symptom) => ({
      ...symptom,
      displayName: symptom.symptomResponse.name,
      category: "CC",
    }));

    const medicationsArr = prescriptionData.prescriptionMedicationResponses.map((medication) => ({
      ...medication,
      displayName: medication.medicationResponse.name,
      category: "RX",
    }));

    const labInvestigationsArr = prescriptionData.prescriptionLabReportResponses.map((labTest) => ({
      ...labTest,
      displayName: labTest.labTestResponse.name,
      category: "LAB",
    }));

    let advicesArr = [];
    if (prescriptionData?.additionalRecommendations !== "") {
      advicesArr = prescriptionData?.additionalRecommendations.split(",").map((advice) => {
        return {
          category: "ADV",
          displayName: advice.trim(),
        };
      });
    }

    const data = {
      diagnosisArr,
      symptomsArr,
      medicationsArr,
      labInvestigationsArr,
      advicesArr,
    };

    return {
      data,
    };
  } catch (error) {
    console.error(error);
  }
};
