import * as Yup from "yup";
import {
  VERIFICATION_VIA_MOBILE,
  VERIFICATION_VIA_ADHAAR,
  VERIFICATION_VIA_ABHA_USING_ADHAAR,
  VERIFICATION_VIA_ABHA_USING_MOBILE,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_ADHAAR,
  VERIFICATION_VIA_ABHA_ADDRESS_USING_MOBILE,
} from "store/constant";

export const createAbhaValidation = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .min(3, "Minimum 3 characters are required for first name.")
    .max(50, "Maximum 50 characters are allowed in first name.")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .required("Please enter your middle name.")
    .min(1, "Minimum 1 character is required for middle name.")
    .max(50, "Maximum 50 characters are allowed in middle name.")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .min(3, "Minimum 3 characters are required for last name.")
    .max(50, "Maximum 50 characters are allowed in last name.")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  healthId: Yup.string().required("HealthId is required"),
  password: Yup.string().required("Password is required"),
  mobileNumber: Yup.string()
    .required("Please enter a valid mobile number")
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Please enter a valid email.")
    .max(255, "Maximum 255 characters are allowed in email."),

  getAbhaRadioBtn: Yup.string().required("Please select an option"),
  getAbhaEntityText: Yup.string().test("conditional-validation", null, function (value) {
    const { getAbhaRadioBtn } = this.parent;

    if (!value) {
      return this.createError({ message: "This field is required" });
    }

    switch (getAbhaRadioBtn) {
      case VERIFICATION_VIA_MOBILE:
        if (!/^[6-9][0-9]{9}$/.test(value)) {
          return this.createError({ message: "Please enter a valid mobile number" });
        }
        break;
      case VERIFICATION_VIA_ADHAAR:
        if (!/^[0-9]{12}$/.test(value)) {
          return this.createError({ message: "Please enter a valid 12-digit Adhaar number" });
        }
        break;
      case "abhaNoBtn":
        if (!/^[0-9]{14}$/.test(value)) {
          return this.createError({ message: "Please enter a valid 14-digit Abha number" });
        }
        break;
      case "abhaAddressBtn":
        if (!/^[a-zA-Z0-9_.-]+$/.test(value)) {
          return this.createError({ message: "Please enter a valid Abha address" });
        }
        break;
    }

    return true;
  }),
});
