import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router";

import * as React from "react";
import { ACTIVE_STATE } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { createRole, getRole, updateRole } from "services/roleService";
import { accessToken } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ControlCameraOutlinedIcon from "@mui/icons-material/ControlCameraOutlined";
import { DescriptionOutlined } from "@mui/icons-material";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import { manageRoleSchema } from "../Common/ValidationSchema/manageRoleSchema";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";

const ManageRole = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const navigate = useNavigate();

  const navigateToRoles = () => {
    navigate("/home/Roles");
  };

  const { state } = useLocation();
  const { roleId } = state;
  const accessTokenValue = accessToken();

  const defaultValues = {
    name: "",
    description: "",
    status: ACTIVE_STATE,
  };
  const [role, setRole] = useState(defaultValues);

  useEffect(() => {
    if (roleId) {
      const fetchData = async () => {
        try {
          const response = await getRole(roleId);
          setRole(response.data);
        } catch (error) {
          handleClick("error", "Fetch roles failed");
        }
      };
      fetchData();
    }
  }, [accessTokenValue, roleId, handleClick]);

  return (
    <Reveal>
      <Formik
        enableReinitialize={true}
        initialValues={{
          role: role && role.name ? role.name : "",
          description: role && role.description ? role.description : "",
          status: role && role.status ? role.status : "",
          submit: "",
        }}
        validationSchema={manageRoleSchema}
        onSubmit={async (values) => {
          const data = {
            name: values.role,
            description: values.description,
            status: values.status,
          };
          try {
            if (roleId) {
              data["id"] = role.id;
              await updateRole(role.id, data);
            } else {
              await createRole(data);
            }
            handleClick(
              "success",
              { roleId } ? "Role updated successfully!" : "Role added successfully!"
            );
            setTimeout(() => {
              navigateToRoles();
            }, 1000);
          } catch (error) {
            handleClick("error", "API issue!");
          }
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
                mb: 2,
              }}
            >
              {roleId ? "Update Role" : "Create Role"}
            </Typography>
            <FormInputField
              style={{ width: "30%", marginRight: "20px" }}
              label={"Role"}
              name={"role"}
              type={"text"}
              value={values.role}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              startAdornment={<ControlCameraOutlinedIcon />}
              error={Boolean(errors.role && touched.role)}
              errorText={errors.role}
            ></FormInputField>

            <FormInputField
              style={{ width: "30%", marginRight: "20px" }}
              label={"Role Description"}
              name={"description"}
              type={"text"}
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              startAdornment={<DescriptionOutlined />}
            ></FormInputField>

            <FormInputField
              style={{ width: "30%" }}
              label={"Status"}
              name={"status"}
              value={values.status}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              startAdornment={<PauseCircleOutlineOutlinedIcon />}
              readOnly
            ></FormInputField>

            <Box sx={{ mt: 3 }}>
              <CustomButton
                className={roleId ? "btn--primary" : "ri-add-fill ri-lg btn--primary"}
                style={{ marginRight: "20px" }}
                type="submit"
                label={roleId ? "Update Role" : "Add Role"}
              ></CustomButton>
              <CustomButton
                className="btn--primary"
                onClick={() => navigateToRoles()}
                label="Roles"
              />
            </Box>
          </form>
        )}
      </Formik>
    </Reveal>
  );
};

export default ManageRole;
