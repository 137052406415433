import { ToastContext } from "ui-component/custom-components/CustomToast";
import { Typography, Paper, Card, CardContent, Skeleton, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useLocation, useNavigate } from "react-router";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { useEffect } from "react";
import {
  CLINIC_ADMIN,
  DATE_FORMAT_DMY,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  currentActiveUser,
} from "store/constant";
import { useState } from "react";
import "assets/scss/dashboard.scss";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useContext } from "react";
import { getAppointmentsByOrgIdOrDoctorId } from "services/Appointments";
import { doesProductServicesExist, getDoctorByOrgId } from "services/doctorService";
import { useDispatch } from "react-redux";
import { setOrgId, setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import ModalUI from "ui-component/ModalUI";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import Reveal from "views/utilities/Reveal";
import { PendingDetailsPopupComponent } from "./CommonDashboardForFrontDeskAndDoctor";
import { Build } from "@mui/icons-material";
import { getUserFeatures, getUserOrgId } from "store/Slices/userSlice";
import { useSelector } from "react-redux";
import { SidebarUtilContext } from "layout/SidebarUtilContext";
import { profileDashboard } from "menu-items/dashboard";
import { getAllContent } from "services/ContentService";
import dayjs from "dayjs";

const ClinicDashboard = () => {
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checkProfileCompletedApiCalled, setCheckProfileCompletedApiCalled] = useState(false);
  const completedDetails = useLocation()?.state?.completedDetails;

  const { toggleMainClass, setSecondarySideBarData } = useContext(SidebarUtilContext);
  const userFeatures = useSelector(getUserFeatures);

  const patientTableFields = [
    { field: "srNo", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "lastVisited", label: "Last Visited" },
  ];

  const doctorTableFields = [
    { field: "srNo", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "mobilenumber", label: "Mobile Number" },
  ];

  const [patientTableValues, setPatientTableValues] = useState([]);
  const [doctorTableValues, setDoctorTableValues] = useState([]);
  const userOrgId = useSelector(getUserOrgId);
  const activeUser = currentActiveUser();

  const [prodServciesPending, setProdServciesPending] = useState(true);

  const [pendingTaskPopupVisible, setPendingTaskPopupVisible] = useState(false);
  const handlePendingPopupClose = () => {
    setPendingTaskPopupVisible(false);
  };

  useEffect(() => {
    if (!prodServciesPending) {
      setPendingTaskPopupVisible(true);
    } else if (checkProfileCompletedApiCalled && completedDetails) {
      handleClick("success", "You're all set to go!!");
    }
  }, [prodServciesPending, checkProfileCompletedApiCalled]);

  const checkForProfileSetupDetails = async () => {
    if (activeUser.roleName === CLINIC_ADMIN) {
      try {
        const orgAssociationRecord = activeUser.userOrganizationAssociationList.filter(
          (el) => el.organizationId === userOrgId
        )[0];
        if (orgAssociationRecord.isAdmin) {
          const prodServBool = await doesProductServicesExist(userOrgId);
          setProdServciesPending(prodServBool.data);
          setCheckProfileCompletedApiCalled(true);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    checkForProfileSetupDetails();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const doctorListResponse = await getDoctorByOrgId(userOrgId);
        const doctorData = doctorListResponse.data.map((doctor, index) => ({
          id: doctor?.id,
          srNo: index + 1,
          name: `Dr. ${doctor?.userResponse?.name}`,
          mobilenumber: doctor?.userResponse?.mobileNumber,
        }));
        setDoctorTableValues(doctorData);

        const patientListResponse = await getAppointmentsByOrgIdOrDoctorId(userOrgId);
        const patientData = patientListResponse.data.content.map((patient, index) => ({
          id: patient?.id,
          srNo: index + 1,
          name: patient?.patientName,
          lastVisited: patient?.lastVisitedDate
            ? dayjs(patient?.lastVisitedDate).format(DATE_FORMAT_DMY)
            : null,
        }));
        setPatientTableValues(patientData);
      } catch (error) {
        console.error("Error fetching organisation details");
      }
    };

    fetchData();
  }, [handleClick, userOrgId]);

  /** todo : health feeds are static, change once api are made for them */
  const healthFeeds = [
    {
      feedTitle: "Childhood obesity: Prediatricians ring alarm bell, offer tips to parents",
      feedText:
        "At a seminar held on World Obesity Day on Monday, doctors highlighted the importance of the balanced diet to keep the body health. Also the importance of exercise to keep body fit",
    },
    {
      feedTitle: "Childhood obesity: Prediatricians ring alarm bell, offer tips to parents",
      feedText:
        "At a seminar held on World Obesity Day on Monday, doctors highlighted the importance of the balanced diet to keep the body health. Also the importance of exercise to keep body fit",
    },
  ];

  // appointment booking
  const [isModalOpen, setisModalOpen] = useState(false);

  const handleModalClose = () => {
    dispatch(setSelectedAppointmentData({}));
    setisModalOpen(false);
  };

  const handleModalOpen = () => {
    dispatch(setOrgId(userOrgId));
    setisModalOpen(true);
  };

  const steps = [
    {
      label: "Complete Product-Service Information",
      icon: <Build />,
      condition: prodServciesPending,
      onClick: () => {
        const profileObj = profileDashboard(
          userFeatures,
          userOrgId,
          activeUser,
          activeUser.roleName
        );
        setSecondarySideBarData(profileObj);
        navigate("/home/manageOrganization", {
          state: { organizationId: userOrgId, pendingTask: true },
        });
        toggleMainClass((prevClass) => "main sub-sidebar");
      },
    },
  ];

  const [contents, setContents] = useState([]);
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getAllContent();
        setContents(content?.data);
      } catch (e) {
        console.error("Error fetching Content");
      }
    };

    fetchContent();
  }, [handleClick]);

  return (
    <ClinicDashboardSkeleton>
      <div className="clinic-dashboard">
        <Reveal className="heading">
          <Typography className="dashboard-title">Dashboard</Typography>
          <CustomButton
            className="ri-add-fill ri-lg btn--secondary"
            label="New Appointment"
            onClick={handleModalOpen}
            style={{ margin: "auto 0" }}
          ></CustomButton>
        </Reveal>
        <Grid container spacing={2}>
          <Grid size={{ lg: 6, md: 12, sm: 12, xs: 12 }}>
            <Reveal>
              <Paper
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  padding: "17px 24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div>
                    <Typography className="card-field-value">Doctors</Typography>
                    <CustomizedTable
                      columns={doctorTableFields}
                      tableData={doctorTableValues}
                      rowsPerPageInTable={3}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <p
                      className="view-all"
                      onClick={() => {
                        navigate(`/home/doctor`);
                      }}
                    >
                      View All
                    </p>
                  </div>
                </div>
              </Paper>
            </Reveal>
          </Grid>
          <Grid size={{ lg: 6, md: 12, sm: 12, xs: 12 }}>
            <Reveal>
              <Paper
                style={{
                  width: "100%",
                  minHeight: "100%",
                  borderRadius: "10px",
                  padding: "17px 24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography className="card-field-value">Patients</Typography>
                    <CustomizedTable
                      columns={patientTableFields}
                      tableData={patientTableValues}
                      rowsPerPageInTable={3}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <p
                      className="view-all"
                      onClick={() => {
                        navigate(`/home/patient`);
                      }}
                    >
                      View All
                    </p>
                  </div>
                </div>
              </Paper>
            </Reveal>
          </Grid>
        </Grid>
        <br></br>
        <Reveal>
          <Paper style={{ height: "100%", padding: "20px", textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Typography className="card-field-value">Health Feeds</Typography>
              <div>
                <CustomButton
                  iconButton={<i className="ri-more-2-fill" />}
                  style={{ padding: 0, marginLeft: "5px" }}
                ></CustomButton>
              </div>
            </div>
            <div>
              {contents?.slice(0, 2)?.map((content, index) => (
                <Card key={index} style={{ marginBottom: "20px" }}>
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <div
                        style={{
                          minWidth: "86px",
                          minHeight: "86px",
                          borderRadius: "8px",
                          backgroundColor: "#F9F87133",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <PlayCircleIcon style={{ width: "20px", height: "20px" }}></PlayCircleIcon>
                      </div>
                      <div>
                        <Typography className="healthFeedTitle">{content?.title}</Typography>
                        <Typography
                          className="healthFeedText"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                          }}
                        >
                          {content?.description}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "86px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <i className="ri-heart-line ri-xl" />
                        <i className="ri-pushpin-line ri-xl" />
                        <i className="ri-play-list-add-line ri-lg" />
                      </div>
                      <CustomButton
                        label="Read Full Article"
                        className="btn--primary"
                        onClick={() => {
                          navigate(`/home/health-feeds/content/${content?.uuid}`, {
                            state: {
                              contentId: content?.uuid,
                            },
                          });
                        }}
                      ></CustomButton>
                    </div>
                  </CardContent>
                </Card>
              ))}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <p
                  className="view-all"
                  onClick={() => {
                    navigate("/home/health-feeds");
                  }}
                >
                  View All
                </p>
              </div>
            </div>
          </Paper>
        </Reveal>

        <ModalUI
          visible={pendingTaskPopupVisible}
          close={handlePendingPopupClose}
          title={"Complete below profile details"}
          style={{
            overflowY: "auto",
          }}
          component={<PendingDetailsPopupComponent steps={steps} activeUser={activeUser} />}
        />

        <ModalUI
          visible={isModalOpen}
          close={handleModalClose}
          title="Book Appointment"
          style={{
            overflowY: "scroll",
            height: "550px",
            width: "610px",
          }}
          component={
            <BookAppointmentModal handleClick={handleClick} closeModal={handleModalClose} />
          }
        />
      </div>
    </ClinicDashboardSkeleton>
  );
};

const ClinicDashboardSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid size={{ xs: 6 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={270}
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={270}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClinicDashboard;
