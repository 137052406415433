import * as Yup from "yup";

export const AddAvailabilityValidationSchema = Yup.object().shape({
  // consultationType: Yup.string().required("Required"),
  // organization: Yup.string().required("Required"),
  startTime: Yup.string().required("Required"),
  endTime: Yup.string().required("Required"),
});

export const AddAvailabilityValidationSchema2 = Yup.object().shape({
  // consultationType: Yup.string().required("Required"),
  organization: Yup.string().required("Required"),
  startTime: Yup.string().required("Required"),
  endTime: Yup.string().required("Required"),
});
