import { Grid, Typography, IconButton, Skeleton, Box, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateVitalSignType from "./UpdateViralSignType";
import { vitalSchema } from "../../Common/ValidationSchema/vitalSignValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  entitiesInfo,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useContext } from "react";
import {
  createVitalSignTypes,
  deleteVitalSignTypes,
  getVitalSignTypes,
} from "services/EntitiesServices";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import InfoModal from "../InfoModal";
import InfoIcon from "@mui/icons-material/Info";

const VitalSignTypes = () => {
  const [vitalSignType, setVitalSignType] = useState([]);
  const [displayVitalSignType, setDisplayVitalSignType] = useState([]);
  const [newVitalSignType, setNewVitalSignType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const entityInfo = entitiesInfo.find((entity) => entity.id === "Vital Sign Types");
  const [openDialog, setOpenDialog] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getVitalSignTypes();
      setVitalSignType(response?.data);
    } catch (error) {
      handleClick("error", "Error fetching vital-sign-type");
    }
  }, [handleClick]);

  const handleDelete = async (vitalSignType) => {
    if (vitalSignType.status === ACTIVE_STATE) {
      try {
        await deleteVitalSignTypes(vitalSignType.id);

        handleClick("success", "Vital sign-type marked Inactive!");
        fetchData();
      } catch (e) {
        handleClick("error", "Vital-sign-type marked Inactive failed");
      }
    } else {
      handleClick("info", "Organization is already inactive!");
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const addVitalSignType = async () => {
    if (error) {
      return;
    }
    const data = { name: newVitalSignType };

    if (vitalSignType.some((t) => t.name.toLowerCase() === newVitalSignType.toLowerCase())) {
      handleClick("error", "Vital sign-type already exists");
      return;
    }
    try {
      const res = await createVitalSignTypes(data);
      handleClick("success", "Vital sign-type added successfully!");
      setNewVitalSignType("");
      setVitalSignType([...vitalSignType, res.data]);
    } catch (e) {
      handleClick("error", "Error creating vital-sign-type");
    }
  };

  useEffect(() => {
    setDisplayVitalSignType(
      vitalSignType.filter((s) =>
        s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    );
  }, [vitalSignType, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const [error, setError] = useState("");

  const validateVital = (vital) => {
    try {
      vitalSchema.validate({ vital });
      setError("");
    } catch (e) {
      setError(e.message);
    }
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Vital Sign Type" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <VitalSignTypeSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
            Vital Sign Types
          </h2>
          {entityInfo && (
            <>
              <InfoIcon
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={() => setOpenDialog(true)}
              />
              <InfoModal
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                entityInfo={{ ...entityInfo, title: "Vital Sign Types" }}
              />
            </>
          )}
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any vital sign type
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "90%" }}
                label={"Search vital sign type"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  <IconButton onClick={clearSearch} edge="end">
                    {<ClearOutlinedIcon fontSize="small" />}
                  </IconButton>
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Add a new vital sign type
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={8} sx={{ mr: 2 }}>
                <FormInputField
                  label={"Add vital sign type"}
                  value={newVitalSignType}
                  error={Boolean(error)}
                  errorText={error}
                  onChange={(e) => {
                    setNewVitalSignType(e.target.value);
                    validateVital(e.target.value);
                  }}
                  startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid item sx={{ mt: 0.5 }}>
                <CustomButton
                  className="btn--primary"
                  disabled={newVitalSignType === ""}
                  onClick={addVitalSignType}
                  label="Add"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>

        <CustomizedTable columns={columns} tableData={displayVitalSignType} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Vital Sign Type"
          component={
            <UpdateVitalSignType
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </VitalSignTypeSkeleton>
  );
};

const VitalSignTypeSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: "10%" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="40%"
            height={80}
          />
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VitalSignTypes;
