import { api as API } from "./AxiosInterceptor";

// filter response.data for active availability
// export const getDoctorAvailability = (docId) => API.get(`/api/availabilities/doctor/${docId}`);

export const getDoctorAvailability = async (doctorId, organizationId) => {
  const response = await API.get(`/api/availabilities/query`, {
    params: { doctorId, organizationId },
  });
  return response?.data;
};

export const doctorAdvanceSearch = (searchParams) =>
  API.get(`/api/doctor/search/advanced`, {
    params: searchParams,
  });

export const getAllDoctors = () => API.get(`/api/doctors`);

export const getDoctorById = (docId) => API.get(`/api/doctors/${docId}`);
export const createDoctors = (data) => API.post(`/api/doctors`, data);
export const updateDoctors = (docId, data) => API.put(`/api/doctors/${docId}`, data);
export const deleteDoctors = (docId) => API.delete(`/api/doctors/${docId}`);

// get doctors by Org Id
export const getDoctorByOrgId = (orgId) => API.get(`/api/doctors/organization/${orgId}`);

//ge doctors by user ID
export const getDoctorByUserId = (orgId) => API.get(`/api/doctors/user/${orgId}`);

export const updateDoctor = (roleBasedIdOfDoctor, data) =>
  API.put(`/api/doctors/${roleBasedIdOfDoctor}`, data);

// awards related apis
export const getAllAwards = () => API.get(`/api/doctor/awards`);
export const createDoctorAwards = (data) => API.post(`/api/doctor/awards`, data);
export const updateDoctorAwards = (id, data) => API.put(`/api/doctor/awards/${id}`, data);
export const deleteDoctorAwards = (id) => API.delete(`/api/doctor/awards/${id}`);

// common-treatments Apis
export const getAllCommonTreats = () => API.get(`/api/common-treatments`);
export const createCommonTreats = (data) => API.post(`/api/common-treatments`, data);
export const updateCommonTreats = (id, data) => API.put(`/api/common-treatments/${id}`, data);
export const deleteCommonTreats = (id) => API.delete(`/api/common-treatments/${id}`);

// experiences related api
export const getExperiences = () => API.get(`/api/doctor/experiences`);
export const createExperiences = (data) => API.post(`/api/doctor/experiences`, data);
export const updateExperiences = (id, data) => API.put(`/api/doctor/experiences/${id}`, data);
export const deleteExperiences = (id) => API.delete(`/api/doctor/experiences/${id}`);

//memberships related Apis
export const getMemberships = () => API.get(`/api/doctor/memberships`);
export const createMemberships = (data) => API.post(`/api/doctor/memberships`, data);
export const updateMemberships = (id, data) => API.put(`/api/doctor/memberships/${id}`, data);
export const deleteMemberships = (id) => API.delete(`/api/doctor/memberships/${id}`);

// registration related Apis
export const getRegistrations = () => API.get(`/api/doctor/registrations`);
export const createRegistrations = (data) => API.post(`/api/doctor/registrations`, data);
export const updateRegistrations = (id, data) => API.put(`/api/doctor/registrations/${id}`, data);
export const deleteRegistrations = (id) => API.delete(`/api/doctor/registrations/${id}`);

// publications related Api
export const getPublications = () => API.get(`/api/doctor/publications`);
export const createPublications = (data) => API.post(`/api/doctor/publications`, data);
export const updatePublications = (id, data) => API.put(`/api/doctor/publications/${id}`, data);
export const deletePublications = (id) => API.delete(`/api/doctor/publications/${id}`);

//qualification related Apis
export const getQualifications = () => API.get(`/api/doctor/qualifications`);
export const createQualifications = (data) => API.post(`/api/doctor/qualifications`, data);
export const updateQualifications = (id, data) => API.put(`/api/doctor/qualifications/${id}`, data);
export const deleteQualifications = (id) => API.delete(`/api/doctor/qualifications/${id}`);

// doctor common treatment Apis
export const createDoctorCommonTreatment = (data) =>
  API.post(`/api/doctor/doctor-common-treatments`, data);
export const deleteDoctorCommonTreatment = (id) =>
  API.delete(`/api/doctor/doctor-common-treatments/${id}`);

// UHI integration Apis
export const searchDoctorUhi = (data) => API.post(`/api/doctor/uhi/search`, data);
export const secondSearchDoctorUhi = (data) => API.post(`/api/doctor/uhi/second-search`, data);
export const initBookingUhi = (data) => API.post(`/api/doctor/uhi/init-booking`, data);
export const completeBookingUhi = (data) => API.post(`/api/doctor/uhi/confirm-booking`, data);
export const cancelUhiAppointment = (data) => API.post(`/api/doctor/uhi/cancel-booking`, data);

export const getUhiAppointmentsByPatientId = (patientId) =>
  API.get(`/api/appointments/uhi/patient/${patientId}`);

// Doctor pending profile details check Apis
export const doSpecializationAndExperienceExist = (doctorId) =>
  API.get(`/api/doctors/exists/specialization-experience`, { params: { doctorId } });
export const doesAvailabilityExist = (doctorId, organizationId) =>
  API.get(`/api/availabilities/exists`, { params: { doctorId, organizationId } });
export const doesProductServicesExist = (organizationId) =>
  API.get(`/api/billing/medical-services/exists`, { params: { organizationId } });
