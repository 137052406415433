import { useState, useEffect } from "react";
import { Box, FormControl } from "@mui/material";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import * as React from "react";
import { reviewSchema } from "../ValidationSchema/reviewValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { COMPLETED, accessToken, currentActiveUser } from "store/constant";
import { useLocation, useNavigate } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import { DescriptionOutlined, PersonOutlineOutlined, TitleOutlined } from "@mui/icons-material";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { useContext } from "react";
import { getAppointmentsByPatientId } from "services/Appointments";
import { getPatientByUserId } from "services/patientService";
import { createReviews, getReviewById, updateReviews } from "services/ReviewService";

const Review = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const [patient, setPatient] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [noCompletedAppointments, setnoCompletedAppointments] = useState(false);
  const [reviewToUpdate, setReviewToUpdate] = useState(null);
  const { state } = useLocation();
  const reviewId = state ? state.reviewId : null;
  const accessTokenValue = accessToken();

  useEffect(() => {
    if (reviewId) {
      const fetchData = async () => {
        try {
          const response = await getReviewById(reviewId);
          setReviewToUpdate(response.data);
        } catch (error) {
          handleClick("error", "Fetch review failed");
        }
      };
      fetchData();
    }
  }, [reviewId, handleClick]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPatientByUserId(currentActiveUser().id);

        const patientId = response.data ? response.data.id : null;
        setPatient(response.data);

        if (patientId) {
          const tempAppointmentsRes = await getAppointmentsByPatientId(patientId);

          const appRes = tempAppointmentsRes.data.filter(
            (el) => el.appointmentStatus === COMPLETED && el.doctor.acceptReviews
          );
          if (appRes.length === 0) {
            setnoCompletedAppointments(true);
          } else {
            setnoCompletedAppointments(false);
          }
          const docArr = [];
          appRes.forEach((el) => {
            const name = el.doctor.userResponse.name;
            if (docArr.findIndex((el) => el.name === name) < 0) {
              docArr.push({
                id: el.doctor.id,
                name: el.doctor.userResponse.name,
              });
            }
          });
          setDoctors(docArr);
        }
      } catch (error) {
        handleClick("error", "Fetch patientInfo failed");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        doctor:
          doctors && doctors.length > 0
            ? reviewToUpdate
              ? doctors.find((doc) => doc.id === reviewToUpdate.doctorId)?.id
              : doctors[0].id
            : "",
        rating: reviewToUpdate ? reviewToUpdate.rating : 3,
        title: reviewToUpdate ? reviewToUpdate.title : "",
        description: reviewToUpdate ? reviewToUpdate.description : "",
        submit: "",
      }}
      validationSchema={reviewSchema}
      onSubmit={async (values) => {
        const data = {
          patientId: patient.id,
          doctorId: values.doctor,
          rating: values.rating,
          title: values.title,
          description: values.description,
        };

        try {
          if (reviewId) {
            await updateReviews(reviewId, data);
          } else {
            await createReviews(data);
          }
          handleClick("success", "Review added successfully!");
          navigate("/home/reviewList");
        } catch (error) {
          if (error.response.status === 400) {
            handleClick("error", "Review has already given for this doctor!!");
          } else {
            handleClick("error", "API issue!");
          }
        }
      }}
    >
      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit} {...others}>
          <h2>Review</h2>

          {noCompletedAppointments === true && (
            <>
              <h3>Sorry, there are no completed appointments for review!</h3>
            </>
          )}
          {noCompletedAppointments === false && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormSelectField
                    style={{ width: "40%", marginRight: "30px" }}
                    label="Doctor"
                    name="doctor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={<PersonOutlineOutlined />}
                    menuItems={doctors.map((el) => {
                      return {
                        ...el,
                        value: el?.id,
                        menuLabel: el?.name,
                      };
                    })}
                    value={values.doctor}
                    size={"big"}
                  ></FormSelectField>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{}}>
                    <Rating
                      name="rating"
                      value={values.rating}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size="large"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormInputField
                    label="Title"
                    name="title"
                    type="title"
                    required
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.title && errors.title)}
                    errorText={errors.title}
                    startAdornment={<TitleOutlined />}
                    size={"big"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInputField
                    label="Description"
                    name="description"
                    required
                    type="description"
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.description && errors.description)}
                    errorText={errors.description}
                    startAdornment={<DescriptionOutlined />}
                    size={"big"}
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <CustomButton
                  className="btn--primary"
                  type="submit"
                  label="Submit Review"
                ></CustomButton>
              </Box>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

export default Review;
