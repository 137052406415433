import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const NoDataFoundMessageInTable = ({ colSpan, isTeleconsultation = false }) => (
  <TableRow>
    <TableCell
      style={{
        textAlign: "center",
        fontStyle: "italic",
        color: isTeleconsultation ? "white" : "rgba(0, 0, 0, 0.5)",
      }}
      sx={{ borderBottom: "none !important" }}
      colSpan={colSpan}
    >
      {isTeleconsultation ? "No Vital Added..." : "No data found..."}
    </TableCell>
  </TableRow>
);

export default NoDataFoundMessageInTable;
