import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale } from "chart.js";
import { useRef } from "react";
import { color } from "@mui/system";

ChartJS.register(LineElement, CategoryScale, LinearScale);

const IndicatorWithTrendGraph = ({ currentValue, trendData }) => {
  const trendRef = useRef(null);

  const data = {
    labels: trendData?.labels || [], // Historical labels
    datasets: [
      {
        label: "Historical Trend",
        data: trendData?.values || [], // Historical values
        borderColor: "#1996d0",
        tension: 0,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true, // Show x-axis
        // border: {
        //   color: "#004c70", // Set axis color
        //   width: 2, // Thicken the x-axis to 5px
        // },
        grid: {
          drawOnChartArea: false, // Hide grid lines and background
          drawTicks: false, // Hide the tick marks
        },
        ticks: {
          display: false, // Hide labels on x-axis
        },
      },
      y: {
        display: true, // Show y-axis
        // border: {
        //   color: "#004c70", // Set axis color
        //   width: 2, // Thicken the x-axis to 5px
        // },
        grid: {
          drawOnChartArea: false, // Hide grid lines and background
          drawTicks: false, // Hide the tick marks
        },
        ticks: {
          display: false, // Hide labels on y-axis
        },
      },
    },
    plugins: { legend: { display: false }, tooltip: { enabled: false } },
    elements: { line: { borderWidth: 1 } },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="indicator">
      <div className="value">{currentValue}</div>
      <div className="trend-icon" style={{ width: "40px", height: "40px" }}>
        <Line ref={trendRef} data={data} options={options} />
      </div>
    </div>
  );
};

export default IndicatorWithTrendGraph;
