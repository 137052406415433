import React, { useState, useEffect } from "react";
import "assets/scss/appointment.scss";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  PRESCRIPTION_DONE,
  COMPLETED,
} from "store/constant";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { getAppointments } from "services/Appointments";
import { useContext } from "react";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import { Box, Grid, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Reducers/userReducer";
import { useNavigate } from "react-router";
import { getInvoicePDFById } from "services/BillingService";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "patientName", label: "Patient Name" },
  { field: "doctor.userResponse.name", label: "Doctor Name" },
  { field: "appointmentDate", label: "Date" },
  { field: "appointmentTime", label: "Time" },
  { field: "appointmentStatusDisplay", label: "Status" },
  { field: "Actions", label: "ACTIONS", actions: true },
];

const BillingForAppointments = () => {
  const [appointment, setAppointment] = useState([]);
  const { handleClick } = useContext(ToastContext);

  const userOrgId = useSelector(getUserOrgId);
  const accessTokenValue = accessToken();

  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const response = await getAppointments();
      setAppointment(
        response.data
          .filter(
            (appointment) =>
              appointment.orgId === userOrgId &&
              (appointment.appointmentStatus === PRESCRIPTION_DONE ||
                appointment.appointmentStatus === COMPLETED)
          )
          .map((el) => {
            return {
              ...el,
              appointmentStatusDisplay: el.appointmentStatus.replace(/_/g, " "),
            };
          })
      );
    } catch (error) {
      handleClick("error", "Error fetching appointments");
    }
  }, [userOrgId, handleClick]);

  const extractedData = appointment.map((row) => {
    const patientName = row.patient.user?.name;
    return { ...row, patientName };
  });

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const handleGenerateInvoice = (appointment) => {
    navigate("/home/generateInvoice", {
      state: {
        appointmentId: appointment.id,
        patientId: appointment.patientId,
        doctorId: appointment.doctorId,
        orgId: appointment.orgId,
      },
    });
  };

  const isDownloadInvoiceDisable = (appointment) => {
    if (appointment?.appointmentStatus === COMPLETED) {
      return false;
    }
    return true;
  };

  const isGenerateInvoiceDisable = (appointment) => {
    if (appointment?.appointmentStatus === PRESCRIPTION_DONE) {
      return false;
    }
    return true;
  };

  const handleDownloadInvoice = async (appointment) => {
    try {
      // toDo need to check once the pr is merged
      const pdfRes = await getInvoicePDFById(null, appointment?.id);
      const blob = new Blob([pdfRes.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleClick("error", "Error downloading invoice PDF");
    }
  };

  const actions = [
    {
      label: "Generate Invoice",
      icon: <i className="ri-bill-line ri-1x icon-primary-blue" style={{ fontSize: "18px" }} />,
      onClick: handleGenerateInvoice,
      checkDisable: isGenerateInvoiceDisable,
    },
    {
      label: "Download Invoice",
      icon: <i className="ri-download-line ri-1x icon-primary-blue" style={{ fontSize: "18px" }} />,
      onClick: handleDownloadInvoice,
      checkDisable: isDownloadInvoiceDisable,
    },
    {
      label: "Print Invoice",
      icon: <i className="ri-printer-line ri-1x icon-primary-blue" style={{ fontSize: "18px" }} />,
      onClick: () => {},
      checkDisable: isDownloadInvoiceDisable,
    },
  ];

  return (
    <BillingForAppointmentsSkeleton>
      <Reveal className="appointment-container">
        <div className="section-heading">
          <h2 className="page-title">Billing</h2>
          <div className="buttons"></div>
        </div>

        <CustomizedTable columns={columns} tableData={extractedData} actions={actions} />
      </Reveal>
    </BillingForAppointmentsSkeleton>
  );
};

export const BillingForAppointmentsSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={250}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingForAppointments;
