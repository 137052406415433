import { ArrowCircleRightOutlined, BuildCircleOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";

const OnboardingRole = ({
  onboardingStep,
  setOnboardingStep,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  navigate,
  validateForm,
  setTouched,
  roles,
}) => {
  return (
    <div className="container content-div align-items-center justify-content-center animate__animated animate__fadeInUp animate__normal">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-12 align-items-center justify-content-center d-flex flex-column text-center custom-flex-box">
          <img src="/images/Mobile login-bro.png" alt="login-img" className="mx-auto" />
          <form className="form-flex-box align-items-center">
            <div className="mb-3">
              <label htmlFor="inputTypeText" className="form-label onboarding-label">
                Register as
              </label>
              <Grid>
                <FormSelectField
                  style={{ width: "100%", marginTop: "10px" }}
                  border={"1px solid #e6eef1"}
                  size="small"
                  background={"#fff"}
                  startAdornment={<BuildCircleOutlined />}
                  value={values.roleId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.roleId && touched.roleId)}
                  errorText={errors.roleId}
                  name={"roleId"}
                  outlinedInputStyle={{ lineHeight: "24.43px" }}
                  borderColorOnFocus={"#29bf91"}
                  displayEmpty
                  inputPropStyle={{ fontSize: "16px", fontWeight: "400" }}
                  menuItems={[
                    { value: "", menuLabel: "Please select your role" },
                    {
                      value: roles?.patientRoleId,
                      menuLabel: "Patient",
                    },
                    {
                      value: roles?.doctorRoleId,
                      menuLabel: "Doctor",
                    },
                  ]}
                />
              </Grid>
            </div>
            <div
              className="btn btn-onboarding-next"
              onClick={async () => {
                const c = await validateForm();
                const f = {};
                if (Object.keys(c).length === 0) {
                  if (values.roleId === roles?.patientRoleId) {
                    navigate("/register", {
                      state: {
                        patientDetail: { mobileNumber: values.mobileNumber },
                      },
                    });
                  }
                  setOnboardingStep((prev) => prev + 1);
                } else {
                  for (const prop in c) {
                    f[prop] = true;
                  }
                  setTouched(f);
                }
              }}
            >
              <span>Get Started</span>
              <ArrowCircleRightOutlined
                style={{ width: "18px", height: "18px" }}
              ></ArrowCircleRightOutlined>
            </div>
          </form>
          <p>
            By proceeding you agree to the{" "}
            <a
              href="https://hc.argusservices.in/refund-policy.html"
              target="_blank"
              rel="noreferrer"
              className="custom-a"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://hc.argusservices.in/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
              className="custom-a"
            >
              Privacy Policy
            </a>{" "}
            by <b className="text-success">Arog</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingRole;
