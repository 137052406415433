import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import ModalUI from "ui-component/ModalUI";
import SubCard from "ui-component/cards/SubCard";
import CustomButton from "ui-component/custom-components/CustomButton";
import OrganizationAddAmenity from "./OrganizationAddAmenity";
import { useState } from "react";
import { Field } from "formik";
import { AMENITY_ACTIONS } from "../ManageOrganization";
import Reveal from "views/utilities/Reveal";

const OrgAmenities = ({
  values,
  amenityState,
  amenityDispatch,
  orgDetail,
  setOrgDetail,
  setNewlyCreatedAmenity,
  fetchAmenities,
  amenityError,
}) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openAddAmenityModal = () => {
    setOpen(true);
  };

  return (
    <Reveal>
      <Grid container justifyContent="space-between" sx={{ mt: 5, mb: 2 }}>
        <CustomButton
          style={{ marginLeft: "auto" }}
          className="ri-add-fill ri-lg btn--primary"
          label="Add Amenity"
          onClick={() => openAddAmenityModal()}
        />
      </Grid>
      <SubCard style={{ padding: "15px" }}>
        <Grid container spacing={2}>
          {amenityState &&
            Object.entries(amenityState.organizedAmenities).map(([category, items], outerIndex) => (
              <Grid item xs={4} key={category}>
                <h4>{category}</h4>
                {category === "Payment Mode Accepted" && (
                  <>
                    {amenityError && (
                      <>
                        <Typography color="error" variant="body4">
                          {amenityError}
                        </Typography>
                      </>
                    )}
                  </>
                )}
                {items.flat().map((it, innerIndex) => {
                  const continuousIndex =
                    Object.entries(amenityState.organizedAmenities)
                      .slice(0, outerIndex)
                      .reduce((sum, [, arr]) => sum + arr.length, 0) + innerIndex;
                  return (
                    <Grid key={it.id} item>
                      <CheckboxField
                        index={continuousIndex}
                        amenityId={it.id}
                        label={it.name}
                        amenitiesCheckboxs={amenityState.filledAmenitiesCheckboxIds}
                        amenityDispatch={amenityDispatch}
                        amenityState={amenityState}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ))}
        </Grid>
      </SubCard>

      <ModalUI
        visible={open}
        close={closeModal}
        title="Add Amenity"
        component={
          <OrganizationAddAmenity
            amenitiesCategory={amenityState.amenitiesCategory}
            close={(amenity) => {
              setOrgDetail({
                ...orgDetail,
                doctorByOrg: values.doctors,
                org: {
                  name: values.name,
                  code: values.code,
                  state: values.state,
                  since_when: values.since_when,
                  contactName: values.contactName,
                  contactMobile: values.contactMobile,
                  contactEmail: values.contactEmail,
                  address: values.address,
                  ad_state: values.ad_state,
                  ad_district: values.ad_district,
                  ad_city: values.ad_city,
                  ad_pincode: values.ad_pincode,
                  latitude: values.latitude,
                  longitude: values.longitude,
                },
              });
              setNewlyCreatedAmenity(amenity);
              fetchAmenities(amenity);
              closeModal();
            }}
            onCancel={() => {
              closeModal();
            }}
          />
        }
      />
    </Reveal>
  );
};

const CheckboxField = ({ index, amenityId, label, amenityDispatch }) => {
  return (
    <Field type="checkbox" name={`amenities.${index}`}>
      {({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              onClick={(e) => {
                const orgCreationcheckboxValue = { id: amenityId };
                amenityDispatch({
                  type: AMENITY_ACTIONS.ON_CLICK_AMENITY_CHECKBOX,
                  payload: {
                    orgCreationcheckboxValue: orgCreationcheckboxValue,
                    amenityId: amenityId,
                    checked: e.target.checked,
                  },
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={label}
        />
      )}
    </Field>
  );
};

export default OrgAmenities;
