import { startsAndEndsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const abhaClinicAdminPageValidationForNewPatientRegistration = Yup.object().shape({
  name: Yup.string()
    .required("Full name is required")
    .min(3, "Minimum 3 characters required")
    .max(100, "Maximum 100 characters allowed")
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space")
    .test(
      "contains-alphabetic",
      "Name must start and end with with alphabet",
      startsAndEndsWithAlphabetic
    ),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Mobile number is required"),
  emailId: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .required("email is required")
    .max(255, "Maximum 255 characters allowed"),
  gender: Yup.string().required("gender is Required"),
});

export const abhaClinicAdminValidationForNewFamilyMemberRegistration = Yup.object().shape({
  name: Yup.string()
    .required("Full name is required")
    .min(3, "Minimum 3 characters required")
    .max(100, "Maximum 100 characters allowed")
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    ),
  gender: Yup.string().required("gender is Required"),
  relationship: Yup.string().required("relationship is required"),
  dateOfBirth: Yup.date().required("Required"),
});

export const abhaClinicAdminPageValidationForExistingUser = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Mobile number is required"),
  selectedPatientId: Yup.string().required("Please select a user from dropdown."),
});
