import "assets/scss/custom-charts.scss";
import { Cell, Label, Legend, Pie, PieChart } from "recharts";

const CustomDoughnutChart = ({ title, message }) => {
  // Specify the three key values with their respective colors
  const dataValues = [
    { name: "LDL", value: 20, color: "#F21E1E" },
    { name: "HDL", value: 30, color: "#4C49C7" },
    { name: "Triglycerides", value: 30, color: "#FF7A00" },
  ];

  const colors = [...dataValues.map((entry) => entry.color)];

  return (
    <div className="custom-doughnut-chart">
      <div className="heading">
        <div className="title">
          <p>{title}</p>
        </div>
        <div className="actions"></div>
      </div>
      <div className="main-chart">
        <PieChart width={250} height={150}>
          <Pie
            data={dataValues}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={55}
            outerRadius={60}
            startAngle={90}
            endAngle={450}
            paddingAngle={-10}
            cornerRadius={10}
            fill="#8884d8"
            stroke="none"
          >
            {dataValues.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
            <Label
              position="center"
              content={({ viewBox }) => {
                const { cx, cy } = viewBox;
                return (
                  <text
                    x={cx}
                    y={cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      fill: "#616B67",
                    }}
                  >
                    <tspan x={cx} dy="-0.5em">
                      HDL/LDL
                    </tspan>
                    <tspan x={cx} dy="1.2em">
                      Ratio
                    </tspan>
                  </text>
                );
              }}
            />
          </Pie>
          <Legend
            verticalAlign="middle"
            align="right"
            layout="vertical"
            iconType="square"
            iconSize={10}
            formatter={(value, entry) => <span style={{ color: entry.color }}>{entry.value}</span>}
          />
        </PieChart>
      </div>
      <div className="footer">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default CustomDoughnutChart;
