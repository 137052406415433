import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { entitiesInfo } from "store/constant";
import { Box } from "@mui/system";

const EntityInfoPage = () => {
  const location = useLocation();
  const { state } = location;
  const { data } = state || {};
  const [entityInformation, setEntityInformation] = useState([]);

  useEffect(() => {
    if (data) {
      const entitiesInformation = data.children
        .map((entity) => {
          const entityInfo = entitiesInfo.find((e) => e.id === entity.id);
          if (entityInfo) {
            return {
              id: entity.id,
              title: entity.title,
              info: entityInfo.info,
              icon: entityInfo.icon,
            };
          }
          return null;
        })
        .filter(Boolean);
      setEntityInformation(entitiesInformation);
    }
  }, [data]);

  return (
    <>
      <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
        Entities Information
      </h2>
      <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
      <div>
        <Box sx={{ padding: 2 }}>
          {entityInformation.map((entity) => (
            <Box
              key={entity.id}
              sx={{ marginBottom: 2, padding: 2, borderRadius: 2, backgroundColor: "#ffffff" }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item>{entity.icon}</Grid>
                <Grid item>
                  <Typography variant="h4" sx={{ fontWeight: "bold", color: "#007390" }}>
                    {entity.title}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1 }} />
              <Typography
                sx={{
                  marginTop: 1,
                  fontFamily: "Poppins, sans-serif",
                  color: "#555",
                  fontSize: "1rem",
                }}
              >
                {entity.info}
              </Typography>
            </Box>
          ))}
        </Box>
      </div>
    </>
  );
};

export default EntityInfoPage;
