import { configureStore } from "@reduxjs/toolkit";
import { customizationReducer } from "./Slices/customizationSlice";
import { userReducer } from "./Slices/userSlice";
import { appointmentReducer } from "./Slices/appointmentSlice";
import { appointmentDataReducer } from "./Slices/appointmentDataSlice";
import { doctorOnboardingReducer } from "./Slices/doctorOnboardingSlice";
import { prescriptionPadReducer } from "./Slices/prescriptionPadSlice";
import { cloudSyncReducer } from "./Slices/cloudSyncSlice";
import { demoReducer } from "./Slices/demoSlice";
import { modalReducer } from "./Slices/popUpModelSlice";
import { BillingInvoiceReducer } from "./Slices/billingInvoiceSlice";
export const store = configureStore({
  reducer: {
    customization: customizationReducer,
    user: userReducer,
    appointment: appointmentReducer,
    appointmentData: appointmentDataReducer,
    prescriptionPad: prescriptionPadReducer,
    doctorOnboarding: doctorOnboardingReducer,
    cloudSync: cloudSyncReducer,
    demo: demoReducer,
    modal: modalReducer,
    billingInvoice: BillingInvoiceReducer,
  },
});
