import {
  AddOutlined,
  ClearOutlined,
  CoronavirusOutlined,
  GroupOutlined,
  PersonOutlineOutlined,
  SevereColdOutlined,
} from "@mui/icons-material";
import { Autocomplete, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  createPatientFamilyHistory,
  deletePatientFamilyHistory,
  editPatientFamilyHistory,
  getPatientFamilyDetails,
  getPatientFamilyHistoryByPatientId,
  getProfilePicture,
} from "services/patientService";
import {
  currentActiveUser,
  familyRelations,
  DATE_FORMAT,
  startsAndEndsWithAlphabetic,
  startsWithAlphabetic,
  SEVERITY_TYPES,
} from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import Reveal from "views/utilities/Reveal";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "relationType", label: "Relation Type" },
  { field: "medicalCondition", label: "Medical Condition" },
  { field: "displaySeverity", label: "Severity" },
  { field: "onsetDate", label: "Since When" },
  { field: "Actions", label: "Actions" },
];

const PatientFamilyHistory = ({ patientId }) => {
  const [patientFamilyHistories, setPatientFamilyHistories] = useState([]);
  const [displayPatientFamilyHistories, setDisplayPatientFamilyHistories] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (organization) => {
    try {
      await deletePatientFamilyHistory(organization.id);
      handleClick("success", "Patient Family-history deleted successfully");
      const index = patientFamilyHistories.findIndex((el) => el.id === organization.id);
      patientFamilyHistories.splice(index, 1);
      setDisplayPatientFamilyHistories(patientFamilyHistories);
    } catch (error) {
      handleClick("error", "Family-history deletion failed");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pFamilyHistory) => {
    setSelected(pFamilyHistory);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchPatientFamilyHistories = async () => {
    try {
      const response = await getPatientFamilyHistoryByPatientId(patientId);
      setPatientFamilyHistories(response.data);
    } catch (error) {
      handleClick("error", "Error fetching patient family histories");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-1x icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    fetchPatientFamilyHistories();
  }, []);

  useEffect(() => {
    setDisplayPatientFamilyHistories(
      patientFamilyHistories.filter((item) => {
        item["displaySeverity"] = SEVERITY_TYPES.find((e) => e.value === item.severity)?.menuLabel;
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (typeof value === "string" && index !== 6) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return false;
        });
      })
    );
  }, [patientFamilyHistories, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search Family-history"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"btn--primary"}
          startIcon={<AddOutlined />}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomizedTable
        columns={columns}
        tableData={displayPatientFamilyHistories}
        actions={actions}
        rowsPerPageInTable={3}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Family History" : "Create Patient Family History"}
        component={
          <PatientProcedureModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientFamilyHistories();
              } else {
                closeModal();
              }
            }}
            patientId={patientId}
          />
        }
      />
    </Reveal>
  );
};

const PatientProcedureModal = ({ patientId, selected, handleClick, close }) => {
  const [relationType, setRelationType] = useState(
    selected?.relationType ? selected?.relationType : ""
  );
  const [patientRelations, setPatientRelations] = useState([]);
  const [relativeUserId, setRelativeUserId] = useState(
    selected?.relativeUserId ? selected?.relativeUserId : ""
  );
  const [medicalCondition, setMedicalCondition] = useState(
    selected?.medicalCondition ? selected?.medicalCondition : ""
  );
  const [medicalConditionTouched, setMedicalConditionTouched] = useState(false);

  const [onsetDate, setOnsetDate] = useState(
    selected?.onsetDate ? dayjs(selected?.onsetDate) : null
  );

  const [severity, setSeverity] = useState(selected?.severity ? selected?.severity : "");
  const [severityTouched, setSeverityTouched] = useState(false);

  const [errors, setErrors] = useState({});

  const currentDate = dayjs();
  const [dateTouched, setDateTouched] = useState(false);

  const fetchFamilyDetails = async () => {
    try {
      const familyId = currentActiveUser().familyResponse?.id;
      const response = await getPatientFamilyDetails(familyId);
      let content = null;
      const relations = await Promise.all(
        response.data.userFamilyMappingResponses?.map(async (relation) => {
          try {
            const profilePicResponse = await getProfilePicture(relation?.userId);
            content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
          } catch (error) {
            console.error("unable to fetch profile pic");
          }
          const genderBasedImage =
            relation?.userResponse.gender === "FEMALE" ? "/images/woman.png" : "/images/man.png";
          return {
            name: relation?.userResponse.name,
            relationship: relation?.relationship,
            profile: content ? content : genderBasedImage,
            userId: relation?.userId,
          };
        })
      );
      relations.push({ name: "None", relationship: null, profile: null, userId: null });
      setPatientRelations(relations.filter((relation) => relation.relationship !== "SELF"));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFamilyDetails();
  }, []);

  const minDateForFamilyHistory = dayjs("1800-01-01");

  const validateSeverity = (errors, severity) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!severity) {
      errors.severity = "Required";
    } else if (severity.length < 3) {
      errors.severity = "Must be at least 3 characters long";
    } else if (severity.length > 30) {
      errors.severity = "Cannot be longer than 30 characters";
    } else if (!pattern.test(severity)) {
      errors.severity = "can only contain letters and space";
    } else if (!startsWithAlphabetic(severity)) {
      errors.severity = "Must start with alphabet";
    }
  };

  const validateMedicalCondition = (errors, medicalCondition) => {
    const pattern = /^[a-zA-Z0-9\s]+$/;
    if (!medicalCondition) {
      errors.medicalCondition = "Required";
    } else if (medicalCondition.length < 3) {
      errors.medicalCondition = "Must be at least 3 characters long";
    } else if (medicalCondition.length > 30) {
      errors.medicalCondition = "Cannot be longer than 30 characters";
    } else if (!pattern.test(medicalCondition)) {
      errors.medicalCondition = "can't contain special characters";
    } else if (!startsAndEndsWithAlphabetic(medicalCondition)) {
      errors.medicalCondition = "Must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};
    if (!relationType) {
      errors.relationType = "Required";
    }

    setSeverityTouched(true);
    validateSeverity(errors, severity);

    setMedicalConditionTouched(true);
    validateMedicalCondition(errors, medicalCondition);

    setDateTouched(true);
    if (onsetDate === null) {
      errors.onsetDate = "Required.";
    } else if (!(onsetDate?.isValid ? onsetDate.isValid() : true)) {
      errors.onsetDate = "Invalid 'Date' value.";
    } else if (onsetDate > currentDate) {
      errors.onsetDate = "Date cannot be in future.";
    } else if (onsetDate < minDateForFamilyHistory) {
      errors.dateAdministered = "Date can't be less than the year 1800.";
    }

    return errors;
  };

  const saveFamilyHistory = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        relativeUserId,
        relationType: relationType,
        medicalCondition,
        onsetDate: dayjs(onsetDate).format(DATE_FORMAT),
        severity,
      };
      if (selected) {
        try {
          await editPatientFamilyHistory(selected.id, data);
          handleClick("success", "Patient Family-history updated successfully!!");
          close("save");
        } catch (error) {
          handleClick("error", "Error updating patient Family-history");
        }
      } else {
        try {
          await createPatientFamilyHistory(data);
          handleClick("success", "Patient Family-history created successfully!!");
          close("save");
        } catch (error) {
          handleClick("error", "Error creating patient Family-history");
        }
      }
    }
  };

  const getProfile = (relation) => {
    return relation.profile ? (
      <img
        src={relation?.profile}
        alt={relation?.name}
        style={{
          width: "11%",
          height: "10%",
          borderRadius: "8px",
          border: "1px solid grey",
          marginRight: "3px",
        }}
      />
    ) : null;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormSelectField
            style={{ width: "100%", marginTop: "7px" }}
            required
            label={"Relationship Type"}
            name={"relationType"}
            value={relationType}
            size={"big"}
            startAdornment={<GroupOutlined />}
            onChange={(e) => {
              setRelationType(e.target.value);
              setRelativeUserId(
                patientRelations.find((relation) => relation.relationship === e.target.value)
                  ?.userId || null
              );
              const newErrors = { ...errors };
              delete newErrors.relationType;
              setErrors(newErrors);
            }}
            menuItems={familyRelations}
            error={Boolean(errors.relationType)}
            errorText={errors.relationType}
          ></FormSelectField>
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormSelectField
            style={{ width: "100%", marginTop: "7px" }}
            label={"Family Member"}
            startAdornment={<PersonOutlineOutlined />}
            size={"big"}
            value={relativeUserId || selected?.userId || null}
            disabled={
              relationType
                ? patientRelations.find((relations) => relations.relationship === relationType)
                  ? false
                  : true
                : false
            }
            onChange={(event, value) => {
              const relation = patientRelations.find(
                (relation) => relation.userId === event.target.value
              );
              if (relation.name !== "None") setRelationType(relation.relationship);
              setRelativeUserId(event.target.value);
            }}
            renderValue={(value) => {
              const relation = patientRelations.find((relation) => relation.userId === value);
              return relation ? `${relation.name} - ${relation.relationship}` : "";
            }}
            menuItems={(relationType
              ? patientRelations.filter(
                  (relations) =>
                    relations.relationship === relationType || relations.name === "None"
                )
              : patientRelations
            )?.map((e) => {
              return {
                value: e?.userId,
                menuLabel: (
                  <div style={{ display: "flex", alignItems: "center", width: "315px" }}>
                    {getProfile(e) && getProfile(e)}
                    <div style={{ marginLeft: "8px" }}>
                      <div>{e.name}</div>
                      <div style={{ color: "#555" }}>{e.relationship}</div>
                    </div>
                  </div>
                ),
              };
            })}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Medical Condition"
            name="medicalCondition"
            value={medicalCondition}
            onBlur={(e) => {
              setMedicalConditionTouched(true);
              const newError = {};
              validateMedicalCondition(newError, medicalCondition);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setMedicalCondition(e.target.value);
              if (medicalConditionTouched) {
                const newError = {};
                validateMedicalCondition(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.medicalCondition;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<CoronavirusOutlined />}
            size={"big"}
            error={Boolean(errors.medicalCondition)}
            errorText={errors.medicalCondition}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormSelectField
            style={{ width: "100%", marginTop: "7px" }}
            label="Severity"
            name="severity"
            value={severity}
            onBlur={(e) => {
              setSeverityTouched(true);
              const newError = {};
              validateSeverity(newError, severity);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setSeverity(e.target.value);
              if (severityTouched) {
                const newError = {};
                validateSeverity(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.severity;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<SevereColdOutlined />}
            size={"big"}
            error={Boolean(errors.severity)}
            errorText={errors.severity}
            menuItems={SEVERITY_TYPES.filter((type) => type.menuLabel)}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormDatePicker
            label={"Since When"}
            disableFuture
            value={onsetDate}
            minDate={minDateForFamilyHistory}
            inputFormat="DD/MM/YYYY"
            size={"big"}
            onChange={(date) => {
              setOnsetDate(date);
              if (date === null) {
                setErrors({ ...errors, onsetDate: "Required." });
              } else if (!date.isValid()) {
                setErrors({ ...errors, onsetDate: "Invalid 'Date' value." });
              } else if (date > currentDate) {
                setErrors({
                  ...errors,
                  onsetDate: "Date cannot be in a future.",
                });
              } else if (date < minDateForFamilyHistory) {
                setErrors({
                  ...errors,
                  onsetDate: "Date can't be less than the year 1800.",
                });
              } else {
                const { onsetDate, ...newError } = errors;
                setErrors(newError);
              }
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.onsetDate && dateTouched)}
            errorText={errors.onsetDate}
          ></FormDatePicker>
        </Grid>

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={saveFamilyHistory}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientFamilyHistory;
