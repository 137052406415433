import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { updateVitalSignTypes } from "services/EntitiesServices";

const UpdateVitalSignType = ({ selected, close }) => {
  const [value, setValue] = useState(selected?.name);
  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);

  const validate = () => {
    const errors = {};
    if (!value) {
      errors.value = "Required";
    }

    return errors;
  };

  const UpdateVitalSignType = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      if (selected) {
        const data = {
          id: selected.id,
          name: value,
        };
        try {
          await updateVitalSignTypes(selected.id, data);
          handleClick("success", "Vital sign type updated successfully!");
          close();
        } catch (e) {
          handleClick("error", "Error updating vital-sign-type");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Vital Sign Type
      </Typography>
      <FormInputField
        style={{ marginTop: "20px" }}
        label={"Vital sign type"}
        value={value}
        error={Boolean(errors.value)}
        errorText={errors.value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        startAdornment={<DriveFileRenameOutlineOutlined />}
        size="small"
      />
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton className="btn--primary" onClick={UpdateVitalSignType} label="Update" />
      </Box>
    </Box>
  );
};

export default UpdateVitalSignType;
