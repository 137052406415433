import { api as API } from "./AxiosInterceptor";

export const createPrescriptions = (data) => API.post(`/api/prescriptions`, data);
export const getPrescriptionById = (id) => API.get(`/api/prescriptions/${id}`);
export const getMedicationsByPatientId = (patientId) =>
  API.get(`/api/prescriptions/medications/patient/${patientId}`);
export const updatePrescriptions = (data, id) => API.put(`/api/prescriptions/${id}`, data);

// prescription Search
export const searchPrescription = (param) =>
  API.get(`/api/prescription/search?query=${param}`, {
    showLoader: false,
  });

// prescriptions by Doctor id/Patient id
export const getPrescriptionByIds = (doctorId, patientId) => {
  return API.get(`/api/prescriptions/past-prescriptions`, {
    params: { doctorId, patientId },
  });
};

// creating prescription templates
export const createTemplates = (data) => API.post(`/api/prescription/templates`, data);

export const deleteTemplates = (id) => API.delete(`/api/prescription/templates/${id}`);
export const UpdateTemplate = (id, data) => API.put(`/api/prescription/templates/${id}`, data);

// prescription templates by doctor Id
export const getTemplatesByDocId = (docId) =>
  API.get(`/api/prescription/templates/doctor/${docId}`);

export const downloadPDF = async (appointmentId) => {
  return await API.get(`/api/prescriptions/generate-pdf?appointmentId=${appointmentId}`, {
    responseType: "blob",
  });
};

export const generatePrescription = (file) => API.post(`/api/prescription/search/audio`, file);

export const getPrescriptionByAppointmentId = (appointmentId) =>
  API.get(`/api/prescriptions/appointment`, {
    params: {
      appointmentId: appointmentId,
    },
  });

export const getEhrSummary = (patientId) => API.post(`/api/ehr/ehr-summary/${patientId}`);
export const getEhrData = (patientId) => API.get(`/api/ehr/${patientId}`);
