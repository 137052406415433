export const splitName = (fullName) => {
  const nameArr = fullName.trim().split(" ");

  const firstName = nameArr[0];
  const lastName = nameArr.length > 1 ? nameArr[nameArr.length - 1] : "";
  const middleName = nameArr.length > 2 ? nameArr.slice(1, -1).join(" ") : "";

  return {
    firstName,
    middleName,
    lastName,
  };
};
