import { api as API } from "./AxiosInterceptor";

// healthId creation APIs

// aadhaar apis
// export const generateAbdmAadhaarOtp = (data) =>
//   API.post(`/api/abdm/healthid/create/generate-aadhaar-otp`, data);
export const generateAbdmAadhaarOtp = (data) => API.post(`/api/abha/request-otp`, data);
// export const verifyAbdmAadhaarOtp = (data) =>
//   API.post(`/api/abdm/healthid/create/verify-aadhaar-otp`, data);
// export const verifyAbdmAadhaarOtp = (data) => API.post(`/api/abha/create`, data);
export const verifyAbdmAadhaarOtp = (patientId, data) =>
  API.post(`/api/abha/create?patientId=${patientId}`, data);
export const resendAbdmAadhaarOtp = (data) =>
  API.post(`/api/abdm/healthid/create/resend-aadhaar-otp`, data);

//mobile related apis
export const generateAbdmMobileOtp = (data) =>
  API.post(`/api/abdm/healthid/create/generate-mobile-otp`, data);
export const verifyAndGenerateAbdmMobileOtp = (data) =>
  API.post(`/api/abdm/healthid/create/check-and-generate-mobile-otp`, data);
// export const verifyAbdmMobileOtp = (data) =>
//   API.post(`/api/abdm/healthid/create/verify-mobile-otp`, data);
export const updateAbdmDetail = (patientId, data) =>
  API.post(`/api/abha/update?patientId=${patientId}`, data);

// creating health Id
export const createHealthIdWithPreVerified = (patientId, data) =>
  API.post(
    `/api/abdm/healthid/create/create-health-id-with-pre-verified?patientId=${patientId}`,
    data
  );

// generate abha token
export const getAbhaCard = (token) => {
  return API.get(`/api/abdm/healthid/account/get-abha-card`, {
    params: { token },
    responseType: "arraybuffer",
  });
};

// healthId capture Apis
export const searchHealthIdExist = (data) =>
  API.post(`/api/abdm/healthid/capture/search-health-id`, data);
export const getAunthentication = (data) => API.post(`/api/abdm/healthid/capture/auth-init`, data);
export const getMobileOtpDownload = (data) =>
  API.post(`/api/abdm/healthid/capture/confirm-mobile-otp-download`, data, {
    responseType: "arraybuffer",
  });
export const getAadhaarOtpDownload = (data) =>
  API.post(`/api/abdm/healthid/capture/confirm-aadhar-otp-download`, data, {
    responseType: "arraybuffer",
  });
