import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {
  accessToken,
  ACTIVE_STATE,
  ORGANIZATION,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
} from "store/constant";
import Grid from "@mui/material/Grid2";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import { Formik } from "formik";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { useReducer } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Slices/userSlice";
import CustomBackButton from "ui-component/custom-components/CustomBackButton";
import OrgBasicDetails from "./Child components/OrgBasicDetails";
import OrgLocation from "./Child components/OrgLocation";
import OrgAmenities from "./Child components/OrgAmenities";
import OrgPhotos from "./Child components/OrgPhotos";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useLoadScript } from "@react-google-maps/api";
import {
  createImageLInkedToOrg,
  createOrganization,
  deleteOrgImage,
  getAllAmenities,
  getDoctorsByOrg,
  getImagesByOrg,
  getOrgById,
  updateOrganization,
} from "services/organizationService";
import SubCard from "ui-component/cards/SubCard";
import { getServiceConfiguration } from "services/BillingService";
import { RegisterOrgSchema } from "../Common/ValidationSchema/ManageOrganizationFormValidation";
import { AddPhotoAlternate, Place } from "@mui/icons-material";

const libraries = ["places"];

export const FILE_ACTIONS = {
  FETCH_ORG_IMAGE: "FETCH_ORGANIZATION_IMAGES",
  UPLOAD_IMAGE: "UPLOAD IMAGES ON ORGANIZATION PAGE",
  REMOVE_IMAGE_WITH_ID: "REMOVE IMAGE WITH ID",
  REMOVE_IMAGE_WITHOUT_ID: "REMOVE IMAGE WITHOUT ID",
};

export const fileReducer = (state, action) => {
  switch (action.type) {
    case FILE_ACTIONS.FETCH_ORG_IMAGE:
      const filesWithContent = action.payload.responseImages.map((image) => ({
        file: image,
        content: `data:image/${image.extension};base64,${image.document}`,
      }));
      return {
        ...state,
        selectedFile: filesWithContent,
      };

    case FILE_ACTIONS.UPLOAD_IMAGE:
      const fileStateOrgCreate = {
        ...state,
        fileList: [...action.payload.files, ...state.fileList],
        selectedFile: [...action.payload.updatedSelectedFiles, ...state.selectedFile],
      };
      return fileStateOrgCreate;

    case FILE_ACTIONS.REMOVE_IMAGE_WITHOUT_ID:
      const updatedFileList = state.fileList.filter((file) => {
        return file !== action.payload.fileWithContent.file;
      });
      const updatedSelectedFile = state.selectedFile.filter((val) => {
        return val !== action.payload.fileWithContent;
      });
      return {
        ...state,
        fileList: updatedFileList,
        selectedFile: updatedSelectedFile,
      };

    case FILE_ACTIONS.REMOVE_IMAGE_WITH_ID:
      const updateSelectedFile = state.selectedFile.filter((val) => {
        return val !== action.payload.fileWithContent;
      });
      return {
        ...state,
        selectedFile: updateSelectedFile,
      };

    default:
      return state;
  }
};

export const AMENITY_ACTIONS = {
  FETCH_AMENITIES: "FETCH AMENITIES",
  SET_INITIAL_CHECKBOX_VALUES: "SET INITIAL CHECKBOX VALUES",
  ON_CLICK_AMENITY_CHECKBOX: "ON CLICK AMENITY CHECKBOX",
};

const amenityReducer = (state, action) => {
  switch (action.type) {
    case AMENITY_ACTIONS.FETCH_AMENITIES:
      let InitialCheckboxValues;
      let FilledAmenitiesCheckboxIds;

      const OrganizedAmenities = action.payload.amenities.reduce((accumulator, item) => {
        const { category } = item;
        if (!accumulator[category]) {
          accumulator[category] = [];
        }
        accumulator[category].push(item);
        return accumulator;
      }, {});

      if (action.payload.newCreatedAmenity) {
        FilledAmenitiesCheckboxIds = state.filledAmenitiesCheckboxIds
          ? state.filledAmenitiesCheckboxIds
          : [];
        FilledAmenitiesCheckboxIds.push({
          id: action.payload.newCreatedAmenity.id,
        });
      } else {
        FilledAmenitiesCheckboxIds = state.filledAmenitiesCheckboxIds
          ? state.filledAmenitiesCheckboxIds
          : [];
      }

      if (FilledAmenitiesCheckboxIds.length > 0) {
        InitialCheckboxValues = Object.values(OrganizedAmenities).flatMap(
          (orgAmenitiesSortedWithCategory) => {
            const checkboxs = orgAmenitiesSortedWithCategory.map((amenity) => {
              return FilledAmenitiesCheckboxIds.some(
                (filledAmenity) => filledAmenity.id === amenity.id
              );
            });
            return checkboxs;
          }
        );
      } else {
        InitialCheckboxValues = Array.from(
          { length: action.payload.amenities.length },
          () => false
        );
      }
      return {
        ...state,
        initialCheckboxValues: InitialCheckboxValues,
        amenities: action.payload.amenities,
        organizedAmenities: OrganizedAmenities,
        amenitiesCategory: Object.keys(OrganizedAmenities),
      };

    case AMENITY_ACTIONS.SET_INITIAL_CHECKBOX_VALUES:
      const valuesForIntialCheckBoxValues = Object.values(state.organizedAmenities).flatMap(
        (orgAmenitiesSortedWithCategory) => {
          const checkboxs = orgAmenitiesSortedWithCategory.map((amenity) => {
            return Array.from(action.payload.orgAmenitiesId).includes(amenity.id);
          });
          return checkboxs;
        }
      );
      return {
        ...state,
        filledAmenitiesCheckboxIds: action.payload.amenityObjectWithId,
        initialCheckboxValues: valuesForIntialCheckBoxValues,
      };

    case AMENITY_ACTIONS.ON_CLICK_AMENITY_CHECKBOX:
      if (action.payload.checked === true) {
        const amenityState = {
          ...state,
          filledAmenitiesCheckboxIds: [
            action.payload.orgCreationcheckboxValue,
            ...(state.filledAmenitiesCheckboxIds || []),
          ],
        };
        return amenityState;
      } else if (action.payload.checked === false) {
        // Checkbox is unchecked
        if (state.filledAmenitiesCheckboxIds.length > 0) {
          const updatedCheckboxes = state.filledAmenitiesCheckboxIds.filter(
            (checkbox) => checkbox.id !== action.payload.amenityId
          );
          return {
            ...state,
            filledAmenitiesCheckboxIds: updatedCheckboxes,
          };
        }
      }
      return state;

    default:
      return state;
  }
};

const ManageOrganizations = ({ ...others }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const { state } = useLocation();
  const orgId = useSelector(getUserOrgId);
  const organizationId = state?.organizationId ? state?.organizationId : orgId;
  const pendingTask = state?.pendingTask;
  const accessTokenValue = accessToken();

  const [dobError, setDobError] = useState(organizationId ? "" : "Please select a year.");
  const [pincodeError, setPincodeError] = useState("");

  const [amenityError, setAmenityError] = useState("");

  const validateAmenity = () => {
    const idsArrayOfPaymentModeAcceptedCategory = amenityState.organizedAmenities[
      "Payment Mode Accepted"
    ].map((el) => el.id);
    let isMatch = false;

    if (amenityState.filledAmenitiesCheckboxIds) {
      isMatch = amenityState.filledAmenitiesCheckboxIds.some((item) =>
        idsArrayOfPaymentModeAcceptedCategory.includes(item.id)
      );
    }

    if (isMatch) {
      const error = "";
      setAmenityError(error);
      return error;
    } else {
      const error = "Select atleast one payment mode";
      setAmenityError(error);
      return error;
    }
  };

  const navigate = useNavigate();
  const defaultCenter = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
  const [isLoading, setIsLoading] = useState(true);
  const [newlyCreatedAmenity, setNewlyCreatedAmenity] = useState();

  const defaultValues = {
    name: "",
    code: "",
    adState: null,
    sinceWhen: null,
    contactName: "",
    address: "",
    contactMobile: "",
    contactEmail: "",
    adDistrict: null,
    adSubDistrict: null,
    adPincode: "",
    status: ACTIVE_STATE,
    latitude: "",
    longitude: "",
  };

  const { handleClick } = useContext(ToastContext);

  const [ownershipArr, setownershipArr] = useState([]);

  // Organization image (file) related state variable
  const initialFileState = { selectedFile: [], fileList: [] };
  const [fileState, fileDispatch] = useReducer(fileReducer, initialFileState);

  // Organization amenities related state variable
  const initialAmenityState = {
    amenities: [],
    organizedAmenities: [],
    initialCheckboxValues: [],
    filledAmenitiesCheckboxIds: null,
    amenitiesCategory: [],
  };
  const [amenityState, amenityDispatch] = useReducer(amenityReducer, initialAmenityState);

  // basic organization detail related combined state variable
  const [orgDetail, setOrgDetail] = useState({
    center: defaultCenter,
    org: defaultValues,
  });

  useEffect(() => {
    if (amenityState.filledAmenitiesCheckboxIds) {
      validateAmenity();
    }
  }, [amenityState?.organizedAmenities, amenityState?.filledAmenitiesCheckboxIds]);

  // Doctor and image Remove list in update
  const [imageRemoveList, setImageRemoveList] = useState([]);

  useEffect(() => {
    // fetchSpecializations();
    fetchAmenities();
  }, [accessTokenValue]);

  useEffect(() => {
    if (organizationId && !Array.isArray(amenityState.organizedAmenities) && !newlyCreatedAmenity) {
      const fetchOrgData = async () => {
        try {
          let filteredDoctor = [];
          const response = await getOrgById(organizationId);
          const orgResponse = {
            ...response.data,
          };
          if (!orgResponse.sinceWhen) {
            setDobError("Please select a year.");
          }
          // if (orgResponse?.organizationDocuments?.length > 0) {
          //   setPhotosGrid(true);
          // }
          const orgAmenities = response.data.amenities;
          const orgAmenitiesId = orgAmenities.map((amenity) => {
            return amenity.id;
          });
          const amenityObjectWithId = orgAmenitiesId.map((amenityId) => {
            return { id: amenityId };
          });
          amenityDispatch({
            type: AMENITY_ACTIONS.SET_INITIAL_CHECKBOX_VALUES,
            payload: {
              amenityObjectWithId: amenityObjectWithId.length === 0 ? null : amenityObjectWithId,
              orgAmenitiesId,
            },
          });

          try {
            const responseImages = await getImagesByOrg(organizationId);
            fileDispatch({
              type: FILE_ACTIONS.FETCH_ORG_IMAGE,
              payload: {
                responseImages: Array.isArray(responseImages.data) ? responseImages.data : [],
              },
            });
          } catch (error) {
            console.error("Fetch images by Organization failed.");
          }

          try {
            const responseDoctorByOrg = await getDoctorsByOrg(organizationId);
            filteredDoctor = responseDoctorByOrg.data.map((doctor) => {
              return {
                id: doctor.id,
                userResponse: doctor.userResponse,
                name: doctor.userResponse.name,
              };
            });
            const customArr = [{ id: ORGANIZATION, name: "Organization" }];
            customArr.push(...filteredDoctor);
            setownershipArr(customArr);
          } catch (error) {
            setownershipArr([{ id: ORGANIZATION, name: "Organization" }]);
            console.error("Fecth doctors by organization failed");
          }
          setOrgDetail({
            ...orgDetail,
            center: {
              lat: response.data.latitude,
              lng: response.data.longitude,
            },
            org: orgResponse,
          });
          setIsLoading(false);
        } catch (error) {
          console.error("Fetch organization failed.");
          setIsLoading(false);
        }
      };
      fetchOrgData();
    } else if (!organizationId) {
      setIsLoading(false);
    }
  }, [amenityState.organizedAmenities]);

  useEffect(() => {
    if (ownershipArr.length > 0) {
      setServiceConfiguration();
    }
  }, [ownershipArr]);

  const fetchAmenities = async (newCreatedAmenity) => {
    try {
      const response = await getAllAmenities();
      amenityDispatch({
        type: AMENITY_ACTIONS.FETCH_AMENITIES,
        payload: {
          amenities: response.data,
          newCreatedAmenity,
        },
      });
    } catch (error) {
      console.error("Fetch amenities failed.");
    }
  };

  const [servicesArr, setServicesArr] = useState([]);

  const setServiceConfiguration = () => {
    const confData = async () => {
      try {
        const res = await getServiceConfiguration(organizationId, null, ORGANIZATION);
        const categories = res.data.map((el) => ({
          ...el,
          owner: el.ownerType === ORGANIZATION ? ORGANIZATION : el.doctorId,
        }));
        setServicesArr(categories);
      } catch (error) {
        console.error("API issue.");
      }
    };
    confData();
  };

  const [locationGrid, setLocationGrid] = useState(false);
  const [photosGrid, setPhotosGrid] = useState(false);
  // const getState = (states, orgDetail) => {
  //   return states.find((el) => el.name === orgDetail.org.adState?.name);
  // };

  // const getDistrict = (states, orgDetail) => {
  //   const districts = getState(states, orgDetail)?.districts;
  //   return districts.find((el) => el.name === orgDetail.org.adDistrict.name);
  // };

  // const getSubDistrict = async (states, orgDetail) => {
  //   const subDistrictResponse = await getSubDistrictByDistrict(
  //     getDistrict(states, orgDetail)?.code
  //   );
  //   return subDistrictResponse.data?.find((el) => el.name === orgDetail.org.adSubDistrict.name);
  // };

  // const [subDistrict, setSubDistrict] = useState(null);

  // useEffect(() => {
  //   if (organizationId && states.length > 0 && orgDetail?.org?.adSubDistrict) {
  //     const fetchSubDistrict = async () => {
  //       const subDistrictData = await getSubDistrict(states, orgDetail);
  //       setSubDistrict(subDistrictData);
  //     };

  //     fetchSubDistrict();
  //   }
  // }, [states, orgDetail]);

  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    mainContent.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, [locationGrid, photosGrid]);

  return (
    <ManageOrganizationSkeleton>
      <Reveal style={{ width: "100%", minHeight: "100vh" }}>
        {!isLoading && (
          <Formik
            validateOnMount={true}
            enableReinitialize={true}
            initialValues={{
              name: orgDetail?.org?.name ? orgDetail.org.name : "",
              // code: orgDetail?.org?.code ? orgDetail.org.code : "",
              status: orgDetail?.org?.status ? orgDetail.org.status : "",
              since_when: orgDetail?.org?.sinceWhen ? dayjs(orgDetail.org.sinceWhen) : null,
              contactName: orgDetail?.org?.contactName ? orgDetail.org.contactName : "",
              contactMobile: orgDetail?.org?.contactMobile ? orgDetail.org.contactMobile : "",
              contactEmail: orgDetail?.org?.contactEmail ? orgDetail.org.contactEmail : "",
              address: orgDetail?.org?.address ? orgDetail.org.address : "",
              adState: orgDetail?.org?.adState ? orgDetail?.org?.adState : null,
              adDistrict: orgDetail?.org?.adDistrict ? orgDetail?.org?.adDistrict : null,
              adSubDistrict: orgDetail?.org?.adSubDistrict ? orgDetail?.org?.adSubDistrict : null,
              adPincode: orgDetail?.org?.adPincode ? orgDetail.org.adPincode : "",
              latitude: orgDetail?.org?.latitude ? orgDetail.org.latitude : "",
              longitude: orgDetail?.org?.longitude ? orgDetail.org.longitude : "",
              // doctors: orgDetail.doctorByOrg,
              amenities: amenityState.initialCheckboxValues,
              categoryId: "",
              serviceName: "",
              amount: "",
              taxId: "",
              owner: "",
            }}
            validationSchema={RegisterOrgSchema}
            onSubmit={async (values) => {
              const data = {
                name: values.name,
                // code: values.code,
                adState: values.adState,
                sinceWhen: values.since_when,
                contactName: values.contactName,
                contactMobile: values.contactMobile,
                contactEmail: values.contactEmail,
                address: values.address,
                adDistrict: values.adDistrict,
                adSubDistrict: values.adSubDistrict,
                adPincode: values.adPincode,
                status: values.status,
                latitude: orgDetail.center.lat,
                longitude: orgDetail.center.lng,
                amenities: amenityState.filledAmenitiesCheckboxIds
                  ? amenityState.filledAmenitiesCheckboxIds
                  : [],
              };
              if (organizationId) {
                //For Update
                data["id"] = organizationId;
                const updateOrg = async () => {
                  try {
                    if (validateAmenity() === "") {
                      await updateOrganization(organizationId, data);
                    }
                    if (imageRemoveList.length > 0) {
                      const deletedImages = imageRemoveList.map(async (image) => {
                        return deleteOrgImage(image.id);
                      });
                      await Promise.all(deletedImages);
                    }
                    //Image upload for organization
                    if (fileState.fileList.length > 0) {
                      const formData = new FormData();
                      fileState.fileList.forEach((file) => {
                        formData.append("files", file);
                      });
                      await createImageLInkedToOrg(organizationId, formData);
                    }
                    handleClick("success", "Organization Details Updated Successfully");
                  } catch (error) {
                    const uploadDocumentApiPattern = /\/uploadDocuments$/;
                    const deleteDocumentApiPattern = /\/delete-document$/;
                    if (error?.config?.url === `/api/organizations/${organizationId}`) {
                      handleClick("error", "There seems to be an error updating clinic.");
                    } else if (uploadDocumentApiPattern.test(error?.config?.url)) {
                      handleClick("error", "There seems to be an error uploading Images.");
                    } else if (deleteDocumentApiPattern.test(error?.config?.url)) {
                      handleClick("error", "There seems to be an error deleteing Images.");
                    } else {
                      handleClick("error", "There seems to be an error updating clinic");
                    }
                    setTimeout(() => {
                      navigate(-1);
                    }, 1000);
                  }
                };
                updateOrg();
              } else {
                //  For Create
                const createOrg = async () => {
                  try {
                    const response = await createOrganization(data);
                    if (fileState.fileList.length > 0) {
                      const formData = new FormData();
                      fileState.fileList.forEach((file) => {
                        formData.append("files", file);
                      });
                      await createImageLInkedToOrg(response.data.id, formData);
                    }
                    handleClick("success", "Organization has been successfully added.");
                    setTimeout(() => {
                      navigate(-1);
                    }, 1000);
                  } catch (error) {
                    const UploadDocuemntApiPattern = /\/uploadDocuments$/;
                    if (error?.config?.url === `/api/organizations`) {
                      handleClick("error", error?.response?.data?.message);
                    } else if (UploadDocuemntApiPattern.test(error?.config?.url)) {
                      handleClick(
                        "error",
                        "Error uploading document for organization. (Organization and doctors created!)"
                      );
                    } else {
                      handleClick("error", error?.response?.data?.message);
                    }
                  }
                };
                createOrg();
              }
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              isValid,
              dirty,
              setFieldValue,
              setValues,
              setErrors,
              validateForm,
              setTouched,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomBackButton />
                  <Typography
                    sx={{
                      fontSize: "21px",
                      fontWeight: 600,
                      color: "#004C70",
                    }}
                  >
                    {organizationId ? "Update Clinic Details" : "Organization Registration"}
                  </Typography>
                </div>
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Grid sx={{ m: "30px 30px 20px 30px" }}>
                    <OrgBasicDetails
                      setErrors={setErrors}
                      setTouched={setTouched}
                      setDobError={setDobError}
                      dobError={dobError}
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                      pincodeError={pincodeError}
                      setPincodeError={setPincodeError}
                    />
                  </Grid>

                  <Grid sx={{ m: "30px 30px 20px 30px" }}>
                    <OrgAmenities
                      values={values}
                      amenityState={amenityState}
                      amenityDispatch={amenityDispatch}
                      orgDetail={orgDetail}
                      setOrgDetail={setOrgDetail}
                      setNewlyCreatedAmenity={setNewlyCreatedAmenity}
                      fetchAmenities={fetchAmenities}
                      amenityError={amenityError}
                    />
                  </Grid>

                  {locationGrid && (
                    <Grid sx={{ m: "30px 30px 20px 30px" }}>
                      <SubCard style={{ padding: "15px" }}>
                        <OrgLocation
                          orgDetail={orgDetail}
                          setOrgDetail={setOrgDetail}
                          setFieldValue={setFieldValue}
                          isLoaded={isLoaded}
                        />
                      </SubCard>
                    </Grid>
                  )}

                  {photosGrid && (
                    <Grid sx={{ m: "30px 30px 20px 30px" }}>
                      <OrgPhotos
                        fileState={fileState}
                        setFieldValue={setFieldValue}
                        fileDispatch={fileDispatch}
                        imageRemoveList={imageRemoveList}
                        setImageRemoveList={setImageRemoveList}
                      />
                    </Grid>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      ml: 3.6,
                      mr: 3.6,
                      mb: 3.6,
                    }}
                  >
                    <CustomButton
                      style={{ marginRight: "30px" }}
                      label={"Update Location"}
                      name="manageLocation"
                      onClick={() => {
                        setLocationGrid(!locationGrid);
                        setPhotosGrid(false);
                      }}
                      className="btn--primary"
                      startIcon={<Place />}
                    />
                    <CustomButton
                      label={"Manage Organization Photos"}
                      name="manageOrgPhotos"
                      onClick={() => {
                        setPhotosGrid(!photosGrid);
                        setLocationGrid(false);
                      }}
                      className="btn--primary"
                      startIcon={<AddPhotoAlternate />}
                    />
                    <CustomButton
                      style={{ marginLeft: "auto" }}
                      label="Cancel"
                      onClick={() => navigate(-1)}
                      className="btn--error"
                    />
                    <CustomButton
                      label={organizationId ? "Update" : "Save"}
                      onClick={handleSubmit}
                      type={"submit"}
                      className={"btn--primary"}
                      style={{ marginLeft: "30px" }}
                    />
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
      </Reveal>
    </ManageOrganizationSkeleton>
  );
};

const ManageOrganizationSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 4 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid container size={{ xs: 12 }} sx={{ justifyContent: "center" }}>
          {Array.from({ length: 6 }, (_, index) => index).map((el, index) => (
            <Grid
              container
              key={index}
              size={{ xs: index < 5 ? 2.28 : 0.57 }}
              alignItems={"center"}
            >
              <Grid size={{ xs: index < 5 ? 3 : 12 }}>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </Grid>
              <Grid size={{ xs: index < 5 ? 9 : 0 }}>
                {index < 5 && (
                  <Skeleton
                    sx={{ borderRadius: "4px" }}
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={3}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid size={{ xs: 12 }} ml={8} mr={8} mt={2}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={300}
          />
        </Grid>

        <Grid size={{ xs: 12 }} ml={8} mr={8} display={"flex"} justifyContent={"space-between"}>
          <Grid size={{ xs: 4 }} display={"flex"}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="30%"
              height={30}
            />
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="30%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 1.5 }} display={"flex"}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageOrganizations;
