// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-icon-margin .css-gcc2o7-MuiButton-startIcon {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.btn--primary-light {
  background-color: #e6eef1;
  color: #004c70;
}
.btn--primary-light:hover {
  background-color: #e6eef1;
  color: #004c70;
}

.btn--primary {
  background-color: #004c70;
  color: #ffffff;
}
.btn--primary:hover {
  background-color: #004c70;
  color: #ffffff;
}

.btn--secondary-light {
  background-color: #def1ee;
  color: #004c70;
}
.btn--secondary-light:hover {
  background-color: #def1ee;
  color: #004c70;
}

.btn--secondary {
  background-color: #29bf91;
  color: #ffffff;
}
.btn--secondary:hover {
  background-color: #29bf91;
  color: #ffffff;
}

.btn--error {
  background-color: rgba(255, 112, 112, 0.1019607843);
  color: #ff7070;
}
.btn--error:hover {
  background-color: rgba(255, 112, 112, 0.1019607843);
  color: #ff7070;
}

.css-gcc2o7-MuiButton-startIcon {
  display: inherit;
  margin-right: 0px;
  margin-left: 0px;
}

.css-1gnd1fd-MuiButton-endIcon {
  display: inherit;
  margin-right: 0px;
  margin-left: 0px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/customButtonStyle.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;EACA,yBAAA;AAAJ;;AAIA;EACE,yBAAA;EACA,cAAA;AADF;AAGE;EACE,yBAAA;EACA,cAAA;AADJ;;AAKA;EACE,yBAAA;EACA,cAAA;AAFF;AAIE;EACE,yBAAA;EACA,cAAA;AAFJ;;AAMA;EACE,yBAAA;EACA,cAAA;AAHF;AAKE;EACE,yBAAA;EACA,cAAA;AAHJ;;AAOA;EACE,yBAAA;EACA,cAAA;AAJF;AAME;EACE,yBAAA;EACA,cAAA;AAJJ;;AAQA;EACE,mDAAA;EACA,cAAA;AALF;AAOE;EACE,mDAAA;EACA,cAAA;AALJ;;AASA;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;AANF;;AASA;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;AANF","sourcesContent":[".no-icon-margin {\n  .css-gcc2o7-MuiButton-startIcon {\n    margin-right: 0 !important;\n    margin-left: 0 !important;\n  }\n}\n\n.btn--primary-light {\n  background-color: #e6eef1;\n  color: #004c70;\n\n  &:hover {\n    background-color: #e6eef1;\n    color: #004c70;\n  }\n}\n\n.btn--primary {\n  background-color: #004c70;\n  color: #ffffff;\n\n  &:hover {\n    background-color: #004c70;\n    color: #ffffff;\n  }\n}\n\n.btn--secondary-light {\n  background-color: #def1ee;\n  color: #004c70;\n\n  &:hover {\n    background-color: #def1ee;\n    color: #004c70;\n  }\n}\n\n.btn--secondary {\n  background-color: #29bf91;\n  color: #ffffff;\n\n  &:hover {\n    background-color: #29bf91;\n    color: #ffffff;\n  }\n}\n\n.btn--error {\n  background-color: #ff70701a;\n  color: #ff7070;\n\n  &:hover {\n    background-color: #ff70701a;\n    color: #ff7070;\n  }\n}\n\n.css-gcc2o7-MuiButton-startIcon {\n  display: inherit;\n  margin-right: 0px;\n  margin-left: 0px;\n}\n\n.css-1gnd1fd-MuiButton-endIcon {\n  display: inherit;\n  margin-right: 0px;\n  margin-left: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
