export const SET_IS_SYNCING = "SET_IS_SYNCING";
export const SET_SHOW_SYNC_MODAL = "SET_SHOW_SYNC_MODAL";
export const SET_SYNC_STATUS = "SET_SYNC_STATUS";
export const SET_LAST_SYNC_INFO = "SET_LAST_SYNC_INFO";

export const setIsSyncing = (payload) => {
  return { type: SET_IS_SYNCING, payload };
};

export const setShowSyncModal = (payload) => {
  return { type: SET_SHOW_SYNC_MODAL, payload };
};

export const setSyncStatus = (payload) => {
  return { type: SET_SYNC_STATUS, payload };
};

export const setLastSyncInfo = (payload) => {
  return { type: SET_LAST_SYNC_INFO, payload };
};
