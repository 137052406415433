// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotate-loop {
  animation: rotationLoop 3s linear infinite;
}

@keyframes rotationLoop {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/animations.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":[".rotate-loop {\n  animation: rotationLoop 3s linear infinite;\n}\n\n@keyframes rotationLoop {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
