import React from "react";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const EditSelectField = ({
  name,
  menuItems,
  error,
  style,
  size = "big",
  isEditable,
  errorText,
  initialValue,
  index,
  field,
  handleUpdate,
  isTeleconsultation = false,
  ...restProps
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [background, setBackground] = useState("transparent");
  const [value, setValue] = useState(initialValue === null ? menuItems[0].value : initialValue);

  useEffect(() => {
    setBackground(readOnly ? "transparent" : "#e3e3e3");
  }, [readOnly]);

  const handleDoubleClick = () => {
    if (isEditable) {
      setReadOnly(false);
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setReadOnly(true);
      handleUpdate(index, field, event.target.value);
    }
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      <FormControl error={error} sx={style}>
        <Select
          sx={{
            "& .MuiSelect-select": {
              paddingTop: readOnly ? "0px" : "5px",
              paddingBottom: readOnly ? "10px" : "5px",
              paddingLeft: readOnly ? "0px" : "10px",
              fontFamily: "IBM Plex Mono",
              fontSize: "14px",
              lineHeight: "18px",
              color: isTeleconsultation ? (readOnly ? "#ffffff" : "#485772") : "#485772",
              fontWeight: "500",
              borderRadius: "0px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSelect-icon": {
              display: readOnly ? "none" : undefined,
            },
            background: background,
          }}
          MenuProps={{ MenuListProps: { disablePadding: true } }}
          inputProps={{
            sx: {
              background: background,
            },
          }}
          onKeyDownCapture={handleKeyPress}
          value={value}
          onBlur={(event) => {
            setReadOnly(true);
            handleUpdate(index, field, event.target.value);
          }}
          readOnly={readOnly}
          onChange={handleChange}
          {...restProps}
        >
          {menuItems.map((row, index) => {
            return (
              <MenuItem name={name} key={index} value={row.value}>
                {row.menuLabel}
              </MenuItem>
            );
          })}
        </Select>
        {error && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default EditSelectField;
