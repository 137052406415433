import * as Yup from "yup";

export const articleValidationSchema = Yup.object().shape({
  contentType: Yup.string().required("Required"),
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

export const mediaValidationSchema = Yup.object().shape({
  contentType: Yup.string().required("Required"),
  title: Yup.string().required("Required"),
  link: Yup.string().required("Required"),
});

export const campaignValidationSchema = Yup.object().shape({
  contentType: Yup.string().required("Required"),
  title: Yup.string().required("Required"),
});
