import * as Yup from "yup";

export const featureCodeSchema = Yup.object().shape({
  featureCode: Yup.string()
    .max(50, "Code is too long")
    .matches(
      /^[A-Z0-9]+([_-][A-Z0-9]+)*$/,
      "Can contain only uppercase letters, numbers, and the special characters _ and -. Cannot start or end with _ or -"
    ),
});

export const featureNameSchema = Yup.object().shape({
  featureName: Yup.string().max(50, "Name is too long"),
});

export const featureDescriptionSchema = Yup.object().shape({
  featureDescription: Yup.string().max(255, "Text is too long"),
});
