import { api as API } from "./AxiosInterceptor";

//login with mobile number
export const generateMobileLoginOtp = (data) =>
  API.post(`/api/abdm/hpr/auth/login-via-mobile-send-otp`, data);

export const mobileVerifyOtp = (data) =>
  API.post(`/api/abdm/hpr/auth/login-via-mobile-verify-otp`, data);

export const userAuthorizedToken = (data) =>
  API.post(`/api/abdm/hpr/auth/login-via-mobile-generate-token`, data);

//login with HPID
export const verifyHealthId = (data) => API.post(`/api/abdm/hpr/search/exist-by-hpr-id`, data);

export const authInit = (data) => API.post(`/api/abdm/hpr/auth/auth-init-for-login`, data);

export const confirmPassword = (data) => API.post(`/api/abdm/hpr/auth/auth-confirm-password`, data);

export const confirmMobileOtp = (data) =>
  API.post(`/api/abdm/hpr/auth/auth-confirm-mobile-otp`, data);

export const confirmAadhaarOtp = (data) =>
  API.post(`/api/abdm/hpr/auth/auth-confirm-aadhaar-otp`, data);

export const getLoggedInProfile = () => API.get(`/api/abdm/hpr/account/profile`);

//HFR Utility Apis
export const getStates = () => API.get(`/api/abdm/hfr/utility/states`);

export const getDistricts = (id) => API.get(`/api/abdm/hfr/utility/districts?stateCode=${id}`);

export const getSubDistricts = (id) =>
  API.get(`/api/abdm/hfr/utility/sub-districts?districtCode=${id}`);

export const getMasterType = () => API.get(`/api/abdm/hfr/utility/master-type`);

export const getMasterData = (type) => API.get(`/api/abdm/hfr/utility/master-data?type=${type}`);

export const getSpecialities = (data) => API.post(`/api/abdm/hfr/utility/specialities`, data);

export const getOwnerSubType = (data) => API.get(`/api/abdm/hfr/utility/owner-sub-type`, data);

export const getFacilityType = (data) => API.post(`/api/abdm/hfr/utility/facility-type`, data);

export const getFacilitySubType = (data) =>
  API.post(`/api/abdm/hfr/utility/facility-sub-type`, data);

export const basicInformation = (id, data) =>
  API.post(`/api/abdm/hfr/onboarding/basic-information?id=${id}`, data);

export const additionalInformation = (id, data) =>
  API.post(`/api/abdm/hfr/onboarding/additional-information?id=${id}`, data);

export const detailedInformation = (id, data) =>
  API.post(`/api/abdm/hfr/onboarding/detailed-information?id=${id}`, data);

export const submitFacility = (id, data) =>
  API.post(`/api/abdm/hfr/onboarding/submit-facility?id=${id}`, data);

export const scanAndShareQr = (hfrId, counterId, text) =>
  API.get(`/api/scan-and-share/get-qr-code`, {
    responseType: "blob",
    params: {
      hfrId: hfrId,
      counterId: counterId,
      text: text,
    },
  });
