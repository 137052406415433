import { Grid, Menu, MenuList } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getProfilePicture } from "services/patientService";
import {
  CLINIC_VISIT,
  currentActiveUser,
  DATE_FORMAT,
  FEMALE,
  TELE_CONSULTATION,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
} from "store/constant";
import CustomButton from "./CustomButton";
import CustomMenuItem from "./CustomMenuItem";
import { useNavigate } from "react-router";
import { calculateAge } from "utils/calculate-age";

const RecentVisitItem = ({ appointment }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const currentUserRole = currentActiveUser()?.roleName;
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const actions = [
    {
      label: "View Prescription",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: (appointmentDetail) =>
        navigate("/home/myPrescription/" + appointmentDetail.appointmentId),
    },
    {
      label: "View PDF",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: (appointmentDetail) =>
        navigate("/home/prescription/" + appointmentDetail.appointmentId),
    },
  ];

  const formattedTime = (timeString) => {
    let [hour, minute, seconds] = timeString.split(":");
    hour = hour % 24;

    let hour12 = hour % 12 || 12;
    let ampm = hour < 12 ? "AM" : "PM";

    minute = minute < 10 ? "0" + minute : minute;

    return `${hour12}:${minute} ${ampm}`;
  };

  const today = dayjs();
  const tomorrow = today.add(1, "day");
  const [profileImage, setProfileImage] = useState(null);
  const genderBasedImage = appointment?.gender === FEMALE ? "/images/woman.png" : "/images/man.png";

  let appointmentDay;
  if (dayjs(appointment?.appointmentDate).isSame(today, "day")) {
    appointmentDay = "Today";
  } else if (dayjs(appointment?.appointmentDate).isSame(tomorrow, "day")) {
    appointmentDay = "Tomorrow";
  } else {
    appointmentDay = dayjs(appointment?.appointmentDate).format(DATE_FORMAT);
  }

  const fetchProfilePicture = async () => {
    try {
      const profilePicResponse = await getProfilePicture(
        currentUserRole === "PATIENT" ? appointment?.doctorUserId : appointment?.patientUserId
      );
      const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;

      setProfileImage({
        filename: profilePicResponse.data.filename,
        content,
      });
    } catch (error) {
      console.error("Error fetching profile picture", error);
    }
  };

  useEffect(() => {
    fetchProfilePicture();
  }, [appointment]);

  return (
    <li
      className="mui-card list-card"
      style={{
        marginBottom: "10px",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Grid container className="row mx-0 mb-0 w-100">
        <Grid item md={3} className="px-0">
          <div style={{ marginTop: "5px" }}>
            <img
              style={{
                borderRadius: "8%",
                width: "80px",
                height: "80px",
                border: "2px solid #e0e0e0",
              }}
              className="patient-avatar"
              src={profileImage ? profileImage?.content : genderBasedImage}
              alt=""
            />
          </div>
        </Grid>

        <Grid item md={9} className="px-0">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: "10px" }}>
              <p
                className="patient-name"
                style={{ fontSize: "18px", fontWeight: "600", margin: "0", color: "#333" }}
              >
                {currentUserRole === "PATIENT" ? appointment?.doctorName : appointment?.patientName}
              </p>
              <p
                className="patient-details"
                style={{ fontSize: "14px", color: "#777", margin: "4px 0 0" }}
              >
                {currentUserRole === "PATIENT" ? (
                  <span>{appointment?.organizationName}</span>
                ) : (
                  <>
                    <span>{calculateAge(appointment?.dateOfBirth)} years</span>
                    <span> | </span>
                    <span>{appointment.gender ? appointment.gender : "NA"}</span>
                  </>
                )}
              </p>
            </div>
            {currentUserRole === "PATIENT" && (
              <>
                <CustomButton
                  iconButton={<i className="ri-more-2-fill" />}
                  onClick={(event) => handleMenuOpen(event)}
                  aria-label="Actions"
                  style={{
                    padding: "8px",
                    minWidth: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#f5f5f5",
                  }}
                />
                <Menu
                  elevation={1}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <MenuList dense sx={{ p: 0 }}>
                    {actions.map((action, actionIndex) => (
                      <CustomMenuItem
                        key={actionIndex}
                        onClick={() => {
                          handleMenuClose();
                          action.onClick(appointment);
                        }}
                        icon={action.icon}
                        text={action.label}
                        style={{ padding: "12px 16px", fontSize: "14px", color: "#333" }}
                      />
                    ))}
                  </MenuList>
                </Menu>
              </>
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            <div
              className="mt-3"
              style={{
                borderTop: "1px solid #e0e0e0",
                paddingTop: "8px",
                marginTop: "8px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {appointment?.type === CLINIC_VISIT && (
                <div
                  className="visit-type-offline"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#4caf50",
                    fontSize: "14px",
                  }}
                >
                  <i className="ri-walk-fill ri-lg" style={{ marginRight: "8px" }} />
                  {TEXT_FOR_CLINIC_VISIT}
                </div>
              )}
              {appointment?.type === TELE_CONSULTATION && (
                <div
                  className="visit-type-online"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#2196f3",
                    fontSize: "14px",
                  }}
                >
                  <img
                    src="/images/hc_online_icon.svg"
                    className="online"
                    alt="online"
                    style={{ marginRight: "8px" }}
                  />
                  {TEXT_FOR_TELECONSULTATION}
                </div>
              )}
              <div style={{ color: "grey" }}>|</div>
              <div style={{ fontSize: "14px", color: "#333" }}>
                {formattedTime(appointment?.appointmentTime)}
              </div>
              <div style={{ color: "grey" }}>|</div>
              <div style={{ fontSize: "14px", color: "#333" }}>{appointmentDay}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </li>
  );
};

export default RecentVisitItem;
