import React from "react";
import { TextField, FormHelperText, FormControl } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const EditInputField = ({
  name,
  error,
  style,
  errorText,
  initialValue = "",
  handleUpdate,
  index,
  field,
  textLimitations,
  isTeleconsultation = false,
  ...restProps
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [background, setBackground] = useState("transparent");
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);

  useEffect(() => {
    setBackground(readOnly ? "#004C700D" : "#e3e3e3");
  }, [readOnly]);

  const handleDoubleClick = () => {
    setReadOnly(false);
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(value?.length, value?.length);
    }
  };

  const formatDuration = (input) => {
    // Regular expression to match numeric part
    const matchNumeric = input?.match(/\d+/);
    const numericPart = matchNumeric ? parseInt(matchNumeric[0]) : 0;

    // Regular expression to match string part
    const matchString = input?.match(/([^0-9]+)\b/);
    const stringPart = matchString ? matchString[0].trim() : "";

    let durationUnit;
    switch (stringPart.toLowerCase().charAt(0)) {
      case "m":
        durationUnit = "Month";
        break;
      case "w":
        durationUnit = "Week";
        break;
      case "y":
        durationUnit = "Year";
        break;
      default:
        durationUnit = "Day";
        break;
    }

    return `${numericPart} ${durationUnit}${numericPart > 1 ? "s" : ""}`;
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (field === "duration" || field === "since") {
        const formattedDuration = formatDuration(event.target.value);
        setValue(formattedDuration);
        handleUpdate(index, field, formattedDuration);
      } else {
        handleUpdate(index, field, event.target.value);
      }
      setReadOnly(true);
    }
  };

  return (
    <div style={{ width: "100%" }} onDoubleClick={handleDoubleClick}>
      <FormControl sx={{ width: "100%" }}>
        <TextField
          variant="outlined"
          sx={{
            // Root class for the input field
            "& .MuiOutlinedInput-root": {
              backgroundColor: readOnly ? "transparent" : "#e3e3e3",
              borderRadius: "0px",
            },
            "& .MuiOutlinedInput-input": {
              paddingTop: readOnly ? "0px !important" : "5px !important",
              paddingBottom: readOnly ? "10px !important" : "5px !important",
              paddingLeft: readOnly ? "0px !important" : "10px !important",
              fontFamily: "IBM Plex Mono",
              fontSize: "14px",
              lineHeight: "18px",
              color: isTeleconsultation ? (readOnly ? "#ffffff" : "#485772") : "#485772",
              fontWeight: "500",
              borderRadius: "0px",
              backgroundColor: "transparent",
              cursor: readOnly ? "pointer" : undefined,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none ",
            },

            // Class for the label of the input field
          }}
          error={error}
          style={{ ...style }}
          inputRef={inputRef}
          value={value}
          onKeyDown={handleKeyPress}
          onBlur={(event) => {
            setReadOnly(true);
            handleUpdate(index, field, event.target.value);
          }}
          inputProps={{
            maxLength: textLimitations.maxLength,
            readOnly: readOnly,
            backgroundcolor: background,
          }}
          size="small"
          multiline={textLimitations.multiline}
          maxRows={textLimitations.maxRows}
          onChange={handleChange}
          {...restProps}
        />
        {error && (
          <FormHelperText error id={`standard-weight-helper-text-${name}`}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default EditInputField;
