import { useState, useEffect, useRef } from "react";
import { api } from "services/AxiosInterceptor";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import AddAvailability from "../Appointment/AddAvailability";
import {
  ACTIVE_STATE,
  CLINIC_VISIT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  currentActiveUser,
} from "store/constant";
import EditAvailabilityCard from "../Availability/EditAvailabilityCard";
import CustomButton from "ui-component/custom-components/CustomButton";
import "assets/scss/availability.scss";
import { getDoctorAvailability, getDoctorById } from "services/doctorService";
import { deleteAvailabilities } from "services/Availability";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import { Box, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Slices/userSlice";
import { useLocation } from "react-router";
import "react-datepicker/dist/react-datepicker.css";

const DoctorAvailability = () => {
  const { handleClick } = useContext(ToastContext);
  const [availabilities, setAvailabilities] = useState([]);
  const [doctorDetail, setDoctorDetail] = useState(null);
  const [doctorOrganizations, setDoctorOrganizations] = useState([]);
  const doctorId = currentActiveUser()?.roleBasedId;
  const [editAvailabilityData, seteditAvailabilityData] = useState({});
  const [isUpdate, setisUpdate] = useState(false);
  const doctorOrgId = useSelector(getUserOrgId);

  const { state } = useLocation();
  const pendingTask = state?.pendingTask;
  const [open, setOpen] = useState(pendingTask ? true : false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteAvailability = async (availability) => {
    try {
      await deleteAvailabilities(availability.id);
      handleAvailabilities();
    } catch (error) {
      handleClick("error", "There seems to be an error deleting availability");
    }
  };

  const getDataForEditAvailability = (availability) => {
    seteditAvailabilityData(availability);
    setisUpdate(true);
  };

  const handleAvailabilities = async () => {
    try {
      const availabilityResponse = await getDoctorAvailability(doctorId);
      setAvailabilities(availabilityResponse);
    } catch (error) {
      console.error("Error fetching availability");
    }
  };

  /* Will be used when we have to edit block availability */

  // const handleEditBlockAvailability = async (data) => {
  //   try {
  //     const response = await editBlockAvailabilities(data);
  //   } catch (error) {
  //     handleClick("error", "Error editing block availability");
  //   }
  // };

  const manageAvailabilities = (organization) => {
    let clinicVisitAvailabilities = [],
      teleconsultationAvailabilities = [];
    const availabilitiesArr = Array.isArray(availabilities) ? availabilities : [availabilities];
    availabilitiesArr.forEach((element) => {
      if (element.organizationId === organization.id) {
        if (element.consultationType === CLINIC_VISIT) {
          clinicVisitAvailabilities.push(element);
        } else {
          teleconsultationAvailabilities.push(element);
        }
      }
    });
    return {
      clinicArr: clinicVisitAvailabilities,
      teleArr: teleconsultationAvailabilities,
    };
  };

  const getAvailabilitiesForOtherOrganizations = () => {
    const availabilitiesArr = Array.isArray(availabilities) ? availabilities : [availabilities];
    return availabilitiesArr.filter((el) => el.organizationId !== doctorOrgId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const doctorResponse = await getDoctorById(doctorId);
        setDoctorDetail(doctorResponse.data);
        const organizationIds = [];
        doctorResponse.data.userResponse.userOrganizationAssociationList.forEach((org) => {
          if (org.isDoctor === true && org.status === ACTIVE_STATE) {
            organizationIds.push(org.organizationId);
          }
        });
        const organizationResponse = await api.get(`/api/organizations/ids?ids=${organizationIds}`);
        setDoctorOrganizations(organizationResponse.data);

        const availabilityResponse = await getDoctorAvailability(doctorId);
        setAvailabilities(availabilityResponse);
      } catch (error) {
        console.error("Error fetching availabilities.");
      }
    };
    fetchData();
  }, [doctorId]);

  return (
    <DoctorAvailabilitySkeleton doctorOrganizations={doctorOrganizations}>
      <div className="doctor-availability">
        <Reveal
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <h6 className="page-title">My Availability</h6>
          </div>
          <div className="col" style={{ position: "relative" }}>
            <CustomButton
              className="ri-add-fill ri-lg btn--secondary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleOpen();
                setisUpdate(false);
              }}
              label="Add Availability"
            />
          </div>
        </Reveal>

        {doctorOrganizations.map((organization, index) => (
          <Reveal key={index}>
            <EditAvailabilityCard
              cardInfo={{
                name: organization.name,
                address: organization.address,
              }}
              handleOpen={handleOpen}
              getDataForEditAvailability={getDataForEditAvailability}
              handleDeleteAvailability={handleDeleteAvailability}
              clinicVisitAvailabilities={manageAvailabilities(organization).clinicArr}
              teleconsultationAvailabilities={manageAvailabilities(organization).teleArr}
            ></EditAvailabilityCard>
            <br></br>
          </Reveal>
        ))}
      </div>
      <AddAvailability
        isOpen={open}
        pendingTask={pendingTask}
        onClose={handleCloseModal}
        doctors={[doctorDetail]}
        docOrganizations={doctorOrganizations}
        availabilities={availabilities}
        handleAvailabilities={handleAvailabilities}
        columns={[]}
        actionsForAvailabilities={[]}
        isUpdate={isUpdate}
        isDelete={false}
        editAvailabilityData={editAvailabilityData}
        isDoctorAdding={true}
        otherOrgavailabilities={getAvailabilitiesForOtherOrganizations()}
      ></AddAvailability>
    </DoctorAvailabilitySkeleton>
  );
};

const DoctorAvailabilitySkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        {Array.from({ length: 2 }, (_, index) => index).map((el, index) => (
          <Grid size={{ xs: 12 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={135}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DoctorAvailability;
