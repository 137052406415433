import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import userReducer from "./Reducers/userReducer";
import appointmentReducer from "./Reducers/appointmentReducer";
import appointmentDataReducer from "./Reducers/appointmentDataReducer";
import doctorOnboardingReducer from "./Reducers/doctorOnboardingReducer";
import prescriptionPadReducer from "./Reducers/prescriptionPadReducer";
import cloudSyncReducer from "./Reducers/CloudSyncReducer";
import demoReducer from "./Reducers/DemoReducer";
// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
  customization: customizationReducer,
  user: userReducer,
  appointment: appointmentReducer,
  appointmentDataReducer: appointmentDataReducer,
  prescriptionPadReducer: prescriptionPadReducer,
  doctorOnboarding: doctorOnboardingReducer,
  cloudSync: cloudSyncReducer,
  demo: demoReducer,
});

const reducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return rootReducer(state, action);
};

export default reducer;
