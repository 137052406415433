import { api as API } from "./AxiosInterceptor";

export const createNotification = (data) => API.post(`/api/notifications`, data);
export const getAllNotifications = () => API.get(`/api/notifications`);
export const deleteNotification = (id) => API.delete(`/api/notifications/${id}`);
export const updateNotification = (id, data) => API.put(`/api/notifications/${id}`, data);

export const getAllNotificationTypes = () => API.get(`/api/notification-types`);
export const createNotificationType = (data) => API.post(`/api/notification-types`, data);
export const getNotificationType = (id) => API.get(`/api/notification-types/${id}`);
export const updateNotificationType = (id, data) => API.put(`/api/notification-types/${id}`, data);
export const deleteNotificationType = (id) => API.delete(`/api/notification-types/${id}`);
