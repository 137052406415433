import { useState, useEffect } from "react";
import { Box, Input, Typography } from "@mui/material";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router";
import * as React from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomButton from "ui-component/custom-components/CustomButton";
import SubCard from "ui-component/cards/SubCard";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import {
  ARTICLE,
  CAMPAIGN,
  DATE_FORMAT_DMY,
  MEDIA,
  accessToken,
  contentTypes,
} from "store/constant";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import {
  createContent,
  getContentById,
  getPosterForCampaign,
  updateContent,
} from "services/ContentService";
import {
  articleValidationSchema,
  campaignValidationSchema,
  mediaValidationSchema,
} from "../Common/ValidationSchema/contentValidationSchema";
import {
  CategoryOutlined,
  CloudUpload,
  Code,
  Description,
  Link,
  PhotoSizeSelectActual,
  Tag,
  Title,
  PersonOutline,
} from "@mui/icons-material";

const ManageContent = () => {
  const { handleClick } = useContext(ToastContext);

  const navigate = useNavigate();

  const navigateToPatients = () => {
    navigate("/home/health-feeds");
  };

  const { state } = useLocation();
  const contentId = state["contentId"];

  const [content, setContent] = useState(null);
  const [file, setFile] = useState(null);
  const [contentType, setContentType] = useState(
    content?.contentType ? content?.contentType : contentTypes[0]?.value
  );
  const accessTokenValue = accessToken();
  const [posterName, setPosterName] = useState(null);

  useEffect(() => {
    if (contentId) {
      const fetchData = async () => {
        try {
          const response = await getContentById(contentId);
          setContent(response?.data);
          setContentType(response?.data?.contentType);
        } catch (error) {
          console.error("Error fetching content");
        }
      };
      fetchData();
    }
  }, [accessTokenValue, contentId, handleClick]);

  const handleFileUploads = async (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (!allowedTypes.includes(file?.type)) {
      handleClick("error", "Only JPG, JPEG, and PNG files are allowed");
      return;
    }
    let updatedSlected;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const s = new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({ name: file.name, value: reader.result });
      };
    });

    try {
      const updatedFiles = await s;
      updatedSlected = updatedFiles;
      setFile(file);
      setPosterName(updatedSlected?.name);
    } catch (e) {
      handleClick("error", "Sorry! Your file can not be uploaded.");
    }
  };

  useEffect(() => {
    const fetchPoster = async () => {
      try {
        const poster = await getPosterForCampaign(content?.poster);
        const posterContent = `data:image/${poster?.data.extension};base64,${poster.data.document}`;
        const posterWithContent = {
          filename: poster.data.filename,
          content: posterContent,
        };
        setPosterName(posterWithContent?.filename);
      } catch (e) {
        console.error("Can not fetch poster for campaign");
      }
    };

    if (contentId) {
      if (content?.poster) {
        fetchPoster();
      }
    }
  }, [handleClick, content?.poster, contentId]);

  return (
    <Reveal>
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: content?.title ? content?.title : "",
          description: content?.description ? content?.description : "",
          tags: content?.tags ? content?.tags : "",
          contentType: contentType ? contentType : "",
          reference: content?.reference ? content?.reference : "",
          link: content?.link ? content?.link : "",
          poster: content?.poster ? content?.poster : "",
          startDate: content?.startDate ? dayjs(content?.startDate) : null,
          endDate: content?.endDate ? dayjs(content?.endDate) : null,
          actionCode: content?.actionCode ? content?.actionCode : "",
          author: content?.author ? content?.author : "",
          submit: "",
        }}
        validationSchema={() => {
          if (contentType === ARTICLE) {
            return articleValidationSchema;
          } else if (contentType === MEDIA) {
            return mediaValidationSchema;
          } else {
            return campaignValidationSchema;
          }
        }}
        onSubmit={async (values) => {
          let data = {};
          if (contentType === ARTICLE) {
            data = {
              contentType: contentType,
              articleResponse: {
                title: values?.title,
                description: values?.description,
                tags: values?.tags,
                link: values?.link,
                reference: values?.reference,
                auhtor: values?.author,
              },
            };
          } else if (contentType === MEDIA) {
            data = {
              contentType: contentType,
              mediaResponse: {
                title: values?.title,
                description: values?.description,
                tags: values?.tags,
                link: values?.link,
              },
            };
          } else if (contentType === CAMPAIGN) {
            data = {
              contentType: contentType,
              campaignResponse: {
                title: values?.title,
                description: values?.description,
                tags: values?.tags,
                actionCode: values?.actionCode,
                startDate: values?.startDate,
                endDate: values?.endDate,
              },
            };
          }

          const formData = new FormData();
          formData.append(
            "contentResponse",
            new Blob([JSON.stringify(data)], { type: "application/json" })
          );
          if (file) {
            formData.append("file", file);
          }

          if (contentId) {
            try {
              await updateContent(contentId, formData);
              handleClick("success", "Content has been successfully updated.");
              navigate("/home/health-feeds");
            } catch (e) {
              handleClick("error", "There seems to be an error updating content");
            }
          } else {
            try {
              await createContent(formData);
              handleClick("success", "Content has been successfully created.");
              navigate("/home/health-feeds");
            } catch (e) {
              handleClick("error", "There seems to be an error creating content");
            }
          }
        }}
      >
        {({ errors, touched, values, setFieldValue, handleBlur, handleChange, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
                mb: 2,
              }}
            >
              {contentId ? "Update Content" : "Create Content"}
            </Typography>
            <SubCard style={{ padding: "15px" }}>
              <FormSelectField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Content Type"
                name="contentType"
                required
                value={values.contentType}
                onBlur={handleBlur}
                onChange={(e) => {
                  setContentType(e.target.value);
                }}
                menuItems={contentTypes}
                error={Boolean(touched.contentType && errors.contentType)}
                errorText={errors.contentType}
                startAdornment={<CategoryOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Title"
                name="title"
                required
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.title && errors.title)}
                errorText={errors.title}
                startAdornment={<Title />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Description"
                name="description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.description && errors.description)}
                errorText={errors.description}
                startAdornment={<Description />}
                size={"big"}
                multiline
              />

              <FormInputField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Tags (Comma Separated String)"
                name="tags"
                value={values.tags}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.tags && errors.tags)}
                errorText={errors.tags}
                startAdornment={<Tag />}
                size={"big"}
              />

              {(contentType === ARTICLE || contentType === MEDIA) && (
                <FormInputField
                  style={{
                    width: "100%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  required={contentType === MEDIA}
                  label="link"
                  name="link"
                  value={values.link}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.link && errors.link)}
                  errorText={errors.link}
                  startAdornment={<Link />}
                  size={"big"}
                />
              )}

              {contentType === ARTICLE && (
                <>
                  <FormInputField
                    style={{
                      width: "48.5%",
                      marginRight: "30px",
                      marginBottom: "25px",
                    }}
                    label="Reference"
                    name="reference"
                    value={values.reference}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.reference && errors.reference)}
                    errorText={errors.reference}
                    startAdornment={<PersonOutline />}
                    size={"big"}
                  />

                  <FormInputField
                    style={{
                      width: "48.5%",
                      marginBottom: "25px",
                    }}
                    label="Author"
                    name="author"
                    value={values.author}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.author && errors.author)}
                    errorText={errors.author}
                    startAdornment={<PersonOutline />}
                    size={"big"}
                  />
                </>
              )}

              {contentType === CAMPAIGN && (
                <>
                  <FormInputField
                    style={{
                      width: "80%",
                      marginRight: "30px",
                      marginBottom: "25px",
                    }}
                    label="Poster"
                    name="poster"
                    value={posterName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.poster && errors.poster)}
                    errorText={errors.poster}
                    startAdornment={<PhotoSizeSelectActual />}
                    size={"big"}
                  />
                  <CustomButton
                    className="btn--primary"
                    startIcon={<CloudUpload />}
                    label="Browse"
                    component="label"
                    style={{
                      width: "15%",
                      height: "50px",
                    }}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileUploads(e);
                      }}
                      inputProps={{}}
                    />
                  </CustomButton>

                  <FormInputField
                    style={{
                      width: "100%",
                      marginBottom: "25px",
                    }}
                    label="Action Code"
                    name="actionCode"
                    value={values.actionCode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.actionCode && errors.actionCode)}
                    errorText={errors.actionCode}
                    startAdornment={<Code />}
                    size={"big"}
                  />

                  <FormDatePicker
                    format={DATE_FORMAT_DMY}
                    disablePast
                    label={"Start Date"}
                    value={values.startDate}
                    size={"big"}
                    required
                    onChange={(date) => {
                      setFieldValue("startDate", date);
                    }}
                    style={{
                      width: "48.5%",
                      marginBottom: "25px",
                      marginRight: "30px",
                    }}
                  />

                  <FormDatePicker
                    format={DATE_FORMAT_DMY}
                    disablePast
                    label={"End Date"}
                    value={values.endDate}
                    size={"big"}
                    required
                    onChange={(date) => {
                      setFieldValue("endDate", date);
                    }}
                    minDate={values?.startDate}
                    style={{
                      width: "48.5%",
                      marginBottom: "25px",
                    }}
                  />
                </>
              )}
            </SubCard>

            <Box sx={{ mt: 3 }} display="flex">
              <CustomButton
                className="btn--secondary"
                style={{ marginRight: "20px" }}
                type="submit"
                label={"Save"}
              ></CustomButton>
              <CustomButton
                className="btn--error"
                onClick={() => navigateToPatients()}
                label="Cancel"
              />
            </Box>
          </form>
        )}
      </Formik>
    </Reveal>
  );
};

export default ManageContent;
