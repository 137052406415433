import { api as API } from "./AxiosInterceptor";

// availability Apis
export const getAvailabilities = (id) => API.get(`/api/availabilities/${id}`);
export const createAvailabilities = (data) => API.post(`/api/availabilities`, data);
export const updateAvailabilities = (availabilityId, data) =>
  API.put(`/api/availabilities/${availabilityId}`, data);
export const deleteAvailabilities = (availabilityId) =>
  API.delete(`/api/availabilities/${availabilityId}`);

// availabiliies for block-calendar
export const getBlockAvailabilities = (docId, orgId) =>
  API.get(`/api/availabilities/block?doctorId=${docId}&organizationId=${orgId}`);

export const getBlockAvailabilitiesByDate = (docId, date) =>
  API.get(`/api/availabilities/block/doctor?doctorId=${docId}&date=${date}`);

export const cancelBlockAvailability = (id) => API.delete(`/api/availabilities/block/${id}`);

export const createBlockAvailabilities = (data) => API.post(`/api/availabilities/block`, data);

export const editBlockAvailabilities = (data) => API.put(`/api/availabilities/block`, data);

// availabilities for slots
export const getSlotsAvailabilities = (searchParams, data = null) => {
  return API.post(`/api/availabilities/slots`, data, {
    params: searchParams,
    headers: {
      "Content-Type": "application/json", // Set the correct Content-Type header
    },
  });
};

// availabilities for check-in
export const checkInAvailabilities = (data) => API.put(`/api/availabilities/check-in`, data);

export const currentStatusDoctor = (doctorId, organizationId) =>
  API.get(
    `/api/availabilities/current-status?doctorId=${doctorId}&organizationId=${organizationId}`
  );
