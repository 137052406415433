import React from "react";
import { STOMACH } from "utils/ehr-data-utils";

const HumanStomach = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  const isSelected = selectedOrgan.includes(STOMACH);

  return (
    <div
      id="human-body-stomach"
      className={`human-stomach ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <img
        //   style={{ height: "82px", width: "16px" }}
        src="/images/stomach.svg"
        alt="Outer Body"
        className={`stomach hover body-part ${isSelected ? "highlight" : ""}`}
      />
    </div>
  );
};

export default HumanStomach;
