import React, { useState, useEffect } from "react";
import AddAvailability from "./AddAvailability";
import "assets/scss/appointment.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import TimerSharpIcon from "@mui/icons-material/TimerSharp";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  CLINIC_ADMIN,
  DATE_FORMAT,
  HOUR_MINUTE_FORMAT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  currentActiveUser,
  orgId,
  SCHEDULED,
  RESCHEDULED,
  FRONT_DESK,
  DOCTOR,
  roleName,
  NO_SHOW,
  PRESCRIPTION_DONE,
} from "store/constant";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { getAppointments, updateAppointmentStatus } from "services/Appointments";
import { useContext } from "react";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import ModalUI from "ui-component/ModalUI";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Actions/appointmentDataAction";
import { getDoctorByOrgId } from "services/doctorService";
import { getOrgById } from "services/organizationService";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import { Box, Grid, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { Person } from "@mui/icons-material";

const getColumns = () => {
  let columns;

  const c = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "patientName", label: "PATIENT NAME" },
    { field: "appointmentDate", label: "DATE" },
    { field: "appointmentTime", label: "TIME" },
    { field: "appointmentStatus", label: "Status" },
    { field: "Actions", label: "ACTIONS", actions: true },
  ];

  if (roleName() === FRONT_DESK || roleName() === CLINIC_ADMIN) {
    columns = [...c.slice(0, 2), { field: "doctorName", label: "DOCTOR NAME" }, ...c.slice(2)];
    return columns;
  } else {
    return c;
  }
};

const Appointment = () => {
  const dispatch = useDispatch();

  const [appointment, setAppointment] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const [availabilityOpen, setAvailabilityOpen] = useState(false);
  const [currOrg, setcurrOrg] = useState({});
  const [doctorLabel, setDoctorLabel] = useState("All");
  const [isModalOpen, setisModalOpen] = useState(false);

  const userOrgId = orgId();
  const accessTokenValue = accessToken();

  const handleModalClose = () => {
    dispatch(setSelectedAppointmentData({}));
    setisModalOpen(false);
    fetchData();
  };

  const handleModalOpen = () => {
    dispatch(
      setSelectedAppointmentData({
        orgId: userOrgId,
        appointmentDate: dayjs().format(DATE_FORMAT),
      })
    );
    setisModalOpen(true);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await getAppointments();
      if (
        currentActiveUser()?.roleName === CLINIC_ADMIN ||
        currentActiveUser()?.roleName === FRONT_DESK ||
        currentActiveUser()?.roleName === DOCTOR
      ) {
        if (doctorLabel !== "All") {
          setAppointment(
            response.data.filter(
              (appointment) => appointment.doctor.userResponse.name === doctorLabel
            )
          );
        } else {
          setAppointment(response.data.filter((appointment) => appointment.orgId === userOrgId));
        }
      } else {
        setAppointment(response.data);
      }
    } catch (error) {
      handleClick("error", "Error fetching appointments");
    }

    try {
      const response = await getDoctorByOrgId(userOrgId);
      setDoctors(response.data);
    } catch (error) {
      handleClick("error", "Issue fetching doctors.");
    }

    try {
      const organizationResponse = await getOrgById(userOrgId);
      if (organizationResponse) {
        setcurrOrg(organizationResponse.data);
      }
    } catch (error) {
      handleClick("error", "Issue fetching doctors.");
    }
  }, [doctorLabel, userOrgId, handleClick]);

  const extractedData = appointment.map((row) => {
    const patientName = row.patient.user?.name;
    const doctorName = row.doctor.userResponse.name;
    return {
      ...row,
      appointmentStatus:
        row?.appointmentStatus === NO_SHOW
          ? "NO SHOW"
          : row?.appointmentStatus === PRESCRIPTION_DONE
          ? "PRESCRIPTION DONE"
          : row?.appointmentStatus,
      patientName,
      doctorName,
    };
  });

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const handleDelete = async (appointment) => {
    try {
      await updateAppointmentStatus(appointment.id, "CANCELLED");
      handleClick("success", "Appointment cancelled successfully!");
      fetchData();
    } catch (error) {
      handleClick("error", "Appointment deletion failed");
    }
  };

  const isDisable = (row) => {
    if (row?.appointmentStatus === SCHEDULED || row?.appointmentStatus === RESCHEDULED) {
      const now = dayjs();
      const nowString = now.format(DATE_FORMAT);
      const currentTime = now.format(HOUR_MINUTE_FORMAT);
      if (
        nowString === row.appointmentDate &&
        currentTime > row.appointmentTime.replaceAll(" ", "")
      ) {
        return true;
      } else {
        return undefined;
      }
    } else {
      return true;
    }
  };

  const handleFilterAppointments = (label) => {
    setDoctorLabel((prevLabel) => {
      if (label?.label === prevLabel) {
        fetchData();
        return prevLabel;
      } else {
        return label?.label;
      }
    });
  };

  const actions = [
    {
      label: "Cancel",
      icon: <i className="ri-delete-bin-fill ri-1x icon-primary-blue" />,
      onClick: handleDelete,
      checkDisable: isDisable,
    },
  ];

  return (
    <AppointmentSkeleton>
      <Reveal className="appointment-container">
        <div className="section-heading">
          <h2 className="page-title">All Appointments</h2>
          <div className="buttons">
            <CustomButton
              className="button btn--primary"
              onClick={() => {
                setAvailabilityOpen(true);
              }}
              icon={<TimerSharpIcon />}
              label="Set Availability"
            ></CustomButton>
            <CustomButton
              onClick={handleModalOpen}
              className="button ri-add-fill ri-lg btn--secondary"
              label="New Appointment"
            ></CustomButton>
          </div>
        </div>
        {(roleName() === FRONT_DESK || roleName() === CLINIC_ADMIN) && (
          <div className="section-heading">
            <CustomButton
              label={doctorLabel}
              style={{ marginLeft: "auto" }}
              className={"btn--secondary-light"}
              startIcon={<Person />}
              endIcon={<i className="ri-arrow-down-s-fill" />}
              dropDownMenuItems={[
                { label: "All" },
                ...doctors.map((doctor) => ({
                  label: doctor?.userResponse?.name,
                })),
              ]}
              onMenuItemClick={handleFilterAppointments}
              menuItemHoverColor="#29BF91"
            />
          </div>
        )}

        <CustomizedTable columns={getColumns()} tableData={extractedData} actions={actions} />
        <AddAvailability
          isOpen={availabilityOpen}
          onClose={() => {
            dispatch(setSelectedAppointmentData({}));
            setAvailabilityOpen(false);
          }}
          doctors={doctors}
          docOrganizations={[currOrg]}
          handleAvailabilities={() => {}}
          columns={[]}
          isUpdate={false}
          isDoctorAdding={false}
        ></AddAvailability>

        <ModalUI
          visible={isModalOpen}
          close={handleModalClose}
          title="Book Appointment"
          style={{
            overflowY: "scroll",
            height: "550px",
            width: "610px",
          }}
          component={
            <BookAppointmentModal handleClick={handleClick} closeModal={handleModalClose} />
          }
        />
      </Reveal>
    </AppointmentSkeleton>
  );
};

export const AppointmentSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={250}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Appointment;
