import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Checkbox, FormControlLabel, FormHelperText, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import CustomButton from "ui-component/custom-components/CustomButton";
import AnimateButton from "ui-component/extended/AnimateButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { PersonOutlineOutlined } from "@mui/icons-material";
import { createUserProfile } from "services/userService";
import { userSignUpSchema } from "views/Components/Common/ValidationSchema/manageUserValidation";
import { ACTIVE_STATE, getRoleIdByName, PATIENT } from "store/constant";
import { useContext } from "react";

// ===========================|| FIREBASE - REGISTER ||=========================== //

const FirebaseRegister = ({ ...others }) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const { handleClick } = useContext(ToastContext);

  const location = useLocation();
  const patientDetail = location?.state?.patientDetail || {};
  // const googleId = state ? state.googleId : null;

  return (
    <>
      <Formik
        initialValues={{
          fullName: patientDetail.fullName,
          mobileNumber: patientDetail.mobileNumber,
          email: patientDetail.emailId,
          submit: null,
        }}
        validationSchema={userSignUpSchema}
        onSubmit={async (values) => {
          const data = {
            name: values.fullName,
            mobileNumber: values.mobileNumber,
            emailId: values.email,
            roleId: await getRoleIdByName(PATIENT, handleClick),
            status: ACTIVE_STATE,
            roleName: PATIENT,
          };
          try {
            await createUserProfile(data);
            handleClick("success", "User has been successfully added!");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } catch (error) {
            if (String(error.response.data.message) === "Please pass unique Mobile number") {
              handleClick("error", error.response.data.message);
              return;
            }
            handleClick("error", "There seems to be an error adding the user.");
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Grid container>
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormInputField
                  style={{
                    width: "100%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Full Name"
                  name="fullName"
                  required
                  value={values.fullName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.fullName && errors.fullName)}
                  errorText={errors.fullName}
                  startAdornment={<PersonOutlineOutlined />}
                  size={"big"}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormInputField
                  style={{
                    width: "100%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Mobile Number"
                  type={"tel"}
                  required
                  name="mobileNumber"
                  inputProps={{
                    maxLength: 10,
                  }}
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  errorText={errors.mobileNumber}
                  startAdornment={<i className="ri-phone-line ri-xl" />}
                  size={"big"}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormInputField
                  style={{
                    width: "100%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Email Address"
                  type="email"
                  name="email"
                  required
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email)}
                  errorText={errors.email}
                  startAdornment={<i className="ri-mail-line ri-lg" />}
                  size={"big"}
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="space-between">
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="subtitle1">
                      Agree with &nbsp;
                      <Typography variant="subtitle1" component={Link} to="#">
                        Terms & Condition.
                      </Typography>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <CustomButton
                  className="btn--primary"
                  onClick={() => handleSubmit()}
                  disabled={!checked}
                  disableElevation
                  width="100%"
                  type="button"
                  label="Sign Up"
                  height="45px"
                />
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FirebaseRegister;
