import { useState, useEffect, useRef } from "react";
import { Box, Checkbox, FormControlLabel, ListItem, ListItemText, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router";
//  for Date
import * as React from "react";
import {
  doctorSchemaForClinicAdmin,
  doctorSchemaForSuperAdmin,
} from "./../Common/ValidationSchema/manageDoctorValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  CLINIC_ADMIN,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  DOCTOR,
  MAX_AGE_FOR_REGUAR_DOB,
  MIN_AGE_FOR_DOCTOR,
  SUPER_ADMIN,
  accessToken,
  baseWebUrl,
  currentActiveUser,
  genderList,
  getRoleIdByName,
  orgId,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { ControlCameraOutlined, DomainOutlined } from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import SubCard from "ui-component/cards/SubCard";
import { getAllOrganizations, sendUserInvitationLink } from "services/organizationService";
import {
  deleteUserOrgLink,
  getPrimaryUserByMobileNumber,
  linkUserWithOrganization,
  updateLinkUserOrg,
} from "services/userService";
import { getDoctorById, updateDoctor } from "services/doctorService";
import { useContext } from "react";
import { getSpecializations } from "services/EntitiesServices";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import { v4 as uuidv4 } from "uuid";

const ManageDoctor = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);
  const formikRef = useRef();
  const organizationId = orgId();
  const [isClinicAdmin, setIsClinicAdmin] = useState(() => {
    const currentUser = currentActiveUser();
    if (currentUser) {
      return (
        currentUser.roleName === CLINIC_ADMIN ||
        currentUser.userOrganizationAssociationList?.some((association) => association.isAdmin)
      );
    }
    return false;
  });

  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");
  const MAX_DATE_FOR_DOCTOR = dayjs().subtract(MIN_AGE_FOR_DOCTOR, "year");
  const [showField, setShowField] = useState(false);
  useEffect(() => {
    if (isClinicAdmin || currentActiveUser()?.roleName === SUPER_ADMIN) {
      setShowField(true);
    }
  });

  const navigate = useNavigate();

  const navigateToDoctors = () => {
    navigate("/home/doctor");
  };

  const { state } = useLocation();
  const doctorId = state["doctorId"];
  const accessTokenValue = accessToken();

  const [organizations, setOrganizations] = useState([]);
  const [specializations, setSpecializations] = useState([]);

  const [selectedOrganizations, setSelectedOrganizations] = useState([]); // State for selected organizations (IDs)
  const [copySelectedOrganizations, setCopySelectedOrganizations] = useState([]);
  const [prevSelectedOrg, setPrevSelectedOrg] = useState([]);
  const [isDoctorMap, setIsDoctorMap] = useState({}); // State to map org ID to isDoctor selection
  const [isAdminMap, setIsAdminMap] = useState({});
  const [dobError, setDobError] = useState(doctorId ? "" : "Date of Birth is required.");
  const [mobileNumber, setMobileNumber] = useState("");
  const [doctorUserDetails, setDoctorUserDetails] = useState(null);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // get organizations
        const response = await getAllOrganizations();
        let organizationsList = response.data;
        if (isClinicAdmin) {
          const userOrganizations = currentActiveUser()
            .userOrganizationAssociationList.filter((org) => org.isAdmin === true)
            .map((org) => org.organizationId);

          // Filter organizations based on the userOrganizations
          organizationsList = organizationsList.filter((org) =>
            org.userAssociations.some((association) =>
              userOrganizations.includes(association?.organizationId)
            )
          );
          setOrganizations(organizationsList);
        } else {
          setOrganizations(response.data);
        }
        // get specializations
        const speResponse = await getSpecializations();
        setSpecializations(speResponse.data);
      } catch (error) {
        console.error("Error fetching data");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  useEffect(() => {
    validateCheckboxes();
  }, [selectedOrganizations, isDoctorMap, isAdminMap]);

  const [doctor, setDoctor] = useState(null);
  useEffect(() => {
    if (doctorId) {
      const fetchData = async () => {
        try {
          const response = await getDoctorById(doctorId);
          setDoctor(response.data);
          const doctorMap = {};
          const adminMap = {};

          const selectedOrg = [];
          let organizaitonsToSearch = [];
          if (isClinicAdmin) {
            organizaitonsToSearch = [
              response.data.userResponse.userOrganizationAssociationList.find(
                (org) => org.organizationId === organizationId
              ),
            ];
          } else {
            organizaitonsToSearch = [...response.data.userResponse.userOrganizationAssociationList];
          }
          organizaitonsToSearch.forEach((org) => {
            doctorMap[org.organizationId] = org.isDoctor;
            adminMap[org.organizationId] = org.isAdmin;
            selectedOrg.push({
              ...org,
              orgId: org.organizationId,
              name: org.organizationName,
            });
          });
          setIsDoctorMap(doctorMap);
          setIsAdminMap(adminMap);
          setSelectedOrganizations(selectedOrg);
          setPrevSelectedOrg(selectedOrg);
          setCopySelectedOrganizations(selectedOrg.map((el) => el.name));
        } catch (error) {
          console.error("Error fetching doctors");
        }
      };
      fetchData();
    }
  }, [accessTokenValue, doctorId, handleClick, organizations]);

  const getOrgCodeNameText = (el) => {
    return `${el?.code} - ${el?.name}`;
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateCheckboxes = () => {
    let errors = "";
    selectedOrganizations.forEach((org) => {
      if (!isDoctorMap[org.orgId] && !isAdminMap[org.orgId]) {
        errors = "*At least one role must be selected for each organizaiton";
      }
    });
    setValidationErrors(errors);
    return !Boolean(errors);
  };

  const handleCheckboxChange = (event, orgId) => {
    const { checked } = event.target;
    const newIsDoctorMap = { ...isDoctorMap };
    const newIsAdminMap = { ...isAdminMap };

    if (event.target.name === "isDoctor") {
      newIsDoctorMap[orgId] = checked;
    } else {
      newIsAdminMap[orgId] = checked;
    }

    setIsDoctorMap(newIsDoctorMap);
    setIsAdminMap(newIsAdminMap);
  };

  const handleDeleteOrganization = async (org, values, setFieldValue) => {
    setFieldValue(
      "doctorOrganizationAssociation",
      values.doctorOrganizationAssociation.filter((prev) => prev !== org.name)
    );
    if (org.id) {
      setSelectedOrganizations((prevOrgs) => prevOrgs.filter((prevOrg) => prevOrg?.id !== org.id)); // Remove from list with ID
    } else {
      setSelectedOrganizations((prevOrgs) => prevOrgs.filter((prevOrg) => prevOrg !== org)); // Remove from list without ID
    }
  };

  useEffect(() => {
    const getExistingDoctorByMobileNumber = async () => {
      try {
        if (mobileNumber.length === 10) {
          const userResponse = await getPrimaryUserByMobileNumber(mobileNumber);
          if (userResponse.data.roleName !== DOCTOR) {
            handleClick("error", "Invalid Number for doctor registration");
            setDisableSaveButton(true);
          } else if (
            userResponse.data.userOrganizationAssociationList.find(
              (org) => org.organizationId === organizationId
            )
          ) {
            handleClick("error", "Doctor is already linked with your organization");
            setDisableSaveButton(true);
          } else {
            setDoctorUserDetails(userResponse.data);
            const doctorResponse = await getDoctorById(userResponse.data.roleBasedId);
            setDoctor(doctorResponse.data);
            formikRef.current.resetForm();
            setDisableSaveButton(false);
          }
        }
      } catch (error) {
        if (error.response.status === 404) {
          formikRef.current.setValues({
            ...formikRef.current?.values,
            firstName: "",
            middleName: "",
            lastName: "",
            mobileNumber: mobileNumber,
            gender: "",
            email: "",
            address: "",
            specialization: "",
            dateOfBirth: null,
          });
          setDoctor(null);
          setDisableSaveButton(false);
        }
      }
    };
    if (mobileNumber) {
      getExistingDoctorByMobileNumber();
    }
  }, [mobileNumber]);

  return (
    <Reveal>
      <Formik
        enableReinitialize={!!doctor}
        innerRef={formikRef}
        initialValues={{
          firstName: doctor?.userResponse?.firstName ? doctor.userResponse.firstName : "",
          middleName: doctor?.userResponse?.middleName ? doctor.userResponse.middleName : "",
          lastName: doctor?.userResponse?.lastName ? doctor.userResponse.lastName : "",
          gender: doctor?.userResponse?.gender ? doctor.userResponse.gender : "",
          email: doctor?.userResponse?.emailId ? doctor.userResponse.emailId : "",
          password: doctor?.userResponse?.password ? doctor.userResponse.password : "",
          mobileNumber: doctor?.userResponse?.mobileNumber ? doctor.userResponse.mobileNumber : "",
          address: doctor?.userResponse?.address ? doctor.userResponse.address : "",
          specialization: doctor?.specialization?.id ? doctor.specialization.id : "",
          qualification: doctor?.qualification ? doctor.qualification : "",
          experience: doctor?.experience ? doctor.experience : "",
          consultationFees: doctor?.consultationFees ? doctor.consultationFees : "",
          languagesSpoken: doctor?.languagesSpoken ? doctor.languagesSpoken : [],
          doctorOrganizationAssociation: copySelectedOrganizations,
          dateOfBirth: doctor?.userResponse?.dateOfBirth
            ? dayjs(doctor.userResponse.dateOfBirth)
            : null,
          submit: "",
        }}
        validationSchema={() => {
          if (isClinicAdmin) {
            return doctorSchemaForClinicAdmin;
          } else {
            return doctorSchemaForSuperAdmin;
          }
        }}
        onSubmit={async (values) => {
          const isValidated = validateCheckboxes();
          if (isValidated) {
            const data = {
              id: uuidv4(),
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              roleId: await getRoleIdByName(DOCTOR, handleClick),
              roleName: DOCTOR,
              mobileNumber: values.mobileNumber,
              emailId: values.email,
              password: values.password,
              address: values.address,
              gender: values.gender ? values.gender : "",
              state: ACTIVE_STATE,
              dateOfBirth: values.dateOfBirth.format(DATE_FORMAT),
              noOfAttempts: null,
            };

            if (!doctorId) {
              try {
                const payload = {
                  id: uuidv4(),
                  orgId: orgId(),
                  user: doctorUserDetails || data,
                  callbackUrl: `${baseWebUrl}/invitation`,
                  isExistingUser: doctorUserDetails ? true : false,
                  invitationType: "EMAIL",
                  validity: 7,
                };
                const response = await sendUserInvitationLink(payload);
                handleClick("success", "An invitation link has been sent to the Doctor.");
                navigate("/home/doctor");
              } catch (error) {
                handleClick("error", "Error sending invitation Link. Please try again.");
              }
            } else {
              try {
                let payload = {
                  ...doctor,
                  specialization: specializations.find((el) => el.id === values.specialization),
                  userResponse: {
                    ...doctor.userResponse,
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    mobileNumber: values.mobileNumber,
                    emailId: values.email,
                    gender: values.gender ? values.gender : "",
                  },
                };
                await updateDoctor(doctor?.id, payload);

                if (!isClinicAdmin) {
                  selectedOrganizations.forEach(async (org) => {
                    if (prevSelectedOrg.map((el) => el.orgId).includes(org.orgId)) {
                      const linkDataFromDB = prevSelectedOrg.find((o) => o.orgId === org.orgId);
                      const payloadOfUpdatedLink = {
                        ...linkDataFromDB,
                        isDoctor: isDoctorMap[org.orgId],
                        isAdmin: isAdminMap[org.orgId],
                      };
                      if (
                        linkDataFromDB.isDoctor !== payloadOfUpdatedLink.isDoctor ||
                        linkDataFromDB.isAdmin !== payloadOfUpdatedLink.isAdmin
                      ) {
                        await updateLinkUserOrg(payloadOfUpdatedLink.id, payloadOfUpdatedLink);
                      }
                    } else {
                      const payload = {
                        ...org,
                        mobileNumber: values.mobileNumber,
                        isDoctor: isDoctorMap[org.orgId] ? isDoctorMap[org.orgId] : org.isDoctor,
                        isAdmin: isAdminMap[org.orgId] ? isAdminMap[org.orgId] : org.isDoctor,
                      };
                      try {
                        await linkUserWithOrganization(payload);
                      } catch (error) {
                        handleClick("error", "There was an error linking organization");
                      }
                    }
                  });

                  prevSelectedOrg.forEach(async (org) => {
                    if (!selectedOrganizations.map((el) => el.orgId).includes(org.orgId)) {
                      await deleteUserOrgLink(org.id);
                    }
                  });
                }
                navigateToDoctors();
              } catch (error) {
                handleClick("error", "There seems to be an error updating doctor");
              }
            }
          }
        }}
      >
        {({ values, errors, touched, setFieldValue, handleBlur, handleChange, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
                mb: 2,
              }}
            >
              {doctorId ? "Update Doctor" : "Doctor Registration"}
            </Typography>

            <SubCard style={{ padding: "15px" }}>
              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                required
                label="Mobile Number"
                disabled={Boolean(doctorId)}
                type={"tel"}
                name="mobileNumber"
                value={values.mobileNumber}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setMobileNumber(e.target.value);
                }}
                inputProps={{
                  maxLength: 10,
                }}
                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                errorText={errors.mobileNumber}
                startAdornment={<i className="ri-phone-line ri-xl" />}
                size={"big"}
              />
              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="First Name"
                required
                name="firstName"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.firstName && errors.firstName)}
                errorText={errors.firstName}
                startAdornment={<PersonOutlineOutlinedIcon />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Middle Name"
                name="middleName"
                value={values.middleName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.middleName && errors.middleName)}
                errorText={errors.middleName}
                startAdornment={<PersonOutlineOutlinedIcon />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                required
                label="Last Name"
                name="lastName"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.lastName && errors.lastName)}
                errorText={errors.lastName}
                startAdornment={<PersonOutlineOutlinedIcon />}
                size={"big"}
              />

              <FormSelectField
                style={{
                  width: "30%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                label="Gender"
                name="gender"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<PersonOutlineOutlinedIcon />}
                menuItems={genderList}
                value={values.gender}
                error={Boolean(touched.gender && errors.gender)}
                errorText={errors.gender}
                size={"big"}
              ></FormSelectField>

              <FormDatePicker
                format={DATE_FORMAT_DMY}
                size={"big"}
                value={values.dateOfBirth}
                maxDate={MAX_DATE_FOR_DOCTOR}
                minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
                background={"#fff"}
                onChange={(date) => {
                  setFieldValue("dateOfBirth", date);
                  const currentDate = dayjs();
                  if (date === null) {
                    setDobError("Date of birth is required.");
                  } else if (!date.isValid()) {
                    setDobError("Please enter a valid 'Date of birth' value.");
                  } else if (date > currentDate) {
                    setDobError("Date of birth cannot be a future date.");
                  } else if (date < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
                    setDobError(
                      `Date of birth cannot be less than the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}`
                    );
                  } else if (date > MAX_DATE_FOR_DOCTOR) {
                    setDobError(
                      `Age must be at least ${currentDate.diff(MAX_DATE_FOR_DOCTOR, "year")} years.`
                    );
                  } else {
                    setDobError("");
                  }
                }}
                style={{
                  width: "30%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                error={Boolean(touched.dateOfBirth && dobError)}
                errorText={dobError}
              />

              {doctorId && showField && organizations.length > 0 && (
                <FormSelectField
                  style={{
                    width: "30%",
                    marginBottom: "25px",
                    marginRight: "30px",
                  }}
                  label={"Organization"}
                  name={"doctorOrganizationAssociation"}
                  onBlur={handleBlur}
                  value={values.doctorOrganizationAssociation}
                  error={Boolean(
                    errors.doctorOrganizationAssociation && touched.doctorOrganizationAssociation
                  )}
                  errorText={errors.doctorOrganizationAssociation}
                  size={"big"}
                  multiple
                  required
                  renderValue={(selected) => {
                    return selected.map((el) => (el?.name ? el.name : el)).join(", ");
                  }}
                  startAdornment={<DomainOutlined />}
                  onChange={(e) => {
                    let orgList = [];
                    e.target.value.forEach((org) => {
                      const orgId = organizations.find(
                        (o) => o.name === (org?.name ? org.name : org)
                      ).id;
                      orgList.push({
                        orgId: orgId,
                        name: org?.name ? org.name : org,
                        isDoctor: false,
                        isAdmin: false,
                      });
                    });
                    setSelectedOrganizations(orgList);
                    const {
                      target: { value },
                    } = e;
                    const data = typeof value === "string" ? value.split(",") : value;
                    setFieldValue("doctorOrganizationAssociation", data);
                  }}
                  menuItems={organizations.map((el) => {
                    return {
                      ...el,
                      value: el?.name,
                      menuLabel: getOrgCodeNameText(el),
                    };
                  })}
                ></FormSelectField>
              )}

              {doctorId && specializations.length > 0 && (
                <FormSelectField
                  style={{
                    width: "30%",
                    marginBottom: "25px",
                    marginRight: "30px",
                  }}
                  required
                  label="Specialization"
                  name="specialization"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  startAdornment={<ControlCameraOutlined />}
                  menuItems={specializations.map((el) => {
                    return {
                      value: el.id,
                      menuLabel: el.name,
                    };
                  })}
                  error={Boolean(touched.specialization && errors.specialization)}
                  errorText={errors.specialization}
                  value={values.specialization}
                  size={"big"}
                ></FormSelectField>
              )}

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="E-mail"
                name="email"
                required
                disabled={Boolean(doctorId)}
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                errorText={errors.email}
                startAdornment={<i className="ri-mail-line ri-lg" />}
                size={"big"}
              />

              {doctorId && showField && (
                <>
                  {touched.doctorOrganizationAssociation && validationErrors && (
                    <Grid display={"flex"} justifyContent={"end"}>
                      <Typography color="error" variant="body2">
                        {validationErrors}
                      </Typography>
                    </Grid>
                  )}
                  <Box sx={{ mt: 2, width: "100%", bgcolor: "#F4F8FE" }}>
                    {selectedOrganizations.map((org) => (
                      <Reveal>
                        <ListItem key={org.orgId}>
                          <ListItemText
                            primary={getOrgCodeNameText(
                              organizations.find((el) => el.id === org.orgId)
                            )}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isDoctorMap[org.orgId] || false}
                                name="isDoctor"
                                onChange={(event) => handleCheckboxChange(event, org.orgId)}
                              />
                            }
                            label="As Doctor"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isAdminMap[org.orgId] || false}
                                name="isAdmin"
                                onChange={(event) => handleCheckboxChange(event, org.orgId)}
                              />
                            }
                            label="As Admin"
                          />

                          <CustomButton
                            iconButton={<i className="ri-delete-bin-fill ri-xl icon-primary-red" />}
                            onClick={() => handleDeleteOrganization(org, values, setFieldValue)}
                            aria-label="settings"
                          />
                        </ListItem>
                      </Reveal>
                    ))}
                  </Box>
                </>
              )}
            </SubCard>

            <Box sx={{ mt: 3 }} display="flex">
              <CustomButton
                className="btn--secondary"
                style={{ marginRight: "20px" }}
                type="submit"
                label={"Save"}
                disabled={disableSaveButton}
              ></CustomButton>
              <CustomButton
                className="btn--error"
                onClick={() => navigateToDoctors()}
                label="Cancel"
              />
            </Box>
          </form>
        )}
      </Formik>
    </Reveal>
  );
};

export default ManageDoctor;
