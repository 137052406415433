const SkeletonShoulderFrame = () => {
  return (
    <div className="custom-skeleton-shoulder-frame">
      <div className="shoulder-frame-left-clavicle body-part-container">
        <img
          src="/images/human-skeleton/human-shoulder-frame/shoulder-frame-left-clavicle.svg"
          className="body-part hover"
          alt="shoulder-frame-left-clavicle"
        ></img>
      </div>
      <div className="shoulder-frame-right-clavicle body-part-container">
        <img
          src="/images/human-skeleton/human-shoulder-frame/shoulder-frame-right-clavicle.svg"
          className="body-part hover"
          alt="shoulder-frame-right-clavicle"
        ></img>
      </div>
    </div>
  );
};

export default SkeletonShoulderFrame;
