import "assets/scss/custom-charts.scss";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { padding } from "@mui/system";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CustomBarChart = ({
  displayName,
  title,
  values,
  labels,
  barColor = "#33ADEA",
  yAxisRange,
  xAxisLabel,
  yAxisLabel,
}) => {
  // display two bars one above other to get desired design of range
  // bottom bar is transparent

  const [sortedLabels, setSortedLabels] = useState([]);
  const [sortedValues, setSortedValues] = useState([]);

  useEffect(() => {
    if (values && labels) {
      const parseDateString = (dateString) => {
        const [day, month, year] = dateString.split("/");
        return new Date(`20${year}`, month - 1, day);
      };

      const sortedData = labels
        ?.map((label, index) => ({
          label: label,
          min: values[index].min,
          max: values[index].max,
        }))
        .sort((a, b) => parseDateString(a.label) - parseDateString(b.label));

      const sortedLabels = sortedData.map((item) => item.label);
      setSortedLabels(sortedLabels);

      const sortedValues = sortedData.map((item) => ({
        min: item.min,
        max: item.max,
      }));
      setSortedValues(sortedValues);
    }
  }, [values, labels]);

  const data = {
    labels: sortedLabels?.length > 0 ? sortedLabels : Array(sortedValues?.length).fill(""),
    datasets: [
      {
        label: "Min",
        data: sortedValues.map((rate) => rate.min),
        backgroundColor: "rgba(0, 159, 227, 0)",
        borderWidth: 0,
        barThickness: 10,
      },
      {
        label: "Max",
        data: sortedValues.map((rate) => rate.max - rate.min),
        backgroundColor: barColor,
        borderWidth: 0,
        barThickness: 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel || "", // X-Axis label
        },
        stacked: true,
        grid: {
          color: "#B5BBC233",
          drawTicks: false,
        },
        ticks: {
          display: true,
          padding: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel || "", // Y-Axis label
        },
        stacked: true,
        grid: {
          color: "#B5BBC233",
          drawTicks: false,
        },
        beginAtZero: false,
        min: yAxisRange?.updatedMin,
        max: yAxisRange?.updatedMax,
        ticks: {
          display: true,
          stepSize: yAxisRange?.stepSize,
          autoSkip: false,
          padding: 10,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      // disable tooltip for below bar, and customize message
      tooltip: {
        enabled: true,
        filter: (tooltipItem) => {
          return tooltipItem.dataset.label === "Max";
        },
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const min = values[index].min;
            const max = values[index].max;
            const customMessage = `Min: ${min}, Max: ${max}`;
            return customMessage;
          },
        },
      },
    },
  };

  return (
    <div className="custom-bar-chart">
      <div className="heading">
        <div className="title">
          <p>{displayName}</p>
        </div>
        <div className="actions"></div>
      </div>
      <div className="main-chart">
        <Bar data={data} options={options} />
      </div>
      <div className="footer">
        {/* <div>
          <p>Min: 19</p>
        </div>
        <div>
          <p>Max: 26</p>
        </div> */}
      </div>
    </div>
  );
};

export default CustomBarChart;
