import { GroupOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { getPatientFamilyDetails } from "services/patientService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import {
  abhaClinicAdminPageValidationForExistingUser,
  abhaClinicAdminPageValidationForNewPatientRegistration,
  abhaClinicAdminValidationForNewFamilyMemberRegistration,
} from "../Common/ValidationSchema/abhaClinicAdminPageValidation";
import {
  createUserFamilyProfile,
  createUserProfile,
  getPrimaryUserByMobileNumber,
} from "services/userService";
import { useNavigate } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  AGE_DIFFERENCE_MAP,
  DATE_FORMAT_DMY,
  FEMALE,
  MALE,
  MAX_AGE_FOR_REGUAR_DOB,
  OTHER,
  PATIENT,
  RELATIONSHIPS_WITH_OLDER_AGE,
  RELATIONSHIPS_WIT_YOUNGER_AGE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  familyRelations,
  filteredRelationsForFemale,
  filteredRelationsForMale,
  genderList,
  getRoleIdByName,
} from "store/constant";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { calculateAge } from "utils/calculate-age";
import OfflineAlert from "OfflineAlert";
import { Online } from "react-detect-offline";

const AbhaClinicAdminPage = ({ ...others }) => {
  const [primaryUser, setPrimaryUser] = useState(null);

  const [lowerBoundDateForFamilyDOB, setLowerBoundDateForFamilyDOB] = useState(
    dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year")
  );
  const [upperBoundDateForFamilyDOB, setUpperBoundDateForFamilyDOB] = useState(dayjs());
  const [patientAge, setPatientAge] = useState(null);

  const navigate = useNavigate();
  const navigateToAbhaPage = (patientId, patientUserId) => {
    navigate("/home/manageAbhaNumber", {
      state: { patientId: patientId, patientUserId: patientUserId },
    });
  };

  const [patientsName, setPatientsName] = useState([]);

  const currentDate = dayjs();
  const [dobError, setDobError] = useState("");

  const [newPatientRegistration, setNewPatientRegistration] = useState(false);
  const [newFamilyMemberRegistration, setNewFamilyMemberRegistration] = useState(false);
  const [canAddNewFamilyMember, setCanAddNewFamilyMember] = useState(false);

  const { handleClick } = useContext(ToastContext);

  const handleMobileNumberChange = (e, setFieldValue, values, setValues, setTouched) => {
    const inputNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const maxLength = 10; // Maximum allowed digits

    // Ensure the number does not exceed the maximum length
    const truncatedNumber = inputNumber.slice(0, maxLength);
    const { value } = e.target;
    setFieldValue("mobileNumber", truncatedNumber.toString());
    if (value.length === 10) {
      searchPatients(value, values, setValues, setTouched);
    } else if (value.length < 10) {
      setCanAddNewFamilyMember(false);
      setPatientsName([]);
    }
  };

  const searchPatients = async (mobileNumber, values, setValues, setTouched) => {
    try {
      const userResponse = await getPrimaryUserByMobileNumber(mobileNumber);

      const PatientAge = calculateAge(userResponse.data.dateOfBirth);
      setPatientAge(PatientAge);

      setPrimaryUser(userResponse.data);
      const familyId = userResponse.data?.familyResponse?.id;
      // if no family, will convert the primary user into family response
      let allPatientsResponse = [
        {
          userId: userResponse.data.id,
          userResponse: userResponse.data,
          isPrimary: true,
        },
      ];
      if (familyId) {
        const response = await getPatientFamilyDetails(familyId);
        allPatientsResponse = response.data?.userFamilyMappingResponses;
      }
      setCanAddNewFamilyMember(true);
      setPatientsName(allPatientsResponse);
      setNewPatientRegistration(false);
    } catch (error) {
      if (error.response.status === 404) {
        setCanAddNewFamilyMember(false);
        const newValues = {
          ...values,
          mobileNumber: mobileNumber,
          name: "",
          emailId: "",
          dateOfBirth: null,
          gender: "",
          selectedPatientId: "",
          relationship: "",
        };

        setValues(newValues);
        setTouched({});
        setNewPatientRegistration(true);
        setPrimaryUser(null);
        handleClick("error", "Sorry, Patient Data was not found");
      }
    }
  };

  const handleCheckboxChange = (event, setValues, setTouched, values) => {
    const newValues = {
      ...values,
      name: "",
      emailId: "",
      dateOfBirth: null,
      gender: "",
      selectedPatientId: "",
      relationship: "",
    };

    setValues(newValues);
    setTouched({});
    setNewFamilyMemberRegistration(event.target.checked);
  };

  return (
    <>
      <OfflineAlert />
      <Online>
        <AbhaClinicAdminSkeleton>
          <Reveal>
            <Formik
              enableReinitialize={true}
              // validateOnMount={true}
              initialValues={{
                mobileNumber: "",
                name: "",
                emailId: "",
                dateOfBirth: null,
                gender: "",
                selectedPatientId: "",
                relationship: "",
              }}
              validationSchema={() => {
                if (newPatientRegistration) {
                  return abhaClinicAdminPageValidationForNewPatientRegistration;
                } else if (newFamilyMemberRegistration) {
                  return abhaClinicAdminValidationForNewFamilyMemberRegistration;
                } else {
                  return abhaClinicAdminPageValidationForExistingUser;
                }
              }}
              onSubmit={async (values) => {
                const patientNameArr = values.name.split(" ");
                const data = {
                  firstName: patientNameArr[0],
                  middleName: patientNameArr.length > 2 ? patientNameArr[1] : "",
                  lastName: patientNameArr.length > 1 ? patientNameArr[2] : "",
                  roleId: await getRoleIdByName(PATIENT, handleClick),
                  roleName: PATIENT,
                  gender: values.gender,
                  mobileNumber: values.mobileNumber,
                  dateOfBirth: values.dateOfBirth,
                  emailId: primaryUser?.emailId,
                };

                if (newPatientRegistration) {
                  const response = await createUserProfile(data);
                  // setSelectedPatientId(response.data.roleBasedId);
                  navigateToAbhaPage(response.data.roleBasedId, primaryUser.id);
                } else if (newFamilyMemberRegistration) {
                  const updatedData = {
                    ...data,
                    relationship: values.relationship,
                  };
                  const response = await createUserFamilyProfile(updatedData, primaryUser.id);
                  navigateToAbhaPage(response.data.roleBasedId, primaryUser.id);
                } else {
                  navigateToAbhaPage(values.selectedPatientId, primaryUser.id);
                }
                return;
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                handleChange,
                handleSubmit,
                setValues,
                setTouched,
              }) => (
                <form noValidate onSubmit={handleSubmit} {...others}>
                  <Grid sx={{ mt: "20px" }}>
                    <Typography
                      sx={{
                        fontSize: "21px",
                        fontWeight: 600,
                        color: "#004C70",
                      }}
                    >
                      ABHA Creation
                    </Typography>
                  </Grid>
                  <Grid
                    marginTop={"30px"}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid
                      sx={{
                        width: "700px",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        // height: '600px',
                        boxShadow: "0 0 1rem rgba(0, 0, 0, 0.1)",
                        padding: "24px 24px 24px 0px",
                        // display: 'flex'
                      }}
                      container
                      mt={1}
                    >
                      <Grid sx={{ width: "100%", paddingLeft: "24px" }}>
                        <Typography
                          sx={{
                            mb: 2,
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#000000",
                          }}
                        >
                          Patient Details
                        </Typography>
                      </Grid>

                      <Grid sx={{ width: "100%", paddingLeft: "24px" }} style={{ paddingTop: 0 }}>
                        <FormInputField
                          style={{
                            width: "100%",
                            marginRight: "30px",
                            marginBottom: "10px",
                          }}
                          required
                          label="Mobile Number"
                          type={"number"}
                          name="mobileNumber"
                          inputProps={{
                            maxLength: 10,
                          }}
                          error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                          errorText={errors.mobileNumber}
                          value={values.mobileNumber}
                          disabled={newFamilyMemberRegistration ? true : false}
                          onChange={(e) =>
                            handleMobileNumberChange(
                              e,
                              setFieldValue,
                              values,
                              setValues,
                              setTouched
                            )
                          }
                          startAdornment={<i className="ri-phone-line ri-xl" />}
                          size={"big"}
                        />
                      </Grid>

                      {!newPatientRegistration &&
                        !newFamilyMemberRegistration &&
                        patientsName.length > 0 && (
                          <>
                            <Grid
                              sx={{ width: "50%", paddingLeft: "24px" }}
                              style={{ paddingTop: 0 }}
                            >
                              <Reveal>
                                <FormSelectField
                                  style={{
                                    width: "100%",
                                    marginRight: "30px",
                                    marginBottom: "10px",
                                  }}
                                  required
                                  label={"Select Patient"}
                                  name="selectedPatientId"
                                  startAdornment={<PersonOutlineOutlined />}
                                  size={"big"}
                                  value={values.selectedPatientId}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(
                                    touched.selectedPatientId && errors.selectedPatientId
                                  )}
                                  errorText={errors.selectedPatientId}
                                  menuItems={patientsName?.map((e) => ({
                                    value: e.userResponse.roleBasedId,
                                    menuLabel: e.userResponse.name,
                                  }))}
                                />
                              </Reveal>
                            </Grid>
                          </>
                        )}

                      {canAddNewFamilyMember && (
                        <Reveal style={{ marginBottom: "10px", paddingLeft: "24px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newFamilyMemberRegistration}
                                onChange={(e) =>
                                  handleCheckboxChange(e, setValues, setTouched, values)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              />
                            }
                            label="Add new family member to create his/her ABHA"
                          />
                        </Reveal>
                      )}

                      {(newPatientRegistration || newFamilyMemberRegistration) && (
                        <>
                          <Grid sx={{ width: "50%" }} style={{ paddingTop: 0 }}>
                            <Reveal>
                              <FormInputField
                                style={{
                                  width: "90%",
                                  margin: "25px",
                                }}
                                label="Full Name"
                                type={"text"}
                                name="name"
                                required
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.name && errors.name)}
                                errorText={errors.name}
                                startAdornment={<PersonOutlineOutlined />}
                                size={"big"}
                              />
                            </Reveal>
                          </Grid>
                          {newPatientRegistration && (
                            <Grid sx={{ width: "50%" }} style={{ paddingTop: 0 }}>
                              <Reveal>
                                <FormInputField
                                  style={{
                                    width: "90%",
                                    margin: "25px",
                                  }}
                                  label="Email"
                                  type={"email"}
                                  name="emailId"
                                  required
                                  value={values.emailId}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(touched.emailId && errors.emailId)}
                                  errorText={errors.emailId}
                                  startAdornment={<i className="ri-mail-line ri-lg" />}
                                  size={"big"}
                                />
                              </Reveal>
                            </Grid>
                          )}
                          <Grid sx={{ width: "50%" }} style={{ paddingTop: 0 }}>
                            <Reveal>
                              <FormDatePicker
                                format={DATE_FORMAT_DMY}
                                disableFuture
                                label={"Birth date"}
                                minDate={lowerBoundDateForFamilyDOB}
                                maxDate={upperBoundDateForFamilyDOB}
                                value={values.dateOfBirth}
                                size={"big"}
                                onChange={(date) => {
                                  setFieldValue("dateOfBirth", date);
                                  if (date === null) {
                                    setDobError("Date of birth is required.");
                                  } else if (!date.isValid()) {
                                    setDobError("Please select a valid 'Date of birth' value.");
                                  } else if (date > currentDate) {
                                    setDobError("Date of birth cannot be a future date.");
                                  } else if (date < lowerBoundDateForFamilyDOB) {
                                    setDobError(
                                      `Should be greater than ${lowerBoundDateForFamilyDOB
                                        .subtract(1, "day")
                                        .format(DATE_FORMAT_DMY)}.`
                                    );
                                  } else if (date > upperBoundDateForFamilyDOB) {
                                    setDobError(
                                      `Should be less than ${upperBoundDateForFamilyDOB
                                        .add(1, "day")
                                        .format(DATE_FORMAT_DMY)}.`
                                    );
                                  } else {
                                    setDobError("");
                                  }
                                }}
                                style={{
                                  width: "90%",
                                  marginLeft: "25px",
                                }}
                                error={Boolean(dobError && touched.dateOfBirth)}
                                errorText={dobError}
                              ></FormDatePicker>
                            </Reveal>
                          </Grid>
                          <Grid sx={{ width: "50%" }} style={{ paddingTop: 0 }}>
                            <Reveal>
                              <FormSelectField
                                style={{
                                  width: "90%",
                                  marginLeft: "25px",
                                }}
                                label="Gender"
                                name="gender"
                                required
                                onChange={(e) => {
                                  handleChange(e);
                                  if (values.relationship && e.target.value !== OTHER) {
                                    const isRelationshipValid =
                                      e.target.value ===
                                      familyRelations.find(
                                        (item) => item.value === values.relationship
                                      )?.gender;
                                    if (!isRelationshipValid) {
                                      setFieldValue("relationship", "");
                                    }
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(touched.gender && errors.gender)}
                                errorText={errors.gender}
                                startAdornment={<PersonOutlineOutlined />}
                                menuItems={genderList}
                                value={values.gender}
                                size={"big"}
                              ></FormSelectField>
                            </Reveal>
                          </Grid>
                        </>
                      )}
                      {newFamilyMemberRegistration && (
                        <Grid sx={{ width: "50%" }} style={{ paddingTop: 0 }}>
                          <Reveal>
                            <RelationShipForm
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              touched={touched}
                              errors={errors}
                              patientAge={patientAge}
                              setLowerBoundDateForFamilyDOB={setLowerBoundDateForFamilyDOB}
                              setUpperBoundDateForFamilyDOB={setUpperBoundDateForFamilyDOB}
                              setDobError={setDobError}
                            />
                          </Reveal>
                        </Grid>
                      )}
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          width: "100%",
                          mt: 2,
                        }}
                      >
                        <CustomButton
                          sx={{ mr: 1 }}
                          label={"Next"}
                          className={"btn--secondary"}
                          type={"submit"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Reveal>
        </AbhaClinicAdminSkeleton>
      </Online>
    </>
  );
};

const AbhaClinicAdminSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2} mt={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }} mt={3} display={"flex"} justifyContent={"center"}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="80%"
            height={200}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const RelationShipForm = ({
  handleBlur,
  handleChange,
  touched,
  errors,
  patientAge,
  setLowerBoundDateForFamilyDOB,
  setUpperBoundDateForFamilyDOB,
  setDobError,
}) => {
  const { values } = useFormikContext();

  useEffect(() => {
    let newUpperBound;
    let newLowerBound;

    if (values?.relationship) {
      const ageDifference = AGE_DIFFERENCE_MAP[values.relationship] || 0;

      if (RELATIONSHIPS_WITH_OLDER_AGE.includes(values.relationship)) {
        newUpperBound = dayjs().subtract(patientAge + ageDifference, "year");
        newLowerBound = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");
        setUpperBoundDateForFamilyDOB(newUpperBound);
        setLowerBoundDateForFamilyDOB(newLowerBound);
      } else if (RELATIONSHIPS_WIT_YOUNGER_AGE.includes(values.relationship)) {
        newLowerBound = dayjs().subtract(patientAge - ageDifference, "year");
        newUpperBound = dayjs();
        setLowerBoundDateForFamilyDOB(newLowerBound);
        setUpperBoundDateForFamilyDOB(newUpperBound);
      }

      if (values?.dateOfBirth?.isValid()) {
        if (values.dateOfBirth < newLowerBound) {
          setDobError(
            `Should be greater than ${newLowerBound.subtract(1, "day").format(DATE_FORMAT_DMY)}.`
          );
        } else if (values.dateOfBirth > newUpperBound) {
          setDobError(
            `Should be less than ${newUpperBound.add(1, "day").format(DATE_FORMAT_DMY)}.`
          );
        } else {
          setDobError("");
        }
      }
    }
  }, [values?.relationship]);

  return (
    <>
      <FormSelectField
        style={{
          width: "100%",
          marginBottom: "25px",
          marginRight: "30px",
        }}
        label={"Relationship"}
        required
        name={"relationship"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.relationship}
        error={Boolean(touched.relationship && errors.relationship)}
        errorText={errors.relationship}
        size={"big"}
        startAdornment={<GroupOutlined />}
        menuItems={
          values.gender === MALE
            ? filteredRelationsForMale
            : values.gender === FEMALE
            ? filteredRelationsForFemale
            : familyRelations
        }
      ></FormSelectField>
    </>
  );
};

export default AbhaClinicAdminPage;
