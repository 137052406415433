const SkeletonRightArm = () => {
  return (
    <div className="custom-skeleton-right-arm">
      <div className="group-1">
        <div className="right-arm-humerus body-part-container">
          <img
            src="/images/human-skeleton/human-right-arm/right-arm-humerus.svg"
            className="body-part hover"
            alt="right-arm-humerus"
          ></img>
        </div>
        <div className="right-arm-scapula body-part-container">
          <img
            src="/images/human-skeleton/human-right-arm/right-arm-scapula.svg"
            className="body-part hover"
            alt="right-arm-scapula"
          ></img>
        </div>
      </div>
      <div className="group-2">
        <div className="right-arm-ulna body-part-container">
          <img
            src="/images/human-skeleton/human-right-arm/right-arm-ulna.svg"
            className="body-part hover"
            alt="right-arm-ulna"
          ></img>
        </div>
        <div className="right-arm-radius body-part-container">
          <img
            src="/images/human-skeleton/human-right-arm/right-arm-radius.svg"
            className="body-part hover"
            alt="right-arm-radius"
          ></img>
        </div>
      </div>
      <div className="group-3">
        {/* todo:image  */}
        <div className="right-arm-palm body-part-container">
          <img
            src="/images/human-skeleton/human-right-arm/right-arm-palm.svg"
            className="body-part hover"
            alt="right-arm-palm"
          ></img>
        </div>
      </div>
    </div>
  );
};
export default SkeletonRightArm;
