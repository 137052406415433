import {
  AddOutlined,
  ClearOutlined,
  CoronavirusOutlined,
  DescriptionOutlined,
  SevereColdOutlined,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getChronicDiseases } from "services/EntitiesServices";
import {
  createPatientMedicalConditions,
  deleteChronicDiesease,
  editPatientMedicalConditions,
  getPatientChronicDiseaseByPatientId,
} from "services/patientService";
import ModalUI from "ui-component/ModalUI";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import {
  DATE_FORMAT,
  SEVERITY_TYPES,
  startsAndEndsWithAlphabetic,
  startsWithAlphabetic,
} from "store/constant";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "chronicDiseaseName", label: "Name" },
  { field: "severity", label: "Severity" },
  { field: "description", label: "Description" },
  { field: "onsetDate", label: "Since When" },
  { field: "Actions", label: "Actions" },
];

const PatientChronicDisease = ({ patientDOB, patientId }) => {
  const [patientChronicDisease, setPatientChronicDisease] = useState([]);
  const [displayPatientChronicDisease, setDisplayChronicDisease] = useState([]);
  const [chronicDiseases, setChronicDiseases] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deleteChronicDiesease(row.id);
      handleClick("success", "Patient Chronic-disease deleted successfully");
      const index = patientChronicDisease.findIndex((el) => el.id === row.id);
      patientChronicDisease.splice(index, 1);
      setDisplayChronicDisease(patientChronicDisease);
    } catch (error) {
      handleClick("error", "Patient Chronic-disease deletion failed");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pChronicDisease) => {
    setSelected(pChronicDisease);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchChronicDisease = async () => {
    try {
      const ChronicDisease = await getChronicDiseases();
      setChronicDiseases(ChronicDisease.data);
    } catch (error) {
      handleClick("error", "Error fetching Chronic Disease");
    }
  };

  const fetchPatientChronicDisease = async () => {
    try {
      const response = await getPatientChronicDiseaseByPatientId(patientId);
      const PatientChronicDisease = response.data.map((pChronicDisease) => {
        return {
          ...pChronicDisease,
          chronicDiseaseName: pChronicDisease?.chronicDiseaseResponse.name,
        };
      });
      setPatientChronicDisease(PatientChronicDisease);
    } catch (error) {
      handleClick("error", "Error fetching patient chronic-disease data");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete  ";
      },
      icon: <i className="ri-delete-bin-fill ri-1x icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    if (chronicDiseases.length > 0) {
      fetchPatientChronicDisease();
    }
  }, [chronicDiseases]);

  useEffect(() => {
    fetchChronicDisease();
  }, []);

  useEffect(() => {
    setDisplayChronicDisease(
      patientChronicDisease
        .filter((item) => {
          const values = Object.values(item);
          const lowerCaseQuery = searchQuery.toLowerCase();
          return values.some((value, index) => {
            if (typeof value === "string" && index !== 6) {
              return value.toLowerCase().includes(lowerCaseQuery);
            }
            return false;
          });
        })
        .map((item) => {
          const { onsetDate, severity, ...rest } = item;
          return {
            ...rest,
            severity: SEVERITY_TYPES.find((e) => e.value === item.severity).menuLabel,
            onsetDate: dayjs(onsetDate).format("MMMM YYYY"),
          };
        })
    );
  }, [patientChronicDisease, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search Chronic-disease"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"btn--primary"}
          startIcon={<AddOutlined />}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomizedTable
        columns={columns}
        tableData={displayPatientChronicDisease}
        actions={actions}
        rowsPerPageInTable={10}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Chronic-disease" : "Create Patient Chronic-disease"}
        component={
          <PatientChronicDiseaseModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientChronicDisease();
              } else {
                closeModal();
              }
            }}
            patientDOB={patientDOB}
            patientId={patientId}
            chronicDiseases={chronicDiseases}
          />
        }
      />
    </Reveal>
  );
};

const PatientChronicDiseaseModal = ({
  chronicDiseases,
  patientDOB,
  patientId,
  selected,
  handleClick,
  close,
}) => {
  const [severity, setSeverity] = useState(selected?.severity ? selected?.severity : "");
  const [severityTouched, setSeverityTouched] = useState(false);

  const [description, setDescription] = useState(
    selected?.description ? selected?.description : ""
  );
  const [descriptionTouched, setDescriptionTouched] = useState(false);

  const [onsetDate, setOnSetDate] = useState(
    selected?.onsetDate ? dayjs(selected?.onsetDate) : null
  );
  const [chronicDisease, setChronicDisease] = useState(null);
  const [, setNewChronicDisease] = useState(null);
  const [errors, setErrors] = useState({});

  const minDateForChronicDisease = dayjs(patientDOB ? patientDOB : "1900-01-01");

  const validateSeverity = (errors, severity) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!severity) {
      errors.severity = "Required";
    } else if (severity.length < 3) {
      errors.severity = "Must be at least 3 characters long";
    } else if (severity.length > 30) {
      errors.severity = "Cannot be longer than 30 characters";
    } else if (!pattern.test(severity)) {
      errors.severity = "can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(severity)) {
      setErrors({ ...errors, seve: "Name must start with alphabet" });
    }
  };

  const validateDescription = (errors, description) => {
    if (!description) {
      errors.description = "Required";
    } else if (description.length < 3) {
      errors.description = "Must be at least 3 characters long";
    } else if (description.length > 255) {
      errors.description = "Cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(description)) {
      errors.description = "Name must start with alphabet";
    }
  };

  const currentDate = dayjs();
  const [dateTouched, setDateTouched] = useState(false);

  const validate = () => {
    const errors = {};

    setSeverityTouched(true);
    validateSeverity(errors, severity);

    setDescriptionTouched(true);
    validateDescription(errors, description);

    setDateTouched(true);
    if (onsetDate === null) {
      errors.onsetDate = "Required.";
    } else if (!(onsetDate?.isValid ? onsetDate.isValid() : true)) {
      errors.onsetDate = "Invalid 'Date' value.";
    } else if (onsetDate > currentDate) {
      errors.onsetDate = "Date cannot be in future.";
    } else if (onsetDate < minDateForChronicDisease) {
      errors.dateAdministered = "Date can't be less than DOB.";
    }

    if (!chronicDisease) {
      errors.chronicDisease = "Required";
    }

    return errors;
  };

  useEffect(() => {
    if (selected && chronicDiseases) {
      const ChronicDisease = chronicDiseases.find((chronicDisease) => {
        return chronicDisease.id === selected.chronicDiseaseId;
      });
      setChronicDisease(ChronicDisease);
    }
  }, [selected, chronicDiseases]);

  const saveChronicDisease = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        severity,
        description,
        onsetDate: dayjs(onsetDate).format(DATE_FORMAT),
        chronicDiseaseId: selected?.chronicDiseaseResponse?.id,
      };

      if (selected) {
        try {
          await editPatientMedicalConditions(selected.id, data);
          handleClick("success", "Patient chornic-disease updated successfully!!");
          close("save");
        } catch (error) {
          handleClick("error", "Error updating patient chronic-disease");
        }
      } else {
        try {
          const postData = { ...data, chronicDiseaseId: chronicDisease.id };
          await createPatientMedicalConditions(postData);
          handleClick("success", "Patient Chronic-disease created successfully!!");
          close("save");
        } catch (error) {
          handleClick("error", "Error creating patient chronic-disease");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <CustomAutoComplete
            options={chronicDiseases}
            readOnly={selected}
            label={"Chronic-Disease"}
            placeholder="Select"
            required
            iconStart={<CoronavirusOutlined />}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => {
              setChronicDisease(value);
              const newErrors = { ...errors };
              delete newErrors.chronicDisease;
              setErrors(newErrors);
            }}
            onInput={(event, newInputValue) => {
              setNewChronicDisease(newInputValue);
            }}
            value={chronicDisease}
            error={Boolean(errors.chronicDisease)}
            errorText={errors.chronicDisease}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormSelectField
            style={{ width: "100%", marginTop: "7px" }}
            label="Severity"
            name="severity"
            required
            value={severity}
            onBlur={(e) => {
              setSeverityTouched(true);
              const newError = {};
              validateSeverity(newError, severity);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setSeverity(e.target.value);
              if (severityTouched) {
                const newError = {};
                validateSeverity(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.severity;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<SevereColdOutlined />}
            size={"big"}
            error={Boolean(errors.severity)}
            errorText={errors.severity}
            menuItems={SEVERITY_TYPES.filter((type) => type.menuLabel)}
          ></FormSelectField>
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Description"
            name="description"
            required
            value={description}
            onBlur={(e) => {
              setDescriptionTouched(true);
              const newError = {};
              validateDescription(newError, description);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setDescription(e.target.value);
              if (descriptionTouched) {
                const newError = {};
                validateDescription(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.description;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<DescriptionOutlined />}
            size={"big"}
            error={Boolean(errors.description)}
            errorText={errors.description}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormDatePicker
            label={"Since When"}
            disableFuture
            views={["month", "year"]}
            openTo="month"
            required
            value={onsetDate}
            minDate={minDateForChronicDisease}
            size={"big"}
            onChange={(date) => {
              setOnSetDate(date);
              if (date === null) {
                setErrors({ ...errors, onsetDate: "Required." });
              } else if (!date.isValid()) {
                setErrors({ ...errors, onsetDate: "Invalid 'Date' value." });
              } else if (date > currentDate) {
                setErrors({
                  ...errors,
                  onsetDate: "Date cannot be in a future.",
                });
              } else if (date < minDateForChronicDisease) {
                setErrors({
                  ...errors,
                  onsetDate: "Date can't be less than Date of birth.",
                });
              } else {
                const { onsetDate, ...newError } = errors;
                setErrors(newError);
              }
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.onsetDate && dateTouched)}
            errorText={errors.onsetDate}
          ></FormDatePicker>
        </Grid>
        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={saveChronicDisease}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientChronicDisease;
