// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s;
  margin-top: 10px;
  margin-bottom: 10px;
}

.file-upload.dragover {
  border-color: #000;
}

.file-upload-label {
  display: block;
  font-size: 16px;
  color: #666;
}
.file-upload-label .label-text {
  margin-bottom: 0;
}
.file-upload-label .file-supported {
  margin-top: 0;
  font-size: small;
}
.file-upload-label .file-supported em {
  font-weight: 500;
}

.file-list {
  margin-top: 20px;
}

.file-list h4 {
  margin-bottom: 10px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  background: #f9f9f9;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 60%;
  cursor: pointer;
}

.file-size {
  flex: 0 0 25%;
}

.button-container {
  display: flex;
  flex-direction: row-reverse;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/customDropZone.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,WAAA;AACF;AACE;EACE,gBAAA;AACJ;AACE;EACE,aAAA;EACA,gBAAA;AACJ;AAAI;EACE,gBAAA;AAEN;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,qBAAA;EACA,UAAA;AAAF;;AAGA;EACE,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,aAAA;EACA,2BAAA;AAAF","sourcesContent":[".file-upload {\n  height: 150px;\n  border: 2px dashed #ccc;\n  border-radius: 10px;\n  text-align: center;\n  cursor: pointer;\n  transition: border-color 0.3s;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.file-upload.dragover {\n  border-color: #000;\n}\n\n.file-upload-label {\n  display: block;\n  font-size: 16px;\n  color: #666;\n\n  .label-text {\n    margin-bottom: 0;\n  }\n  .file-supported {\n    margin-top: 0;\n    font-size: small;\n    em {\n      font-weight: 500;\n    }\n  }\n}\n\n.file-list {\n  margin-top: 20px;\n}\n\n.file-list h4 {\n  margin-bottom: 10px;\n}\n\n.file-list ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.file-list li {\n  background: #f9f9f9;\n  margin-bottom: 5px;\n  padding: 10px;\n  border-radius: 5px;\n  text-align: left;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.file-name {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  flex: 0 0 60%;\n  cursor: pointer;\n}\n\n.file-size {\n  flex: 0 0 25%;\n}\n\n.button-container {\n  display: flex;\n  flex-direction: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
