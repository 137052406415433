import { Typography, IconButton, Skeleton, Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateProcedure from "./UpdateProcedure";
import {
  procedureDescriptionSchema,
  procedureSchema,
} from "../../Common/ValidationSchema/procedureValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  entitiesInfo,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { useContext } from "react";
import { createProcedures, getProcedures } from "services/EntitiesServices";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import InfoModal from "../InfoModal";
import InfoIcon from "@mui/icons-material/Info";

const Procedure = () => {
  const [procedure, setProcedure] = useState([]);
  const [displayProcedure, setDisplayProcedure] = useState([]);
  const [newProcedure, setNewProcedure] = useState("");
  const [newProcedureDescription, setNewProcedureDescription] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const entityInfo = entitiesInfo.find((entity) => entity.id === "Procedure");
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({
    procedure: "",
    procedureDescription: "",
  });
  const [procedureTouched, setProcedureTouched] = useState(false);
  const [procedureDescriptionTouched, setProcedureDescriptionTouched] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getProcedures();
      setProcedure(response?.data);
    } catch (error) {
      console.error("Error fetching procedures");
    }
  }, []);

  const handleDelete = async (procedure) => {
    if (procedure.status === ACTIVE_STATE) {
      try {
        await procedure.id;
        handleClick("success", "Procedure is marked Inactive!");
        fetchData();
      } catch (e) {
        handleClick("error", "There seems to be an error marking the Procedure as Inactive.");
      }
    } else {
      handleClick("info", "Procedure is already inactive!");
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const validateProcedure = async (procedure) => {
    try {
      await procedureSchema.validate({ procedure });
      setErrors((prevErrors) => ({ ...prevErrors, procedure: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({ ...prevErrors, procedure: e.message }));
    }
  };
  const validateProcedureDescription = async (procedureDescription) => {
    try {
      await procedureDescriptionSchema.validate({ procedureDescription });
      setErrors((prevErrors) => ({ ...prevErrors, procedureDescription: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        procedureDescription: e.message,
      }));
    }
  };

  const addProcedure = async () => {
    await validateProcedure(newProcedure);
    await validateProcedureDescription(newProcedureDescription);
    if (errors.procedure || errors.procedureDescription) {
      handleClick(
        "error",
        errors.procedure +
          (errors.procedure && errors.procedureDescription ? ", " : "") +
          errors.procedureDescription
      );
      return;
    }
    const data = { name: newProcedure, description: newProcedureDescription };

    if (procedure.some((t) => t.name.toLowerCase() === newProcedure.toLowerCase())) {
      handleClick("error", "Procedure already exists");
      return;
    }

    try {
      const res = await createProcedures(data);
      handleClick("success", "Procedure has been successfully added.");
      setErrors({ procedure: "", procedureDescription: "" });
      setNewProcedure("");
      setNewProcedureDescription("");
      setProcedure([...procedure, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the procedure.");
    }
  };

  useEffect(() => {
    setDisplayProcedure(
      procedure.filter((s) => s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
    );
  }, [procedure, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "description", label: "Description" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <ProcedureSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>Procedure</h2>
          {entityInfo && (
            <>
              <InfoIcon
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={() => setOpenDialog(true)}
              />
              <InfoModal
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                entityInfo={{ ...entityInfo, title: "Procedure" }}
              />
            </>
          )}
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any procedure
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "30%" }}
                label={"Search procedure"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  <IconButton onClick={clearSearch} edge="end">
                    {<ClearOutlinedIcon fontSize="small" />}
                  </IconButton>
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Add a new Procedure
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid size={{ xs: 5 }} sx={{ mr: 2 }}>
                <FormInputField
                  label={"Procedure Name"}
                  value={newProcedure}
                  error={Boolean(errors.procedure)}
                  errorText={errors.procedure}
                  onBlur={(e) => {
                    setProcedureTouched(true);
                    validateProcedure(e.target.value);
                  }}
                  onChange={(e) => {
                    setNewProcedure(e.target.value);
                    if (procedureTouched) {
                      validateProcedure(e.target.value);
                    }
                  }}
                  startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid size={{ xs: 5 }} sx={{ mr: 2 }}>
                <FormInputField
                  label={"Procedure Description"}
                  value={newProcedureDescription}
                  error={Boolean(errors.procedureDescription)}
                  errorText={errors.procedureDescription}
                  onBlur={(e) => {
                    setProcedureDescriptionTouched(true);
                    validateProcedureDescription(e.target.value);
                  }}
                  onChange={(e) => {
                    setNewProcedureDescription(e.target.value);
                    if (procedureDescriptionTouched) {
                      validateProcedureDescription(e.target.value);
                    }
                  }}
                  startAdornment={<DescriptionOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid sx={{ mt: 0.5 }}>
                <CustomButton
                  className="btn--primary"
                  disabled={newProcedure === ""}
                  onClick={addProcedure}
                  label="Add"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>

        <CustomizedTable columns={columns} tableData={displayProcedure} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Procedure"
          component={
            <UpdateProcedure
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </ProcedureSkeleton>
  );
};

const ProcedureSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="90%"
            height={80}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Procedure;
