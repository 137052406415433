import { api as API } from "./AxiosInterceptor";

export const getPackages = () => API.get(`/api/subscription/packages`);
export const deletePackage = (id) => API.delete(`/api/subscription/packages/${id}`);
export const savePackage = (data) => API.post("/api/subscription/packages", data);
export const updatePackage = (id, data) => API.put(`/api/subscription/packages/${id}`, data);
export const getBundles = () => API.get(`/api/subscription/bundles`);
export const getLicence = (id) => API.get(`/api/subscription/licences`);
export const getLicenceDataById = (id) => API.get(`/api/subscription/licences/${id}`);
export const createLicences = (data) => API.post(`/api/subscription/licences`, data);
export const updateLicences = (data, id) => API.put(`/api/subscription/licences/${id}`, data);
export const getSubscriptionLicenceAssociations = () =>
  API.get(`/api/subscription/licence-associations`);
export const deleteLicences = (id) => API.delete(`/api/subscription/licences/${id}`);
export const getBundleById = (id) => API.get(`/api/subscription/bundles/${id}`);
export const getLicenceByOrgId = (id) => API.get(`/api/subscription/licences/org/${id}`);
export const createBundles = (data) => API.post(`/api/subscription/bundles`, data);
export const updateBundles = (data, id) => API.put(`/api/subscription/bundles/${id}`, data);
export const deleteBundle = (id) => API.delete(`/api/subscription/bundles/${id}`);

//mock-data generation
export const generateMockData = (data) => API.post(`api/mock-data`, data);
