import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { completeBookingUhi } from "services/doctorService";
import { getUhiAppointmentData } from "store/Slices/appointmentDataSlice";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import "../../../assets/scss/PatientPayment.scss";
import { SCHEDULED } from "store/constant";

const PatientPayment = () => {
  const uhiAppointmentData = useSelector(getUhiAppointmentData);
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const appointmentConfirmation = async () => {
    try {
      const data = {
        request: {
          transactionId: uhiAppointmentData?.request?.transactionId,
          providerId: uhiAppointmentData?.request?.providerId,
          providerUri: uhiAppointmentData?.request?.providerUri,
          paymentAmount: uhiAppointmentData?.request?.totalFees,
          paymentStatus: "PAID",
          paymentMode: "UPI",
          paymentVpa: "",
          fulfillmentId: uhiAppointmentData.request?.fulfillmentId,
          starttime: uhiAppointmentData?.request?.starttime,
          endtime: uhiAppointmentData?.request?.endtime,
          orderId: uhiAppointmentData?.request?.orderId,
        },
        appointmentResponse: {
          ...uhiAppointmentData?.appointmentResponse,
          appointmentStatus: SCHEDULED,
          orderId: uhiAppointmentData?.request?.orderId,
          doctorDetails: JSON.stringify(uhiAppointmentData?.appointmentResponse?.doctorDetails),
          orgDetails: JSON.stringify(uhiAppointmentData?.appointmentResponse?.orgDetails),
          providerDetails: JSON.stringify(uhiAppointmentData?.appointmentResponse?.providerDetails),
        },
      };
      const response = await completeBookingUhi(data);
      handleClick("success", "Appointment has been successfully booked.");
      navigate("/home/dashboard");
    } catch (error) {
      handleClick("There seems to be an error confirming the appointment.");
    }
  };

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <h2 style={{ display: "inline" }}>Payment</h2>
        </div>
        <div class="appointment-summary">
          <h2>Appointment Summary</h2>
          <div class="summary-row">
            <span class="summary-label">Consultation Fees:</span>
            <span class="summary-value" id="consultationFees">
              ₹{uhiAppointmentData?.request?.consultationFees}
            </span>
          </div>
          <div class="summary-row">
            <span class="summary-label">Registration Fees:</span>
            <span class="summary-value" id="consultationFees">
              ₹{uhiAppointmentData?.request?.registrationFees}
            </span>
          </div>
          <div class="summary-row">
            <span class="summary-label">CGST:</span>
            <span class="summary-value" id="cgstFees">
              ₹{uhiAppointmentData?.request?.cgstFees}
            </span>
          </div>
          <div class="summary-row">
            <span class="summary-label">SGST:</span>
            <span class="summary-value" id="sgstFees">
              ₹{uhiAppointmentData?.request?.sgstFees}
            </span>
          </div>
          <div class="summary-row">
            <span class="summary-label">Total Fees:</span>
            <span class="summary-value" id="totalFees">
              ₹{uhiAppointmentData?.request?.totalFees}
            </span>
          </div>
          <div class="summary-row">
            <span class="summary-label">Order ID:</span>
            <span class="summary-value" id="orderId">
              {uhiAppointmentData?.request?.orderId}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
            {" "}
            <CustomButton
              className="btn--secondary"
              label="Confirm"
              onClick={appointmentConfirmation}
            ></CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientPayment;
