import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router";
import * as React from "react";
import { basicSchema } from "../Common/ValidationSchema/managePatientValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  createPatientEmergencyContact,
  editPatientEmergencyContact,
  getPatientData,
  getPatientEmergencyContactByPatientId,
  setPatientData,
} from "services/patientService";
import { createUserProfile } from "services/userService";
import CustomButton from "ui-component/custom-components/CustomButton";
import SubCard from "ui-component/cards/SubCard";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import {
  BloodtypeOutlined,
  ContactEmergencyOutlined,
  FitnessCenterOutlined,
  GroupOutlined,
  HeightOutlined,
} from "@mui/icons-material";
import {
  DATE_FORMAT,
  MAX_AGE_FOR_REGUAR_DOB,
  PATIENT,
  accessToken,
  genderList,
  getRoleIdByName,
} from "store/constant";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";

const ManagePatient = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const navigate = useNavigate();
  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");

  const navigateToPatients = () => {
    navigate("/home/patient");
  };

  const { state } = useLocation();
  const patientId = state["patientId"];
  const [userId, setUserId] = useState(null);
  const [patient, setPatient] = useState(null);
  const [emergencyContactData, setEmergencyContactData] = useState({});
  const bloodGroupOptions = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const accessTokenValue = accessToken();

  const currentDate = dayjs();
  const [dobError, setDobError] = useState(patientId ? "" : "Date of Birth is required.");

  useEffect(() => {
    if (patientId) {
      const fetchData = async () => {
        try {
          const response = await getPatientData(patientId);
          const ec = await getPatientEmergencyContactByPatientId(patientId);
          setEmergencyContactData(ec?.data[0]);
          setUserId(response?.data?.user?.id);
          setPatient(response?.data);
        } catch (error) {
          handleClick("error", "Error fetching patient data");
        }
      };
      fetchData();
    }
  }, [accessTokenValue, patientId, handleClick]);

  return (
    <Reveal>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: patient?.user?.firstName ? patient.user.firstName : "",
          middleName: patient?.user?.middleName ? patient.user.middleName : "",
          lastName: patient?.user?.lastName ? patient.user.lastName : "",
          gender: patient?.user?.gender ? patient.user.gender : "",
          email: patient?.user?.emailId ? patient.user.emailId : "",
          password: patient?.user?.password ? patient.user.password : "",
          mobileNumber: patient?.user?.mobileNumber ? patient.user.mobileNumber : "",
          address: patient?.user?.address ? patient.user.address : "",
          dateOfBirth: patient?.user?.dateOfBirth ? dayjs(patient.user.dateOfBirth) : null,
          bloodGroup: patient?.bloodGroup ? patient.bloodGroup : "",
          height: patient?.height ? patient.height : "",
          weight: patient?.weight ? patient.weight : "",
          allergies: patient?.allergies ? patient.allergies : "",
          medications: patient?.medications ? patient.medications : "",
          emergencyContactName: emergencyContactData?.name ? emergencyContactData?.name : "",
          emergencyContactNumber: emergencyContactData?.mobileNumber
            ? emergencyContactData?.mobileNumber
            : "",
          emergencyContactRelationship: emergencyContactData?.relationship
            ? emergencyContactData?.relationship
            : "",
          submit: "",
        }}
        validationSchema={basicSchema}
        onSubmit={async (values) => {
          if (!dobError) {
            const data = {
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              roleId: await getRoleIdByName(PATIENT, handleClick),
              roleName: PATIENT,
              mobileNumber: values.mobileNumber,
              emailId: values.email,
              password: values.password,
              address: values.address,
              gender: values.gender ? values.gender : "",
              dateOfBirth: values.dateOfBirth.format(DATE_FORMAT),
              noOfAttempts: null,
            };

            const emergencyData = {
              name: values?.emergencyContactName,
              mobileNumber: values?.emergencyContactNumber,
              relationship: values?.emergencyContactRelationship,
              patientId: patientId,
            };

            try {
              if (patientId) {
                const updateData = {
                  id: patientId,
                  bloodGroup: values.bloodGroup,
                  height: values.height,
                  weight: values.weight,
                  allergies: values.allergies,
                  medications: values.medications,
                  emergencyContactName: values.emergencyContactName,
                  emergencyContactNumber: values.emergencyContactNumber,
                  emergencyContactRelationship: values.emergencyContactRelationship,
                  user: {
                    id: userId,
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    password: values.password,
                    roleId: await getRoleIdByName(PATIENT, handleClick),
                    roleName: PATIENT,
                    gender: values.gender,
                    address: values.address,
                    mobileNumber: values.mobileNumber,
                    emailId: values.email,
                    dateOfBirth: values.dateOfBirth.format(DATE_FORMAT),
                  },
                };
                if (emergencyContactData?.id) {
                  await editPatientEmergencyContact(emergencyContactData?.id, emergencyData);
                } else {
                  await createPatientEmergencyContact(emergencyData);
                }
                try {
                  await setPatientData(patientId, updateData);
                  handleClick("success", "Patient updated successfully!");
                  setTimeout(() => {
                    navigateToPatients();
                  }, 1000);
                } catch (e) {
                  handleClick("error", "Error updating patient");
                }
              } else {
                try {
                  const response = await createUserProfile(data);
                  if (response) {
                    const payload = {
                      bloodGroup: values?.bloodGroup || "",
                      height: values?.height || "",
                      weight: values?.weight || "",
                      allergies: values?.allergies || "",
                      medications: values?.medications || "",
                      emergencyContactName: values?.emergencyContactName || " ",
                      emergencyContactNumber: values?.emergencyContactNumber || "",
                      emergencyContactRelationship: values?.emergencyContactRelationship || "",
                      user: response?.data,
                    };
                    try {
                      const res = await setPatientData(response?.data.roleBasedId, payload);

                      const newEmergencyContactData = {
                        name: values?.emergencyContactName,
                        mobileNumber: values?.emergencyContactNumber,
                        relationship: values?.emergencyContactRelationship,
                        patientId: res?.data?.id,
                      };
                      try {
                        await createPatientEmergencyContact(newEmergencyContactData);
                        handleClick("success", "Patient created successfully!");
                        setTimeout(() => {
                          navigateToPatients();
                        }, 1000);
                      } catch (e) {
                        handleClick("error", "Error creating patient");
                      }
                    } catch (e) {
                      handleClick("error", "Error creating patient");
                    }
                  }
                } catch (e) {
                  handleClick("error", "Error creating patient");
                }
              }
            } catch (error) {
              handleClick("error", "Error updating patient");
            }
          }
        }}
      >
        {({ errors, touched, values, setFieldValue, handleBlur, handleChange, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
                mb: 2,
              }}
            >
              {patientId ? "Update Patient" : "Patient Registration"}
            </Typography>
            <SubCard style={{ padding: "15px" }}>
              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="First Name"
                name="firstName"
                required
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.firstName && errors.firstName)}
                errorText={errors.firstName}
                startAdornment={<PersonOutlineOutlinedIcon />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Middle Name"
                name="middleName"
                value={values.middleName}
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<PersonOutlineOutlinedIcon />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Last Name"
                name="lastName"
                required
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.lastName && errors.lastName)}
                errorText={errors.lastName}
                startAdornment={<PersonOutlineOutlinedIcon />}
                size={"big"}
              />

              <FormSelectField
                style={{
                  width: "30%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                label="Gender"
                name="gender"
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<PersonOutlineOutlinedIcon />}
                menuItems={genderList}
                value={values.gender}
                error={Boolean(touched.gender && errors.gender)}
                errorText={errors.gender}
                size={"big"}
              ></FormSelectField>

              <FormDatePicker
                inputFormat="DD/MM/YYYY"
                disableFuture
                label={"Birth date"}
                value={values.dateOfBirth}
                size={"big"}
                minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
                required
                onChange={(date) => {
                  setFieldValue("dateOfBirth", date);
                  if (date === null) {
                    setDobError("Date of birth is required.");
                  } else if (!date.isValid()) {
                    setDobError("Invalid 'Date of birth' value.");
                  } else if (date > currentDate) {
                    setDobError("Date of birth cannot be a future date.");
                  } else if (date < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
                    setDobError(
                      `can't be less than the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}.`
                    );
                  } else {
                    setDobError("");
                  }
                }}
                style={{
                  width: "30%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                error={Boolean(dobError && touched.dateOfBirth)}
                errorText={dobError}
              ></FormDatePicker>

              {/* <FormSelectField
                name={"bloodGroup"}
                style={{
                  width: "30%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                label={"Blood Group"}
                onBlur={handleBlur}
                value={values.bloodGroup}
                size={"big"}
                startAdornment={<BloodtypeOutlined />}
                onChange={handleChange}
                menuItems={bloodGroupOptions.map((el) => {
                  return {
                    ...el,
                    value: el,
                    menuLabel: el,
                  };
                })}
              ></FormSelectField> */}

              {/* <FormInputField
                type={"number"}
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Height (in cm)"
                name="height"
                value={values.height}
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<HeightOutlined />}
                size={"big"}
              />

              <FormInputField
                type={"number"}
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Weight (in Kg)"
                name="weight"
                value={values.weight}
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<FitnessCenterOutlined />}
                size={"big"}
              /> */}

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Mobile Number"
                disabled={Boolean(patientId)}
                type={"tel"}
                required
                name="mobileNumber"
                value={values.mobileNumber}
                inputProps={{
                  maxLength: 10,
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                errorText={errors.mobileNumber}
                startAdornment={<i className="ri-phone-line ri-xl" />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="E-mail"
                name="email"
                required
                disabled={Boolean(patientId)}
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                errorText={errors.email}
                startAdornment={<i className="ri-mail-line ri-xl" />}
                size={"big"}
              />

              {/* <FormInputField
                style={{
                  width: "63.2%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Area of Intervention / Address"
                name="address"
                value={values.address}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.address && errors.address)}
                errorText={errors.address}
                startAdornment={<HomeOutlinedIcon />}
                size={"big"}
              /> */}

              {/* <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Emergency Contact Name"
                name="emergencyContactName"
                value={values.emergencyContactName}
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<ContactEmergencyOutlined />}
                size={"big"}
              /> */}

              {/* <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Emergency Contact Number"
                type={"tel"}
                name="emergencyContactNumber"
                value={values.emergencyContactNumber}
                onBlur={handleBlur}
                inputProps={{
                  maxLength: 10,
                }}
                onChange={handleChange}
                error={Boolean(touched.emergencyContactNumber && errors.emergencyContactNumber)}
                errorText={errors.emergencyContactNumber}
                startAdornment={<i className="ri-phone-line ri-xl" />}
                size={"big"}
              /> */}

              {/* <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Emergency Contact Relationship"
                name="emergencyContactRelationship"
                value={values.emergencyContactRelationship}
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<GroupOutlined />}
                size={"big"}
              /> */}
            </SubCard>

            <Box sx={{ mt: 3 }} display="flex">
              <CustomButton
                className="btn--secondary"
                style={{ marginRight: "20px" }}
                type="submit"
                label={"Save"}
              ></CustomButton>
              <CustomButton
                className="btn--error"
                onClick={() => navigateToPatients()}
                label="Cancel"
              />
            </Box>
          </form>
        )}
      </Formik>
    </Reveal>
  );
};

export default ManagePatient;
