import { Box, Typography, Grid, Card, Skeleton, Chip, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  ACTIVE_STATE,
  currentActiveUser,
  entitiesInfo,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
} from "store/constant";
import { getFeatures } from "services/EntitiesServices";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";
import { api } from "services/AxiosInterceptor";
import CustomButton from "ui-component/custom-components/CustomButton";
import { Formik } from "formik";
import { useRef } from "react";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { DomainOutlined } from "@mui/icons-material";
import Reveal from "views/utilities/Reveal";
import {
  createFeatureAssociation,
  deleteFeatureAssociation,
  getFeatureAssociationsByFeatureId,
} from "services/EntitiesServices";
import { useDispatch, useSelector } from "react-redux";
import { getUserFeatures } from "store/Reducers/userReducer";
import { setUserFeatures } from "store/Actions/userActions";
import InfoIcon from "@mui/icons-material/Info";
import InfoModal from "../InfoModal";

const FeaturesAssociation = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  let currUsersFeatures = useSelector(getUserFeatures);
  const { handleClick } = useContext(ToastContext);
  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState(null);
  const [associations, setAssociations] = useState(null);
  const [features, setFeatures] = useState(null);
  const [updatedUsers, setupdatedUsers] = useState([]);
  const [updatedRoles, setupdatedRoles] = useState([]);
  const [selUsers, setselUsers] = useState([]);
  const [selRoles, setselRoles] = useState([]);
  const currUserid = currentActiveUser().id;
  const currRole = currentActiveUser().roleId;
  const entityInfo = entitiesInfo.find((entity) => entity.id === "FeaturesAssociation");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // toDo
        const response = await api.get("/api/users");
        setUsers(
          response?.data.map((el) => {
            return { value: el.id, menuLabel: el.name };
          })
        );
        const roleResponse = await api.get("/api/roles");
        setRoles(
          roleResponse?.data.map((el) => {
            return { value: el.id, menuLabel: el.name };
          })
        );
        const featureResponse = await getFeatures();
        setFeatures(
          featureResponse?.data.map((el) => {
            return { value: el.id, menuLabel: el.name, code: el.code };
          })
        );
      } catch (error) {
        handleClick("error", "error fetching data");
      }
    };
    fetchData();
  }, [handleClick]);

  const handleUserAndRoleChanges = (associations) => {
    let usersToBeRemoved = [],
      rolesTpBeRemoved = [];
    let tempUsers = [],
      tempRoles = [];
    associations.forEach((el) => {
      if (el.status === ACTIVE_STATE) {
        if (el.userId) {
          usersToBeRemoved.push(el.userId);
          tempUsers.push(users.find((u) => u.value === el.userId));
        }
        if (el.roleId) {
          rolesTpBeRemoved.push(el.roleId);
          tempRoles.push(roles.find((r) => r.value === el.roleId));
        }
      }
    });
    setselUsers(tempUsers);
    setselRoles(tempRoles);
    setupdatedUsers(users.filter((x) => !usersToBeRemoved.includes(x.value)));
    setupdatedRoles(roles.filter((x) => !rolesTpBeRemoved.includes(x.value)));
  };

  const handleDeleteAssociation = async (element, index) => {
    const selAssociation = associations.find(
      (feature) => feature.userId === element.value || feature.roleId === element.value
    );
    const selAssociationId = selAssociation.id;
    const selFeatureId = selAssociation.featureId;
    try {
      await deleteFeatureAssociation(selAssociationId);
      if (index === 0) {
        // for maintaining list the new item has to be removed
        setselUsers(selUsers.filter((u) => u.value !== element.value));
        // for maintaining dropdown new item has to be added
        updatedUsers.push(element);
        setupdatedUsers(updatedUsers);
        if (element.value === currUserid) {
          findFeatureNameAndRemove(selFeatureId);
        }
      } else {
        // for maintaining list the new item has to be removed
        setselRoles(selRoles.filter((r) => r.value !== element.value));
        // for maintaining dropdown new item has to be added
        updatedRoles.push(element);
        setupdatedRoles(updatedRoles);
        if (element.value === currRole) {
          findFeatureNameAndRemove(selFeatureId);
        }
      }
      handleClick("success", "Feature association deleted successfully!");
    } catch (error) {
      handleClick("error", "Error deleting Feature association!");
    }
  };

  const findFeatureNameAndStore = (id) => {
    const featureName = features.find((f) => f.value === id).code;
    // if feature is already there no need to push
    if (!currUsersFeatures.hasOwnProperty(featureName)) {
      currUsersFeatures[featureName] = "";
      dispatch(setUserFeatures(currUsersFeatures));
    }
  };

  const findFeatureNameAndRemove = (id) => {
    const featureName = features.find((f) => f.value === id).code;
    delete currUsersFeatures[featureName];
    dispatch(setUserFeatures(currUsersFeatures));
  };

  return (
    <FeatureAssociationSkeleton>
      <Reveal style={{ display: "flex" }}>
        {users && features && (
          <>
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={{
                selFeature: "",
                user: "",
                role: "",
                submit: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                try {
                  if (values.user) {
                    const dataForUserLinking = {
                      featureId: values.selFeature,
                      userId: values.user,
                      featureResponse: null,
                    };
                    const res = await createFeatureAssociation(dataForUserLinking);
                    setAssociations((prev) => [...prev, res.data]);
                    // for maintaining list the new item has to be added
                    selUsers.push(users.find((u) => u.value === values.user));
                    setselUsers(selUsers);
                    // for maintaining dropdown new item has to be removed
                    setupdatedUsers(updatedUsers.filter((u) => u.value !== values.user));
                    handleClick("success", "User linked successfully!");
                    // if selected user id is the logged in user then feature should be added
                    if (values.user === currUserid) {
                      findFeatureNameAndStore(values.selFeature);
                    }
                    values.user = "";
                  }
                  if (values.role) {
                    const dataForRoleLinking = {
                      featureId: values.selFeature,
                      roleId: values.role,
                      featureResponse: null,
                    };
                    const res = await createFeatureAssociation(dataForRoleLinking);
                    setAssociations((prev) => [...prev, res.data]);
                    // for maintaining list the new item has to be added
                    selRoles.push(roles.find((r) => r.value === values.role));
                    setselRoles(selRoles);
                    // for maintaining dropdown new item has to be removed
                    setupdatedRoles(updatedRoles.filter((r) => r.value !== values.role));
                    handleClick("success", "Role linked successfully!");
                    if (values.role === currRole) {
                      findFeatureNameAndStore(values.selFeature);
                    }
                    values.role = "";
                  }
                } catch (error) {
                  handleClick("error", "Error creating feature association");
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
                      Feature Association
                    </h2>
                    {entityInfo && (
                      <>
                        <InfoIcon
                          style={{ cursor: "pointer", marginLeft: "15px" }}
                          onClick={() => setOpenDialog(true)}
                        />
                        <InfoModal
                          open={openDialog}
                          onClose={() => setOpenDialog(false)}
                          entityInfo={{ ...entityInfo, title: "Feature Association" }}
                        />
                      </>
                    )}
                  </div>
                  <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
                  <Card style={{ padding: "30px 20px" }}>
                    <Grid container className="row mb-0 mx-0">
                      <Grid item md={12} sx={{ marginBottom: "20px" }}>
                        <FormSelectField
                          style={{ width: "50%" }}
                          label="Features"
                          name="selFeature"
                          onBlur={handleBlur}
                          onChange={async (e) => {
                            setFieldValue("selFeature", e.target.value);
                            try {
                              const featureAssociations = await getFeatureAssociationsByFeatureId(
                                e.target.value
                              );
                              if (featureAssociations.data) {
                                setAssociations(featureAssociations.data);
                                handleUserAndRoleChanges(featureAssociations.data);
                              }
                            } catch (error) {
                              handleClick("error", "Error fetching feature associations!");
                            }
                          }}
                          startAdornment={<DomainOutlined />}
                          menuItems={features}
                          value={values.selFeature}
                          size={"big"}
                        ></FormSelectField>
                      </Grid>
                      {values.selFeature && (
                        <>
                          <Grid item md={3} sx={{ marginRight: "20px" }}>
                            <FormSelectField
                              style={{ width: "100%" }}
                              label="Users"
                              name="user"
                              onBlur={handleBlur}
                              onChange={async (e) => {
                                setFieldValue("user", e.target.value);
                              }}
                              startAdornment={<PersonOutlineOutlinedIcon />}
                              menuItems={updatedUsers}
                              value={values.user}
                              size={"big"}
                            ></FormSelectField>
                          </Grid>
                          <Grid item md={4} className="px-2">
                            <FormSelectField
                              style={{ width: "100%" }}
                              label="Roles"
                              name="role"
                              onBlur={handleBlur}
                              onChange={async (e) => {
                                setFieldValue("role", e.target.value);
                              }}
                              startAdornment={<PersonOutlineOutlinedIcon />}
                              menuItems={updatedRoles}
                              value={values.role}
                              size={"big"}
                            ></FormSelectField>
                          </Grid>
                          <Grid item md={1} className=""></Grid>
                          <Grid item md={3} className="">
                            <Box sx={{ mt: 1 }} display="flex">
                              <CustomButton
                                className="btn--secondary"
                                style={{ padding: "5px 50px" }}
                                type="submit"
                                disabled={!((values.user || values.role) && values.selFeature)}
                                label={"Save"}
                              ></CustomButton>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Card>
                  <Card style={{ padding: "30px 20px", marginTop: "20px" }}>
                    <Typography className="cst-title">Users</Typography>
                    {selUsers &&
                      selUsers.map((user, index) => {
                        return (
                          <>
                            <Chip
                              key={index}
                              label={user.menuLabel}
                              className="btn--primary-light cst-feature-chip"
                              onDelete={(e) => handleDeleteAssociation(user, 0)}
                            />
                          </>
                        );
                      })}
                    {selUsers.length === 0 && (
                      <>
                        <Typography className="mt-2">
                          {!values.selFeature
                            ? "Please select a feature!"
                            : "There are no selected users for this feature yet!"}
                        </Typography>
                      </>
                    )}
                  </Card>
                  <Card style={{ padding: "30px 20px", marginTop: "20px" }}>
                    <Typography className="cst-title">Roles</Typography>
                    {selRoles &&
                      selRoles.map((role, index) => {
                        return (
                          <>
                            <Chip
                              key={index}
                              label={role.menuLabel}
                              className="btn--primary-light cst-feature-chip"
                              onDelete={(e) => handleDeleteAssociation(role, 1)}
                            />
                          </>
                        );
                      })}
                    {selRoles.length === 0 && (
                      <>
                        <Typography className="mt-2">
                          {!values.selFeature
                            ? "Please select a feature!"
                            : "There are no selected roles for this feature yet!"}
                        </Typography>
                      </>
                    )}
                  </Card>
                </form>
              )}
            </Formik>
          </>
        )}
      </Reveal>
    </FeatureAssociationSkeleton>
  );
};

const FeatureAssociationSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "8px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={100}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturesAssociation;
