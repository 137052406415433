import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { pathMapper } from "store/constant";

const SecondarySidebar = ({ toggleMainClass, data }) => {
  const navigate = useNavigate();
  const InfoPage = data.children.find((item) => item.id === "InfoPage");
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function (event) {
    setWidth(window.innerWidth);
  });

  const location = useLocation();

  const navigateToRoute = (url, state) => {
    if (width <= 768) {
      toggleMainClass((prevClass) => "main responsive-shrink");
    }
    navigate(url, state);
  };

  return (
    <div className="secondary-navbar" id="secondary-navbar">
      <div className="header">
        <div className="branding">
          <div className="row justify-content-space-between align-items-center">
            <div className="col">
              <h6 className="secondary-navbar-title">{data.title}</h6>
            </div>
          </div>
        </div>

        <nav className="custom-navbar-ul">
          {data?.children?.map(
            (item) =>
              item.id !== "InfoPage" && (
                <a
                  style={{ textDecoration: "none" }}
                  href={item.url}
                  key={item.id}
                  className={`custom-nav-list ${
                    location.pathname === item.url ||
                    pathMapper[item.id]?.includes(location.pathname)
                      ? "active"
                      : ""
                  }`}
                  onClick={(event) => {
                    event.preventDefault();
                    navigateToRoute(item.url, { state: item.state });
                  }}
                >
                  <span>{item?.title}</span>
                  <img
                    src="/images/hc_double_arrows.svg"
                    className="img-double-arrow"
                    alt="img-double-arrow"
                  />
                </a>
              )
          )}
        </nav>
      </div>
      {InfoPage && (
        <div className="bottom-links">
          <nav className="custom-navbar-ul">
            <a
              style={{ textDecoration: "none" }}
              href={InfoPage.url}
              key={InfoPage.id}
              className={`custom-nav-list ${location.pathname === InfoPage.url ? "active" : ""}`}
              onClick={(event) => {
                event.preventDefault();
                navigateToRoute(InfoPage.url, { state: { ...InfoPage.state, data } });
              }}
            >
              <span>{InfoPage?.title}</span>
              <img
                src="/images/hc_double_arrows.svg"
                className="img-double-arrow"
                alt="img-double-arrow"
              />
            </a>
          </nav>
        </div>
      )}
    </div>
  );
};

export default SecondarySidebar;
