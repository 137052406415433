import React, { useMemo, useState, useEffect } from "react";
import { Input, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  SUPER_ADMIN,
  accessToken,
  currentActiveUser,
} from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { deleteOrganization, getAllOrganizations, getOrgById } from "services/organizationService";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CustomButton from "ui-component/custom-components/CustomButton";
import ModalUI from "ui-component/ModalUI";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import { Box } from "@mui/system";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "name", label: "Name" },
  { field: "address", label: "Address" },
  { field: "ad_state", label: "State" },
  { field: "ad_district", label: "District" },
  { field: "adPincode", label: "Pincode" },
  { field: "status", label: "Status" },
  { field: "Actions", label: "Actions" },
];
const Organization = () => {
  const navigate = useNavigate();
  const loggedInUserRole = currentActiveUser()?.roleName;

  const [organizations, setOrganizations] = useState([]);
  const { handleClick } = useContext(ToastContext);

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const accessTokenValue = accessToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllOrganizations();
        const organizationData = response.data.map((el, ind) => {
          return {
            ...el,
            ad_state: el.adState && el.adState.name ? el.adState.name : "N/A",
            ad_district: el.adDistrict && el.adDistrict.name ? el.adDistrict.name : "N/A",
          };
        });
        setOrganizations(organizationData);
      } catch (error) {
        console.error("Fetch organization failed");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  const showDetails = (org) => {
    showData(org);
    handleOrgClick(org);
  };

  const showData = (org) => {
    setShow(org);
    handleOpen();
  };

  const handleOrgClick = (org) => {
    navigator.clipboard.writeText(JSON.stringify(org.code));
  };

  const handleUpdate = (organization) => {
    // code to handle update functionality
    navigate("/home/manageOrganization", {
      state: { organizationId: organization.id },
    });
  };

  const handleMarkInactive = async (organization) => {
    if (organization.status === ACTIVE_STATE) {
      try {
        await deleteOrganization(organization.id);
        handleClick("success", "Organization has been marked Inactive");
        const index = organizations.findIndex((el) => el.id === organization.id);
        organizations.splice(index, 1);
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Organization as Inactive.");
      }
    } else {
      handleClick("info", "Organization is already inactive!");
    }
  };

  const navigatoToManageOrganization = () => {
    navigate("/home/manageOrganization", { state: { organizationId: null } });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const filteredRows = useMemo(() => {
    if (!searchTerm) return organizations;

    if (organizations.length > 0) {
      const attributes = Object.keys(organizations[0]);
      const list = [];
      const keyArr = ["name", "address", "ad_state", "ad_district"];
      for (const current of organizations) {
        for (const attribute of attributes) {
          if (attribute === "id") {
            continue;
          }
          if (keyArr.includes(attribute)) {
            const value = current[attribute];
            if (value && value.toLowerCase().includes(searchTerm.toLowerCase())) {
              const found = organizations.find((row) => row.id === current.id);
              if (found && !list.includes(found)) {
                list.push(found);
              }
            }
          }
        }
      }
      return list;
    }
    return [];
  }, [searchTerm, organizations]);

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleMarkInactive,
    },
    {
      label: "Generate code",
      icon: <DataObjectIcon style={{ color: "#004C70" }} />,
      onClick: showDetails,
    },
  ];

  return (
    <OrganizationSkeleton>
      <Reveal>
        <div style={{ display: "flex" }}>
          <h2 style={{ display: "inline" }}>Organizations</h2>
          <Input
            sx={{ ml: 5, mb: 2 }}
            size="lg"
            placeholder="Search a doctor..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {loggedInUserRole === SUPER_ADMIN && (
            <>
              <CustomButton
                className="ri-add-fill ri-lg btn--primary"
                style={{ marginLeft: "auto" }}
                onClick={() => navigatoToManageOrganization()}
                label="Add organization"
              />
            </>
          )}
        </div>
        <CustomizedTable
          columns={columns}
          tableData={filteredRows}
          actions={actions}
          rowsPerPageInTable={10}
        />

        {show && (
          <ModalUI
            visible={open}
            close={handleModalClose}
            title="Organization code"
            component={
              <div>
                <p>Hospital: {show?.name}</p>
                <p>
                  Address: {show?.ad_city}, {show?.ad_state}
                </p>
                <p
                  style={{
                    color: show?.status === ACTIVE_STATE ? "green" : "red",
                  }}
                >
                  {show?.status}
                </p>
                <h3 style={{ textAlign: "center" }}>Code: {show?.code}</h3>
              </div>
            }
          />
        )}
      </Reveal>
    </OrganizationSkeleton>
  );
};

export const OrganizationSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid size={{ xs: 1.5 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid container size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Organization;
