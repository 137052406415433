import * as Yup from "yup";

export const taxRateSchema = Yup.object().shape({
  taxRate: Yup.number()
    .required("Tax Rate is required")
    .typeError("Tax Rate must be a number")
    .min(0, "Tax Rate can't be less than 0%")
    .max(28, "Tax Rate can't be more than 28%"),
});

export const taxRateDescriptionSchema = Yup.object().shape({
  taxRateDescription: Yup.string().max(255, "Description text too long"),
});
