import dayjs from "dayjs";
import * as Yup from "yup";

export const packageValidation = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Minimum 3 characters required")
    .max(30, "Maximum 30 characters allowed"),
  description: Yup.string()
    .required("Description is required")
    .min(3, "Minimum 3 characters required")
    .max(100, "Maximum 100 characters allowed"),
  price: Yup.number()
    .required("Price is required")
    .min(0, "Price cannot be negative")
    .max(100000, "Price cannot be greater than 100000"),
  type: Yup.string().required("Please select package type"),
  validTill: Yup.date()
    .required("Valid Till date is required")
    .min(dayjs(), "Valid Till date can not be in the past"),
  validity: Yup.number()
    .required("Validity is required")
    .min(0, "Validity cannot be negative")
    .max(365, "Validity cannot be greater than 365"),
});

export const bundleValidation = Yup.object().shape({
  name: Yup.string().required("Name is required").max(30, "Maximum 30 characters allowed"),
  description: Yup.string()
    .required("Description is required")
    .max(100, "Maximum 100 characters allowed"),
  price: Yup.number()
    .required("Price is required")
    .min(0, "Price cannot be negative")
    .max(100000, "Price cannot be greater than 100000"),
  usageLimit: Yup.number()
    .required("Usage Limit is required")
    .min(0, "Usage Limit cannot be negative")
    .max(1000, "Usage Limit cannot be greater than 1000"),
  features: Yup.array().min(1, "Select at least one feature"),
});
