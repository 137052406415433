import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DescriptionOutlined, DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { updateFeatures } from "services/EntitiesServices";

const UpdateFeature = ({ selected, close }) => {
  const [name, setName] = useState(selected?.name);
  const [description, setDescription] = useState(selected?.description);
  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);

  const validate = () => {
    const errors = {};
    if (!name) {
      errors.name = "Required";
    }

    if (!description) {
      errors.description = "Required";
    }
    return errors;
  };

  const updateFeature = async () => {
    if (selected) {
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        setErrors({});

        const data = {
          id: selected.id,
          name: name,
          description: description,
        };
        try {
          await updateFeatures(selected.id, data);
          handleClick("success", "Feature updated successfully!");
          close();
        } catch (e) {
          handleClick("error", "Update feature failed");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Feature
      </Typography>

      <Grid container spacing={2} sx={{ maxWidth: "350px", marginTop: "5px" }}>
        <Grid item xs={12}>
          <FormInputField
            label={"Feature Name"}
            value={name}
            error={Boolean(errors.name)}
            errorText={errors.name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            startAdornment={<DriveFileRenameOutlineOutlined />}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <FormInputField
            label={"Feature Description"}
            value={description}
            error={Boolean(errors.description)}
            errorText={errors.description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            startAdornment={<DescriptionOutlined />}
            size="small"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton className="btn--primary" onClick={updateFeature} label="Update" />
      </Box>
    </Box>
  );
};

export default UpdateFeature;
