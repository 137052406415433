import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Card,
  Skeleton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import { useNavigate } from "react-router";
import * as React from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  DAY_GRID_CALENDAR,
  MONTH_GRID_CALENDAR,
  SCHEDULED,
  currentActiveUser,
  RESCHEDULED,
  TELE_CONSULTATION,
  CLINIC_VISIT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  DATE_FORMAT,
  TIME_FORMAT,
  CHECKED_IN,
  CANCELLED,
  COMPLETED,
  PRESCRIPTION_DONE,
  NO_SHOW,
  TOTAL,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_CHECKED_IN,
  TEXT_FOR_TELECONSULTATION,
  FRONT_DESK,
  TEXT_FOR_COMPLETED,
  DOCTOR,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomFullCalendar from "ui-component/custom-components/CustomFullCalendar";
import TimerSharpIcon from "@mui/icons-material/TimerSharp";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "assets/scss/doctorAppointments.scss";
import ModalUI from "ui-component/ModalUI";
import {
  AddOutlined,
  Autorenew,
  CancelOutlined,
  Close,
  EventOutlined,
  TrendingUp,
  VisibilityOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { getDoctorAvailability, getDoctorByUserId } from "services/doctorService";
import {
  updateAppointmentStatus,
  getAppointmentById,
  getFilteredAppointments,
} from "services/Appointments";
import { getBlockAvailabilities } from "services/Availability";
import { useContext } from "react";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import PatientCard from "./PatientDTO/PatientCard";
import PatientHealthDetails from "./PatientDTO/PatientHealthDetails";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCallback } from "react";
import { Menu, MenuList } from "@mui/material";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import Reveal from "views/utilities/Reveal";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Slices/userSlice";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";
import { setPrescriptionId, setPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import { useMainClass } from "layout/SidebarUtilContext";
import { styled } from "@mui/material/styles";
import { getPrescriptionByAppointmentId } from "services/PrescriptionsService";
import {
  getAppointmentEvents,
  handleMonthViewMoreClick,
  updateFilteredAppointment,
} from "utils/appointment-utils";
import CustomTabCard from "ui-component/custom-components/CustomTabCard";
import EhrSummary from "./doctor-components/EhrSummary";
import PatientPrescription from "../Patient/PatientPrescription";
import { setClearInvoiceState } from "store/Slices/billingInvoiceSlice";

const DoctorAppointments = ({ ...others }) => {
  const { mainClass } = useMainClass();

  const handleFilteredAppointmentsChange = async (appointments, info, appointmentResponse) => {
    try {
      await fetchAndFilterAppointments(doctorData?.id, doctorOrgId, appointmentResponse, info);
      const sortedAppointments = [...appointments].sort(
        (a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
      );

      appointments.forEach((appointment) => {
        if (appointment.appointmentId === sortedAppointments[0].appointmentId) {
          appointment.eventSelected = true;
        } else {
          appointment.eventSelected = false;
        }
      });

      if (sortedAppointments.length > 0) {
        const appointmentDetails = await getAppointmentById(sortedAppointments[0].appointmentId);
        setSelectedAppointment(appointmentDetails.data);
        await fetchPatientLatestVitalSigns(
          appointmentDetails.data.patientId,
          setPatientLatestVitals,
          handleClick
        );
      } else {
        setSelectedAppointment(undefined);
      }
    } catch (error) {
      console.error("Error fetching appointment");
    }
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [doctorData, setDoctorData] = useState({});
  // eslint-disable-next-line
  const [doctorAvailability, setDoctorAvailability] = useState([]);
  const [doctorAppointments, setDoctorAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const user = currentActiveUser();
  const docId = currentActiveUser()?.roleBasedId;
  const [patientVitals, setPatientVitals] = useState([]);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);
  const [appointmentResponse, setAppointmentResponse] = useState(null);

  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const [filteredDoctorEvents, setFilteredDoctorEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const [calendarView, setCalendarView] = useState(DAY_GRID_CALENDAR);
  const [viewInfo, setViewInfo] = useState(null);

  const [appointmentList, setAppointmentList] = useState([]);
  const [showAppointmentList, setShowAppointmentList] = useState(false);

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const doctorOrgId = useSelector(getUserOrgId);

  const [appointmentModalTitle, setAppointmentModalTitle] = useState(null);
  const [isReschedule, setIsRechedule] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState(null);

  const handleNewModalOpenForBookingAppointment = () => {
    const appointment = {
      doctorId: docId,
      orgId: doctorOrgId,
      appointmentDate: dayjs().format(DATE_FORMAT),
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
  };

  const handlePrescriptionClick = async (appointmentId) => {
    try {
      const response = await getPrescriptionByAppointmentId(appointmentId);
      const prescriptionId = response?.data?.id;

      const diagnosisArr = [
        ...response?.data?.prescriptionDiagnosisResponses?.map((diagnosis) => {
          return {
            ...diagnosis,
            displayName: diagnosis?.diagnosisResponse?.name,
            category: "DX",
          };
        }),
      ];
      const symptomsArr = [
        ...response?.data?.prescriptionSymptomResponses?.map((symptom) => {
          return {
            ...symptom,
            displayName: symptom?.symptomResponse?.name,
            category: "CC",
          };
        }),
      ];

      const medicationsArr = [
        ...response?.data?.prescriptionMedicationResponses?.map((medication) => {
          return {
            ...medication,
            displayName: medication?.medicationResponse?.name,
            category: "RX",
          };
        }),
      ];
      const labInvestigationsArr = [
        ...response?.data?.prescriptionLabReportResponses?.map((labTest) => {
          return {
            ...labTest,
            displayName: labTest?.labTestResponse?.name,
            category: "LAB",
          };
        }),
      ];

      const advice = response?.data?.additionalRecommendations;
      let advicesArr = [];
      if (advice !== "") {
        advicesArr = response?.data?.additionalRecommendations.split(",").map((advice) => {
          return {
            category: "ADV",
            displayName: advice,
          };
        });
      }
      dispatch(setPrescriptionId(prescriptionId));
      dispatch(
        setPrescriptionPadData({
          diagnosisArr,
          symptomsArr,
          medicationsArr,
          labInvestigationsArr,
          advicesArr,
        })
      );
      navigate(`/home/prescriptionPad/${appointmentId}`, {
        state: {
          isEditable: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleNewModalOpenForReschedulingAppointment = () => {
    const appointment = {
      id: selectedAppointment?.id,
      patientMobile: selectedAppointment?.patient?.user?.mobileNumber,
      patientName: selectedAppointment?.patient?.user?.name,
      orgId: selectedAppointment?.orgId,
      appointmentTime: selectedAppointment?.appointmentTime.slice(0, -3),
      patientId: selectedAppointment?.patientId,
      doctorId: selectedAppointment?.doctorId,
      appointmentDate: selectedAppointment?.appointmentDate,
      notes: selectedAppointment?.notes,
      type: selectedAppointment?.type,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setIsRechedule(true);
    setAppointmentModalTitle("Reschedule Appointment");
    setIsNewModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsNewModalOpen(false);
    parentAppointmentsRefresh(doctorData);
    // handleGetAppointmentEvents(doctorData);
  };

  const [doctorBlockCalendar, setDoctorBlockCalendar] = useState([]);
  const [doctorBlockCalendarEvents, setDoctorBlockCalendarEvents] = useState([]);

  const formatDateTime = (date, time) => {
    return `${date}T${time}`;
  };

  const blockEventsByTime = (blockedEvents, startTimeTobeChecked, endTimeTobeChecked) => {
    while (startTimeTobeChecked <= endTimeTobeChecked) {
      const endDateTime30Min = dayjs(startTimeTobeChecked).add(30, "minute");
      blockedEvents.push({
        start: dayjs(startTimeTobeChecked),
        end: dayjs(endDateTime30Min),
        appointmentType: "Blocked",
        display: "background",
      });
      startTimeTobeChecked = `${endDateTime30Min.format(DATE_FORMAT)}T${endDateTime30Min.format(
        TIME_FORMAT
      )}`;
    }
    return blockedEvents;
  };

  const createBlockCalendarEvents = (blockedDetails) => {
    let blockedEvents = [];
    blockedDetails.forEach((blocked) => {
      let startDateTime = formatDateTime(blocked.startDate, blocked.startTime);
      const endDateTime = formatDateTime(blocked.endDate, blocked.endTime);
      if (blocked.blockingType === "CUSTOM_DATE_TIME_RANGE") {
        let startDay = dayjs(blocked.startDate).date();
        let endDay = dayjs(blocked.endDate).date();
        let dateToBeBlocked = blocked.startDate; // by default start date
        for (let index = startDay; index <= endDay; index++) {
          let startTimeForADay = formatDateTime(dateToBeBlocked, blocked.startTime);
          let endDateTimeForADay = formatDateTime(dateToBeBlocked, blocked.endTime);
          blockedEvents = blockEventsByTime(blockedEvents, startTimeForADay, endDateTimeForADay);
          dateToBeBlocked = dayjs(dateToBeBlocked).add(1, "d").format(DATE_FORMAT);
        }
      } else {
        blockedEvents = blockEventsByTime(blockedEvents, startDateTime, endDateTime);
      }
    });
    return blockedEvents;
  };

  const getBlockCalendar = async () => {
    try {
      const response = await getBlockAvailabilities(user?.roleBasedId, doctorOrgId);
      const doctorBlockedDetails = response.data.filter(
        (entry) =>
          entry.blockingType !== "SINGLE_DATE" || entry.blockingType !== "CUSTOM_DATE_TIME_RANGE"
      );
      setDoctorBlockCalendar(doctorBlockedDetails);
      const events = createBlockCalendarEvents(doctorBlockedDetails);
      setDoctorBlockCalendarEvents(events);
    } catch (error) {
      if (error.response?.status === 404) {
      } else {
        console.error("Error fetching block calendar details");
      }
    }
  };

  useEffect(() => {
    if (user.roleName === DOCTOR) {
      getBlockCalendar();
    }
  }, []);

  // useEffect(() => {
  //   fetchAndFilterAppointments(doctorData?.id, doctorOrgId);
  // }, [viewInfo]);

  const handleGetAppointmentEvents = async (doctorDetail) => {
    try {
      const appointmentResponse = await getFilteredAppointments({
        doctorId: docId,
        orgId: doctorOrgId,
      });
      setAppointmentResponse(appointmentResponse);
      // await fetchAndFilterAppointments(docId, doctorOrgId, appointmentResponse);
      const appointmentInfoData = await getAppointmentEvents(
        doctorDetail,
        doctorOrgId,
        appointmentResponse
      );
      setDoctorAppointments([...appointmentInfoData.doctorAppointments]);
      setAllEvents([...appointmentInfoData.allEvents]);
      // const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
      // handleFilteredAppointmentsChange(filteredAppointments, viewInfo, appointmentResponse);
      // setFilteredDoctorEvents(filteredAppointments);
      return appointmentResponse;
    } catch (error) {
      console.error("Error fetching appointments");
    }
  };

  const parentAppointmentsRefresh = async (doctorData) => {
    try {
      const appointmentResponse = await handleGetAppointmentEvents(doctorData);
      // await fetchAndFilterAppointments(docId, doctorOrgId, appointmentResponse);
      const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
      handleFilteredAppointmentsChange(filteredAppointments, viewInfo, appointmentResponse);
      setFilteredDoctorEvents(filteredAppointments);
    } catch (error) {
      console.error("Error fetching refreshed appointments");
    }
  };

  const fetchAndFilterAppointments = async (doctorId, doctorOrgId, appointmentResponse, info) => {
    try {
      if (!appointmentResponse) {
        appointmentResponse = await getFilteredAppointments({
          doctorId: docId,
          orgId: doctorOrgId,
        });
      }

      const allowedStatuses = [SCHEDULED, RESCHEDULED, CHECKED_IN, PRESCRIPTION_DONE, COMPLETED];
      const allAppointments = appointmentResponse?.data?.content
        ?.filter((app) => allowedStatuses.includes(app.appointmentStatus))
        .filter(
          (app) =>
            app.appointmentDate >= dayjs(info?.start).format(DATE_FORMAT) &&
            app.appointmentDate < dayjs(info?.end).format(DATE_FORMAT)
        );
      // Filter appointments for "Scheduled" or "Rescheduled" status
      const scheduledOrRescheduled = allAppointments?.filter((app) =>
        [SCHEDULED, RESCHEDULED].includes(app.appointmentStatus)
      );

      // Filter offline appointments for "Clinic Visit" type with "Scheduled" or "Rescheduled" status
      const offline = scheduledOrRescheduled?.filter((app) => app.type === CLINIC_VISIT);

      // Filter online appointments for "Teleconsultation" type with "Scheduled" or "Rescheduled" status
      const online = scheduledOrRescheduled?.filter((app) => app.type === TELE_CONSULTATION);
      const checkedIn = allAppointments?.filter((app) => app.appointmentStatus === CHECKED_IN);
      const prescriptionDone = allAppointments?.filter(
        (app) => app.appointmentStatus === COMPLETED || app.appointmentStatus === PRESCRIPTION_DONE
      );

      setAppointmentInfo({
        totalAppointments: allAppointments,
        onlineAppointments: online,
        offlineAppointments: offline,
        checkedIn: checkedIn,
        prescriptionDone: prescriptionDone,
      });
    } catch (error) {
      console.error("Error filtering appointments", error);
      return null;
    }
  };

  useEffect(() => {
    let intervalId;
    const fetchData = async () => {
      try {
        let docResponse = {};
        if (user.roleName === DOCTOR) {
          const result = await getDoctorByUserId(user.id);
          docResponse = result?.data;
          if (docResponse) {
            const docId = docResponse?.id;
            setDoctorData(docResponse);

            const response = await getDoctorAvailability(docId, doctorOrgId);
            setDoctorAvailability(response);

            handleGetAppointmentEvents(docResponse);
            // const appointmentData = await fetchAndFilterAppointments(docId, doctorOrgId);

            // if (appointmentData) {
            //   setAppointmentInfo(appointmentData);
            // }
          }
        }
        await parentAppointmentsRefresh(docResponse);
        intervalId = setInterval(() => {
          parentAppointmentsRefresh(docResponse);
        }, 300000);
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const vitalDetails = patientLatestVitals.map((vital) => {
      return {
        ...vital,
        value: `${vital.value}${vital.vitalSignTypeResponse.unitAbbreviation ?? ""}`,
        displayName: `${vital.vitalSignTypeResponse.displayName}`,
        deviation: (
          <Grid display={"flex"}>
            <TrendingUp
              style={{
                color: "#388E3C",
                height: "20px",
                width: "20px",
              }}
            ></TrendingUp>
            <span className="change-field-value-up">+15%</span>
          </Grid>
        ),
      };
    });
    setPatientVitals(vitalDetails);
  }, [patientLatestVitals]);

  const eventClick = async (eventInfo) => {
    if (eventInfo.event.extendedProps.appointmentType === "Blocked") {
      return;
    }
    try {
      const appointmentId = doctorAppointments.find(
        (appointment) => appointment.id === eventInfo.event.extendedProps.appointmentId
      )?.id;

      const appointmentResponse = await getAppointmentById(appointmentId);
      setSelectedAppointment(appointmentResponse.data);
      await fetchPatientLatestVitalSigns(
        appointmentResponse.data.patientId,
        setPatientLatestVitals,
        handleClick
      );

      const updatedEvents = filteredDoctorEvents.map((item) => {
        if (item.appointmentId === eventInfo.event.extendedProps.appointmentId) {
          return { ...item, eventSelected: true };
        } else {
          return { ...item, eventSelected: false };
        }
      });

      setFilteredDoctorEvents(updatedEvents);
    } catch (error) {
      console.error("Error fetching appointment detail");
    }
  };
  const handleCancelAppointment = () => {
    handleDialogOpen();
  };

  // dialog box
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogYes = async () => {
    setOpen(false);
    // delete appointment, and fetch current appointments as events
    // todo : currently all appointments are shown, need to filter based on active/inactive once dto changed
    try {
      await updateAppointmentStatus(selectedAppointment.id, "CANCELLED");
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the appointment");
    }
    setSelectedAppointment(undefined);
    parentAppointmentsRefresh(doctorData);
  };

  const handleDialogNo = () => {
    setOpen(false);
  };

  const handleMonthViewMore = async (eventInfo) => {
    const appointmentDetails = await handleMonthViewMoreClick(eventInfo, doctorAppointments);
    setAppointmentList(appointmentDetails);
    setShowAppointmentList(true);
    setSelectedAppointment("");
  };

  const handleViewInfoClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowAppointmentList(false);
  };

  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [iframeAppointmentID, setIframeAppointmentId] = useState(null);

  const handleJoinRoom = useCallback(() => {
    try {
      if (selectedAppointment) {
        const room = selectedAppointment.id?.toString();
        navigate(`/home/prescriptionPad/${room}`, {
          state: {
            roomId: room,
          },
        });
      } else {
        handleClick("error", "Please select an appointment to join");
      }
    } catch (e) {
      handleClick("error", "Not able to join the call");
    }
  }, [selectedAppointment, navigate, handleClick]);

  const [anchorEl, setAnchorEl] = useState(null);
  const actions = [
    {
      label: "Cancel",
      icon: <CancelOutlined style={{ color: "#f5574c" }} />,
      onClick: handleCancelAppointment,
    },
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const CustomizedToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#004c70",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      height: "2rem",
      fontSize: "1rem",
      textAlign: "center",
      color: "#fff",
      border: "0.5px solid #004c70",
      backgroundColor: "#004c70",
      display: "flex",
      alignItems: "center",
      padding: "8px",
    },
  }));

  const getAppointmentLen = (idx) => {
    return (
      [
        appointmentInfo.totalAppointments,
        appointmentInfo.offlineAppointments,
        appointmentInfo.onlineAppointments,
        appointmentInfo.checkedIn,
        appointmentInfo.prescriptionDone,
      ][idx]?.length || 0
    );
  };
  return (
    <DoctorAppointmentSkeleton>
      <Reveal className="doctor-appointments">
        <Dialog
          open={open}
          onClose={handleDialogNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" fontSize={16}>
            Cancel Patient appointment?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Appointment will be cancelled and patient will be notified.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
              No
            </Button>
            <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <div
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "auto", padding: "0.5rem 0" }}
        >
          <div className="col breadcrumb-row">
            <h6 className="page-title">My Appointments</h6>
          </div>
          <div className="col">
            <ul className="horizontal-ul" style={{ marginTop: 0 }}>
              <li className="main-actions">
                <CustomButton
                  className={"mui-btn--primary"}
                  height="36px"
                  startIcon={<AddOutlined />}
                  label={"New Appointment"}
                  onClick={handleNewModalOpenForBookingAppointment}
                ></CustomButton>
              </li>
              <li className="main-actions">
                <CustomButton
                  height="36px"
                  className={"mui-btn--secondary"}
                  startIcon={<TimerSharpIcon></TimerSharpIcon>}
                  label={"Manage Availability"}
                  onClick={() => {
                    navigate("/home/doctorAvailability");
                  }}
                ></CustomButton>
              </li>
              <li className="main-actions" style={{ marginLeft: "0px" }}>
                <CustomButton
                  iconButton={<Autorenew style={{ color: "#004C70" }}></Autorenew>}
                  onClick={() => {
                    parentAppointmentsRefresh(doctorData);
                  }}
                  style={{ padding: "2px 5px", marginRight: "5px" }}
                  customBackGroundColor="#29BF911A"
                ></CustomButton>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-bar">
          <Reveal className="mui-tabs__bar">
            {appointmentInfo &&
              [
                TOTAL,
                TEXT_FOR_CLINIC_VISIT,
                TEXT_FOR_TELECONSULTATION,
                TEXT_FOR_CHECKED_IN,
                TEXT_FOR_COMPLETED,
              ].map((slab, i) => (
                <CustomTabCard
                  key={i}
                  title={slab}
                  value={getAppointmentLen(i)}
                  // isActive={activeTab === slab}
                  // onClick={() => handleTabChange(slab)}
                />
              ))}
          </Reveal>
        </div>

        <div>
          <Grid
            container
            className="cst-height mx-0 row justify-content-space-between align-items-center mb-0 appointment-layout"
          >
            <Grid size={{ xs: 6, md: 3.5 }} style={{ height: "100%", padding: "16px 16px 0 0" }}>
              {/* todo : some details are static, integrate api call and fetch required data */}
              <Reveal style={{ height: "100%" }}>
                <Card
                  className="custom-card-ui common-scrollbar"
                  style={{ backgroundColor: "white", height: "100%", scrollbarWidth: "none" }}
                >
                  <div className="row justify-content-space-between align-items-center">
                    {selectedAppointment &&
                      calendarView === MONTH_GRID_CALENDAR &&
                      appointmentList.length > 0 && (
                        <Reveal
                          className="btn back-btn"
                          onClick={() => {
                            setShowAppointmentList(true);
                            setSelectedAppointment("");
                          }}
                        >
                          <img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>
                        </Reveal>
                      )}

                    <div className="col card-title">
                      {!selectedAppointment ? "Patients List" : "Patient's Details"}
                    </div>

                    {!selectedAppointment && showAppointmentList && (
                      <div className="col">
                        <i
                          className={`ri-${
                            appointmentList[0]?.type === CLINIC_VISIT
                              ? "walk-fill"
                              : "video-chat-line"
                          }`}
                          style={{
                            width: "30px",
                            height: "30px",
                            fontSize: "20px",
                            textAlign: "center",
                            paddingTop: "5px",
                            color:
                              appointmentList[0]?.type === CLINIC_VISIT ? "#29bf91" : "#004c70",
                          }}
                        />
                      </div>
                    )}
                    <div className="col filters">
                      {selectedAppointment && (
                        <>
                          <CustomButton
                            iconButton={<i className="ri-more-2-fill" />}
                            onClick={(event) => handleMenuOpen(event)}
                          ></CustomButton>
                          <Menu
                            elevation={1}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{ style: { borderRadius: "4px" } }}
                          >
                            <MenuList dense sx={{ p: 0 }}>
                              {actions.map((action, actionIndex) => {
                                return (
                                  <CustomMenuItem
                                    key={actionIndex}
                                    text={action.label}
                                    icon={action.icon}
                                    onClick={() => {
                                      handleMenuClose();
                                      action.onClick();
                                    }}
                                  />
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </>
                      )}

                      {!selectedAppointment && showAppointmentList && (
                        <div className="heading-date">{appointmentList[0]?.appointmentDate}</div>
                      )}
                    </div>
                  </div>
                  {selectedAppointment && (
                    <Reveal>
                      {/* Patients basic details */}
                      <PatientCard
                        patientId={selectedAppointment?.patientId}
                        viewData={{
                          basicDetails: true,
                          lifeStyle: true,
                          chronicDiseases: true,
                          lastVisited: true,
                        }}
                        patientData={selectedAppointment?.patient}
                        handleClick={handleClick}
                      ></PatientCard>
                      <Grid container className="row mb-0 mx-0 column-patient-details">
                        <Grid size={{ md: 12 }} className="px-0">
                          <div className="row w-100 mb-0 align-items-left flex-direction-column">
                            {selectedAppointment && (
                              <>
                                <div
                                  style={{
                                    flexWrap: "wrap",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "5px",
                                  }}
                                >
                                  {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
                                    selectedAppointment?.appointmentStatus
                                  ) && (
                                    <CustomizedToolTip
                                      title={mainClass === "main" ? "Reschedule" : ""}
                                      arrow
                                    >
                                      <CustomButton
                                        className={"btn--primary-light"}
                                        height="32px"
                                        label={mainClass !== "main" ? "Reschedule" : ""}
                                        startIcon={mainClass !== "main" && <EventOutlined />}
                                        iconButton={mainClass === "main" && <EventOutlined />}
                                        onClick={handleNewModalOpenForReschedulingAppointment}
                                        style={{ padding: mainClass === "main" && "0px 20px" }}
                                      />
                                    </CustomizedToolTip>
                                  )}
                                  <CustomizedToolTip
                                    title={mainClass === "main" ? "View EHR" : ""}
                                    arrow
                                  >
                                    <CustomButton
                                      className={"btn--primary-light"}
                                      height="32px"
                                      label={mainClass !== "main" ? "View EHR" : ""}
                                      startIcon={mainClass !== "main" && <VisibilityOutlined />}
                                      iconButton={mainClass === "main" && <VisibilityOutlined />}
                                      style={{ padding: mainClass === "main" && "0px 20px" }}
                                      onClick={() => {
                                        navigate(`/home/ehr/${selectedAppointment?.patientId}`);
                                      }}
                                    />
                                  </CustomizedToolTip>
                                  {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
                                    selectedAppointment?.appointmentStatus
                                  ) ? (
                                    <CustomButton
                                      className={"mui-btn--primary"}
                                      height="32px"
                                      label={"Consult Now"}
                                      endIcon={
                                        <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                                      }
                                      onClick={() => {
                                        dispatch(
                                          setPrescriptionPadData({
                                            diagnosisArr: [],
                                            symptomsArr: [],
                                            medicationsArr: [],
                                            labInvestigationsArr: [],
                                            advicesArr: [],
                                          })
                                        );
                                        dispatch(setPrescriptionId(null));
                                        dispatch(setClearInvoiceState());
                                        if (selectedAppointment.type === TELE_CONSULTATION) {
                                          handleJoinRoom();
                                        } else {
                                          navigate(
                                            `/home/prescriptionPad/${selectedAppointment?.id}`
                                          );
                                        }
                                      }}
                                    />
                                  ) : (
                                    [COMPLETED, PRESCRIPTION_DONE].includes(
                                      selectedAppointment?.appointmentStatus
                                    ) &&
                                    dayjs(selectedAppointment.appointmentDate).isAfter(
                                      dayjs().subtract(15, "day")
                                    ) && (
                                      <CustomButton
                                        className={"mui-btn--primary"}
                                        height="32px"
                                        label={"Edit Prescription"}
                                        startIcon={<i className="ri-eye-line ri-xl"></i>}
                                        onClick={() => {
                                          handlePrescriptionClick(selectedAppointment.id);
                                        }}
                                      />
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <PatientHealthDetails
                        patientVitals={patientVitals}
                        patientId={selectedAppointment?.patientId}
                        handleClick={handleClick}
                        patientAbhaId={selectedAppointment?.patient?.abhaId}
                        setIframeOpen={setIframeOpen}
                        setIframeSrc={setIframeSrc}
                        setIframeAppointmentId={setIframeAppointmentId}
                      ></PatientHealthDetails>
                    </Reveal>
                  )}
                  {!selectedAppointment && !showAppointmentList && (
                    <Grid>No Appointment selected...</Grid>
                  )}

                  {!selectedAppointment &&
                    showAppointmentList &&
                    appointmentList
                      .filter(
                        (appointment) =>
                          ![CANCELLED, NO_SHOW].includes(appointment.appointmentStatus)
                      )
                      .map((appointment, index) => (
                        <Reveal>
                          <Card key={index} className="appointment-card">
                            <PatientCard
                              patientId={appointment?.patientId}
                              patientData={appointment?.patient}
                              viewData={{
                                basicDetails: true,
                                lifeStyle: true,
                                chronicDiseases: true,
                                lastVisited: true,
                              }}
                              selectedAppointment={appointment}
                              handleClick={handleClick}
                            ></PatientCard>
                            <Grid container className="row mb-0 mx-0 column-patient-details">
                              <Grid size={{ md: 12 }} className="px-0">
                                <div className="row w-100 mb-0 align-items-left flex-direction-column">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <CustomButton
                                      className={"btn--secondary"}
                                      style={{ marginRight: "12px", height: "50px" }}
                                      label={"More Details"}
                                      startIcon={<InfoOutlinedIcon />}
                                      onClick={() => {
                                        handleViewInfoClick(appointment);
                                      }}
                                    ></CustomButton>
                                    {[SCHEDULED, RESCHEDULED, CHECKED_IN].includes(
                                      appointment?.appointmentStatus
                                    ) ? (
                                      <CustomButton
                                        className={"mui-btn--primary"}
                                        style={{ height: "50px" }}
                                        label={"Consult Now"}
                                        endIcon={
                                          <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                                        }
                                        onClick={() => {
                                          dispatch(
                                            setPrescriptionPadData({
                                              diagnosisArr: [],
                                              symptomsArr: [],
                                              medicationsArr: [],
                                              labInvestigationsArr: [],
                                              advicesArr: [],
                                            })
                                          );
                                          dispatch(setPrescriptionId(null));
                                          dispatch(setClearInvoiceState());
                                          navigate(`/home/prescriptionPad/${appointment?.id}`);
                                        }}
                                      ></CustomButton>
                                    ) : (
                                      [COMPLETED, PRESCRIPTION_DONE].includes(
                                        appointment?.appointmentStatus
                                      ) &&
                                      dayjs(appointment.appointmentDate).isAfter(
                                        dayjs().subtract(15, "day")
                                      ) && (
                                        <CustomButton
                                          className={"mui-btn--primary"}
                                          style={{ height: "50px" }}
                                          label={"Edit Prescription"}
                                          startIcon={<i className="ri-eye-line ri-xl"></i>}
                                          onClick={() => {
                                            handlePrescriptionClick(appointment.id);
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Reveal>
                      ))}
                </Card>
              </Reveal>
            </Grid>
            <Grid
              id="prescription-clinic-ehr-details"
              size={{ md: 3, xs: 6 }}
              style={{ height: "100%", padding: "16px 16px 0 0" }}
            >
              <Card
                className="custom-card-ui common-scrollbar"
                style={{
                  backgroundColor: "white",
                  scrollbarWidth: "none",
                }}
              >
                <EhrSummary
                  key={selectedAppointment?.id}
                  patientId={selectedAppointment?.patientId}
                ></EhrSummary>
              </Card>
            </Grid>
            <Grid size={{ md: 5.5, xs: 12 }} style={{ height: "100%", paddingTop: "16px" }}>
              <Reveal style={{ height: "100%" }}>
                <Box className="left-box">
                  <CustomFullCalendar
                    appointmentResponse={appointmentResponse}
                    setCalendarView={setCalendarView}
                    viewInfo={viewInfo}
                    setViewInfo={setViewInfo}
                    calendarView={calendarView}
                    setFilteredDoctorEvents={setFilteredDoctorEvents}
                    allEvents={allEvents}
                    filteredDoctorEvents={filteredDoctorEvents}
                    eventClick={eventClick}
                    blockedDays={doctorBlockCalendar}
                    blockedEvents={doctorBlockCalendarEvents}
                    slotDuration={doctorData?.avgAppointmentDuration <= 15 ? 30 : 60}
                    onFilteredAppointmentsChange={handleFilteredAppointmentsChange}
                    handleMonthViewMore={handleMonthViewMore}
                    setShowAppointmentList={setShowAppointmentList}
                    handlePrescriptionClick={handlePrescriptionClick}
                  ></CustomFullCalendar>
                </Box>
              </Reveal>
            </Grid>
          </Grid>
          <ModalUI
            visible={isNewModalOpen}
            close={() => {
              dispatch(setSelectedAppointmentData({}));
              setIsNewModalOpen(false);
            }}
            title={appointmentModalTitle}
            style={{
              overflowY: "auto",
              height: "550px",
              width: "610px",
            }}
            component={
              <BookAppointmentModal
                reschedule={isReschedule}
                handleClick={handleClick}
                closeModal={handleNewModalClose}
              />
            }
          />

          {iframeOpen && (
            <div className="iframe-div">
              {iframeSrc ? (
                <iframe
                  title="consent form"
                  src={iframeSrc}
                  target="_blank"
                  className="iframe"
                ></iframe>
              ) : (
                <div className="iframe">
                  <PatientPrescription appId={iframeAppointmentID} />
                </div>
              )}

              <CustomButton
                iconButton={<Close />}
                onClick={() => {
                  setIframeOpen(false);
                  setIframeSrc(null);
                }}
                className="iframe-close-button"
              />
            </div>
          )}
        </div>
      </Reveal>
    </DoctorAppointmentSkeleton>
  );
};

export default DoctorAppointments;

const DoctorAppointmentSkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container size={{ xs: 12 }} sx={{ justifyContent: "space-between" }}>
          <Grid size={{ xs: 3 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>

          <Grid display={"flex"} size={{ xs: 6 }}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="10%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid container size={{ xs: 12 }} sx={{ justifyContent: "space-between" }}>
          <Grid size={{ xs: 8 }} pr={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
