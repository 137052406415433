import {
  ClearOutlined,
  EmojiObjectsOutlined,
  LayersOutlined,
  LocalHospitalOutlined,
  MonitorHeartOutlined,
  TimelapseOutlined,
  TroubleshootOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  createPatientTreatmentPlan,
  deletePatientTreatmentPlan,
  editPatientTreatmentPlan,
  getPatientTreatmentPlanByPatientId,
} from "services/patientService";
import { startsAndEndsWithAlphabetic, startsWithAlphabetic } from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "diagnosis", label: "Diagnosis" },
  { field: "objectives", label: "Objectives" },
  { field: "interventions", label: "Interventions" },
  { field: "expectedOutcome", label: "Expected Outcome" },
  { field: "duration", label: "Duration" },
  {
    field: "responsibleHealthcareProfessional",
    label: "Responsible Healthcare Professional",
  },
  { field: "Actions", label: "Actions" },
];

const PatientTreatmentPlan = ({ patientId }) => {
  const [patientTreatmentPlans, setPatientTreatmentPlans] = useState([]);
  const [displayPatientTreatmentPlans, setDisplayPatientTreatmentPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientTreatmentPlan(row.id);
      handleClick("success", "Patient Treatment-plan has been successfully deleted.");
      const index = patientTreatmentPlans.findIndex((el) => el.id === row.id);
      patientTreatmentPlans.splice(index, 1);
      setDisplayPatientTreatmentPlans(patientTreatmentPlans);
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the Patient Treatment-plan");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pPhysicalExam) => {
    setSelected(pPhysicalExam);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchPatientTreatmentPlans = async () => {
    try {
      const response = await getPatientTreatmentPlanByPatientId(patientId);
      setPatientTreatmentPlans(response.data);
    } catch (error) {
      console.error("Error fetching patient treatment plan");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    fetchPatientTreatmentPlans();
  }, []);

  useEffect(() => {
    setDisplayPatientTreatmentPlans(
      patientTreatmentPlans.filter((item) => {
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (typeof value === "string" && index !== 8) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return false;
        });
      })
    );
  }, [patientTreatmentPlans, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search Treatment-plan"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"ri-add-fill ri-lg btn--primary"}
          onClick={openCreateModal}
        />
      </Grid>
      <CustomizedTable
        columns={columns}
        tableData={displayPatientTreatmentPlans}
        actions={actions}
        rowsPerPageInTable={10}
      />
      <ModalUI
        visible={open}
        close={closeModal}
        style={{
          overflow: "auto",
        }}
        title={selected ? "Update Patient treatment-plan" : "Create Patient treatment-plan"}
        component={
          <PatientTreatmentPlanModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientTreatmentPlans();
              } else {
                closeModal();
              }
            }}
            patientId={patientId}
          />
        }
      />
    </Reveal>
  );
};

const PatientTreatmentPlanModal = ({ patientId, selected, handleClick, close }) => {
  const [diagnosis, setDiagnosis] = useState(selected?.diagnosis ? selected?.diagnosis : "");
  const [diagnosisTouched, setDiagnosisTouched] = useState(false);

  const [objectives, setObjectives] = useState(selected?.objectives ? selected?.objectives : "");
  const [objectivesTouched, setObjectivesTouched] = useState(false);

  const [interventions, setInterventions] = useState(
    selected?.interventions ? selected?.interventions : ""
  );
  const [interventionsTouched, setInterventionTouched] = useState(false);

  const [expectedOutcome, setExpectedOutcome] = useState(
    selected?.expectedOutcome ? selected?.expectedOutcome : null
  );
  const [expectedOutcomeTouched, setExpectedOutcomeTouched] = useState(false);

  const [duration, setDuration] = useState(selected?.duration ? selected?.duration : "");
  const [durationTouched, setDurationTouched] = useState(false);

  const [responsibleHealthcareProfessional, setResponsibleHealthcareProfessional] = useState(
    selected?.responsibleHealthcareProfessional ? selected?.responsibleHealthcareProfessional : ""
  );
  const [responsibleHealthcareProfessionalTouched, setResponsibleHealthcareProfessionalTouched] =
    useState(false);

  const [errors, setErrors] = useState({});

  const validateDiagnosis = (errors, diagnosis) => {
    if (!diagnosis) {
      errors.diagnosis = "Diagnosis is required.";
    } else if (diagnosis.length < 3) {
      errors.diagnosis = "Must be at least 3 characters long";
    } else if (diagnosis.length > 1000) {
      errors.diagnosis = "Cannot be longer than 1000 characters";
    } else if (!startsWithAlphabetic(diagnosis)) {
      errors.diagnosis = "Must start with alphabet";
    }
  };

  const validateObjectives = (errors, objectives) => {
    if (!objectives) {
      errors.objectives = "Objectives is required.";
    } else if (objectives.length < 3) {
      errors.objectives = "Must be at least 3 characters long";
    } else if (objectives.length > 1000) {
      errors.objectives = "Cannot be longer than 1000 characters";
    } else if (!startsWithAlphabetic(objectives)) {
      errors.objectives = "Must start with alphabet";
    }
  };

  const validateInterventions = (errors, interventions) => {
    if (!interventions) {
      errors.interventions = "Interventions is required.";
    } else if (interventions.length < 3) {
      errors.interventions = "Must be at least 3 characters long";
    } else if (interventions.length > 1000) {
      errors.interventions = "Cannot be longer than 1000 characters";
    } else if (!startsWithAlphabetic(interventions)) {
      errors.interventions = "Must start with alphabet";
    }
  };

  const validateExpectedOutcome = (errors, expectedOutcome) => {
    if (!expectedOutcome) {
      errors.expectedOutcome = "Expected outcome is required.";
    } else if (expectedOutcome.length < 3) {
      errors.expectedOutcome = "Must be at least 3 characters long";
    } else if (expectedOutcome.length > 255) {
      errors.expectedOutcome = "Cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(expectedOutcome)) {
      errors.expectedOutcome = "Must start with alphabet";
    }
  };

  const validateDuration = (errors, duration) => {
    const pattern = /^[a-zA-Z0-9\s]+$/;
    if (!duration) {
      errors.duration = "Duration is required.";
    } else if (duration.length < 3) {
      errors.duration = "Must be at least 3 characters long";
    } else if (duration.length > 100) {
      errors.duration = "Cannot be longer than 100 characters";
    } else if (!pattern.test(duration)) {
      errors.duration = "can't contain special characters";
    }
  };

  const validateResponsibleHealcareProfessional = (errors, responsibleHealthcareProfessional) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!responsibleHealthcareProfessional) {
      errors.responsibleHealthcareProfessional = "Responsible Healthcare Professional is required.";
    } else if (responsibleHealthcareProfessional.length < 3) {
      errors.responsibleHealthcareProfessional = "Must be at least 3 characters long";
    } else if (responsibleHealthcareProfessional.length > 100) {
      errors.responsibleHealthcareProfessional = "Cannot be longer than 100 characters";
    } else if (!pattern.test(responsibleHealthcareProfessional)) {
      errors.responsibleHealthcareProfessional = "can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(responsibleHealthcareProfessional)) {
      errors.responsibleHealthcareProfessional = "Must start and end with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setDiagnosisTouched(true);
    validateDiagnosis(errors, diagnosis);

    setObjectivesTouched(true);
    validateObjectives(errors, objectives);

    setInterventionTouched(true);
    validateInterventions(errors, interventions);

    setExpectedOutcomeTouched(true);
    validateExpectedOutcome(errors, expectedOutcome);

    setDurationTouched(true);
    validateDuration(errors, duration);

    setResponsibleHealthcareProfessionalTouched(true);
    validateResponsibleHealcareProfessional(errors, responsibleHealthcareProfessional);

    return errors;
  };

  const savePatientTreatmentPlan = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        diagnosis,
        objectives,
        interventions,
        expectedOutcome,
        duration,
        responsibleHealthcareProfessional,
      };

      if (selected) {
        try {
          await editPatientTreatmentPlan(selected.id, data);
          handleClick("success", "Patient treatment-plan has been successfully updated!");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error updating the patient treatment-plan");
        }
      } else {
        try {
          await createPatientTreatmentPlan(data);
          handleClick("success", "Patient treatment-plan has been successfully created!");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error creating the patient treatment-plan");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Diagnosis"
            name="diagnosis"
            required
            value={diagnosis}
            onBlur={(e) => {
              setDiagnosisTouched(true);
              const newError = {};
              validateDiagnosis(newError, diagnosis);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setDiagnosis(e.target.value);
              if (diagnosisTouched) {
                const newError = {};
                validateDiagnosis(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.diagnosis;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<TroubleshootOutlined />}
            size={"big"}
            error={Boolean(errors.diagnosis)}
            errorText={errors.diagnosis}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Objectives"
            name="objectives"
            required
            value={objectives}
            onBlur={(e) => {
              setObjectivesTouched(true);
              const newError = {};
              validateObjectives(newError, objectives);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setObjectives(e.target.value);
              if (objectivesTouched) {
                const newError = {};
                validateObjectives(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.objectives;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<EmojiObjectsOutlined />}
            size={"big"}
            error={Boolean(errors.objectives)}
            errorText={errors.objectives}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Interventions"
            name="interventions"
            required
            value={interventions}
            onBlur={(e) => {
              setInterventionTouched(true);
              const newError = {};
              validateInterventions(newError, interventions);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setInterventions(e.target.value);
              if (interventionsTouched) {
                const newError = {};
                validateInterventions(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.interventions;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<LayersOutlined />}
            size={"big"}
            error={Boolean(errors.interventions)}
            errorText={errors.interventions}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Expected Outcome"
            required
            name="expectedOutcome"
            value={expectedOutcome}
            onBlur={(e) => {
              setExpectedOutcomeTouched(true);
              const newError = {};
              validateExpectedOutcome(newError, expectedOutcome);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setExpectedOutcome(e.target.value);
              if (expectedOutcomeTouched) {
                const newError = {};
                validateExpectedOutcome(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.expectedOutcome;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<MonitorHeartOutlined />}
            size={"big"}
            error={Boolean(errors.expectedOutcome)}
            errorText={errors.expectedOutcome}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Duration"
            name="duration"
            required
            value={duration}
            onBlur={(e) => {
              setDurationTouched(true);
              const newError = {};
              validateDuration(newError, duration);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setDuration(e.target.value);
              if (durationTouched) {
                const newError = {};
                validateDuration(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.duration;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<TimelapseOutlined />}
            size={"big"}
            error={Boolean(errors.duration)}
            errorText={errors.duration}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Responsible Healthcare Professional"
            name="responsibleHealthcareProfessional"
            required
            value={responsibleHealthcareProfessional}
            onBlur={(e) => {
              setResponsibleHealthcareProfessionalTouched(true);
              const newError = {};
              validateResponsibleHealcareProfessional(newError, responsibleHealthcareProfessional);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setResponsibleHealthcareProfessional(e.target.value);
              if (responsibleHealthcareProfessionalTouched) {
                const newError = {};
                validateResponsibleHealcareProfessional(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.responsibleHealthcareProfessional;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<LocalHospitalOutlined />}
            size={"big"}
            error={Boolean(errors.responsibleHealthcareProfessional)}
            errorText={errors.responsibleHealthcareProfessional}
          />
        </Grid>

        <Grid sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={savePatientTreatmentPlan}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientTreatmentPlan;
