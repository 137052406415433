import {
  AccountBalance,
  Article,
  CloudUpload,
  CorporateFare,
  DomainOutlined,
  FiberPinOutlined,
  HomeOutlined,
  HomeWork,
  Http,
  InsertPhoto,
  Mail,
  NavigateNext,
  Person,
  Phone,
  PictureAsPdf,
  Place,
  ShareLocation,
  Today,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik } from "formik";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { api } from "services/AxiosInterceptor";
import {
  basicInformation,
  getFacilitySubType,
  getFacilityType,
  getMasterData,
} from "services/HfrService";
import { getOrgById } from "services/organizationService";
import { SIZE_5_MB, hprProfile, orgId } from "store/constant";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const HfrBasicInfo = () => {
  const { handleClick } = useContext(ToastContext);
  const [orgdata, setOrgData] = useState(null);
  const hprUserProfile = hprProfile();
  const [isHidden, setIsHidden] = useState(false);

  const daysOfWeek = [
    {
      value: 1,
      label: "Monday",
      code: "MON",
      stime: null,
      etime: null,
      fullday: false,
      available: false,
    },
    {
      value: 2,
      label: "Tuesday",
      code: "TUE",
      stime: null,
      etime: null,
      fullday: false,
      available: false,
    },
    {
      value: 3,
      label: "Wednesday",
      code: "WED",
      stime: null,
      etime: null,
      fullday: false,
      available: false,
    },
    {
      value: 4,
      label: "Thursday",
      code: "THU",
      stime: null,
      etime: null,
      fullday: false,
      available: false,
    },
    {
      value: 5,
      label: "Friday",
      code: "FRI",
      stime: null,
      etime: null,
      fullday: false,
      available: false,
    },
    {
      value: 6,
      label: "Saturday",
      code: "SAT",
      stime: null,
      etime: null,
      fullday: false,
      available: false,
    },
    {
      value: 7,
      label: "Sunday",
      code: "SUN",
      stime: null,
      etime: null,
      fullday: false,
      available: false,
    },
  ];

  const handleFileUploads = async (event, setFieldValue, type, values) => {
    const file = event.target.files[0];
    if (file?.size > SIZE_5_MB) {
      handleClick("error", "File size exceeds 5MB. Please choose a smaller file.");
      event.target.value = null;
      return;
    }

    let updatedSlected;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const s = new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({ name: file.name, value: reader.result });
      };
    });

    try {
      const updatedFiles = await s;
      updatedSlected = updatedFiles;

      if (type === "Facility Building Photograph") {
        setFieldValue("facilityInformation.facilityUploads.facilityBuildingPhoto", updatedSlected);
      }
      if (type === "Facility Board Photograph") {
        setFieldValue("facilityInformation.facilityUploads.facilityBoardPhoto", updatedSlected);
      }

      if (type === "Facility Address Proof") {
        setFieldValue("facilityInformation.facilityAddressProof", [
          {
            ...values.facilityInformation.facilityAddressProof[0],
            addressProofAttachment: updatedSlected,
          },
        ]);
      }
    } catch (e) {
      handleClick("error", "Can not upload your file");
    }
  };

  const [facilityRegions, setFacilityRegions] = useState([]);
  const [addressProofs, setAddressProofs] = useState([]);
  const [facilityOperationalStatus, setFacilityOperationalStatus] = useState([]);
  const [facilityOwnership, setFacilityOwnership] = useState();
  const [facilityOwnershipSubtype, setFacilityOwnershipSubtype] = useState([]);
  const [facilityOwnershipData, setFacilityOwnershipData] = useState([]);
  const [systemOfMedicine, setSystemOfMedicine] = useState();
  const [typeOfService, setTypeOfService] = useState([]);
  const [facilityType, setFacilityType] = useState([]);
  const [facilitySubtype, setFacilitySubtype] = useState([]);
  const [typeOfHospital, setTypeOfHospital] = useState();
  const [ownershipCode, setOwnershipCode] = useState("");
  const [systemOfMedCode, setSystemOfMedCode] = useState([]);
  const [basicInfo, setBasicInfo] = useState(null);
  const [trackId, setTrackId] = useState({});
  const organizationId = orgId();

  const [subtype, setSubtype] = useState("State");

  const fetchData = async () => {
    const FacilityRegion = await getMasterData("Facility-region");
    setFacilityRegions(FacilityRegion?.data?.data);
    const addressTypes = await getMasterData("Address-proof");
    setAddressProofs(addressTypes?.data?.data);
    const facilityOperationalStatus = await getMasterData("Fac-status");
    setFacilityOperationalStatus(facilityOperationalStatus?.data?.data);
    const ownership = await getMasterData("Owner");
    setFacilityOwnership(ownership?.data?.data);
    const medicine = await getMasterData("Medicine");
    setSystemOfMedicine(medicine?.data?.data);
    const serviceType = await getMasterData("Type-service");
    setTypeOfService(serviceType?.data?.data);
    const specialityType = await getMasterData("speciality-type");
    setTypeOfHospital(specialityType?.data?.data);
  };

  useEffect(() => {
    const fetchOwnershipData = async () => {
      try {
        const ownershipData = await getMasterData(subtype);
        setFacilityOwnershipData(ownershipData?.data);
      } catch (error) {
        handleClick("error", "Can not get form status");
      }
    };

    fetchOwnershipData();
  }, [subtype, handleClick]);

  const subTypesforGov = [
    { code: "C", value: "Central Government" },
    {
      code: "S",
      value: "State Government / UT Administration",
    },
  ];
  const subTypes = [
    { code: "P", value: "Profit" },
    { code: "NP", value: "Not For Profit" },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();

  const getFacType = async (postdata) => {
    // Combine type and empty string checks with destructuring and logical AND
    const hasValidStrings = Object.values(postdata).every(
      (value) => typeof value === "string" && value.trim() !== ""
    );

    if (hasValidStrings) {
      try {
        const res = await getFacilityType(postdata);
        setFacilityType(res?.data?.data);
        setFacilitySubtype([]);
      } catch (error) {
        console.error("Error fetching facility type:", error);
      }
    } else {
      setFacilityType([]);
      setFacilitySubtype([]);
    }
  };

  const getFacSubType = async (postdata) => {
    if (!postdata?.facilityTypeCode) {
      return;
    }

    // Set isHidden based on facilityTypeCode value
    setIsHidden(["11", "12", "9", "10", "74", "13"].includes(postdata.facilityTypeCode));

    try {
      const res = await getFacilitySubType(postdata);
      setFacilitySubtype(res?.data?.data);
    } catch (error) {
      console.error("Error fetching facility subtypes:", error);
    }
  };

  const convertTo12HourFormat = (hour, minute) => {
    hour = hour % 24;

    let hour12 = hour % 12 || 12;
    let ampm = hour < 12 ? "AM" : "PM";

    minute = minute < 10 ? "0" + minute : minute;

    // Return the converted time as a string
    return `${hour12}:${minute} ${ampm}`;
  };

  const fetchOrg = async () => {
    try {
      const response = await getOrgById(organizationId);
      setOrgData(response?.data);
    } catch (error) {
      handleClick("error", "Can not get organization details");
    }

    try {
      const response = await api.get(`/api/abdm/hfr/onboarding?orgId=${organizationId}`);
      setBasicInfo(response?.data?.facilityBasicInformationRequest);
      setTrackId(response?.data?.trackingId);

      if (response?.data) {
        const ownership =
          response?.data?.facilityBasicInformationRequest?.facilityInformation?.ownershipCode;
        const ownershipSubtype =
          response?.data?.facilityBasicInformationRequest?.facilityInformation
            ?.ownershipSubTypeCode;
        if (ownership === "G") {
          setFacilityOwnershipSubtype(subTypesforGov);
        } else {
          setFacilityOwnershipSubtype(subTypes);
        }
        setOwnershipCode(ownership);

        if (ownershipSubtype === "C") {
          setSubtype("CENTRAL-GOVERNMENT");
        } else if (ownershipSubtype === "P") {
          setSubtype("Profit-type");
        } else if (ownershipSubtype === "NP") {
          setSubtype("Non-profit-type");
        } else {
          setSubtype("State");
        }

        const postdata = {
          ownershipCode: ownership,
          systemOfMedicineCode:
            response?.data?.facilityBasicInformationRequest?.facilityInformation
              ?.systemOfMedicineCode,
        };
        await getFacType(postdata);

        let data = {
          facilityTypeCode:
            response?.data?.facilityBasicInformationRequest?.facilityInformation?.facilityTypeCode,
        };
        await getFacSubType(data);
      }
    } catch (e) {
      handleClick("error", "Can not get form status");
    }
  };

  useEffect(() => {
    fetchOrg();
  }, []);

  return (
    <Reveal>
      {/* {orgdata && ( */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          daysOfWeek: daysOfWeek,
          trackingId: trackId ? trackId : "",
          facilityInformation: {
            facilityName: orgdata?.name,
            ownershipCode: basicInfo?.facilityInformation?.ownershipCode
              ? basicInfo?.facilityInformation?.ownershipCode
              : "",
            ownershipSubTypeCode: basicInfo?.facilityInformation?.ownershipSubTypeCode
              ? basicInfo?.facilityInformation?.ownershipSubTypeCode
              : "",
            ownershipSubTypeCode2: basicInfo?.facilityInformation?.ownershipSubTypeCode2
              ? basicInfo?.facilityInformation?.ownershipSubTypeCode2
              : "",
            facilityAddressDetails: {
              country: "india",
              stateLGDCode: orgdata?.adState?.code,
              districtLGDCode: orgdata?.adDistrict?.code,
              subDistrictLGDCode: orgdata?.adSubDistrict?.code,
              facilityRegion: basicInfo?.facilityInformation?.facilityAddressDetails?.facilityRegion
                ? basicInfo?.facilityInformation?.facilityAddressDetails?.facilityRegion
                : "",
              villageCityTownLGDCode: null,
              addressLine1: orgdata?.address,
              addressLine2: null,
              pincode: orgdata?.adPincode,
              latitude: orgdata?.latitude,
              longitude: orgdata?.longitude,
            },
            facilityContactInformation: {
              facilityContactNumber: basicInfo?.facilityInformation?.facilityContactInformation
                ?.facilityContactNumber
                ? basicInfo?.facilityInformation?.facilityContactInformation?.facilityContactNumber
                : "",
              facilityEmailId: basicInfo?.facilityInformation?.facilityContactInformation
                ?.facilityEmailId
                ? basicInfo?.facilityInformation?.facilityContactInformation?.facilityEmailId
                : "",
              facilityLandlineNumber: basicInfo?.facilityInformation?.facilityContactInformation
                ?.facilityLandlineNumber
                ? basicInfo?.facilityInformation?.facilityContactInformation?.facilityLandlineNumber
                : "",
              facilityStdCode: basicInfo?.facilityInformation?.facilityContactInformation
                ?.facilityStdCode
                ? basicInfo?.facilityInformation?.facilityContactInformation?.facilityStdCode
                : "",
              websiteLink: "",
            },
            systemOfMedicineCode: basicInfo?.facilityInformation?.systemOfMedicineCode
              ? basicInfo?.facilityInformation?.systemOfMedicineCode?.split(",")
              : [],
            facilityTypeCode: basicInfo?.facilityInformation?.facilityTypeCode
              ? basicInfo?.facilityInformation?.facilityTypeCode
              : "",
            facilitySubType: basicInfo?.facilityInformation?.facilitySubType
              ? basicInfo?.facilityInformation?.facilitySubType
              : "",
            specialityTypeCode: basicInfo?.facilityInformation?.specialityTypeCode
              ? basicInfo?.facilityInformation?.specialityTypeCode
              : "",
            typeOfServiceCode: basicInfo?.facilityInformation?.typeOfServiceCode
              ? basicInfo?.facilityInformation?.typeOfServiceCode
              : "",
            facilityOperationalStatus: basicInfo?.facilityInformation?.facilityOperationalStatus
              ? basicInfo?.facilityInformation?.facilityOperationalStatus
              : "",
            facilityUploads: {
              facilityBoardPhoto: null,
              facilityBuildingPhoto: null,
            },
            facilityAddressProof: [],
            timingsOfFacility: [],
            abdmCompliantSoftware: [
              {
                existingSoftwares: [],
                anyOther: "healthCloud",
              },
            ],
          },
        }}
        onSubmit={async (values) => {
          try {
            let timeData = [];
            if (values?.facilityInformation?.facilityOperationalStatus === "F") {
              timeData = values?.daysOfWeek
                ?.map((e) => {
                  let time;
                  if (e?.available) {
                    if (e?.fullday) {
                      time = "24*7";
                    } else {
                      time = `${convertTo12HourFormat(
                        e?.stime?.$H,
                        e?.stime?.$m
                      )} - ${convertTo12HourFormat(e?.etime?.$H, e?.etime.$m)}`;
                    }
                    return {
                      workingDays: e?.code,
                      openingHours: time,
                    };
                  }
                  return null;
                })
                .filter((el) => Boolean(el));
            }
            const postData = {
              ...values,
              facilityInformation: {
                ...values?.facilityInformation,
                timingsOfFacility: timeData,
                systemOfMedicineCode: values?.facilityInformation?.systemOfMedicineCode?.join(","),
              },
            };
            const res = await basicInformation(organizationId, postData);
            if (postData?.facilityInformation?.facilityOperationalStatus !== "F") {
              navigate("/home/hfrSubmit", {
                state: {
                  trackingId: res?.data?.trackingId,
                  facilityOperationalStatus:
                    postData?.facilityInformation?.facilityOperationalStatus,
                },
              });
            } else {
              navigate("/home/hfrAdditionalInfo", {
                state: {
                  systemOfMedicine: values?.facilityInformation?.systemOfMedicineCode,
                  trackingId: res?.data?.trackingId,
                },
              });
            }
            handleClick("success", "Basic Info updated successfully !!");
          } catch (error) {
            handleClick("error", error.response.data.message);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <Box>
            <form onSubmit={handleSubmit}>
              <h2>Register Your Health Facility (HFR)</h2>

              <Reveal className="otp-container" style={{ width: "auto" }}>
                <h3>Facility Manager Details (for official communication)</h3>
                <FormControl sx={{ width: "31%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-firstname"
                    type="text"
                    name="firstName"
                    value={hprUserProfile?.firstName}
                    onBlur={handleBlur}
                    label="First name"
                    startAdornment={<Person />}
                  />
                </FormControl>
                <FormControl sx={{ width: "30%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-middlename"
                    type="text"
                    name="middleName"
                    value={hprUserProfile?.middleName}
                    onBlur={handleBlur}
                    label="Middle name"
                    startAdornment={<Person />}
                  />
                </FormControl>
                <FormControl sx={{ width: "30%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-lastname"
                    type="text"
                    name="lastName"
                    value={hprUserProfile?.lastName}
                    onBlur={handleBlur}
                    label="Last name"
                    startAdornment={<Person />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-mobilenumber"
                    type="tel"
                    name="mobilenumber"
                    value={hprUserProfile?.mobile}
                    onBlur={handleBlur}
                    label="Mobile Number"
                    startAdornment={<Phone />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-email"
                    type="email"
                    name="email"
                    value={hprUserProfile?.email}
                    onBlur={handleBlur}
                    label="Email"
                    startAdornment={<Mail />}
                  />
                </FormControl>
              </Reveal>

              <Reveal className="otp-container" style={{ width: "auto" }}>
                <h3>Facility Details</h3>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-facilityname"
                    type="text"
                    name="facilityname"
                    value={values?.facilityInformation?.facilityName?.toUpperCase()}
                    onBlur={handleBlur}
                    label="Facility name"
                    startAdornment={<DomainOutlined />}
                  />
                </FormControl>
                <FormControl sx={{ width: "23%", mr: 2, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-location"
                    type="tel"
                    name="latitude"
                    value={values?.facilityInformation?.facilityAddressDetails?.latitude}
                    onBlur={handleBlur}
                    label="Latitude"
                    startAdornment={<Place />}
                  />
                </FormControl>

                <FormControl sx={{ width: "22%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-location"
                    type="tel"
                    name="longitude"
                    value={values?.facilityInformation?.facilityAddressDetails?.longitude}
                    onBlur={handleBlur}
                    label="Longitude"
                    startAdornment={<Place />}
                  />
                </FormControl>

                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-country"
                    type="text"
                    name="country"
                    value={values?.facilityInformation?.facilityAddressDetails?.country?.toUpperCase()}
                    onBlur={handleBlur}
                    label="Country"
                    startAdornment={<HomeOutlined />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-state"
                    type="text"
                    name="state"
                    value={orgdata?.adState?.name?.toUpperCase()}
                    onBlur={handleBlur}
                    label="State"
                    startAdornment={<HomeOutlined />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-district"
                    type="text"
                    name="district"
                    value={orgdata?.adDistrict?.name?.toUpperCase()}
                    onBlur={handleBlur}
                    label="District"
                    startAdornment={<HomeOutlined />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-subdistrict"
                    type="text"
                    name="subdistrict"
                    value={orgdata?.adSubDistrict?.name?.toUpperCase()}
                    onBlur={handleBlur}
                    label="Sub District"
                    startAdornment={<HomeOutlined />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-address1"
                    type="text"
                    name="address1"
                    value={values?.facilityInformation?.facilityAddressDetails?.addressLine1}
                    onBlur={handleBlur}
                    label="Address"
                    startAdornment={<HomeOutlined />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-pincode"
                    type="tel"
                    name="pincode"
                    value={values?.facilityInformation?.facilityAddressDetails?.pincode}
                    onBlur={handleBlur}
                    label="Pin Code"
                    startAdornment={<FiberPinOutlined />}
                  />
                </FormControl>

                {(facilityRegions?.find(
                  (e) =>
                    e?.code === values?.facilityInformation?.facilityAddressDetails?.facilityRegion
                ) ||
                  values?.facilityInformation?.facilityAddressDetails?.facilityRegion === "") && (
                  <FormControl sx={{ width: "47%", mt: 2, mr: 3 }}>
                    <CustomAutoComplete
                      value={
                        facilityRegions?.find(
                          (e) =>
                            e?.code ===
                            values?.facilityInformation?.facilityAddressDetails?.facilityRegion
                        ) ?? null
                      }
                      options={facilityRegions}
                      getOptionLabel={(option) => option?.value}
                      onChange={(e, newValue) => {
                        setFieldValue(
                          "facilityInformation.facilityAddressDetails.facilityRegion",
                          newValue?.code
                        );
                      }}
                      label={"Facility Region"}
                      placeholder={"Select Facility Region"}
                      iconStart={<ShareLocation />}
                    />
                  </FormControl>
                )}
                <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-landline"
                    type="tel"
                    name="facilityLandlineNumber"
                    value={
                      values?.facilityInformation?.facilityContactInformation
                        ?.facilityLandlineNumber
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "facilityInformation.facilityContactInformation.facilityLandlineNumber",
                        e.target.value
                      );
                    }}
                    label="Landline Number (For Public Display)"
                    startAdornment={<Phone />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-mobile"
                    type="tel"
                    name="mobile"
                    value={
                      values?.facilityInformation?.facilityContactInformation?.facilityContactNumber
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "facilityInformation.facilityContactInformation.facilityContactNumber",
                        e.target.value
                      );
                    }}
                    label="Mobile Number (For Public Display)"
                    startAdornment={<Phone />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-email"
                    type="email"
                    name="email"
                    value={values?.facilityInformation?.facilityContactInformation?.facilityEmailId}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "facilityInformation.facilityContactInformation.facilityEmailId",
                        e.target.value
                      );
                    }}
                    label="Facility Email (For Public Display)"
                    startAdornment={<Mail />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-website"
                    type="text"
                    name="website"
                    value={values?.facilityInformation?.facilityContactInformation?.websiteLink}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "facilityInformation.facilityContactInformation.websiteLink",
                        e.target.value
                      );
                    }}
                    label="Website (For Public Display)"
                    startAdornment={<Http />}
                  />
                </FormControl>
              </Reveal>

              <Reveal className="otp-container" style={{ width: "auto" }}>
                <h3>Uploads</h3>
                <i>Please Note:</i>
                <ul>
                  <i>
                    <li>Maximum allowed file size is 5MB.</li>
                    <li>Only pdf file types are allowed for documents.</li>
                    <li>Only jpeg/jpg/png file types are allowed for photographs.</li>
                    <li>Please upload a clear picture of the health facility building.</li>
                  </i>
                </ul>

                <FormControl sx={{ width: "35%", mr: "1%", mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-buildingphoto"
                    name="buildingPhoto"
                    value={
                      values?.facilityInformation?.facilityUploads?.facilityBuildingPhoto?.name
                    }
                    onBlur={handleBlur}
                    label="Facility Building Photograph"
                    startAdornment={<InsertPhoto />}
                  />
                </FormControl>
                <FormControl sx={{ width: "12%", mr: "2%", mt: 2 }}>
                  <CustomButton
                    className="btn--primary"
                    startIcon={<CloudUpload />}
                    label="Browse"
                    component="label"
                    style={{
                      width: "100%",
                      height: "40px",
                      marginTop: "5px",
                    }}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileUploads(e, setFieldValue, "Facility Building Photograph");
                      }}
                      inputProps={{}}
                    />
                  </CustomButton>
                </FormControl>
                <FormControl sx={{ width: "35%", mr: "1%", mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-firstname"
                    type="boardPhoto"
                    name="boardPhoto"
                    value={values?.facilityInformation?.facilityUploads?.facilityBoardPhoto?.name}
                    onBlur={handleBlur}
                    label="Facility Board Photograph"
                    startAdornment={<InsertPhoto />}
                  />
                </FormControl>
                <FormControl sx={{ width: "12%", mr: "2%", mt: 2 }}>
                  <CustomButton
                    className="btn--primary"
                    startIcon={<CloudUpload />}
                    label="Browse"
                    component="label"
                    style={{
                      width: "100%",
                      height: "40px",
                      marginTop: "5px",
                    }}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileUploads(e, setFieldValue, "Facility Board Photograph");
                      }}
                      inputProps={{}}
                    />
                  </CustomButton>
                </FormControl>
                <FormControl sx={{ width: "48%", mr: "2%", mt: 2 }}>
                  <CustomAutoComplete
                    options={addressProofs}
                    getOptionLabel={(option) => option?.value}
                    onChange={(e, newValue) => {
                      setFieldValue("facilityInformation.facilityAddressProof", [
                        { addressProofType: newValue?.code },
                      ]);
                    }}
                    label={"Address Proof Type"}
                    placeholder={"Address Proof Type"}
                    iconStart={<Article />}
                  />
                </FormControl>
                <FormControl sx={{ width: "35%", mr: "1%", mt: 2 }}>
                  <FormInputField
                    disabled
                    id="outlined-adornment-firstname"
                    type="addressProof"
                    name="addressProof"
                    value={
                      values?.facilityInformation?.facilityAddressProof?.addressProofAttachment
                        ?.name
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Address Proof"
                    startAdornment={<PictureAsPdf />}
                  />
                </FormControl>
                <FormControl sx={{ width: "12%", mr: "2%", mt: 2 }}>
                  <CustomButton
                    className="btn--primary"
                    startIcon={<CloudUpload />}
                    label="Browse"
                    component="label"
                    style={{
                      width: "100%",
                      height: "40px",
                      marginTop: "5px",
                    }}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileUploads(e, setFieldValue, "Facility Address Proof", values);
                      }}
                      inputProps={{}}
                    />
                  </CustomButton>
                </FormControl>
              </Reveal>

              <Reveal className="otp-container" style={{ width: "auto" }}>
                <h3>Days of Operation</h3>
                <div style={{ marginBottom: "20px" }}>
                  {(facilityOperationalStatus?.find(
                    (e) => e?.code === values?.facilityInformation?.facilityOperationalStatus
                  ) ||
                    values?.facilityInformation?.facilityOperationalStatus === "") && (
                    <FormControl sx={{ width: "40%", mt: 2 }}>
                      <CustomAutoComplete
                        value={
                          facilityOperationalStatus?.find(
                            (e) =>
                              e?.code === values?.facilityInformation?.facilityOperationalStatus
                          ) ?? null
                        }
                        options={facilityOperationalStatus}
                        getOptionLabel={(option) => option.value}
                        onChange={(e, newValue) => {
                          setFieldValue(
                            "facilityInformation.facilityOperationalStatus",
                            newValue?.code
                          );
                        }}
                        label={"Facility Operational Status"}
                        placeholder={"Facility Operational Status"}
                        iconStart={<Today />}
                      />
                    </FormControl>
                  )}
                </div>
                {values?.facilityInformation?.facilityOperationalStatus === "F" && (
                  <>
                    <i>Please Note:</i>
                    <ul>
                      <i>
                        <li>Tick the 24Hrs box if your facility opens all day.</li>
                        <li>
                          The timings can be either typed in 24hr format or selected from the
                          timepicker.
                        </li>
                        <li>Tick the days of operation box if "Facility is open" on that day</li>
                      </i>
                    </ul>

                    <TableContainer component={Paper}>
                      <Table
                        aria-label="simple table"
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                width: "20%",
                                padding: "8px",
                              }}
                            >
                              Days of Operation
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                width: "20%",
                                padding: "8px",
                              }}
                            >
                              Available 24 hours
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                width: "25%",
                                padding: "8px",
                              }}
                            >
                              From
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                width: "25%",
                                padding: "8px",
                              }}
                            >
                              To
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {daysOfWeek.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell
                                sx={{
                                  width: "20%",
                                  padding: "8px",
                                }}
                                style={{ paddingLeft: "50px" }}
                              >
                                <Checkbox
                                  value={row?.available}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `daysOfWeek[${index}].available`,
                                      e.target.checked
                                    );
                                  }}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                {row.label}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  width: "20%",
                                  padding: "8px",
                                }}
                              >
                                <Checkbox
                                  value={row?.fullday}
                                  disabled={!values.daysOfWeek[index].available}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                  onChange={(e) => {
                                    setFieldValue(`daysOfWeek[${index}].fullday`, e.target.checked);
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  width: "25%",
                                  padding: "8px",
                                }}
                              >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <TimePicker
                                    renderInput={(params) => <TextField {...params} />}
                                    value={values.daysOfWeek[index].stime}
                                    onChange={(e) => {
                                      setFieldValue(`daysOfWeek[${index}].stime`, e);
                                    }}
                                    disabled={
                                      !values.daysOfWeek[index].available ||
                                      values.daysOfWeek[index].fullday
                                    }
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  width: "25%",
                                  padding: "8px",
                                }}
                              >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <TimePicker
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={
                                      !values.daysOfWeek[index].available ||
                                      values.daysOfWeek[index].fullday
                                    }
                                    value={values.daysOfWeek[index].etime}
                                    onChange={(e) => {
                                      setFieldValue(`daysOfWeek[${index}].etime`, e);
                                    }}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Reveal>

              <Reveal className="otp-container" style={{ width: "auto" }}>
                <h3>Detailed Facility Information</h3>
                <FormControl component="fieldset" sx={{ width: "47%", mr: 3 }}>
                  <FormLabel component="legend">Facility Ownership</FormLabel>
                  <RadioGroup
                    row
                    aria-label="ownershipCode"
                    name="ownershipCode"
                    onChange={(e) => {
                      if (e.target.defaultValue === "G") {
                        setFacilityOwnershipSubtype(subTypesforGov);
                      } else {
                        setFacilityOwnershipSubtype(subTypes);
                      }
                      setOwnershipCode(e.target.defaultValue);
                      setFieldValue("facilityInformation.ownershipCode", e.target.defaultValue);
                      setFacilityOwnershipData([]);
                      setFacilityType([]);
                      setFacilitySubtype([]);
                    }}
                    value={values?.facilityInformation?.ownershipCode}
                  >
                    {facilityOwnership?.map((e, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={e?.code}
                          control={<Radio />}
                          label={e?.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>

                {values?.facilityInformation?.ownershipCode !== "" && facilityOwnershipSubtype && (
                  <FormControl component="fieldset" sx={{ width: "46%", mr: 3 }}>
                    <FormLabel component="legend">Facility Ownership Subtype</FormLabel>
                    <RadioGroup
                      row
                      aria-label="subtype"
                      name="subtype"
                      style={{ display: "inline" }}
                      onChange={(e) => {
                        if (e.target.defaultValue === "C") {
                          setSubtype("CENTRAL-GOVERNMENT");
                        } else if (e.target.defaultValue === "P") {
                          setSubtype("Profit-type");
                        } else if (e.target.defaultValue === "NP") {
                          setSubtype("Non-profit-type");
                        } else {
                          setSubtype("State");
                        }
                        setFieldValue(
                          "facilityInformation.ownershipSubTypeCode",
                          e.target.defaultValue
                        );
                      }}
                      value={values?.facilityInformation?.ownershipSubTypeCode}
                    >
                      {facilityOwnershipSubtype?.map((e, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={e?.code}
                            control={<Radio />}
                            label={e?.value}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                )}

                {facilityOwnershipData?.data?.length > 0 && (
                  <FormControl sx={{ width: "50%", mr: 3, mt: 2 }}>
                    <CustomAutoComplete
                      value={
                        facilityOwnershipData?.data?.find(
                          (e) => e?.code === values?.facilityInformation?.ownershipSubTypeCode2
                        ) ?? null
                      }
                      options={facilityOwnershipData?.data}
                      getOptionLabel={(option) => option?.value}
                      onChange={(event, newValue) => {
                        setFieldValue("facilityInformation.ownershipSubTypeCode2", newValue?.code);
                      }}
                      label={facilityOwnershipData?.type}
                      placeholder={facilityOwnershipData?.type}
                      iconStart={<AccountBalance />}
                    />
                  </FormControl>
                )}
              </Reveal>

              {values?.facilityInformation?.ownershipCode && (
                <Reveal className="otp-container" style={{ width: "auto" }}>
                  <FormControl component="fieldset" sx={{ width: "100%", mr: 3 }}>
                    System Of Medicine
                    <FormGroup
                      style={{ display: "inline" }}
                      name={"systemOfMedicine"}
                      onChange={async (e) => {
                        let s;
                        if (e.target.checked) {
                          setFieldValue("facilityInformation.systemOfMedicineCode", [
                            ...values.facilityInformation.systemOfMedicineCode,
                            e.target.defaultValue,
                          ]);
                          setSystemOfMedCode([...systemOfMedCode, e.target.defaultValue]);
                          s = [...systemOfMedCode, e.target.defaultValue];
                        } else {
                          setFieldValue(
                            "facilityInformation.systemOfMedicineCode",
                            values.facilityInformation.systemOfMedicineCode.filter(
                              (el) => el !== e.target.defaultValue
                            )
                          );
                          setSystemOfMedCode(
                            systemOfMedCode.filter((el) => el !== e.target.defaultValue)
                          );
                          s = systemOfMedCode.filter((el) => el !== e.target.defaultValue);
                        }
                        const postdata = {
                          ownershipCode: ownershipCode,
                          systemOfMedicineCode: s.join(","),
                        };
                        getFacType(postdata);
                      }}
                    >
                      {systemOfMedicine?.map((e, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={e?.code}
                            control={<Checkbox />}
                            label={e?.value}
                            checked={values?.facilityInformation?.systemOfMedicineCode?.includes(
                              e?.code
                            )}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>

                  {facilityType && (
                    <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                      <CustomAutoComplete
                        value={
                          facilityType?.find(
                            (e) => e?.code === values?.facilityInformation?.facilityTypeCode
                          ) ?? null
                        }
                        options={facilityType}
                        getOptionLabel={(option) => option?.value}
                        onChange={(event, newValue) => {
                          setFieldValue("facilityInformation.facilityTypeCode", newValue?.code);
                          let data = {
                            facilityTypeCode: newValue?.code,
                          };
                          getFacSubType(data);
                        }}
                        label={"Facility Type"}
                        placeholder={"Facility Type"}
                        iconStart={<HomeWork />}
                      />
                    </FormControl>
                  )}

                  {facilitySubtype && (
                    <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                      <CustomAutoComplete
                        value={
                          facilitySubtype?.find(
                            (e) => e?.code === values?.facilityInformation?.facilitySubType
                          ) ?? null
                        }
                        options={facilitySubtype}
                        getOptionLabel={(option) => option?.value}
                        onChange={(event, newValue) => {
                          setFieldValue("facilityInformation.facilitySubType", newValue?.code);
                        }}
                        label={"Facility Sub Type"}
                        placeholder={"Facility Sub Type"}
                        iconStart={<CorporateFare />}
                      />
                    </FormControl>
                  )}

                  {!isHidden && (
                    <FormControl component="fieldset" sx={{ width: "47%", mr: 3, mt: 2 }}>
                      Type of Service
                      <FormGroup
                        row
                        name="systemOfMedicine"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue(
                              "facilityInformation.typeOfServiceCode",
                              (values.facilityInformation.typeOfServiceCode
                                ? [
                                    values.facilityInformation.typeOfServiceCode,
                                    e.target.defaultValue,
                                  ]
                                : [e.target.defaultValue]
                              ).join(",")
                            );
                          } else {
                            setFieldValue(
                              "facilityInformation.typeOfServiceCode",
                              values.facilityInformation.typeOfServiceCode
                                .split(",")
                                .filter((el) => el !== e.target.defaultValue)
                                .join(",")
                            );
                          }
                        }}
                      >
                        {typeOfService?.map((e, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={e?.code}
                              control={<Checkbox />}
                              label={e?.value}
                              checked={values?.facilityInformation?.typeOfServiceCode
                                ?.split(",")
                                ?.includes(e?.code)}
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  )}

                  {!isHidden && (
                    <FormControl component="fieldset" sx={{ width: "46%", mt: 2 }}>
                      <FormLabel component="legend">Speciality Type</FormLabel>
                      <RadioGroup
                        row
                        aria-label="specialityType"
                        name="specialityType"
                        onChange={(e) => {
                          setFieldValue(
                            "facilityInformation.specialityTypeCode",
                            e.target.defaultValue
                          );
                        }}
                        value={values?.facilityInformation?.specialityTypeCode}
                      >
                        {typeOfHospital?.map((e, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={e?.code}
                              control={<Radio />}
                              label={e?.value}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Reveal>
              )}

              <Reveal style={{ width: "auto", marginTop: "20px", display: "flex" }}>
                <CustomButton
                  type="submit"
                  label="Next"
                  style={{ marginLeft: "auto" }}
                  className="btn--primary"
                  endIcon={<NavigateNext />}
                />
              </Reveal>
            </form>
          </Box>
        )}
      </Formik>
      {/* )} */}
    </Reveal>
  );
};

export default HfrBasicInfo;
