import { startsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const treatValidationSchema = Yup.object().shape({
  treat: Yup.string()
    .min(3, "Text too short")
    .max(50, "Text too long")
    .matches(/^[a-zA-Z0-9 .,'-]*$/, "Only letters, numbers, spaces, and .,'- are allowed")
    .test("contains-alphabetic", "Must start with alphabet", startsWithAlphabetic),
});
