import React from "react";
import { LIVER } from "utils/ehr-data-utils";

const HumanLiver = ({ selectedOrgan, onClick, organKey, getOrganVitalCharts }) => {
  const isSelected = selectedOrgan.includes(LIVER);
  return (
    <div
      id="human-body-liver"
      className={`human-body-liver-spleen ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <img
        src="/images/liver.svg"
        alt="Outer Body"
        className={`liver hover body-part ${isSelected ? "highlight" : ""}`}
      />
      <img
        src="/images/spleen.svg"
        alt="Outer Body"
        className={`spleen hover body-part ${isSelected ? "highlight" : ""}`}
      />
    </div>
  );
};

export default HumanLiver;
