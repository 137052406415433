import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTypography from "ui-component/custom-components/CustomTypography";
import "assets/scss/OtpScreen.scss";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CustomButton from "ui-component/custom-components/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  authInit,
  confirmAadhaarOtp,
  confirmMobileOtp,
  confirmPassword,
  generateMobileLoginOtp,
  getLoggedInProfile,
  mobileVerifyOtp,
  userAuthorizedToken,
  verifyHealthId,
} from "services/HfrService";
import {
  AADHAAR_OTP,
  MOBILE_OTP,
  PASSWORD,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  orgId,
} from "store/constant";
import { useEffect } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { FingerprintOutlined, Grading, PermIdentity } from "@mui/icons-material";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import { useContext } from "react";
import { getOrgById } from "services/organizationService";
import Reveal from "views/utilities/Reveal";

const HprLogin = () => {
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);
  const [alignment, setAlignment] = useState("mobile");
  const [mobileNumber, setMobileNumber] = useState("");
  const [hpid, setHpid] = useState("");
  const [mobileNumberOtp, setMobileNumberOtp] = useState("");
  const [password, setPassword] = useState("");
  const [aadharOtp, setAadharOtp] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [txnId, setTxnId] = useState();
  const [mobileLinkedHpIdDTO, setMobileLinkedHpIdDTO] = useState([]);
  const [mobileOtpVerified, setMobileOtpVerified] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isHfrId, setisHfrId] = useState(false);
  const organizationId = orgId();

  const handleAlignmentChange = (event, newAlignment) => {
    setHpid("");
    setMobileNumber("");
    setMobileNumberOtp("");
    setAuthMethod("");
    setAlignment(newAlignment);
    setMobileOtpVerified(false);
  };

  const sendMobileLoginOtp = async (data) => {
    try {
      const apiData = {
        mobile: data,
      };
      const response = await generateMobileLoginOtp(apiData);
      setTxnId(response?.data?.txnId);
      setOtp(true);
    } catch (error) {
      handleClick("error", error.response.data.message);
    }
  };

  const verifyHpid = async (data) => {
    try {
      const apiData = {
        idType: "",
        domainName: "",
        hprId: data,
      };
      const response = await verifyHealthId(apiData);
      if (response?.data?.status === true) {
        setIsHpid(true);
      } else {
        handleClick("error", "HpId does not exist");
      }
    } catch (error) {
      handleClick("error", error.response.data.message);
    }
  };

  const verifyMobileOtp = async (data) => {
    try {
      const apiData = {
        otp: data,
        txnId: txnId,
      };
      const response = await mobileVerifyOtp(apiData);
      setMobileLinkedHpIdDTO(response?.data?.mobileLinkedHpIdDTO);
      setMobileOtpVerified(true);
    } catch (error) {
      handleClick("error", error.response.data.message);
    }
  };

  const initAuth = async (data) => {
    try {
      const apiData = {
        authMethod: data,
        domainName: "",
        hprId: hpid,
        idType: "",
      };
      const response = await authInit(apiData);
      setAuthMethod(data);
      setTxnId(response?.data?.txnId);
    } catch (error) {
      setAuthMethod("");
      handleClick("error", error.response.data.message);
    }
  };

  const hpidAuthMethods = [PASSWORD, AADHAAR_OTP, MOBILE_OTP];

  const [authMethod, setAuthMethod] = useState("");
  const [otp, setOtp] = useState(false);
  const [isHpid, setIsHpid] = useState(false);
  const handleRowChange = (row) => {
    setSelectedValue(row);
  };

  const verifyHprId = async () => {
    try {
      if (isHfrId) {
        handleClick("success", "Organization already registered on HFR");
        return;
      }
    } catch (error) {
      handleClick("error", "Can not get organization details");
    }

    try {
      const apiData = {
        hpId: selectedValue.hprIdNumber,
        txnId: txnId,
      };
      const response = await userAuthorizedToken(apiData);
      const hprProfile = await getLoggedInProfile();
      localStorage.removeItem("hprProfile");
      localStorage.setItem("hprProfile", JSON.stringify(hprProfile.data));
      localStorage.removeItem("hfrToken");
      localStorage.setItem("hfrToken", JSON.stringify(response.data));
      navigate("/home/hfrBasicInfo");
    } catch (error) {
      handleClick("error", "Can not Verify your HPR-ID");
    }
  };

  const loginWithHpId = async () => {
    try {
      if (isHfrId) {
        handleClick("success", "Organization already registered on HFR");
        return;
      }
    } catch (error) {
      handleClick("error", "Can not get organization details");
    }
    try {
      let apiData;
      let response;
      if (authMethod === AADHAAR_OTP) {
        apiData = {
          otp: aadharOtp,
          txnId: txnId,
        };

        response = await confirmAadhaarOtp(apiData);
      } else if (authMethod === MOBILE_OTP) {
        apiData = {
          otp: mobileOtp,
          txnId: txnId,
        };

        response = await confirmMobileOtp(apiData);
      } else {
        apiData = {
          password: password,
          txnId: txnId,
        };

        response = await confirmPassword(apiData);
      }
      localStorage.removeItem("hprProfile");
      const hprProfile = await getLoggedInProfile();
      localStorage.setItem("hprProfile", JSON.stringify(hprProfile.data));
      localStorage.removeItem("hfrToken");
      localStorage.setItem("hfrToken", JSON.stringify(response.data));
      navigate("/home/hfrBasicInfo");
    } catch (error) {
      handleClick("error", error.response.data.message);
    }
  };

  const navigateToLinkHfr = () => {
    navigate("/home/linkHfr");
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOrgById(organizationId);
      if (response?.data?.hfrFacilityId) {
        setisHfrId(true);
      }
    };

    fetchData();
  }, [organizationId]);

  return (
    <HprLoginSkeleton>
      <Reveal>
        <div style={{ display: "flex" }}>
          <h2>Register your Health Facility (HFR) </h2>

          {!isHfrId && (
            <CustomButton
              className="btn--primary"
              style={{ marginLeft: "auto", marginTop: "8px" }}
              label="Link Hfr with Organization"
              onClick={() => navigateToLinkHfr()}
            />
          )}
        </div>
        <Grid container justifyContent="center" alignItems="center">
          <div className="otp-container" style={{ width: "600px", marginTop: "0px" }}>
            <CustomTypography variant="h2" style={{ textAlign: "center" }}>
              Login To Your HPR Account
            </CustomTypography>
            <CustomTypography style={{ margin: "20px 0px" }} variant="h4">
              Login via
            </CustomTypography>

            <div className="aadhar-input-container">
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={(e) => {
                  handleAlignmentChange(e, e.target.value);
                  setOtp(false);
                  setIsHpid(false);
                }}
                aria-label="Platform"
              >
                <ToggleButton value="mobile">Mobile Number</ToggleButton>
                <ToggleButton value="hpid">HPID/Username</ToggleButton>
              </ToggleButtonGroup>
            </div>
            {!mobileOtpVerified && (
              <div>
                <div className="aadhar-input-container">
                  {alignment === "mobile" && (
                    <Reveal style={{ minWidth: "100%" }}>
                      <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                        <FormInputField
                          id="mobileInput"
                          type="tel"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          label="Mobile Number"
                          startAdornment={<i className="ri-phone-line ri-xl" />}
                        />
                      </FormControl>
                    </Reveal>
                  )}
                  {alignment === "hpid" && (
                    <Reveal>
                      <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                        <FormInputField
                          id="hpid"
                          type="text"
                          value={hpid}
                          onChange={(e) => setHpid(e.target.value)}
                          label="Healthcare Professional ID"
                          startAdornment={<PermIdentity />}
                          endAdorment={<InputAdornment position="end">@hpr.abdm</InputAdornment>}
                        />
                      </FormControl>
                    </Reveal>
                  )}
                </div>
                <div className="aadhar-input-container">
                  {alignment === "mobile" && otp && (
                    <Reveal>
                      <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                        <FormInputField
                          id="mobile otp"
                          type="tel"
                          value={mobileNumberOtp}
                          onChange={(e) => setMobileNumberOtp(e.target.value)}
                          label="Mobile Otp"
                          startAdornment={<FingerprintOutlined />}
                        />
                      </FormControl>
                    </Reveal>
                  )}

                  {alignment === "hpid" && isHpid && (
                    <Reveal>
                      <CustomAutoComplete
                        options={hpidAuthMethods}
                        onChange={(event, newValue) => {
                          initAuth(newValue);
                        }}
                        label={"Select Authentication Method"}
                        placeholder={"Select Authentication Method"}
                        iconStart={<Grading />}
                      />
                    </Reveal>
                  )}
                </div>
                {alignment === "hpid" && (
                  <div className="aadhar-input-container">
                    {authMethod === PASSWORD && (
                      <Reveal>
                        <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                          <FormInputField
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            label="Enter Password"
                            startAdornment={<FingerprintOutlined />}
                          />
                        </FormControl>
                      </Reveal>
                    )}
                    {authMethod === AADHAAR_OTP && (
                      <Reveal>
                        <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                          <FormInputField
                            id="aadharOtp"
                            type="tel"
                            value={aadharOtp}
                            onChange={(e) => setAadharOtp(e.target.value)}
                            label="Enter Aadhaar Otp"
                            startAdornment={<FingerprintOutlined />}
                          />
                        </FormControl>
                      </Reveal>
                    )}
                    {authMethod === MOBILE_OTP && (
                      <Reveal>
                        <FormControl sx={{ my: 1, width: "100%" }} variant="outlined">
                          <FormInputField
                            id="mobileOtp"
                            type="tel"
                            value={mobileOtp}
                            onChange={(e) => setMobileOtp(e.target.value)}
                            label="Enter Mobile Otp"
                            startAdornment={<FingerprintOutlined />}
                          />
                        </FormControl>
                      </Reveal>
                    )}
                  </div>
                )}
                <Reveal
                  className="aadhar-input-container"
                  style={{ alignContent: "center", alignItems: "center" }}
                >
                  {alignment === "mobile" && !otp && (
                    <CustomButton
                      variant="contained"
                      label="Send Otp"
                      className="btn--primary"
                      onClick={() => {
                        sendMobileLoginOtp(mobileNumber);
                      }}
                      disabled={!(mobileNumber.length === 10)}
                    />
                  )}
                  {alignment === "mobile" && otp && (
                    <CustomButton
                      variant="contained"
                      color="primary"
                      label="Verify"
                      onClick={() => {
                        verifyMobileOtp(mobileNumberOtp);
                      }}
                      className="btn--primary"
                      disabled={!(mobileNumber.length === 10)}
                    />
                  )}
                  {alignment === "hpid" && !isHpid && (
                    <CustomButton
                      variant="contained"
                      color="primary"
                      label="Verify HPID"
                      onClick={() => {
                        verifyHpid(hpid);
                      }}
                      className="btn--primary"
                      disabled={!hpid}
                    />
                  )}
                  {isHpid && (
                    <CustomButton
                      variant="contained"
                      color="primary"
                      label="Login"
                      onClick={() => {
                        loginWithHpId();
                      }}
                      className="btn--primary"
                      disabled={!authMethod && isHpid}
                    />
                  )}
                </Reveal>
              </div>
            )}

            {mobileOtpVerified && (
              <Reveal
                style={{
                  margin: "30px 0px",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{ border: "1px solid grey", margin: "10px 0px" }}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "10%", textAlign: "center" }}>Select</TableCell>
                        <TableCell sx={{ width: "50%", textAlign: "center" }}>Full Name</TableCell>
                        <TableCell sx={{ width: "40%", textAlign: "center" }}>HPR-ID No.</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mobileLinkedHpIdDTO?.map((row, index) => {
                        return (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell sx={{ width: "16%", textAlign: "center" }}>
                              <Radio
                                name="radio-buttons"
                                value={row}
                                variant="outlined"
                                checked={selectedValue === row}
                                onChange={() => {
                                  handleRowChange(row);
                                }}
                              ></Radio>
                            </TableCell>
                            <TableCell sx={{ width: "16%", textAlign: "center" }}>
                              {row.name}
                            </TableCell>
                            <TableCell sx={{ width: "16%", textAlign: "center" }}>
                              {row.hprIdNumber}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <CustomButton
                  variant="contained"
                  color="primary"
                  label="Verify"
                  onClick={verifyHprId}
                  className="btn--primary"
                  disabled={!selectedValue}
                  style={{ left: "42%" }}
                />
              </Reveal>
            )}
            <div>
              <CustomTypography style={{ textAlign: "center" }}>
                Do not have a Healthcare Professional ID?
                <Link
                  to="/home/OtpScreen"
                  style={{ color: "#007bff", textDecoration: "none", marginLeft: "5px" }}
                >
                  Click here to register
                </Link>
              </CustomTypography>
            </div>
          </div>
        </Grid>

        <div style={{ marginTop: "20px" }}>
          <h3>What is HFR?</h3>
          <Typography>
            Health Facility Registry is a comprehensive repository of health facilities of the
            country across modern and traditional systems of medicine. It includes both public and
            private health facilities including hospitals, clinics, diagnostic laboratories and
            Health Facility Registry is a comprehensive repository of health facilities of the
            country across modern and traditional systems of medicine. It includes both public and
            private health facilities including hospitals, clinics, diagnostic laboratories and
            imaging centers, pharmacies, etc.
          </Typography>
          <br></br>
          <Typography>
            Registration will enable health facilities to get connected to India's digital health
            ecosystem and allow their listing on a national platform. This will instill trust in
            citizens seeking healthcare services by improving discovery of health facilities. Health
            facilities signing up will be able to gain access to a host of digital services.
          </Typography>
          <br></br>
          <Typography>
            To know more
            <a
              href="https://nhpr.abdm.gov.in/home"
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: "#007bff", marginLeft: "5px", textDecoration: "none" }}
            >
              Click Here
            </a>
          </Typography>
        </div>
      </Reveal>
    </HprLoginSkeleton>
  );
};

const HprLoginSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={5}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4} display={"flex"} justifyContent={"center"}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="70%"
            height={350}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HprLogin;
