import React, { useContext, useEffect, useRef, useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import "assets/scss/PatientDetails.scss";
import { getColorAndBackgroundColorBySeverity } from "utils/calculate-vital-color";
import { getPatientLifestyleByPatientId } from "services/patientService";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const PatientEHRHealthDetailsCard = ({ patientEhrData }) => {
  const [searchItem, setSearchItem] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [lifestyleHabits, setLifestyleHabits] = useState([]);
  const { handleClick } = useContext(ToastContext);

  // if want to show pouinter on hover over scroll-bar

  //   const scrollContainerRef = useRef(null);

  //   const handleMouseEnter = () => {
  //     if (scrollContainerRef.current) {
  //       scrollContainerRef.current.style.cursor = "pointer";
  //     }
  //   };

  //   const handleMouseLeave = () => {
  //     if (scrollContainerRef.current) {
  //       scrollContainerRef.current.style.cursor = "auto";
  //     }
  //   };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";

    for (let i = 0; i < 6; i++) {
      const randomValue = Math.floor(Math.random() * 12) + 4;
      color += letters[randomValue];
    }

    return color;
  };
  const patientPastProcedures = patientEhrData?.patientMedicalHistory?.pastProcedures;
  const patientChronicDiseases = patientEhrData?.patientMedicalHistory?.patientChronicDiseases;
  const patientVitals = patientEhrData?.clinicMeasuredVitals;

  const getTimeAgo = (datePerformed) => {
    const diff = new Date() - new Date(datePerformed);
    const monthsAgo = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));

    if (monthsAgo === 0) {
      return "less than a month";
    } else {
      return `${monthsAgo} months ago`;
    }
  };
  const severityToValue = (severity) => {
    switch (severity) {
      case "low":
        return 0;
      case "moderate":
        return 0.5;
      case "High":
        return 1;
      default:
        return 0.5;
    }
  };

  const tabs = [
    { id: 1, label: "Summary" },
    { id: 2, label: "Encounters" },
    { id: 3, label: "Immunizations" },
    { id: 4, label: "Previous Visits" },
  ];

  const anxietyDisorder = [
    { name: "Paroxetine 25mg Tablet" },
    { name: "AppenParoxetine 50mg Tabletdectomy" },
  ];
  const disorders = [
    {
      type: "Speech",
      names: ["Apraxia"],
    },
    {
      type: "Cognitive",
      names: ["Alzheimer"],
    },
    {
      type: "Mental",
      names: ["Anxiety"],
    },
    {
      type: "Physical",
      names: ["Arthritis"],
    },
    {
      type: "Other",
      names: ["Hypertension"],
    },
  ];

  //   const tabs = [
  //     { id: 1, label: "Summary", disabled: false },
  //     { id: 2, label: "Encounters", disabled: true },
  //     { id: 3, label: "Immunizations", disabled: true },
  //     { id: 4, label: "Previous Visits", disabled: true },
  //   ];

  useEffect(() => {
    const fetchData = async () => {
      if (!patientEhrData?.patientId) return;

      try {
        const lifestyleResponse = await getPatientLifestyleByPatientId(patientEhrData?.patientId);

        const habitStyles = {
          Smoking: { color: "red", borderColor: "darkred", icon: "🚬" },
          "Consuming Tobacco": { color: "brown", borderColor: "purple", icon: "🚭" },
          Drinking: { color: "blue", borderColor: "darkblue", icon: "🍻" },
          "Non-vegetarian": { color: "green", borderColor: "darkgreen", icon: "🍗" },
          Vegetarian: { color: "green", borderColor: "darkgreen", icon: "🥦" },
          Eggetarian: { color: "yellow", borderColor: "goldenrod", icon: "🍳" },
        };

        const matchedHabits = lifestyleResponse.data.flatMap((responseObj) => {
          const responseArray = responseObj.response.split(",");
          return responseArray
            .map((response) => response.trim())
            .filter((response) => habitStyles.hasOwnProperty(response))
            .map((habit) => ({
              name: habit,
              color: habitStyles[habit]?.color || "black",
              borderColor: habitStyles[habit]?.borderColor || "gray",
              icon: habitStyles[habit]?.icon || "❓",
            }));
        });

        if (Array.isArray(matchedHabits)) {
          setLifestyleHabits(matchedHabits);
        } else {
          console.error("Matched habits are not an array:", matchedHabits);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [patientEhrData?.patientId]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 10px" }}>
        <div>
          <FormInputField
            style={{ width: "100%" }}
            label={"Search..."}
            value={searchItem}
            startAdornment={<i className="ri-search-line ri-lg" />}
            onChange={(e) => {
              setSearchItem(e.target.value);
            }}
            size="small"
          ></FormInputField>
        </div>
        <div>
          <CustomButton className="btn--secondary-light ri-filter-3-line ri-lg"></CustomButton>

          <CustomButton
            label="Sort By"
            style={{ marginLeft: "20px" }}
            className="btn--secondary-light"
            endIcon={<i className="ri-arrow-drop-down-line ri-lg" />}
          ></CustomButton>
        </div>
      </div>
      <div className="tab-list">
        <div className="tab-list">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab-names hover ${activeTab === tab.id ? "active-tab" : ""} ${
                tab.disabled ? "disabled-tab" : ""
              }`}
              onClick={() => !tab.disabled && setActiveTab(tab.id)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>

      <div className="tab-content">
        {activeTab === 1 && (
          <div className="summary">
            <div className="vitals">
              <div className="patient-vitals">
                <div className="header-container">
                  <p className="header-title">Vitals</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>
                <div
                  className="patient-vitals-details "
                  //   ref={scrollContainerRef}
                  //   onMouseEnter={handleMouseEnter}
                  //   onMouseLeave={handleMouseLeave}
                >
                  {/* {patientVitals?.map((vital, index) => (
                    <div key={index} className="vital-item">
                      <p style={{ margin: "8px 3px" }}>
                        {vital?.vitalSignTypeResponse?.displayName}
                      </p>
                      <p style={{ margin: "8px 3px" }}>
                        {vital?.value} {vital?.vitalSignTypeResponse?.unitAbbreviation}
                      </p>
                    </div>
                  ))} */}

                  {patientVitals && patientVitals?.length > 0 ? (
                    patientVitals.map((vital, index) => (
                      <div key={index} className="vital-item">
                        <p style={{ margin: "8px 3px" }}>
                          {vital?.vitalSignTypeResponse?.displayName}
                        </p>
                        <p style={{ margin: "8px 3px" }}>
                          {vital?.value} {vital?.vitalSignTypeResponse?.unitAbbreviation}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p style={{ margin: "5px" }}>Data not available</p>
                  )}

                  {/* {vitals.map((vital, index) => (
                    <div key={index} className="vital-item">
                      <p style={{ margin: "8px 3px" }}>{vital.name}</p>
                      <p style={{ margin: "8px 3px" }}>{vital.value}</p>
                    </div>
                  ))} */}
                </div>
              </div>
              {/* <div className="anxiety-disorder">
                <div className="header-container">
                  <p className="header-title">Anxiety Disorder</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>
                {anxietyDisorder.map((disorder, index) => (
                  <div key={index} className="anxiety-disorder-details">
                    <i className="ri-syringe-line ri-xl icon-primary-blue" />
                    <p style={{ margin: "8px 3px" }}>{disorder?.name}</p>
                  </div>
                ))}
              </div> */}
            </div>
            <div className="reports">
              <div className="disorders">
                <div className="header-container">
                  <p className="header-title">Disorders</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>

                <div className="disorder-list-container">
                  {disorders.map((item, index) => (
                    <div key={index} className="disorder-list">
                      <p style={{ margin: "8px 13px" }}>{item.type} :</p>
                      <div className="disorder-names">
                        {item.names.map((name, idx) => (
                          <div key={idx} className="badge">
                            {name}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="detailed-reports">
                <div className="header-container">
                  <p className="header-title">Detailed Reports</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>
                <div className="list">
                  <div className="list-item">
                    <div className="name">
                      <p className="name-1">Mehakjot History</p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "8px",
                          marginBottom: "3px",
                          gap: "5px",
                        }}
                      >
                        <i className="ri-file-fill ri-lg icon-primary-blue" />{" "}
                        <p style={{ margin: "8px 3px" }}>Tension </p>
                      </div>
                    </div>
                    <div className="icons">
                      <i className="ri-eye-line ri-lg" />
                      <i className="ri-more-2-line ri-lg" />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="conditions">
              <div className="health-conditions">
                <div className="header-container">
                  <p className="header-title">Health Conditions</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>
                <ul className="condition-list">
                  {patientChronicDiseases && patientChronicDiseases?.length > 0 ? (
                    patientChronicDiseases?.map((condition, index) => {
                      const colorCode = getColorAndBackgroundColorBySeverity(condition?.severity);

                      return (
                        <li
                          key={index}
                          style={{
                            borderLeft: `2px solid ${getRandomColor()}`,
                            marginBottom: "10px",
                          }}
                          className="condition-item"
                        >
                          <p className="condition-name">
                            {condition?.chronicDiseaseResponse?.name}
                          </p>
                          <span
                            className="condition-badge"
                            style={{
                              color: colorCode?.color,
                              backgroundColor: colorCode?.backgroundColor,
                            }}
                          >
                            {condition?.severity}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <p style={{ margin: "5px" }}>Data Not Available</p>
                  )}
                </ul>
              </div>
              <div className="lifestyles">
                <div className="header-container">
                  <p className="header-title">Lifestyle & Hx</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>
                <ul className="lifestyle-list">
                  {lifestyleHabits.map((lifestyle, index) => (
                    <li
                      key={index}
                      style={{ borderLeft: `2px solid ${lifestyle.borderColor}` }}
                      className="lifestyle-item"
                    >
                      <p className="lifestyle-name">{lifestyle.name}</p>
                      <span>{lifestyle.icon}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="surgery">
                <div className="header-container">
                  <p className="header-title">Last Surgery</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>
                <ul className="surgery-list">
                  {patientPastProcedures && patientPastProcedures?.length > 0 ? (
                    patientPastProcedures?.map((procedure) => (
                      <li
                        key={procedure.id}
                        className="surgery-item"
                        style={{
                          borderLeft: `2px solid ${getRandomColor()}`,
                          marginBottom: "10px",
                        }}
                      >
                        <p className="surgery-name">
                          {procedure.procedureResponse.name} ({getTimeAgo(procedure.datePerformed)})
                        </p>
                      </li>
                    ))
                  ) : (
                    <p style={{ margin: "5px" }}>Data Not Available</p>
                  )}
                </ul>
              </div>
              {/* <div className="procedure">
                <div className="header-container">
                  <p className="header-title">Last Procedure</p>
                  <div className="header-icons">
                    <i className="ri-fullscreen-fill ri-xl hover" />
                    <i className="ri-more-2-line ri-xl hover" />
                  </div>
                </div>
                <li className="procedure-item" style={{ borderLeft: `2px solid purple` }}>
                  <p className="procedure-name">Dressing (3 months ago)</p>
                </li>
              </div> */}
            </div>
          </div>
        )}

        {activeTab === 2 && (
          <div>
            <h2>Encounters</h2>
            <p>Encounters Content Part 1</p>
            <p>Encounters Content Part 2</p>
          </div>
        )}

        {activeTab === 3 && (
          <div>
            <h2>Immunizations</h2>
            <p>Immunizations Content Part 1</p>
            <p>Immunizations Content Part 2</p>
          </div>
        )}

        {activeTab === 4 && (
          <div>
            <h2>Previous Visits</h2>
            <p>Previous Visits Content Part 1</p>
            <p>Previous Visits Content Part 2</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientEHRHealthDetailsCard;
