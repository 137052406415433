import * as Yup from "yup";

const createHprSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(3, "Minimum 3 characters required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .required("Middle name is required")
    .min(1, "Minimum 1 character required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(3, "Minimum 3 characters required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  dayOfBirth: Yup.string().required("Day of birth is required"),
  monthOfBirth: Yup.string().required("Month of birth is required"),
  yearOfBirth: Yup.string().required("Year of birth is required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .required("Email is required")
    .max(255, "Maximum 255 characters allowed"),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  stateCode: Yup.string().required("State is required"),
  districtCode: Yup.string().required("District is required"),
  gender: Yup.string().required("Gender is required"),
  hpCategoryCode: Yup.string().required("Healthcare Professional Category is required"),
  hpSubCategoryCode: Yup.string().required("Healthcare Professional Subcategory is required"),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Minimum 3 characters allowed")
    .max(256, "Maximum 256 characters allowed"),
  hprId: Yup.string().required("Healthcare Professional Id is required"),
});

export default createHprSchema;
