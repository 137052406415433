import { Grid } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Reveal from "views/utilities/Reveal";

const OrgLocation = ({ orgDetail, setOrgDetail, setFieldValue, isLoaded }) => {
  const handleMapClick = async (event) => {
    const clickedPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setOrgDetail({
      ...orgDetail,
      center: clickedPosition,
    });
  };

  return (
    <Reveal>
      {isLoaded && (
        <Grid sx={{ mb: 4 }}>
          <Grid>
            <GoogleMap
              zoom={10}
              center={orgDetail.center}
              mapContainerClassName="map-container"
              onClick={(e) => {
                handleMapClick(e);
                // setFieldValue('dirty', true);
              }}
            >
              <Marker position={orgDetail.center} />
            </GoogleMap>
          </Grid>

          {/* </APIProvider> */}
        </Grid>
      )}
    </Reveal>
  );
};

export default OrgLocation;
