import { AddOutlined, CalendarToday, FormatListBulleted } from "@mui/icons-material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useNavigate, useOutletContext } from "react-router";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomFullCalendar from "ui-component/custom-components/CustomFullCalendar";
import { PatientAppointmentCard } from "../DoctorAppointmentsNew";

const PatientListForDashboard = () => {
  const navigate = useNavigate();

  const { patientListSharedValue } = useOutletContext();
  const {
    handleNewModalOpenForBookingAppointment,
    isCalendarView,
    setIsCalendarView,
    calendarView,
    setCalendarView,
    viewInfo,
    setViewInfo,
    appointmentResponse,
    setFilteredDoctorEvents,
    allEvents,
    filteredDoctorEvents,
    eventClick,
    doctorBlockCalendar,
    doctorBlockCalendarEvents,
    doctorData,
    handleFilteredAppointmentsChange,
    handleMonthViewMore,
    setShowAppointmentList,
    handlePrescriptionClick,
    todaysAppointments,
    patients,
    selectedAppointment,
    setSelectedAppointment,
    parentAppointmentsRefresh,
    handleNewModalOpenForReschedulingAppointment,
    setAppointmentModalTitle,
    setIsNewModalOpen,
  } = patientListSharedValue;

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: "10px",
          alignItems: "center",
        }}
      >
        <Grid>
          <div
            style={{
              paddingLeft: "10px",
              fontSize: "16px",
              fontWeight: "600",
              margin: "0",
              color: "#004c70",
              lineHeight: "normal",
            }}
          >
            My Patients
          </div>
        </Grid>
        <Grid>
          <CustomButton
            startIcon={<AddOutlined />}
            label={"Appointment"}
            className={"btn--primary"}
            gap={"0px"}
            onClick={handleNewModalOpenForBookingAppointment}
            style={{ marginRight: "10px" }}
          />
          <CustomButton
            startIcon={isCalendarView ? <FormatListBulleted /> : <CalendarToday />}
            label={isCalendarView ? "List view" : "Calendar view"}
            className={isCalendarView ? "btn--secondary" : "btn--secondary-light"}
            gap={"0px"}
            onClick={() => {
              if (isCalendarView) {
                setViewInfo(null);
              }
              setIsCalendarView(!isCalendarView);
            }}
          />
        </Grid>
      </Grid>
      {isCalendarView ? (
        <div style={{ height: "90%" }}>
          <CustomFullCalendar
            appointmentResponse={appointmentResponse}
            setCalendarView={setCalendarView}
            viewInfo={viewInfo}
            setViewInfo={setViewInfo}
            calendarView={calendarView}
            setFilteredDoctorEvents={setFilteredDoctorEvents}
            allEvents={allEvents}
            filteredDoctorEvents={filteredDoctorEvents}
            eventClick={eventClick}
            blockedDays={doctorBlockCalendar}
            blockedEvents={doctorBlockCalendarEvents}
            slotDuration={doctorData?.avgAppointmentDuration <= 15 ? 30 : 60}
            onFilteredAppointmentsChange={handleFilteredAppointmentsChange}
            handleMonthViewMore={handleMonthViewMore}
            setShowAppointmentList={setShowAppointmentList}
            handlePrescriptionClick={handlePrescriptionClick}
          ></CustomFullCalendar>
        </div>
      ) : (
        <div
          style={{
            height: "90%",
            overflowY:
              (todaysAppointments || patients) &&
              todaysAppointments?.length + patients?.length !== 0
                ? "auto"
                : undefined,
          }}
          className={
            todaysAppointments && todaysAppointments.length !== 0 ? "common-scrollbar" : undefined
          }
        >
          {todaysAppointments && (
            <div className="common-scrollbar" style={{ width: "100%", padding: "5px 5px 0px 5px" }}>
              {todaysAppointments.map((item, index) => {
                return (
                  <PatientAppointmentCard
                    itemLength={todaysAppointments.length}
                    item={item}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                    index={index}
                    parentAppointmentsRefresh={parentAppointmentsRefresh}
                    doctorData={doctorData}
                    handleNewModalOpenForReschedulingAppointment={
                      handleNewModalOpenForReschedulingAppointment
                    }
                  />
                );
              })}
            </div>
          )}
          {patients && (
            <div className="common-scrollbar" style={{ width: "100%", padding: "0px 5px 5px 5px" }}>
              {patients.map((item, index) => {
                return (
                  <PatientAppointmentCard
                    itemsLength={patients.length}
                    item={item}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                    index={index}
                    isPatientCard={true}
                    setAppointmentModalTitle={setAppointmentModalTitle}
                    setIsNewModalOpen={setIsNewModalOpen}
                    parentAppointmentsRefresh={parentAppointmentsRefresh}
                    doctorData={doctorData}
                  />
                );
              })}
            </div>
          )}
          {todaysAppointments && patients && todaysAppointments.length + patients.length === 0 && (
            <div>No Patients...</div>
          )}
          <Grid sx={{ display: "flex", justifyContent: "end", mt: "5px", pr: "5px" }}>
            <CustomButton
              startIcon={<i className="ri-list-check-line" />}
              label={"View all"}
              className={"btn--primary-light"}
              onClick={() => {
                navigate("/home/patient");
              }}
              gap="0px"
              style={{ padding: "6px 12px 6px 7.8px" }}
            />
          </Grid>
        </div>
      )}
    </>
  );
};

export default PatientListForDashboard;
