const SkeletonBackBone = () => {
  return (
    <div className="custom-skeleton-back-bone">
      <div className="group-1">
        <div className="group-1-1">
          <div className="back-bone-cervical-vertebrae-1 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-1.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-1"
            ></img>
          </div>
          <div className="back-bone-cervical-vertebrae-2 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-2.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-2"
            ></img>
          </div>
          <div className="back-bone-cervical-vertebrae-3 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-3.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-3"
            ></img>
          </div>
          <div className="back-bone-cervical-vertebrae-4 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-4.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-4"
            ></img>
          </div>
          <div className="back-bone-cervical-vertebrae-5 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-5.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-5"
            ></img>
          </div>
          <div className="back-bone-cervical-vertebrae-6 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-6.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-6"
            ></img>
          </div>
          <div className="back-bone-cervical-vertebrae-7 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-7.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-7"
            ></img>
          </div>
          <div className="back-bone-cervical-vertebrae-none body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-none.svg"
              className="body-part hover"
              alt="back-bone-cervical-vertebrae-none"
            ></img>
          </div>
        </div>

        {/* todo:image  */}
        <div className="group-1-2">
          <div className="back-bone-cervical-vertebrae-left">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-left.svg"
              alt="back-bone-cervical-vertebrae-left"
            ></img>
          </div>
        </div>
        {/* todo:image  */}
        <div className="group-1-3">
          <div className="back-bone-cervical-vertebrae-right">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-cervical-vertebrae-right.svg"
              alt="back-bone-cervical-vertebrae-right"
            ></img>
          </div>
        </div>
      </div>
      <div className="group-2">
        <div className="group-2-1">
          <div className="back-bone-thoracic-vertebrae-4 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-4.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-4"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-5 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-5.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-5"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-6 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-6.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-6"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-7 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-7.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-7"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-8 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-8.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-8"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-9 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-9.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-9"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-10 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-10.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-10"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-11 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-11.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-11"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-12 body-part-container">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-12.svg"
              className="body-part hover"
              alt="back-bone-thoracic-vertebrae-12"
            ></img>
          </div>
          {/* helper bones just for show*/}
          <>
            <div className="back-bone-thoracic-vertebrae-helper-1">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-1.svg"
                alt="back-bone-thoracic-vertebrae-helper-1"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-2">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-2.svg"
                alt="back-bone-thoracic-vertebrae-helper-2"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-3">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-3.svg"
                alt="back-bone-thoracic-vertebrae-helper-3"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-4">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-4.svg"
                alt="back-bone-thoracic-vertebrae-helper-4"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-5">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-5.svg"
                alt="back-bone-thoracic-vertebrae-helper-5"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-6">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-6.svg"
                alt="back-bone-thoracic-vertebrae-helper-6"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-7">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-7.svg"
                alt="back-bone-thoracic-vertebrae-helper-7"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-8">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-8.svg"
                alt="back-bone-thoracic-vertebrae-helper-8"
              ></img>
            </div>
            <div className="back-bone-thoracic-vertebrae-helper-9">
              <img
                src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-9.svg"
                alt="back-bone-thoracic-vertebrae-helper-9"
              ></img>
            </div>
          </>
        </div>
        {/* helper bones */}
        <>
          <div className="back-bone-thoracic-vertebrae-helper-left-1">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-left-1.svg"
              alt="back-bone-thoracic-vertebrae-helper-left-1"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-left-2">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-left-2.svg"
              alt="back-bone-thoracic-vertebrae-helper-left-2"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-left-3">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-left-3.svg"
              alt="back-bone-thoracic-vertebrae-helper-left-3"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-left-4">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-left-4.svg"
              alt="back-bone-thoracic-vertebrae-helper-left-4"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-left-5">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-left-5.svg"
              alt="back-bone-thoracic-vertebrae-helper-left-5"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-right-1">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-right-1.svg"
              alt="back-bone-thoracic-vertebrae-helper-right-1"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-right-2">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-right-2.svg"
              alt="back-bone-thoracic-vertebrae-helper-right-2"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-right-3">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-right-3.svg"
              alt="back-bone-thoracic-vertebrae-helper-right-3"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-right-4">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-right-4.svg"
              alt="back-bone-thoracic-vertebrae-helper-right-4"
            ></img>
          </div>
          <div className="back-bone-thoracic-vertebrae-helper-right-5">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-thoracic-vertebrae-helper-right-5.svg"
              alt="back-bone-thoracic-vertebrae-helper-right-5"
            ></img>
          </div>
        </>
      </div>
      <div className="group-3">
        <div className="back-bone-lumbar-vertebrae-1 body-part-container">
          <img
            src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-1.svg"
            className="body-part hover"
            alt="back-bone-lumbar-vertebrae-1"
          ></img>
        </div>
        {/* helper bones */}
        <>
          <div className="back-bone-lumbar-vertebrae-helper-1">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-1.svg"
              alt="back-bone-lumbar-vertebrae-helper-1"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-left-0">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-left-0.svg"
              alt="back-bone-lumbar-vertebrae-helper-left-0"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-left-1">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-left-1.svg"
              alt="back-bone-lumbar-vertebrae-helper-left-1"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-right-0">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-right-0.svg"
              alt="back-bone-lumbar-vertebrae-helper-right-0"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-right-1">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-right-1.svg"
              alt="back-bone-lumbar-vertebrae-helper-right-1"
            ></img>
          </div>
        </>
      </div>
      <div className="group-4">
        <div className="back-bone-lumbar-vertebrae-2 body-part-container">
          <img
            src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-2.svg"
            className="body-part hover"
            alt="back-bone-lumbar-vertebrae-2"
          ></img>
        </div>
        <div className="back-bone-lumbar-vertebrae-3 body-part-container">
          <img
            src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-3.svg"
            className="body-part hover"
            alt="back-bone-lumbar-vertebrae-3"
          ></img>
        </div>
        <div className="back-bone-lumbar-vertebrae-4 body-part-container">
          <img
            src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-4.svg"
            className="body-part hover"
            alt="back-bone-lumbar-vertebrae-4"
          ></img>
        </div>
        <div className="back-bone-lumbar-vertebrae-5 body-part-container">
          <img
            src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-5.svg"
            className="body-part hover"
            alt="back-bone-lumbar-vertebrae-5"
          ></img>
        </div>

        {/* helper bones */}
        <>
          <div className="back-bone-lumbar-vertebrae-helper-left-2">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-left-2.svg"
              alt="back-bone-lumbar-vertebrae-helper-left-2"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-left-3">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-left-3.svg"
              alt="back-bone-lumbar-vertebrae-helper-left-3"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-left-4">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-left-4.svg"
              alt="back-bone-lumbar-vertebrae-helper-left-4"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-left-5">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-left-5.svg"
              alt="back-bone-lumbar-vertebrae-helper-left-5"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-right-2">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-right-2.svg"
              alt="back-bone-lumbar-vertebrae-helper-right-2"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-right-3">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-right-3.svg"
              alt="back-bone-lumbar-vertebrae-helper-right-3"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-right-4">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-right-4.svg"
              alt="back-bone-lumbar-vertebrae-helper-right-4"
            ></img>
          </div>
          <div className="back-bone-lumbar-vertebrae-helper-right-5">
            <img
              src="/images/human-skeleton/human-back-bone/back-bone-lumbar-vertebrae-helper-right-5.svg"
              alt="back-bone-lumbar-vertebrae-helper-right-5"
            ></img>
          </div>
        </>
      </div>
    </div>
  );
};

export default SkeletonBackBone;
