import { Assignment, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { api } from "services/AxiosInterceptor";
import { additionalInformation } from "services/HfrService";
import { orgId } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const { Box } = require("@mui/system");
const { Formik } = require("formik");

const HfrAdditionalInfo = () => {
  const state = useLocation();
  const systemOfMedicine = state?.state?.systemOfMedicine;
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);
  const options = [
    { code: "YALL", value: "Yes, Available for Everyone" },
    { code: "YIN", value: "Available for in-patients only" },
    { code: "N", value: "No" },
  ];

  const [additionalInfo, setAdditionalInfo] = useState({});
  const organizationId = orgId();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/abdm/hfr/onboarding?orgId=${organizationId}`);
        setAdditionalInfo(response?.data?.facilityAdditionalInformationRequest);
      } catch (error) {
        handleClick("error", "Can not get form status");
      }
    };

    fetchData();
  }, [organizationId, handleClick]);

  const goBack = () => {
    navigate("/home/hfrBasicInfo");
  };

  return (
    <Reveal>
      <Formik
        enableReinitialize={true}
        initialValues={{
          trackingId: state?.state?.trackingId,
          generalInformation: {
            hasDialysisCenter: additionalInfo?.generalInformation?.hasDialysisCenter
              ? additionalInfo?.generalInformation?.hasDialysisCenter
              : "",
            hasPharmacy: additionalInfo?.generalInformation?.hasPharmacy
              ? additionalInfo?.generalInformation?.hasPharmacy
              : "",
            hasBloodBank: additionalInfo?.generalInformation?.hasBloodBank
              ? additionalInfo?.generalInformation?.hasBloodBank
              : "",
            hasCathLab: additionalInfo?.generalInformation?.hasCathLab
              ? additionalInfo?.generalInformation?.hasCathLab
              : "",
            hasDiagnosticLab: additionalInfo?.generalInformation?.hasDiagnosticLab
              ? additionalInfo?.generalInformation?.hasDiagnosticLab
              : "",
            hasImagingCenter: additionalInfo?.generalInformation?.hasImagingCenter
              ? additionalInfo?.generalInformation?.hasImagingCenter
              : "",
            servicesByImagingCenter: [],
          },
          linkedProgramIds: {
            nhrrId: additionalInfo?.linkedProgramIds?.nhrrId
              ? additionalInfo?.linkedProgramIds?.nhrrId
              : "",
            nin: additionalInfo?.linkedProgramIds?.nin ? additionalInfo?.linkedProgramIds?.nin : "",
            abpmjayId: additionalInfo?.linkedProgramIds?.abpmjayId
              ? additionalInfo?.linkedProgramIds?.abpmjayId
              : "",
            rohiniId: additionalInfo?.linkedProgramIds?.rohiniId
              ? additionalInfo?.linkedProgramIds?.rohiniId
              : "",
            echsId: additionalInfo?.linkedProgramIds?.echsId
              ? additionalInfo?.linkedProgramIds?.echsId
              : "",
            cghsId: additionalInfo?.linkedProgramIds?.cghsId
              ? additionalInfo?.linkedProgramIds?.cghsId
              : "",
            ceaRegistration: additionalInfo?.linkedProgramIds?.ceaRegistration
              ? additionalInfo?.linkedProgramIds?.ceaRegistration
              : "",
            stateInsuranceSchemeId: additionalInfo?.linkedProgramIds?.stateInsuranceSchemeId
              ? additionalInfo?.linkedProgramIds?.stateInsuranceSchemeId
              : "",
          },
        }}
        onSubmit={async (values) => {
          try {
            await additionalInformation(organizationId, values);
            navigate("/home/hfrDetailedInfo", {
              state: {
                systemOfMedicine: systemOfMedicine,
                trackingId: values?.trackingId,
              },
            });
            handleClick("success", "Additional Info updated successfully !!");
          } catch (error) {
            handleClick("error", error.response.data.message);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <Box onSubmit={handleSubmit}>
            <form>
              <h2>Register Your Health Facility (HFR)</h2>

              <Reveal className="otp-container" style={{ width: "auto" }}>
                <h3>General Information</h3>
                <FormControl component="fieldset" sx={{ width: "50%", mb: 3 }}>
                  <FormLabel component="legend">
                    Does your facility have Diagnistic Laboratory?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="DiagnosticLaboratory"
                    name="DiagnosticLaboratory"
                    onChange={(e) => {
                      setFieldValue("generalInformation.hasDiagnosticLab", e.target.defaultValue);
                    }}
                    value={values?.generalInformation?.hasDiagnosticLab}
                  >
                    {options?.map((e, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={e?.code}
                          control={<Radio />}
                          label={e?.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{ width: "50%", mb: 3 }}>
                  <FormLabel component="legend">Does your facility have Pharmacy?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Pharmacy"
                    name="Pharmacy"
                    onChange={(e) => {
                      setFieldValue("generalInformation.hasPharmacy", e.target.defaultValue);
                    }}
                    value={values?.generalInformation?.hasPharmacy}
                  >
                    {options?.map((e, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={e?.code}
                          control={<Radio />}
                          label={e?.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{ width: "50%", mb: 3 }}>
                  <FormLabel component="legend">Does your facility have Blood Bank?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="BloodBank"
                    name="BloodBank"
                    onChange={(e) => {
                      setFieldValue("generalInformation.hasBloodBank", e.target.defaultValue);
                    }}
                    value={values?.generalInformation?.hasBloodBank}
                  >
                    {options?.map((e, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={e?.code}
                          control={<Radio />}
                          label={e?.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{ width: "50%", mb: 3 }}>
                  <FormLabel component="legend">Does your facility have Cath Lab?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="CathLab"
                    name="CathLab"
                    onChange={(e) => {
                      setFieldValue("generalInformation.hasCathLab", e.target.defaultValue);
                    }}
                    value={values?.generalInformation?.hasCathLab}
                  >
                    {options?.map((e, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={e?.code}
                          control={<Radio />}
                          label={e?.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{ width: "50%" }}>
                  <FormLabel component="legend">Does your facility have Dialysis Center?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="DialysisCenter"
                    name="DialysisCenter"
                    onChange={(e) => {
                      setFieldValue("generalInformation.hasDialysisCenter", e.target.defaultValue);
                    }}
                    value={values?.generalInformation?.hasDialysisCenter}
                  >
                    {options?.map((e, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={e?.code}
                          control={<Radio />}
                          label={e?.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" sx={{ width: "50%" }}>
                  <FormLabel component="legend">Does your facility have Imaging Center?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="ImagingCenter"
                    name="ImagingCenter"
                    onChange={(e) => {
                      setFieldValue("generalInformation.hasImagingCenter", e.target.defaultValue);
                    }}
                    value={values?.generalInformation?.hasImagingCenter}
                  >
                    {options?.map((e, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={e?.code}
                          control={<Radio />}
                          label={e?.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Reveal>

              <Reveal className="otp-container" style={{ width: "auto", marginBottom: "30px" }}>
                <h3>Linked Program IDs</h3>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-nhrr"
                    type="nhrr"
                    name="nhrr"
                    value={values?.linkedProgramIds?.nhrrId}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.nhrrId", e.target.value);
                    }}
                    label="NHRR ID"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-nin"
                    type="nin"
                    name="nin"
                    value={values?.linkedProgramIds?.nin}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.nin", e.target.value);
                    }}
                    label="National Identification Number"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-rohini"
                    type="rohini"
                    name="rohini"
                    value={values?.linkedProgramIds?.rohiniId}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.rohiniId", e.target.value);
                    }}
                    label="ROHINI ID (As alloted by IIB)"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-ab-pmjay"
                    type="ab-pmjay"
                    name="ab-pmjay"
                    value={values?.linkedProgramIds?.abpmjayId}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.abpmjayId", e.target.value);
                    }}
                    label="AB-PMJAY Hospital ID"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-cghs"
                    type="cghs"
                    name="cghs"
                    value={values?.linkedProgramIds?.cghsId}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.cghsId", e.target.value);
                    }}
                    label="CGHS Hospital ID"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-echs"
                    type="echs"
                    name="echs"
                    value={values?.linkedProgramIds?.echsId}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.echsId", e.target.value);
                    }}
                    label="ECHS Hospital ID"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
                <FormControl sx={{ width: "47%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-hmis"
                    type="hmis"
                    name="hmis"
                    value={values?.linkedProgramIds?.ceaRegistration}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.ceaRegistration", e.target.value);
                    }}
                    label="State HMIS ID"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
                <FormControl sx={{ width: "46%", mr: 3, mt: 2 }}>
                  <FormInputField
                    id="outlined-adornment-sish"
                    type="sish"
                    name="sish"
                    value={values?.linkedProgramIds?.stateInsuranceSchemeId}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("linkedProgramIds.stateInsuranceSchemeId", e.target.value);
                    }}
                    label="State Insurance Scheme Hospital ID"
                    startAdornment={<Assignment />}
                  />
                </FormControl>
              </Reveal>

              <Reveal style={{ width: "auto", marginTop: "20px", display: "flex" }}>
                <CustomButton
                  label="Previous"
                  style={{ marginRight: "auto" }}
                  className="btn--primary"
                  startIcon={<NavigateBefore />}
                  onClick={goBack}
                />
                <CustomButton
                  type="submit"
                  label="Next"
                  style={{ marginLeft: "auto" }}
                  className="btn--primary"
                  endIcon={<NavigateNext />}
                />
              </Reveal>
            </form>
          </Box>
        )}
      </Formik>
    </Reveal>
  );
};

export default HfrAdditionalInfo;
