import { margin } from "@mui/system";
import React from "react";

function FeatureCard({ title, icon, description, bgClass, onClick }) {
  return (
    <div className={"card " + bgClass}>
      <div className="card-body">
        <div>
          <h2 className="demo-card-title">
            <div className="title-icon">
              <img src={icon} />
            </div>
            <span className="title-text">{title}</span>
          </h2>
          <div className="body-text">
            {description.map((d) => (
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                <HeartBullet />
                <p>{d}</p>
              </div>
            ))}
          </div>
        </div>
        <button className="codepen-button btn-pad" onClick={onClick}>
          <div className="body">
            <span>Learn More</span>
            <svg
              width="2rem"
              height="2rem"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M11.4485 16.4487L7.55139 12.5515C8.99814 8.16555 13.1294 5 18 5C18.323 5 18.6428 5.01392 18.9588 5.04121C18.9861 5.35719 19 5.677 19 6.00006C19 10.8706 15.8345 15.0019 11.4485 16.4487Z"
                  stroke="#000000"
                ></path>{" "}
                <path
                  d="M7.99994 16L6.99994 17"
                  stroke="#000000"
                  strokeLinecap="round"
                ></path>{" "}
                <path
                  d="M9.49994 17.5L8.49994 18.5"
                  stroke="#000000"
                  strokeLinecap="round"
                ></path>{" "}
                <path
                  d="M6.49994 14.5L5.49994 15.5"
                  stroke="#000000"
                  strokeLinecap="round"
                ></path>{" "}
                <circle cx="12.9999" cy="11" r="1" stroke="#000000"></circle>{" "}
                <path
                  d="M13.9999 6L17.9999 10"
                  stroke="#000000"
                  strokeLinecap="round"
                ></path>{" "}
                <path
                  d="M13.9999 15.5V19L4.99994 10H8.49994"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

function HeartBullet() {
  return (
    <div style={{ marginRight: "0.5rem" }}>
      <svg
        height="1rem"
        width="1rem"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M12 8H12.01M12 11V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#2a9bc0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>{" "}
        </g>
      </svg>
    </div>
  );
}

export default FeatureCard;
