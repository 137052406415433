import { PersonOutlineOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";

const OnboardingStep0 = ({ handleBlur, handleChange, values, errors, touched }) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="inputTypeText" className="form-label onboarding-label">
          Kindly help us with your full name
        </label>
        <Grid>
          <FormInputField
            //   value={name}
            startAdornment={<PersonOutlineOutlined />}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.fullName}
            name={"fullName"}
            // style={{maxWidth: '400px', height: '42.67px'}}
            size="small"
            border={"1px solid #e6eef1"}
            background={"#fff"}
            borderOnFocus={"1px solid #29bf91"}
            error={Boolean(touched.fullName && errors.fullName)}
            errorText={errors.fullName}
            //   height={"64.43px"}
            inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
            placeholder={"E.g. John Doe"}
          />
        </Grid>
      </div>
    </>
  );
};

export default OnboardingStep0;
