import { createSlice } from "@reduxjs/toolkit";
import { orgId } from "store/constant";

const initialState = {
  user: null,
  activeMember: null,
  parentMemberId: null,
  frontDeskUserLinking: null,
  renderSidebar: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setParentFamilyId: (state, action) => {
      localStorage.setItem("parentMemberId", JSON.stringify(action.payload));
      state.parentMemberId = action.payload;
    },
    setActiveMember: (state, action) => {
      localStorage.setItem("activeMember", JSON.stringify(action.payload));
      state.activeMember = action.payload;
    },
    setUserFeatures: (state, action) => {
      localStorage.setItem("userFeatures", JSON.stringify(action.payload));
      state.userFeatures = action.payload;
    },
    setUserOrgId: (state, action) => {
      localStorage.setItem("orgId", action.payload);
      state.orgId = action.payload;
    },
    setFrontDeskUserLinking: (state, action) => {
      localStorage.setItem("frontDeskUserLinking", action.payload);
      state.frontDeskUserLinking = action.payload;
    },
    setRenderSidebar: (state, action) => {
      state.renderSidebar = action.payload;
    },
  },
});

export const {
  setUserData,
  setParentFamilyId,
  setActiveMember,
  setUserFeatures,
  setUserOrgId,
  setFrontDeskUserLinking,
  setRenderSidebar,
} = userSlice.actions;

export const getUserData = (state) => state.user.user;
export const getParentMemberId = (state) => state.user?.parentMemberId;
export const getActiveMember = (state) => state.user?.activeMember;
export const getUserFeatures = (state) => state.user?.userFeatures;
export const getUserOrgId = (state) => (state.user?.orgId ? state.user.orgId : orgId());
export const getFrontDeskUserLinking = (state) => state.user?.frontDeskUserLinking;
export const getRenderSidebar = (state) => state.user?.renderSidebar;

export const userReducer = userSlice.reducer;
