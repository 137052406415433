import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ModalUI from "ui-component/ModalUI";
import SubCard from "ui-component/cards/SubCard";
import CustomButton from "ui-component/custom-components/CustomButton";
import OrganizationAddAmenity from "./OrganizationAddAmenity";
import { useState } from "react";
import { Field } from "formik";
import { AMENITY_ACTIONS } from "../ManageOrganization";
import Reveal from "views/utilities/Reveal";
import { KeyboardArrowDown } from "@mui/icons-material";

const OrgAmenities = ({
  values,
  amenityState,
  amenityDispatch,
  orgDetail,
  setOrgDetail,
  setNewlyCreatedAmenity,
  fetchAmenities,
  amenityError,
}) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openAddAmenityModal = () => {
    setOpen(true);
  };

  return (
    <Reveal>
      <SubCard style={{ padding: "15px" }}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography className="card-field-value">Organization Amenities</Typography>
          <CustomButton
            style={{ marginLeft: "auto" }}
            className="ri-add-fill ri-lg btn--primary"
            label="Add Amenity"
            onClick={() => openAddAmenityModal()}
          />
        </Grid>
        <Grid container spacing={2}>
          {amenityState &&
            Object.entries(amenityState.organizedAmenities).map(([category, items], outerIndex) => (
              <Grid size={{ xs: 12 }} key={category}>
                <Accordion defaultExpanded={category === "Payment Mode Accepted" ? true : false}>
                  <AccordionSummary
                    sx={{
                      minHeight: "30px !important",
                      "& .MuiAccordionSummary-content": {
                        margin: "0px !important",
                        justifyContent: "center",
                      },
                      borderRadius: "8px",
                      "&.Mui-expanded": {
                        borderRadius: "8px 8px 0px 0px",
                      },
                      padding: "8px 16px",
                      background: "#e6eef1",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                    }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <h4>
                        {category === "Payment Mode Accepted" ? (
                          <>
                            {category}{" "}
                            <span className="required-asterisk" style={{ marginLeft: "6px" }}>
                              *
                            </span>
                          </>
                        ) : (
                          category
                        )}
                      </h4>
                      <CustomButton
                        iconButton={<KeyboardArrowDown />}
                        size="small"
                        style={{ padding: 0, height: "20px", marginLeft: "auto" }}
                      />
                    </div>
                  </AccordionSummary>

                  {category === "Payment Mode Accepted" && (
                    <>
                      {amenityError && (
                        <>
                          <Typography color="error" variant="body4">
                            {amenityError}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                  <AccordionDetails
                    className="common-scrollbar"
                    sx={{
                      p: "7px 15px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <Grid container spacing={2}>
                      {items.flat().map((it, innerIndex) => {
                        const continuousIndex =
                          Object.entries(amenityState.organizedAmenities)
                            .slice(0, outerIndex)
                            .reduce((sum, [, arr]) => sum + arr.length, 0) + innerIndex;

                        return (
                          <Grid key={it.id} item size={{ xs: 12, md: 6, lg: 4 }}>
                            <CheckboxField
                              index={continuousIndex}
                              amenityId={it.id}
                              label={it.name}
                              amenitiesCheckboxs={amenityState.filledAmenitiesCheckboxIds}
                              amenityDispatch={amenityDispatch}
                              amenityState={amenityState}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
        </Grid>
      </SubCard>

      <ModalUI
        visible={open}
        close={closeModal}
        title="Add Amenity"
        component={
          <OrganizationAddAmenity
            amenitiesCategory={amenityState.amenitiesCategory}
            close={(amenity) => {
              setOrgDetail({
                ...orgDetail,
                doctorByOrg: values.doctors,
                org: {
                  name: values.name,
                  code: values.code,
                  state: values.state,
                  since_when: values.since_when,
                  contactName: values.contactName,
                  contactMobile: values.contactMobile,
                  contactEmail: values.contactEmail,
                  address: values.address,
                  ad_state: values.ad_state,
                  ad_district: values.ad_district,
                  ad_city: values.ad_city,
                  ad_pincode: values.ad_pincode,
                  latitude: values.latitude,
                  longitude: values.longitude,
                },
              });
              setNewlyCreatedAmenity(amenity);
              fetchAmenities(amenity);
              closeModal();
            }}
            onCancel={() => {
              closeModal();
            }}
          />
        }
      />
    </Reveal>
  );
};

const CheckboxField = ({ index, amenityId, label, amenityDispatch }) => {
  return (
    <Field type="checkbox" name={`amenities.${index}`}>
      {({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              onClick={(e) => {
                const orgCreationcheckboxValue = { id: amenityId };
                amenityDispatch({
                  type: AMENITY_ACTIONS.ON_CLICK_AMENITY_CHECKBOX,
                  payload: {
                    orgCreationcheckboxValue: orgCreationcheckboxValue,
                    amenityId: amenityId,
                    checked: e.target.checked,
                  },
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={label}
        />
      )}
    </Field>
  );
};

export default OrgAmenities;
