import dayjs from "dayjs";
import { getBlockAvailabilitiesByDate } from "services/Availability";
import { CLINIC_VISIT, NONE, TELE_CONSULTATION, TIME_FORMAT } from "store/constant";

const mergeSlots = (slots) => {
  const mergedSlots = [];

  slots.forEach((slot) => {
    const existingSlot = mergedSlots.find(
      (s) => s.slotTime === slot.slotTime && s.organizationId === slot.organizationId
    );
    if (existingSlot) {
      if (existingSlot.blocked === true) {
        existingSlot.remainingCount = slot.remainingCount;
        existingSlot.consultationType = slot.consultationType;
        delete existingSlot.blocked;
      }
      if (existingSlot.remainingCount === slot.remainingCount) {
        if (existingSlot.consultationType !== slot.consultationType) {
          existingSlot.consultationType = "BOTH";
        }
      }
    } else {
      mergedSlots.push({ ...slot });
    }
  });
  mergedSlots.sort((a, b) => {
    if (a.slotTime < b.slotTime) {
      return -1;
    } else {
      return 1;
    }
  });
  return mergedSlots;
};

export const blockDoctorSlots = async (doctorSlots, doctorId, date) => {
  const blockCalendarResponse = await getBlockAvailabilitiesByDate(doctorId, date);
  for (const slot of doctorSlots) {
    for (const block of blockCalendarResponse.data) {
      const timeArr = slot.slotTime.split(":");
      const time = dayjs().set("hour", timeArr[0]).set("minute", timeArr[1]).set("second", 0);
      const startTime = time.format(TIME_FORMAT);
      const endTime = time.add(slot.slotDuration, "minute").format(TIME_FORMAT);
      switch (block.serviceType) {
        case NONE:
          if (
            block.organizationId.includes(slot.organizationId) &&
            startTime >= block.startTime &&
            endTime <= block.endTime
          ) {
            slot.remainingCount = 0;
            slot.blocked = true;
          }
          break;
        case TELE_CONSULTATION:
          if (
            slot.consultationType === CLINIC_VISIT &&
            block.organizationId.includes(slot.organizationId) &&
            startTime >= block.startTime &&
            endTime <= block.endTime
          ) {
            slot.remainingCount = 0;
            slot.blocked = true;
          }
          break;
        case CLINIC_VISIT:
          if (
            slot.consultationType === TELE_CONSULTATION &&
            block.organizationId.includes(slot.organizationId) &&
            startTime >= block.startTime &&
            endTime <= block.endTime
          ) {
            slot.remainingCount = 0;
            slot.blocked = true;
          }

          break;
        default:
      }
    }
  }
  return mergeSlots(doctorSlots);
};
