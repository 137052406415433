import React from "react";
import { BRAIN } from "utils/ehr-data-utils";

const HumanBrain = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  const isSelected = selectedOrgan.includes(BRAIN);

  return (
    // <div className={`human-brain ${!isSelected && selectedOrgan ? "fade" : ""}`} onClick={onClick}>
    <div className="human-brain" id="human-body-brain">
      <div
        className={`brain ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
        onClick={onClick}
      >
        <img
          src="/images/rightBrain.svg"
          alt="Right Brain"
          className={`right-brain hover body-part ${isSelected ? "highlight" : ""}`}
        />
        <img
          src="/images/leftBrain.svg"
          alt="Left Brain"
          className={`left-brain hover body-part ${isSelected ? "highlight" : ""}`}
        />
      </div>
    </div>
  );
};

export default HumanBrain;
