import { useState, useEffect, useRef } from "react";
import { api } from "services/AxiosInterceptor";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import AddAvailability from "../Appointment/AddAvailability";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  ACTIVE_STATE,
  CLINIC_VISIT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  currentActiveUser,
  DATE_FORMAT,
  blockCalendeOptions,
} from "store/constant";
import dayjs from "dayjs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditAvailabilityCard from "../Availability/EditAvailabilityCard";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import "assets/scss/availability.scss";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { getDoctorAvailability, getDoctorById } from "services/doctorService";
import {
  deleteAvailabilities,
  getBlockAvailabilities,
  cancelBlockAvailability,
  createBlockAvailabilities,
  editBlockAvailabilities,
} from "services/Availability";
import { getAppointmentCountBetweenDates } from "services/Appointments";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import {
  Box,
  Grid,
  Skeleton,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Reducers/userReducer";
import { useLocation } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { date } from "yup";

const DoctorAvailability = () => {
  const { handleClick } = useContext(ToastContext);
  const [availabilities, setAvailabilities] = useState([]);
  const [doctorDetail, setDoctorDetail] = useState(null);
  const [doctorOrganizations, setDoctorOrganizations] = useState([]);
  const doctorId = currentActiveUser()?.roleBasedId;
  const [editAvailabilityData, seteditAvailabilityData] = useState({});
  const [isUpdate, setisUpdate] = useState(false);
  const doctorOrgId = useSelector(getUserOrgId);
  const [blockAvailability, setBlockAvailability] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const datepickerRef = useRef(null);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [blockFromDate, setBlockFromDate] = useState(null);
  const [blockToDate, setBlockToDate] = useState(null);
  const [blockFromTime, setBlockFromTime] = useState(null);
  const [blockToTime, setBlockToTime] = useState(null);
  const [blockingType, setBlockingType] = useState("SINGLE_DATE");
  const [blockConfirmation, setBlockConfirmation] = useState(false);
  const [effectedAppointments, setEffectedAppointments] = useState(0);
  const [selectedAppointmentOption, setselectedAppointmentOption] = useState("");
  const [editId, setEditId] = useState();
  const [blockEditButton, setBlockEditButton] = useState();

  const { state } = useLocation();
  const pendingTask = state?.pendingTask;

  const [open, setOpen] = useState(pendingTask ? true : false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseBlockModal = () => {
    setIsBlockModalOpen(false);
    // getBlockCalendar();
    // getAppointmentEvents(doctorData);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteAvailability = async (availability) => {
    try {
      await deleteAvailabilities(availability.id);
      handleAvailabilities();
    } catch (error) {
      handleClick("error", "Error deleting availability");
    }
  };

  const getDataForEditAvailability = (availability) => {
    seteditAvailabilityData(availability);
    setisUpdate(true);
  };

  const handleAvailabilities = async () => {
    try {
      const availabilityResponse = await getDoctorAvailability(doctorId);
      setAvailabilities(availabilityResponse);
    } catch (error) {
      handleClick("error", "Error fetching availability");
    }
  };

  const handleEditBlockAvailability = async (data) => {
    try {
      const response = await editBlockAvailabilities(data);
    } catch (error) {
      handleClick("error", "Error editing block availability");
    }
  };

  useEffect(() => {
    const fetchBlockAvailabilities = async () => {
      try {
        const blockAvailabilityResponse = await getBlockAvailabilities(doctorId, doctorOrgId);
        setBlockAvailability(blockAvailabilityResponse.data);
      } catch (error) {
        handleClick("error", "Error fetching block dates list");
      }
    };
    fetchBlockAvailabilities();
  }, [doctorId, doctorOrgId]);

  const highlightedDates = Array.isArray(blockAvailability)
    ? blockAvailability
        .map((availability) => {
          const startDate = new Date(availability.startDate);
          const endDate = new Date(availability.endDate);
          const dates = [];

          // Loop from startDate to endDate
          while (startDate <= endDate) {
            const formattedDate = `${startDate.getUTCFullYear()}/${String(
              startDate.getUTCMonth() + 1
            ).padStart(2, "0")}/${String(startDate.getUTCDate()).padStart(2, "0")}`;

            dates.push({
              date: formattedDate,
              holidayName: "",
            });
            startDate.setDate(startDate.getDate() + 1);
          }
          return dates;
        })
        .flat()
    : [];

  const manageAvailabilities = (organization) => {
    let clinicVisitAvailabilities = [],
      teleconsultationAvailabilities = [];
    const availabilitiesArr = Array.isArray(availabilities) ? availabilities : [availabilities];
    availabilitiesArr.forEach((element) => {
      if (element.organizationId === organization.id) {
        if (element.consultationType === CLINIC_VISIT) {
          clinicVisitAvailabilities.push(element);
        } else {
          teleconsultationAvailabilities.push(element);
        }
      }
    });
    return {
      clinicArr: clinicVisitAvailabilities,
      teleArr: teleconsultationAvailabilities,
    };
  };

  const getAvailabilitiesForOtherOrganizations = () => {
    const availabilitiesArr = Array.isArray(availabilities) ? availabilities : [availabilities];
    return availabilitiesArr.filter((el) => el.organizationId !== doctorOrgId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const doctorResponse = await getDoctorById(doctorId);
        setDoctorDetail(doctorResponse.data);

        const organizationIds = [];
        doctorResponse.data.userResponse.userOrganizationAssociationList.forEach((org) => {
          if (org.isDoctor === true && org.status === ACTIVE_STATE) {
            organizationIds.push(org.organizationId);
          }
        });

        const organizationResponse = await api.get(`/api/organizations/ids?ids=${organizationIds}`);
        setDoctorOrganizations(organizationResponse.data);

        const availabilityResponse = await getDoctorAvailability(doctorId);
        setAvailabilities(availabilityResponse);
      } catch (error) {
        handleClick("error", "Error fetching availabilities.");
      }
    };
    fetchData();
  }, [doctorId]);

  const BlockCalendarModal = ({ doctorId, organizationId, closeModal, handleClick }) => {
    const [errors, setErrors] = useState({});

    const currentDate = dayjs().startOf("day");

    const validate = () => {
      const errors = {};

      if (blockFromDate === null) {
        errors.fromDate = "Required.";
      } else if (!(blockFromDate?.isValid ? blockFromDate.isValid() : true)) {
        errors.fromDate = "Invalid 'Date' value.";
      } else if (blockFromDate < currentDate) {
        errors.fromDate = "Date cannot be in past.";
      }

      if (blockToDate === null) {
        errors.toDate = "Required.";
      } else if (!(blockToDate?.isValid ? blockToDate.isValid() : true)) {
        errors.toDate = "Invalid 'Date' value.";
      } else if (!dayjs(blockToDate).isAfter(blockFromDate)) {
        errors.toDate = "Date should be after the from date.";
      }

      if (!selectedAppointmentOption) {
        errors.appointmentOption = "Required";
      }

      if (!blockFromTime) {
        errors.fromTime = "Required";
      }

      if (!blockToTime) {
        errors.toTime = "Required";
      }

      return errors;
    };

    const handleFetchSaveData = async () => {
      try {
        const fromDate = dayjs(blockFromDate);
        const toDate = dayjs(blockToDate);
        const startDate = fromDate.format(DATE_FORMAT);
        const endDate = toDate.format(DATE_FORMAT);
        const payload = {
          startDate: startDate,
          endDate: blockToDate ? endDate : null,
          startTime: blockFromTime,
          endTime: blockToTime,
          blockingType: blockingType,
          doctorId: doctorId,
          organizationId: [organizationId],
          serviceType: selectedAppointmentOption,
        };
        const editPayload = {
          id: editId,
          startDate: startDate,
          endDate: blockToDate ? endDate : null,
          startTime: blockFromTime,
          endTime: blockToTime,
          blockingType: blockingType,
          doctorId: doctorId,
          organizationId: [organizationId],
          serviceType: selectedAppointmentOption,
        };
        if (blockConfirmation && !blockEditButton) {
          await createBlockAvailabilities(payload);
          handleClick("success", "Availability blocked successfully");
          setBlockConfirmation(false);
          closeModal();
        } else if (blockConfirmation && blockEditButton) {
          await handleEditBlockAvailability(editPayload);
          handleClick("success", "Availability block edited successfully");
          setBlockConfirmation(false);
          closeModal();
        } else {
          // todo : call api to fetch count of appointments that will be effected
          await fetchAppointmentCount();
          setBlockConfirmation(true);
        }
      } catch (error) {
        handleClick("error", "Error saving block calendar");
      }
    };

    const fetchAppointmentCount = async () => {
      try {
        const toDate = blockToDate !== null ? dayjs(blockToDate).format("YYYY-MM-DD") : null;
        const payload = {
          fromDate: dayjs(blockFromDate).format("YYYY-MM-DD"),
          toDate: dayjs(blockToDate).format("YYYY-MM-DD") ? toDate : null,
          fromTime: blockFromTime,
          toTime: blockToTime,
          doctorId: doctorId,
          orgId: organizationId,
        };
        const response = await getAppointmentCountBetweenDates(payload);
        setEffectedAppointments(response.data.totalAppointments);
      } catch (error) {
        handleClick("error", "Error fetching appointment count");
      }
    };

    const handleBlockCalendar = () => {
      const validationErrors = validate();
      if (
        (blockingType === "SINGLE_DATE" && Object.keys(validationErrors).length > 3) ||
        (blockingType === "CUSTOM_DATE_TIME_RANGE" && Object.keys(validationErrors).length > 0)
      ) {
        setErrors(validationErrors);
      } else {
        setErrors({});
        handleFetchSaveData();
      }
    };

    return (
      <div>
        <Grid container spacing={3} mt={0.7}>
          <Grid item style={{ paddingTop: 0, marginTop: "20px" }}>
            <Typography sx={{ mt: 0, mb: 0, fontWeight: 500, color: "#000000" }}>
              Blocking Type
            </Typography>
            <Grid item style={{ display: "flex", paddingTop: 0, mb: 0 }}>
              <RadioGroup
                value={blockingType}
                onChange={(e) => {
                  setBlockingType(e.target.value);
                  setBlockConfirmation(false);
                  if (e.target.value === "CUSTOM_DATE_TIME_RANGE") {
                    setBlockToDate(null);
                    setBlockFromTime(null);
                    setBlockToTime(null);
                    const newErrors = { ...errors };
                    delete newErrors.toDate;
                    delete newErrors.fromTime;
                    delete newErrors.toTime;
                    setErrors(newErrors);
                  }
                }}
                style={{ height: "50px" }}
              >
                <Grid item xs={12}>
                  <FormControlLabel value="SINGLE_DATE" control={<Radio />} label="Single Date" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value="CUSTOM_DATE_TIME_RANGE"
                    control={<Radio />}
                    label="Custom Range"
                  />
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={0.7}>
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormDatePicker
              inputFormat="DD/MM/YYYY"
              label={blockingType === "SINGLE_DATE" ? "On Date" : "From Date"}
              value={blockFromDate}
              size={"big"}
              required
              minDate={dayjs().startOf("day")}
              onChange={(e) => {
                setBlockFromDate(e);
                if (e === null) {
                  setErrors({ ...errors, fromDate: "Required." });
                } else if (!e.isValid()) {
                  setErrors({ ...errors, fromDate: "Invalid 'Date' value." });
                } else if (e < currentDate) {
                  setErrors({ ...errors, fromDate: "Date cannot be in past." });
                } else {
                  const { fromDate, ...newError } = errors;
                  setErrors(newError);
                }
                // once date changes then we need to refetch the total appointments on that data
                // so for that changing the blockConfirmation values to false again
                setBlockConfirmation(false);
              }}
              style={{
                width: "100%",
                marginRight: "30px",
                marginTop: "7px",
              }}
              error={Boolean(errors.fromDate)}
              errorText={errors.fromDate}
            ></FormDatePicker>
          </Grid>
          {blockingType !== "SINGLE_DATE" && (
            <Grid item xs={6} style={{ paddingTop: 0 }}>
              <FormDatePicker
                inputFormat="DD/MM/YYYY"
                label={"To Date"}
                required
                value={blockToDate}
                size={"big"}
                minDate={
                  blockFromDate && dayjs(blockFromDate).isValid()
                    ? dayjs(blockFromDate).add(1, "day").startOf("day")
                    : dayjs().startOf("day")
                }
                onChange={(e) => {
                  setBlockToDate(e);
                  if (e === null) {
                    setErrors({ ...errors, toDate: "Required." });
                  } else if (!e.isValid()) {
                    setErrors({ ...errors, toDate: "Invalid 'Date' value." });
                  } else if (!e.isAfter(blockFromDate)) {
                    setErrors({
                      ...errors,
                      toDate: "Date should be after the from Date.",
                    });
                  } else {
                    const { toDate, ...newError } = errors;
                    setErrors(newError);
                  }
                  // once date changes then we need to refetch the total appointments on that data
                  // so for that changing the blockConfirmation values to false again
                  setBlockConfirmation(false);
                }}
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginTop: "7px",
                }}
                error={Boolean(errors.toDate)}
                errorText={errors.toDate}
              ></FormDatePicker>
            </Grid>
          )}
        </Grid>
        {blockingType !== "SINGLE_DATE" && (
          <Grid container spacing={3} mt={0.7}>
            <Grid item sx={{ width: "50%" }} style={{ paddingTop: 0, marginTop: "20px" }}>
              <FormInputField
                style={{ width: "100%" }}
                label="From Time"
                name="fromTime"
                type="time"
                shrink={true}
                value={blockFromTime}
                onChange={(e) => {
                  setBlockFromTime(e.target.value);
                  const newErrors = { ...errors };

                  if (blockToTime && e.target.value && blockToTime <= e.target.value) {
                    newErrors.fromTime = "From Time should be before To Time.";
                  } else {
                    delete newErrors.fromTime;
                    delete newErrors.toTime;
                  }
                  setErrors(newErrors);
                }}
                error={Boolean(errors.fromTime)}
                errorText={errors.fromTime}
                size={"big"}
              />
            </Grid>
            <Grid item sx={{ width: "50%" }} style={{ paddingTop: 0, marginTop: "20px" }}>
              <FormInputField
                style={{ width: "100%" }}
                label="To Time"
                shrink={true}
                name="toTime"
                type="time"
                value={blockToTime}
                onChange={(e) => {
                  setBlockToTime(e.target.value);
                  const newErrors = { ...errors };

                  if (blockFromTime && e.target.value && e.target.value <= blockFromTime) {
                    newErrors.toTime = "To Time should be after From Time.";
                  } else {
                    delete newErrors.toTime;
                    delete newErrors.fromTime;
                  }
                  setErrors(newErrors);
                }}
                error={Boolean(errors.toTime)}
                errorText={errors.toTime}
                size={"big"}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={6} mt={2}>
            <FormSelectField
              style={{ width: "100%", marginTop: "15px" }}
              label="Appointment Options"
              name="selectedAppointmentOption"
              onChange={(e) => {
                setselectedAppointmentOption(e.target.value);
                const newErrors = { ...errors };
                delete newErrors.appointmentOption;
                setErrors(newErrors);
              }}
              startAdornment={<FilterAltIcon />}
              menuItems={blockCalendeOptions}
              value={selectedAppointmentOption}
              size={"big"}
              error={Boolean(errors.appointmentOption)}
              errorText={errors.appointmentOption}
            ></FormSelectField>
          </Grid>
        </Grid>
        {blockConfirmation && effectedAppointments > 0 && (
          <Typography style={{ marginTop: "20px", fontWeight: "500", color: "#004c70" }}>
            {`You have ${effectedAppointments} appointments scheduled, which will be cancelled.`}{" "}
            <br></br> {`Do you want to proceed?`}
          </Typography>
        )}

        <div style={{ display: "flex" }}>
          <CustomButton
            className={"mui-btn--primary"}
            height="36px"
            label={"Block Calendar"}
            onClick={() => {
              fetchAppointmentCount();
              handleBlockCalendar();
            }}
            style={{ marginTop: "20px", marginRight: "20px" }}
          ></CustomButton>
          {blockConfirmation && (
            <CustomButton
              className={"btn--secondary-light"}
              height="36px"
              label={"Cancel"}
              onClick={closeModal}
              style={{ marginTop: "20px", marginRight: "20px" }}
            ></CustomButton>
          )}
        </div>
      </div>
    );
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  function findMatchingDate(blockAvailability, date) {
    return blockAvailability.find((item) => {
      const startDate = dayjs(item.startDate).startOf("day");
      const endDate = dayjs(item.endDate).endOf("day");
      return dayjs(date).startOf("day").isBetween(startDate, endDate, null, "[]");
    });
  }

  return (
    <DoctorAvailabilitySkeleton doctorOrganizations={doctorOrganizations}>
      <div className="doctor-availability">
        <Reveal
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <h6 className="page-title">My Availability</h6>
          </div>
          <div className="col" style={{ position: "relative" }}>
            <CustomButton
              className="calendar ri-calendar-2-line ri-lg btn--secondary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setIsOpen(!isOpen);
                setIsBlock(false);
              }}
            />
            {isOpen && (
              <>
                <div
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    top: "38px",
                    transform: "translateX(-80px)",
                  }}
                >
                  <div ref={datepickerRef}>
                    <DatePicker
                      selected={new Date()}
                      // highlightDates={availableDates}
                      holidays={highlightedDates}
                      onChange={(date) => {
                        // setIsOpen(!isOpen);
                        setIsBlock(true);
                        setBlockFromDate(date);
                        if (highlightedDates.some((item) => item.date === formatDate(date))) {
                          setBlockEditButton(true);
                        } else {
                          setBlockEditButton(false);
                        }
                        const matchedObject = findMatchingDate(blockAvailability, date);
                        if (matchedObject) {
                          setBlockFromDate(matchedObject.startDate);
                          setBlockToDate(matchedObject.endDate);
                          setBlockFromTime(matchedObject.fromTime);
                          setBlockToTime(matchedObject.toTime);
                          setselectedAppointmentOption(matchedObject.appointmentType);
                          setBlockingType(matchedObject.blockingType);
                          setEditId(matchedObject.id);
                        } else {
                        }
                      }}
                      inline
                    />
                  </div>
                  {isBlock && (
                    <div
                      style={{
                        width: "100%",
                        // background: "white",
                        // padding: "4px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                        // border: "1px solid #aeaeae",
                        // borderRadius: "0.3rem",
                      }}
                    >
                      <CustomButton
                        onClick={() => setIsBlockModalOpen(true)}
                        label="Block date"
                        disabled={blockEditButton}
                      />
                      <CustomButton
                        className={""}
                        onClick={() => setIsBlockModalOpen(true)}
                        label="Edit"
                        disabled={!blockEditButton}
                      />
                      <ModalUI
                        visible={isBlockModalOpen}
                        close={() => {
                          setIsBlockModalOpen(false);
                        }}
                        title={"Block Calendar By Date"}
                        style={{
                          overflowY: "none",
                          height: "fit-content",
                          width: "610px",
                        }}
                        component={
                          <BlockCalendarModal
                            doctorId={doctorId}
                            organizationId={doctorOrgId}
                            closeModal={handleCloseBlockModal}
                            handleClick={handleClick}
                          ></BlockCalendarModal>
                        }
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            <CustomButton
              className="ri-add-fill ri-lg btn--secondary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleOpen();
                setisUpdate(false);
              }}
              label="Add Availability"
            />
          </div>
        </Reveal>

        {doctorOrganizations.map((organization, index) => (
          <Reveal key={index}>
            <EditAvailabilityCard
              cardInfo={{
                name: organization.name,
                address: organization.address,
              }}
              handleOpen={handleOpen}
              getDataForEditAvailability={getDataForEditAvailability}
              handleDeleteAvailability={handleDeleteAvailability}
              clinicVisitAvailabilities={manageAvailabilities(organization).clinicArr}
              teleconsultationAvailabilities={manageAvailabilities(organization).teleArr}
            ></EditAvailabilityCard>
            <br></br>
          </Reveal>
        ))}
      </div>
      <AddAvailability
        isOpen={open}
        pendingTask={pendingTask}
        onClose={handleCloseModal}
        doctors={[doctorDetail]}
        docOrganizations={doctorOrganizations}
        availabilities={availabilities}
        handleAvailabilities={handleAvailabilities}
        columns={[]}
        actionsForAvailabilities={[]}
        isUpdate={isUpdate}
        isDelete={false}
        editAvailabilityData={editAvailabilityData}
        isDoctorAdding={true}
        otherOrgavailabilities={getAvailabilitiesForOtherOrganizations()}
      ></AddAvailability>
    </DoctorAvailabilitySkeleton>
  );
};

const DoctorAvailabilitySkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        {Array.from({ length: 2 }, (_, index) => index).map((el, index) => (
          <Grid item xs={12}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={135}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DoctorAvailability;
