import React from "react";
import Typography from "@mui/material/Typography";
import CustomTypography from "./CustomTypography";
import { Box, Menu, MenuList, Skeleton, Tab } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import "../../assets/scss/CustomCard.scss";
import { useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomButton from "./CustomButton";
import ModalUI from "ui-component/ModalUI";
import {
  TELE_CONSULTATION,
  currentActiveUser,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
  DATE_FORMAT,
  HOUR_MINUTE_FORMAT,
  BOTH,
  CLINIC_VISIT,
  convertTimeForTimeFormat,
  UHI,
  SYSTEM,
} from "store/constant";
import { useNavigate } from "react-router";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import {
  setAppointmentDate,
  setDoctorId,
  setSelectedAppointmentData,
} from "store/Slices/appointmentDataSlice";
import { useDispatch } from "react-redux";
import { TodayOutlined } from "@mui/icons-material";
import { getSlotsAvailabilities } from "services/Availability";
import { getDoctorAvailability } from "services/doctorService";
import { useContext } from "react";
import { ToastContext } from "./CustomToast";
import { getProfilePicture } from "services/patientService";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { blockDoctorSlots } from "utils/block-doctor-slots";
import CustomMenuItem from "./CustomMenuItem";

const currentDate = dayjs();

const CustomCard = ({ doctorDetail, isUhiDoctor, isDoctorProfilepage, actions }) => {
  const [items, setItems] = useState(null);
  const [tabValue, setTabValue] = React.useState("0");
  const [slotsDetails, setSlotsDetails] = useState([]);
  const [, setTabDate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uhiDoctorData, setUhiDoctorData] = useState(null);

  const user = currentActiveUser();

  const { roleBasedId, name: userName } = user;

  const familyMemberList = user.familyMemberList || [];

  const extractedData = familyMemberList.map((member) => ({
    id: member.patientId,
    name: member.name,
  }));

  extractedData.unshift({
    id: roleBasedId,
    name: userName,
  });

  // const filterUhiAvailableSlots = (slots, date) => {
  //   const now = dayjs();
  //   const nowString = now.format(DATE_FORMAT);
  //   const currentTime = now.format(HOUR_MINUTE_FORMAT);
  //   const filteredSlots = slots
  //     .filter((slot) => {
  //       const slotDate = dayjs(slot.start).format(DATE_FORMAT);
  //       return slotDate === date;
  //     })
  //     .map((slot) => {
  //       const startDate = dayjs(slot.start);
  //       const endDate = dayjs(slot.end);
  //       const differenceInMinutes = endDate.diff(startDate, "minute");
  //       const formattedStartTime = startDate.format("HH:mm");
  //       const formattedEndTime = endDate.format("HH:mm");

  //       return {
  //         date: date,
  //         consultationType: TELE_CONSULTATION,
  //         slotDuration: differenceInMinutes,
  //         slotTime: formattedStartTime,
  //         endTime: formattedEndTime,
  //         fulfillmentId: slot.fulfillmentId,
  //         disabled: nowString === date && currentTime > formattedStartTime ? true : false,
  //       };
  //     });
  //   setSlotsDetails(filteredSlots);
  // };

  const filterAvailableSlots = (slots, date) => {
    const now = dayjs();
    const nowString = now.format(DATE_FORMAT);
    const currentTime = now.format(HOUR_MINUTE_FORMAT);
    const filteredSlots = slots.map((slot) => {
      return {
        ...slot,
        date: date,
        disabled:
          slot.remainingCount === 0
            ? true
            : nowString === date && currentTime > slot.slotTime
            ? true
            : false,
      };
    });
    setSlotsDetails(filteredSlots);
  };

  const getDoctorSlots = async (doctorDetail, date) => {
    const formattedStartDate = date.format(DATE_FORMAT);
    const formattedEndDate = date.add(6, "day").format(DATE_FORMAT);

    const doctorType = doctorDetail?.doctorType;
    const commonParams = {
      doctorType,
      appointmentDate: formattedStartDate,
      doctorId: doctorDetail?.docId,
    };

    try {
      let response = null;
      if (doctorType === "UHI") {
        const searchParams = {
          doctorType,
        };
        const data = {
          euaSecondSearchRequest: {
            transactionId: doctorDetail.transactionId,
            providerUri: doctorDetail.providerUri,
            providerId: doctorDetail.providerId,
            fulfillmentType: "Online",
            agentId: doctorDetail.agentId,
            startTime: `${formattedStartDate}T00:00:00`,
            endTime: `${formattedEndDate}T23:59:59`,
            fulfillmentId: doctorDetail.fulfillmentId,
            categoryId: doctorDetail.categoryId,
            itemId: doctorDetail.itemId,
            hospitalId: doctorDetail.hospitalId,
          },
        };
        response = await getSlotsAvailabilities(searchParams, data);
        filterAvailableSlots(response?.data, formattedStartDate);
        setUhiDoctorData(data);
      } else if (doctorType === "SYSTEM") {
        response = await getSlotsAvailabilities(commonParams);
        const listAfterBlocked = await blockDoctorSlots(
          response?.data,
          doctorDetail?.docId,
          formattedStartDate
        );
        filterAvailableSlots(listAfterBlocked, formattedStartDate);
      }
    } catch (error) {
      setSlotsDetails(null);
    }
  };

  useEffect(() => {
    const formattedDate = currentDate.format(DATE_FORMAT);
    setTabDate(formattedDate);

    const fetchData = async () => {
      if (doctorDetail) {
        await getDoctorSlots(doctorDetail, currentDate);
      }
    };

    fetchData();
  }, [doctorDetail, currentDate]);

  const handleChange = async (event, newValue, date, doctorDetail) => {
    setTabValue(newValue);
    setTabDate(date);

    try {
      await getDoctorSlots(doctorDetail, date);
    } catch (error) {
      console.error("Error fetching slots", error);
      setSlotsDetails(null);
    }
  };

  useEffect(() => {
    const items = Array.from({ length: 7 }, (_, i) => {
      const date = currentDate.add(i, "day");

      let formattedDate = date.format("MMM DD");

      let dayLabel = "";
      if (i === 0) {
        dayLabel = "Today";
      } else if (i === 1) {
        dayLabel = "Tomorrow";
      } else {
        dayLabel = date.format("ddd");
      }
      return {
        label: (
          <>
            {formattedDate} <br /> {dayLabel}
          </>
        ),

        value: `${i}`,
        component: (
          <TimeSlots
            className="time-slots"
            navigate={navigate}
            roleBasedId={roleBasedId}
            slots={slotsDetails?.filter((slot) => {
              if (slot.date === dayjs().format(DATE_FORMAT)) {
                if (slot.slotTime < dayjs().format(HOUR_MINUTE_FORMAT)) {
                  return false;
                }
              }
              return true;
            })}
            date={date}
            user={user}
            uhiDoctorData={uhiDoctorData}
            isUhiDoctor={isUhiDoctor}
            doctorDetail={doctorDetail}
            extractedData={extractedData}
            getDoctorSlots={getDoctorSlots}
          />
        ),
        date: date,
      };
    });

    setItems(items);
  }, [doctorDetail?.doctorAvailabilities, slotsDetails]);

  return (
    <Reveal className="custom-card">
      <div className="custom-card-inner" style={{ display: "flex", marginTop: "20px" }}>
        <DoctorDetailsCard
          doctorDetail={doctorDetail}
          isUhiDoctor={isUhiDoctor}
          isDoctorProfilepage={isDoctorProfilepage}
          actions={null}
        />
        {items && (
          <Box className="time-slot">
            <TabContext value={tabValue}>
              <Box style={{ overflowX: "auto", height: "40%" }}>
                <TabList
                  variant="scrollable"
                  onChange={(event, newValue) => {
                    const selectedItem = items.find((item) => item.value === newValue);
                    const formatedDate = dayjs(selectedItem.date).format(DATE_FORMAT);
                    dispatch(setAppointmentDate(formatedDate));
                    dispatch(setDoctorId(doctorDetail?.docId));
                    handleChange(event, newValue, selectedItem?.date, doctorDetail);
                  }}
                  aria-label="lab API tabs example"
                  allowScrollButtonsMobile={true}
                  TabIndicatorProps={{
                    sx: {
                      display: "none",
                    },
                  }}
                >
                  {items.map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        sx={{
                          backgroundColor: tabValue === `${item.value}` ? "#29bf91" : "#ffffff",
                          borderRadius: "8px 8px 8px 8px",
                          color: tabValue === `${item.value}` ? "#ffffff !important" : "#004c70",
                          padding: "5px 0px",
                        }}
                        label={item.label}
                        value={`${item.value}`}
                      />
                    );
                  })}
                </TabList>
              </Box>
              {items.map((item, index) => {
                return (
                  <TabPanel
                    className="tab-panel"
                    key={index}
                    value={`${item.value}`}
                    sx={{
                      padding: "5px 25px",
                    }}
                  >
                    {item.component}
                  </TabPanel>
                );
              })}
            </TabContext>
          </Box>
        )}
        <Actions actions={actions} doctorDetail={doctorDetail} />
      </div>
    </Reveal>
  );
};

export default CustomCard;

const Actions = ({ actions, doctorDetail }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const handleMenuOpen = (event, doctorDetail) => {
    setClickedRow(doctorDetail);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <CustomButton
        iconButton={<i className="ri-more-2-fill" />}
        onClick={(event) => handleMenuOpen(event, doctorDetail)}
        aria-label="Actions"
        style={{ padding: 0 }}
      />
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            borderRadius: "4px",
          },
        }}
      >
        <MenuList dense sx={{ p: 0 }}>
          {actions?.map((action, actionIndex) => {
            return (
              <CustomMenuItem
                key={actionIndex}
                text={
                  typeof action.label === "function" ? action.label(doctorDetail) : action.label
                }
                icon={action.icon}
                onClick={() => {
                  handleMenuClose();
                  action.onClick(clickedRow.docId);
                }}
              />
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export const DoctorDetailsCard = ({ doctorDetail, isUhiDoctor, isDoctorProfilepage, actions }) => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [consultationTypeData, setConsultationTypeData] = useState({
    canClinicVisit: false,
    canTeleconsultation: false,
  });

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        setIsLoading(true);
        const profilePicResponse = await getProfilePicture(doctorDetail?.userId);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;

        setProfileImage({
          filename: profilePicResponse.data.filename,
          content,
        });
      } catch (error) {
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchDoctorAvailability = async () => {
      try {
        let response;
        if (doctorDetail?.doctorAvailabilities) {
          response = doctorDetail.doctorAvailabilities;
        } else {
          response = await getDoctorAvailability(doctorDetail.docId);
        }

        let canClinic = false;
        let canTeleconsult = false;
        response.forEach((availability) => {
          if (availability.consultationType === CLINIC_VISIT) {
            canClinic = true;
          } else if (availability.consultationType === TELE_CONSULTATION) {
            canTeleconsult = true;
          }
        });
        setConsultationTypeData({
          canClinicVisit: canClinic,
          canTeleconsultation: canTeleconsult,
        });
      } catch (error) {}
    };

    if (!isUhiDoctor) {
      fetchProfilePicture();
      fetchDoctorAvailability();
    }
  }, [doctorDetail, isUhiDoctor]);

  const genderBasedImage =
    doctorDetail?.gender === "FEMALE" ? "/images/woman.png" : "/images/man.png";

  return (
    <Reveal className="custom-card-inner-left">
      <div className="custom-card-inner-left-image" style={{ position: "relative" }}>
        {isLoading ? (
          <Skeleton variant="circular" animation="wave" width={"100%"} height={"90%"} />
        ) : (
          <>
            <img
              src={
                isUhiDoctor
                  ? doctorDetail.doctorImage
                  : profileImage
                  ? profileImage?.content
                  : genderBasedImage
              }
              alt={doctorDetail?.name}
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
              }}
            />
            <img
              src={
                doctorDetail?.doctorType === UHI
                  ? doctorDetail?.providerImage
                  : "/images/arog-logo-text.svg"
              }
              alt="Source Logo"
              style={{
                background: "white",
                borderRadius: "50%",
                position: "absolute",
                left: "60%",
                // transform: "translateX(-50%)",
                bottom: "0",
                width: "40px",
                height: "40px",
              }}
            />
          </>
        )}
      </div>
      <div className="custom-card-inner-left-details">
        <div
          className="custom-card-inner-left-details-first"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <CustomTypography className="custom-card-inner-left-details-first-text">
            {doctorDetail?.name}
          </CustomTypography>
          {actions && <Actions actions={actions} doctorDetail={doctorDetail} />}
        </div>
        {doctorDetail?.details && (
          <>
            <div className="custom-card-inner-left-details-second">
              <CustomTypography className="custom-card-inner-left-details-second-text">
                {doctorDetail?.details}
              </CustomTypography>
            </div>
          </>
        )}
        <div className="custom-card-inner-left-details-third" style={{ gap: "1vw" }}>
          <div
            className="custom-card-inner-left-details-third-1"
            style={{ display: "flex", alignItems: "center", gap: "0.2vw" }}
          >
            <i className="ri-pin-distance-line ri-lg" />
            <div>
              <CustomTypography className="custom-card-inner-left-details-third-text">
                5 mins away
              </CustomTypography>
            </div>
          </div>
          {doctorDetail?.value !== null && (
            <>
              <span>|</span>
              <div
                className="custom-card-inner-left-details-third-2"
                style={{ display: "flex", gap: ".1vw" }}
              >
                {doctorDetail?.value !== 0 && (
                  <>
                    <CustomTypography style={{ paddingTop: "1px" }}>
                      {doctorDetail?.value}
                    </CustomTypography>
                    <i className="ri-star-fill icon-primary-star" style={{ fontSize: "1.2em" }} />
                  </>
                )}
                {doctorDetail?.value === 0 && (
                  <>
                    <span className="cst-star-style">
                      - <i className="ri-star-fill" style={{ fontSize: "1.2em" }} />
                    </span>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className="custom-card-inner-left-details-fourth">
          {consultationTypeData.canClinicVisit && (
            <div style={{ cursor: "text", display: "flex" }}>
              <i
                className="ri-walk-fill ri-lg"
                style={{
                  left: "1px",
                  gap: "0px",
                  opacity: "0px",
                  color: "#29BF91",
                }}
              />

              <div>
                <CustomTypography className="offlineVisit" sx={{}}>
                  {TEXT_FOR_CLINIC_VISIT}
                </CustomTypography>
              </div>
            </div>
          )}
          {consultationTypeData.canClinicVisit && consultationTypeData.canTeleconsultation && (
            <span>|</span>
          )}
          {consultationTypeData.canTeleconsultation && (
            <div style={{ cursor: "text", display: "flex" }}>
              <i
                className="ri-video-chat-fill ri-lg"
                style={{
                  marginTop: "4px",
                  left: "1px",
                  gap: "0px",
                  opacity: "0px",
                  color: "#004C70",
                }}
              />
              <div>
                <CustomTypography className="onlineVisit">
                  {TEXT_FOR_TELECONSULTATION}
                </CustomTypography>
              </div>
            </div>
          )}
        </div>
        <div
          className="custom-card-inner-left-details-fifth"
          style={{ cursor: "pointer", display: "flex" }}
        >
          {!isDoctorProfilepage && (
            <>
              <div>
                {/* on view more, navigating to doctor profile, and sending flag value and data */}
                <CustomTypography
                  className="more-details"
                  onClick={() => {
                    let data;
                    if (isUhiDoctor) {
                      data = {
                        isUhiDoctor: true,
                        name: doctorDetail.name,
                        details: doctorDetail.details,
                        value: 0,
                        doctorImage: doctorDetail.doctorImage,
                        transactionId: doctorDetail.transactionId,
                        providerUri: doctorDetail.providerUri,
                        providerId: doctorDetail.providerId,
                        fulfillmentType: "Online",
                        agentId: doctorDetail.agentId,
                        fulfillmentId: doctorDetail.fulfillmentId,
                        categoryId: doctorDetail.categoryId,
                        itemId: doctorDetail.itemId,
                        hospitalId: doctorDetail.hospitalId,
                        doctorDetails: doctorDetail.doctorDetails,
                        orgDetails: doctorDetail.orgDetails,
                        providerDetails: doctorDetail.providerDetails,
                      };
                    } else {
                      data = {
                        //for now, it is set to SYSTEM
                        doctorType: SYSTEM,
                        isUhiDoctor: false,
                        name: doctorDetail.name,
                        docId: doctorDetail.docId,
                        details: doctorDetail.details,
                        value: doctorDetail.value,
                        orgIds: doctorDetail.orgIds,
                        imageSrc: doctorDetail.imageSrc,
                        userId: doctorDetail.userId,
                      };
                    }
                    navigate("/home/doctorProfile", {
                      state: {
                        viewOnlyPage: true,
                        data: data,
                        isDoctorProfilepage: true,
                      },
                    });
                  }}
                >
                  View more
                </CustomTypography>
              </div>
              <div>
                <KeyboardDoubleArrowRightOutlinedIcon
                  style={{
                    width: "100%",
                    height: "18.36px",
                    top: "16px",
                    left: "4px",
                    padding: "0px",
                    gap: "0px",
                    opacity: "0px",
                    color: "#004C70",
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Reveal>
  );
};

const TimeSlots = ({
  uhiDoctorData,
  slots,
  extractedData,
  date,
  doctorDetail,
  roleBasedId,
  navigate,
  isUhiDoctor,
  getDoctorSlots,
}) => {
  const { handleClick } = useContext(ToastContext);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [timeSlot, setTimeSlot] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (doctorDetail?.docId) {
      slots = [];
    }
  }, [doctorDetail?.docId]);

  const handleTimeSlotClick = (timeSlot) => {
    const now = dayjs();
    const nowString = now.format(DATE_FORMAT);
    const currentTime = now.format(HOUR_MINUTE_FORMAT);
    if (timeSlot?.date === nowString && currentTime > timeSlot?.slotTime) {
      handleClick("error", "Sorry! Selected time is passed, Please book another slot");
      return;
    }

    setisModalOpen(true);
    setTimeSlot(timeSlot);
    let appointment;
    if (isUhiDoctor) {
      appointment = {
        ...doctorDetail,
        appointmentDate: timeSlot.date,
        appointmentTime: timeSlot.slotTime,
        patientId: roleBasedId,
        notes: "",
        type: TELE_CONSULTATION,
        fulfillmentId: timeSlot.fulfillmentId,
        slotDuration: timeSlot.slotDuration,
        endTime: dayjs(timeSlot.slotTime, HOUR_MINUTE_FORMAT)
          .add(timeSlot.slotDuration, "minute")
          .format(HOUR_MINUTE_FORMAT),
      };
    } else {
      appointment = {
        isUhiDoctor: isUhiDoctor,
        orgId: timeSlot?.organizationId,
        appointmentDate: timeSlot.date,
        appointmentTime: timeSlot.slotTime,
        patientId: roleBasedId,
        doctorId: doctorDetail?.docId,
        notes: "",
        type:
          doctorDetail?.doctorType === UHI
            ? TELE_CONSULTATION
            : timeSlot.consultationType === BOTH
            ? CLINIC_VISIT
            : timeSlot.consultationType,
      };
    }
    dispatch(setSelectedAppointmentData(appointment));
  };

  const handleCloseModal = async () => {
    setisModalOpen(false);
    // if (isUhiDoctor) {
    // } else {
    //   await getDoctorSlots(doctorDetail, dayjs(date).format(DATE_FORMAT));
    // }
  };

  const [reschedule, setReschedule] = useState(false);

  const handleModalClose = () => {
    setReschedule(false);
  };
  return (
    <>
      {(!slots || slots?.length === 0) && (
        <Grid
          sx={{
            overflowY: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          maxHeight={"80px"}
          container
        >
          {" "}
          <Typography sx={{ mt: 2 }}>No slots available</Typography>
        </Grid>
      )}
      {slots && (
        <Grid
          className="custom-scrollbar"
          sx={{
            overflowY: "auto",
            msOverflowStyle: "none",
          }}
          maxHeight={"80px"}
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {slots.map((timeSlot, index) => {
            return (
              <Grid key={index} sx={{ marginRight: "5px", marginBottom: "5px" }}>
                <CustomButton
                  label={`${convertTimeForTimeFormat(timeSlot.slotTime)}`}
                  className={"btn--primary-light"}
                  style={{ width: "23%", padding: "0px", height: "22px" }}
                  disabled={timeSlot.disabled}
                  onClick={() => handleTimeSlotClick(timeSlot)}
                  fontSize={"12px"}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <ModalUI
        timeSlot={timeSlot}
        visible={isModalOpen}
        close={() => {
          dispatch(setSelectedAppointmentData({}));
          setisModalOpen(false);
        }}
        title="Book Appointment"
        style={{
          overflowY: "auto",
          height: "550px",
          width: "610px",
        }}
        component={
          <BookAppointmentModal
            setReschedule={setReschedule}
            closeModal={handleCloseModal}
            uhiDoctorData={uhiDoctorData}
            doctorDetail={doctorDetail}
          />
        }
      />

      <ModalUI
        visible={reschedule}
        title={"Appointment already exists for same day or same time."}
        close={handleModalClose}
        component={
          <>
            <Grid sx={{ marginTop: "30px" }}>
              <Grid sx={{ mt: 1 }} style={{ display: "flex", justifyContent: "center" }}>
                <CustomButton
                  label="Manage Appointments"
                  className="btn--secondary"
                  startIcon={<TodayOutlined />}
                  onClick={() => {
                    setReschedule(false);
                    navigate(`/home/patientAppointments`);
                  }}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
