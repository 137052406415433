import { api as API } from "./AxiosInterceptor";

// billing invoices apis
export const getBillingInvoiceById = (invoiceId) => API.get(`/api/billing/invoices/${invoiceId}`);
export const generateBillingInvoices = (data) => API.post(`/api/billing/invoices`, data);

// medical service categories Apis
export const getMedicalServiceCategories = () => API.get("/api/billing/medical-service-categories");
export const createMedicalServiceCategories = (data) =>
  API.post("/api/billing/medical-service-categories", data);
export const updateMedicalServiceCategories = (id, data) =>
  API.put(`/api/billing/medical-service-categories/${id}`, data);
export const deleteMedicalServiceCategories = (id) =>
  API.delete(`/api/billing/medical-service-categories/${id}`);

// tax rate apis
export const getTaxRates = () => API.get("/api/billing/tax-rates");
export const createTaxRates = (data) => API.post("/api/billing/tax-rates", data);
export const updateTaxRates = (id, data) => API.put(`/api/billing/tax-rates/${id}`, data);
export const deleteTaxRates = (id) => API.delete(`/api/billing/tax-rates/${id}`);

// medical ervice Apis
export const getMedicalServices = () => API.get(`/api/billing/medical-services`);
export const createMedicalServices = (data) => API.post(`/api/billing/medical-services`, data);
export const updateMedicalServices = (id, data) =>
  API.put(`/api/billing/medical-services/${id}`, data);
export const deleteMedicalServices = (id) => API.delete(`/api/billing/medical-services/${id}`);

// invoice pdf

export const getInvoicePDFById = (invoiceId, appointmentId) =>
  API.get(`/api/billing/invoices/generate-pdf`, {
    responseType: "blob",
    params: { invoiceId, appointmentId },
  });

// medical services by owner-type
export const getServiceConfiguration = (organizationId, doctorId, ownerType) =>
  API.get(`/api/billing/medical-services/owner-type`, {
    params: { organizationId, doctorId, ownerType },
  });
