import { startsAndEndsWithAlphabetic, startsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const roleSchema = Yup.object().shape({
  roleId: Yup.string().required("Required"),
});

export const step0 = Yup.object().shape({
  fullName: Yup.string()
    .required("Required")
    .min(3, "Minimum 3 characters required")
    .max(50, "Maximum 100 characters allowed")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    )
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space"),
});

export const step1 = Yup.object().shape({
  gender: Yup.string().required("Required"),
});

export const step2 = Yup.object().shape({});

export const step3 = Yup.object().shape({
  emailId: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .max(255, "Too long contact email")
    .required("email is required"),
});

export const step4 = Yup.object().shape({
  doctorRegistration: Yup.object().shape({
    registrationCouncil: Yup.string().required("Required"),
    registrationId: Yup.string().required("Required"),
  }),
});

export const step5 = Yup.object().shape({
  organization: Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .min(3, "Minimum 3 characters required")
      .max(100, "Too long organization name")
      .test(
        "contains-alphabetic",
        "Hospital name must start and end with alphabet",
        startsAndEndsWithAlphabetic
      )
      .matches(/^[a-zA-Z0-9 .,'-]*$/, "Only letters, numbers, spaces, and .,'- are allowed"),
    address: Yup.string()
      .required("Required")
      .min(3, "Minimum 3 characters allowed")
      .max(256, "Maximum 256 characters allowed"),
    // adState: Yup.object().required("Required"),
    // adDistrict: Yup.object().required("Required"),
    adPincode: Yup.string()
      .matches(/^\d{6}$/, "Pincode must be of 6 digits") // Validates that it is a 6-digit number
      .required("Required"),
  }),
});
