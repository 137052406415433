const SkeletonRibCage = () => {
  return (
    <div className="custom-skeleton-rib-cage">
      <div className="group-1">
        <div className="rib-cage-left-rib-2 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-2.svg"
            className="body-part hover"
            alt="rib-cage-left-rib-2"
          ></img>
        </div>
        <div className="rib-cage-left-rib-3 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-3.svg"
            className="body-part hover"
            alt="rib-cage-left-rib-3"
          ></img>
        </div>
        <div className="rib-cage-left-rib-4 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-4.svg"
            className="body-part hover"
            alt="rib-cage-left-rib-4"
          ></img>
        </div>
        <div className="rib-cage-left-rib-5-10 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-5-10.svg"
            className="body-part hover"
            alt="rib-cage-left-rib-5-10"
          ></img>
        </div>
        <div className="rib-cage-left-rib-back-1">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-back-1.svg"
            alt="rib-cage-left-rib-back-1"
          ></img>
        </div>
        <div className="rib-cage-left-rib-back-2">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-back-2.svg"
            alt="rib-cage-left-rib-back-2"
          ></img>
        </div>
        <div className="rib-cage-left-rib-back-3">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-back-3.svg"
            alt="rib-cage-left-rib-back-3"
          ></img>
        </div>
        <div className="rib-cage-left-rib-back-4">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-back-4.svg"
            alt="rib-cage-left-rib-back-4"
          ></img>
        </div>
        <div className="rib-cage-left-rib-back-5">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-left-rib-back-5.svg"
            alt="rib-cage-left-rib-back-5"
          ></img>
        </div>
      </div>
      <div className="group-2">
        <div className="rib-cage-right-rib-2 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-2.svg"
            className="body-part hover"
            alt="rib-cage-right-rib-2"
          ></img>
        </div>
        <div className="rib-cage-right-rib-3 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-3.svg"
            className="body-part hover"
            alt="rib-cage-right-rib-3"
          ></img>
        </div>
        <div className="rib-cage-right-rib-4 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-4.svg"
            className="body-part hover"
            alt="rib-cage-right-rib-4"
          ></img>
        </div>
        <div className="rib-cage-right-rib-5-10 body-part-container">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-5-10.svg"
            className="body-part hover"
            alt="rib-cage-right-rib-5-10"
          ></img>
        </div>
        <div className="rib-cage-right-rib-back-1">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-back-1.svg"
            alt="rib-cage-right-rib-back-1"
          ></img>
        </div>
        <div className="rib-cage-right-rib-back-2">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-back-2.svg"
            alt="rib-cage-right-rib-back-2"
          ></img>
        </div>
        <div className="rib-cage-right-rib-back-3">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-back-3.svg"
            alt="rib-cage-right-rib-back-3"
          ></img>
        </div>
        <div className="rib-cage-right-rib-back-4">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-back-4.svg"
            alt="rib-cage-right-rib-back-4"
          ></img>
        </div>
        <div className="rib-cage-right-rib-back-5">
          <img
            src="/images/human-skeleton/human-rib-cage/rib-cage-right-rib-back-5.svg"
            alt="rib-cage-right-rib-back-5"
          ></img>
        </div>
      </div>

      <div className="rib-cage-left-cervical-bone body-part-container">
        <img
          src="/images/human-skeleton/human-rib-cage/rib-cage-left-cervical-bone.svg"
          className="body-part hover"
          alt="rib-cage-left-cervical-bone"
        ></img>
      </div>
      <div className="rib-cage-right-cervical-bone body-part-container">
        <img
          src="/images/human-skeleton/human-rib-cage/rib-cage-right-cervical-bone.svg"
          className="body-part hover"
          alt="rib-cage-right-cervical-bone"
        ></img>
      </div>
      <div className="rib-cage-sternum body-part-container">
        <img
          src="/images/human-skeleton/human-rib-cage/rib-cage-sternum.svg"
          className="body-part hover"
          alt="rib-cage-sternum"
        ></img>
      </div>
      <div className="rib-cage-manubrium body-part-container">
        <img
          src="/images/human-skeleton/human-rib-cage/rib-cage-manubrium.svg"
          className="body-part hover"
          alt="rib-cage-manubrium"
        ></img>
      </div>
      <div className="rib-cage-left-floating-rib body-part-container">
        <img
          src="/images/human-skeleton/human-rib-cage/rib-cage-left-floating-rib.svg"
          className="body-part hover"
          alt="rib-cage-left-floating-rib"
        ></img>
      </div>
      <div className="rib-cage-right-floating-rib body-part-container">
        <img
          src="/images/human-skeleton/human-rib-cage/rib-cage-right-floating-rib.svg"
          className="body-part hover"
          alt="rib-cage-right-floating-rib"
        ></img>
      </div>
    </div>
  );
};
export default SkeletonRibCage;
