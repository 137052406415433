import { api as API } from "./AxiosInterceptor";

// healthId creation APIs

// aadhaar apis
// export const generateAbdmAadhaarOtp = (data) =>
//   API.post(`/api/abdm/healthid/create/generate-aadhaar-otp`, data);
export const generateAbdmAadhaarOtp = (data) => API.post(`/api/abha/request-otp`, data);
// export const verifyAbdmAadhaarOtp = (data) =>
//   API.post(`/api/abdm/healthid/create/verify-aadhaar-otp`, data);
// export const verifyAbdmAadhaarOtp = (data) => API.post(`/api/abha/create`, data);
export const verifyAbdmAadhaarOtp = (patientId, data) =>
  API.post(`/api/abha/create?patientId=${patientId}`, data);
export const resendAbdmAadhaarOtp = (data) =>
  API.post(`/api/abdm/healthid/create/resend-aadhaar-otp`, data);

//mobile related apis
export const generateAbdmMobileOtp = (data) =>
  API.post(`/api/abdm/healthid/create/generate-mobile-otp`, data);
export const verifyAndGenerateAbdmMobileOtp = (data) =>
  API.post(`/api/abdm/healthid/create/check-and-generate-mobile-otp`, data);
// export const verifyAbdmMobileOtp = (data) =>
//   API.post(`/api/abdm/healthid/create/verify-mobile-otp`, data);
export const updateAbdmDetail = (patientId, data) =>
  API.post(`/api/abha/update?patientId=${patientId}`, data);

// creating health Id
export const createHealthIdWithPreVerified = (patientId, data) =>
  API.post(
    `/api/abdm/healthid/create/create-health-id-with-pre-verified?patientId=${patientId}`,
    data
  );

// generate abha token
export const getAbhaCard = (token) => {
  return API.get(`/api/abdm/healthid/account/get-abha-card`, {
    params: { token },
    responseType: "arraybuffer",
  });
};

// GET ABHA APIs:
export const verifyGetAbhaOtpViaMobile = (data, patientId) =>
  API.post(`/api/abha/verify-via-mobile`, data, {
    params: {
      patientId,
    },
  });

export const verifyGetAbhaOtpViaAbhaEntities = (data, patientId) =>
  API.post(`/api/abha/verify-via-abha`, data, {
    params: {
      patientId,
    },
  });

export const getLinkedAbha = (patientId) =>
  API.get(`/api/abha/get-abha-card`, {
    params: {
      patientId,
    },
    responseType: "arraybuffer",
  });
