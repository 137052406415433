import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  medicationsArr: [],
  symptomsArr: [],
  diagnosisArr: [],
  labInvestigationsArr: [],
  advicesArr: [],
  prescriptionId: null,
};

const prescriptionPadSlice = createSlice({
  name: "prescriptionPad",
  initialState,
  reducers: {
    setPrescriptionPadData(state, action) {
      state.medicationsArr = action.payload.medicationsArr;
      state.symptomsArr = action.payload.symptomsArr;
      state.diagnosisArr = action.payload.diagnosisArr;
      state.labInvestigationsArr = action.payload.labInvestigationsArr;
      state.advicesArr = action.payload.advicesArr;
    },
    setMedications(state, action) {
      state.medicationsArr = state.medicationsArr.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    setSymptoms(state, action) {
      state.symptomsArr = state.symptomsArr?.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    setDiagnosis(state, action) {
      state.diagnosisArr = state.diagnosisArr.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    setLabInvestigations(state, action) {
      state.labInvestigationsArr = state.labInvestigationsArr.map((item, i) =>
        i === action.payload.index
          ? { ...item, [action.payload.field]: action.payload.value }
          : item
      );
    },
    setAdvices(state, action) {
      state.advicesArr = state.advicesArr.map((item, i) =>
        i === action.payload.index ? { ...item, displayName: action.payload.value } : item
      );
    },
    setPrescriptionId(state, action) {
      state.prescriptionId = action.payload;
    },
  },
});

export const {
  setPrescriptionPadData,
  setMedications,
  setSymptoms,
  setDiagnosis,
  setLabInvestigations,
  setAdvices,
  setPrescriptionId,
} = prescriptionPadSlice.actions;

export const getPrescriptionPadData = (state) => state.prescriptionPad;
export const getFieldData = (state, key) => state.prescriptionPad[key];
export const getSymptoms = (state) => state.prescriptionPad.symptomsArr;
export const getDiagnosis = (state) => state.prescriptionPad.diagnosisArr;
export const getLabInvestigations = (state) => state.prescriptionPad.labInvestigationsArr;
export const getAdvices = (state) => state.prescriptionPad.advicesArr;
export const getPrescriptionId = (state) => state.prescriptionPad.prescriptionId;

export const prescriptionPadReducer = prescriptionPadSlice.reducer;
