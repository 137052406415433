import React from "react";
import { Modal, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "./custom-components/CustomButton";
import Reveal from "views/utilities/Reveal";

const ModalUI = ({ visible, title, close, style, closeButtonStylling, component }) => {
  return (
    <Modal
      open={visible}
      onClose={() => close(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(2px)",
      }}
    >
      <Card
        style={{
          width: "max-content",
          maxWidth: "90%",
          padding: "30px",
          margin: "auto",
          position: "relative",
          borderRadius: "10px",
          ...style,

          scrollbarWidth: "thin",
          scrollbarColor: "#398bb2 transparent",
        }}
      >
        <style>
          {`
      .MuiCard-root::-webkit-scrollbar {
        width: 4px;
      }

      .MuiCard-root::-webkit-scrollbar-thumb {
        background-color: #398bb2;
        border-radius: 10px;  
      }

      .MuiCard-root::-webkit-scrollbar-track {
        background: transparent;
      }
    `}
        </style>
        <Reveal>
          <Grid container alignItems={"center"}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              {title}
            </Typography>
            <CustomButton
              onClick={() => close(false)}
              iconButton={<CloseIcon></CloseIcon>}
              style={{ marginLeft: "auto", ...closeButtonStylling }}
            ></CustomButton>
          </Grid>
          {component}
        </Reveal>
      </Card>
    </Modal>
  );
};

export default ModalUI;
