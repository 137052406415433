import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { updateLanguages } from "services/EntitiesServices";
import { languageSchema } from "../../Common/ValidationSchema/languageValidation";

const UpdateLanguage = ({ selected, close }) => {
  const [value, setValue] = useState(selected?.name);
  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);
  const [error, setError] = useState("");
  const [languageTouched, setLanguageTouched] = useState(false);

  const validateLanguage = async (language) => {
    try {
      await languageSchema.validate({ language });
      setError("");
    } catch (e) {
      setError(e.message);
    }
  };

  const UpdateLanguage = async () => {
    await validateLanguage(value);
    if (errors) {
      handleClick("error", error);
    } else {
      setErrors({});

      if (selected) {
        const data = {
          id: selected.id,
          name: value,
        };
        try {
          await updateLanguages(selected.id, data);
          close();
          handleClick("success", "Language updated successfully!");
        } catch (e) {
          handleClick("error", "Error updating language");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Language
      </Typography>
      <FormInputField
        style={{ marginTop: "20px" }}
        label={"Language"}
        value={value}
        error={Boolean(error)}
        errorText={error}
        onBlur={(e) => {
          setLanguageTouched(true);
          validateLanguage(e.target.value);
        }}
        onChange={(e) => {
          setValue(e.target.value);
          if (languageTouched) {
            validateLanguage(e.target.value);
          }
        }}
        startAdornment={<DriveFileRenameOutlineOutlined />}
        size="small"
      />
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton
          className="btn--primary"
          onClick={UpdateLanguage}
          label="Update"
          disabled={value === ""}
        />
      </Box>
    </Box>
  );
};

export default UpdateLanguage;
