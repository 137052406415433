import { Menu, MenuList, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getProfilePicture } from "services/patientService";
import {
  CLINIC_VISIT,
  convertTimeForTimeFormat,
  currentActiveUser,
  DATE_FORMAT_DMY,
  FEMALE,
  TELE_CONSULTATION,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
} from "store/constant";
import CustomButton from "./CustomButton";
import CustomMenuItem from "./CustomMenuItem";
import { useNavigate } from "react-router";
import { calculateAge } from "utils/calculate-age";
import { VisibilityOutlined } from "@mui/icons-material";

const RecentVisitItem = ({
  appointment,
  setPrescriptionAppointmentId,
  setPrescriptionModalOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const currentUserRole = currentActiveUser()?.roleName;
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const actions = [
    {
      label: "View Prescription",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: (appointmentDetail) =>
        navigate("/home/myPrescription/" + appointmentDetail.appointmentId),
    },
    {
      label: "View PDF",
      icon: <i className="ri-eye-line ri-xl icon-primary-blue" />,
      onClick: (appointmentDetail) =>
        navigate("/home/prescription/" + appointmentDetail.appointmentId),
    },
  ];

  const today = dayjs();
  const tomorrow = today.add(1, "day");
  const [profileImage, setProfileImage] = useState(null);
  const genderBasedImage = appointment?.gender === FEMALE ? "/images/woman.png" : "/images/man.png";

  let appointmentDay;
  if (dayjs(appointment?.appointmentDate).isSame(today, "day")) {
    appointmentDay = "Today";
  } else if (dayjs(appointment?.appointmentDate).isSame(tomorrow, "day")) {
    appointmentDay = "Tomorrow";
  } else {
    appointmentDay = dayjs(appointment?.appointmentDate).format(DATE_FORMAT_DMY);
  }

  const fetchProfilePicture = async () => {
    try {
      setIsLoading(true);
      const profilePicResponse = await getProfilePicture(
        currentUserRole === "PATIENT" ? appointment?.doctorUserId : appointment?.patientUserId
      );
      const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;

      setProfileImage({
        filename: profilePicResponse.data.filename,
        content,
      });
    } catch (error) {
      console.error("Error fetching profile picture", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfilePicture();
  }, [appointment]);

  return (
    <li
      className="mui-card list-card"
      style={{
        marginBottom: "10px",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      <div className="w-100">
        <div style={{ display: "flex", alignItems: "center" }}>
          {isLoading ? (
            <Skeleton
              sx={{ marginRight: "15px" }}
              variant="circular"
              animation="wave"
              width={"85px"}
              height={"60px"}
            />
          ) : (
            <img
              style={{
                borderRadius: "50%",
                width: "60px",
                height: "60px",
                marginRight: "15px",
              }}
              className="patient-avatar"
              src={profileImage ? profileImage?.content : genderBasedImage}
              alt=""
            />
          )}
          <div className="w-100" style={{ paddingRight: "20px" }}>
            <p
              className="patient-name"
              style={{ fontSize: "18px", fontWeight: "600", margin: "0", color: "#333" }}
            >
              {currentUserRole === "PATIENT"
                ? `Dr. ${appointment?.doctorName}`
                : appointment?.patientName}
            </p>
            <p
              className="patient-details"
              style={{ fontSize: "14px", color: "#777", margin: "4px 0 0" }}
            >
              {currentUserRole === "PATIENT" ? (
                <span>{appointment?.organizationName}</span>
              ) : (
                <>
                  <span>{calculateAge(appointment?.dateOfBirth)} years</span>
                  <span> | </span>
                  <span>{appointment.gender ? appointment.gender : "N/A"}</span>
                </>
              )}
            </p>
          </div>
          {currentUserRole === "PATIENT" && (
            <>
              <CustomButton
                iconButton={<i className="ri-more-2-fill" />}
                onClick={(event) => handleMenuOpen(event)}
                aria-label="Actions"
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "15px",
                  padding: "0px",
                }}
              />
              <Menu
                elevation={1}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <MenuList dense sx={{ p: 0 }}>
                  {actions.map((action, actionIndex) => (
                    <CustomMenuItem
                      key={actionIndex}
                      onClick={() => {
                        handleMenuClose();
                        action.onClick(appointment);
                      }}
                      icon={action.icon}
                      text={action.label}
                      style={{ padding: "12px 16px", fontSize: "14px", color: "#333" }}
                    />
                  ))}
                </MenuList>
              </Menu>
            </>
          )}
        </div>
        <div
          className="mt-3 w-100"
          style={{
            borderTop: "1px solid #e0e0e0",
            paddingTop: "8px",
            marginTop: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {appointment?.type === CLINIC_VISIT && (
            <div
              className="visit-type-offline"
              style={{
                display: "flex",
                alignItems: "center",
                color: "#4caf50",
                fontSize: "14px",
              }}
            >
              <i className="ri-walk-fill ri-lg" style={{ marginRight: "8px" }} />
              {TEXT_FOR_CLINIC_VISIT}
            </div>
          )}
          {appointment?.type === TELE_CONSULTATION && (
            <div
              className="visit-type-online"
              style={{
                display: "flex",
                alignItems: "center",
                color: "#2196f3",
                fontSize: "14px",
              }}
            >
              <img
                src="/images/hc_online_icon.svg"
                className="online"
                alt="online"
                style={{ marginRight: "8px" }}
              />
              {TEXT_FOR_TELECONSULTATION}
            </div>
          )}
          <div style={{ color: "grey" }}>|</div>
          <div style={{ fontSize: "14px", color: "#333" }}>
            {convertTimeForTimeFormat(appointment?.appointmentTime)}
          </div>
          <div style={{ color: "grey" }}>|</div>
          <div style={{ fontSize: "14px", color: "#333" }}>{appointmentDay}</div>
        </div>
        <div
          className="mt-3 w-100"
          style={{
            borderTop: "1px solid #e0e0e0",
            paddingTop: "8px",
            marginTop: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CustomButton
            className={"btn--primary-light"}
            height="32px"
            label={"View EHR"}
            style={{ padding: "0px 27.5px" }}
            gap={"0px"}
            startIcon={<VisibilityOutlined />}
            onClick={() => {
              navigate(`/home/ehr/${appointment?.patientId}`);
            }}
          />
          <CustomButton
            className={"btn--primary-light"}
            height="32px"
            label={"View Prescription"}
            style={{ padding: "0px 15px" }}
            gap={"0px"}
            startIcon={<VisibilityOutlined />}
            onClick={() => {
              setPrescriptionAppointmentId(appointment?.appointmentId);
              setPrescriptionModalOpen(true);
            }}
          />
        </div>
      </div>
    </li>
  );
};

export default RecentVisitItem;
