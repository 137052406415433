const SkeletonRightLeg = () => {
  return (
    <div className="custom-skeleton-right-leg">
      <div className="group-1">
        {/* todo:image  */}
        <div className="right-leg-foot body-part-container">
          <img
            src="/images/human-skeleton/human-right-leg/right-leg-foot.svg"
            className="body-part hover"
            alt="right-leg-foot"
          ></img>
        </div>
      </div>
      <div className="group-2">
        <div className="right-leg-femur body-part-container">
          <img
            src="/images/human-skeleton/human-right-leg/right-leg-femur.svg"
            className="body-part hover"
            alt="right-leg-femur"
          ></img>
        </div>
        <div className="right-leg-tibia body-part-container">
          <img
            src="/images/human-skeleton/human-right-leg/right-leg-tibia.svg"
            className="body-part hover"
            alt="right-leg-tibia"
          ></img>
        </div>
        <div className="right-leg-patella body-part-container">
          <img
            src="/images/human-skeleton/human-right-leg/right-leg-patella.svg"
            className="body-part hover"
            alt="right-leg-patella"
          ></img>
        </div>
        <div className="right-leg-fibula body-part-container">
          <img
            src="/images/human-skeleton/human-right-leg/right-leg-fibula.svg"
            className="body-part hover"
            alt="right-leg-fibula"
          ></img>
        </div>
        <div className="right-leg-tarsus body-part-container">
          <img
            src="/images/human-skeleton/human-right-leg/right-leg-tarsus.svg"
            className="body-part hover"
            alt="right-leg-tarsus"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default SkeletonRightLeg;
