import { api as API } from "./AxiosInterceptor";

// patients Apis
export const getAllPatients = () => API.get(`/api/patients`);
export const getPatientData = (id) => API.get(`/api/patients/${id}`);
export const createPatient = (data) => API.post(`/api/patients`, data);
export const deletePatientData = (id) => API.delete(`/api/patients/${id}`);
export const setPatientData = (patientId, data) => API.put(`/api/patients/${patientId}`, data);

export const getPatientDetails = (url, id) => API.get(`${url}/${id}`);

// patient by user id
export const getPatientByUserId = (userId) => API.get(`/api/patients/user/${userId}`);

//patient by mobile number
export const getPatientByMobileNumber = (mobileNumber) =>
  API.get(`/api/patients/mobilenumber/${mobileNumber}`);

// patient by doctor/organization id
export const getPatientsByDoctorOrOrgId = (orgId, doctorId) => {
  return API.get(`/api/appointments/query`, {
    params: {
      orgId: orgId,
      doctorId: doctorId,
    },
  });
};

// Patient allergy
export const getPatientAllergyByPatientId = (patientId) =>
  API.get(`/api/patient/allergies/patient/${patientId}`);
export const savePatientAllergies = (data) => API.post(`/api/patient/allergies`, data);
export const editPatientAllergies = (id, data) => API.put(`/api/patient/allergies/${id}`, data);
export const deletePatientAllergy = (id) => API.delete(`/api/patient/allergies/${id}`);
export const getPatientAllergyById = (id) => API.get(`/api/patient/allergies/${id}`);

export const createPatientLifeStyle = (data) => API.post(`/api/patient/lifestyle-responses`, data);
export const updatePatientLifeStyle = (id, data) =>
  API.put(`/api/patient/lifestyle-responses/${id}`, data);
export const getLifestyleQuestions = () => API.get(`/api/lifestyle-questions`);
export const getPatientLifestyleByPatientId = (patientId) =>
  API.get(`/api/patient/lifestyle-responses/patient/${patientId}`);

// Patient chronic-disease
export const createPatientMedicalConditions = (data) =>
  API.post(`/api/patient/chronic-diseases`, data);
export const editPatientMedicalConditions = (id, data) =>
  API.put(`/api/patient/chronic-diseases/${id}`, data);
export const getPatientChronicDiseaseByPatientId = (patientId) =>
  API.get(`/api/patient/chronic-diseases/patient/${patientId}`);
export const deleteChronicDiesease = (id) => API.delete(`/api/patient/chronic-diseases/${id}`);
export const getPatientChronicDieseaseById = (id) => API.get(`/api/patient/chronic-diseases/${id}`);

// Patient family-history
export const createPatientFamilyHistory = (data) => API.post(`/api/patient/family-histories`, data);
export const editPatientFamilyHistory = (id, data) =>
  API.put(`/api/patient/family-histories/${id}`, data);
export const deletePatientFamilyHistory = (id) => API.delete(`/api/patient/family-histories/${id}`);
export const getPatientFamilyHistoryByPatientId = (patientId) =>
  API.get(`/api/patient/family-histories/patient/${patientId}`);
export const getPatientFamilyHistoryById = (id) => API.get(`/api/patient/family-histories/${id}`);

export const getPatientFamilyHistory = (id) => API.get(`/api/chronic-diseases/patient/${id}`);

// Patient past procedure
export const createPatientPastProcedures = (data) => API.post(`/api/patient/past-procedures`, data);
export const editPatientPastProcedures = (id, data) =>
  API.put(`/api/patient/past-procedures/${id}`, data);
export const deletePatientPastProcedures = (id) => API.delete(`/api/patient/past-procedures/${id}`);
export const getPatientPastProceduresByPatientId = (patientId) =>
  API.get(`/api/patient/past-procedures`, { params: { patientId: patientId } });
export const getPatientPastProcedureById = (id) => API.get(`/api/patient/past-procedures/${id}`);

// Patient Immunization
export const createPatientImmunization = (data) => API.post(`/api/patient/immunizations`, data);
export const editPatientImmunization = (id, data) =>
  API.put(`/api/patient/immunizations/${id}`, data);
export const deletePatientImmunization = (id) => API.delete(`/api/patient/immunizations/${id}`);
export const getPatientImmunizationByPatientId = (patientId) =>
  API.get(`/api/patient/immunizations/patient/${patientId}`);
export const getPatientImmunizationById = (id) => API.get(`/api/patient/immunizations/${id}`);

// Patient Emergency contact
export const createPatientEmergencyContact = (data) =>
  API.post(`/api/patient/emergency-contacts`, data);
export const editPatientEmergencyContact = (id, data) =>
  API.put(`/api/patient/emergency-contacts/${id}`, data);
export const deletePatientEmergencyContact = (id) =>
  API.delete(`/api/patient/emergency-contacts/${id}`);
export const getPatientEmergencyContactByPatientId = (patientId) =>
  API.get(`/api/patient/emergency-contacts/patient/${patientId}`);
export const getPatientEmergencyContactById = (id) =>
  API.get(`/api/patient/emergency-contacts/${id}`);

// Patient Insurance Information
export const createPatientInsuranceInfo = (data) =>
  API.post(`/api/patient/insurance-informations`, data);
export const editPatientInsuranceInfo = (id, data) =>
  API.put(`/api/patient/insurance-informations/${id}`, data);
export const deletePatientInsuranceInfo = (id) =>
  API.delete(`/api/patient/insurance-informations/${id}`);
export const getPatientInsuranceInfoByPatientId = (patientId) =>
  API.get(`/api/patient/insurance-informations/patient/${patientId}`);
export const getPatientInsuranceInfoById = (id) =>
  API.get(`/api/patient/insurance-informations/${id}`);

// Patient Physical exam
export const createPatientPhysicalExam = (data) => API.post(`/api/patient/physical-exams`, data);
export const editPatientPhysicalExam = (id, data) =>
  API.put(`/api/patient/physical-exams/${id}`, data);
export const deletePatientPhysicalExam = (id) => API.delete(`/api/patient/physical-exams/${id}`);
export const getPatientPhysicalExamByPatientId = (patientId) =>
  API.get(`/api/patient/physical-exams/patient/${patientId}`);
export const getPatientPhysicalExamById = (id) => API.get(`/api/patient/physical-exams/${id}`);

// Patient Treatment plan
export const createPatientTreatmentPlan = (data) => API.post(`/api/patient/treatment-plans`, data);
export const editPatientTreatmentPlan = (id, data) =>
  API.put(`/api/patient/treatment-plans/${id}`, data);
export const deletePatientTreatmentPlan = (id) => API.delete(`/api/patient/treatment-plans/${id}`);
export const getPatientTreatmentPlanByPatientId = (patientId) =>
  API.get(`/api/patient/treatment-plans/patient/${patientId}`);
export const getPatientTreatmentPlanById = (id) => API.get(`/api/patient/treatment-plans/${id}`);

// Patient Family Members
export const getPatientFamilyDetails = (familyId) =>
  API.get(`/api/users/family-users?familyId=${familyId}`);

// Patient Vitals
export const createPatientVital = (data) => API.post(`/api/patient/vitals`, data);
export const getPatientVitalById = (id) => API.get(`/api/patient/vitals/${id}`);
export const editPatientVital = (id, data) => API.put(`/api/patient/vitals/${id}`, data);
export const deletePatientVital = (id) => API.delete(`/api/patient/vitals/${id}`);
export const getPatientVitalByPatientId = (patientId) =>
  API.get(`/api/patient/vitals/patient/${patientId}`);
export const getPatientLatestVitalByPatientId = (patientId) =>
  API.get(`/api/patient/vitals/latest/${patientId}`);

export const uploadProfilePicture = (userId, file) =>
  API.post(`/api/users/${userId}/uploadProfilePicture`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getProfilePicture = (userId) => API.get(`/api/users/${userId}/profilePicture`);

// Patient documents
export const uploadPatientDocuments = (data) =>
  API.post(`/api/patient/documents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getPatientDocumentById = (id) => API.get(`/api/patient/documents/${id}`);
export const deletePatientDocumentByDocumentId = (documentId) =>
  API.put(`/api/patient/documents/delete/${documentId}`);
export const getPatientDocumentByDocumentId = (documentId) =>
  API.get(`/api/patient/documents/document/${documentId}`);
export const getPatientDocumentByPatientId = (patientId) =>
  API.get(`/api/patient/documents/patient/${patientId}`);

// family member verification if age >= 18 and <= 60

export const getOtpForFamilyMember = (contactField, verificationType) =>
  API.post(`hc-auth/verification/init`, {
    contactField: contactField,
    verificationType: verificationType,
  });

export const verifyOtpForFamilyMember = (contactField, verificationType, otp) =>
  API.post("hc-auth/verification/complete", {
    contactField: contactField,
    verificationType: verificationType,
    otp: otp,
  });
// Patient family doctor
export const getFamilyDoctor = (familyId) =>
  API.get(`/api/users/family-doctor?familyId=${familyId}`);
export const addFamilyDoctor = (primaryUserId, doctorId) =>
  API.post(`/api/users/add-doctor`, null, { params: { doctorId, primaryUserId } });
export const removeFamilyDoctor = (familyId, doctorId) =>
  API.delete(`/api/users/remove-doctor`, { params: { doctorId, familyId } });
