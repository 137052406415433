import { FiberPinOutlined, HomeOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { FormControl, Grid } from "@mui/material";
import { useRef } from "react";
import { fetchLocationByPincode } from "services/organizationService";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";

const OnboardingStep5 = ({
  handleBlur,
  handleChange,
  values,
  setValues,
  hfrResponse,
  errors,
  touched,
  setFieldValue,
  pincodeError,
  setPincodeError,
  setTouched,
}) => {
  const handlePincodeChange = async (e) => {
    const { value } = e.target;
    setPincodeError("");
    if (value.length === 6) {
      const response = await fetchLocationByPincode(value);

      if (response.data.length !== 0 && response.data[0].id) {
        const stateOb = {
          name: response.data[0].stateName.toUpperCase(),
          code: response.data[0].stateCode,
        };

        const districtOb = {
          name: response.data[0].districtName.toUpperCase(),
          code: response.data[0].districtCode,
        };

        setFieldValue("organization.adState", stateOb);
        setFieldValue("organization.adDistrict", districtOb);
      } else {
        setFieldValue("organization.adState", null);
        setFieldValue("organization.adDistrict", null);
        setTouched({
          ...touched,
          organization: { ...touched.organization, adPincode: true },
        });
        setPincodeError("Please enter valid pincode");
      }
    }
    setFieldValue("organization.adPincode", value);
  };

  const districtRef = useRef(null);

  return (
    <>
      <div className="col-12 mb-3">
        <label
          style={{ marginBottom: "24px" }}
          htmlFor="inputTypeText"
          className="form-label onboarding-label"
        >
          Can you provide us with the clinic details ?
        </label>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} xl={12} md={6} sm={12}>
            <FormInputField
              //   value={name}
              style={{ width: "100%" }}
              startAdornment={<PersonOutlineOutlined />}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.organization.name}
              name={"organization.name"}
              // style={{maxWidth: '400px', height: '42.67px'}}
              size="small"
              border={"1px solid #e6eef1"}
              background={"#fff"}
              borderOnFocus={"1px solid #29bf91"}
              error={Boolean(touched?.organization?.name && errors?.organization?.name)}
              errorText={errors?.organization?.name}
              //   height={"64.43px"}
              inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
              placeholder={"Clinic name"}
            />
          </Grid>
          <Grid item xs={12} xl={12} md={6} sm={12}>
            <FormInputField
              //   value={name}
              style={{ width: "100%" }}
              startAdornment={<HomeOutlined />}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.organization.address}
              name={"organization.address"}
              readOnly={hfrResponse?.address ? true : false}
              // style={{maxWidth: '400px', height: '42.67px'}}
              size="small"
              border={"1px solid #e6eef1"}
              background={"#fff"}
              borderOnFocus={"1px solid #29bf91"}
              error={Boolean(touched?.organization?.address && errors?.organization?.address)}
              errorText={errors?.organization?.address}
              inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
              placeholder={"Address"}
            />
          </Grid>
          {!(errors?.organization?.adPincode || pincodeError) && values.organization.adState && (
            <Grid item xs={12} xl={12} md={6} sm={12}>
              <FormControl style={{ width: "100%" }}>
                <CustomAutoComplete
                  options={[]}
                  required
                  background={"#fff"}
                  readOnly={true}
                  placeholder="Select State"
                  inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
                  border={"1px solid #e6eef1"}
                  borderOnFocus={"1px solid #29bf91"}
                  height={"27.47px"}
                  size={"small"}
                  iconStart={<HomeOutlined />}
                  getOptionLabel={(option) => option?.name}
                  // onChange={async (event, newValue) => {
                  //   if (newValue) {
                  //     setValues({
                  //       ...values,
                  //       organization: {
                  //         ...values.organization,
                  //         adState: newValue,
                  //         adDistrict: null,
                  //       },
                  //     });
                  //     setDistricts(newValue.districts);
                  //     districtRef.current.focus();
                  //   } else if (newValue === null) {
                  //     setValues({
                  //       ...values,
                  //       organization: {
                  //         ...values.organization,
                  //         adState: newValue,
                  //         adDistrict: newValue,
                  //       },
                  //     });
                  //   }
                  // }}
                  value={values?.organization?.adState}
                  error={Boolean(touched?.organization?.adState && errors?.organization?.adState)}
                  errorText={errors?.organization?.adState}
                />
              </FormControl>
            </Grid>
          )}
          {!(errors?.organization?.adPincode || pincodeError) && values.organization.adDistrict && (
            <Grid item xs={12} xl={12} md={6} sm={12}>
              <FormControl style={{ width: "100%" }}>
                <CustomAutoComplete
                  options={[]}
                  // readOnly={values?.organization?.adState ? false : true}
                  readOnly={true}
                  placeholder="Select District"
                  required
                  iconStart={<HomeOutlined />}
                  getOptionLabel={(option) => option?.name}
                  // onChange={async (event, newValue) => {
                  //   if (newValue) {
                  //     setValues({
                  //       ...values,
                  //       organization: {
                  //         ...values.organization,
                  //         adDistrict: newValue,
                  //       },
                  //     });
                  //   } else if (newValue === null) {
                  //     setValues({
                  //       ...values,
                  //       organization: {
                  //         ...values.organization,
                  //         adDistrict: newValue,
                  //       },
                  //     });
                  //   }
                  // }}
                  background={"#fff"}
                  inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
                  border={"1px solid #e6eef1"}
                  borderOnFocus={"1px solid #29bf91"}
                  height={"27.47px"}
                  size={"small"}
                  openOnFocus={true}
                  value={values?.organization?.adDistrict}
                  error={Boolean(
                    touched?.organization?.adDistrict && errors?.organization?.adDistrict
                  )}
                  errorText={errors?.organization?.adDistrict}
                  inputRef={districtRef}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} xl={12} md={6} sm={12}>
            <FormInputField
              style={{ width: "100%" }}
              placeholder="Pincode"
              required
              value={values.organization.adPincode}
              readOnly={hfrResponse?.pincode ? true : false}
              name={"organization.adPincode"}
              inputProps={{
                maxLength: 6,
              }}
              type={"tel"}
              border={"1px solid #e6eef1"}
              background={"#fff"}
              borderOnFocus={"1px solid #29bf91"}
              inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
              onBlur={handleBlur}
              onChange={handlePincodeChange}
              error={Boolean(
                touched?.organization?.adPincode &&
                  (errors?.organization?.adPincode || pincodeError)
              )}
              errorText={errors?.organization?.adPincode || pincodeError}
              startAdornment={<FiberPinOutlined />}
              size={"small"}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OnboardingStep5;
