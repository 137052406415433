import {
  ClearOutlined,
  DisplaySettingsOutlined,
  PersonOutlineOutlined,
  PolicyOutlined,
  RoomPreferencesOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  createPatientInsuranceInfo,
  deletePatientInsuranceInfo,
  editPatientInsuranceInfo,
  getPatientInsuranceInfoByPatientId,
} from "services/patientService";
import { startsAndEndsWithAlphabetic, startsWithAlphabetic } from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "providerName", label: "Provider Name" },
  { field: "policyNumber", label: "Policy Number" },
  { field: "policyHolder", label: "Policy Holder" },
  { field: "coverageDetails", label: "Coverage details" },
  { field: "mobileNumber", label: "Mobile Number" },
  { field: "Actions", label: "Actions" },
];

const PatientInsuranceInfo = ({ patientId }) => {
  const [patientInsuranceInfos, setPatientInsuranceInfos] = useState([]);
  const [displayPatientInsuranceInfos, setDisplayPatientInsuranceInfos] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientInsuranceInfo(row.id);
      handleClick("success", "Patient Insurance has been successfully deleted.");
      const index = patientInsuranceInfos.findIndex((el) => el.id === row.id);
      patientInsuranceInfos.splice(index, 1);
      setDisplayPatientInsuranceInfos(patientInsuranceInfos);
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the Patient Insurance.");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pInsuranceInfo) => {
    setSelected(pInsuranceInfo);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchPatientInsuranceInfos = async () => {
    try {
      const response = await getPatientInsuranceInfoByPatientId(patientId);
      setPatientInsuranceInfos(response.data);
    } catch (error) {
      console.error("Error fetching patient insurance info");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    fetchPatientInsuranceInfos();
  }, []);

  useEffect(() => {
    setDisplayPatientInsuranceInfos(
      patientInsuranceInfos.filter((item) => {
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (typeof value === "string" && index !== 7) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return false;
        });
      })
    );
  }, [patientInsuranceInfos, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1.4 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search Insurance-info"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"ri-add-fill ri-lg btn--primary"}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomizedTable
        columns={columns}
        tableData={displayPatientInsuranceInfos}
        actions={actions}
        rowsPerPageInTable={10}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Insurance Info" : "Create Patient Insurance Info"}
        component={
          <PatientInsuranceInfoModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientInsuranceInfos();
              } else {
                closeModal();
              }
            }}
            patientId={patientId}
          />
        }
      />
    </Reveal>
  );
};

const PatientInsuranceInfoModal = ({ patientId, selected, handleClick, close }) => {
  const [providerName, setProviderName] = useState(
    selected?.providerName ? selected?.providerName : ""
  );
  const [providerNameTouched, setProviderNameTouched] = useState(false);

  const [policyNumber, setPolicyNumber] = useState(
    selected?.policyNumber ? selected?.policyNumber : ""
  );
  const [policyNumberTouched, setPolicyNumberTouched] = useState(false);

  const [policyHolder, setPolicyHolder] = useState(
    selected?.policyHolder ? selected?.policyHolder : ""
  );
  const [policyHolderTouched, setPolicyHolderTouched] = useState(false);

  const [coverageDetails, setCoverageDetails] = useState(
    selected?.coverageDetails ? selected?.coverageDetails : ""
  );
  const [coverageDetailsTouched, setCoverageDetailsTouched] = useState(false);

  const [mobileNumber, setMobileNumber] = useState(
    selected?.mobileNumber ? selected?.mobileNumber : ""
  );
  const [mobileNumberTouched, setMobileNumberTouched] = useState(false);

  const [errors, setErrors] = useState({});

  const validateProviderName = (errors, providerName) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!providerName) {
      errors.providerName = "Please enter a valid provider name.";
    } else if (providerName.length < 3) {
      errors.providerName = "Name must be at least 3 characters long";
    } else if (providerName.length > 100) {
      errors.providerName = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(providerName)) {
      errors.providerName = "Name can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(providerName)) {
      errors.providerName = "Name must start and end with alphabet";
    }
  };

  const validatePolicyNumber = (errors, policyNumber) => {
    const pattern = /^[a-zA-Z0-9]+$/;
    if (!policyNumber) {
      errors.policyNumber = "Please enter valid policy number.";
    } else if (policyNumber.length < 3) {
      errors.policyNumber = "Policy number must be at least 3 characters long";
    } else if (policyNumber.length > 100) {
      errors.policyNumber = "Policy number cannot be longer than 100 characters";
    } else if (!pattern.test(policyNumber)) {
      errors.policyNumber = "Policy number can only contain letters and numbers without space";
    }
  };

  const validatePolicyHolder = (errors, policyHolder) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!policyHolder) {
      errors.policyHolder = "Please enter policy holder name.";
    } else if (policyHolder.length < 3) {
      errors.policyHolder = "Name must be at least 3 characters long";
    } else if (policyHolder.length > 100) {
      errors.policyHolder = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(policyHolder)) {
      errors.policyHolder = "Name can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(policyHolder)) {
      errors.policyHolder = "Name must start and end with alphabet";
    }
  };

  const validateCoverageDetails = (errors, coverageDetails) => {
    if (!coverageDetails) {
      errors.coverageDetails = "Please enter coverage details";
    } else if (coverageDetails.length < 3) {
      errors.coverageDetails = "Details must be at least 3 characters long";
    } else if (coverageDetails.length > 255) {
      errors.coverageDetails = "Details cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(coverageDetails)) {
      errors.coverageDetails = "Details must start with alphabet";
    }
  };

  const validateMobileNumber = (errors, mobileNumber) => {
    if (!mobileNumber) {
      errors.mobileNumber = "Please enter a valid mobile number.";
    } else if (!/^[6-9][0-9]{9}$/.test(mobileNumber)) {
      errors.mobileNumber = "Please enter a valid mobile number.";
    }
  };

  const validate = () => {
    const errors = {};

    setProviderNameTouched(true);
    validateProviderName(errors, providerName);

    setPolicyNumberTouched(true);
    validatePolicyNumber(errors, policyNumber);

    setPolicyHolderTouched(true);
    validatePolicyHolder(errors, policyHolder);

    setMobileNumberTouched(true);
    validateMobileNumber(errors, mobileNumber);

    setCoverageDetailsTouched(true);
    validateCoverageDetails(errors, coverageDetails);

    return errors;
  };

  const saveInsuranceInfo = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        providerName,
        policyNumber,
        mobileNumber,
        policyHolder,
        coverageDetails,
      };

      if (selected) {
        try {
          await editPatientInsuranceInfo(selected.id, data);
          handleClick("success", "Patient insurance information has been successfully updated.");
          close("save");
        } catch (error) {
          handleClick(
            "error",
            "There seems to be an error updating the patient insurance information."
          );
        }
      } else {
        try {
          await createPatientInsuranceInfo(data);
          handleClick("success", "Patient insurance information has been successfully created.");
          close("save");
        } catch (error) {
          handleClick(
            "error",
            "There seems to be an error creating the patient insurance information."
          );
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Provider Name"
            name="providerName"
            required
            value={providerName}
            onBlur={(e) => {
              setProviderNameTouched(true);
              const newError = {};
              validateProviderName(newError, providerName);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setProviderName(e.target.value);
              if (providerNameTouched) {
                const newError = {};
                validateProviderName(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.providerName;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<RoomPreferencesOutlined />}
            size={"big"}
            error={Boolean(errors.providerName)}
            errorText={errors.providerName}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Policy Number"
            required
            name="policyNumber"
            value={policyNumber}
            onBlur={(e) => {
              setPolicyNumberTouched(true);
              const newError = {};
              validatePolicyNumber(newError, policyNumber);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setPolicyNumber(e.target.value);
              if (policyNumberTouched) {
                const newError = {};
                validatePolicyNumber(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.policyNumber;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<PolicyOutlined />}
            size={"big"}
            error={Boolean(errors.policyNumber)}
            errorText={errors.policyNumber}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Policy Holder"
            required
            name="policyHolder"
            value={policyHolder}
            onBlur={(e) => {
              setPolicyHolderTouched(true);
              const newError = {};
              validatePolicyHolder(newError, policyHolder);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setPolicyHolder(e.target.value);
              if (policyHolderTouched) {
                const newError = {};
                validatePolicyHolder(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.policyHolder;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<PersonOutlineOutlined />}
            size={"big"}
            error={Boolean(errors.policyHolder)}
            errorText={errors.policyHolder}
          />
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Mobile Number"
            name="mobileNumber"
            required
            type={"tel"}
            value={mobileNumber}
            inputProps={{
              maxLength: 10,
            }}
            onBlur={(e) => {
              setMobileNumberTouched(true);
              const newError = {};
              validateMobileNumber(newError, mobileNumber);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setMobileNumber(e.target.value);
              if (mobileNumberTouched) {
                const newError = {};
                validateMobileNumber(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.mobileNumber;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<i className="ri-phone-line ri-xl" />}
            size={"big"}
            error={Boolean(errors.mobileNumber)}
            errorText={errors.mobileNumber}
          />
        </Grid>

        <Grid size={{ xs: 12 }} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Coverage Details"
            name="coverageDetails"
            required
            value={coverageDetails}
            onBlur={(e) => {
              setCoverageDetailsTouched(true);
              const newError = {};
              validateCoverageDetails(newError, coverageDetails);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setCoverageDetails(e.target.value);
              if (coverageDetailsTouched) {
                const newError = {};
                validateCoverageDetails(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.coverageDetails;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<DisplaySettingsOutlined />}
            size={"big"}
            error={Boolean(errors.coverageDetails)}
            errorText={errors.coverageDetails}
          />
        </Grid>

        <Grid sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={saveInsuranceInfo}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientInsuranceInfo;
