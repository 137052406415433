import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid, FormControl, FormHelperText } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { CalendarTodayOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  AVAILABILITY_TIME_FOR_THE_24TH_HOUR,
  CLINIC_VISIT,
  TELE_CONSULTATION,
  orgId,
  roleName,
} from "store/constant";
import ModalUI from "ui-component/ModalUI";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { Formik } from "formik";
import {
  AddAvailabilityValidationSchema,
  AddAvailabilityValidationSchema2,
} from "../Common/ValidationSchema/addAvailabilityValidationSchema";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { createAvailabilities, updateAvailabilities } from "services/Availability";
import { useNavigate } from "react-router";

const AddAvailability = ({
  isOpen,
  onClose,
  doctors,
  pendingTask,
  docOrganizations,
  handleAvailabilities,
  isUpdate,
  isDelete,
  editAvailabilityData,
  isDoctorAdding,
  otherOrgavailabilities = [],
}) => {
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const { handleClick } = useContext(ToastContext);
  const organizationId = orgId();

  const navigate = useNavigate();

  useEffect(() => {
    if (isDoctorAdding && doctors) {
      setSelectedDoctor(doctors[0]);
    }
  }, [isDoctorAdding, doctors]);

  const [timeSlots, setTimeSlots] = useState([
    {
      startTime: "",
      endTime: "",
      clinicVisit: false,
      teleconsultation: false,
      day: 1,
      doctorId: null,
      organization: null,
    },
  ]);

  const handleModalClose = () => {
    setOpen(false);
    onClose();
    setUpdate(false);
    setChecked(false);
    setisClinicVisitChecked(false);
    setDays({ ...staticDays });
    setSelectedDoctor(doctors?.length === 1 ? doctors[0] : null);
    setisBlocked(false);
    setTimeSlots([
      {
        startTime: "",
        endTime: "",
        clinicVisit: false,
        teleconsultation: false,
        day: "1",
        organization: null,
      },
    ]);
  };

  const getDay = (dayNo) => {
    switch (dayNo) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 7:
        return "Sunday";
      default:
        return "Monday";
    }
  };

  useEffect(() => {
    setUpdate(isUpdate);
    setOpen(isOpen);
    if (!(isUpdate && isOpen)) {
      return;
    }
    const initObj = {
      id: editAvailabilityData.id,
      startTime: editAvailabilityData.startTime,
      endTime: editAvailabilityData.endTime,
      durationInMinutes: editAvailabilityData.durationMinutes,
      clinicVisit: editAvailabilityData.consultationType === TELE_CONSULTATION ? false : true,
      teleconsultation: editAvailabilityData.consultationType === CLINIC_VISIT ? false : true,
      day: editAvailabilityData.dayOfWeek[0],
      organization: editAvailabilityData.organizationId,
      doctorId: editAvailabilityData.doctorId,
    };
    let tempDayObj = {};
    for (const day in staticDays) {
      if (editAvailabilityData.dayOfWeek.includes(Number(day))) tempDayObj[day] = true;
      else tempDayObj[day] = false;
    }
    setDays(tempDayObj);
    setChecked(editAvailabilityData.dayOfWeek.length > 1);
    setisClinicVisitChecked(editAvailabilityData.consultationType !== TELE_CONSULTATION);
    setSelectedDoctor(doctors.find((doctor) => doctor.id === editAvailabilityData.doctorId));
    setTimeSlots([initObj]);
  }, [doctors, isUpdate, isDelete, isOpen, editAvailabilityData]);

  const daysOfWeek = [
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
    { value: 7, label: "Sunday" },
  ];

  const getSelectedDays = () => {
    let dayArr = [];
    for (const key in days) {
      if (days[key]) dayArr.push(Number(key));
    }
    return dayArr;
  };

  const [isBlocked, setisBlocked] = useState(false);

  const checkTeleconsultationOverlappingWithConfirmation = (slot) => {
    if (slot.teleconsultation) {
      const sT = slot.startTime;
      const eT = slot.endTime;
      let currisBlocked = false;
      otherOrgavailabilities.forEach((element) => {
        if (!currisBlocked) {
          if (sT >= element.endTime || eT <= element.startTime) {
          } else {
            currisBlocked = true;
          }
        }
      });
      setisBlocked(currisBlocked);
    } else {
      setisBlocked(false);
    }
  };

  const handleSaveAvailability = async () => {
    if (
      !selectedDoctor ||
      !timeSlots.every((slot) => {
        return (
          slot.startTime &&
          slot.endTime &&
          slot.day &&
          (slot.clinicVisit === true ? slot.organization : true) &&
          (slot.clinicVisit || slot.teleconsultation)
        );
      })
    ) {
      handleClick("error", "Please fill in all the required fields.");
      return;
    }

    if (
      timeSlots.some(
        (timeSlot) =>
          timeSlot.startTime === timeSlot.endTime ||
          (timeSlot.startTime > timeSlot.endTime &&
            timeSlot.endTime !== AVAILABILITY_TIME_FOR_THE_24TH_HOUR)
      )
    ) {
      handleClick("error", "Invalid time slots, please select valid start time & end time.");
      return;
    }

    let selectedDayData = {};
    timeSlots.forEach((timeSlot) => {
      if (timeSlot.clinicVisit && timeSlot.teleconsultation) {
        let tempData = getSelectedDayDateObj(timeSlot, timeSlot.startTime, timeSlot.endTime);
        selectedDayData = { clinicData: { ...tempData }, teleData: { ...tempData } };
        selectedDayData.clinicData.consultationType = CLINIC_VISIT;
        selectedDayData.teleData.consultationType = TELE_CONSULTATION;
      } else {
        selectedDayData = getSelectedDayDateObj(timeSlot, timeSlot.startTime, timeSlot.endTime);
        selectedDayData.consultationType = timeSlot.clinicVisit ? CLINIC_VISIT : TELE_CONSULTATION;
      }
    });
    try {
      if (
        selectedDayData.hasOwnProperty("clinicData") &&
        selectedDayData.hasOwnProperty("teleData")
      ) {
        await createAvailabilities(selectedDayData.clinicData);
        await createAvailabilities(selectedDayData.teleData);
      } else {
        await createAvailabilities(selectedDayData);
      }
      handleClick("success", "Availability added successfully");
      handleModalClose();
      if (pendingTask) {
        navigate("/home/dashboard", { state: { completedDetails: true } });
      }
      handleAvailabilities();
    } catch (error) {
      if (error?.response?.data.includes("Conflict in availability time slots")) {
        handleClick("error", "Conflict in availability time slots!");
      } else {
        handleClick("error", "Error creating availability");
      }
    }
  };

  const getSelectedDayDateObj = (timeSlot, sT, eT, cType) => {
    return {
      id: isUpdate ? editAvailabilityData.id : null,
      doctorId: selectedDoctor?.id,
      organizationId: timeSlot.clinicVisit ? timeSlot.organization : organizationId,
      dayOfWeek: getSelectedDays(),
      isActive: true,
      startTime: sT,
      endTime: eT,
    };
  };

  const updateAvailability = async () => {
    const id = editAvailabilityData.id;
    if (
      timeSlots.some(
        (timeSlot) =>
          timeSlot.startTime === timeSlot.endTime ||
          (timeSlot.startTime > timeSlot.endTime &&
            timeSlot.endTime.slice(0, 5) !== AVAILABILITY_TIME_FOR_THE_24TH_HOUR)
      )
    ) {
      handleClick("error", "Invalid time slots, please select valid start time & end time.");
      return;
    }

    let selectedDaysData = {};
    let timeSlot = timeSlots.find((timeSlot) => timeSlot.id === id);
    selectedDaysData = getSelectedDayDateObj(timeSlot, timeSlot.startTime, timeSlot.endTime);
    selectedDaysData.consultationType = timeSlot.clinicVisit ? CLINIC_VISIT : TELE_CONSULTATION;

    let finalArr = {};
    finalArr = selectedDaysData;
    try {
      await updateAvailabilities(id, finalArr);
      handleClick("success", "Availability updated successfully!");
      handleModalClose();
      handleAvailabilities();
    } catch (error) {
      if (error?.response?.data.includes("Conflict in availability time slots")) {
        handleClick("error", "Conflict in availability time slots!");
      } else {
        handleClick("error", "Error updating Availability!");
      }
    }
  };

  const [checked, setChecked] = useState(false);

  const currentRole = roleName();

  const staticDays = {
    1: true,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  };

  const [days, setDays] = useState({ ...staticDays });

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isClinicVisitChecked, setisClinicVisitChecked] = useState(false);

  const handleTimeSlotChange = (index, field, value) => {
    const updatedTimeSlots = [...timeSlots];
    if (field === "day" && value instanceof Array) {
      updatedTimeSlots[index][field] = value[0];
      setTimeSlots(updatedTimeSlots);
    } else {
      updatedTimeSlots[index][field] = value;
      setTimeSlots(updatedTimeSlots);
      if (isDoctorAdding) {
        checkTeleconsultationOverlappingWithConfirmation(updatedTimeSlots[index]);
      }
    }
    if (field === "day") {
      const obj = { ...staticDays };
      obj[1] = false;
      if (value instanceof Array) {
        value.map((day) => (obj[day] = true));
      } else {
        obj[value] = true;
      }
      setDays(obj);
    } else if (field === "doctorId") {
      setSelectedDoctor(doctors.find((doctor) => doctor.id === value));
    } else if (field === "clinicVisit") {
      if (value === true) {
        setisClinicVisitChecked(true);
        // only if there is single organization then it should be autoselected
        if (docOrganizations.length === 1) {
          handleTimeSlotChange(index, "organization", docOrganizations[0].id);
        }
      } else {
        setisClinicVisitChecked(false);
      }
    }
  };

  const handleButtonClick = (day) => {
    let obj = { ...days };
    obj[day] = !days[day];
    setDays(obj);
    const tempDays = Object.keys(obj).filter((day) => obj[day]);
    handleTimeSlotChange(0, "day", tempDays);
  };

  return (
    <div>
      {/* Add and update modal */}
      <ModalUI
        visible={open}
        close={handleModalClose}
        title="Set Doctor's Schedule"
        style={{
          overflowY: "scroll",
          height: "550px",
          width: "610px",
        }}
        component={
          <>
            <Formik
              enableReinitialize={true}
              initialValues={{
                startTime: timeSlots[0].startTime,
                endTime: timeSlots[0].endTime,
                clinicVisit: timeSlots[0].clinicVisit,
                consultationType: timeSlots[0].clinicVisit ? CLINIC_VISIT : TELE_CONSULTATION,
                teleconsultation: timeSlots[0].teleconsultation,
                day: timeSlots[0].day,
                doctorId: timeSlots[0].doctorId,
                organization: timeSlots[0].organization,
              }}
              validationSchema={() => {
                if (isClinicVisitChecked) {
                  return AddAvailabilityValidationSchema2;
                } else {
                  return AddAvailabilityValidationSchema;
                }
              }}
              onSubmit={() => {
                if (update) {
                  updateAvailability();
                } else {
                  handleSaveAvailability();
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isValid,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {timeSlots.map((slot, index) => (
                    <Grid key={index}>
                      <Grid container spacing={3} mt={1}>
                        {!isDoctorAdding && (
                          <>
                            <Grid item sx={{ width: "50%" }}>
                              <FormSelectField
                                style={{ width: "100%" }}
                                label={"Select Doctor"}
                                name="doctorId"
                                value={slot.doctorId}
                                size={"big"}
                                startAdornment={<PersonOutlineOutlined />}
                                onChange={(event) =>
                                  handleTimeSlotChange(index, "doctorId", event.target.value)
                                }
                                menuItems={doctors.map((el) => {
                                  return {
                                    ...el,
                                    value: el?.id,
                                    menuLabel: el?.userResponse?.name,
                                  };
                                })}
                              ></FormSelectField>
                            </Grid>
                          </>
                        )}
                      </Grid>

                      <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item sx={{ width: "50%" }}>
                          <FormSelectField
                            style={{ width: "100%" }}
                            label={"Day"}
                            name={"day"}
                            value={slot.day}
                            size={"big"}
                            startAdornment={<CalendarTodayOutlined />}
                            onChange={(event) => {
                              handleTimeSlotChange(index, "day", event.target.value);
                            }}
                            menuItems={daysOfWeek.map((el) => {
                              return {
                                ...el,
                                value: el?.value,
                                menuLabel: el?.label,
                              };
                            })}
                          ></FormSelectField>
                        </Grid>
                        <Grid item sx={{ width: "50%" }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  onChange={handleCheckboxChange}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 28 },
                                  }}
                                />
                              }
                              label="Copy to other days"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>

                      {checked ? (
                        <Grid container mt={2}>
                          {Object.keys(days).map((day, index) => {
                            return (
                              <Grid item key={index}>
                                <CustomButton
                                  style={{ marginRight: "10px" }}
                                  className={days[day] ? "btn--primary" : "btn--primary-light"}
                                  label={getDay(parseInt(day)).substring(0, 3)}
                                  onClick={() => handleButtonClick(day)}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        <></>
                      )}

                      <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item sx={{ width: "50%" }}>
                          <FormControl component="fieldset">
                            <Grid container>
                              <Typography variant="h5" sx={{ mb: 0.5 }}>
                                Consultation Type{" "}
                                <span
                                  style={{
                                    color:
                                      !values.clinicVisit &&
                                      !values.teleconsultation &&
                                      touched.clinicVisit &&
                                      touched.teleconsultation
                                        ? "red"
                                        : "#000000",
                                  }}
                                >
                                  *
                                </span>
                              </Typography>
                              {Boolean(
                                !values.clinicVisit &&
                                  !values.teleconsultation &&
                                  touched.clinicVisit &&
                                  touched.teleconsultation
                              ) && (
                                <FormHelperText sx={{ m: 0 }} error>
                                  Required
                                </FormHelperText>
                              )}
                            </Grid>
                            <FormGroup style={{ display: "inline" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={update && values.teleconsultation}
                                    checked={values.clinicVisit}
                                    onChange={(event) => {
                                      if (!update) {
                                        handleTimeSlotChange(
                                          index,
                                          "clinicVisit",
                                          event.target.checked
                                        );
                                      }
                                    }}
                                    name="clinicVisit"
                                  />
                                }
                                label="Clinic Visit"
                                style={
                                  update && values.teleconsultation ? { cursor: "not-allowed" } : {}
                                }
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={update && values.clinicVisit}
                                    checked={values.teleconsultation}
                                    onChange={(event) => {
                                      if (!update) {
                                        handleTimeSlotChange(
                                          index,
                                          "teleconsultation",
                                          event.target.checked
                                        );
                                      }
                                    }}
                                    name="teleconsultation"
                                  />
                                }
                                label="Teleconsultation"
                                style={
                                  update && values.clinicVisit ? { cursor: "not-allowed" } : {}
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        {isClinicVisitChecked === true && (
                          <>
                            <Grid item sx={{ width: "50%" }}>
                              {docOrganizations?.length > 1 ? (
                                <FormSelectField
                                  style={{ width: "100%" }}
                                  label={"Organization"}
                                  name={"organization"}
                                  required
                                  value={slot.organization ? slot.organization : ""}
                                  size={"big"}
                                  startAdornment={<CalendarTodayOutlined />}
                                  error={errors.organization && touched.organization}
                                  errorText={errors.organization}
                                  onChange={(event) => {
                                    handleTimeSlotChange(index, "organization", event.target.value);
                                  }}
                                  menuItems={docOrganizations.map((el) => {
                                    return {
                                      ...el,
                                      value: el?.id,
                                      menuLabel: el?.name,
                                    };
                                  })}
                                ></FormSelectField>
                              ) : (
                                <FormInputField
                                  style={{ width: "100%" }}
                                  label={"Organization"}
                                  name={"organization"}
                                  required
                                  shrink={true}
                                  value={
                                    docOrganizations.find((org) => org.id === slot.organization)
                                      ?.name || ""
                                  }
                                  error={errors.organization && touched.organization}
                                  errorText={errors.organization}
                                  startAdornment={<CalendarTodayOutlined />}
                                  size={"big"}
                                  readOnly
                                />
                              )}
                            </Grid>
                          </>
                        )}
                      </Grid>

                      <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item sx={{ width: "50%" }}>
                          <FormInputField
                            style={{ width: "100%" }}
                            label="Start Time"
                            name="startTime"
                            type="time"
                            required
                            shrink={true}
                            value={values.startTime}
                            error={Boolean(errors.startTime && touched.startTime)}
                            errorText={errors.startTime}
                            onChange={(event) => {
                              setFieldValue("startTime", event.target.value);
                              handleTimeSlotChange(index, "startTime", event.target.value);
                            }}
                            size={"big"}
                          />
                        </Grid>
                        <Grid item sx={{ width: "50%" }}>
                          <FormInputField
                            style={{ width: "100%" }}
                            label="End Time"
                            shrink={true}
                            required
                            name="endTime"
                            type="time"
                            value={values.endTime}
                            error={Boolean(errors.endTime && touched.endTime)}
                            errorText={errors.endTime}
                            onChange={(event) => {
                              setFieldValue("endTime", event.target.value);
                              handleTimeSlotChange(index, "endTime", event.target.value);
                            }}
                            size={"big"}
                          />
                        </Grid>
                      </Grid>

                      {isBlocked && (
                        <>
                          <Grid container sx={{ mt: 3 }} style={{ display: "flex" }}>
                            <Typography variant="h4" color="secondary">
                              There is a conflict in availability for the selected slots with other
                              organization!!
                            </Typography>
                            <Typography variant="h4" color="secondary" sx={{ mt: 2 }}>
                              Do you still want to create this availability?
                            </Typography>
                          </Grid>
                          <Grid container sx={{ mt: 3 }} style={{ display: "flex" }}>
                            <CustomButton
                              className="btn--secondary"
                              style={{ marginLeft: "auto" }}
                              label={"Yes, Proceed!"}
                              type={"submit"}
                            ></CustomButton>
                          </Grid>
                        </>
                      )}

                      {!isBlocked && (
                        <>
                          <Grid container sx={{ mt: 3 }} style={{ display: "flex" }}>
                            <CustomButton
                              className="btn--secondary"
                              style={{ marginLeft: "auto" }}
                              label={update ? "Update Availability" : "Save Availability"}
                              type={"submit"}
                            ></CustomButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                </form>
              )}
            </Formik>
          </>
        }
      />
    </div>
  );
};

export default AddAvailability;
