import "assets/scss/style.scss";
import "assets/scss/prescriptionPad.scss";
import ModalUI from "ui-component/ModalUI";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PrescriptionSearch from "./prescriptionSearch";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import PrescriptionCard from "./prescriptionCard";
import { useEffect, useContext, useMemo, useState } from "react";
import {
  ACTIVE_STATE,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  FRONT_DESK,
  INACTIVE_STATE,
  PRESCRIPTION_DONE,
  isDemo,
  loggedInUser,
  orgId,
} from "store/constant";
import PatientVitalSignModal from "views/Components/Patient/PatientProfile/PatientVitalSignModal";
import { getAppointmentById, updateAppointmentStatus } from "services/Appointments";
import {
  createPrescriptions,
  createTemplates,
  getPrescriptionById,
  getPrescriptionByIds,
  getTemplatesByDocId,
  updatePrescriptions,
} from "services/PrescriptionsService";
import { getVitalSignTypes } from "services/EntitiesServices";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrescriptionId,
  getPrescriptionPadData,
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Slices/prescriptionPadSlice";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";
import "shepherd.js/dist/css/shepherd.css";
import "assets/scss/shepherd-guide.scss";
import { getUsersByOrganizationId } from "services/organizationService";
import { setPatientInvoiceId } from "store/Slices/billingInvoiceSlice";

const PrescriptionPad = () => {
  const { state } = useLocation();
  const { handleClick } = useContext(ToastContext);
  const currentPrescription = useSelector(getPrescriptionPadData);
  const prescriptionId = useSelector(getPrescriptionId);

  const computedData = useMemo(() => {
    return currentPrescription;
  }, [currentPrescription]);

  useEffect(() => {}, [computedData]);

  const [previousPrescription, setPreviousPrescription] = useState({
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });
  const [appointment, setAppointment] = useState({});
  const [pastPrescriptions, setPastPrescriptions] = useState([]);
  const [prescriptionIndex, setPrescriptionIndex] = useState(-1);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);
  const { id } = useParams();

  const [removedPrescriptionData, setRemovedPrescription] = useState({
    medicationsArr: [],
    symptomsArr: [],
    diagnosisArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });

  const dispatch = useDispatch();
  // vital sign functionality
  const [vitalModalOpen, setVitalModalOpen] = useState(false);
  const fetchVitalSignTypes = async () => {
    try {
      const response = await getVitalSignTypes();
      setVitalSignTypes(response.data);
    } catch (error) {
      console.error("Error fetching Vital sign types");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const appointmentResponse = await getAppointmentById(id);
        setAppointment(appointmentResponse.data);

        const pastPrescriptionResponse = await getPrescriptionByIds(
          loggedInUser()?.roleBasedId,
          appointmentResponse.data?.patientId
        );
        setPastPrescriptions(pastPrescriptionResponse.data);

        fetchVitalSignTypes();
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    handleGetTemplates();
    //eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const getEndDateForPrescription = (duration) => {
    const [countStr, type] = duration.split(" ");
    const count = parseInt(countStr);
    if (isNaN(count)) {
      return null;
    }
    let endDate = dayjs();
    switch (type.toLowerCase()) {
      case "day":
      case "days":
        endDate = endDate.add(count, "day");
        break;
      case "week":
      case "weeks":
        endDate = endDate.add(count, "week");
        break;
      case "month":
      case "months":
        endDate = endDate.add(count, "month");
        break;
      default:
        return null; // Invalid duration type
    }
    return endDate.format(DATE_TIME_FORMAT);
  };

  const savePrescriptionPad = async () => {
    /* 
      todo currently, only items selected from search bar can be saved, as id need to be sent.
      Need some changes from backend
    */

    const prescriptionDetails = [];
    const symptomDetails = [];
    const diagnosisDetails = [];
    const labTestDetails = [];

    currentPrescription.medicationsArr.forEach((medicine) => {
      const prescriptionDetail = {
        id: medicine.id || null,
        medicationId: medicine.medicationId,
        duration: medicine.duration,
        purpose: null,
        frequency: medicine?.frequency?.trim() || "",
        doseTiming: medicine?.doseTiming,
        prescribingDoctor: null,
        instructions: medicine.instructions,
        patientId: appointment?.patientId,
        status: medicine.status || ACTIVE_STATE,
        startDate: dayjs().format(DATE_TIME_FORMAT),
        endDate: getEndDateForPrescription(medicine.duration),
        prescriptionId: prescriptionId || null,
      };
      prescriptionDetails.push(prescriptionDetail);
    });

    currentPrescription.symptomsArr.forEach((symptom) => {
      const detail = {
        id: symptom.id || null,
        symptomId: symptom.symptomId,
        severity: symptom.severity,
        since: symptom.since,
        complaints: "complaints",
        status: symptom.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || null,
      };
      symptomDetails.push(detail);
    });

    currentPrescription.diagnosisArr.forEach((diagnosis) => {
      const detail = {
        id: diagnosis.id || null,
        diagnosisId: diagnosis.diagnosisId,
        since: "2 days",
        severity: "MODERATE",
        conclusions: diagnosis.conclusions,
        status: diagnosis.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || null,
      };
      diagnosisDetails.push(detail);
    });

    currentPrescription.labInvestigationsArr.forEach((labTest) => {
      const detail = {
        id: labTest.id || null,
        patientId: appointment?.patientId,
        labTestId: labTest.labTestId,
        reportDate: dayjs().format(DATE_FORMAT),
        performedBy: null,
        orderedBy: null,
        instructions: labTest.instructions,
        status: labTest.status || ACTIVE_STATE,
        prescriptionId: prescriptionId || null,
      };
      labTestDetails.push(detail);
    });
    prescriptionDetails.push(...removedPrescriptionData.medicationsArr);
    symptomDetails.push(...removedPrescriptionData.symptomsArr);
    diagnosisDetails.push(...removedPrescriptionData.diagnosisArr);
    labTestDetails.push(...removedPrescriptionData.labInvestigationsArr);

    const payload = {
      appointmentId: appointment?.id,
      additionalRecommendations: currentPrescription.advicesArr
        .map((advice) => advice?.displayName)
        .join(", "),
      doctorSignature: "Signature",
      followUpDate: dayjs().add(7, "day").format(DATE_FORMAT),
      referredTo: null,
      status: ACTIVE_STATE,
      templateId: null,
      prescriptionMedicationResponses: prescriptionDetails,
      prescriptionSymptomResponses: symptomDetails,
      prescriptionDiagnosisResponses: diagnosisDetails,
      prescriptionLabReportResponses: labTestDetails,
    };

    try {
      if (prescriptionId) {
        const updateResponse = await updatePrescriptions(payload, prescriptionId);
        const response = await getPrescriptionById(updateResponse.data.id);

        let advice = response.data?.additionalRecommendations;
        let advicesArr = [];
        if (advice && advice !== "") {
          advicesArr = response.data?.additionalRecommendations.split(",").map((advice) => {
            return {
              category: "ADV",
              displayName: advice.trim(),
            };
          });
        }
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [
              ...response.data.prescriptionDiagnosisResponses.map((diagnosis) => {
                return {
                  ...diagnosis,
                  displayName: diagnosis.diagnosisResponse.name,
                  category: "DX",
                };
              }),
            ],
            symptomsArr: [
              ...response.data.prescriptionSymptomResponses.map((symptom) => {
                return {
                  ...symptom,
                  displayName: symptom.symptomResponse.name,
                  category: "CC",
                };
              }),
            ],
            medicationsArr: [
              ...response.data.prescriptionMedicationResponses.map((medication) => {
                return {
                  ...medication,
                  displayName: medication.medicationResponse.name,
                  category: "RX",
                };
              }),
            ],
            labInvestigationsArr: [
              ...response.data.prescriptionLabReportResponses.map((labTest) => {
                return {
                  ...labTest,
                  displayName: labTest.labTestResponse.name,
                  category: "LAB",
                };
              }),
            ],
            advicesArr: [...advicesArr],
          })
        );
        checkForFrontDesk();
      } else {
        const createResponse = await createPrescriptions(payload);
        const response = await getPrescriptionById(createResponse.data.id);
        let advice = response.data?.additionalRecommendations;
        let advicesArr = [];
        if (advice && advice !== "") {
          advicesArr = response.data?.additionalRecommendations.split(",").map((advice) => {
            return {
              category: "ADV",
              displayName: advice.trim(),
            };
          });
        }
        dispatch(
          setPrescriptionPadData({
            diagnosisArr: [
              ...response.data.prescriptionDiagnosisResponses.map((diagnosis) => {
                return {
                  ...diagnosis,
                  displayName: diagnosis.diagnosisResponse.name,
                  category: "DX",
                };
              }),
            ],
            symptomsArr: [
              ...response.data.prescriptionSymptomResponses.map((symptom) => {
                return {
                  ...symptom,
                  displayName: symptom.symptomResponse.name,
                  category: "CC",
                };
              }),
            ],
            medicationsArr: [
              ...response.data.prescriptionMedicationResponses.map((medication) => {
                return {
                  ...medication,
                  displayName: medication.medicationResponse.name,
                  category: "RX",
                };
              }),
            ],
            labInvestigationsArr: [
              ...response.data.prescriptionLabReportResponses.map((labTest) => {
                return {
                  ...labTest,
                  displayName: labTest.labTestResponse.name,
                  category: "LAB",
                };
              }),
            ],
            advicesArr: [...advicesArr],
          })
        );
        dispatch(setPrescriptionId(response.data.id));
        checkForFrontDesk();
      }
    } catch (error) {
      handleClick("error", "There seems to be an error saving prescription");
    }
  };

  // methods for removing prescription items
  const handleRemoveMedication = (index) => {
    const updatedMedicationsArr = [...currentPrescription.medicationsArr];
    if (updatedMedicationsArr[index].id) {
      const updatedMedication = { ...updatedMedicationsArr[index], status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        medicationsArr: [...removedPrescriptionData.medicationsArr, updatedMedication],
      });
    }
    updatedMedicationsArr.splice(index, 1);

    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        medicationsArr: updatedMedicationsArr,
      })
    );
  };

  const handleRemoveSymptom = (index) => {
    const updatedSymptomsArr = [...currentPrescription.symptomsArr];
    if (updatedSymptomsArr[index].id) {
      const updatedSymptom = { ...updatedSymptomsArr[index], status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        symptomsArr: [...removedPrescriptionData.symptomsArr, updatedSymptom],
      });
    }
    updatedSymptomsArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        symptomsArr: updatedSymptomsArr,
      })
    );
  };

  const handleRemoveDiagnosis = (index) => {
    const updatedDiagnosisArr = [...currentPrescription.diagnosisArr];
    if (updatedDiagnosisArr[index].id) {
      const updatedDiagnosis = { ...updatedDiagnosisArr, status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        diagnosisArr: [...removedPrescriptionData.diagnosisArr, updatedDiagnosis],
      });
    }
    updatedDiagnosisArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        diagnosisArr: updatedDiagnosisArr,
      })
    );
  };

  const handleRemoveLabInvestigations = (index) => {
    const updatedLabInvestigations = [...currentPrescription.labInvestigationsArr];
    if (updatedLabInvestigations[index].id) {
      const updatedLab = { ...updatedLabInvestigations, status: INACTIVE_STATE };
      setRemovedPrescription({
        ...removedPrescriptionData,
        labInvestigationsArr: [...removedPrescriptionData.labInvestigationsArr, updatedLab],
      });
    }
    updatedLabInvestigations.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        labInvestigationsArr: updatedLabInvestigations,
      })
    );
  };

  const handleRemoveAdvices = (index, key) => {
    const updatedAdvicesArr = [...currentPrescription.advicesArr];
    updatedAdvicesArr.splice(index, 1);
    dispatch(
      setPrescriptionPadData({
        ...currentPrescription,
        advicesArr: updatedAdvicesArr,
      })
    );
  };

  // template functionality
  const [modalOpen, setModalOpen] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({
    templateName: "",
    templateDescription: "",
  });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [templateMenus, setTemplateMenus] = useState([]);

  useEffect(() => {
    if (templateInfo.templateName.trim() === "") {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [templateInfo]);

  const handleTemplateInfoChange = (event) => {
    setTemplateInfo({
      ...templateInfo,
      [event.target.name]: event.target.value,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setVitalModalOpen(false);
  };

  const handleSaveTemplate = async () => {
    try {
      const templateObject = {
        diagnosis: currentPrescription.diagnosisArr,
        symptoms: currentPrescription.symptomsArr,
        medications: currentPrescription.medicationsArr,
        labInvestigations: currentPrescription.labInvestigationsArr,
        advices: currentPrescription.advicesArr,
      };
      const payload = {
        templateName: templateInfo.templateName.trim(),
        templateContent: JSON.stringify(templateObject),
        description: templateInfo.templateDescription.trim(),
        status: ACTIVE_STATE,
        version: "1.0",
        doctorId: loggedInUser()?.roleBasedId,
      };
      if (checkIfValueIsPresent(templateObject)) {
        await createTemplates(payload);
      } else {
        handleClick("error", "Template should not be empty.");
      }
      setTemplateInfo({ templateName: "", templateDescription: "" });
      handleGetTemplates();
      closeModal();
    } catch (error) {
      handleClick("error", "There seems to be an error saving the template");
    }
  };

  const handleGetTemplates = async () => {
    try {
      const templateResponses = await getTemplatesByDocId(loggedInUser()?.roleBasedId);

      let templates = templateResponses.data.map((template) => ({
        label: template.templateName,
        templateData: template.templateContent,
        category: "TEMP",
        displayName: template.templateName,
      }));

      // Check if there are no templates and add "NO TEMPLATE AVAILABLE"
      if (templates.length === 0) {
        templates.push({
          label: "NO TEMPLATE AVAILABLE",
          templateData: "",
          category: "INFO",
          displayName: "NO TEMPLATE AVAILABLE",
          style: { fontStyle: "italic" },
          disabled: true,
          // isNoTemplateAvailable: true,
        });
      }

      templates.push({
        label: "SAVE THIS",
        templateData: null,
        category: "ACTION",
        displayName: "SAVE",
        isSaveButton: true,
        icon: <SaveIcon style={{ color: "#004c70" }} />,
      });

      setTemplateMenus(templates);
    } catch (error) {
      console.error("Error fetching templates");
    }
  };

  const handleSelectTemplate = (template) => {
    if (!template?.templateData && template?.displayName === "SAVE") {
      setVitalModalOpen(false);
      setModalOpen(true);
      return;
    }
    const templateData = JSON.parse(template.templateData);
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: templateData.diagnosis,
        symptomsArr: templateData.symptoms,
        medicationsArr: templateData.medications,
        labInvestigationsArr: templateData.labInvestigations,
        advicesArr: templateData.advices,
      })
    );
  };

  // update past prescription-pad detail object
  useEffect(() => {
    if (prescriptionIndex === -1) {
      return;
    }
    const medicationsArr = pastPrescriptions[prescriptionIndex].prescriptionMedicationResponses.map(
      (medication) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex].id,
          medicationId: medication.medicationId,
          category: "RX",
          displayName: medication.medicationResponse.name,
          frequency: medication.frequency,
          duration: medication.duration,
          instructions: medication.instructions,
          doseTiming: medication.doseTiming,
        };
      }
    );

    const diagnosisArr = pastPrescriptions[prescriptionIndex].prescriptionDiagnosisResponses.map(
      (diagnosis) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex].id,
          diagnosisId: diagnosis.diagnosisId,
          category: "DX",
          displayName: diagnosis.diagnosisResponse.name,
          conclusions: diagnosis.conclusions,
        };
      }
    );

    const symptomsArr = pastPrescriptions[prescriptionIndex].prescriptionSymptomResponses.map(
      (symptom) => {
        return {
          prescriptionId: pastPrescriptions[prescriptionIndex].id,
          symptomId: symptom.symptomId,
          category: "CC",
          displayName: symptom.symptomResponse.name,
          since: symptom.since,
          severity: symptom.severity,
        };
      }
    );

    const labInvestigationsArr = pastPrescriptions[
      prescriptionIndex
    ].prescriptionLabReportResponses.map((lab) => {
      return {
        prescriptionId: pastPrescriptions[prescriptionIndex].id,
        labTestId: lab.labTestId,
        category: "LAB",
        displayName: lab.labTestResponse.name,
        instructions: lab.instructions,
      };
    });

    const advice = pastPrescriptions[prescriptionIndex].additionalRecommendations;
    let advicesArr = [];
    if (advice !== "") {
      advicesArr = pastPrescriptions[prescriptionIndex].additionalRecommendations
        .split(", ")
        .map((advice) => {
          return {
            category: "ADV",
            displayName: advice,
          };
        });
    }

    setPreviousPrescription({
      diagnosisArr: diagnosisArr,
      symptomsArr: symptomsArr,
      medicationsArr: medicationsArr,
      labInvestigationsArr: labInvestigationsArr,
      advicesArr: advicesArr,
    });
  }, [prescriptionIndex]);

  const checkIfValueIsPresent = (obj) => {
    return Object.values(obj).some((res) => res != null && res?.length > 0);
  };

  const checkForFrontDesk = async () => {
    const response = await getUsersByOrganizationId(orgId());
    const frontDeskUserFound = response.data.find((user) => user.roleName === FRONT_DESK);
    try {
      await updateAppointmentStatus(appointment.id, PRESCRIPTION_DONE);
      if (frontDeskUserFound) {
        navigate("/home/dashboard");
      } else {
        navigate("/home/generateInvoice", {
          state: {
            appointmentId: appointment.id,
            patientId: appointment.patientId,
            doctorId: appointment.doctorId,
            orgId: appointment.orgId,
          },
        });
      }
    } catch (error) {
      handleClick("error", "There seems to be an error completing appointment");
    }
  };

  return (
    <Reveal>
      <div
        className="row justify-content-space-between align-items-center roll-content"
        style={{ height: "36px" }}
      >
        <div className="col">
          <ul className="horizontal-ul" style={{ marginTop: 0 }}>
            <li className="main-actions" id="prescription-end-consultation">
              <CustomButton
                height="36px"
                className={"mui-btn--primary"}
                startIcon={
                  state?.isEditable ? (
                    <i className="ri-save-2-line ri-xl" />
                  ) : (
                    <img src="/images/hc_double_tick.svg" alt="double_tick_svg" />
                  )
                }
                label={state?.isEditable ? "Save changes" : "End Consultation"}
                onClick={isDemo() ? null : savePrescriptionPad}
              ></CustomButton>
            </li>
            <li className="main-actions" id="prescription-templates" style={{ marginLeft: "0px" }}>
              <CustomButton
                className={"mui-btn--secondary"}
                // startIcon={<img src="/images/hc_template.svg" alt="template_svg" />}
                height="36px"
                endIcon={<i className="ri-arrow-down-s-fill" />}
                label={"Templates"}
                dropDownMenuItems={templateMenus}
                onMenuItemClick={handleSelectTemplate}
                menuItemHoverColor="#29bf91"
              ></CustomButton>
            </li>
            <li className="main-actions" style={{ marginLeft: "0px" }}>
              <div
                id="prescription-past-prescriptions"
                style={{ display: "flex", alignItems: "center" }}
              >
                <IconButton
                  onClick={() => {
                    setPrescriptionIndex((prev) => prev + 1);
                  }}
                  disabled={prescriptionIndex === pastPrescriptions.length - 1}
                  style={{ paddingLeft: "5px", paddingRight: "5px" }}
                >
                  <KeyboardArrowLeftIcon
                    style={{
                      color: prescriptionIndex === pastPrescriptions.length - 1 ? "" : "#004C70",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
                <p style={{ margin: "0px", fontWeight: "500", color: "#004c70" }}>Prescriptions</p>
                <IconButton
                  onClick={() => {
                    setPrescriptionIndex((prev) => prev - 1);
                  }}
                  disabled={prescriptionIndex === -1}
                  style={{ paddingLeft: "5px", paddingRight: "5px" }}
                >
                  <KeyboardArrowRightIcon
                    style={{
                      color: prescriptionIndex === -1 ? "" : "#004C70",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div style={{ marginBottom: "0px" }}>
        <Grid
          container
          spacing={2}
          className="cst-height mx-0 row justify-content-space-between align-items-center mb-0 prescription-layout"
        >
          <Grid id="prescription-clinic-pad" style={{ height: "100%", width: "100%" }}>
            <Card
              className="custom-card-ui"
              style={{
                backgroundColor: "white",
                padding: "24px 15px",
                scrollbarWidth: "none",
              }}
            >
              {prescriptionIndex === -1 && (
                <div id="prescription-clinic-search">
                  <PrescriptionSearch
                    templates={templateMenus}
                    handleSelectTemplate={handleSelectTemplate}
                  />
                </div>
              )}
              {prescriptionIndex !== -1 && (
                <Typography
                  style={{
                    fontFamily: "Mitr",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#004C70",
                  }}
                >
                  {dayjs(
                    pastPrescriptions[prescriptionIndex].appointmentResponse.appointmentDate
                  ).format("DD MMM YYYY")}
                </Typography>
              )}
              <br></br>

              <PrescriptionCard
                key="Cc"
                cardHeaderTitle="Cc"
                values={
                  prescriptionIndex === -1
                    ? currentPrescription.symptomsArr
                    : previousPrescription.symptomsArr
                }
                onDelete={handleRemoveSymptom}
                isEditable={prescriptionIndex === -1}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Dx"
                cardHeaderTitle="Dx"
                values={
                  prescriptionIndex === -1
                    ? currentPrescription.diagnosisArr
                    : previousPrescription.diagnosisArr
                }
                onDelete={handleRemoveDiagnosis}
                isEditable={prescriptionIndex === -1}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Rx"
                cardHeaderTitle="Rx"
                cardContentField="Medicines"
                values={
                  prescriptionIndex === -1
                    ? currentPrescription.medicationsArr
                    : previousPrescription.medicationsArr
                }
                onDelete={handleRemoveMedication}
                isEditable={prescriptionIndex === -1}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Lab"
                cardHeaderTitle="Lab"
                values={
                  prescriptionIndex === -1
                    ? currentPrescription.labInvestigationsArr
                    : previousPrescription.labInvestigationsArr
                }
                onDelete={handleRemoveLabInvestigations}
                isEditable={prescriptionIndex === -1}
              ></PrescriptionCard>

              <PrescriptionCard
                key="Adv"
                cardHeaderTitle="Adv"
                values={
                  prescriptionIndex === -1
                    ? currentPrescription.advicesArr
                    : previousPrescription.advicesArr
                }
                onDelete={handleRemoveAdvices}
                isEditable={prescriptionIndex === -1}
              ></PrescriptionCard>
            </Card>
          </Grid>
        </Grid>
      </div>
      <ModalUI
        visible={modalOpen || vitalModalOpen}
        close={closeModal}
        title={modalOpen ? "Save Prescription as Template" : "Add Patient Vital Sign"}
        component={
          <>
            {modalOpen ? (
              <div style={{ width: "500px" }}>
                <FormInputField
                  style={{ width: "60%", marginTop: "20px" }}
                  label={"Template Name *"}
                  name={"templateName"}
                  inputProps={{ maxLength: 30 }}
                  value={templateInfo.templateName}
                  onChange={handleTemplateInfoChange}
                  startAdornment={<DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>}
                ></FormInputField>
                <FormInputField
                  style={{ width: "100%", marginTop: "20px" }}
                  label="Template Description"
                  name="templateDescription"
                  inputProps={{ maxLength: 100 }}
                  value={templateInfo.templateDescription}
                  onChange={handleTemplateInfoChange}
                  startAdornment={<DescriptionIcon></DescriptionIcon>}
                ></FormInputField>
                <div style={{ marginTop: "20px", display: "flex" }}>
                  <CustomButton
                    label="Save"
                    height="36px"
                    className="mui-btn--primary"
                    style={{ marginRight: "30px" }}
                    onClick={isDemo() ? closeModal() : handleSaveTemplate}
                    disabled={!showSaveButton}
                  ></CustomButton>
                  <CustomButton
                    height="36px"
                    label="Cancel"
                    className="btn--secondary-light"
                    onClick={() => {
                      setTemplateInfo({
                        templateName: "",
                        templateDescription: "",
                      });
                      closeModal();
                    }}
                  ></CustomButton>
                </div>
              </div>
            ) : (
              <PatientVitalSignModal
                selected={null}
                close={(operation) => {
                  if (operation === "save") {
                    closeModal();
                    fetchPatientLatestVitalSigns(
                      appointment?.patientId,
                      setPatientLatestVitals,
                      handleClick
                    );
                  } else {
                    closeModal();
                  }
                }}
                apData={appointment}
                patientId={appointment?.patientId}
                vitalSignTypes={vitalSignTypes}
              ></PatientVitalSignModal>
            )}
          </>
        }
      ></ModalUI>
    </Reveal>
  );
};

export default PrescriptionPad;
