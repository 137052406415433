import { useState, useEffect } from "react";
import {
  Typography,
  Tab,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardContent,
} from "@mui/material";
import dayjs from "dayjs";

import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import * as React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "assets/scss/doctorAppointments.scss";
import {
  Liquor,
  SmokingRoomsOutlined,
  VaccinesOutlined,
  VideoCall,
  DirectionsWalk,
} from "@mui/icons-material";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import Reveal from "views/utilities/Reveal";
import Tooltip from "@mui/material/Tooltip";
import {
  getPatientLifestyleByPatientId,
  getPatientPastProceduresByPatientId,
} from "services/patientService";
import { getMedicationsByPatientId } from "services/PrescriptionsService";
import { getApprovedRecords } from "services/HiuService";
import { currentActiveUser, DATE_FORMAT, hiuToken } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { Stack } from "@mui/system";
import { getRecentAppointments } from "services/Appointments";
const columns = [
  { field: "displayName", label: "dataType" },
  { field: "value", label: "value" },
  { field: "deviation", label: "deviation" },
];

const PatientHealthDetails = ({
  patientVitals,
  isTeleconsultation = false,
  patientAbhaId,
  patientId,
  handleClick,
  setIframeOpen,
  setIframeSrc,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [activeMedications, setActiveMedications] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [visits, setVisits] = useState([]);
  const [records, setRecords] = useState([]);
  const [lifestyleHabits, setLifestyleHabits] = useState({
    Eggetarian: false,
    "Non-vegetarian": false,
    Vegetarian: false,
    "Consuming Tobacco": false,
    Drinking: false,
    Smoking: false,
  });
  const doctorId = currentActiveUser().roleBasedId;

  useEffect(() => {
    const getRecordsForPatient = async () => {
      try {
        const abdmRecords = await getApprovedRecords(10, doctorId, patientAbhaId);
        setRecords(abdmRecords?.data);
      } catch (e) {
        handleClick("error", "Can not fetch health records... Please try after some time");
      }
    };

    getRecordsForPatient();
    const intervalId = setInterval(getRecordsForPatient, 60000);

    return () => clearInterval(intervalId);
  }, [activeTab, doctorId, handleClick, patientAbhaId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (patientId) {
          fetchPatientVisitDetails();

          const activeMedicationResponse = await getMedicationsByPatientId(patientId);
          setActiveMedications(activeMedicationResponse.data);

          const medicalHistoryResponse = await getPatientPastProceduresByPatientId(patientId);
          setMedicalHistory(medicalHistoryResponse.data);

          const lifestyleResponse = await getPatientLifestyleByPatientId(patientId);

          const matchedHabits = lifestyleResponse.data.flatMap((responseObj) => {
            const responseArray = responseObj.response.split(",");
            return responseArray.filter((response) =>
              lifestyleHabits.hasOwnProperty(response.trim())
            );
          });

          // re-initialize, when patient id change, to avoid data being persisted
          const updatedLifestyleHabits = {
            Eggetarian: false,
            "Non-vegetarian": false,
            Vegetarian: false,
            "Consuming Tobacco": false,
            Drinking: false,
            Smoking: false,
          };

          matchedHabits.forEach((habit) => {
            if (!updatedLifestyleHabits[habit]) {
              updatedLifestyleHabits[habit] = true;
            }
          });
          setLifestyleHabits(updatedLifestyleHabits);
        }
      } catch (error) {
        handleClick("error", "Error fetching data:");
      }
    };

    fetchData();
  }, [patientId]);

  const fetchPatientVisitDetails = async () => {
    try {
      const doctorId = currentActiveUser()?.roleBasedId;
      const visitsResponse = await getRecentAppointments(
        patientId,
        null,
        null,
        null,
        doctorId,
        null,
        5
      );
      setVisits(visitsResponse.data);
    } catch (error) {
      console.error("Error fetching patient visit details:", error);
    }
  };

  return (
    <Grid container className="row mb-0 mx-0 column-patient-details">
      <Grid item md={12} className="px-0">
        <div className="row w-100 mb-0 align-items-left flex-direction-column">
          <Grid item md={12} className="px-0 precscription-tabs h-100">
            <TabContext value={activeTab}>
              {!isTeleconsultation && (
                <TabList
                  value={activeTab}
                  onChange={handleTabChange}
                  centered
                  sx={{
                    height: "39px",
                    width: "100%",
                    minHeight: "39px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    ".MuiTab-root": {
                      borderRight: "1px solid #e0e0e0",
                      "&:last-child": {
                        borderRight: "none",
                      },
                    },
                    ".Mui-selected": {
                      backgroundColor: "#E6EEF1",
                      color: "#747b8d !important",
                    },
                    ".MuiButtonBase-root.MuiTab-root": {
                      paddingBottom: "8px",
                    },
                    ".MuiTabs-indicator": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Tab label="About" className="tab" value="1" />
                  <Tab label="Record" className="tab" value="2" />
                  <Tab label="Visits" className="tab" value="3" />
                </TabList>
              )}
              <TabPanel
                sx={{
                  padding: isTeleconsultation ? "0px" : "24px 10px 0px 10px",
                  backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.6)" : "",
                  color: isTeleconsultation ? "#ffffff" : "",
                }}
                value="1"
              >
                <Reveal>
                  <Accordion
                    defaultExpanded
                    sx={{
                      backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.1)" : "",
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        minHeight: "30px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      <Typography
                        className={isTeleconsultation ? "about-title-tele" : "about-title"}
                      >
                        Vitals
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomizedTable
                        doNotShowTableHead={true}
                        noPagination={true}
                        columns={columns}
                        tableData={patientVitals}
                        fontSize={"11px"}
                        isTeleconsultation={isTeleconsultation}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <hr
                    style={{
                      backgroundColor: "#888888",
                      border: "none",
                      height: "1px",
                    }}
                  ></hr>
                  <Accordion
                    defaultExpanded
                    sx={{
                      backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.1)" : "",
                      color: isTeleconsultation ? "white" : "",
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        minHeight: "30px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                          color: isTeleconsultation ? "white" : "",
                        },
                      }}
                    >
                      <Typography
                        sx={{ color: isTeleconsultation ? "white" : "" }}
                        className={isTeleconsultation ? "about-title-tele" : "about-title"}
                      >
                        Lifestyle & Hx
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: "3px 23px" }}>
                      {lifestyleHabits["Drinking"] && (
                        <Tooltip title="Drinking" arrow>
                          <Liquor
                            sx={{
                              p: "5px",
                              borderRadius: "20px",
                              fontSize: "40px",
                              fill: "#DD2025",
                              backgroundColor: "#F2F6F8",
                              mr: "10px",
                            }}
                          />
                        </Tooltip>
                      )}
                      {lifestyleHabits["Smoking"] && (
                        <Tooltip title="Smoking" arrow>
                          <SmokingRoomsOutlined
                            sx={{
                              p: "5px",
                              borderRadius: "20px",
                              fontSize: "40px",
                              fill: "#F6AB65",
                              backgroundColor: "#F2F6F8",
                              mr: "10px",
                            }}
                          />
                        </Tooltip>
                      )}

                      {lifestyleHabits["Consuming Tobacco"] && (
                        <Tooltip title="Consuming Tobacco" arrow>
                          <img
                            src="/images/tobacco.svg"
                            style={{
                              padding: "5px",
                              marginRight: "10px",
                              height: "40px",
                              borderRadius: "20px",
                              backgroundColor: "#F2F6F8",
                            }}
                            alt="Consuming Tobacoo"
                          ></img>
                        </Tooltip>
                      )}

                      {lifestyleHabits["Vegetarian"] && (
                        <Tooltip title="Vegetarian" arrow>
                          <img
                            src="/images/vegetarian-diet.svg"
                            style={{
                              padding: "5px",
                              marginRight: "10px",
                              height: "40px",
                              borderRadius: "20px",
                              backgroundColor: "#F2F6F8",
                            }}
                            alt="Vegetarian"
                          ></img>
                        </Tooltip>
                      )}

                      {lifestyleHabits["Eggetarian"] && (
                        <Tooltip title="Eggetarian" arrow>
                          <img
                            src="/images/eggetarian-diet.svg"
                            style={{
                              padding: "5px",
                              marginRight: "10px",
                              height: "40px",
                              borderRadius: "20px",
                              backgroundColor: "#F2F6F8",
                            }}
                            alt="Eggetarian"
                          ></img>
                        </Tooltip>
                      )}

                      {lifestyleHabits["Non-vegetarian"] && (
                        <Tooltip title="Non-vegetarian" arrow>
                          <img
                            src="/images/non-vegetarian-diet.svg"
                            style={{
                              padding: "5px",
                              marginRight: "10px",
                              height: "40px",
                              borderRadius: "20px",
                              backgroundColor: "#F2F6F8",
                            }}
                            alt="Non-vegetarian"
                          ></img>
                        </Tooltip>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <hr
                    style={{
                      backgroundColor: "#888888",
                      border: "none",
                      height: "1px",
                    }}
                  ></hr>
                  <Accordion
                    defaultExpanded
                    sx={{
                      backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.1)" : "",
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        minHeight: "30px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      <Typography
                        className={isTeleconsultation ? "about-title-tele" : "about-title"}
                      >
                        Medical History
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: "3px 12px" }}>
                      {medicalHistory.length === 0 ? (
                        <div>No medical history found</div>
                      ) : (
                        medicalHistory.map((item) => (
                          <>
                            <ListItem sx={{ p: "0px 16px" }} key={item}>
                              <ListItemText
                                primaryTypographyProps={{
                                  sx: { color: "#747B8D !important" },
                                }}
                                primary={item?.procedureResponse.name}
                              ></ListItemText>
                            </ListItem>
                          </>
                        ))
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <hr
                    style={{
                      backgroundColor: "#888888",
                      border: "none",
                      height: "1px",
                    }}
                  ></hr>
                  <Accordion
                    defaultExpanded
                    sx={{
                      backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.1)" : "",
                      margin: "0px !important",
                      ":: before": { opacity: 0 },
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        minHeight: "30px !important",
                        "& .MuiAccordionSummary-content": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      <Typography
                        className={isTeleconsultation ? "about-title-tele" : "about-title"}
                      >
                        Active Medication
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: "3px 12px" }}>
                      {activeMedications.length === 0 ? (
                        <div>No ongoing medications</div>
                      ) : (
                        activeMedications.map((item) => (
                          <>
                            <ListItem sx={{ p: "5px 10px" }} key={item}>
                              <ListItemIcon>
                                <VaccinesOutlined sx={{ fill: "#0C2D48" }} />
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{
                                  sx: { color: "#747B8D !important" },
                                }}
                                primary={item?.medicationResponse.name}
                              ></ListItemText>
                            </ListItem>
                          </>
                        ))
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Reveal>
              </TabPanel>

              <TabPanel value="2" sx={{ padding: "24px 0px 0px 15px", width: "100%" }}>
                <div style={{ display: "flex", marginBottom: "40px" }}>
                  <CustomButton
                    label={"Request Consent"}
                    onClick={() => {
                      setIframeOpen(true);
                      setIframeSrc(
                        `https://abdmgateway.argusservices.in/abdm-m3-ui/consent-list?token=${hiuToken}&username=${doctorId}&searchValue=${patientAbhaId}&themeColor=004c70`
                      );
                    }}
                    style={{ marginLeft: "auto" }}
                    className={"btn--primary"}
                  />
                </div>
                {records.length === 0 ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "100px" }}
                  >
                    <Typography sx={{ color: "#888", fontStyle: "italic" }}>
                      No Records Found
                    </Typography>
                    <Typography
                      sx={{ color: "#888", fontStyle: "italic", alignContent: "flex-start" }}
                    >
                      Request Consent To View Patient's Record
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Stack spacing={2} sx={{ width: "100%" }}>
                      {records?.map((record, index) => (
                        <CustomButton
                          label={dayjs(record?.creationDate).format(DATE_FORMAT)}
                          onClick={() => {
                            setIframeOpen(true);
                            setIframeSrc(record?.viewFhirUrl);
                          }}
                          className={"btn--primary-light"}
                          style={{ width: "100%" }}
                        />
                      ))}
                    </Stack>
                  </Grid>
                )}
              </TabPanel>

              <TabPanel value="3">
                {visits.length === 0 ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "200px" }}
                  >
                    <Typography sx={{ color: "#888", fontStyle: "italic" }}>
                      No visits found
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {visits.map((visit, index) => (
                      <Grid item xs={12} key={index}>
                        <Card
                          sx={{
                            borderRadius: "12px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <CardContent sx={{ padding: "16px 24px" }}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Typography sx={{ color: "#0C2D48", fontWeight: 600 }}>
                                  Date:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography sx={{ color: "#333" }}>
                                  {dayjs(visit.appointmentDate).format("DD-MM-YYYY")}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="space-between"
                              style={{ padding: "8px" }}
                            >
                              <div style={{ display: "flex" }}>
                                <Grid item>
                                  <Typography
                                    sx={{ color: "#0C2D48", fontWeight: 600, marginRight: 1 }}
                                  >
                                    Reason:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography sx={{ color: "#333" }}>{visit.notes}</Typography>
                                </Grid>
                              </div>
                              <Grid item xs={1} container justifyContent="stretch">
                                {visit.type === "CLINIC_VISIT" && (
                                  <DirectionsWalk sx={{ color: "#0C2D48" }} />
                                )}
                                {visit.type === "TELECONSULTATION" && (
                                  <VideoCall sx={{ color: "#0C2D48" }} />
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </TabPanel>
            </TabContext>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
  // );
};

export default PatientHealthDetails;
