import React from "react";
import { HEART } from "utils/ehr-data-utils";

const HumanHeart = ({ selectedOrgan, onClick, organKey, getOrganVitalCharts }) => {
  const isSelected = selectedOrgan.includes(HEART);
  return (
    <div
      id="human-body-heart"
      className={`human-heart ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <img
        src="/images/heart.svg"
        alt="Outer Body"
        className={`heart hover body-part ${isSelected ? "highlight" : ""}`}
        // className={`heart hover body-part`}
      />
    </div>
  );
};

export default HumanHeart;
