// project imports
import MinimalLayout from "layout/MinimalLayout";
import ErrorPage from "views/Components/Error/ErrorPage";
import DoctorOnboardingPage from "views/onboarding/DoctorOnboarding/DoctorOnboardingPage";
import RegisterVerification from "views/onboarding/RegisterVerification";
import Login from "views/pages/authentication/authentication3/Login3";
import Register from "views/pages/authentication/authentication3/Register3";
import DemoView from "views/pages/demo/DemoView";

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  errorElement: <ErrorPage />,
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/onboarding",
      element: <DoctorOnboardingPage />,
    },
    {
      path: "/verification",
      element: <RegisterVerification />,
    },
    {
      path: "/demo",
      element: <DemoView />,
    },
  ],
};

export default AuthenticationRoutes;
