import React from "react";
import { Grid, Menu, MenuList } from "@mui/material";
import "../../assets/scss/style.scss";
import CustomButton from "./CustomButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import {
  FEMALE,
  CLINIC_VISIT,
  TELE_CONSULTATION,
  currentActiveUser,
  CHECKED_IN,
  SCHEDULED,
  RESCHEDULED,
  FRONT_DESK,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
  DATE_FORMAT,
  HOUR_MINUTE_FORMAT,
} from "store/constant";
import { useState } from "react";
// import CustomMenuItem from "./CustomMenuItem";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import ModalUI from "ui-component/ModalUI";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Actions/appointmentDataAction";
import { getAppointmentById, updateAppointmentStatus } from "services/Appointments";
import { useContext } from "react";
import { ToastContext } from "./CustomToast";
import { useCallback } from "react";
import { getPatientData, getProfilePicture } from "services/patientService";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { calculateAge } from "utils/calculate-age";
import {
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Actions/PrescriptionPadDataActions";
import { getColorAndBackgroundColorBySeverity } from "utils/calculate-vital-color";

const AppointmentListItem = ({ appointment, setIsUpdated }) => {
  const [isFrontDeskUser, setisFrontDeskUser] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const user = currentActiveUser();
  const [profileImage, setProfileImage] = useState(null);

  const dispatch = useDispatch();

  const formattedTime = (timeString) => {
    const date = dayjs(`1970-01-01T${timeString}`);
    return date.format(HOUR_MINUTE_FORMAT);
  };

  const today = dayjs();
  const tomorrow = today.add(1, "day");

  let appointmentDay;
  if (dayjs(appointment?.appointmentDate).isSame(today, "day")) {
    appointmentDay = "Today";
  } else if (dayjs(appointment?.appointmentDate).isSame(tomorrow, "day")) {
    appointmentDay = "Tomorrow";
  } else {
    appointmentDay = dayjs(appointment?.appointmentDate).format(DATE_FORMAT);
  }

  useEffect(() => {}, [navigate]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseBookingModal = async () => {
    setIsUpdated((prev) => !prev);
    setIsBookingModalOpen(false);
    // toDO when frontdesk users association completed at that time this needs to be modified
    // associated doctorId should be passed here
  };

  const handleEditAppointment = async () => {
    const selectedAppointment = await getAppointmentById(appointment?.id);
    let timeArr = appointment.appointmentTime.split(":");
    let apDate = dayjs(appointment.appointmentDate);
    apDate = apDate.set("hour", Number(timeArr[0])).set("minute", Number(timeArr[1]));
    // if hours & minutes are less than 0 then 0 should be added as a prefix
    const appointmentTime = apDate.format(HOUR_MINUTE_FORMAT);
    const appointmentData = {
      patientMobile: selectedAppointment?.data?.patient?.user?.mobileNumber,
      id: appointment?.id,
      orgId: appointment?.orgId,
      appointmentTime: appointmentTime,
      patientId: appointment?.patientId,
      doctorId: appointment?.doctorId,
      appointmentDate: appointment?.appointmentDate,
      notes: appointment?.notes,
      type: appointment?.type,
    };

    dispatch(setSelectedAppointmentData(appointmentData));
    setIsBookingModalOpen(true);
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleEditAppointment,
    },
  ];

  const handleJoinRoom = useCallback(async () => {
    try {
      const room = appointment?.id?.toString();
      navigate(`/home/prescriptionPad/${room}`, {
        state: {
          roomId: room,
        },
      });
    } catch (e) {
      handleClick("error", "Not able to join the call");
    }
  }, [appointment?.id, navigate, handleClick]);

  useEffect(() => {
    const checkFrontDeskUser = () => {
      if (user && user.roleName === FRONT_DESK) {
        setisFrontDeskUser(true);
      }
    };
    // userId of patient is not here for now
    const fetchProfilePicture = async () => {
      try {
        const patientResponse = await getPatientData(appointment.patientId);
        const profilePicResponse = await getProfilePicture(patientResponse.data.user.id);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;

        setProfileImage({
          filename: profilePicResponse.data.filename,
          content,
        });
      } catch (error) {
        console.error("Error fetching profile picture", error);
      }
    };
    checkFrontDeskUser();
    fetchProfilePicture();
  }, [appointment]);

  const genderBasedImage = appointment?.gender === FEMALE ? "/images/woman.png" : "/images/man.png";

  const manageCommonClickForFrontDeskANdDoctor = async () => {
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: [],
        symptomsArr: [],
        medicationsArr: [],
        labInvestigationsArr: [],
        advicesArr: [],
      })
    );
    dispatch(setPrescriptionId(null));
    if (isFrontDeskUser) {
      if (appointment.appointmentStatus === CHECKED_IN) {
        handleClick("success", "Patient has already Checked In!");
      } else {
        try {
          await updateAppointmentStatus(appointment.id, CHECKED_IN);
          appointment.appointmentStatus = CHECKED_IN;
          handleClick("success", "Checked In successfully!");
        } catch (error) {
          handleClick("error", "Cant able to check in!");
        }
      }
    } else {
      if (appointment.type === TELE_CONSULTATION) {
        handleJoinRoom();
      } else {
        navigate(`/home/prescriptionPad/${appointment?.id}`);
      }
    }
  };

  const checkAppointmentStatus = () => {
    const availableStatus = [SCHEDULED, RESCHEDULED];
    if (availableStatus.includes(appointment.appointmentStatus)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Reveal>
      <ModalUI
        visible={isBookingModalOpen}
        close={() => {
          dispatch(setSelectedAppointmentData({}));
          setIsBookingModalOpen(false);
        }}
        title="Reschedule Appointment"
        style={{
          overflowY: "scroll",
          height: "33.375rem",
          width: "38.125rem",
        }}
        component={
          <BookAppointmentModal
            reschedule={true}
            data={isBookingModalOpen}
            closeModal={handleCloseBookingModal}
          />
        }
      />

      <li className="mui-card list-card" style={{ marginBottom: "10px" }}>
        <Grid container className="row mx-0 mb-0 w-100">
          <Grid item md={8} className="px-0">
            <div className="">
              <div className="px-0">
                <Grid container className="row align-items-center">
                  <Grid item>
                    <img
                      style={{
                        borderRadius: "8px",
                        marginRight: "6px",
                        width: "50px",
                        height: "50px",
                      }}
                      className="patient-avatar"
                      src={profileImage ? profileImage?.content : genderBasedImage}
                      alt=""
                    />
                  </Grid>
                  <Grid item>
                    <p className="patient-name">{appointment?.patientName}</p>
                    <p className="patient-details">
                      <span>{calculateAge(appointment?.dateOfBirth)} years</span>
                      <span> | </span>
                      <span>{appointment.gender ? appointment.gender : "NA"}</span>
                    </p>
                  </Grid>
                </Grid>
              </div>
              <div className="px-0">
                <div className="row mb-2">
                  {appointment.chronicDiseases?.map((chronic, index) => {
                    const colorCode = getColorAndBackgroundColorBySeverity(chronic.severity);
                    return (
                      <div
                        key={index}
                        className="mui-badge badge"
                        style={{
                          color: colorCode?.color,
                          backgroundColor: colorCode?.backgroundColor,
                        }}
                      >
                        {chronic.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="px-0">
                <div className="row mb-0 patient-details">
                  {appointment?.type === CLINIC_VISIT && (
                    <div className="col visit-type-offline">
                      <i className="ri-walk-fill ri-lg" />
                      {TEXT_FOR_CLINIC_VISIT}
                    </div>
                  )}
                  {appointment?.type === TELE_CONSULTATION && (
                    <div className="col visit-type-online">
                      <img src="/images/hc_online_icon.svg" className="online" alt="online" />
                      {TEXT_FOR_TELECONSULTATION}
                    </div>
                  )}

                  <div className="col additional-details">
                    {formattedTime(appointment?.appointmentTime)}
                  </div>
                  <div className="col additional-details">{appointmentDay}</div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item md={4} className="px-0">
            <Grid container className="h-100" direction="column" justifyContent="space-between">
              <div>
                {/* because we have already given the reshcedule button below */}
                {/* and also once checked in appointment can not be rescheduled */}
                {/* so we dont need this menu */}
                {/* <ul className="horizontal-ul">
                  <li>
                    <div className="mui-dropdown">
                      <CustomButton
                        iconButton={<i className="ri-more-2-fill" />}
                        onClick={(event) => handleMenuOpen(event)}
                        aria-label="Actions"
                      />
                      <Menu
                        elevation={1}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: {
                            borderRadius: "4px",
                          },
                        }}
                      >
                        <MenuList dense sx={{ p: 0 }}>
                          {actions.map((action, actionIndex) => (
                            <CustomMenuItem
                              key={actionIndex}
                              onClick={() => {
                                handleMenuClose();
                                action.onClick(appointment);
                              }}
                              icon={action.icon}
                              text={action.label}
                            />
                          ))}
                        </MenuList>
                      </Menu>
                    </div>
                  </li>
                </ul> */}
              </div>
              <div>
                {checkAppointmentStatus() && (
                  <>
                    <CustomButton
                      label="Reschedule"
                      className={"btn--secondary-light mui-pull-right p-lr-35"}
                      startIcon={<CalendarMonthIcon />}
                      onClick={handleEditAppointment}
                    />
                  </>
                )}
              </div>
              <div>
                {checkAppointmentStatus() && (
                  <CustomButton
                    label={
                      isFrontDeskUser && appointment.type !== TELE_CONSULTATION
                        ? "Check In"
                        : "Start Consultation"
                    }
                    style={{ marginTop: "20px" }}
                    className={
                      isFrontDeskUser && appointment.type !== TELE_CONSULTATION
                        ? "btn--primary mui-btn mui-btn--primary width-fit-content mui-pull-right p-lr-47"
                        : "btn--primary mui-btn mui-btn--primary width-fit-content mui-pull-right"
                    }
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                    onClick={manageCommonClickForFrontDeskANdDoctor}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </li>
    </Reveal>
  );
};

export default AppointmentListItem;
