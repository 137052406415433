import React, { useState } from "react";
import BloodTest from "../BloodTest";
import SugarTest from "../HeartTest";
import "assets/scss/humanBody.scss";
import HumanLungs from "./HumanLungs";
import HumanLiver from "./HumanLiver";
import HumanIntenstines from "./HumanIntenstines";
import HumanBlader from "./HumanBlader";
import HumanStomach from "./HumanStomach";
import HumanKidney from "./HumanKidney";
import HumanHeart from "./HumanHeart";
import HumanBrain from "./HumanBrain";
import {
  BLADDER,
  BRAIN,
  HEART,
  INTESTINES,
  KIDNEY,
  LIVER,
  LUNGS,
  MOUTH,
  STOMACH,
  THROAT,
} from "utils/ehr-data-utils";
import HumanSkeleton from "../HumanSkeleton/HumanSkeleton";
import HumanMouth from "./HumanMouth";
import HumanThroat from "./HumanThroat";

const HumanBody = ({
  selectedOrgan,
  setSelectedOrgan,
  getOrganVitalCharts,
  setSelectedSpecialization,
  selectedSpecialization,
}) => {
  const handleOrganClick = (organKey) => {
    let newSelectedOrgan;

    // if (selectedOrgan.includes(organKey)) {
    //   newSelectedOrgan = selectedOrgan.filter((key) => key !== organKey);
    // } else {
    //   newSelectedOrgan = [...selectedOrgan, organKey];
    // }
    if (selectedSpecialization) {
      setSelectedSpecialization("");
    }

    if (selectedOrgan.includes(organKey)) {
      newSelectedOrgan = [];
    } else {
      newSelectedOrgan = [organKey];
    }
    setSelectedOrgan(newSelectedOrgan);
    getOrganVitalCharts(newSelectedOrgan);
  };

  // const handleMouseEnter = (organ) => {
  //   setSelectedOrgan(organ);
  // };

  // const handleMouseLeave = () => {
  //   setSelectedOrgan(null);
  // };

  return (
    <div className="human-body">
      <div className="human-body-skeleton">
        <img className="skeleton" src="/images/skeleton.svg" alt="human-skeleton" />
      </div>
      <div className="human-body-container">
        {/* Pass `selectedOrgan` and `onClick` handler to each component */}
        <HumanBrain selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(BRAIN)} />
        <div className="human-lower-body-container">
          <HumanMouth selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(MOUTH)} />
          <HumanThroat selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(THROAT)} />
          <div
            className="internal-lower-human-body-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <HumanLungs selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(LUNGS)} />
            <HumanLiver selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(LIVER)} />
            <HumanIntenstines
              selectedOrgan={selectedOrgan}
              onClick={() => handleOrganClick(INTESTINES)}
            />
          </div>
          <HumanStomach
            selectedOrgan={selectedOrgan}
            onClick={() => handleOrganClick(STOMACH)}
            id="stomach-organ"
          />
          <HumanKidney selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(KIDNEY)} />
          <HumanBlader selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(BLADDER)} />
          <HumanHeart selectedOrgan={selectedOrgan} onClick={() => handleOrganClick(HEART)} />
        </div>
      </div>
    </div>
  );
};

export default HumanBody;
