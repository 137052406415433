import "assets/scss/custom-charts.scss";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { padding } from "@mui/system";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CustomSingleBarChart = ({
  title,
  displayName,
  parentDivStyle,
  graphStyle,
  noHeading,
  customOptions,
  values,
  labels,
  barColor = "#29bf91",
  yAxisRange,
  xAxisLabel,
  yAxisLabel,
  barThickness,
}) => {
  const [sortedLabels, setSortedLabels] = useState([]);
  const [sortedValues, setSortedValues] = useState([]);

  useEffect(() => {
    if (values && labels) {
      const parseDateString = (dateString) => {
        const [day, month, year] = dateString.split("/");
        return new Date(`20${year}`, month - 1, day);
      };

      const combinedData = labels.map((label, index) => ({
        label: label,
        value: values[index].value,
      }));

      const sortedData = combinedData.sort(
        (a, b) => parseDateString(a.label) - parseDateString(b.label)
      );

      const sortedLabels = sortedData.map((item) => item.label);
      const sortedValues = sortedData.map((item) => ({ value: item.value }));

      setSortedLabels(sortedLabels);
      setSortedValues(sortedValues);
    }
  }, [values, labels]);

  const data = {
    labels: sortedLabels,
    datasets: [
      {
        label: "Measurement",
        data: sortedValues.map((item) => item.value),
        backgroundColor: barColor,
        borderWidth: 0,
        barThickness: barThickness || 10,
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    ],
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel || "", // X-Axis label
        },
        stacked: true,
        grid: {
          color: "#B5BBC233",
          drawTicks: false,
        },
        ticks: {
          display: true,
          padding: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel || "", // Y-Axis label
        },
        stacked: true,
        grid: {
          color: "#B5BBC233",
          drawTicks: false,
        },
        beginAtZero: false,
        min: yAxisRange?.updatedMin,
        max: yAxisRange?.updatedMax,
        ticks: {
          display: true,
          stepSize: yAxisRange?.stepSize,
          autoSkip: false,
          padding: 10,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const options = { ...defaultOptions, ...customOptions };

  return (
    <div className="custom-bar-chart" style={{ ...parentDivStyle }}>
      {!noHeading && (
        <div className="heading">
          <div className="title">
            <p>{displayName}</p>
          </div>
          <div className="actions"></div>
        </div>
      )}
      <div className="main-chart">
        <Bar style={{ ...graphStyle }} data={data} options={options} />
      </div>
      {/* <div className="footer"></div> */}
    </div>
  );
};

export default CustomSingleBarChart;
