import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

// project imports
import AuthWrapper1 from "../AuthWrapper1";
import AuthCardWrapper from "../AuthCardWrapper";
import AuthLogin from "../auth-forms/AuthLogin";
import Logo from "ui-component/Logo";
import AuthFooter from "ui-component/cards/AuthFooter";
import SyncModal from "views/Components/Sync/SyncModal";
import { startCloudSync } from "services/SyncService";
import { useEffect } from "react";
import { useLoader } from "layout/LoaderContext";
import {
  setShowSyncModal,
  setSyncStatus,
  setIsSyncing,
  setLastSyncInfo,
} from "store/Actions/CloudSyncActions";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useSelector } from "react-redux";
import { getLastSyncInfo } from "store/Reducers/CloudSyncReducer";
import dayjs from "dayjs";
import { appEnv } from "store/constant";
import RIf from "ui-component/RIf";
// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const dispatcher = useDispatch();
  const { showLoader, hideLoader } = useLoader();
  const { handleClick: showToast } = useContext(ToastContext);
  const lastSyncInfo = useSelector(getLastSyncInfo);

  const isRunningOnElectron = appEnv === "electron";

  useEffect(() => {
    if (isRunningOnElectron) {
      showLoader();
      return () => hideLoader();
    }
  }, []);

  useEffect(() => {
    if (!isRunningOnElectron) return;
    if (lastSyncInfo.push !== "loading") hideLoader();
    if (!lastSyncInfo.push || !lastSyncInfo.pull) {
      dispatcher(setShowSyncModal(true));
      syncData();
    }
  }, [lastSyncInfo]);

  const syncData = async () => {
    dispatcher(setIsSyncing(true));
    try {
      await startCloudSync();
      dispatcher(setSyncStatus("SUCCESS"));
      const lSD = dayjs();
      dispatcher(
        setLastSyncInfo({
          push: lSD.toISOString(),
          pull: lSD.toISOString(),
        })
      );
      setTimeout(() => {
        dispatcher(setShowSyncModal(false));
      }, 1500);
    } catch (e) {
      dispatcher(setSyncStatus("FAILED"));
      showToast("warning", "Sync failed, app might not function properly");
    }
    dispatcher(setIsSyncing(false));
  };

  return (
    <AuthWrapper1>
      <RIf show={isRunningOnElectron}>
        <SyncModal />
      </RIf>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: "100vh" }}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "calc(100vh - 68px)" }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item>
                    <Link to="#">
                      {/* <Logo /> */}
                      <img
                        src="/images/arog-logo.svg"
                        alt="logo"
                        className="img-fluid"
                        height="100px"
                        width="160px"
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? "column-reverse" : "row"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? "h3" : "h2"}
                          >
                            Welcome To Arog
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={matchDownSM ? "center" : "inherit"}
                          >
                            Please Enter your details to continue
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthLogin />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
};

export default Login;
