import { SET_SELECTED_DOCTOR } from "store/Actions/appointmentActions";

const initialState = {
  selectedDoctor: null,
};

const appointmentReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_SELECTED_DOCTOR: {
      return { ...state, selectedDoctor: action.payload };
    }
    default:
      return state;
  }
};

export default appointmentReducer;
export const getSelectedDoctor = (state) => state.appointment.selectedDoctor;
