import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  Box,
  FormHelperText,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
// third party
import * as Yup from "yup";
import { Formik } from "formik";
// project imports
import useScriptRef from "hooks/useScriptRef";
// assets
import { useNavigate } from "react-router";
import * as React from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useRef } from "react";
import { MENU_OPEN } from "store/actions";
import CustomButton from "ui-component/custom-components/CustomButton";
import { FingerprintOutlined, VerifiedOutlined } from "@mui/icons-material";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { useContext } from "react";
import { getOauthAccessToken, login, otpGenerate, verifyInit } from "services/AuthService";
import {
  ABHA_NUMBER,
  CLINIC_ADMIN,
  DOCTOR,
  FRONT_DESK,
  HPR_ID,
  MOBILE_NUMBER,
  VERIFY_ABHA_NUMBER,
  VERIFY_HPR,
  VERIFY_MOBILE_NUMBER,
} from "store/constant";
import { setShowVerify } from "store/Actions/doctorOnboardingActions";
import { setUserFeatures, setUserOrgId, setFrontDeskUserLinking } from "store/Actions/userActions";

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);
  const scriptedRef = useScriptRef();
  const [isOtpSent, setisOtpSent] = useState(false);
  const navigate = useNavigate();

  const [timer, setTimer] = useState(60); // Set the initial timer to 60 seconds

  const formRef = useRef(null);
  const otpFieldRef = useRef(null);
  const [contactFieldType, setcontactFieldType] = useState(MOBILE_NUMBER);

  const handleSignUp = async () => {
    let fieldType;
    let fieldValue;
    if (contactFieldType === MOBILE_NUMBER) {
      fieldType = VERIFY_MOBILE_NUMBER;
      fieldValue = formRef.current.values.number;
    } else if (contactFieldType === HPR_ID) {
      fieldType = VERIFY_HPR;
      fieldValue = formRef.current.values.hpr;
    } else if (contactFieldType === ABHA_NUMBER) {
      fieldType = VERIFY_ABHA_NUMBER;
      fieldValue = formRef.current.values.abha;
    }
    try {
      const payload = {
        contactField: fieldValue,
        verificationType: fieldType,
      };
      const response = await verifyInit(payload);
      dispatch(setShowVerify(true));
      navigate("/verification", {
        state: {
          fieldType: fieldType,
          fieldValue: fieldValue,
          otpSent: true,
          txnId: response.data.txnId,
        },
      });
    } catch (error) {
      handleClick("error", "Error genertating OTP");
    }
  };

  const OtpResent = async () => {
    formRef.current.values.otp = "";
    const mobilenumber = formRef.current.values.number;
    try {
      const response = await otpGenerate(mobilenumber, MOBILE_NUMBER);
      otpFieldRef.current.focus();
      setTimer(60);
      handleClick("success", "OTP for login has been sent to your registered mobile number.");
      return response;
    } catch (error) {
      if (error && error?.response?.status === 404) {
        handleSignUp();
        handleClick("warning", "Register as new user");
      } else {
        handleClick("error", "Error generating OTP");
      }
    }
  };

  const OTPCHECKED = async () => {
    if (formRef.current.isValid) {
      const { number, abha, hpr } = formRef.current.values;
      if (
        formRef.current.values.number ||
        formRef.current.values.abha ||
        formRef.current.values.hpr
      ) {
        try {
          let contactField, fieldType;
          switch (contactFieldType) {
            case MOBILE_NUMBER:
              if (number && number.length !== 10 && !/^[6-9][0-9]{9}$/.test(number)) {
                handleClick("error", "Enter valid 10 digit Mobile");
              } else {
                contactField = formRef.current.values.number;
                fieldType = MOBILE_NUMBER;
              }
              break;
            case ABHA_NUMBER:
              if (abha && abha.length !== 14 && !/^[0-9]{14}$/.test(abha)) {
                handleClick("error", "Enter valid 14 digit ABHA Number");
              } else {
                contactField = formRef.current.values.abha;
                fieldType = ABHA_NUMBER;
              }
              break;
            case HPR_ID:
              if (hpr && hpr.length <= 10) {
                handleClick("error", "Enter valid HPR");
              } else {
                contactField = formRef.current.values.hpr;
                fieldType = HPR_ID;
              }
              break;
            default:
              break;
          }
          const response = await otpGenerate(contactField, fieldType);
          setisOtpSent(true);
          setTimer(60);
          handleClick("success", "OTP for login has been sent to your registered mobile number.");
          return response;
        } catch (error) {
          if (error && error.response?.status === 404) {
            handleSignUp();
            // handleClick("warning", error.response?.data);
          } else {
            // handleClick("error", error.response?.data);
          }
        }
      } else {
        handleClick("error", "Please fill required field !!");
      }
    }
  };

  const onKeyPress = (event, errors, isValid, handleSubmit) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!isOtpSent) {
        switch (contactFieldType) {
          case MOBILE_NUMBER:
            formRef.current.setTouched({ number: true });
            break;

          case ABHA_NUMBER:
            formRef.current.setTouched({ abha: true });
            break;

          case HPR_ID:
            formRef.current.setTouched({ hpr: true });
            break;

          default:
            break;
        }
        OTPCHECKED();
      } else if (isOtpSent) {
        // Sign In button is active
        if (isValid) {
          handleSubmit();
        }
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    let interval = null;

    if (isOtpSent) {
      setTimer(60); // Reset the timer when OTP is sent
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isOtpSent]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60); // Calculate minutes
    const secs = seconds % 60; // Calculate remaining seconds
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`; // Format as MM:SS
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          number: "",
          abha: "",
          hpr: "",
          otp: "",
          username: "",
          password: "",
          submit: null,
        }}
        validationSchema={() => {
          if (contactFieldType === MOBILE_NUMBER) {
            let validation = Yup.object().shape({
              number: Yup.string()
                .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number")
                .required("Please enter a valid mobile number"),
            });

            if (isOtpSent) {
              validation = validation.concat(
                Yup.object().shape({
                  otp: Yup.string()
                    .matches(/^[0-9]{6}$/, "Please enter a valid 6-digit OTP")
                    .required("Please enter a valid 6-digit OTP"),
                })
              );
            }
            return validation;
          } else if (contactFieldType === ABHA_NUMBER) {
            let validation = Yup.object().shape({
              abha: Yup.string()
                .matches(/^[0-9]{14}$/, "Enter Valid ABHA Number")
                .required("ABHA number is Required"),
            });

            if (isOtpSent) {
              validation = validation.concat(
                Yup.object().shape({
                  otp: Yup.string()
                    .matches(/^[0-9]{6}$/, "Please enter a valid 6-digit OTP")
                    .required("Please enter a valid 6-digit OTP"),
                })
              );
            }
            return validation;
          } else if (contactFieldType === HPR_ID) {
            let validation = Yup.object().shape({
              hpr: Yup.string().required("HPR ID is Required").min(11, "Please enter valid HPR ID"),
            });

            if (isOtpSent) {
              validation = validation.concat(
                Yup.object().shape({
                  otp: Yup.string()
                    .matches(/^[0-9]{6}$/, "Please enter a valid 6-digit OTP")
                    .required("Please enter a valid 6-digit OTP"),
                })
              );
            }
            return validation;
          } else {
            return Yup.object().shape({});
          }
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // to DO
          let contactField, fieldType;
          switch (contactFieldType) {
            case MOBILE_NUMBER:
              contactField = formRef.current.values.number;
              fieldType = MOBILE_NUMBER;
              break;
            case ABHA_NUMBER:
              contactField = formRef.current.values.abha;
              fieldType = ABHA_NUMBER;
              break;
            case HPR_ID:
              contactField = formRef.current.values.hpr;
              fieldType = HPR_ID;
              break;
            default:
              break;
          }
          const otp = formRef.current.values.otp;

          try {
            const response = await getOauthAccessToken(contactField, fieldType, otp);

            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(true);
            }

            const accessToken = response?.data?.access_token;
            const refreshToken = response?.data?.refresh_token;
            const decodedValue = jwtDecode(accessToken);
            localStorage.setItem("isOTPLogin", true);
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("mobilenumber", decodedValue?.sub);
            localStorage.setItem("loggedIn", true);

            const responseList = await login(decodedValue?.sub);
            const userResponse = responseList.data.userResponse;
            localStorage.setItem("currentActiveUser", JSON.stringify(userResponse));
            let userFeatures = responseList.data.features;
            if (Object.keys(userFeatures).length === 0) {
              userFeatures = { USER: "" };
            }
            dispatch(setUserFeatures(userFeatures));

            const roleArr = [CLINIC_ADMIN, DOCTOR, FRONT_DESK];
            if (
              userResponse.roleName === FRONT_DESK &&
              userResponse.userOrganizationAssociationList.length === 0
            ) {
              dispatch(setFrontDeskUserLinking(true));
            } else {
              dispatch(setFrontDeskUserLinking(false));
            }

            if (roleArr.includes(userResponse.roleName)) {
              dispatch(
                setUserOrgId(userResponse.userOrganizationAssociationList[0]?.organizationId)
              );
            }
            dispatch({ type: "SET_ACCESS_TOKEN", payload: accessToken });
            handleClick("success", "Welcome to Arog!");
            setTimeout(() => {
              navigate("/home/dashboard");
            }, 1000);
          } catch (err) {
            handleClick("error", "Please enter valid credentials");
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: "Please enter valid credentials" });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
        }) => (
          <form
            onKeyDown={(e) => onKeyPress(e, errors, isValid, handleSubmit)}
            noValidate
            onSubmit={() => {
              handleSubmit();
              dispatch({ type: MENU_OPEN, id: "default" });
            }}
            {...others}
          >
            <RadioGroup
              aria-label="contact-field-type"
              name="contact-field-type"
              value={contactFieldType}
              onChange={(e) => {
                setcontactFieldType(e.target.value);
                setisOtpSent(false);
                formRef.current.values.otp = "";
                formRef.current.touched.otp = false;
              }}
              style={{ height: "50px" }}
            >
              <Grid item xs={6}>
                <FormControlLabel
                  value="LOGIN_MOBILE_NUMBER"
                  control={<Radio />}
                  label="Mobile Number"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="LOGIN_ABHA_NUMBER" control={<Radio />} label="ABHA" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="LOGIN_HPR" control={<Radio />} label="HPR" />
              </Grid>
            </RadioGroup>
            {contactFieldType === MOBILE_NUMBER && (
              <FormInputField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Mobile Number"
                type={"tel"}
                name="number"
                value={values.number}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.number && errors.number)}
                errorText={errors.number}
                startAdornment={<i className="ri-phone-line ri-xl" />}
                size={"big"}
              />
            )}
            {contactFieldType === ABHA_NUMBER && (
              <FormInputField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="ABHA Number"
                name="abha"
                value={values.abha}
                type={"tel"}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.abha && errors.abha)}
                errorText={errors.abha}
                startAdornment={<FingerprintOutlined />}
                size={"big"}
              />
            )}
            {contactFieldType === HPR_ID && (
              <FormInputField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="HPR"
                name="hpr"
                type={"text"}
                value={values.hpr}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.hpr && errors.hpr)}
                errorText={errors.hpr}
                startAdornment={<VerifiedOutlined />}
                size={"big"}
              />
            )}
            {isOtpSent && (
              <FormInputField
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="OTP"
                name="otp"
                type={"tel"}
                value={values.otp}
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{
                  maxLength: 6,
                }}
                error={Boolean(touched.otp && errors.otp)}
                errorText={errors.otp}
                startAdornment={<FingerprintOutlined />}
                size={"big"}
                autoFocus={true}
                inputRef={otpFieldRef}
              />
            )}
            <Stack direction="row" alignItems="center" justifyContent="start" spacing={1}>
              {isOtpSent && (
                <>
                  <Typography
                    onClick={timer === 0 ? () => OtpResent() : undefined}
                    variant="subtitle1"
                    color={timer === 0 ? "secondary" : "textSecondary"}
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Resend OTP
                  </Typography>
                  {timer !== 0 && (
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      sx={{ fontWeight: 500, ml: "4px !important" }}
                    >
                      in {formatTime(timer)}
                    </Typography>
                  )}
                </>
              )}
            </Stack>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            {!isOtpSent && (
              <Box sx={{ mt: 2 }}>
                <CustomButton
                  className="btn--primary"
                  onClick={() => {
                    OTPCHECKED();
                  }}
                  height="45px"
                  disableElevation
                  width="100%"
                  type="button"
                  label="Send OTP"
                />
              </Box>
            )}
            {isOtpSent && (
              <Box sx={{ mt: 2 }}>
                <CustomButton
                  className="btn--primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                  disableElevation
                  width="100%"
                  type="button"
                  label="Sign In"
                  height="45px"
                />
              </Box>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
export default FirebaseLogin;
