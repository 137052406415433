import React, { useState, useEffect } from "react";
import {
  CLINIC_ADMIN,
  CLINIC_VISIT,
  DOCTOR,
  PATIENT,
  SCHEDULED,
  TELE_CONSULTATION,
  currentActiveUser,
  FRONT_DESK,
  getRoleIdByName,
  DATE_FORMAT,
  HOUR_MINUTE_FORMAT,
  RESCHEDULED,
  startsAndEndsWithAlphabetic,
  MAX_AGE_FOR_REGUAR_DOB,
  APPOINTMENT_TYPE_SYSTEM,
  DATE_FORMAT_DMY,
  convertTimeForTimeFormat,
  SYSTEM,
  UHI,
} from "store/constant";
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { PersonOutlineOutlined } from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { useDispatch, useSelector } from "react-redux";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import dayjs from "dayjs";
import {
  setAppointmentDate,
  setAppointmentTime,
  setDoctorId,
  setNotes,
  setPatientId,
  setType,
  setSelectedAppointmentData,
  setOrgId,
  setUhiAppointmentData,
  getSelectedAppointmentData,
} from "store/Slices/appointmentDataSlice";
import { getPatientFamilyDetails } from "services/patientService";
import { useContext } from "react";
import { createAppointment, getAppointmentById, updateAppointment } from "services/Appointments";
import { getSlotsAvailabilities } from "services/Availability";
import { getDoctorById, getDoctorByOrgId, initBookingUhi } from "services/doctorService";
import { createUserProfile, getPrimaryUserByMobileNumber } from "services/userService";
import { useNavigate } from "react-router";
import { blockDoctorSlots } from "utils/block-doctor-slots";
import { getUserOrgId } from "store/Slices/userSlice";
const BookAppointmentModal = ({
  reschedule,
  closeModal,
  setReschedule,
  uhiDoctorData,
  doctorDetail,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);
  const loggedInUserRole = currentActiveUser()?.roleName;
  const [isAdminToo, setIsAdminToo] = useState(false);
  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");

  const [patientsName, setPatientsName] = useState([]);
  const [orgDoctors, setOrgDoctors] = useState([]);
  const [doctorName, setDoctorName] = useState("");

  const [patientFullName, setpatientFullName] = useState("");
  const [patientEmail, setpatientEmail] = useState("");
  const [patientDOB, setpatientDOB] = useState(null);
  const [patientGender, setpatientGender] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [doctorOrganizationList, setDoctorOrganizationList] = useState([]);

  const appointmentMinDate = dayjs().startOf("day");
  const appointmentMaxDate = dayjs().add(7, "day").endOf("day");

  const currentDate = dayjs();

  const [dateTouched, setDateTouched] = useState(false);

  const handleMobileNumberChange = (e) => {
    if (touched.mobileNumber) {
      validateMobileNumber(e.target.value);
    }

    const inputNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const maxLength = 10; // Maximum allowed digits

    // Ensure the number does not exceed the maximum length
    const truncatedNumber = inputNumber.slice(0, maxLength);
    const { value } = e.target;
    setMobileNumber(truncatedNumber);
    if (value.length === 10) {
      searchPatients(value);
    }
  };

  const searchPatients = async (mobileNumber) => {
    try {
      const userResponse = await getPrimaryUserByMobileNumber(mobileNumber);
      if (userResponse.data && userResponse.data.roleName !== PATIENT) {
        setNewPatientRegistration(false);
        handleClick("error", "Please enter a valid mobile number.");
        return;
      }
      const familyId = userResponse.data?.familyResponse?.id;
      let allPatientsResponse = [
        {
          userId: userResponse.data.id,
          userResponse: userResponse.data,
          isPrimary: true,
        },
      ];
      if (familyId) {
        const response = await getPatientFamilyDetails(familyId);
        allPatientsResponse = response.data?.userFamilyMappingResponses;
      }
      dispatch(setPatientId(allPatientsResponse[0]?.userResponse?.roleBasedId));
      setPatientsName(allPatientsResponse);
      setNewPatientRegistration(false);
    } catch (error) {
      if (error.response?.status === 404) {
        setNewPatientRegistration(true);
        handleClick(
          "info",
          "Sorry, Unable to find Patient Details. Please verify the mobile number."
        );
      }
    }
  };

  const handleGenderChange = (event) => {
    setpatientGender(event.target.value);
  };

  const handlePatientDataChange = (field, value) => {
    switch (field) {
      case "patientFullName":
        if (touched.fullName && value) {
          validateFullName(value);
        } else {
          delete errors.fullName;
        }
        setpatientFullName(value);
        break;
      case "patientEmail":
        if (touched.emailId && value) {
          validateEmail(value);
        } else {
          delete errors.emailId;
        }
        setpatientEmail(value);
        break;
      case "patientDOB":
        validateDate(value);
        setpatientDOB(value);
        break;
      default:
        break;
    }
  };

  const [newPatientRegistration, setNewPatientRegistration] = useState(false);

  const [allSlots, setAllSlots] = useState([]);

  const appointmentData = useSelector(getSelectedAppointmentData);

  const selectedType = appointmentData?.type;
  const [mobileNumber, setMobileNumber] = useState(appointmentData.patientMobile ?? "");
  const [canDisable, setcanDisable] = useState(false);
  const userOrgId = useSelector(getUserOrgId);

  const handleDateChange = (newDate) => {
    if (newDate === null) {
      setErrors({ ...errors, appointmentDate: "Please select a date for appointment." });
    } else if (!newDate.isValid()) {
      setErrors({ ...errors, appointmentDate: "Please select a valid 'Date' value." });
    } else if (newDate < appointmentMinDate || newDate > appointmentMaxDate) {
      setErrors({
        ...errors,
        appointmentDate: "Appointment can only be booked for next 7 days from today.",
      });
    } else {
      const { appointmentDate, ...newError } = errors;
      setErrors(newError);
      dispatch(setAppointmentDate(newDate?.format(DATE_FORMAT)));
    }
  };

  useEffect(() => {
    if (appointmentData?.patientMobile) {
      searchPatients(appointmentData.patientMobile);
    }
  }, [appointmentData?.patientMobile]);

  useEffect(() => {
    // Ensure appointmentDate is initialized with a valid date value
    if (!appointmentData?.appointmentDate) {
      const initialDate = dayjs().format(DATE_FORMAT);
      dispatch(setAppointmentDate(initialDate));
    }
  }, [dispatch, appointmentData?.appointmentDate]);

  const filterAvailableSlots = (slots, date) => {
    const now = dayjs();
    const nowString = now.format(DATE_FORMAT);
    const currentTime = now.format(HOUR_MINUTE_FORMAT);
    const filteredSlots = slots.filter((slot) => {
      return !(slot.remainingCount === 0 || (nowString === date && currentTime > slot.slotTime));
    });
    if (
      appointmentData?.appointmentTime &&
      // appointmentData?.id &&
      (!(nowString === date) || !(currentTime > appointmentData.appointmentTime))
    ) {
      const slotTimeExists = filteredSlots.some(
        (slot) => slot.slotTime === appointmentData.appointmentTime
      );

      if (!slotTimeExists) {
        filteredSlots.push({
          slotTime: appointmentData.appointmentTime,
          organizationId: appointmentData.orgId,
        });
      }
    }
    setAllSlots(filteredSlots);
  };

  useEffect(() => {
    const getSlots = async () => {
      if (!appointmentData?.doctorId && !doctorDetail?.agentId) return;

      const dateValue = appointmentData?.appointmentDate || dayjs().format(DATE_FORMAT);
      const formattedDate = dayjs.isDayjs(dateValue)
        ? dateValue.format(DATE_FORMAT)
        : dayjs(dateValue).format(DATE_FORMAT);

      const doctorType = doctorDetail?.doctorType || SYSTEM;

      const handleSlotAvailabilities = async (params, doctorId, fd) => {
        try {
          const response = await getSlotsAvailabilities(params);
          const listAfterBlocked = await blockDoctorSlots(response?.data, doctorId, fd);
          filterAvailableSlots(listAfterBlocked, fd);
        } catch (e) {
          setAllSlots([]);
          dispatch(setAppointmentTime(""));
        }
      };

      // If the logged-in user is a patient
      if (loggedInUserRole === PATIENT) {
        try {
          let response = null;

          if (doctorType === UHI) {
            const searchParams = { doctorType };
            const data = {
              ...uhiDoctorData?.euaSecondSearchRequest,
              startTime: `${formattedDate}T00:00:00`,
              endTime: `${formattedDate}T23:59:59`,
            };
            response = await getSlotsAvailabilities(searchParams, data);
            filterAvailableSlots(response?.data, formattedDate);
          } else {
            await handleSlotAvailabilities(
              { doctorType, appointmentDate: formattedDate, doctorId: doctorDetail?.docId },
              appointmentData?.doctorId,
              formattedDate
            );
          }
        } catch (e) {
          setAllSlots([]);
          dispatch(setAppointmentTime(""));
        }
      }
      // For other users (e.g., doctors)
      else {
        const commonParams = {
          doctorType: SYSTEM,
          appointmentDate: formattedDate,
          doctorId: appointmentData?.doctorId,
        };

        await handleSlotAvailabilities(commonParams, appointmentData?.doctorId, formattedDate);
      }
    };

    getSlots();
  }, [appointmentData?.appointmentDate, appointmentData?.doctorId]);

  const user = currentActiveUser();

  const familyId = user?.familyResponse?.id;

  const fetchFamilyMembers = async (familyId, primaryUser) => {
    let allPatientsResponse = [
      { userId: primaryUser.id, userResponse: primaryUser, isPrimary: true },
    ];
    try {
      if (familyId) {
        const response = await getPatientFamilyDetails(familyId);
        allPatientsResponse = response.data?.userFamilyMappingResponses;
      }
      setPatientsName(allPatientsResponse);
      if (allPatientsResponse.length && !appointmentData?.patientId) {
        dispatch(setPatientId(allPatientsResponse[0].userResponse.roleBasedId));
      }
    } catch (error) {
      console.error("Error fetching family details");
    }
  };

  const fetchOrgDoctors = async () => {
    try {
      const response = await getDoctorByOrgId(appointmentData?.orgId);
      setOrgDoctors(response.data);
      const org = currentActiveUser()?.userOrganizationAssociationList.find(
        (org) => org.organizationId === userOrgId
      );
      if (loggedInUserRole === DOCTOR && !org.isAdmin && doctorDetail?.doctortype === SYSTEM) {
        const response = await getDoctorById(appointmentData?.doctorId);
        setDoctorName(response.data.userResponse.name);
      } else if (doctorDetail?.doctorType === UHI) {
        setDoctorName(doctorDetail?.name);
      }
      setIsAdminToo(org.isAdmin);
    } catch (error) {
      console.error("Error fetching organization's doctors");
    }
  };

  useEffect(() => {
    if (!appointmentData?.id) return;
    const fetchAppointmentDetailsById = async () => {
      try {
        const response = await getAppointmentById(appointmentData?.id);
        setAppointmentDetails(response?.data);
      } catch (error) {
        console.error("Error fetching appointment details");
      }
    };
    fetchAppointmentDetailsById();
  }, [handleClick, appointmentData?.id]);

  useEffect(() => {
    if (loggedInUserRole === PATIENT) {
      fetchFamilyMembers(familyId, currentActiveUser());
    }
  }, [familyId]);

  const fetchDoctorOrganization = async () => {
    if (doctorDetail?.doctorType === UHI) {
      setDoctorName(doctorDetail?.name);
      return;
    }
    // const docId= appointmentData?.doctorId ? appointmentData?.doctorId : uhiDoctorData?.euaSecondSearchRequest?.agentId
    const response = await getDoctorById(appointmentData?.doctorId);
    const docResponse = response.data.userResponse.userOrganizationAssociationList.filter(
      (e) => e.isDoctor
    );
    setDoctorName(response.data.userResponse.name);
    setDoctorOrganizationList(docResponse);
  };

  useEffect(() => {
    if (
      loggedInUserRole === CLINIC_ADMIN ||
      loggedInUserRole === DOCTOR ||
      loggedInUserRole === FRONT_DESK
    ) {
      fetchOrgDoctors();
    } else if (loggedInUserRole === PATIENT && !appointmentData.isUhiDoctor) {
      fetchDoctorOrganization();
    }
  }, []);

  const sendData = async () => {
    try {
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setTouched({ mobileNumber: true, emailId: true });
        setErrors(validationErrors);
      } else {
        let finalData = {
          ...appointmentData,
          appointmentStatus: SCHEDULED,
          slotDuration: 30,
          sourceType: APPOINTMENT_TYPE_SYSTEM,
        };

        if (appointmentData?.id) {
          await updateAppointment(appointmentData?.id, finalData);
          handleClick("success", "Your appointment has been rescheduled.");
          dispatch(setSelectedAppointmentData({}));
          closeModal();
        } else if (newPatientRegistration) {
          const patientNameArr = patientFullName.split(" ");
          const userData = {
            name: patientFullName ? patientFullName : null,
            firstName: patientNameArr[0] ? patientNameArr[0] : null,
            middleName: patientNameArr.length > 2 ? patientNameArr[1] : null,
            lastName: patientNameArr.length > 1 ? patientNameArr[1] : null,
            roleId: await getRoleIdByName(PATIENT, handleClick),
            roleName: PATIENT,
            mobileNumber: mobileNumber,
            emailId: patientEmail ? patientEmail : null,
            gender: patientGender ? patientGender : null,
            dateOfBirth: patientDOB ? patientDOB : null,
            noOfAttempts: null,
            googleId: null,
            roleBasedId: null,
            address: null,
            status: "ACTIVE",
            profilePictureDocumentId: null,
          };
          let response;
          try {
            response = await createUserProfile(userData);
          } catch (error) {
            handleClick("error", "There seems to be an error adding new patient.");
          }
          finalData["patientId"] = response.data.roleBasedId;
          finalData["sourceType"] = APPOINTMENT_TYPE_SYSTEM;

          try {
            await createAppointment(finalData);
            handleClick("success", "Your appointment has been successfully booked.");
          } catch (error) {
            handleClick("error", "There seems to be an error booking appointment");
          }
          dispatch(setSelectedAppointmentData({}));
          closeModal();
        } else if (doctorDetail?.doctorType === UHI) {
          const patient = patientsName.filter(
            (patient) => patient.userResponse.roleBasedId === appointmentData.patientId
          )[0];
          const data = {
            transactionId: uhiDoctorData?.euaSecondSearchRequest?.transactionId,
            providerUri: uhiDoctorData?.euaSecondSearchRequest?.providerUri,
            providerId: uhiDoctorData?.euaSecondSearchRequest?.providerId,
            name: patient.userResponse.name,
            mobile: patient.userResponse.mobileNumber,
            email: patient.userResponse.email,
            fulfillmentId: uhiDoctorData?.euaSecondSearchRequest?.fulfillmentId,
            starttime: uhiDoctorData?.euaSecondSearchRequest?.startTime,
            endtime: uhiDoctorData?.euaSecondSearchRequest?.endTime,
            itemId: uhiDoctorData?.euaSecondSearchRequest?.itemId,
            hospitalId: uhiDoctorData?.euaSecondSearchRequest?.hospitalId,
            fulfillmentType: uhiDoctorData?.euaSecondSearchRequest?.fulfillmentType,
            agentId: uhiDoctorData?.euaSecondSearchRequest?.agentId,
            categoryId: uhiDoctorData?.euaSecondSearchRequest?.categoryId,
            priceValue: "0",
            priceCurrency: "INR",
            descriptorName: "Consultation",
            descriptorCode: "Consultation",
            gender: patient.userResponse.gender,
            dob: patient.userResponse.dateOfBirth,
          };
          const payload = {
            sourceType: UHI,
            euaInitRequest: data,
          };

          const response = await createAppointment(payload);
          handleClick("success", "Your appointment has been initiated");
          dispatch(setSelectedAppointmentData({}));
          closeModal();
          const dataForConfirmAppointment = {
            request: {
              transactionId: response?.data?.euaInitResponse?.transactionId,
              providerId: response?.data?.euaInitResponse?.providerId,
              providerUri: response?.data?.euaInitResponse?.providerUri,
              fulfillmentId: data.fulfillmentId,
              starttime: data.starttime,
              endtime: data.endtime,
              orderId: response?.data?.euaInitResponse?.orderId,
              consultationFees: response?.data?.euaInitResponse?.consultationFees,
              registrationFees: response?.data?.euaInitResponse?.registrationFees,
              totalFees: response?.data?.euaInitResponse?.totalFees,
              sgstFees: response?.data?.euaInitResponse?.sgstFees,
              cgstFees: response?.data?.euaInitResponse?.cgstFees,
              patientId: appointmentData.patientId,
            },
            appointmentResponse: {
              ...finalData,
            },
          };
          dispatch(setUhiAppointmentData(dataForConfirmAppointment));
          navigate("/home/patientPayment", {
            state: { dataForConfirmAppointment: response?.data?.euaInitResponse },
          });
        } else {
          finalData["patientId"] = appointmentData?.patientId;
          finalData["sourceType"] = APPOINTMENT_TYPE_SYSTEM;
          await createAppointment(finalData);
          handleClick("success", "Your appointment has been successfully booked.");
          dispatch(setSelectedAppointmentData({}));
          closeModal();
        }
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        if (
          loggedInUserRole === DOCTOR ||
          loggedInUserRole === CLINIC_ADMIN ||
          loggedInUserRole === FRONT_DESK
        ) {
          handleClick(
            "error",
            "Appointment already exist for the same patient on same day or same time."
          );
        } else if (loggedInUserRole === PATIENT) {
          if (setReschedule) {
            setReschedule(true);
          }
          handleClick("error", "You already have an appointment scheduled for this time slot.");
        }
        dispatch(setSelectedAppointmentData({}));
        closeModal();
      } else {
        handleClick(
          "error",
          "An error occurred while processing the request. Please try again later."
        );
        dispatch(setSelectedAppointmentData({}));
        closeModal();
      }
    }
  };

  const genderList = [
    {
      value: "MALE",
      menuLabel: "MALE",
    },
    {
      value: "FEMALE",
      menuLabel: "FEMALE",
    },
    {
      value: "OTHER",
      menuLabel: "OTHER",
    },
  ];

  const validateMobileNumber = (value) => {
    const pattern = /^[6-9][0-9]{9}$/;
    if (value) {
      if (!pattern.test(value)) {
        setErrors({ ...errors, mobileNumber: "Please enter a valid mobile number." });
      } else {
        const { mobileNumber, ...newErrors } = errors;
        setErrors(newErrors);
      }
    } else {
      setErrors({ ...errors, mobileNumber: "Please enter a valid mobile number." });
    }
  };

  const validateEmail = (value) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value) {
      if (!pattern.test(value)) {
        setErrors({ ...errors, emailId: "Please enter a valid email" });
      } else {
        const { emailId, ...newErrors } = errors;
        setErrors(newErrors);
      }
    }
  };

  const validateFullName = (value) => {
    const pattern = /^[a-zA-Z\s]*$/;
    const minLength = 2;
    const maxLength = 100;

    if (value) {
      if (value.length < minLength) {
        setErrors({ ...errors, fullName: `Name must be at least ${minLength} characters long` });
      } else if (value.length > maxLength) {
        setErrors({ ...errors, fullName: `Name must be at most ${maxLength} characters long` });
      } else if (!pattern.test(value)) {
        setErrors({ ...errors, fullName: "Name should only contain letters and space" });
      } else if (!startsAndEndsWithAlphabetic(value)) {
        setErrors({ ...errors, fullName: "Name must start and end with alphabet" });
      } else {
        const { fullName, ...newErrors } = errors;
        setErrors(newErrors);
      }
    }
  };

  const validateDate = (value) => {
    if (value) {
      if (!value.isValid()) {
        setErrors({ ...errors, patientDOB: "Please enter a valid 'Date of birth' value" });
      } else if (value < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
        setErrors({
          ...errors,
          patientDOB: `Date cannot be before the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}`,
        });
      } else if (value > currentDate) {
        setErrors({ ...errors, patientDOB: "Date cannot be in future" });
      } else {
        delete errors.patientDOB;
      }
    } else {
      delete errors.patientDOB;
    }
  };

  const validate = () => {
    const errors = {};
    switch (loggedInUserRole) {
      case PATIENT:
        if (!appointmentData?.type) {
          errors.consultationType = "Please select a consultation type.";
        }
        if (!appointmentData?.appointmentTime) {
          errors.appointmentTime = "Please select a time for appointment.";
        }
        if (!appointmentData?.patientId) {
          errors.selectPatient = "Please select a patient.";
        }
        if (!appointmentData?.appointmentDate) {
          errors.appointmentDate = "Please select a date for appointment.";
        }
        if (!appointmentData?.orgId && doctorDetail?.doctorType === SYSTEM) {
          errors.orgId = "Please select an organization.";
        }
        break;

      case DOCTOR:
        if (!appointmentData?.type) {
          errors.consultationType = "Please select a consultation type.";
        }
        if (!appointmentData?.appointmentTime) {
          errors.appointmentTime = "Please select a time for appointment.";
        }
        if (!appointmentData?.patientId) {
          if (patientEmail) {
            setTouched({ ...touched, emailId: true });
            const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!pattern.test(patientEmail)) {
              errors.emailId = "Please enter a valid email.";
            }
          } else {
            // as only mobile number is mandatory so removing this validation
            // errors.emailId = "Required";
          }

          if (mobileNumber) {
            setTouched({ ...touched, mobileNumber: true });
            const pattern = /^[6-9][0-9]{9}$/;
            if (!pattern.test(mobileNumber)) {
              errors.mobileNumber = "Please enter a valid mobile number.";
            }
          } else {
            errors.mobileNumber = "Please enter a valid mobile number.";
          }

          setDateTouched(true);
          if (patientDOB === null) {
            // as only mobile number is mandatory so removing this validation
            // errors.patientDOB = "Date of birth is Required.";
          } else if (!patientDOB.isValid()) {
            errors.patientDOB = "Please select a valid 'Date of birth' value.";
          } else if (patientDOB > currentDate) {
            errors.patientDOB = "Date cannot be in future.";
          }
        } else {
          delete errors.mobileNumber;
          delete errors.emailId;
        }
        if (!appointmentData?.appointmentDate) {
          errors.appointmentDate = "Please select a valid date.";
        }
        break;

      case CLINIC_ADMIN:
      case FRONT_DESK:
        if (!appointmentData?.doctorId) {
          errors.selectDoctor = "Please select a doctor for appointment.";
        }
        if (!appointmentData?.type) {
          errors.consultationType = "Please select a consultation type.";
        }
        if (!appointmentData?.appointmentTime) {
          errors.appointmentTime = "Please select a time for appointment.";
        }
        if (!appointmentData?.patientId) {
          if (patientEmail) {
            setTouched({ ...touched, emailId: true });
            const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!pattern.test(patientEmail)) {
              errors.emailId = "Please enter a valid email.";
            }
          } else {
            // as only mobile number is mandatory so removing this validation
            // errors.emailId = "Required";
          }

          if (mobileNumber) {
            setTouched({ ...touched, mobileNumber: true });
            const pattern = /^[6-9][0-9]{9}$/;
            if (!pattern.test(mobileNumber)) {
              errors.mobileNumber = "Please enter a valid mobile number.";
            }
          } else {
            errors.mobileNumber = "Please enter a valid mobile number.";
          }

          setDateTouched(true);
          if (patientDOB === null) {
            // as only mobile number is mandatory so removing this validation
            // errors.patientDOB = "Date of birth is Required.";
          } else if (!patientDOB.isValid()) {
            errors.patientDOB = "Please select a valid 'Date of birth' value.";
          } else if (patientDOB > currentDate) {
            errors.patientDOB = "Date cannot be in future.";
          }
        } else {
          delete errors.mobileNumber;
          delete errors.emailId;
        }

        if (!appointmentData?.appointmentDate) {
          errors.appointmentDate = "Please select a date for appointment.";
        }
        break;

      default:
        return errors;
    }

    return errors;
  };

  const handleConsultationTypeChange = (selectedType) => {
    const filteredSlots = allSlots?.filter(
      (slot) =>
        slot.organizationId === appointmentData?.orgId &&
        (slot.consultationType === selectedType || slot.consultationType === "BOTH")
    );
    const isSlotPresent = filteredSlots.find(
      (slot) => slot.slotTime === appointmentData.appointmentTime
    );
    if (!isSlotPresent) {
      dispatch(setAppointmentTime(""));
      const { appointmentTime, ...newErrors } = errors;
      setErrors(newErrors);
    }
  };

  return (
    <>
      {(loggedInUserRole === CLINIC_ADMIN || isAdminToo || loggedInUserRole === FRONT_DESK) && (
        <>
          <Typography sx={{ mt: 2, mb: 1.5, fontWeight: 500, color: "#000000" }}>
            Select Doctor
          </Typography>
          <Grid container spacing={2}>
            <Grid size={{ xs: 6 }}>
              <FormSelectField
                style={{
                  width: "100%",
                }}
                label={"Select Doctor"}
                disabled={reschedule}
                required
                startAdornment={<PersonOutlineOutlined />}
                size={"big"}
                error={Boolean(errors.selectDoctor)}
                errorText={errors.selectDoctor}
                value={appointmentData?.doctorId ? appointmentData?.doctorId : ""}
                onChange={(event) => {
                  const { selectDoctor, ...newErrors } = errors;
                  setErrors(newErrors);
                  dispatch(setDoctorId(event.target.value));
                }}
                menuItems={orgDoctors?.map((e) => {
                  return {
                    value: e?.userResponse?.roleBasedId,
                    menuLabel: `Dr. ${e?.userResponse?.name}`,
                  };
                })}
              />
            </Grid>
          </Grid>
        </>
      )}

      {(([DOCTOR, PATIENT].includes(loggedInUserRole) && !isAdminToo && doctorName) ||
        doctorDetail?.doctorType === UHI) && (
        <>
          <Typography sx={{ mt: 2, mb: 1.5, fontWeight: 500, color: "#000000" }}>
            Doctor Details
          </Typography>
          <Grid container spacing={2}>
            <Grid size={{ xs: 6 }}>
              <FormInputField
                style={{
                  width: "100%",
                }}
                disabled={true}
                label={"Name"}
                value={doctorDetail?.doctorType === SYSTEM ? `Dr. ${doctorName}` : doctorName}
                startAdornment={<PersonOutlineOutlined />}
                size={"big"}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Typography sx={{ mt: 2, mb: 1.5, fontWeight: 500, color: "#000000" }}>
        {loggedInUserRole === PATIENT
          ? "Patient Details"
          : newPatientRegistration === true
          ? "Add new patient"
          : reschedule
          ? "Patient Name"
          : "Search patient"}
      </Typography>
      <Grid container spacing={2} mt={1.7}>
        {(loggedInUserRole === DOCTOR ||
          loggedInUserRole === CLINIC_ADMIN ||
          loggedInUserRole === FRONT_DESK) && (
          <>
            <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
              <FormInputField
                style={{
                  width: "100%",
                }}
                label="Mobile Number"
                disabled={reschedule}
                required
                name="number"
                value={
                  appointmentData && appointmentData?.id
                    ? appointmentDetails?.patient?.user?.mobileNumber
                    : mobileNumber
                }
                onBlur={(e) => {
                  setTouched({ ...touched, mobileNumber: true });
                  validateMobileNumber(e.target.value);
                }}
                error={Boolean(errors.mobileNumber)}
                errorText={errors.mobileNumber}
                onChange={handleMobileNumberChange}
                startAdornment={<i className="ri-phone-line ri-xl" />}
                size={"big"}
              />
            </Grid>
            {newPatientRegistration && (
              <>
                <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
                  <FormInputField
                    style={{
                      width: "100%",
                    }}
                    label="Full Name"
                    type={"text"}
                    onBlur={(e) => {
                      if (e.target.value) {
                        setTouched({ ...touched, fullName: true });
                        validateFullName(e.target.value);
                      }
                    }}
                    error={Boolean(errors.fullName)}
                    errorText={errors.fullName}
                    name="patientFullName"
                    value={patientFullName}
                    onChange={(e) => {
                      handlePatientDataChange("patientFullName", e.target.value);
                    }}
                    startAdornment={<PersonOutlineOutlined />}
                    size={"big"}
                  />
                </Grid>
                <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
                  <FormInputField
                    style={{
                      width: "100%",
                    }}
                    label="Email"
                    type={"text"}
                    required
                    name="patientEmail"
                    error={Boolean(errors.emailId)}
                    errorText={errors.emailId}
                    onBlur={(e) => {
                      if (e.target.value) {
                        setTouched({ ...touched, emailId: true });
                        validateEmail(e.target.value);
                      }
                    }}
                    value={patientEmail}
                    onChange={(e) => {
                      handlePatientDataChange("patientEmail", e.target.value);
                    }}
                    startAdornment={<i className="ri-mail-line ri-lg" />}
                    size={"big"}
                  />
                </Grid>
                <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
                  <FormDatePicker
                    format={DATE_FORMAT_DMY}
                    disableFuture
                    label={"Birth date"}
                    minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
                    value={patientDOB}
                    size={"big"}
                    onChange={(date) => {
                      setDateTouched(true);
                      handlePatientDataChange("patientDOB", date);
                    }}
                    error={Boolean(errors.patientDOB && dateTouched)}
                    errorText={errors.patientDOB}
                    style={{
                      width: "100%",
                    }}
                  ></FormDatePicker>
                </Grid>
                <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
                  <FormSelectField
                    style={{
                      width: "100%",
                    }}
                    label="Gender"
                    name="gender"
                    onChange={handleGenderChange}
                    startAdornment={<PersonOutlineOutlined />}
                    menuItems={genderList}
                    value={patientGender}
                    size={"big"}
                  ></FormSelectField>
                </Grid>
              </>
            )}
          </>
        )}
        {patientsName.length > 0 && !newPatientRegistration && (
          <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
            <FormSelectField
              style={{
                width: "100%",
              }}
              label={"Select Patient"}
              disabled={appointmentData?.id ? true : false}
              error={Boolean(errors.selectPatient)}
              errorText={errors.selectPatient}
              startAdornment={<PersonOutlineOutlined />}
              size={"big"}
              value={appointmentData?.patientId ? appointmentData?.patientId : ""}
              onChange={(event) => dispatch(setPatientId(event.target.value))}
              menuItems={patientsName?.map((e) => {
                return {
                  value: e.userResponse?.roleBasedId,
                  menuLabel:
                    e.relationship === "SELF"
                      ? e.userResponse?.name
                      : `${e.userResponse?.name} (${e.relationship})`,
                };
              })}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid size={{ xs: 6 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ mt: 0, mb: 0, fontWeight: 500, color: "#000000" }}>
              Consultation{" "}
              <span style={{ color: errors?.consultationType ? "red" : "#000000" }}>*</span>
            </Typography>
            {errors.consultationType && (
              <FormHelperText sx={{ mt: 0.3 }} error>
                {errors.consultationType}
              </FormHelperText>
            )}
          </div>
          <div>
            <RadioGroup
              value={appointmentData?.type ? appointmentData?.type : ""}
              onChange={(e) => {
                handleConsultationTypeChange(e.target.value);
                const { consultationType, ...newErrors } = errors;
                setErrors(newErrors);
                dispatch(setType(e.target.value));
              }}
              style={{ height: "50px" }}
            >
              <div style={{ width: "40%" }}>
                <FormControlLabel
                  value={CLINIC_VISIT}
                  control={<Radio size="small" />}
                  disabled={
                    !reschedule &&
                    (doctorDetail?.doctorType === UHI ||
                      (canDisable && appointmentData?.type !== CLINIC_VISIT))
                  }
                  label="In Clinic"
                />
              </div>
              <div style={{ width: "64%" }}>
                <FormControlLabel
                  value={TELE_CONSULTATION}
                  disabled={
                    !reschedule &&
                    (appointmentData?.isUhiDoctor ||
                      (canDisable && appointmentData?.type !== TELE_CONSULTATION))
                  }
                  control={
                    <Radio
                      size="small"
                      checked={
                        doctorDetail?.doctorType === UHI ||
                        appointmentData?.type === TELE_CONSULTATION
                      }
                    />
                  }
                  label="Tele-consultation"
                />
              </div>
            </RadioGroup>
          </div>
        </Grid>
        {loggedInUserRole === PATIENT && doctorDetail?.doctorType === SYSTEM && (
          <Grid size={{ xs: 6 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mt: 0, mb: 1, fontWeight: 500, color: "#000000" }}>
                Organization <span style={{ color: errors?.orgId ? "red" : "#000000" }}>*</span>
              </Typography>
              {errors.orgId && (
                <FormHelperText sx={{ ml: 2, mt: 0.3 }} error>
                  {errors.orgId}
                </FormHelperText>
              )}
            </div>

            <div>
              <FormSelectField
                style={{ width: "100%" }}
                name={"organization"}
                required
                value={appointmentData.orgId ? appointmentData.orgId : ""}
                size={"big"}
                disabled={appointmentData?.id ? true : false}
                startAdornment={<i className="ri-building-fill ri-xl" />}
                error={errors.orgId && touched.orgId}
                errorText={errors.orgId}
                onChange={(event) => {
                  dispatch(setAppointmentTime(""));
                  const { orgId, ...newErrors } = errors;
                  setErrors(newErrors);
                  dispatch(setOrgId(event.target.value));
                }}
                menuItems={doctorOrganizationList.map((el) => {
                  return {
                    value: el?.organizationId,
                    menuLabel: el?.organizationName,
                  };
                })}
              ></FormSelectField>
            </div>
          </Grid>
        )}
      </Grid>

      <Typography sx={{ mt: 0.2, mb: 1, fontWeight: 500, color: "#000000" }}>
        Date and Time Slot
      </Typography>
      <Grid container spacing={2} mt={0.7}>
        <Grid size={{ xs: 6 }} style={{ paddingTop: 0 }}>
          <FormDatePicker
            format={DATE_FORMAT_DMY}
            label={"Appointment Date"}
            value={appointmentData?.appointmentDate}
            size={"big"}
            readOnly={doctorDetail?.doctorType === SYSTEM && !Boolean(appointmentData?.doctorId)}
            minDate={appointmentMinDate}
            maxDate={appointmentMaxDate}
            onChange={handleDateChange}
            error={Boolean(errors.appointmentDate)}
            errorText={errors.appointmentDate}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
              marginBottom: "30px",
            }}
            // disabled={appointmentData?.isUhiDoctor || false}
          ></FormDatePicker>
        </Grid>
        <Grid size={{ xs: 6 }} style={{ marginTop: 7, paddingTop: 0 }}>
          {doctorDetail?.doctorType === SYSTEM &&
          allSlots?.filter(
            (slot) =>
              slot.organizationId === appointmentData?.orgId &&
              (selectedType
                ? slot.consultationType === selectedType || slot.consultationType === "BOTH"
                : true)
          ).length === 0 ? (
            <Typography style={{ marginTop: 7, paddingTop: 0 }}>Slots not Available</Typography>
          ) : (
            <FormSelectField
              style={{
                width: "100%",
              }}
              label="Slot Time"
              name="number"
              required
              error={Boolean(errors.appointmentTime)}
              errorText={errors.appointmentTime}
              menuItems={
                doctorDetail?.doctorType === UHI
                  ? allSlots.map((e) => {
                      return {
                        value: e?.slotTime,
                        menuLabel: `${convertTimeForTimeFormat(e?.slotTime)}`,
                      };
                    })
                  : allSlots
                      ?.filter(
                        (slot) =>
                          slot.organizationId === appointmentData?.orgId &&
                          (selectedType
                            ? slot.consultationType === selectedType ||
                              slot.consultationType === "BOTH"
                            : true)
                      )
                      .map((e) => {
                        return {
                          value: e?.slotTime,
                          menuLabel: `${convertTimeForTimeFormat(e?.slotTime)}`,
                        };
                      })
              }
              value={appointmentData?.appointmentTime ? appointmentData?.appointmentTime : ""}
              onChange={(e) => {
                const { appointmentTime, ...newErrors } = errors;
                setErrors(newErrors);
                dispatch(setAppointmentTime(e?.target?.value));
              }}
              startAdornment={<i className="ri-time-line ri-xl" />}
              size={"big"}
              disabled={appointmentData?.isUhiDoctor}
            />
          )}
        </Grid>
      </Grid>

      <Grid>
        <Grid sx={{ width: "100%" }} style={{ paddingTop: 0 }}>
          <FormInputField
            style={{
              width: "100%",
              marginRight: "30px",
              marginBottom: "25px",
            }}
            label="Reason For consultation"
            disabled={reschedule}
            value={
              appointmentData?.id
                ? appointmentDetails?.notes
                : appointmentData?.notes
                ? appointmentData.notes
                : ""
            }
            type="text"
            onChange={(e) => dispatch(setNotes(e?.target?.value))}
            startAdornment={<AssignmentOutlinedIcon style={{ mt: 4 }} />}
            size={"big"}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1 }} style={{ display: "flex" }}>
        <CustomButton
          className="btn--secondary"
          style={{ marginLeft: "auto" }}
          label={reschedule ? "Reschedule" : "Book Appointment"}
          onClick={sendData}
          disabled={
            doctorDetail?.doctorType === SYSTEM &&
            allSlots?.filter(
              (slot) =>
                slot.organizationId === appointmentData?.orgId &&
                (selectedType
                  ? slot.consultationType === selectedType || slot.consultationType === "BOTH"
                  : true)
            ).length === 0
          }
        />
      </Grid>
    </>
  );
};

export default BookAppointmentModal;
