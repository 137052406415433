import React from "react";
import { Modal, Card, Typography, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "./custom-components/CustomButton";
import Reveal from "views/utilities/Reveal";

const ModalUI = ({ visible, title, close, style, component }) => {
  return (
    <Modal
      open={visible}
      onClose={() => close(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(2px)",
      }}
    >
      <Card
        style={{
          width: "max-content",
          maxWidth: "90%",
          padding: "30px",
          margin: "auto",
          position: "relative",
          borderRadius: "10px",
          ...style,
        }}
      >
        <Reveal>
          <Grid container alignItems={"center"}>
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              {title}
            </Typography>
            <CustomButton
              onClick={() => close(false)}
              iconButton={<CloseIcon></CloseIcon>}
              style={{ marginLeft: "auto" }}
            ></CustomButton>
          </Grid>
          {component}
        </Reveal>
      </Card>
    </Modal>
  );
};

export default ModalUI;
