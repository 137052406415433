import { PlaceOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import dayjs from "dayjs";
import { Formik } from "formik";
import { useState } from "react";
import { useContext } from "react";
import { setPatientData, uploadProfilePicture } from "services/patientService";
import {
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  MAX_AGE_FOR_REGUAR_DOB,
  genderList,
  timeFormat,
  timeFormats,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { patientGeneralInfoSchema } from "views/Components/Common/ValidationSchema/patientGeneralInfoSchema";
import Reveal from "views/utilities/Reveal";

const libraries = ["places"];

const PatientGeneralInfo = ({
  profileImageFile,
  patientDetails,
  setPatientDetails,
  readOnly,
  setReadOnly,
  ...others
}) => {
  const bloodGroupOptions = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const { handleClick } = useContext(ToastContext);

  const currentDate = dayjs();
  const [dobError, setDobError] = useState(
    patientDetails?.user?.dateOfBirth ? "" : "Please select a date of birth."
  );
  const [timeFormatForUser, setTimeFormatForUser] = useState(timeFormat().toString());

  const changeTimeFormat = (e) => {
    setTimeFormatForUser(e.target.value);
  };

  return (
    <Reveal>
      {isLoaded && (
        <Grid container>
          {patientDetails && (
            <Formik
              enableReinitialize={true}
              initialValues={{
                firstName: patientDetails.user.firstName ? patientDetails.user.firstName : "",
                middleName: patientDetails.user.middleName ? patientDetails.user.middleName : "",
                lastName: patientDetails.user.lastName ? patientDetails.user.lastName : "",
                mobileNumber: patientDetails.user.mobileNumber
                  ? patientDetails.user.mobileNumber
                  : "",
                email: patientDetails.user.emailId ? patientDetails.user.emailId : "",
                password: patientDetails.user.password ? patientDetails.user.password : "",
                address: patientDetails.user.address ? patientDetails.user.address : "",
                gender: patientDetails.user.gender ? patientDetails.user.gender : "",
                dateOfBirth: patientDetails.user.dateOfBirth
                  ? dayjs(patientDetails.user.dateOfBirth)
                  : null,
                bloodGroup: patientDetails.bloodGroup ? patientDetails.bloodGroup : "",
                height: patientDetails.height ? patientDetails.height : "",
                weight: patientDetails.weight ? patientDetails.weight : "",
                emergencyContactName: patientDetails.emergencyContactName
                  ? patientDetails.emergencyContactName
                  : "",
                emergencyContactNumber: patientDetails.emergencyContactNumber
                  ? patientDetails.emergencyContactNumber
                  : "",
                emergencyContactRelationship: patientDetails.emergencyContactRelationship
                  ? patientDetails.emergencyContactRelationship
                  : "",
                longitude: patientDetails.longitude ? patientDetails.longitude : 77.2088,
                latitude: patientDetails.latitude ? patientDetails.latitude : 28.6139,
                submit: "",
              }}
              validationSchema={patientGeneralInfoSchema}
              onSubmit={async (values) => {
                if (!dobError) {
                  try {
                    const updatedData = {
                      ...patientDetails,
                      bloodGroup: values.bloodGroup,
                      height: values.height,
                      weight: values.weight,
                      emergencyContactName: values.emergencyContactName,
                      emergencyContactNumber: values.emergencyContactNumber,
                      emergencyContactRelationship: values.emergencyContactRelationship,
                      latitude: values.latitude,
                      longitude: values.longitude,
                      user: {
                        ...patientDetails?.user,
                        firstName: values.firstName,
                        middleName: values.middleName,
                        lastName: values.lastName,
                        gender: values.gender,
                        address: values.address,
                        mobileNumber: values.mobileNumber,
                        emailId: values.email,
                        dateOfBirth: values.dateOfBirth.format(DATE_FORMAT),
                        prefers24HrFormat: timeFormatForUser === "24" ? true : false,
                      },
                    };
                    let response;
                    if (profileImageFile) {
                      let profilePicId;
                      try {
                        const formData = new FormData();
                        formData.append("file", profileImageFile);
                        const response = await uploadProfilePicture(
                          patientDetails?.user.id,
                          formData
                        );
                        profilePicId = response.data.profilePictureDocumentId;
                      } catch (error) {
                        handleClick(
                          "error",
                          "There seems to be some error uploading profile picture"
                        );
                      }
                      response = await setPatientData(patientDetails?.id, {
                        ...updatedData,
                        user: {
                          ...updatedData.user,
                          profilePictureDocumentId: profilePicId,
                        },
                      });
                    } else {
                      response = await setPatientData(patientDetails?.id, updatedData);
                    }

                    setPatientDetails(response?.data);
                    localStorage.setItem("timeFormat", timeFormatForUser);
                    handleClick("success", "Patient Information has been successfully updated.");
                    setReadOnly(true);
                  } catch (error) {
                    handleClick(
                      "error",
                      "There seems to be an error updating the patient information."
                    );
                  }
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit} {...others}>
                  <Grid container sx={{ height: "fit-content" }}>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                          maxHeight: "40px",
                        }}
                      >
                        First Name
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="firstName"
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.firstName && touched.firstName)}
                        errorText={errors.firstName}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Gender
                      </Typography>
                      <FormSelectField
                        name={"gender"}
                        style={{ width: "50%" }}
                        onBlur={handleBlur}
                        value={values.gender}
                        size={"small"}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        onChange={handleChange}
                        noArrow={readOnly}
                        menuItems={genderList}
                        error={Boolean(errors.gender && touched.gender)}
                        errorText={errors.gender}
                      ></FormSelectField>
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Middle Name
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="middleName"
                        value={values.middleName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.middleName && touched.middleName)}
                        errorText={errors.middleName}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Blood Group
                      </Typography>
                      <FormSelectField
                        name={"bloodGroup"}
                        style={{ width: "50%" }}
                        onBlur={handleBlur}
                        value={values.bloodGroup}
                        size={"small"}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        onChange={handleChange}
                        noArrow={readOnly}
                        menuItems={bloodGroupOptions.map((el) => {
                          return {
                            value: el,
                            menuLabel: el,
                          };
                        })}
                      ></FormSelectField>
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Last Name
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="lastName"
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.lastName && touched.lastName)}
                        errorText={errors.lastName}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Height (in cm)
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="height"
                        type="number"
                        value={values.height}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.height && touched.height)}
                        errorText={errors.height}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Mobile Number
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="mobileNumber"
                        value={values.mobileNumber}
                        type={"tel"}
                        readOnly={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 10,
                        }}
                        disableBorder={true}
                        background={"transparent"}
                        size={"small"}
                        error={Boolean(errors.mobileNumber && touched.mobileNumber)}
                        errorText={errors.mobileNumber}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Weight (in kg)
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="weight"
                        type="number"
                        value={values.weight}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.weight && touched.weight)}
                        errorText={errors.weight}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Email Address
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="email"
                        value={values.email}
                        type="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={true}
                        background={"transparent"}
                        size={"small"}
                        error={Boolean(errors.email && touched.email)}
                        errorText={errors.email}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Address
                      </Typography>
                      <FormInputField
                        style={{ width: "50%" }}
                        name="address"
                        value={values.address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.address && touched.address)}
                        errorText={errors.address}
                      />
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Date Of Birth
                      </Typography>
                      <FormDatePicker
                        disableFuture
                        format={DATE_FORMAT_DMY}
                        minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
                        value={values.dateOfBirth}
                        size={"small"}
                        onChange={(date) => {
                          setFieldValue("dateOfBirth", date);
                          if (date === null) {
                            setDobError("Please select a date of birth.");
                          } else if (!date.isValid()) {
                            setDobError("Please selct a valid 'Date of birth' value.");
                          } else if (date > currentDate) {
                            setDobError("Date of birth cannot be a future date.");
                          } else if (date < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
                            setDobError(
                              `Date of birth can't be less than the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}.`
                            );
                          } else {
                            setDobError("");
                          }
                        }}
                        style={{
                          width: "50%",
                        }}
                        readOnly={readOnly}
                        iconAtEnd={true}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        disableBorder={true}
                        error={Boolean(dobError && touched.dateOfBirth)}
                        errorText={dobError}
                      ></FormDatePicker>
                    </Grid>
                    <Grid display={"flex"} sx={{ mb: 0.5 }} size={{ md: 12 }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "250px",
                          paddingTop: "8px",
                        }}
                      >
                        Preferred Time Format
                      </Typography>
                      <FormSelectField
                        name={"Time Format"}
                        style={{ width: "50%" }}
                        onBlur={handleBlur}
                        value={timeFormatForUser}
                        size={"small"}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        onChange={changeTimeFormat}
                        noArrow={readOnly}
                        menuItems={timeFormats}
                      ></FormSelectField>
                    </Grid>
                  </Grid>
                  <Grid sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#004C70",
                        minWidth: "250px",
                        paddingTop: "8px",
                        mb: 2,
                      }}
                    >
                      Location:
                    </Typography>
                  </Grid>
                  <Grid container size={{ xs: 12 }}>
                    <Grid sx={{ mb: 0.5 }} size={{ xs: 4, md: 4 }} display={"flex"}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "fit-content",
                          paddingTop: "9.5px",
                          marginRight: "10px",
                        }}
                      >
                        Latitude
                      </Typography>
                      <FormInputField
                        style={{ width: "70%" }}
                        name="latitude"
                        type="number"
                        value={values.latitude}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.latitude && touched.latitude)}
                        errorText={errors.latitude}
                      />
                    </Grid>
                    <Grid sx={{ mb: 0.5, pl: 1 }} size={{ xs: 4, md: 4 }} display={"flex"}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#004C70",
                          minWidth: "fit-content",
                          paddingTop: "9.5px",
                          marginRight: "10px",
                        }}
                      >
                        Longitude
                      </Typography>
                      <FormInputField
                        style={{ width: "70%" }}
                        name="longitude"
                        type="number"
                        value={values.longitude}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disableBorder={true}
                        readOnly={readOnly}
                        background={readOnly ? "transparent" : "#e3e3e3"}
                        size={"small"}
                        error={Boolean(errors.longitude && touched.longitudea)}
                        errorText={errors.longitude}
                      />
                    </Grid>
                    <Grid size={{ xs: 4, md: 4 }} display={"flex"}>
                      <CustomButton
                        style={{ marginLeft: "auto" }}
                        label={"Get Current Location"}
                        className={"btn--secondary"}
                        startIcon={<PlaceOutlined />}
                        disabled={readOnly}
                        onClick={() => {
                          if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(
                              (position) => {
                                setFieldValue("latitude", position.coords.latitude.toFixed(5));
                                setFieldValue("longitude", position.coords.longitude.toFixed(5));
                              },
                              (error) => {
                                console.error("Error getting geo-location");
                              }
                            );
                          } else {
                            handleClick("error", "Geolocation is not supported by this browser.");
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: 12, md: 12 }}>
                    <GoogleMap
                      sx={{ height: "350px" }}
                      item
                      zoom={10}
                      center={{
                        lat: parseFloat(values.latitude),
                        lng: parseFloat(values.longitude),
                      }}
                      mapContainerClassName="map-container"
                      onClick={(e) => {
                        if (!readOnly) {
                          setFieldValue("latitude", e.latLng.lat());
                          setFieldValue("longitude", e.latLng.lng());
                        }
                      }}
                    >
                      <Marker
                        position={{
                          lat: parseFloat(values.latitude),
                          lng: parseFloat(values.longitude),
                        }}
                      />
                    </GoogleMap>
                  </Grid>

                  {!readOnly && (
                    <Grid sx={{ mt: 4 }} display={"flex"} justifyContent={"center"}>
                      <CustomButton
                        label={"Cancel"}
                        className={"btn--error"}
                        style={{ width: "100px" }}
                        onClick={() => {
                          setDobError("");
                          resetForm();
                          setReadOnly(true);
                        }}
                      />

                      <CustomButton
                        type="submit"
                        label={"Save"}
                        className={"btn--secondary"}
                        style={{ marginLeft: "10px", width: "100px" }}
                      />
                    </Grid>
                  )}
                </form>
              )}
            </Formik>
          )}
        </Grid>
      )}
    </Reveal>
  );
};

export default PatientGeneralInfo;
