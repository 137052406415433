import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

const CustomInputField = ({ iconStart, iconEnd, InputProps, readOnly, ...props }) => {
  return (
    <TextField
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? <InputAdornment position="end">{iconEnd}</InputAdornment> : null,
      }}
      readOnly={readOnly}
      {...props}
    />
  );
};

export default CustomInputField;
