const SkeletonLeftLeg = () => {
  return (
    <div className="custom-skeleton-left-leg">
      <div className="main-group">
        <div className="group-1">
          {/* todo:image  */}
          <div className="left-leg-foot body-part-container">
            <img
              src="/images/human-skeleton/human-left-leg/left-leg-foot.svg"
              className="body-part hover"
              alt="left-leg-foot"
            ></img>
          </div>
        </div>
        <div className="group-2">
          <div className="left-leg-femur body-part-container">
            <img
              src="/images/human-skeleton/human-left-leg/left-leg-femur.svg"
              className="body-part hover"
              alt="left-leg-femur"
            ></img>
          </div>
          <div className="left-leg-tibia body-part-container">
            <img
              src="/images/human-skeleton/human-left-leg/left-leg-tibia.svg"
              className="body-part hover"
              alt="left-leg-tibia"
            ></img>
          </div>
          <div className="left-leg-patella body-part-container">
            <img
              src="/images/human-skeleton/human-left-leg/left-leg-patella.svg"
              className="body-part hover"
              alt="left-leg-patella"
            ></img>
          </div>
          <div className="left-leg-fibula body-part-container">
            <img
              src="/images/human-skeleton/human-left-leg/left-leg-fibula.svg"
              className="body-part hover"
              alt="left-leg-fibula"
            ></img>
          </div>
          <div className="left-leg-tarsus body-part-container">
            <img
              src="/images/human-skeleton/human-left-leg/left-leg-tarsus.svg"
              className="body-part hover"
              alt="left-leg-tarsus"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLeftLeg;
