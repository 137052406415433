import { startsAndEndsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const userSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "Minimum 2 characters allowed")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Minimum 2 characters allowed")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Mobile number is required"),
  role: Yup.string().required("Role is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .max(255, "Too long contact email")
    .required("email is required"),
});

export const userDoctorSchema = userSchema.concat(
  Yup.object().shape({
    specialization: Yup.string().required("Required"),
    doctorOrganizationAssociation: Yup.array().min(1, "Select at least one organization"),
  })
);

export const userClinicAdminSchema = userSchema.concat(
  Yup.object().shape({
    adminOrganizationAssociation: Yup.array().min(1, "Select at least one organization"),
  })
);

export const userFrontDeskSchema = userSchema.concat(
  Yup.object().shape({
    frontDeskOrganizationAssociation: Yup.string().required("Organization is required"),
  })
);

export const userSignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Name is required")
    .min(3, "Minimum 3 characters required")
    .max(100, "Maximum 100 characters allowed")
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    ),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Mobile number is required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .max(255, "Too long contact email")
    .required("email is required"),
});

export const updateUserProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "Minimum 2 characters allowed")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character required")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Minimum 2 characters allowed")
    .max(50, "Maximum 50 characters allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Mobile number is required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .required("email is required")
    .max(255, "Maximum 255 characters allowed"),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Minimum 3 characters allowed")
    .max(256, "Maximum 256 characters allowed"),
  gender: Yup.string().required("Required"),
});
