import React, { useEffect, useState } from "react";
import "assets/scss/PatientDetails.scss";
import PatientEHRBasicDetailsCard from "./PatientEHRBasicDetailsCard";
import PatientEHRHealthDetailsCard from "./PatientEHRHealthDetailsCard";
import { api } from "services/AxiosInterceptor";
import { currentActiveUser } from "store/constant";
import { useLocation } from "react-router";

const PatientDetails = () => {
  const { state } = useLocation();
  const [patientEhrData, setPatientEhrData] = useState(null);
  useEffect(() => {
    const patientId = state?.viewOnlyPage ? state?.patientId : currentActiveUser()?.roleBasedId;

    const fetchPatientEhrDetails = async () => {
      try {
        const response = await api.get(`/api/ehr/${patientId}`);
        setPatientEhrData(response?.data);
      } catch (error) {}
    };
    fetchPatientEhrDetails();
  }, []);

  return (
    <div>
      <h2 style={{ color: "#004c70" }}>Patient's EHR</h2>
      <div className="patient-details">
        <div className="patient-basic-details">
          <PatientEHRBasicDetailsCard patientEhrData={patientEhrData} />
        </div>
        <div className="patient-health-details">
          <PatientEHRHealthDetailsCard patientEhrData={patientEhrData} />
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
