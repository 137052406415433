import "assets/scss/ehr/human-skeleton.scss";
import SkeletonLeftArm from "./SkeletonLeftArm";
import SkeletonRightArm from "./SkeletonRightArm";
import SkeletonPelvis from "./SkeletonPelvis";
import SkeletonLeftLeg from "./SkeletonLeftLeg";
import SkeletonRightLeg from "./SkeletonRightLeg";
import SkeletonBackBone from "./SkeletonBackBone";
import SkeletonShoulderFrame from "./SkeletonShoulderFrame";
import SkeletonHead from "./SkeletonHead";
import SkeletonRibCage from "./SkeletonRibCage";

const HumanSkeleton = () => {
  return (
    <div className="human-skeleton-container">
      <div className="skeleton-head">
        <SkeletonHead></SkeletonHead>
      </div>
      <div className="skeleton-pelvis">
        <SkeletonPelvis></SkeletonPelvis>
      </div>
      <div className="skeleton-rib-cage">
        <SkeletonRibCage></SkeletonRibCage>
      </div>
      <div className="skeleton-back-bone">
        <SkeletonBackBone></SkeletonBackBone>
      </div>
      <div className="skeleton-shoulder-frame">
        <SkeletonShoulderFrame></SkeletonShoulderFrame>
      </div>
      <div className="skeleton-left-leg">
        <SkeletonLeftLeg></SkeletonLeftLeg>
      </div>
      <div className="skeleton-right-leg">
        <SkeletonRightLeg></SkeletonRightLeg>
      </div>
      <div className="skeleton-left-arm">
        <SkeletonLeftArm></SkeletonLeftArm>
      </div>
      <div className="skeleton-right-arm">
        <SkeletonRightArm></SkeletonRightArm>
      </div>
    </div>
  );
};

export default HumanSkeleton;
