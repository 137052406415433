import React, { useState, useRef } from "react";
import "assets/scss/customDropZone.scss";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomButton from "../custom-components/CustomButton";
import { Tooltip } from "@mui/material";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { fileTypes } from "store/constant";

const CustomDropZone = ({ acceptedFileTypes, maxSizeMB = 1, handleFileUpload }) => {
  const { handleClick } = useContext(ToastContext);
  const [files, setFiles] = useState([]);
  const dropRef = useRef(null);

  const validateFiles = (newFiles) => {
    const maxSize = maxSizeMB * 1024 * 1024;

    // if no files encountered in drop event, or some issues in file reading
    const invalidFiles = newFiles.filter((file) => file.type !== "");
    if (newFiles.length === 0 || invalidFiles.length !== newFiles.length) {
      handleClick("error", "File/s not uploaded properly");
    }

    // filter out the accepted types sent as props to the component
    const filteredFiles = invalidFiles.filter((file) => acceptedFileTypes.includes(file.type));
    if (filteredFiles.length !== invalidFiles.length) {
      handleClick("error", "Unsupported file/s");
    }

    const totalSize =
      filteredFiles.reduce((acc, file) => acc + file.size, 0) +
      files.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > maxSize) {
      handleClick("error", `Total file size exceeds the ${maxSizeMB}MB limit`);
    } else {
      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropRef.current.classList.add("dragover");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropRef.current.classList.remove("dragover");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropRef.current.classList.remove("dragover");
    const newFiles = Array.from(event.dataTransfer.files);
    validateFiles(newFiles);
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    validateFiles(newFiles);
    event.target.value = null;
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`;
    else return `${(size / 1048576).toFixed(1)} MB`;
  };

  const getFileTypeDisplayName = () => {
    return acceptedFileTypes.map((type) => fileTypes[type]).join(", ");
  };

  const handleViewFile = (file) => {
    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl, "_blank");
  };

  return (
    <div>
      <div
        ref={dropRef}
        className="file-upload"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileUpload"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <label htmlFor="fileUpload" className="file-upload-label">
          <img src="/images/file-upload.svg" alt="file-upload" width={75} height={75}></img>
          <p className="label-text">
            Drag and drop files here <em>or</em> click to upload
          </p>
          <p className="file-supported">
            (<em>{getFileTypeDisplayName()}</em> files accepted)
          </p>
        </label>
      </div>
      {files.length > 0 && (
        <div className="file-list">
          <h4>Files:</h4>
          <ul style={{}}>
            {files.map((file, index) => (
              <li key={index}>
                <Tooltip title={file.name}>
                  <span className="file-name">{file.name}</span>
                </Tooltip>
                <span className="file-size">{formatFileSize(file.size)}</span>
                <CustomButton
                  iconButton={<VisibilityIcon />}
                  style={{ padding: "0px", height: "18px", marginRight: "5px" }}
                  textAndIconColor="#004c70"
                  onClick={() => handleViewFile(file)} // Replace with your file URL
                />
                <CustomButton
                  iconButton={<CloseIcon />}
                  style={{
                    padding: "0px",
                    height: "18px",
                  }}
                  textAndIconColor="red"
                  onClick={() => handleRemoveFile(index)}
                ></CustomButton>
              </li>
            ))}
          </ul>
        </div>
      )}
      {files.length > 0 && (
        <div className="button-container">
          <CustomButton
            className={"btn--primary"}
            onClick={() => {
              handleFileUpload(files);
              setFiles([]);
            }}
            style={{ marginTop: "10px" }}
            label={"Upload Files"}
          ></CustomButton>
        </div>
      )}
    </div>
  );
};

export default CustomDropZone;
