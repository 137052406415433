import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Typography } from "@mui/material";
import {
  CLINIC_VISIT,
  DATE_FORMAT,
  DAY_GRID_CALENDAR,
  MONTH_GRID_CALENDAR,
  TELE_CONSULTATION,
  WEEK_GRID_CALENDAR,
} from "store/constant";
import { useEffect } from "react";
import { useState } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";

const CustomFullCalendar = ({
  handleMonthViewMore,
  setCalendarView,
  calendarView,
  viewInfo,
  setViewInfo,
  filteredDoctorEvents,
  setFilteredDoctorEvents,
  allEvents,
  eventClick,
  blockedDays,
  slotDuration,
  blockedEvents,
  onFilteredAppointmentsChange,
  setShowAppointmentList,
}) => {
  const [calendarEvents, setCalendarEvents] = useState(allEvents);

  const [selectedDayAppointments, setSelectedDayAppointments] = useState([]);

  const isDateBlocked = (info) => {
    if (info.view.type === MONTH_GRID_CALENDAR) {
      const formattedDate = dayjs(info.date).format(DATE_FORMAT);
      return blockedDays.some(
        (blockedDay) => blockedDay.startDate <= formattedDate && blockedDay.endDate >= formattedDate
      );
    }
    return false;
  };

  const handleDateChange = (info) => {
    const selectedStartDate = dayjs(info.start).format(DATE_FORMAT);
    const selectedEndDate = dayjs(info.end).format(DATE_FORMAT);
    let filteredAppointments = [];
    setShowAppointmentList(false);
    if (info.view.type === DAY_GRID_CALENDAR) {
      setViewInfo(info);
      setCalendarView(info.view.type);
      filteredAppointments = allEvents.filter(
        (event) => dayjs(event.start).format(DATE_FORMAT) === selectedStartDate
      );
    } else if (info.view.type === WEEK_GRID_CALENDAR) {
      setViewInfo(info);
      setCalendarView(info.view.type);
      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) > selectedStartDate &&
          dayjs(event.end).format(DATE_FORMAT) < selectedEndDate
      );
    } else if (info.view.type === MONTH_GRID_CALENDAR) {
      setViewInfo(info);
      setCalendarView(info.view.type);
      const monthStartDate = dayjs(info.view.currentStart).format(DATE_FORMAT);
      const monthEndDate = dayjs(info.view.currentEnd).format(DATE_FORMAT);

      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) >= monthStartDate &&
          dayjs(event.end).format(DATE_FORMAT) < monthEndDate
      );

      const events = allEvents.reduce((acc, appointment) => {
        const date = dayjs(appointment.start).format(DATE_FORMAT);

        if (!acc[date]) {
          acc[date] = {
            clinicVisitCount: 0,
            teleconsultationCount: 0,
            start: appointment.start,
            end: appointment.end,
          };
        }

        if (appointment.appointmentType === "CLINIC_VISIT") {
          acc[date].clinicVisitCount++;
        } else if (appointment.appointmentType === "TELE_CONSULTATION") {
          acc[date].teleconsultationCount++;
        }

        return acc;
      }, {});
      const eventsList = [];
      Object.keys(events).forEach((date) => {
        eventsList.push({
          start: events[date].start,
          end: events[date].end,
          appointmentType: TELE_CONSULTATION,
          count: events[date].teleconsultationCount,
        });
        eventsList.push({
          start: events[date].start,
          end: events[date].end,
          appointmentType: CLINIC_VISIT,
          count: events[date].clinicVisitCount,
        });
      });
      setCalendarEvents(eventsList);
    }
    onFilteredAppointmentsChange(filteredAppointments);
    setFilteredDoctorEvents(filteredAppointments);
    setSelectedDayAppointments(filteredAppointments);
  };

  useEffect(() => {
    if (viewInfo) {
      handleDateChange(viewInfo);
    }
  }, [allEvents]);

  useEffect(() => {
    if (filteredDoctorEvents.length > 0) {
      setSelectedDayAppointments(filteredDoctorEvents);
    } else {
      setSelectedDayAppointments([]);
    }
  }, [filteredDoctorEvents]);

  return (
    <Reveal>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          end: "today prev,next",
          start: "timeGridDay,timeGridWeek,dayGridMonth",
          center: "title",
        }}
        initialView="timeGridDay"
        allDaySlot={false}
        weekends={true}
        slotDuration={{ minute: slotDuration }}
        slotLabelInterval={{ minute: slotDuration }}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "long",
        }}
        buttonText={{
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
        }}
        slotMinTime={"00:00:00"}
        slotMaxTime={"23:59:59"}
        events={
          calendarView === MONTH_GRID_CALENDAR
            ? [...calendarEvents]
            : [...selectedDayAppointments, ...blockedEvents]
        }
        eventContent={renderEventContent}
        dayMaxEventRows={2}
        fixedWeekCount={false}
        views={{
          day: {},
          week: { eventMaxStack: 1 },
        }}
        eventClick={calendarView === MONTH_GRID_CALENDAR ? handleMonthViewMore : eventClick}
        dayCellClassNames={(cellInfo) => (isDateBlocked(cellInfo) ? ["blocked_day"] : [])}
        datesSet={handleDateChange}
      />
    </Reveal>
  );
};

const renderEventContent = (eventInfo) => {
  return (
    <>
      {eventInfo.view.type === MONTH_GRID_CALENDAR ? (
        <>
          {eventInfo.event.extendedProps.count > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "trasnparent",
                cursor: "pointer",
              }}
            >
              <div
                className="appointment-count"
                style={{
                  backgroundColor:
                    eventInfo.event.extendedProps.appointmentType === CLINIC_VISIT
                      ? "#29bf91"
                      : "#004c70",
                }}
              >
                {eventInfo.event.extendedProps.count}{" "}
              </div>
              <i
                className={`ri-${
                  eventInfo.event.extendedProps.appointmentType === CLINIC_VISIT
                    ? "walk-fill"
                    : "video-chat-line"
                }`}
                style={{
                  width: "30px",
                  height: "30px",
                  fontSize: "20px",
                  textAlign: "center",
                  paddingTop: "5px",
                  color:
                    eventInfo.event.extendedProps.appointmentType === CLINIC_VISIT
                      ? "#29bf91"
                      : "#004c70",
                }}
              />
            </div>
          ) : (
            <div style={{ display: "none" }}></div>
          )}
        </>
      ) : (
        <div
          style={{
            maxWidth: "100%",
          }}
        >
          {eventInfo.event.extendedProps.appointmentType === "Blocked" ? (
            <div
              style={{
                backgroundColor: "#b2c9d4",
                width: "auto",
                height: "100%",
              }}
            ></div>
          ) : (
            <div
              style={{
                display: "flex",
                backgroundColor: eventInfo.event.extendedProps.eventSelected
                  ? "#29bf91"
                  : "#004C70",
                padding: "2px 4px",
                width: "100%",
                borderRadius: "10px",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              {eventInfo.event.extendedProps.appointmentType !== CLINIC_VISIT ? (
                <i
                  className="ri-video-chat-line"
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "white",
                  }}
                />
              ) : (
                <i
                  className="ri-walk-fill"
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "white",
                  }}
                />
              )}
              <Typography
                className="fc-patient-name"
                style={{
                  color: "white",
                  width: "150px",
                }}
              >
                {eventInfo.event.title}
              </Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomFullCalendar;
