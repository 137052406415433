import { CorporateFare, Person } from "@mui/icons-material";
import { Grid } from "@mui/material";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";

const OnboardingStep4 = ({ handleBlur, handleChange, values, errors, touched }) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="inputTypeText" className="form-label onboarding-label">
          To confirm your professional status, could you provide the name of the registration
          authority you're registered with, along with your registration number?
        </label>

        <Grid sx={{ mb: "10px", mt: "10px" }}>
          <FormInputField
            placeholder="Registration council name"
            name="doctorRegistration.registrationCouncil"
            value={values.doctorRegistration.registrationCouncil}
            onBlur={handleBlur}
            inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
            background={"#fff"}
            border={"1px solid #e6eef1"}
            borderOnFocus={"1px solid #29bf91"}
            onChange={handleChange}
            error={Boolean(
              touched?.doctorRegistration?.registrationCouncil &&
                errors?.doctorRegistration?.registrationCouncil
            )}
            errorText={errors?.doctorRegistration?.registrationCouncil}
            startAdornment={<CorporateFare />}
            size={"small"}
          />
        </Grid>
        <Grid>
          <FormInputField
            // style={{ width: "30%", marginRight: "30px", marginBottom: "25px" }}
            // label="Email Id"
            style={{ marginBottom: "10px" }}
            placeholder="Registration Id"
            name="doctorRegistration.registrationId"
            value={values.doctorRegistration.registrationId}
            onBlur={handleBlur}
            inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
            background={"#fff"}
            border={"1px solid #e6eef1"}
            borderOnFocus={"1px solid #29bf91"}
            onChange={handleChange}
            error={Boolean(
              touched?.doctorRegistration?.registrationId &&
                errors?.doctorRegistration?.registrationId
            )}
            errorText={errors?.doctorRegistration?.registrationId}
            startAdornment={<Person />}
            size={"small"}
          />
        </Grid>
      </div>
    </>
  );
};

export default OnboardingStep4;
