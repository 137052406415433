import { Close, RestartAltOutlined } from "@mui/icons-material";
import { Drawer, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  getRecentAppointmentsOfDoctor,
  getRecentAppointmentsOfPatient,
} from "services/Appointments";
import {
  CLINIC_VISIT,
  currentActiveUser,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  TELE_CONSULTATION,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import RecentVisitItem from "ui-component/custom-components/RecentVisitItem";
import FilterDrawer from "ui-component/FilterDrawer";
import PatientPrescription from "./PatientPrescription";

const RecentVisits = () => {
  const [appointmentData, setAppointmentData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [isDateValid, setIsDateValid] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [applyFilterDisable, setApplyFilterDisable] = useState(true);
  const [searchParams, setSearchParams] = useState(null);
  const [customRange, setCustomRange] = useState(false);
  const [clinicVisitSelected, setClinicVisitSelected] = useState(false);
  const [teleconsultationSelected, setTeleconsultationSelected] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const currentUser = currentActiveUser();

  const today = dayjs();
  const startDate = today.format(DATE_FORMAT);

  const dateOptions = [
    {
      label: "All",
      startDate: null,
      endDate: null,
    },
    {
      label: "Today",
      startDate: startDate,
      endDate: startDate,
    },
    {
      label: "Yesterday",
      startDate: today.subtract(1, "day").format(DATE_FORMAT),
      endDate: today.subtract(1, "day").format(DATE_FORMAT),
    },
    {
      label: "Last 7 Days",
      startDate: today.subtract(7, "day").format(DATE_FORMAT),
      endDate: startDate,
    },
    {
      label: "Last 30 Days",
      startDate: today.subtract(30, "day").format(DATE_FORMAT),
      endDate: startDate,
    },
    {
      label: "Last 3 Months",
      startDate: today.subtract(3, "month").format(DATE_FORMAT),
      endDate: startDate,
    },
    {
      label: "Enter date range",
      startDate: fromDate,
      endDate: toDate,
    },
  ];

  const getPatientAppointmentData = (data) => {
    const appointments = data.map((appointment) => {
      let apDate = dayjs(appointment.appointmentDate);
      return {
        appointmentId: appointment.id,
        doctorId: appointment.doctorId,
        patientId: appointment.patientId,
        doctorUserId: appointment.doctor.userResponse.id,
        orgId: appointment.orgId,
        doctorName: appointment.doctor.userResponse.name,
        patientName: appointment.patient.user.name,
        gender: appointment.doctor.userResponse.gender,
        organizationName: appointment.organization.name,
        appointmentDate: appointment.appointmentDate,
        appointmentTime: appointment.appointmentTime,
        type: appointment.type,
        slotDuration: appointment.slotDuration,
        formattedDate: apDate.format(DATE_FORMAT_DMY),
      };
    });
    setAppointmentData(appointments);
  };

  const getDoctorList = (data) => {
    const doctorArray = data.reduce((acc, appointment) => {
      if (!acc.some((doctor) => doctor.doctorId === appointment.doctorId)) {
        acc.push({
          doctorId: appointment.doctorId,
          name: appointment.doctor.userResponse.name,
          organizationName: appointment.organization.name,
        });
      }
      return acc;
    }, []);

    setDoctorList(doctorArray);
  };

  const getDoctorAppointmentData = (data) => {
    const appointments = data.map((appointment) => {
      let apDate = dayjs(appointment.appointmentDate);
      return {
        appointmentId: appointment.id,
        doctorId: appointment.doctorId,
        patientId: appointment.patientId,
        patientUserId: appointment.patient.user.id,
        orgId: appointment.orgId,
        patientName: appointment.patient.user.name,
        gender: appointment.patient.user.gender,
        dateOfBirth: appointment.patient.user.dateOfBirth,
        appointmentDate: appointment.appointmentDate,
        appointmentTime: appointment.appointmentTime,
        type: appointment.type,
        chronicDiseases: null,
        lifestyleResponses: null,
        slotDuration: appointment.slotDuration,
        formattedDate: apDate.format(DATE_FORMAT_DMY),
      };
    });
    setAppointmentData(appointments);
  };

  useEffect(() => {
    handleFilter(null, true);
  }, []);

  const handleReset = async () => {
    const { doctorId, appointmentType, fromDate, toDate, ...newObj } = searchParams || {};
    setSearchParams(newObj);
    setApplyFilterDisable(true);
    setFromDate("");
    setToDate("");
    setCustomRange(false);
    setClinicVisitSelected(false);
    setTeleconsultationSelected(false);
    handleFilter(newObj);
  };

  const handleFilter = async (obj, isGetDoctor) => {
    try {
      let response;

      if (currentUser?.roleName === "PATIENT") {
        response = await getRecentAppointmentsOfPatient(
          obj ? obj : searchParams,
          currentUser.roleBasedId
        );
        getPatientAppointmentData(response.data);
        if (isGetDoctor) {
          getDoctorList(response.data);
        }
      }
      if (currentUser?.roleName === "DOCTOR") {
        response = await getRecentAppointmentsOfDoctor(
          obj ? obj : searchParams,
          currentUser.roleBasedId
        );
        getDoctorAppointmentData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      setIsDateValid(dayjs(fromDate) <= dayjs(toDate));
    } else {
      setIsDateValid(true);
    }
  }, [fromDate, toDate]);

  const filterDrawer = (
    <FilterDrawer
      title="Filters"
      filters={[
        ...(currentUser?.roleName === "PATIENT"
          ? [
              {
                label: "Doctor",
                componentType: "autocomplete",
                options:
                  doctorList?.map((e) => ({
                    value: e?.doctorId,
                    label: `${e.name} (${e.organizationName})`,
                  })) || [],
                onChange: (event, newValue) => {
                  setSearchParams({
                    ...searchParams,
                    doctorId: newValue?.value,
                  });
                  setApplyFilterDisable(false);
                },
                value:
                  doctorList
                    .map((e) => ({
                      value: e?.doctorId,
                      label: `${e.name} (${e.organizationName})`,
                    }))
                    .find((option) => option.value === searchParams?.doctorId) || null,
                placeholder: "Select Doctor",
              },
            ]
          : []),
        {
          label: "Appointment Type",
          componentType: "radio",
          name: "appointmentType",
          value: searchParams?.appointmentType || null,

          onChange: (e) => {
            const selectedType = e.target.value;

            // Update searchParams based on the selected value
            setSearchParams({
              ...searchParams,
              appointmentType: selectedType,
            });

            // Set selected state based on the selected type
            setTeleconsultationSelected(selectedType === TELE_CONSULTATION);
            setClinicVisitSelected(selectedType === CLINIC_VISIT);

            // Enable the apply filter button
            setApplyFilterDisable(false);
          },
          options: [
            {
              value: TELE_CONSULTATION,
              label: "Teleconsultation",

              checked: searchParams?.appointmentType === TELE_CONSULTATION,
            },
            {
              value: CLINIC_VISIT,
              label: "Clinic Visit",

              checked: searchParams?.appointmentType === CLINIC_VISIT,
            },
          ],
        },
        {
          label: "Select Date",
          componentType: "autocomplete",
          options: dateOptions,
          onChange: (e, newValue) => {
            if (newValue?.label === "Enter date range") {
              setCustomRange(true);
            } else {
              setCustomRange(false);
              setSearchParams({
                ...searchParams,
                fromDate: newValue?.startDate,
                toDate: newValue?.endDate,
              });
            }
            setApplyFilterDisable(false);
          },
          value:
            dateOptions.find(
              (option) =>
                option.startDate === searchParams?.fromDate &&
                option.endDate === searchParams?.toDate
            ) || null,
          placeholder: "Date Range",
          customComponent: customRange && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <TextField
                style={{ width: "48%" }}
                label="From Date"
                type="date"
                name="fromDate"
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  setSearchParams({
                    ...searchParams,
                    fromDate: e.target.value,
                  });
                }}
                InputLabelProps={{ shrink: true }}
                error={!isDateValid}
                helperText={!isDateValid ? "From Date should be less than or equal to To Date" : ""}
              />
              <TextField
                style={{ width: "48%" }}
                label="To Date"
                type="date"
                name="toDate"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  setSearchParams({
                    ...searchParams,
                    toDate: e.target.value,
                  });
                }}
                InputLabelProps={{ shrink: true }}
                error={!isDateValid}
                helperText={
                  !isDateValid ? "To Date should be greater than or equal to From Date" : ""
                }
              />
            </div>
          ),
        },
      ]}
      applyFilterDisable={applyFilterDisable}
      setApplyFilterDisable={setApplyFilterDisable}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      applyFilters={handleFilter}
      resetFilters={handleReset}
      onClose={() => setOpenDrawer(false)}
    />
  );

  const [prescriptionModalOpen, setPrescriptionModalOpen] = useState(false);
  const [prescriptionAppointmentId, setPrescriptionAppointmentId] = useState(null);

  return (
    <>
      <div className="section-heading">
        <h2 className="page-title">Recent Visits</h2>
        <div className="buttons">
          <CustomButton
            label={"Filters"}
            className={"btn--primary-light"}
            startIcon={<i className={open ? "ri-filter-fill" : "ri-filter-line"} />}
            onClick={toggleDrawer(!open)}
          />
          <CustomButton
            style={{ marginLeft: "20px" }}
            className={"btn--primary-light"}
            startIcon={<RestartAltOutlined />}
            label={""}
            onClick={handleReset}
          />
        </div>
      </div>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        {" "}
        {filterDrawer}{" "}
      </Drawer>
      <Grid container size={{ xs: 12 }} spacing={2}>
        {appointmentData.length === 0 ? (
          <Typography style={{ margin: "20px" }}>No appointments</Typography>
        ) : (
          appointmentData.map((item, index) => (
            <Grid size={{ xs: 12, md: 6, lg: 4 }} className="appointment-list">
              <ul className="appointments">
                <RecentVisitItem
                  appointment={item}
                  key={index}
                  setPrescriptionAppointmentId={setPrescriptionAppointmentId}
                  setPrescriptionModalOpen={setPrescriptionModalOpen}
                />
              </ul>
            </Grid>
          ))
        )}
      </Grid>
      {prescriptionModalOpen && (
        <div className="iframe-div">
          <div className="iframe">
            <PatientPrescription appId={prescriptionAppointmentId} />
          </div>

          <CustomButton
            iconButton={<Close />}
            onClick={() => {
              setPrescriptionModalOpen(false);
              setPrescriptionAppointmentId(null);
            }}
            className="iframe-close-button"
          />
        </div>
      )}
    </>
  );
};

export default RecentVisits;
