import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "primereact/resources/themes/saga-blue/theme.css";

// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";

import "primereact/resources/themes/vela-blue/theme.css";
import "primereact/resources/primereact.min.css";
import CustomToast from "ui-component/custom-components/CustomToast";
import FloatingOverlayBar from "views/pages/demo/OverlayBar";
import { Detector } from "react-detect-offline";
import { setIsOnline } from "store/Slices/cloudSyncSlice";
// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const isDemo = useSelector((state) => state.demo.isDemo);
  const dispatch = useDispatch();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <CustomToast />
        {isDemo && <FloatingOverlayBar />}
        <Detector
          render={({ online }) => {
            dispatch(setIsOnline(online));
          }}
        />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
