import React from "react";
import { MOUTH, THROAT } from "utils/ehr-data-utils";
import "assets/scss/humanBody.scss";

const HumanThroat = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  const isSelected = selectedOrgan.includes(THROAT);

  return (
    <div
      id="human-body-throat"
      className={`human-throat ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <img
        src="/images/throat.svg"
        alt="Outer Body"
        className={`throat hover body-part ${isSelected ? "highlight" : ""}`}
      />
    </div>
  );
};

export default HumanThroat;
