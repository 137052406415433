import React from "react";
import CustomCard from "ui-component/custom-components/CustomCard";
import CustomButton from "ui-component/custom-components/CustomButton";
import "../../../assets/scss/DoctorSearch.scss";
import { Box, Drawer, Rating, Skeleton, Typography, debounce } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import { DoctorDetailsCard } from "../../../ui-component/custom-components/CustomCard";
import { useState } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  BOTH,
  CLINIC_VISIT,
  DATE_FORMAT,
  HOUR_MINUTE_FORMAT,
  PATIENT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  SYSTEM,
  TELE_CONSULTATION,
  UHI,
  accessToken,
  convertTimeForTimeFormat,
  currentActiveUser,
} from "store/constant";
import {
  doctorAdvanceSearch,
  getDoctorAvailability,
  getDoctorById,
  searchDoctorUhi,
} from "services/doctorService";
import { useEffect } from "react";
import { SearchOffOutlined, Edit, Book, CalendarToday } from "@mui/icons-material";
import { useContext } from "react";
import { getSpecializations } from "services/EntitiesServices";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import ModalUI from "ui-component/ModalUI";
import {
  addFamilyDoctor,
  getFamilyDoctor,
  getPatientFamilyDetails,
  removeFamilyDoctor,
} from "services/patientService";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Slices/appointmentDataSlice";
import { getSlotsAvailabilities } from "services/Availability";
import { createAppointment, getRecentAppointments } from "services/Appointments";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { useCallback } from "react";
import FilterDrawer from "ui-component/FilterDrawer";
import { getOrgById } from "services/organizationService";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";

const currentDate = dayjs();

const DoctorSearch = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [nearByDoctors, setNearByDoctors] = useState(null);
  const [patient, setPatient] = useState({});
  const [recentVisits, setRecentVisits] = useState([]);

  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();

  const [searchParams, setSearchParams] = useState(null);

  const [docDetails, setDocDetails] = useState(null);

  const [specializations, setSpecializations] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [isSpecializationDropdownOpen, setIsSpecializationDropdownOpen] = useState(false);
  const [showUhiDoctors, setShowUhiDoctors] = useState(false);
  // const [uhiDoctors, setUhiDoctors] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [familyDoctor, setFamilyDoctor] = useState([]);
  const [openBookAppointmentModal, setOpenBookAppointmentModal] = useState(false);
  const dispatch = useDispatch();
  const [earliestSlotForInstantBooking, setEarliestSlotForInstantBooking] = useState({});
  const [reschedule, setReschedule] = useState(false);
  const [clinicVisitSelected, setClinicVisitSelected] = useState(false);
  const [teleconsultationSelected, setTeleconsultationSelected] = useState(false);

  const [searchItemForCommonSearchBar, setSearchItemForCommonSearchBar] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const [applyFilterDisable, setApplyFilterDisable] = useState(true);
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [doctorType, setDoctorType] = useState(searchParams?.doctorType ?? null);
  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setOpenBookAppointmentModal(false);
    setOpenModal(false);
  };

  const getDetailsString = (doctorObj) => {
    return doctorObj.specialization && doctorObj.yearsOfExperience
      ? `${doctorObj.specialization?.name || doctorObj.specialization} | ${
          doctorObj.yearsOfExperience
        } years of experience`
      : doctorObj.specialization
      ? `${doctorObj.specialization.name || doctorObj.specialization}`
      : doctorObj.yearsOfExperience
      ? `${doctorObj.yearsOfExperience} years of experience`
      : "";
  };

  const fetchData = async (patient) => {
    try {
      setPatient(patient ? patient : currentActiveUser());
      const familyId = patient
        ? patient.familyResponse?.id
        : currentActiveUser().familyResponse?.id;

      let response = { data: [] };
      if (familyId) {
        response = await getFamilyDoctor(familyId);
        const data = await Promise.all(
          response.data.map(async (doctor) => {
            const availabilities = await getDoctorAvailability(doctor.doctorId);
            return {
              docId: doctor.doctorId,
              name: `Dr. ${doctor.doctor.userResponse.name}`,
              details: getDetailsString(doctor.doctor),
              doctorAvailabilities: availabilities,
              gender: doctor.doctor.userResponse.gender,
              value: doctor.doctor.avgRating,
              userId: doctor.doctor.userResponse.id,
            };
          })
        );
        setFamilyDoctor(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAppointmentData = async () => {
    try {
      const currentDate = dayjs().format(DATE_FORMAT);
      const dateBefore5days = dayjs().subtract(5, "day").format(DATE_FORMAT);

      const response = await getRecentAppointments(
        currentActiveUser()?.roleBasedId,
        dateBefore5days,
        currentDate
      );

      const appointmentsWithDistinctDoctors = response.data.reduce((acc, current) => {
        const x = acc.find((item) => item.doctorId === current.doctorId);
        if (!x) {
          acc.push(current);
        }
        return acc;
      }, []);

      const recentvisits = await Promise.all(
        appointmentsWithDistinctDoctors.map(async (appointment) => {
          try {
            const { data } = await getDoctorById(appointment.doctorId);
            const availabilities = await getDoctorAvailability(appointment.doctorId);
            return {
              userId: data?.userResponse?.id,
              docId: appointment.doctorId,
              name: `Dr. ${data.userResponse.name}`,
              details: getDetailsString({
                specialization: data.specialization,
                yearsOfExperience: data.yearsOfExperience,
              }),
              doctorAvailabilities: availabilities,
              gender: data.userResponse.gender,
              value: data.avgRating,
            };
          } catch (error) {
            handleClick("error", "Something went wrong. Try again.");
          }
        })
      );
      setRecentVisits(recentvisits);
    } catch (error) {
      handleClick("error", "Something went wrong. Try again.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchAppointmentData();
  }, []);

  const fetchSpecializations = async () => {
    try {
      const response = await getSpecializations();
      const updatedSpecialization = response?.data.map((item) => {
        return {
          ...item,
          label: item.name,
        };
      });
      setSpecializations(updatedSpecialization);
    } catch (error) {
      console.error("Error fetching specialization and patient data");
    }
  };

  const fetchNearByDoctors = async (location) => {
    try {
      const searchParams = {
        ...location,
        doctorType: BOTH,
      };
      const response = await doctorAdvanceSearch(searchParams);
      const nearByDoctors = response.data.content.map((doctor) => {
        return {
          ...doctor,
          docId: doctor.id,
          orgIds: doctor.organizationIds,
          name: `Dr. ${doctor.firstName} ${doctor.lastName}`,
          details: getDetailsString(doctor),
          value: doctor.avgRating,
          onConsultationStart: () => {
            // Handle consultation start logic
          },
        };
      });
      setNearByDoctors(nearByDoctors);
    } catch (error) {
      console.error("Error fetching near by doctors");
    }
  };

  useEffect(() => {
    const getLocationPermission = async () => {
      try {
        if (!navigator.geolocation) {
          handleClick("error", "Geolocation is not supported by this browser.");
          return;
        }

        const permission = await navigator.permissions.query({
          name: "geolocation",
        });

        if (permission.state === "granted") {
          navigator.geolocation.getCurrentPosition((position) => {
            fetchNearByDoctors({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            setCurrentLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          });
        } else if (permission.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              fetchNearByDoctors({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
              setCurrentLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              console.error("Error getting user location");
            }
          );
        }
      } catch (error) {
        console.error("There was an error requesting location permission");
      }
    };

    getLocationPermission();
  }, []);

  useEffect(() => {
    fetchSpecializations();
  }, [accessTokenValue]);

  const applyFilters = async (searchParams) => {
    setLoading(true);
    setDocDetails([]);
    if (searchParams && Object.keys(searchParams).length !== 0) {
      try {
        const modifiedSearchParams = {
          ...searchParams,
          doctorType: doctorType || BOTH,
          query: searchParams.query ? searchParams.query.trim().replace(/\s+/g, "_") : null,
        };

        const response = await doctorAdvanceSearch({
          ...modifiedSearchParams,
          ...currentLocation,
        });
        const DocDetails = await Promise.all(
          response?.data?.content?.map(async (docDetail) => {
            let availabilityResponse;

            // Define current time and time 7 days later
            const currentDate = new Date();
            const endDate = new Date();
            endDate.setDate(currentDate.getDate() + 7);

            if (!docDetail?.doctorType === "UHI") {
              availabilityResponse = await getDoctorAvailability(docDetail?.id);
            }
            return {
              ...docDetail,
              docId: docDetail?.id,
              orgIds: docDetail?.organizationIds,
              name: `Dr. ${docDetail?.firstName} ${docDetail?.lastName}`,
              details: getDetailsString(docDetail),
              value: docDetail?.avgRating,
              imageSrc: "/images/man.png",
              onConsultationStart: () => {
                // Handle consultation start logic
              },
              doctorAvailabilities: availabilityResponse,
            };
          })
        );

        setDocDetails(DocDetails);
        setShowUhiDoctors(false);
      } catch (error) {
        handleClick("error", "There seems to be an error in doctor search.");
      } finally {
        setLoading(false);
      }
    } else {
      setDocDetails(null);
      // setUhiDoctors(null);
    }
    setLoading(false);
  };

  const debounceApplyFilters = useCallback(debounce(applyFilters, 1000), []);

  const filterUhiDoctorsList = (uhiDoctors) => {
    const doctors = [];
    Object.keys(uhiDoctors).forEach((key) => {
      const provider = uhiDoctors[key];
      provider.providerInfos.forEach((doctor) => {
        doctors.push({
          ...doctor,
          value: 0,
          name: doctor.doctorName,
          yearsOfExperience: doctor.experience,
          imageSrc: doctor.doctorImage,
          highestQualificationName: doctor.education,
          details: getDetailsString(doctor),
          transactionId: provider.transactionId,
          providerUri: provider.providerUri,
          providerId: provider.providerId,
          doctorDetails: {
            name: doctor.doctorName,
            language: doctor.language,
            experience: doctor.experience,
            education: doctor.education,
            image: doctor.doctorImage,
          },
          orgDetails: {
            name: doctor.hospitalName,
            address: "Kishan Nagar",
            city: "Gandhinagar",
            country: "India",
          },
          providerDetails: {
            uri: provider.providerUri,
            id: provider.providerId,
            image: doctor.providerImage,
          },
        });
      });
    });
    return doctors;
  };

  // const getUhiDoctors = async () => {
  //   const date = dayjs();
  //   const formattedStartDate = date.format(DATE_FORMAT);
  //   const formattedEndDate = date.add(6, "day").format(DATE_FORMAT);
  //   try {
  //     const data = {
  //       itemName: "Consultation",
  //       fulfillmentType: "Online",
  //       agentName: "",
  //       agentId: "",
  //       speciality: selectedSpecialization?.name,
  //       hospitalName: "",
  //       startTime: `${formattedStartDate}T00:00:00`,
  //       endTime: `${formattedEndDate}T23:59:59`,
  //       city: "",
  //       language: "",
  //     };
  //     const doctorSerchResponse = await searchDoctorUhi(data);
  //     setUhiDoctors(filterUhiDoctorsList(doctorSerchResponse.data));
  //     setShowUhiDoctors(true);
  //   } catch (error) {
  //     console.error("Error fetching doctors");
  //   }
  // };

  const bookAppointment = async (doctor) => {
    const formattedDate = currentDate.format(DATE_FORMAT);
    const appointmentData = {
      doctorId: doctor?.docId,
      patientId: patient?.roleBasedId,
      appointmentDate: formattedDate,
      appointmentTime: earliestSlotForInstantBooking.slotTime,
      orgId: earliestSlotForInstantBooking.organizationId,
      appointmentStatus: "SCHEDULED",
      notes: "",
      type: earliestSlotForInstantBooking.consultationType,
      slotDuration: earliestSlotForInstantBooking.slotDuration,
    };
    try {
      await createAppointment(appointmentData);
      handleClick("success", "Appointment has been successfully booked.");
    } catch (error) {
      if (error?.response?.status === 409) {
        setReschedule(true);
        handleClick("error", "You already have an appointment scheduled for this time slot.");
      } else {
        handleClick("error", "There seems to be an error booking the appointment.");
      }
    }
    setOpenModal(false);
  };

  const handleEditAppointment = async (doctor) => {
    const formattedDate = currentDate.format(DATE_FORMAT);
    const appointmentData = {
      doctorId: doctor?.docId,
      patientId: patient?.roleBasedId,
      appointmentDate: formattedDate,
      appointmentTime: earliestSlotForInstantBooking.slotTime,
      orgId: earliestSlotForInstantBooking.organizationId,
      appointmentStatus: "SCHEDULED",
      notes: "",
      type: earliestSlotForInstantBooking.consultationType,
      slotDuration: earliestSlotForInstantBooking.slotDuration,
    };

    dispatch(setSelectedAppointmentData(appointmentData));
    setOpenBookAppointmentModal(true);
  };

  const openInstantBooking = async (familyDoctor) => {
    try {
      setSelectedDoctor(familyDoctor);
      const now = dayjs();
      const currentTime = now.format(HOUR_MINUTE_FORMAT);
      const formattedDate = currentDate.format(DATE_FORMAT);
      const response = await getSlotsAvailabilities(familyDoctor?.docId, formattedDate);

      const availableSlots = await response?.data?.filter((slot) => {
        return slot.slotTime > currentTime;
      });

      let earliestSlot = availableSlots.reduce((earliest, slot) => {
        return slot.slotTime < earliest.slotTime ? slot : earliest;
      }, availableSlots[0]);
      if (!earliestSlot) {
        throw new Error("No slots");
      }
      const organizationNameResponse = await getOrgById(earliestSlot?.organizationId);
      earliestSlot = { ...earliestSlot, organizationName: organizationNameResponse?.data?.name };

      setEarliestSlotForInstantBooking(earliestSlot ? earliestSlot : {});

      setOpenModal(true);
    } catch (error) {
      if (error?.response?.status === 404 || error.message === "No slots") {
        setEarliestSlotForInstantBooking({});
        handleClick("info", "Sorry! No slots available for today.");
      } else {
        console.error(error);
      }
    }
  };

  const addDoctorAsFamilyDoctor = async (doctorId) => {
    try {
      const response = await addFamilyDoctor(patient?.id, doctorId);
      const familyResponse = await getPatientFamilyDetails(response.data.familyId);
      const patientRes = { ...patient, familyResponse: familyResponse.data };
      setPatient(patientRes);
      localStorage.setItem("currentActiveUser", JSON.stringify(patientRes));
      fetchData(patientRes);
      handleClick("success", "Family doctor has been successfully added.");
    } catch (error) {
      handleClick("error", "There seems to be an error adding the family doctor.");
    }
  };

  const removeDoctorAsFamilyDoctor = async (doctorId) => {
    try {
      const response = await removeFamilyDoctor(patient?.familyResponse.id, doctorId);
      fetchData();
      handleClick("success", "Family doctor has been successfully removed.");
    } catch (error) {
      handleClick("error", "There seems to be an error removing the family doctor.");
    }
  };

  const addActions = [
    {
      label: "Add as family doctor",
      onClick: addDoctorAsFamilyDoctor,
    },
  ];

  const removeActions = [
    {
      label: "Remove as family doctor",
      onClick: removeDoctorAsFamilyDoctor,
    },
  ];

  return (
    <DoctorSearchSkeletonStructure>
      <div>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-start",
            paddingTop: "15px",
            paddingBottom: "15px",
            marginLeft: "0px",
            // width: '100%',
            backgroundColor: "#f2f6f8",
            position: "sticky",
            top: 0,
            width: "-webkit-fill-available",
            zIndex: 1,
          }}
        >
          <Grid size={{ xs: 12, sm: 7, md: 7, lg: 7, xl: 7 }} sx={{ pt: "0 !important" }}>
            <FormInputField
              style={{ width: "100%" }}
              size="small"
              // label={"Search"}
              placeholder={"Search doctors, clinics, specializations etc."}
              value={searchItemForCommonSearchBar}
              onChange={(e) => {
                setSearchItemForCommonSearchBar(e.target.value);
                const param = e.target.value;
                // if(param !== "" && param.length >=3){

                // }
                if (param !== "") {
                  const newSearchParams = { ...searchParams, query: param };
                  debounceApplyFilters(newSearchParams);
                  setSearchParams(newSearchParams);
                } else {
                  const { query, ...newSearchParams } = searchParams;
                  debounceApplyFilters(newSearchParams);
                  setSearchParams(newSearchParams);
                }
              }}
              startAdornment={<i className="ri-search-line ri-lg" />}
              endAdorment={
                searchItemForCommonSearchBar ? (
                  <i
                    className="ri-close-line ri-lg"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSearchItemForCommonSearchBar("");
                      const { query, ...newSearchParams } = searchParams;
                      debounceApplyFilters(newSearchParams);
                      setSearchParams(newSearchParams);
                    }}
                  />
                ) : undefined
              }
            />
          </Grid>
          <Grid display={"flex"} sx={{ marginLeft: "auto" }}>
            <Reveal>
              <CustomButton
                style={{ marginLeft: "auto", marginTop: "2px" }}
                label={"Filters"}
                className={"btn--primary-light"}
                startIcon={
                  <i
                    className={
                      searchParams?.specializationId ||
                      searchParams?.minRating ||
                      searchParams?.consulationType
                        ? "ri-filter-fill"
                        : "ri-filter-line"
                    }
                  />
                }
                onClick={toggleDrawer(!open)}
              />
            </Reveal>
          </Grid>
          <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
            <FilterDrawerNew
              doctorType={doctorType}
              setDoctorType={setDoctorType}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setSelectedSpecialization={setSelectedSpecialization}
              applyFilters={applyFilters}
              setOpen={setOpen}
              specializations={specializations}
              setIsSpecializationDropdownOpen={setIsSpecializationDropdownOpen}
              isSpecializationDropdownOpen={isSpecializationDropdownOpen}
              selectedSpecialization={selectedSpecialization}
              applyFilterDisable={applyFilterDisable}
              setApplyFilterDisable={setApplyFilterDisable}
            />
          </Drawer>
        </Grid>
        {familyDoctor.length !== 0 &&
          familyDoctor.map((doctor, index) => {
            return (
              (!searchParams || Object.keys(searchParams).length === 0 || !docDetails) && (
                <div className="fixed-header-body " key={index}>
                  <Reveal>
                    <div
                      style={{
                        width: "auto",
                        height: "36px",
                        gap: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        opacity: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          lineHeight: "25.5px",
                          textAlign: "left",
                          color: " #007390",
                        }}
                      >
                        Family doctor
                      </Typography>
                      <CustomButton
                        className="btn--primary"
                        label="Instant Booking"
                        startIcon={<BookOnlineIcon />}
                        onClick={() => openInstantBooking(doctor)}
                      />
                    </div>
                  </Reveal>
                  <CustomCard
                    handleClick={handleClick}
                    doctorDetail={{ ...doctor, doctorType: SYSTEM }}
                    isUhiDoctor={false}
                    actions={removeActions}
                  />
                </div>
              )
            );
          })}
        {openModal && (
          <ModalUI
            visible={openModal}
            close={handleModalClose}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <CalendarToday sx={{ marginRight: "8px", color: "#29BF91" }} />
                Instant Booking
              </div>
            }
            style={{
              padding: "20px",
              width: "600px",
              borderRadius: "10px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            component={
              Object.keys(earliestSlotForInstantBooking).length !== 0 && (
                <>
                  <Grid size={{ xs: 12 }} style={{ marginTop: "30px", marginBottom: "20px" }}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{ fontWeight: "500", color: "#333", fontSize: "1.23rem" }}
                    >
                      Upcoming slot for a consultation with{" "}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: "bold",
                          color: "#29BF91",
                          fontSize: "1.25rem",
                        }}
                      >
                        {selectedDoctor.name}
                      </Typography>{" "}
                      from{" "}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: "bold",
                          color: "#29BF91",
                          fontSize: "1.25rem",
                        }}
                      >
                        {earliestSlotForInstantBooking.organizationName}
                      </Typography>{" "}
                      is available at{" "}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: "bold",
                          color: "#29BF91",
                          fontSize: "1.25rem",
                        }}
                      >
                        {convertTimeForTimeFormat(earliestSlotForInstantBooking.slotTime)}
                      </Typography>
                      .
                      <Typography variant="h5" sx={{ color: "#555", marginTop: "40px" }}>
                        Please choose an action below to proceed with your booking.
                      </Typography>
                    </Typography>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "15px",
                      paddingTop: "10px",
                      borderTop: "1px solid #ddd",
                    }}
                  >
                    <CustomButton
                      className="btn--primary-light"
                      label="Edit"
                      onClick={() => handleEditAppointment(selectedDoctor)}
                      startIcon={<Edit />}
                    />
                    <CustomButton
                      className="btn--primary"
                      label="Book Now"
                      onClick={() => bookAppointment(selectedDoctor)}
                      startIcon={<Book />}
                      style={{
                        backgroundColor: "#29BF91",
                        color: "#fff",
                      }}
                    />
                  </div>
                </>
              )
            }
          />
        )}

        {openBookAppointmentModal && (
          <ModalUI
            visible={openBookAppointmentModal}
            close={handleModalClose}
            title="Book Appointment"
            style={{
              overflowY: "scroll",
              height: "550px",
              width: "610px",
            }}
            component={
              <BookAppointmentModal
                setReschedule={setReschedule}
                handleClick={handleClick}
                closeModal={handleModalClose}
              />
            }
          />
        )}
        <Grid
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            marginTop: "25px",
            padding: "24px 24px 24px 24px",
            height: "fit-content",
          }}
        >
          <Reveal>
            <Grid
              sx={{
                display: "flex",
                height: "36px",
                alignItems: "center",
                position: "sticky",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontWeight: "600",
                  lineHeight: "25.5px",
                  textAlign: "left",
                  color: " #007390",
                }}
              >
                {!searchParams || Object.keys(searchParams).length === 0 || !docDetails
                  ? "Recent Visits"
                  : "Search Results"}
              </Typography>
            </Grid>

            {loading && <ComponentLoader loaderMessage="Searching..." />}
          </Reveal>

          <Grid>
            {searchParams && Object.keys(searchParams).length !== 0 && docDetails && (
              <>
                {docDetails.map((docDetail, index) => {
                  const isFamilyDoctor = familyDoctor.some((doc) => doc.docId === docDetail.docId);
                  const actions = isFamilyDoctor ? removeActions : addActions;
                  return (
                    <CustomCard
                      key={index}
                      doctorDetail={docDetail}
                      isUhiDoctor={false}
                      actions={actions}
                    />
                  );
                })}
                {searchParams &&
                  Object.keys(searchParams).length !== 0 &&
                  docDetails &&
                  docDetails.length === 0 &&
                  !loading && (
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "17px",
                        fontWeight: "600",
                        lineHeight: "25.5px",
                        textAlign: "center",
                        color: " #004c70",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <SearchOffOutlined /> No data found...
                    </Typography>
                  )}
                {!showUhiDoctors && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: "2%",
                    }}
                  >
                    {/* <div>
                        <KeyboardDoubleArrowRightOutlinedIcon
                          style={{
                            width: "100%",
                            height: "18.36px",
                            top: "16px",
                            left: "4px",
                            padding: "0px",
                            gap: "0px",
                            opacity: "0px",
                            color: "#004C70",
                          }}
                        />
                      </div> */}
                    {/* <CustomTypography className="more-details" onClick={getUhiDoctors}>
                        View UHI Doctors
                      </CustomTypography> */}
                  </div>
                )}

                {/* {showUhiDoctors && (
                    <div className="uhi-doctors" style={{ marginTop: "20px" }}>
                      <div className="body-content">
                        {uhiDoctors?.map((doctor, index) => {
                          const isFamilyDoctor = familyDoctor.some(
                            (doc) => doc.docId === doctor.id
                          );
                          const actions = isFamilyDoctor ? removeActions : addActions;
                          return (
                            <DoctorDetailsCard
                              key={index}
                              doctorDetail={{ ...doctor, docId: doctor.id }}
                              isUhiDoctor={true}
                              actions={actions}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )} */}
              </>
            )}

            {(!searchParams || Object.keys(searchParams)?.length === 0 || !docDetails) &&
              recentVisits?.length !== 0 && (
                <>
                  {recentVisits?.map((doctor, index) => {
                    const isFamilyDoctor = familyDoctor.some((doc) => doc.docId === doctor.docId);
                    const actions = isFamilyDoctor ? removeActions : addActions;
                    return (
                      <CustomCard
                        key={index}
                        handleClick={handleClick}
                        doctorDetail={doctor}
                        isUhiDoctor={false}
                        actions={actions}
                      />
                    );
                  })}
                </>
              )}
            {(!searchParams || Object.keys(searchParams).length === 0 || !docDetails) &&
              recentVisits?.length === 0 && <h3>No recent visits</h3>}
          </Grid>
        </Grid>
        <div
          className="fixed-content-footer"
          style={{ paddingLeft: "0px", justifyContent: "space-between", marginBottom: "20px" }}
        >
          <Reveal className="body-header">
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "17px",
                fontWeight: "600",
                lineHeight: "25.5px",
                textAlign: "left",
                color: " #007390",
              }}
            >
              Nearby Doctors
            </Typography>
          </Reveal>
          {nearByDoctors && (
            <div className="body-content">
              {nearByDoctors.map((doctor, index) => {
                const isFamilyDoctor = familyDoctor.some((doc) => doc.docId === doctor.docId);
                const actions = isFamilyDoctor ? removeActions : addActions;
                return (
                  <DoctorDetailsCard
                    key={index}
                    doctorDetail={doctor}
                    isUhiDoctor={false}
                    actions={actions}
                  />
                );
              })}
            </div>
          )}

          {!nearByDoctors && (
            <Grid sx={{ textAlign: "center", padding: "20px" }}>
              <Typography variant="body1" gutterBottom>
                We need access to your location to find nearby doctors.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Please enable location permissions in your browser settings and then click the
                button below to fetch nearby doctors.
              </Typography>
              <CustomButton
                label={"Fetch Nearby doctors"}
                className={"btn--primary"}
                onClick={() => {
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                      (position) => {
                        fetchNearByDoctors({
                          latitude: position.coords.latitude,
                          longitude: position.coords.longitude,
                        });
                        setCurrentLocation({
                          latitude: position.coords.latitude,
                          longitude: position.coords.longitude,
                        });
                      },
                      (error) => {
                        handleClick(
                          "error",
                          "Error getting geo-location, Please manually allow location from the browser setting"
                        );
                      }
                    );
                  } else {
                    handleClick("error", "Geolocation is not supported by this browser.");
                  }
                }}
              />
            </Grid>
          )}
        </div>
      </div>
    </DoctorSearchSkeletonStructure>
  );
};

const FilterDrawerNew = ({
  searchParams,
  setSearchParams,
  setSelectedSpecialization,
  applyFilters,
  setOpen,
  specializations,
  setDoctorType,
  doctorType,
  setIsSpecializationDropdownOpen,
  isSpecializationDropdownOpen,
  selectedSpecialization,
  applyFilterDisable,
  setApplyFilterDisable,
}) => {
  const [specialization, setSpecialization] = useState(selectedSpecialization ?? null);
  const [minRating, setMinRating] = useState(searchParams?.minRating ?? null);
  const [consultationType, setConsultationType] = useState(searchParams?.consultationType ?? null);

  const handleSearchParamsForSpecialization = (newSearchParams) => {
    if (specialization) {
      // const newSearchParams = {
      //   ...searchParams,
      //   specializationId: selectedSpecialization?.id,
      // };
      setSelectedSpecialization(specialization);
      newSearchParams.specializationId = specialization?.id;
      // applyFilters(newSearchParams);
      // setSearchParams(newSearchParams);
    } else {
      // const { specializationId, ...newObj } = searchParams;
      delete newSearchParams?.specializationId;
      // applyFilters(newObj);
      // setSearchParams(newObj);
    }
  };

  const onApply = (searchParams) => {
    const newSearchParams = { ...searchParams };

    handleSearchParamsForSpecialization(newSearchParams);
    handleSearchParamsForMinRating(newSearchParams);
    handleSearchParamsForConsultationType(newSearchParams);
    handleSearchParamsForDoctorType(newSearchParams);

    applyFilters(newSearchParams);
    setSearchParams(newSearchParams);
    setOpen(false);
  };

  const handleSearchParamsForMinRating = (newSearchParams) => {
    if (minRating) {
      newSearchParams.minRating = minRating;
    } else {
      delete newSearchParams?.minRating;
    }
  };

  const handleSearchParamsForConsultationType = (newSearchParams) => {
    if (consultationType) {
      newSearchParams.consultationType = consultationType;
    } else {
      delete newSearchParams?.consultationType;
    }
  };

  const handleSearchParamsForDoctorType = (newSearchParams) => {
    if (doctorType) {
      newSearchParams.doctorType = doctorType;
    } else {
      delete newSearchParams?.doctorType;
    }
  };

  return (
    <FilterDrawer
      title="Filters"
      filters={[
        {
          label: "Specialization",
          componentType: "autocomplete",
          value: specialization,
          options: specializations,
          onChange: (_, newValue) => {
            // setSelectedSpecialization(newValue);
            setSpecialization(newValue);
            setApplyFilterDisable(false);
          },
          placeholder: "Select",
        },
        {
          label: "Minimum Rating",
          componentType: "radio",
          name: "minRating",
          value: minRating || null,
          // onChange: (e) => {
          //   setMinRating(e.target.value);
          //   setApplyFilterDisable(false);
          // },
          options: [1, 2, 3, 4, 5].map((rating) => ({
            value: String(rating),
            label: <Rating value={rating} readOnly />,
            checked: minRating === String(rating),
            onClick: () => {
              setTimeout(() => {
                setMinRating(minRating === String(rating) ? null : String(rating));
                setApplyFilterDisable(false);
              }, 1);
            },
          })),
        },
        {
          label: "Consultation Type",
          componentType: "radio",
          name: "consultationType",
          value: consultationType || null,
          // onChange: (e) => {
          //   setSearchParams({
          //     ...searchParams,
          //     consultationType: e.target.value,
          //   });
          //   e.target.value === TELE_CONSULTATION
          //     ? setClinicVisitSelected(false)
          //     : setTeleconsultationSelected(false);
          //   setApplyFilterDisable(false);
          // },
          options: [
            {
              value: TELE_CONSULTATION,
              label: "Teleconsultation",
              checked: consultationType === TELE_CONSULTATION,
              onClick: () => {
                const newConsultationType =
                  consultationType === TELE_CONSULTATION ? null : TELE_CONSULTATION;
                setTimeout(() => {
                  setConsultationType(newConsultationType);
                  setApplyFilterDisable(false);
                }, 1);
              },
            },
            {
              value: CLINIC_VISIT,
              label: "Clinic Visit",
              checked: consultationType === CLINIC_VISIT,
              onClick: () => {
                const newConsultationType = consultationType === CLINIC_VISIT ? null : CLINIC_VISIT;
                setTimeout(() => {
                  setConsultationType(newConsultationType);
                  setApplyFilterDisable(false);
                }, 1);
              },
            },
          ],
        },
        {
          label: "Source",
          componentType: "radio",
          name: "doctorType",
          value: doctorType || null,
          options: [
            {
              value: SYSTEM,
              label: "Arog",
              checked: doctorType === SYSTEM,
              onClick: () => {
                setTimeout(() => {
                  setDoctorType(doctorType === SYSTEM ? null : SYSTEM);
                  setApplyFilterDisable(false);
                }, 1);
              },
            },
            {
              value: BOTH,
              label: "All",
              checked: doctorType === BOTH,
              onClick: () => {
                setTimeout(() => {
                  setDoctorType(doctorType === BOTH ? null : BOTH);
                  setApplyFilterDisable(false);
                }, 1);
              },
            },
          ],
        },
      ]}
      applyFilterDisable={applyFilterDisable}
      setApplyFilterDisable={setApplyFilterDisable}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      applyFilters={onApply}
      resetFilters={() => {
        setSelectedSpecialization(null);
        const { minRating, consultationType, specializationId, doctorType, ...newObj } =
          searchParams;
        setSearchParams(newObj);
        setApplyFilterDisable(true);
        applyFilters(newObj);
        setOpen(false);
      }}
      onClose={() => setOpen(false)}
    />
  );
};

const DoctorSearchSkeletonStructure = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid container sx={{ ml: 2 }} size={{ xs: 12 }} justifyContent={"space-between"}>
          <Grid size={{ xs: 5 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid container sx={{ m: 2 }} size={{ xs: 12 }}>
          <Grid container size={{ xs: 12 }} sx={{ mb: 2 }} justifyContent={"space-between"}>
            <Grid size={{ xs: 2 }}>
              <Skeleton animation="wave" variant="text" width="100%" height={60} />
            </Grid>
            <Grid size={{ xs: 2 }}>
              <Skeleton animation="wave" variant="text" width="100%" height={60} />
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={150}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ m: 2 }} size={{ xs: 12 }}>
          <Grid container size={{ xs: 12 }} sx={{ mb: 2 }}>
            <Grid size={{ xs: 2 }}>
              <Skeleton animation="wave" variant="text" width="100%" height={60} />
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={150}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DoctorSearch;
