import React from "react";
import { BLADDER } from "utils/ehr-data-utils";

const HumanBlader = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  const isSelected = selectedOrgan.includes(BLADDER);
  return (
    <div
      id="human-body-bladder"
      className={`human-blader ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <div className="human-blader-pipes">
        <img
          style={{ height: "82px", width: "16px" }}
          src="/images/bladerRightPipe.svg"
          alt="Outer Body"
          className="bladerRightPipe"
        />
        <img
          style={{ height: "82px", width: "16px" }}
          src="/images/bladerLeftPipe.svg"
          alt="Outer Body"
          className={"bladerLeftPipe"}
        />
      </div>

      <img
        style={{ height: "31px", width: "32px" }}
        src="/images/blader.svg"
        alt="Outer Body"
        className={`blader hover body-part ${isSelected ? "highlight" : ""}`}
      />
    </div>
  );
};

export default HumanBlader;
