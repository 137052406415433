import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Grid, Input, Paper, Skeleton, Tab, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getActiveMember } from "store/Reducers/userReducer";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import PatientGeneralInfo from "./PatientProfile/PatientGeneralInfo";
import PatientImmunization from "./PatientProfile/PatientImmunization";
import PatientInsuranceInfo from "./PatientProfile/PatientInsuranceInfo";
import PatientVital from "./PatientProfile/PatientVital";
import { useLocation } from "react-router-dom";
import "assets/scss/GoogleMap.scss";
import { getPatientByUserId, getProfilePicture } from "services/patientService";
import { useContext } from "react";
import { useCallback } from "react";
import { SIZE_10_MB, SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import Reveal from "views/utilities/Reveal";
import { calculateAge } from "utils/calculate-age";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";

const PatientProfile = () => {
  const location = useLocation();
  const activeMember = useSelector(getActiveMember);
  const [patientDetails, setPatientDetails] = useState(null);
  const { handleClick } = useContext(ToastContext);
  const [readOnly, setReadOnly] = useState(true);
  const { initialTab } = location.state || { initialTab: "one" };
  const [value, setValue] = useState(initialTab);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = useCallback(async () => {
    try {
      try {
        const profilePicResponse = await getProfilePicture(activeMember?.id);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setProfileImageFileWithContent(profilePicWithContent);
      } catch (error) {
        console.error("Error fetching profile picture", error);
      }
      const patientResponse = await getPatientByUserId(activeMember?.id);
      setPatientDetails(patientResponse.data);
    } catch (error) {
      handleClick("error", "Error fetching patient data");
    }
  }, [activeMember?.id, handleClick]);

  const handleFileUploads = async (event) => {
    const file = event.target.files[0];

    if (file.size > SIZE_10_MB) {
      handleClick("error", "File size exceeds 10MB. Please choose a smaller file.");
      event.target.value = null;
      return;
    }

    let updatedSlected;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const s = new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({ file: file, content: reader.result });
      };
    });
    try {
      const updatedFiles = await s;
      updatedSlected = updatedFiles;
      setProfileImageFile(file);
      setProfileImageFileWithContent(updatedSlected);
    } catch (e) {
      handleClick("error", "Can not upload your file");
    }
  };

  const tablist = [
    {
      value: "one",
      label: "General info",
      component: (
        <PatientGeneralInfo
          profileImageFile={profileImageFile}
          patientDetails={patientDetails}
          setPatientDetails={setPatientDetails}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
        />
      ),
    },
    {
      value: "two",
      label: "Immunization",
      component: (
        <PatientImmunization
          patientDOB={patientDetails?.user?.dateOfBirth}
          patientId={patientDetails?.id}
        />
      ),
    },
    {
      value: "three",
      label: "Insurance",
      component: <PatientInsuranceInfo patientId={patientDetails?.id} />,
    },
    {
      value: "four",
      label: "Vitals",
      component: (
        <PatientVital
          fetchPatientLatestVitalSigns={() => {
            fetchPatientLatestVitalSigns(patientDetails?.id, setPatientLatestVitals, handleClick);
          }}
          patientDOB={patientDetails?.user?.dateOfBirth}
          patientId={patientDetails?.id}
        />
      ),
    },
  ];

  useEffect(() => {
    if (activeMember) {
      fetchData();
    }
  }, [activeMember, fetchData]);

  useEffect(() => {
    if (patientDetails) {
      fetchPatientLatestVitalSigns(patientDetails?.id, setPatientLatestVitals, handleClick);
    }
  }, [patientDetails]);

  const genderBasedImage =
    patientDetails?.user?.gender === "MALE" ? "/images/man.png" : "/images/woman.png";

  return (
    <PatientProfileSkeleton>
      <Reveal>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <div className="section-heading">
            <h2 className="page-title">Personal Details</h2>
          </div>
          <Grid>
            {readOnly && Boolean(value === "one") && (
              <CustomButton
                label={"Edit Profile"}
                startIcon={<i className="ri-edit-line ri-xl icon-primary-blue" />}
                className={"btn--secondary-light"}
                onClick={() => {
                  setReadOnly(false);
                }}
              />
            )}
          </Grid>
        </Grid>

        <Grid>
          <Paper
            variant="outlined"
            sx={{
              borderRadius: "12px",
              width: "100%",
              // minHeight: '650px',
              height: "fit-content",
              padding: "23px",
            }}
          >
            <Grid display={"flex"} sx={{ mb: "10px", width: "100%" }}>
              <Grid className="profile-image-container">
                <Avatar
                  alt="Patient"
                  src={profileImageFileWithContent?.content || genderBasedImage}
                  style={{
                    width: "125px",
                    height: "125px",
                    borderRadius: "50%",
                  }}
                  variant="square"
                  className="image"
                />
                <CustomButton
                  className="delete-profile-image-button"
                  onClick={() => {}}
                  iconButton={<i className="ri-edit-fill" />}
                  style={{
                    borderRadius: "15px",
                    width: "30px",
                    height: "30px",
                    display: readOnly ? "none" : undefined,
                  }}
                  component="label"
                >
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleFileUploads(e);
                    }}
                  />
                </CustomButton>
              </Grid>
              <Grid sx={{ ml: 2 }}>
                <Grid>
                  <Typography
                    sx={{
                      color: "#194B5A",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {patientDetails?.user?.name}
                  </Typography>
                  {patientDetails?.user?.dateOfBirth && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {patientDetails?.user?.gender} |{" "}
                      {calculateAge(patientDetails?.user?.dateOfBirth)} years
                    </Typography>
                  )}
                </Grid>
                <Grid container sx={{ mt: 2, width: "100%" }}>
                  {patientLatestVitals.map((vital, index) => (
                    <Grid
                      key={index}
                      className="badge"
                      display={"flex"}
                      alignItems={"center"}
                      color={vital.color}
                      backgroundColor={vital.bgColor}
                    >
                      {vital.vitalSignTypeResponse.displayName}{" "}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 300,
                          ml: 1,
                          color: "#000000",
                        }}
                      >
                        {`${vital.value} ${vital.vitalSignTypeResponse.unitAbbreviation ?? ""}`}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }} style={{ overflowX: "auto" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    TabIndicatorProps={{
                      sx: {
                        backgroundColor: "#004c70",
                      },
                    }}
                  >
                    {tablist.map((item, index) => {
                      return (
                        <Tab
                          key={index}
                          sx={{
                            backgroundColor: value === item.value ? "#e6eef1" : "inherit",
                            borderRadius: "8px 8px 0px 0px",
                          }}
                          label={item.label}
                          value={item.value}
                        />
                      );
                    })}
                  </TabList>
                </Box>
                {tablist.map((item, index) => {
                  return (
                    <TabPanel
                      key={index}
                      value={item.value}
                      sx={{
                        padding: "10px",
                      }}
                    >
                      {item.component}
                    </TabPanel>
                  );
                })}
              </TabContext>
            </Box>
          </Paper>
        </Grid>
      </Reveal>
    </PatientProfileSkeleton>
  );
};

const PatientProfileSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", ml: 3, mt: 1.5 }}>
          <Grid item xs={2} sx={{ mr: 2 }}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={125}
            />
          </Grid>
          <Grid item xs={10} flexDirection={"column"}>
            <Skeleton
              sx={{ borderRadius: "4px", mb: 1 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={17}
            />
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={17}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 3 }}
              animation="wave"
              variant="rectangular"
              width="90%"
              height={25}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mt: 1 }}
              animation="wave"
              variant="rectangular"
              width="50%"
              height={25}
            />
          </Grid>
        </Grid>

        <Grid sx={{ ml: 3 }} item xs={12} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
          <Skeleton
            sx={{ borderRadius: "4px", mr: 2 }}
            animation="wave"
            variant="rectangular"
            width="15%"
            height={40}
          />
        </Grid>

        <Grid sx={{ ml: 3 }} item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientProfile;
