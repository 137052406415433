// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom Tour Guide Modal */
.tour-guide-modal {
  border: 1px solid black;
}
.tour-guide-modal .shepherd-text {
  text-align: center !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  padding-top: 0;
}
.tour-guide-modal .tour-guide-next-button {
  background-color: #004c70 !important;
  color: #ffffff !important;
}
.tour-guide-modal .tour-guide-back-button {
  background-color: #d3d3d3 !important;
  color: #ffffff !important;
}
.tour-guide-modal .tour-guide-finish-button {
  background-color: #29bf91 !important;
  color: #ffffff !important;
}

.tour-modal-arrow-top .shepherd-arrow:before {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.tour-modal-arrow-bottom .shepherd-arrow:before {
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.tour-modal-arrow-left .shepherd-arrow:before {
  border-top: 1px solid black;
  border-right: 1px solid black;
}

.tour-modal-arrow-right .shepherd-arrow:before {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/shepherd-guide.scss"],"names":[],"mappings":"AAAA,4BAAA;AACA;EACE,uBAAA;AACF;AACE;EACE,6BAAA;EACA,eAAA;EACA,kCAAA;EACA,cAAA;AACJ;AACE;EACE,oCAAA;EACA,yBAAA;AACJ;AACE;EACE,oCAAA;EACA,yBAAA;AACJ;AACE;EACE,oCAAA;EACA,yBAAA;AACJ;;AAKE;EACE,8BAAA;EACA,6BAAA;AAFJ;;AAME;EACE,2BAAA;EACA,4BAAA;AAHJ;;AAQE;EACE,2BAAA;EACA,6BAAA;AALJ;;AASE;EACE,8BAAA;EACA,4BAAA;AANJ","sourcesContent":["/* Custom Tour Guide Modal */\n.tour-guide-modal{\n  border: 1px solid black;\n\n  .shepherd-text{\n    text-align: center !important;\n    font-size: 14px;\n    font-family: \"Poppins\", sans-serif;\n    padding-top: 0;\n  }\n  .tour-guide-next-button{\n    background-color: #004c70 !important;\n    color: #ffffff !important;\n  }\n  .tour-guide-back-button{\n    background-color: #d3d3d3 !important;\n    color: #ffffff !important;\n  }\n  .tour-guide-finish-button{\n    background-color: #29bf91 !important;\n    color: #ffffff !important;\n  }\n}\n\n// to give border to the arrow based on direction\n.tour-modal-arrow-top {\n  .shepherd-arrow:before{\n    border-bottom: 1px solid black;\n    border-right: 1px solid black;\n  }\n}\n.tour-modal-arrow-bottom {\n  .shepherd-arrow:before{\n    border-top: 1px solid black;\n    border-left: 1px solid black;\n  }\n}\n\n.tour-modal-arrow-left {\n  .shepherd-arrow:before{\n    border-top: 1px solid black;\n    border-right: 1px solid black;\n  }\n}\n.tour-modal-arrow-right {\n  .shepherd-arrow:before{\n    border-bottom: 1px solid black;\n    border-left: 1px solid black;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
