import React from "react";
import { ListItemIcon, MenuItem as MuiMenuItem } from "@mui/material";

const CustomMenuItem = ({ text, icon, disabled, onClick, ...props }) => {
  return (
    <MuiMenuItem onClick={onClick} disabled={disabled}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <div style={{ fontSize: "0.875rem" }}>{text}</div>
    </MuiMenuItem>
  );
};

export default CustomMenuItem;
