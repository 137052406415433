import { useContext } from "react";
import { createContext } from "react";

export const SidebarUtilContext = createContext(null);

export const useMainClass = () => useContext(SidebarUtilContext);

export const SidebarUtilProvider = ({
  children,
  mainClass,
  toggleMainClass,
  setSecondarySideBarData,
}) => {
  return (
    <SidebarUtilContext.Provider value={{ mainClass, toggleMainClass, setSecondarySideBarData }}>
      {children}
    </SidebarUtilContext.Provider>
  );
};
