import React from "react";
import { KIDNEY } from "utils/ehr-data-utils";

const HumanKidney = ({ organKey, getOrganVitalCharts, selectedOrgan, onClick }) => {
  // const isSelected = selectedOrgan === "kidney";
  const isSelected = selectedOrgan.includes(KIDNEY);

  return (
    <div
      id="human-body-kidney"
      className={`human-kidney ${!isSelected && selectedOrgan.length > 0 ? "fade" : ""}`}
      onClick={onClick}
    >
      <img
        src="/images/rightKidney.svg"
        alt="Outer Body"
        className={`rightKidney hover body-part ${isSelected ? "highlight" : ""}`}
      />
      {!selectedOrgan.length > 0 || isSelected ? (
        <div className="kidney-parts">
          <img
            src="/images/kidneyAorta.svg"
            alt="Outer Body"
            className={`kidneyAorta hover body-part ${isSelected ? "highlight" : ""}`}
          />
          <img
            src="/images/kidneyVenaCava.svg"
            alt="Outer Body"
            className={`kidneyVenaCava hover body-part ${isSelected ? "highlight" : ""}`}
          />
        </div>
      ) : null}
      <img
        src="/images/leftKidney.svg"
        alt="Outer Body"
        className={`leftKidney hover body-part ${isSelected ? "highlight" : ""}`}
      />
    </div>
  );
};

export default HumanKidney;
