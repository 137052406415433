import { api as API } from "./AxiosInterceptor";

// feature Apis
export const getFeatures = () => API.get(`/api/features`);
export const createFeatures = (data) => API.post(`/api/features`, data);
export const updateFeatures = (id, data) => API.put(`/api/features/${id}`, data);
export const deleteFeatures = (id) => API.delete(`/api/features/${id}`);
export const createFeatureAssociation = (data) => API.post(`/api/feature-associations`, data);
export const getFeaturesByUserId = (id) => API.get(`/api/feature-associations/user?userId=${id}`);
export const deleteFeatureAssociation = (id) => API.delete(`/api/feature-associations/${id}`);
export const getFeatureAssociationsByFeatureId = (id) =>
  API.get(`/api/feature-associations/feature?featureId=${id}`);

// allergy Apis
export const getAllergies = () => API.get(`/api/allergies`);
export const createAllergies = (data) => API.post(`/api/allergies`, data);
export const updateAllergies = (id, data) => API.put(`/api/allergies/${id}`, data);
export const deleteAllergies = (id) => API.delete(`/api/allergies/${id}`);

// Chronic Diseases Apis
export const getChronicDiseases = () => API.get(`/api/chronic-diseases`);
export const createChronicDiseases = (data) => API.post(`/api/chronic-diseases`, data);
export const updateChronicDiseases = (id, data) => API.put(`/api/chronic-diseases/${id}`, data);
export const deleteChronicDiseases = (id) => API.delete(`/api/chronic-diseases/${id}`);

// procedure Apis
export const getProcedures = () => API.get(`/api/procedures`);
export const createProcedures = (data) => API.post(`/api/procedures`, data);
export const updateProcedures = (id, data) => API.put(`/api/procedures/${id}`, data);
export const deleteProcedures = (id) => API.delete(`/api/procedures/${id}`);

// Vaccine type Apis
export const getVaccineTypes = () => API.get(`/api/vaccine-types`);
export const createVaccineTypes = (data) => API.post(`/api/vaccine-types`, data);
export const updateVaccineTypes = (id, data) => API.put(`/api/vaccine-types/${id}`, data);
export const deleteVaccineTypes = (id) => API.delete(`/api/vaccine-types/${id}`);

// Medications Apis
export const getMedications = () => API.get(`/api/medications`);
export const createMedications = (data) => API.post(`/api/medications`, data);
export const updateMedications = (id, data) => API.put(`/api/medications/${id}`, data);
export const deleteMedications = (id) => API.delete(`/api/medications/${id}`);

// Vital Sign Types Apis
export const getVitalSignTypes = () => API.get(`/api/vital-sign-types`);
export const createVitalSignTypes = (data) => API.post(`/api/vital-sign-types`, data);
export const updateVitalSignTypes = (id, data) => API.put(`/api/vital-sign-types/${id}`, data);
export const deleteVitalSignTypes = (id) => API.delete(`/api/vital-sign-types/${id}`);

// languages related api
export const getLanguages = () => API.get(`/api/languages`);
export const createLanguages = (data) => API.post(`/api/languages`, data);
export const updateLanguages = (id, data) => API.put(`/api/languages/${id}`, data);
export const deleteLanguages = (id) => API.delete(`/api/languages/${id}`);

// specialization related Apis
export const getSpecializations = () => API.get(`/api/specializations/all`);
export const createSpecializations = (data) => API.post(`/api/specializations`, data);
export const updateSpecializations = (id, data) => API.put(`/api/specializations/${id}`, data);
export const deleteSpecializations = (id) => API.delete(`/api/specializations/${id}`);
