import {
  SET_APPOINTMENT_DATA,
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_TIME,
  SET_DOCTOR_ID,
  SET_NOTES,
  SET_ORG_ID,
  SET_PATIENT_ID,
  SET_SELECTED_APPOINTMENT_DATA,
  SET_TYPE,
  SET_UHI_APPOINTMENT_DATA,
} from "store/Actions/appointmentDataAction";

const initialState = {
  appointmentData: null,
  orgId: null,
  appointmentTime: null,
  patientId: null,
  doctorId: null,
  appointmentDate: null,
  notes: null,
  type: null,
  selectedAppointmentData: null,
  uhiAppointmentData: null,
};

const appointmentDataReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_APPOINTMENT_DATA: {
      return { ...state, appointmentData: action.payload };
    }
    case SET_SELECTED_APPOINTMENT_DATA: {
      return { ...state, selectedAppointmentData: action.payload };
    }
    case SET_ORG_ID: {
      return {
        ...state,
        selectedAppointmentData: {
          ...state.selectedAppointmentData,
          orgId: action.payload,
        },
      };
    }
    case SET_APPOINTMENT_TIME: {
      return {
        ...state,
        selectedAppointmentData: {
          ...state.selectedAppointmentData,
          appointmentTime: action.payload,
        },
      };
    }
    case SET_PATIENT_ID: {
      return {
        ...state,
        selectedAppointmentData: {
          ...state.selectedAppointmentData,
          patientId: action.payload,
        },
      };
    }
    case SET_DOCTOR_ID: {
      return {
        ...state,
        selectedAppointmentData: {
          ...state.selectedAppointmentData,
          doctorId: action.payload,
        },
      };
    }
    case SET_APPOINTMENT_DATE: {
      return {
        ...state,
        selectedAppointmentData: {
          ...state.selectedAppointmentData,
          appointmentDate: action.payload,
        },
      };
    }
    case SET_NOTES: {
      return {
        ...state,
        selectedAppointmentData: {
          ...state.selectedAppointmentData,
          notes: action.payload,
        },
      };
    }
    case SET_TYPE: {
      return {
        ...state,
        selectedAppointmentData: {
          ...state.selectedAppointmentData,
          type: action.payload,
        },
      };
    }
    case SET_UHI_APPOINTMENT_DATA: {
      return {
        ...state,
        uhiAppointmentData: action.payload,
      };
    }
    default:
      return state;
  }
};

export const getAppointmentData = (state) => state.appointmentDataReducer.appointmentData;
export const getSelectedAppointmentData = (state) =>
  state.appointmentDataReducer.selectedAppointmentData;

export const getOrgId = (state) => state.appointmentDataReducer.orgId;

export const getPatientId = (state) => state.appointmentDataReducer.patientId;

export const getDoctorId = (state) => state.appointmentDataReducer.doctorId;

export const getAppointmentDate = (state) => state.appointmentDataReducer.appointmentDate;

export const getAppointmentTime = (state) => state.appointmentDataReducer.appointmentTime;
export const getNotes = (state) => state.appointmentDataReducer.notes;
export const getType = (state) => state.appointmentDataReducer.type;
export const getUhiAppointmentData = (state) => state.appointmentDataReducer.uhiAppointmentData;

export default appointmentDataReducer;
