import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ExpandMore, RestartAltOutlined } from "@mui/icons-material";
import CustomButton from "./custom-components/CustomButton";
import CustomAutoComplete from "./custom-components/CustomAutoComplete";
import CloseIcon from "@mui/icons-material/Close";

const FilterDrawer = ({
  title,
  filters,
  applyFilterDisable,
  setApplyFilterDisable,
  searchParams,
  applyFilters,
  resetFilters,
  onClose,
}) => {
  const renderComponent = (filter) => {
    switch (filter.componentType) {
      case "radio":
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby={`${filter.name}-radio-group-label`}
              value={filter.value}
              name={filter.name}
              onChange={filter.onChange}
            >
              {filter.options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio size="small" sx={{ padding: "3px 9px" }} />}
                  onClick={option.onClick}
                  checked={option.checked}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "autocomplete":
        return (
          <FormControl sx={{ width: "100%" }}>
            <CustomAutoComplete
              options={filter.options}
              onChange={filter.onChange}
              iconStart={<i className="ri-search-line ri-lg" />}
              label={filter.label}
              placeholder={filter.placeholder}
              getOptionLabel={(option) => option?.label}
              value={filter.value}
            />
            {filter.customComponent}
          </FormControl>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: 300,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <Grid> */}
      <Grid sx={{ background: "#004c70", height: "50px" }} display={"flex"} alignItems={"center"}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "19px",
            fontWeight: "500",
            textAlign: "left",
            paddingLeft: "15px",
            color: "#ffffff",
          }}
        >
          {title}
        </Typography>
        <div style={{ marginLeft: "auto" }}>
          <CustomButton
            style={{
              marginRight: 0,
              paddingRight: 0,
              display: applyFilterDisable ? "none" : undefined,
            }}
            className={"btn--primary"}
            startIcon={<RestartAltOutlined />}
            onClick={() => {
              resetFilters();
              setApplyFilterDisable(true);
            }}
          />
          <CustomButton
            onClick={onClose}
            iconButton={<CloseIcon></CloseIcon>}
            style={{ color: "white", paddingLeft: 0, height: "2px" }}
          ></CustomButton>
        </div>
      </Grid>
      <Box flexGrow={1} overflow={"auto"}>
        {filters.map((filter, index) => (
          <Accordion
            defaultExpanded
            key={index}
            sx={{ margin: "0px !important", ":: before": { opacity: 0 } }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                color: "black",
                minHeight: "48px !important",
                "& .MuiAccordionSummary-content": {
                  margin: "0px !important",
                },
              }}
            >
              {filter.label}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px 16px 7px 16px" }}>
              {renderComponent(filter)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Grid display={"flex"} justifyContent={"center"} sx={{ mb: 1, pt: 1 }}>
        <CustomButton
          style={{
            marginRight: "5px",
            opacity: applyFilterDisable ? "0.6" : "1",
            pointerEvents: applyFilterDisable ? "none" : "auto",
          }}
          className={"btn--secondary"}
          label={"Apply"}
          onClick={() => {
            applyFilters(searchParams);
            onClose();
          }}
        />
      </Grid>
      {/* </Grid> */}
    </Box>
  );
};

export default FilterDrawer;
