import { CategoryOutlined, Description } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { Formik } from "formik";
import { useContext } from "react";
import { useState } from "react";
import { addDiscount, getServiceConfiguration, updateDiscount } from "services/BillingService";
import { DATE_FORMAT, DATE_FORMAT_DMY, ORGANIZATION, orgId } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { discountValidationSchema } from "views/Components/Common/ValidationSchema/discountValidationSchema";

const ManageDiscount = ({ selected, setOpenModal, fetchDiscounts, setSelected }) => {
  const { handleClick } = useContext(ToastContext);

  const [services, setServices] = useState([]);
  const discounts = [
    { menuLabel: "PERCENTAGE", value: "PERCENTAGE" },
    { menuLabel: "AMOUNT", value: "FIXED_AMOUNT" },
  ];

  useState(() => {
    const fetchMedicalServices = async () => {
      try {
        const serviceResponse = await getServiceConfiguration(orgId(), null, ORGANIZATION);
        const filteredServices = serviceResponse.data.filter(
          (obj) => !obj.discounts.some((discount) => discount.discountStatus === "ACTIVE")
        );
        let menuServices = [];
        filteredServices?.forEach((element) => {
          const menuItem = {
            value: element?.id,
            menuLabel: element?.name,
          };

          menuServices.push(menuItem);
        });
        const selectedMenuService = {
          value: selected?.applicableServiceId,
          menuLabel: serviceResponse?.data?.find((e) => e?.id === selected?.applicableServiceId)
            ?.name,
        };
        if (selected) {
          setServices([...menuServices, selectedMenuService]);
        } else {
          setServices(menuServices);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchMedicalServices();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        description: selected?.description ? selected?.description : null,
        discountType: selected?.discountType ? selected?.discountType : discounts[0].value,
        value: selected?.value ? selected?.value : null,
        applicableServiceId: selected?.applicableServiceId ? selected?.applicableServiceId : "",
        validFrom: selected?.validFrom ? dayjs(selected?.validFrom) : null,
        validTo: selected?.validTo ? dayjs(selected?.validTo) : null,
      }}
      validationSchema={discountValidationSchema}
      onSubmit={async (values, { resetForm }) => {
        const data = {
          description: values?.description,
          discountType: values?.discountType,
          value: values?.value,
          applicableServiceId: values?.applicableServiceId,
          validFrom: dayjs(values?.validFrom).format(DATE_FORMAT),
          validTo: dayjs(values?.validTo).format(DATE_FORMAT),
        };

        try {
          if (selected) {
            await updateDiscount(selected?.id, data);
            handleClick("success", "Discount has been successfully updated!");
          } else {
            await addDiscount(data);
            handleClick("success", "Discount has been successfully added!");
          }
          resetForm({
            values: {
              description: "",
              discountType: discounts[0].value,
              value: "",
              applicableServiceId: "",
              validFrom: null,
              validTo: null,
            },
          });
          setSelected(null);
          setOpenModal(false);
          fetchDiscounts();
        } catch (error) {
          handleClick("error", `Failed to ${selected ? "Update" : "Add"} Discount`);
        }
      }}
    >
      {({ values, errors, touched, setFieldValue, handleChange, handleSubmit, handleBlur }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
            <Grid size={{ xs: 12 }}>
              <FormInputField
                style={{ width: "100%", marginTop: "7px" }}
                label="Description"
                name="description"
                value={values.description}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                startAdornment={<Description />}
                size={"big"}
                error={Boolean(errors.description && touched.description)}
                errorText={errors.description}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormSelectField
                style={{ width: "100%", marginTop: "7px" }}
                label={"Service"}
                required
                name={"applicableServiceId"}
                onBlur={handleBlur}
                value={values.applicableServiceId}
                size={"big"}
                startAdornment={<CategoryOutlined />}
                onChange={handleChange}
                menuItems={services}
                error={Boolean(errors.applicableServiceId && touched.applicableServiceId)}
                errorText={errors.applicableServiceId}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormSelectField
                style={{ width: "100%", marginTop: "7px" }}
                label={"Discount Type"}
                required
                name={"discountType"}
                value={values.discountType}
                size={"big"}
                onBlur={handleBlur}
                startAdornment={<CategoryOutlined />}
                onChange={handleChange}
                menuItems={discounts}
                error={Boolean(errors.discountType && touched.discountType)}
                errorText={errors.discountType}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormInputField
                style={{ width: "100%", marginTop: "7px" }}
                label="Value"
                name="value"
                type={"number"}
                value={values.value}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                startAdornment={<Description />}
                size={"big"}
                error={Boolean(errors.value && touched.value)}
                errorText={errors.value}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormDatePicker
                label={"Valid From"}
                name={"validFrom"}
                required
                style={{ width: "100%", marginTop: "7px" }}
                disablePast
                value={values?.validFrom}
                onChange={(date) => setFieldValue("validFrom", date)}
                onBlur={handleBlur}
                format={DATE_FORMAT_DMY}
                maxDate={values?.validTo}
                disableBorder={false}
                error={Boolean(errors.validFrom && touched.validFrom)}
                errorText={errors.validFrom}
              />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <FormDatePicker
                label={"Valid Till"}
                name={"validTo"}
                required
                style={{ width: "100%", marginTop: "7px" }}
                disablePast
                value={values?.validTo}
                onChange={(date) => setFieldValue("validTo", date)}
                onBlur={handleBlur}
                format={DATE_FORMAT_DMY}
                disableBorder={false}
                minDate={values?.validFrom}
                error={Boolean(errors.validTo && touched.validTo)}
                errorText={errors.validTo}
              />
            </Grid>

            <CustomButton
              onClick={handleSubmit}
              label={"Save"}
              className={"btn--secondary"}
              style={{ marginLeft: "auto" }}
            />
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ManageDiscount;
