import React, { useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip, InputAdornment, TextField, debounce } from "@mui/material";
import { useRef, useState } from "react";
import { useContext } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import { generatePrescription, searchPrescription } from "services/PrescriptionsService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { setPrescriptionPadData, getPrescriptionPadData } from "store/Slices/prescriptionPadSlice";
import { useDispatch, useSelector } from "react-redux";

/* Prescription search autocomplete component and all related logics */

const PrescriptionSearch = ({
  templates = [],
  handleSelectTemplate,
  isTeleconsultation = false,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const selectedItemRef = useRef({});
  const isSuggestionRef = useRef(true);
  const [highlightedOption, setHighlightedOption] = useState(null);
  // input value consists the complete input value in textfield
  const [inputValue, setInputValue] = useState("");
  // display text consists of input value for which enter is pressed
  const [displayText, setDisplayText] = useState("");
  const [startHelperText, setStartHelperText] = useState("Search :");
  const countRef = useRef(0);
  const [addItem, setAddItem] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const audioStream = useRef(null);
  const audioRecorder = useRef(null);
  const audioChunks = useRef([]);
  const { handleClick } = useContext(ToastContext);

  const dispatch = useDispatch();
  const currentPrescription = useSelector(getPrescriptionPadData);

  // to check if searching for category or item
  const [selectedCategory, setSelectedCategory] = useState(null);

  // reset the states related to prescription search
  const resetSearchState = () => {
    countRef.current = 0;
    updateStartHelperText();
    setInputValue("");
    setDisplayText("");
    setSuggestions([]);
    selectedItemRef.current = null;
    isSuggestionRef.current = true;
    setAddItem(false);
  };

  const mapGeneratedPrescription = (resp) => {
    let response = resp.data;
    const medicationsArr = response.prescriptionMedicationResponses.map((medication) => {
      return {
        medicationId: medication.medicationId,
        category: "RX",
        displayName: medication.medicationResponse.name,
        frequency: medication.frequency,
        duration: medication.duration,
        instructions: medication.instructions,
        doseTiming: medication.doseTiming,
      };
    });

    const diagnosisArr = response.prescriptionDiagnosisResponses.map((diagnosis) => {
      return {
        diagnosisId: diagnosis.diagnosisId,
        category: "DX",
        displayName: diagnosis.diagnosisResponse.name,
        conclusions: diagnosis.conclusions,
      };
    });

    const symptomsArr = response.prescriptionSymptomResponses.map((symptom) => {
      return {
        symptomId: symptom.symptomId,
        category: "CC",
        displayName: symptom.symptomResponse.name,
        since: symptom.since,
        severity: symptom.severity,
      };
    });

    const labInvestigationsArr = response.prescriptionLabReportResponses.map((lab) => {
      return {
        labTestId: lab.labTestId,
        category: "LAB",
        displayName: lab.labTestResponse.name,
        instructions: lab.instructions,
      };
    });

    const advice = response.additionalRecommendations;
    let advicesArr = [];
    if (advice !== "") {
      advicesArr = response.additionalRecommendations.split(",").map((advice) => {
        return {
          category: "ADV",
          displayName: advice,
        };
      });
    }
    return {
      medicationsArr: medicationsArr,
      symptomsArr: symptomsArr,
      diagnosisArr: diagnosisArr,
      labInvestigationsArr: labInvestigationsArr,
      advicesArr: advicesArr,
    };
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStream.current = stream;
      audioRecorder.current = new MediaRecorder(stream);

      //Clear the existing Prescription when the recording gets started
      let emptyPrescriptionData = {
        medicationsArr: [],
        symptomsArr: [],
        diagnosisArr: [],
        labInvestigationsArr: [],
        advicesArr: [],
      };
      dispatch(setPrescriptionPadData(emptyPrescriptionData));

      audioRecorder.current.ondataavailable = (e) => {
        // If the conversation gets longer than 25MB, do not send the request
        if (e.data.size > 25 * 1024 * 1024) {
          handleClick("error", "Conversation is too long");
          audioChunks.current = [];
          return;
        }
        if (e.data.size > 0) {
          audioChunks.current.push(e.data);
        }
      };
      audioRecorder.current.onstop = async () => {
        if (audioChunks.current.length) {
          const recordedBlob = new Blob(audioChunks.current, {
            type: "audio/mpeg",
          });
          const formData = new FormData();
          formData.append("file", recordedBlob);
          try {
            let resp = await generatePrescription(formData);
            let prescriptionPadData = mapGeneratedPrescription(resp);
            dispatch(setPrescriptionPadData(prescriptionPadData));
          } catch (err) {
            handleClick("error", "Something went wrong!");
          }
          audioChunks.current = [];
        }
      };
      audioRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      if (error instanceof DOMException) handleClick("error", "Permission Denied!");
      else handleClick("error", "Something went wrong!");
    }
  };

  const stopRecording = async () => {
    if (audioRecorder.current && audioRecorder.current.state === "recording") {
      audioRecorder.current.stop();
    }
    if (audioStream.current) {
      audioStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    setIsRecording(false);
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleItemsChange = (event, newValue) => {
    // deleting an item reset search states
    if (newValue.length < selectedValues.length) {
      resetSearchState();
    }

    // removing any duplicate value
    newValue = newValue.filter((item, index, self) => {
      if (item.id) {
        const existingIndex = self.findIndex(
          (t) => t.id === item.id && t.category === item.category
        );
        return index === existingIndex;
      } else {
        return (
          index ===
          self.findIndex((t) => t.displayName === item.displayName && t.category === item.category)
        );
      }
    });
    setSelectedValues(newValue);
  };

  // search items based on input (minimum 3 characters)
  const handleCustomSearch = async (event, newInputValue, reason) => {
    setInputValue(newInputValue);
    var param = newInputValue.trim();

    if (param !== "" && param.length >= 3) {
      // call api only for item (not for duration/notes/etc)
      if (countRef.current === 0) {
        prescriptionSearchDebounce(param);
      }
    } else {
      setSuggestions([]);
    }
  };

  const prescriptionSearch = async (param) => {
    try {
      const searchResponse = await searchPrescription(param);
      const filteredSuggestions = searchResponse.data;

      let extractedData;
      extractedData = filteredSuggestions.map((item) => {
        const parsedItem = JSON.parse(item.additionalInfo);
        return {
          id: item.id,
          category: item.category,
          displayName: parsedItem?.displayName,
          manufacturer: parsedItem?.manufacturer,
          activeIngredient: parsedItem?.activeIngredient,
        };
      });
      setSuggestions([...extractedData]);
    } catch (error) {
      console.error("API issue!");
    }
  };

  const prescriptionSearchDebounce = useCallback(debounce(prescriptionSearch, 500), []);

  // remove extra spaces in between texts
  const removeAllExtraSpaces = (input) => {
    return input.replace(/\s+/g, " ");
  };

  // get chip color based on category for autocomplete
  const getTagColorClass = (input) => {
    if (input === "RX") {
      return "badge-green";
    } else if (input === "CC") {
      return "badge-red";
    } else if (input === "DX") {
      return "badge-orange";
    } else if (input === "LAB") {
      return "badge-teal";
    } else if (input === "ADV") {
      return "badge-grey";
    } else {
      return "badge-grey";
    }
  };

  // update the starting helper text based on category and its order
  const updateStartHelperText = () => {
    const category = selectedItemRef.current?.category;
    if (countRef.current === 0) {
      setStartHelperText("Search :");
    }
    if (category === "DX") {
      if (countRef.current === 1) {
        setStartHelperText("Notes :");
        setAddItem(true);
      }
    } else if (category === "CC") {
      if (countRef.current === 1) {
        setStartHelperText("Since :");
        setAddItem(true);
      }
    } else if (category === "RX") {
      if (countRef.current === 1) {
        setStartHelperText("Frequency :");
      } else if (countRef.current === 2) {
        setStartHelperText("Duration :");
        setAddItem(true);
      }
    } else if (category === "LAB") {
      if (countRef.current === 1) {
        setStartHelperText("Instructions :");
        setAddItem(true);
      }
    }
  };

  // method to convert the duration data into proper format
  const formatDuration = (input) => {
    const matchNumeric = input?.match(/\d+/);
    const numericPart = matchNumeric ? parseInt(matchNumeric[0]) : 0;

    const matchString = input?.match(/([^0-9]+)\b/);
    const stringPart = matchString ? matchString[0].trim() : "";

    let durationUnit;
    switch (stringPart.toLowerCase().charAt(0)) {
      case "m":
        durationUnit = "Month";
        break;
      case "w":
        durationUnit = "Week";
        break;
      case "y":
        durationUnit = "Year";
        break;
      default:
        durationUnit = "Day";
        break;
    }
    return `${numericPart} ${durationUnit}${numericPart > 1 ? "s" : ""}`;
  };

  const saveDataIntoPad = async (values) => {
    // seperate the data based on the cateory and save into respective lists
    const medicines = [];
    const symptoms = [];
    const diagnosis = [];
    const labTests = [];
    const adv = [];
    for (const value of values) {
      if (value.category === "RX") {
        const tagParts = value?.displayName.split(":");
        const displayName = tagParts[0]?.trim();
        const frequency = tagParts?.[1]?.trim() ?? null;
        const duration = tagParts?.[2]?.trim() ?? null;
        medicines.push({
          medicationId: value.id,
          displayName: displayName,
          frequency: frequency,
          duration: formatDuration(duration),
          doseTiming: "AFTER_MEAL",
          category: value.category,
        });
      } else if (value.category === "CC") {
        const tagParts = value?.displayName.split(":");
        const displayName = tagParts[0]?.trim();
        const since = tagParts?.[1]?.trim() ?? null;
        symptoms.push({
          symptomId: value.id,
          displayName: displayName,
          since: formatDuration(since),
          severity: "NONE",
          category: value.category,
        });
      } else if (value.category === "DX") {
        const tagParts = value?.displayName.split(":");
        const displayName = tagParts[0]?.trim();
        const conclusions = tagParts?.[1]?.trim() ?? null;
        diagnosis.push({
          diagnosisId: value.id,
          displayName: displayName,
          conclusions: conclusions,
          category: value.category,
        });
      } else if (value.category === "LAB") {
        const tagParts = value?.displayName.split(":");
        const displayName = tagParts[0]?.trim();
        const instructions = tagParts?.[1]?.trim() ?? null;
        labTests.push({
          labTestId: value.id,
          displayName: displayName,
          instructions: instructions,
          category: value.category,
        });
      } else if (value.category === "ADV") {
        const displayName = value?.displayName?.trim();
        adv.push({ displayName });
      }
    }
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: [...currentPrescription.diagnosisArr, ...diagnosis],
        symptomsArr: [...currentPrescription.symptomsArr, ...symptoms],
        medicationsArr: [...currentPrescription.medicationsArr, ...medicines],
        labInvestigationsArr: [...currentPrescription.labInvestigationsArr, ...labTests],
        advicesArr: [...currentPrescription.advicesArr, ...adv],
      })
    );
  };

  const handleHighlightChange = (event, option, reason) => {
    setHighlightedOption(option);
  };

  const addAsAdvice = (event) => {
    const newValue = {
      displayName: inputValue,
      category: "ADV",
    };
    handleItemsChange(event, [...selectedValues, newValue]);
    resetSearchState();
  };

  const handleSelectItem = (event) => {
    // do nothing when option/suggestion is disabled
    if (highlightedOption && highlightedOption.disabled) {
      return;
    }

    // select the highlighted option from suggestions
    if (isSuggestionRef.current) {
      selectedItemRef.current = highlightedOption;
    }

    // save data into pad when no input value, and enter is pressed
    if (inputValue.trim() === "") {
      saveDataIntoPad(selectedValues);
      const close = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
      close?.click();
      countRef.current = 0;
      updateStartHelperText();
      return;
    }

    // if enter pressed for a suggestion/option
    if (selectedItemRef.current && isSuggestionRef.current) {
      if (selectedItemRef.current.category === "TEMP") {
        handleSelectTemplate(selectedItemRef.current);
        resetSearchState();
      } else {
        const updatedInputValue = displayText + selectedItemRef.current?.displayName + " : ";
        setInputValue(updatedInputValue);
        setDisplayText(updatedInputValue);
        isSuggestionRef.current = false;
        countRef.current += 1;
        updateStartHelperText();
      }
    } else {
      // if enter pressed for a normal text
      const splitInput = inputValue.split(":");

      // extract the last added input value
      let newInput = splitInput[splitInput.length - 1].trim();
      newInput = removeAllExtraSpaces(newInput);
      // if there is new input value and addItem is false (it is not last data), add as text in search bar
      if (newInput && !addItem && (selectedItemRef.current?.category || selectedCategory)) {
        // if advice category selected (category-wise search) (no additional data needed)
        if (selectedCategory && selectedCategory.displayName === "ADV") {
          addAsAdvice(event);
        } else {
          // add as input and ask for additional data
          const updatedInputValue = displayText + newInput + " : ";
          setInputValue(updatedInputValue);
          setDisplayText(updatedInputValue);
          countRef.current++;
          updateStartHelperText();
        }
      } else if (!selectedItemRef.current?.category) {
        // if no selectedItem, then add as advice (for generic search)
        addAsAdvice(event);
      } else {
        // if no text, or addItem is true, add the text till now as item
        let finalText = displayText + newInput;
        finalText = finalText.trim();
        let category;

        category = selectedItemRef.current?.category;
        if (finalText.endsWith(":")) {
          finalText = finalText.slice(0, -1); // Remove the last character
        }
        const newValue = {
          ...selectedItemRef.current,
          displayName: finalText,
          category: category,
        };
        handleItemsChange(event, [...selectedValues, newValue]);
        resetSearchState();
      }
    }
  };

  // listen for key press
  const handleKeyPress = (event) => {
    inputValue.trim();

    if (event.key === "Enter") {
      event.stopPropagation();
      handleSelectItem(event);
    } else if (event.key === "Backspace") {
      // when colon is deleted, edit the display text
      // when special symbol is deleted, reset the selected item we set

      if (highlightedOption) {
        isSuggestionRef.current = true;
      }
      if (inputValue.endsWith(":")) {
        let updatedDisplayText = displayText.split(":").slice(0, -2).join(": ");
        if (updatedDisplayText) {
          updatedDisplayText += ":";
        }
        setDisplayText(updatedDisplayText);
        countRef.current--;
        setAddItem(false);
        updateStartHelperText();
      }
    }
  };

  // show the symbol suggestions only while shift is pressed down
  // const handleKeyUp = (event) => {
  //   if (event.key === "Shift" && inputValue === "") {
  //     setSuggestions([]);
  //   }
  // };

  // filter function to pass to autoComplete, and also keep track of filtered list
  const filterOptions = (options, { inputValue }) => {
    inputValue = inputValue.trim();
    let updatedOptions = options;
    const filteredTemplates = templates.filter((option) =>
      option?.displayName.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (inputValue.length >= 3) {
      for (const temp of filteredTemplates) {
        updatedOptions.push(temp);
      }
    }
    updatedOptions = updatedOptions.map((option) => {
      return {
        ...option,
        disabled:
          selectedValues.some(
            (value) => value.id === option.id && value.category === option.category
          ) ||
          currentPrescription.diagnosisArr.some(
            (diagnosis) =>
              diagnosis.diagnosisId === option.id && diagnosis.category === option.category
          ) ||
          currentPrescription.medicationsArr.some(
            (medication) =>
              medication.medicationId === option.id && medication.category === option.category
          ) ||
          currentPrescription.symptomsArr.some(
            (symptom) => symptom.symptomId === option.id && symptom.category === option.category
          ) ||
          currentPrescription.labInvestigationsArr.some(
            (lab) => lab.labTestId === option.id && lab.category === option.category
          ),
      };
    });

    return updatedOptions;
  };

  /* todo need some ui refactoring for the autocomplete component */

  return (
    <>
      <Autocomplete
        id="tags-outlined"
        options={suggestions}
        autoHighlight
        onBlur={resetSearchState}
        freeSolo
        clearOnEscape
        multiple
        value={selectedValues}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: isTeleconsultation ? "white !important" : "#004c70 !important",
          },
          ".MuiAutocomplete-clearIndicator": {
            color: isTeleconsultation ? "white" : "",
          },
        }}
        filterOptions={filterOptions}
        onHighlightChange={handleHighlightChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              label={option?.displayName}
              {...getTagProps({ index })}
              className={`${getTagColorClass(option?.category)}`}
              style={{
                margin: "2px",
              }}
            />
          ))
        }
        inputValue={inputValue}
        renderOption={(props, option, index) =>
          countRef.current === 0 &&
          suggestions.length > 0 && (
            <div
              key={index}
              {...props}
              style={{
                pointerEvents: option.disabled ? "none" : option.readOnly ? "none" : "auto",
                opacity: option.disabled ? 0.5 : option.readOnly ? 0.75 : 1,
              }}
            >
              <DisplayName option={option}></DisplayName>
            </div>
          )
        }
        getOptionLabel={(option) => `${option?.displayName} ${option?.id}`}
        onChange={(event, newValue, reason) => {
          if (reason === "selectOption") {
            handleSelectItem(event);
          } else {
            handleItemsChange(event, newValue);
          }
        }}
        onInputChange={handleCustomSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            className={
              isTeleconsultation ? "teleconsultation-input-customTextField" : "customTextField"
            }
            onKeyDown={handleKeyPress}
            InputLabelProps={{
              style: { color: "#004c70" },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                paddingRight: "9px",
                backgroundColor: isTeleconsultation ? "rgba(0,0,0,0.6)" : "",
              },
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  <div style={{ width: "75px", textAlign: "center" }}>
                    <span
                      style={{
                        color: isTeleconsultation ? "white" : "#757575",
                        fontStyle: "italic",
                        whiteSpace: "nowrap",
                        fontSize: "13px",
                      }}
                    >
                      {startHelperText}
                    </span>
                  </div>
                </>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <div>{params.InputProps.endAdornment}</div>
                  <div className="prescription-mic" onClick={toggleRecording}>
                    <CustomButton
                      iconButton={
                        <i
                          className={!isRecording ? "ri-mic-line" : "ri-stop-fill"}
                          style={
                            !isRecording
                              ? isTeleconsultation
                                ? { color: "white" }
                                : {}
                              : { color: "red" }
                          }
                        ></i>
                      }
                      style={{ padding: "0" }}
                    ></CustomButton>
                  </div>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

const DisplayName = ({ option }) => {
  const getCategoryName = (category) => {
    if (category === "RX") {
      return "Medicines";
    } else if (category === "CC") {
      return "Symptoms";
    } else if (category === "DX") {
      return "Diagnosis";
    } else if (category === "LAB") {
      return "Lab Investigations";
    } else if (category === "ADV") {
      return "Advices";
    } else if (category === "TEMP") {
      return "Templates";
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ fontSize: "16px" }}>
          {`${option?.displayName}`}
          {option.manufacturer && (
            <em style={{ paddingLeft: "15px", fontSize: "14px" }}>{`(${option?.manufacturer})`}</em>
          )}
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "12px",
            color: "blue",
          }}
        >
          in {getCategoryName(option.category)}
        </div>
      </div>
      {option.activeIngredient && (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
            fontWeight: "500",
            fontSize: "12px",
            color: "grey",
          }}
        >
          {option.activeIngredient}
        </div>
      )}
    </div>
  );
};

export default PrescriptionSearch;
