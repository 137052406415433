import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card,
  Skeleton,
} from "@mui/material";
import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import { useNavigate } from "react-router";
import * as React from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  DAY_GRID_CALENDAR,
  MONTH_GRID_CALENDAR,
  SCHEDULED,
  WEEK_GRID_CALENDAR,
  currentActiveUser,
  blockCalendeOptions,
  RESCHEDULED,
  TELE_CONSULTATION,
  CLINIC_VISIT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  DATE_FORMAT,
  TIME_FORMAT,
  CHECKED_IN,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomFullCalendar from "ui-component/custom-components/CustomFullCalendar";
import TimerSharpIcon from "@mui/icons-material/TimerSharp";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "assets/scss/doctorAppointments.scss";
import ModalUI from "ui-component/ModalUI";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  AddOutlined,
  Autorenew,
  CancelOutlined,
  Close,
  EventOutlined,
  TrendingUp,
} from "@mui/icons-material";
import dayjs from "dayjs";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import { getDoctorAvailability, getDoctorByUserId } from "services/doctorService";
import {
  updateAppointmentStatus,
  getAppointmentById,
  getFilteredAppointments,
  getAppointmentCountBetweenDates,
} from "services/Appointments";
import { createBlockAvailabilities, getBlockAvailabilities } from "services/Availability";
import { useContext } from "react";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Actions/appointmentDataAction";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PatientCard from "./PatientDTO/PatientCard";
import PatientHealthDetails from "./PatientDTO/PatientHealthDetails";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCallback } from "react";
import { Menu, MenuList } from "@mui/material";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import Reveal from "views/utilities/Reveal";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Reducers/userReducer";
import { fetchPatientLatestVitalSigns } from "utils/patient-latest-vital";
import {
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Actions/PrescriptionPadDataActions";

const DoctorAppointments = ({ ...others }) => {
  const handleFilteredAppointmentsChange = async (appointments) => {
    try {
      const sortedAppointments = [...appointments].sort(
        (a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
      );

      appointments.forEach((appointment) => {
        if (appointment.appointmentId === sortedAppointments[0].appointmentId) {
          appointment.eventSelected = true;
        } else {
          appointment.eventSelected = false;
        }
      });

      if (sortedAppointments.length > 0) {
        const appointmentDetails = await getAppointmentById(sortedAppointments[0].appointmentId);
        setSelectedAppointment(appointmentDetails.data);
        await fetchPatientLatestVitalSigns(
          appointmentDetails.data.patientId,
          setPatientLatestVitals,
          handleClick
        );
      } else {
        setSelectedAppointment(undefined);
      }
    } catch (error) {
      handleClick("error", "Error fetching appointment");
    }
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [doctorData, setDoctorData] = useState({});
  // eslint-disable-next-line
  const [doctorAvailability, setDoctorAvailability] = useState([]);
  const [doctorAppointments, setDoctorAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const user = currentActiveUser();
  const docId = currentActiveUser()?.roleBasedId;
  const [, setAppointment] = useState([]);
  const [patientVitals, setPatientVitals] = useState([]);
  const [patientLatestVitals, setPatientLatestVitals] = useState([]);

  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const [filteredDoctorEvents, setFilteredDoctorEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const [calendarView, setCalendarView] = useState(DAY_GRID_CALENDAR);
  const [viewInfo, setViewInfo] = useState(null);

  const [appointmentList, setAppointmentList] = useState([]);
  const [showAppointmentList, setShowAppointmentList] = useState(false);

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);

  const doctorOrgId = useSelector(getUserOrgId);

  const [appointmentModalTitle, setAppointmentModalTitle] = useState(null);
  const [isReschedule, setIsRechedule] = useState(false);

  const handleNewModalOpenForBookingAppointment = () => {
    const appointment = {
      doctorId: docId,
      orgId: doctorOrgId,
      appointmentDate: dayjs().format(DATE_FORMAT),
    };
    dispatch(setSelectedAppointmentData(appointment));
    setAppointmentModalTitle("Book Appointment");
    setIsNewModalOpen(true);
  };

  const handleNewModalOpenForReschedulingAppointment = () => {
    const appointment = {
      id: selectedAppointment?.id,
      patientMobile: selectedAppointment?.patient?.user?.mobileNumber,
      patientName: selectedAppointment?.patient?.user?.name,
      orgId: selectedAppointment?.orgId,
      appointmentTime: selectedAppointment?.appointmentTime.slice(0, -3),
      patientId: selectedAppointment?.patientId,
      doctorId: selectedAppointment?.doctorId,
      appointmentDate: selectedAppointment?.appointmentDate,
      notes: selectedAppointment?.notes,
      type: selectedAppointment?.type,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setIsRechedule(true);
    setAppointmentModalTitle("Reschedule Appointment");
    setIsNewModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsNewModalOpen(false);
    getAppointmentEvents(doctorData);
  };

  const updateFilteredAppointment = (allEvents) => {
    let filteredAppointments = [];
    if (viewInfo) {
      const selectedStartDate = dayjs(viewInfo.start).format(DATE_FORMAT);
      const selectedEndDate = dayjs(viewInfo.end).format(DATE_FORMAT);

      if (calendarView === DAY_GRID_CALENDAR) {
        filteredAppointments = allEvents.filter(
          (event) => dayjs(event.start).format(DATE_FORMAT) === selectedStartDate
        );
      } else if (calendarView === WEEK_GRID_CALENDAR) {
        filteredAppointments = allEvents.filter(
          (event) =>
            dayjs(event.start).format(DATE_FORMAT) > selectedStartDate &&
            dayjs(event.end).format(DATE_FORMAT) < selectedEndDate
        );
      } else if (calendarView === MONTH_GRID_CALENDAR) {
        const monthStartDate = dayjs(viewInfo.view.currentStart).format(DATE_FORMAT);
        const monthEndDate = dayjs(viewInfo.view.currentEnd).format(DATE_FORMAT);

        filteredAppointments = allEvents.filter(
          (event) =>
            dayjs(event.start).format(DATE_FORMAT) >= monthStartDate &&
            dayjs(event.end).format(DATE_FORMAT) < monthEndDate
        );
      }
    } else {
      const today = dayjs();
      const formattedToday = today.format(DATE_FORMAT);
      filteredAppointments = allEvents.filter(
        (event) => dayjs(event.start).format(DATE_FORMAT) === formattedToday
      );
    }
    handleFilteredAppointmentsChange(filteredAppointments);
    setFilteredDoctorEvents(filteredAppointments);
  };

  const handleCloseBlockModal = () => {
    setIsBlockModalOpen(false);
    getBlockCalendar();
    getAppointmentEvents(doctorData);
  };

  const [doctorBlockCalendar, setDoctorBlockCalendar] = useState([]);
  const [doctorBlockCalendarEvents, setDoctorBlockCalendarEvents] = useState([]);

  const formatDateTime = (date, time) => {
    return `${date}T${time}`;
  };

  const blockEventsByTime = (blockedEvents, startTimeTobeChecked, endTimeTobeChecked) => {
    while (startTimeTobeChecked <= endTimeTobeChecked) {
      const endDateTime30Min = dayjs(startTimeTobeChecked).add(30, "minute");
      blockedEvents.push({
        start: dayjs(startTimeTobeChecked).toDate(),
        end: dayjs(endDateTime30Min).toDate(),
        appointmentType: "Blocked",
        display: "background",
      });
      startTimeTobeChecked = `${endDateTime30Min.format(DATE_FORMAT)}T${endDateTime30Min.format(
        TIME_FORMAT
      )}`;
    }
    return blockedEvents;
  };

  const createBlockCalendarEvents = (blockedDetails) => {
    let blockedEvents = [];
    blockedDetails.forEach((blocked) => {
      let startDateTime = formatDateTime(blocked.startDate, blocked.startTime);
      const endDateTime = formatDateTime(blocked.endDate, blocked.endTime);
      if (blocked.blockingType === "CUSTOM_DATE_TIME_RANGE") {
        let startDay = dayjs(blocked.startDate).date();
        let endDay = dayjs(blocked.endDate).date();
        let dateToBeBlocked = blocked.startDate; // by default start date
        for (let index = startDay; index <= endDay; index++) {
          let startTimeForADay = formatDateTime(dateToBeBlocked, blocked.startTime);
          let endDateTimeForADay = formatDateTime(dateToBeBlocked, blocked.endTime);
          blockedEvents = blockEventsByTime(blockedEvents, startTimeForADay, endDateTimeForADay);
          dateToBeBlocked = dayjs(dateToBeBlocked).add(1, "d").format(DATE_FORMAT);
        }
      } else {
        blockedEvents = blockEventsByTime(blockedEvents, startDateTime, endDateTime);
      }
    });
    return blockedEvents;
  };

  const getBlockCalendar = async () => {
    try {
      const response = await getBlockAvailabilities(user?.roleBasedId, doctorOrgId);
      const doctorBlockedDetails = response.data.filter(
        (entry) =>
          entry.blockingType !== "SINGLE_DATE" || entry.blockingType !== "CUSTOM_DATE_TIME_RANGE"
      );
      setDoctorBlockCalendar(doctorBlockedDetails);
      const events = createBlockCalendarEvents(doctorBlockedDetails);
      setDoctorBlockCalendarEvents(events);
    } catch (error) {
      if (error.response?.status === 404) {
      } else {
        handleClick("error", "Error fetching block calendar details");
      }
    }
  };

  useEffect(() => {
    getBlockCalendar();
  }, []);

  const getAppointmentEvents = async (doctorDetail) => {
    const doctorId = doctorDetail?.id;
    try {
      // fetch doctor's appointment
      const appointmentResponse = await getFilteredAppointments({ doctorId: doctorId });
      let apIdArr = [];
      const appointmentData = appointmentResponse?.data.content.map((appointment) => {
        if (
          (appointment.appointmentStatus === SCHEDULED ||
            appointment.appointmentStatus === RESCHEDULED ||
            appointment.appointmentStatus === CHECKED_IN) &&
          appointment.orgId === doctorOrgId
        ) {
          // starting of todays date
          const currDate = dayjs().startOf("day");
          const isDayPassed = dayjs(appointment.appointmentDate).valueOf() < currDate.valueOf();
          // appointment status should be changed to NO_SHOW
          if (isDayPassed) {
            apIdArr.push(appointment.id);
          }
        }
        const obj = {
          appointmentTime: appointment.appointmentTime,
          appointmentDate: appointment.appointmentDate,
        };
        return obj;
      });
      if (apIdArr.length > 0) {
        apIdArr.forEach(async (id) => {
          try {
            await updateAppointmentStatus(id, "NO_SHOW");
          } catch (error) {
            handleClick("error", "Error updating appointment");
          }
        });
      }
      setAppointment(appointmentData);
      setDoctorAppointments(
        appointmentResponse.data.content.filter(
          (app) =>
            (app.appointmentStatus === SCHEDULED ||
              app.appointmentStatus === RESCHEDULED ||
              app.appointmentStatus === CHECKED_IN) &&
            app.orgId === doctorOrgId
        )
      );
      const allEvents = appointmentResponse.data.content
        .filter(
          (app) =>
            (app.appointmentStatus === SCHEDULED ||
              app.appointmentStatus === RESCHEDULED ||
              app.appointmentStatus === CHECKED_IN) &&
            app.orgId === doctorOrgId
        )
        .map((appointment, index) => {
          const startTime = dayjs(`${appointment.appointmentDate}T${appointment.appointmentTime}`);
          const endTime = startTime.add(
            doctorDetail?.avgAppointmentDuration <= 15 ? 30 : 60,
            "minute"
          );
          return {
            start: startTime.toDate(),
            end: endTime.toDate(),
            title: appointment.patientName,
            appointmentType: appointment?.type,
            appointmentId: appointment.id,
            eventSelected: index === 0,
            status: appointment?.appointmentStatus,
          };
        });

      setAllEvents(allEvents);
      updateFilteredAppointment(allEvents);
    } catch (error) {
      handleClick("error", "Error fetching details");
    }
  };
  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        // const allOrganization = await api.get(`/api/organizations`);
        // setAllOrganizations(allOrganization);

        const docResponse = await getDoctorByUserId(user.id);
        if (docResponse?.data) {
          const docId = docResponse?.data.id;
          setDoctorData(docResponse.data);

          const response = await getDoctorAvailability(docId, doctorOrgId);
          setDoctorAvailability(response);

          getAppointmentEvents(docResponse.data);

          intervalId = setInterval(() => {
            getAppointmentEvents(docResponse.data);
          }, 300000);
        }
      } catch (error) {
        handleClick("error", "Error fetching details");
      }
    };
    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const vitalDetails = patientLatestVitals.map((vital) => {
      return {
        ...vital,
        value: `${vital.value}${vital.vitalSignTypeResponse.unitAbbreviation ?? ""}`,
        displayName: `${vital.vitalSignTypeResponse.displayName}`,
        deviation: (
          <Grid display={"flex"}>
            <TrendingUp
              style={{
                color: "#388E3C",
                height: "20px",
                width: "20px",
              }}
            ></TrendingUp>
            <span className="change-field-value-up">+15%</span>
          </Grid>
        ),
      };
    });
    setPatientVitals(vitalDetails);
  }, [patientLatestVitals]);

  const eventClick = async (eventInfo) => {
    if (eventInfo.event.extendedProps.appointmentType === "Blocked") {
      return;
    }
    try {
      const appointmentId = doctorAppointments.find(
        (appointment) => appointment.id === eventInfo.event.extendedProps.appointmentId
      )?.id;

      const appointmentResponse = await getAppointmentById(appointmentId);
      setSelectedAppointment(appointmentResponse.data);
      await fetchPatientLatestVitalSigns(
        appointmentResponse.data.patientId,
        setPatientLatestVitals,
        handleClick
      );

      const updatedEvents = filteredDoctorEvents.map((item) => {
        if (item.appointmentId === eventInfo.event.extendedProps.appointmentId) {
          return { ...item, eventSelected: true };
        } else {
          return { ...item, eventSelected: false };
        }
      });

      setFilteredDoctorEvents(updatedEvents);
    } catch (error) {
      handleClick("error", "Error fetching appointment detail");
    }
  };

  const handleCancelAppointment = () => {
    handleDialogOpen();
  };

  // dialog box
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogYes = async () => {
    setOpen(false);
    // delete appointment, and fetch current appointments as events
    // todo : currently all appointments are shown, need to filter based on active/inactive once dto changed
    try {
      await updateAppointmentStatus(selectedAppointment.id, "CANCELLED");
    } catch (error) {
      handleClick("error", "Error deleting the appointment");
    }
    setSelectedAppointment(undefined);
    getAppointmentEvents(doctorData);
  };

  const handleDialogNo = () => {
    setOpen(false);
  };

  const handleMonthViewMore = async (eventInfo) => {
    const selectedDate = eventInfo.event.start.toISOString().split("T")[0];
    const appointmentOnSelectedDate = doctorAppointments.filter((appointment) => {
      return (
        appointment.appointmentDate === selectedDate &&
        appointment.type === eventInfo.event.extendedProps.appointmentType
      );
    });
    const appointmentDetails = await Promise.all(
      appointmentOnSelectedDate.map(async (appointment) => {
        try {
          const response = await getAppointmentById(appointment.id);
          return response.data;
        } catch (error) {
          handleClick("error", "error fetching patient appointments");
        }
      })
    );
    setAppointmentList(appointmentDetails);
    setShowAppointmentList(true);
    setSelectedAppointment("");
  };

  const handleViewInfoClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowAppointmentList(false);
  };

  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);

  const handleJoinRoom = useCallback(() => {
    try {
      if (selectedAppointment) {
        const room = selectedAppointment.id?.toString();
        navigate(`/home/prescriptionPad/${room}`, {
          state: {
            roomId: room,
          },
        });
      } else {
        handleClick("error", "No selected appointment to join");
      }
    } catch (e) {
      handleClick("error", "Not able to join the call");
    }
  }, [selectedAppointment, navigate, handleClick]);

  const [anchorEl, setAnchorEl] = useState(null);
  const actions = [
    {
      label: "Cancel",
      icon: <CancelOutlined style={{ color: "#f5574c" }} />,
      onClick: handleCancelAppointment,
    },
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <DoctorAppointmentSkeleton>
      <div className="doctor-appointments">
        <Dialog
          open={open}
          onClose={handleDialogNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" fontSize={16}>
            Cancel Patient appointment?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Appointment will be cancelled and patient will be notified.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
              No
            </Button>
            <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Reveal
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <h6 className="page-title">My Appointments</h6>
          </div>
          <div className="col">
            <ul className="horizontal-ul" style={{ marginTop: 0 }}>
              <li className="main-actions">
                <CustomButton
                  className={"mui-btn--primary"}
                  height="36px"
                  startIcon={<AddOutlined />}
                  label={"New Appointment"}
                  onClick={handleNewModalOpenForBookingAppointment}
                ></CustomButton>
              </li>
              <li className="main-actions">
                <CustomButton
                  height="36px"
                  className={"mui-btn--secondary"}
                  startIcon={<TimerSharpIcon></TimerSharpIcon>}
                  label={"Manage Availability"}
                  onClick={() => {
                    navigate("/home/doctorAvailability");
                  }}
                ></CustomButton>
              </li>
              <li className="main-actions">
                <CustomButton
                  iconButton={<EventOutlinedIcon style={{ color: "#004C70" }}></EventOutlinedIcon>}
                  onClick={() => {
                    // setisModalOpen(false);
                    setIsBlockModalOpen(true);
                  }}
                  style={{ padding: "2px 5px", marginRight: "5px" }}
                  customBackGroundColor="#29BF911A"
                ></CustomButton>
              </li>
              <li className="main-actions">
                <CustomButton
                  iconButton={<Autorenew style={{ color: "#004C70" }}></Autorenew>}
                  onClick={() => {
                    getAppointmentEvents(doctorData);
                  }}
                  style={{ padding: "2px 5px", marginRight: "5px" }}
                  customBackGroundColor="#29BF911A"
                ></CustomButton>
              </li>
            </ul>
          </div>
        </Reveal>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Reveal>
              <Box className="left-box">
                <Typography className="box-heading">Today's Appointments</Typography>
                <CustomFullCalendar
                  setCalendarView={setCalendarView}
                  viewInfo={viewInfo}
                  setViewInfo={setViewInfo}
                  calendarView={calendarView}
                  setFilteredDoctorEvents={setFilteredDoctorEvents}
                  allEvents={allEvents}
                  filteredDoctorEvents={filteredDoctorEvents}
                  eventClick={eventClick}
                  blockedDays={doctorBlockCalendar}
                  blockedEvents={doctorBlockCalendarEvents}
                  slotDuration={doctorData?.avgAppointmentDuration <= 15 ? 30 : 60}
                  onFilteredAppointmentsChange={handleFilteredAppointmentsChange}
                  handleMonthViewMore={handleMonthViewMore}
                  setShowAppointmentList={setShowAppointmentList}
                ></CustomFullCalendar>
              </Box>
            </Reveal>
          </Grid>
          <Grid item md={4} xs={12}>
            {/* todo : some details are static, integrate api call and fetch required data */}
            <Reveal>
              <Card className="custom-card-ui" style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row justify-content-space-between align-items-center">
                  {selectedAppointment &&
                    calendarView === MONTH_GRID_CALENDAR &&
                    appointmentList.length > 0 && (
                      <Reveal
                        className="btn back-btn"
                        onClick={() => {
                          setShowAppointmentList(true);
                          setSelectedAppointment("");
                        }}
                      >
                        <img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>
                      </Reveal>
                    )}

                  <div className="col card-title">
                    {!selectedAppointment ? "Patients List" : "Patient's Details"}
                  </div>

                  {!selectedAppointment && showAppointmentList && (
                    <div className="col">
                      <i
                        className={`ri-${
                          appointmentList[0]?.type === CLINIC_VISIT
                            ? "walk-fill"
                            : "video-chat-line"
                        }`}
                        style={{
                          width: "30px",
                          height: "30px",
                          fontSize: "20px",
                          textAlign: "center",
                          paddingTop: "5px",
                          color: appointmentList[0]?.type === CLINIC_VISIT ? "#29bf91" : "#004c70",
                        }}
                      />
                    </div>
                  )}
                  <div className="col filters">
                    {selectedAppointment && (
                      <>
                        <CustomButton
                          iconButton={<i className="ri-more-2-fill" />}
                          onClick={(event) => handleMenuOpen(event)}
                        ></CustomButton>
                        <Menu
                          elevation={1}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          PaperProps={{ style: { borderRadius: "4px" } }}
                        >
                          <MenuList dense sx={{ p: 0 }}>
                            {actions.map((action, actionIndex) => {
                              return (
                                <CustomMenuItem
                                  key={actionIndex}
                                  text={action.label}
                                  icon={action.icon}
                                  onClick={() => {
                                    handleMenuClose();
                                    action.onClick();
                                  }}
                                />
                              );
                            })}
                          </MenuList>
                        </Menu>
                      </>
                    )}

                    {!selectedAppointment && showAppointmentList && (
                      <div className="heading-date">{appointmentList[0]?.appointmentDate}</div>
                    )}
                  </div>
                </div>

                {selectedAppointment && (
                  <Reveal>
                    {/* Patients basic details */}
                    <PatientCard
                      selectedAppointment={selectedAppointment}
                      handleClick={handleClick}
                    ></PatientCard>
                    <Grid container className="row mb-0 mx-0 column-patient-details">
                      <Grid item md={12} className="px-0">
                        <div className="row w-100 mb-0 align-items-left flex-direction-column">
                          {selectedAppointment && (
                            <>
                              <div
                                style={{
                                  flexWrap: "wrap",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "5px",
                                }}
                              >
                                <CustomButton
                                  className={"btn--primary-light"}
                                  height="32px"
                                  label={"Reschedule"}
                                  startIcon={<EventOutlined />}
                                  onClick={handleNewModalOpenForReschedulingAppointment}
                                />
                                <CustomButton
                                  className={"mui-btn--primary"}
                                  height="32px"
                                  label={"Consult Now"}
                                  endIcon={
                                    <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                                  }
                                  onClick={() => {
                                    dispatch(
                                      setPrescriptionPadData({
                                        diagnosisArr: [],
                                        symptomsArr: [],
                                        medicationsArr: [],
                                        labInvestigationsArr: [],
                                        advicesArr: [],
                                      })
                                    );
                                    dispatch(setPrescriptionId(null));
                                    if (selectedAppointment.type === TELE_CONSULTATION) {
                                      handleJoinRoom();
                                    } else {
                                      navigate(`/home/prescriptionPad/${selectedAppointment?.id}`);
                                    }
                                  }}
                                />
                                <CustomButton
                                  className={"btn--primary-light"}
                                  height="32px"
                                  label={"View EHR"}
                                  startIcon={<EventOutlined />}
                                  onClick={() => {
                                    navigate(`/home/ehr/${selectedAppointment?.patientId}`, {
                                      state: {
                                        viewOnlyPage: true,
                                        patientId: selectedAppointment?.patientId,
                                        appointmentId: selectedAppointment.id,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <PatientHealthDetails
                      patientVitals={patientVitals}
                      patientId={selectedAppointment?.patientId}
                      handleClick={handleClick}
                      patientAbhaId={selectedAppointment?.patient?.abhaId}
                      setIframeOpen={setIframeOpen}
                      setIframeSrc={setIframeSrc}
                    ></PatientHealthDetails>
                  </Reveal>
                )}
                {!selectedAppointment && !showAppointmentList && (
                  <Grid>No Appointment selected...</Grid>
                )}
                {!selectedAppointment &&
                  showAppointmentList &&
                  appointmentList.map((appoinment, index) => (
                    <Reveal>
                      <Card key={index} className="appointment-card">
                        <PatientCard
                          selectedAppointment={appoinment}
                          handleClick={handleClick}
                        ></PatientCard>
                        <Grid container className="row mb-0 mx-0 column-patient-details">
                          <Grid item md={12} className="px-0">
                            <div className="row w-100 mb-0 align-items-left flex-direction-column">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "5px",
                                }}
                              >
                                <CustomButton
                                  className={"btn--secondary"}
                                  style={{ marginRight: "12px", height: "50px" }}
                                  label={"More Details"}
                                  startIcon={<InfoOutlinedIcon />}
                                  onClick={() => {
                                    handleViewInfoClick(appoinment);
                                  }}
                                ></CustomButton>
                                <CustomButton
                                  className={"mui-btn--primary"}
                                  style={{ height: "50px" }}
                                  label={"Consult Now"}
                                  endIcon={
                                    <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                                  }
                                  onClick={() => {
                                    dispatch(
                                      setPrescriptionPadData({
                                        diagnosisArr: [],
                                        symptomsArr: [],
                                        medicationsArr: [],
                                        labInvestigationsArr: [],
                                        advicesArr: [],
                                      })
                                    );
                                    dispatch(setPrescriptionId(null));
                                    navigate(`/home/prescriptionPad/${appoinment?.id}`);
                                  }}
                                ></CustomButton>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </Reveal>
                  ))}
              </Card>
            </Reveal>
          </Grid>
        </Grid>
        <ModalUI
          visible={isNewModalOpen}
          close={() => {
            dispatch(setSelectedAppointmentData({}));
            setIsNewModalOpen(false);
          }}
          title={appointmentModalTitle}
          style={{
            overflowY: "auto",
            height: "550px",
            width: "610px",
          }}
          component={
            <BookAppointmentModal
              reschedule={isReschedule}
              handleClick={handleClick}
              closeModal={handleNewModalClose}
            />
          }
        />
        <ModalUI
          visible={isBlockModalOpen}
          close={() => {
            setIsBlockModalOpen(false);
          }}
          title={"Block Calendar By Date"}
          style={{
            overflowY: "none",
            height: "fit-content",
            width: "610px",
          }}
          component={
            <BlockCalendarModal
              doctorId={docId}
              organizationId={doctorOrgId}
              closeModal={handleCloseBlockModal}
              handleClick={handleClick}
            ></BlockCalendarModal>
          }
        />

        {iframeOpen && (
          <div className="iframe-div">
            <iframe
              title="consent form"
              src={iframeSrc}
              target="_blank"
              className="iframe"
            ></iframe>

            <CustomButton
              iconButton={<Close />}
              onClick={() => {
                setIframeOpen(false);
                setIframeSrc(null);
              }}
              className="iframe-close-button"
            />
          </div>
        )}
      </div>
    </DoctorAppointmentSkeleton>
  );
};

export default DoctorAppointments;

const BlockCalendarModal = ({ doctorId, organizationId, closeModal, handleClick }) => {
  const [blockFromDate, setBlockFromDate] = useState(null);
  const [blockToDate, setBlockToDate] = useState(null);
  const [blockFromTime, setBlockFromTime] = useState(null);
  const [blockToTime, setBlockToTime] = useState(null);
  const [blockingType, setBlockingType] = useState("SINGLE_DATE");
  const [blockConfirmation, setBlockConfirmation] = useState(false);
  const [effectedAppointments, setEffectedAppointments] = useState(0);

  const [selectedAppointmentOption, setselectedAppointmentOption] = useState("");

  const [errors, setErrors] = useState({});

  const currentDate = dayjs().startOf("day");

  const validate = () => {
    const errors = {};

    if (blockFromDate === null) {
      errors.fromDate = "Required.";
    } else if (!(blockFromDate?.isValid ? blockFromDate.isValid() : true)) {
      errors.fromDate = "Invalid 'Date' value.";
    } else if (blockFromDate < currentDate) {
      errors.fromDate = "Date cannot be in past.";
    }

    if (blockToDate === null) {
      errors.toDate = "Required.";
    } else if (!(blockToDate?.isValid ? blockToDate.isValid() : true)) {
      errors.toDate = "Invalid 'Date' value.";
    } else if (!blockToDate.isAfter(blockFromDate)) {
      errors.toDate = "Date should be after the from date.";
    }

    if (!selectedAppointmentOption) {
      errors.appointmentOption = "Required";
    }

    if (!blockFromTime) {
      errors.fromTime = "Required";
    }

    if (!blockToTime) {
      errors.toTime = "Required";
    }

    return errors;
  };

  const handleFetchSaveData = async () => {
    try {
      const fromDate = dayjs(blockFromDate);
      const toDate = dayjs(blockToDate);
      const startDate = fromDate.format(DATE_FORMAT);
      const endDate = toDate.format(DATE_FORMAT);
      const payload = {
        startDate: startDate,
        endDate: blockToDate ? endDate : null,
        startTime: blockFromTime,
        endTime: blockToTime,
        blockingType: blockingType,
        doctorId: doctorId,
        organizationId: [organizationId],
        serviceType: selectedAppointmentOption,
      };
      if (blockConfirmation) {
        await createBlockAvailabilities(payload);
        handleClick("success", "Availability blocked successfully");
        setBlockConfirmation(false);
        closeModal();
      } else {
        // todo : call api to fetch count of appointments that will be effected
        await fetchAppointmentCount();
        setBlockConfirmation(true);
      }
    } catch (error) {
      handleClick("error", "Error saving block calendar");
    }
  };

  const fetchAppointmentCount = async () => {
    try {
      const toDate = blockToDate !== null ? dayjs(blockToDate).format("YYYY-MM-DD") : null;
      const payload = {
        fromDate: dayjs(blockFromDate).format("YYYY-MM-DD"),
        toDate: dayjs(blockToDate).format("YYYY-MM-DD") ? toDate : null,
        fromTime: blockFromTime,
        toTime: blockToTime,
        doctorId: doctorId,
        orgId: organizationId,
      };
      const response = await getAppointmentCountBetweenDates(payload);
      setEffectedAppointments(response.data.totalAppointments);
    } catch (error) {
      handleClick("error", "Error fetching appointment count");
    }
  };

  const handleBlockCalendar = () => {
    const validationErrors = validate();
    if (
      (blockingType === "SINGLE_DATE" && Object.keys(validationErrors).length > 3) ||
      (blockingType === "CUSTOM_DATE_TIME_RANGE" && Object.keys(validationErrors).length > 0)
    ) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      handleFetchSaveData();
    }
  };

  return (
    <div>
      <Grid container spacing={3} mt={0.7}>
        <Grid item style={{ paddingTop: 0, marginTop: "20px" }}>
          <Typography sx={{ mt: 0, mb: 0, fontWeight: 500, color: "#000000" }}>
            Blocking Type
          </Typography>
          <Grid item style={{ display: "flex", paddingTop: 0, mb: 0 }}>
            <RadioGroup
              value={blockingType}
              onChange={(e) => {
                setBlockingType(e.target.value);
                setBlockConfirmation(false);
                if (e.target.value === "CUSTOM_DATE_TIME_RANGE") {
                  setBlockToDate(null);
                  setBlockFromTime(null);
                  setBlockToTime(null);
                  const newErrors = { ...errors };
                  delete newErrors.toDate;
                  delete newErrors.fromTime;
                  delete newErrors.toTime;
                  setErrors(newErrors);
                }
              }}
              style={{ height: "50px" }}
            >
              <Grid item xs={12}>
                <FormControlLabel value="SINGLE_DATE" control={<Radio />} label="Single Date" />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value="CUSTOM_DATE_TIME_RANGE"
                  control={<Radio />}
                  label="Custom Range"
                />
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={0.7}>
        <Grid item xs={6} style={{ paddingTop: 0 }}>
          <FormDatePicker
            inputFormat="DD/MM/YYYY"
            label={blockingType === "SINGLE_DATE" ? "On Date" : "From Date"}
            value={blockFromDate}
            size={"big"}
            required
            minDate={dayjs().startOf("day")}
            onChange={(e) => {
              setBlockFromDate(e);
              if (e === null) {
                setErrors({ ...errors, fromDate: "Required." });
              } else if (!e.isValid()) {
                setErrors({ ...errors, fromDate: "Invalid 'Date' value." });
              } else if (e < currentDate) {
                setErrors({ ...errors, fromDate: "Date cannot be in past." });
              } else {
                const { fromDate, ...newError } = errors;
                setErrors(newError);
              }
              // once date changes then we need to refetch the total appointments on that data
              // so for that changing the blockConfirmation values to false again
              setBlockConfirmation(false);
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.fromDate)}
            errorText={errors.fromDate}
          ></FormDatePicker>
        </Grid>
        {blockingType !== "SINGLE_DATE" && (
          <Grid item xs={6} style={{ paddingTop: 0 }}>
            <FormDatePicker
              inputFormat="DD/MM/YYYY"
              label={"To Date"}
              required
              value={blockToDate}
              size={"big"}
              minDate={
                blockFromDate ? blockFromDate.add(1, "day").startOf("day") : dayjs().startOf("day")
              }
              onChange={(e) => {
                setBlockToDate(e);
                if (e === null) {
                  setErrors({ ...errors, toDate: "Required." });
                } else if (!e.isValid()) {
                  setErrors({ ...errors, toDate: "Invalid 'Date' value." });
                } else if (!e.isAfter(blockFromDate)) {
                  setErrors({
                    ...errors,
                    toDate: "Date should be after the from Date.",
                  });
                } else {
                  const { toDate, ...newError } = errors;
                  setErrors(newError);
                }
                // once date changes then we need to refetch the total appointments on that data
                // so for that changing the blockConfirmation values to false again
                setBlockConfirmation(false);
              }}
              style={{
                width: "100%",
                marginRight: "30px",
                marginTop: "7px",
              }}
              error={Boolean(errors.toDate)}
              errorText={errors.toDate}
            ></FormDatePicker>
          </Grid>
        )}
      </Grid>
      {blockingType !== "SINGLE_DATE" && (
        <Grid container spacing={3} mt={0.7}>
          <Grid item sx={{ width: "50%" }} style={{ paddingTop: 0, marginTop: "20px" }}>
            <FormInputField
              style={{ width: "100%" }}
              label="From Time"
              name="fromTime"
              type="time"
              shrink={true}
              value={blockFromTime}
              onChange={(e) => {
                setBlockFromTime(e.target.value);
                const newErrors = { ...errors };

                if (blockToTime && e.target.value && blockToTime <= e.target.value) {
                  newErrors.fromTime = "From Time should be before To Time.";
                } else {
                  delete newErrors.fromTime;
                  delete newErrors.toTime;
                }
                setErrors(newErrors);
              }}
              error={Boolean(errors.fromTime)}
              errorText={errors.fromTime}
              size={"big"}
            />
          </Grid>
          <Grid item sx={{ width: "50%" }} style={{ paddingTop: 0, marginTop: "20px" }}>
            <FormInputField
              style={{ width: "100%" }}
              label="To Time"
              shrink={true}
              name="toTime"
              type="time"
              value={blockToTime}
              onChange={(e) => {
                setBlockToTime(e.target.value);
                const newErrors = { ...errors };

                if (blockFromTime && e.target.value && e.target.value <= blockFromTime) {
                  newErrors.toTime = "To Time should be after From Time.";
                } else {
                  delete newErrors.toTime;
                  delete newErrors.fromTime;
                }
                setErrors(newErrors);
              }}
              error={Boolean(errors.toTime)}
              errorText={errors.toTime}
              size={"big"}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={6} mt={2}>
          <FormSelectField
            style={{ width: "100%", marginTop: "15px" }}
            label="Appointment Options"
            name="selectedAppointmentOption"
            onChange={(e) => {
              setselectedAppointmentOption(e.target.value);
              const newErrors = { ...errors };
              delete newErrors.appointmentOption;
              setErrors(newErrors);
            }}
            startAdornment={<FilterAltIcon />}
            menuItems={blockCalendeOptions}
            value={selectedAppointmentOption}
            size={"big"}
            error={Boolean(errors.appointmentOption)}
            errorText={errors.appointmentOption}
          ></FormSelectField>
        </Grid>
      </Grid>
      {blockConfirmation && effectedAppointments > 0 && (
        <Typography style={{ marginTop: "20px", fontWeight: "500", color: "#004c70" }}>
          {`You have ${effectedAppointments} appointments scheduled, which will be cancelled.`}{" "}
          <br></br> {`Do you want to proceed?`}
        </Typography>
      )}

      <div style={{ display: "flex" }}>
        <CustomButton
          className={"mui-btn--primary"}
          height="36px"
          label={"Block Calendar"}
          onClick={() => {
            fetchAppointmentCount();
            handleBlockCalendar();
          }}
          style={{ marginTop: "20px", marginRight: "20px" }}
        ></CustomButton>
        {blockConfirmation && (
          <CustomButton
            className={"btn--secondary-light"}
            height="36px"
            label={"Cancel"}
            onClick={closeModal}
            style={{ marginTop: "20px", marginRight: "20px" }}
          ></CustomButton>
        )}
      </div>
    </div>
  );
};

const DoctorAppointmentSkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>

          <Grid display={"flex"} item xs={6}>
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="10%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
            <Skeleton
              sx={{ borderRadius: "4px", mr: 2 }}
              animation="wave"
              variant="rectangular"
              width="40%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={8} pr={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
          <Grid item xs={4}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={700}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
