import { startsAndEndsWithAlphabetic, startsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

const containsAlphabetic = (value) => /[a-zA-Z]/.test(value);

export const RegisterOrgSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Minimum 3 characters required")
    .max(100, "Too long organization name")
    .test(
      "contains-alphabetic",
      "Hospital name must contain alphabetic characters",
      containsAlphabetic
    )
    .matches(/^[a-zA-Z0-9 .,'-]*$/, "Only letters, numbers, spaces, and .,'- are allowed"),

  code: Yup.string()
    .min(2, "Too short organization code")
    .max(30, "Too long organization code")
    .required("Code is required"),

  address: Yup.string()
    .required("Address is required")
    .min(3, "Minimum 3 characters allowed")
    .max(256, "Maximum 256 characters allowed"),

  // adDistrict: Yup.object().required("District is required"),

  // adState: Yup.object().required("State is required"),

  adPincode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be of 6 digits") // Validates that it is a 6-digit number
    .required("Pincode is required"),

  contactName: Yup.string()
    .required("Contact name is required")
    .min(3, "Minimum 3 characters required")
    .max(100, "Too long contact name")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    )
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space"),

  contactMobile: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Enter Valid Mobile Number")
    .required("Contact mobile is required"),

  contactEmail: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email ID")
    .required("Contact email is required")
    .max(255, "Maximum 255 characters allowed"),

  since_when: Yup.date().required("Required"),
});

export const s = Yup.object().shape({});
