import { Outlet, useNavigate } from "react-router-dom";

// project imports
// import Customization from "../Customization";
import { useEffect } from "react";
import { isDemo, loggedIn } from "store/constant";
import { getIsDemo } from "store/Reducers/DemoReducer";
import { useSelector } from "react-redux";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn() && !isDemo()) {
      navigate("/home/dashboard");
    }
  });

  return (
    <>
      <Outlet />
      {/* <Customization /> */}
    </>
  );
};

export default MinimalLayout;
