import React from "react";
import { Typography as MuiTypography } from "@mui/material";

const CustomTypography = ({ variant, children, ...props }) => {
  const fontFamily =
    variant === "header" ? '"SF Pro Text", sans-serif' : '"IBM Plex Sans", sans-serif';

  return (
    <MuiTypography variant={variant} sx={{ fontFamily }} {...props}>
      {children}
    </MuiTypography>
  );
};

export default CustomTypography;
