const SkeletonPelvis = () => {
  return (
    <div className="custom-skeleton-pelvis">
      <div className="group-1">
        {/* todo:image  */}
        <div className="pelvis-sacrum body-part-container">
          <img
            src="/images/human-skeleton/human-pelvis/pelvis-sacrum.svg"
            className="body-part hover"
            alt="pelvis-sacrum"
          ></img>
        </div>
      </div>
      <div className="group-2">
        <div className="pelvis-left-ischium-pubis body-part-container">
          <img
            src="/images/human-skeleton/human-pelvis/pelvis-left-ischium-pubis.svg"
            className="body-part hover"
            alt="pelvis-left-ischium-pubis"
          ></img>
        </div>
        <div className="pelvis-right-ischium-pubis body-part-container">
          <img
            src="/images/human-skeleton/human-pelvis/pelvis-right-ischium-pubis.svg"
            className="body-part hover"
            alt="pelvis-right-ischium-pubis"
          ></img>
        </div>
        <div className="pelvis-left-ilium body-part-container">
          <img
            src="/images/human-skeleton/human-pelvis/pelvis-left-ilium.svg"
            className="body-part hover"
            alt="pelvis-left-ilium"
          ></img>
        </div>
        <div className="pelvis-right-ilium body-part-container">
          <img
            src="/images/human-skeleton/human-pelvis/pelvis-right-ilium.svg"
            className="body-part hover"
            alt="pelvis-right-ilium"
          ></img>
        </div>
        <div className="pelvis-sacrum-container">
          <img
            src="/images/human-skeleton/human-pelvis/pelvis-sacrum-container.svg"
            alt="pelvis-sacrum-container"
          ></img>
        </div>
        <div className="pelvis-coccyx body-part-container">
          <img
            src="/images/human-skeleton/human-pelvis/pelvis-coccyx.svg"
            className="body-part hover"
            alt="pelvis-coccyx"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default SkeletonPelvis;
