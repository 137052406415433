import { useContext } from "react";
import { createContext } from "react";

export const SidebarUtilContext = createContext(null);

export const useMainClass = () => useContext(SidebarUtilContext);

export const SidebarUtilProvider = ({
  children,
  mainClass,
  toggleMainClass,
  setSecondarySideBarData,
  tabValue,
  setAppointmentInfo,
}) => {
  return (
    <SidebarUtilContext.Provider
      value={{ tabValue, mainClass, toggleMainClass, setSecondarySideBarData, setAppointmentInfo }}
    >
      {children}
    </SidebarUtilContext.Provider>
  );
};
