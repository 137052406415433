import { useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import CustomButton from "ui-component/custom-components/CustomButton";

import dayjs from "dayjs";
import {
  AlternateEmailOutlined,
  ArrowCircleRightOutlined,
  CreditCardOutlined,
  CurrencyRupeeOutlined,
  Discount,
  DoneAllOutlined,
  PaymentsOutlined,
} from "@mui/icons-material";
import "assets/scss/billingPage.scss";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import { useSelector } from "react-redux";
import {
  getIsPaymentMode,
  getMedicalServiceItems,
  getPatientInvoiceDetail,
  setInvoiceAmountDetail,
  setIsPaymentMode,
  setMedicalServiceItems,
  setPatientInvoiceAdjustments,
  setPatientInvoiceDetail,
  setPatientInvoiceItems,
  setPatientInvoiceTotal,
} from "store/Slices/billingInvoiceSlice";
import { useDispatch } from "react-redux";
import PrescriptionCard from "../Prescription Pad/prescriptionCard";
import PatientCard from "../PatientDTO/PatientCard";
import {
  ACTIVE_STATE,
  CLINIC_VISIT,
  convertTimeForTimeFormat,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
} from "store/constant";
import { convertIntoTwoDecimal } from "utils/price-utils";
import { useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import { getUserOrgId } from "store/Slices/userSlice";
import { getAmenitiesByOrgId } from "services/organizationService";
import { getPrescriptionByAppointmentId } from "services/PrescriptionsService";
import { convertPrescriptionDataToUIFormat } from "utils/prescriptionPadUtils/convert-to-UI-format";
import { getAppointmentById } from "services/Appointments";
import { updateBillingInvoices } from "services/BillingService";

const columns = [
  { field: "service.name", label: "Description", align: "left" },
  { field: "quantity", label: "Qty.", align: "center" },
  { field: "service.price", label: "Rate", align: "center" },
  { field: "discount", label: "Discount", align: "center" },
  { field: "tax", label: "Tax", align: "center" },
  { field: "amount", label: "Amount", align: "center" },
];

export const RenderMedicalServices = ({ sourceData, handleGenerateInvoice, isPaymentMode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const invoiceItems = useSelector(getMedicalServiceItems);
  const invoiceDetails = useSelector(getPatientInvoiceDetail);

  useEffect(() => {
    dispatch(setPatientInvoiceItems([...invoiceItems]));
  }, [invoiceItems]);

  const calculateTotalDetail = (invoiceItems) => {
    let totalDiscount = 0;
    let totalTax = 0;
    let totalAmount = 0;

    invoiceItems.forEach((item) => {
      totalDiscount += item.discount;
      totalTax += item.tax;
      totalAmount += item.amount;
    });
    dispatch(
      setInvoiceAmountDetail({
        discount: convertIntoTwoDecimal(totalDiscount),
        tax: convertIntoTwoDecimal(totalTax),
        amount: convertIntoTwoDecimal(totalAmount),
      })
    );

    dispatch(
      setPatientInvoiceTotal(
        convertIntoTwoDecimal(totalAmount - (invoiceDetails.adjustmentResponses?.[0]?.amount || 0))
      )
    );
  };

  const getDiscountAmount = (discounts, price) => {
    let discountAmount = 0;
    discounts.forEach((discount) => {
      if (!discount.discountStatus === ACTIVE_STATE) {
        discount += 0;
      } else if (discount.discountType === "PERCENTAGE") {
        discountAmount += (price * discount.value) / 100;
      } else if (discount.discountType === "FIXED_AMOUNT") {
        discountAmount += discount.value;
      }
    });

    return discountAmount;
  };

  const handleSelectMedicalService = (selectedItem, value) => {
    const updatedInvoiceItems = invoiceItems.map((item) => {
      if (item?.service.id === selectedItem?.service.id) {
        const discountAmount = getDiscountAmount(item.service.discounts, item.service.price);
        const taxRateAmount =
          ((item.service.price - discountAmount) * item.service.taxRateResponse.rate) / 100;
        return {
          ...item,
          quantity: value,
          discount: convertIntoTwoDecimal(discountAmount * value),
          tax: convertIntoTwoDecimal(taxRateAmount * value),
          amount: convertIntoTwoDecimal(
            (item.service.price - discountAmount + taxRateAmount) * value
          ),
        };
      } else {
        return item;
      }
    });

    dispatch(setMedicalServiceItems(updatedInvoiceItems));
    calculateTotalDetail(updatedInvoiceItems);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div>
        <p className="billing-heading">Select your services</p>
      </div>
      <div
        className="common-scrollbar"
        style={{ flexGrow: 1, maxHeight: "100%", overflowY: "auto", paddingBottom: "10px" }}
      >
        <div className="card-container">
          {invoiceItems.length > 0 ? (
            invoiceItems.map((item) => (
              <div key={item.service.id} className={`medical-service-card`}>
                <div className="medical-service-name">
                  <p>{item.service.name}</p>
                </div>
                <div className="">
                  <div className="quantity-control">
                    <CustomButton
                      iconButton={<i className="ri-subtract-fill"></i>}
                      disabled={item.quantity === 0}
                      onClick={() => {
                        if (item.quantity > 0) {
                          handleSelectMedicalService(item, item.quantity - 1);
                        }
                      }}
                      style={{
                        padding: "0",
                        color: item.quantity === 0 ? "#616161" : "#004c70",
                      }}
                    ></CustomButton>
                    <div className="quantity-display">
                      <span>{item.quantity}</span>
                    </div>

                    <CustomButton
                      iconButton={<i className="ri-add-fill"></i>}
                      onClick={() => {
                        handleSelectMedicalService(item, item.quantity + 1);
                      }}
                      style={{ padding: "0", color: "#004c70" }}
                    ></CustomButton>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No Medical Services Found</div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <CustomButton
          className={"btn--secondary"}
          onClick={() => {
            navigate("/home/ProductAndServices", {
              state: { sourcePage: "billing", sourceData: sourceData },
            });
          }}
          label={"Services"}
          startIcon={<i className="ri-add-fill"></i>}
        />
        <CustomButton
          onClick={() => {
            handleGenerateInvoice();
          }}
          className={"btn--primary"}
          label={invoiceDetails?.id ? "Save Invoice" : "Generate Invoice"}
          startIcon={<DoneAllOutlined />}
        />
      </div>
    </div>
  );
};

export const RenderInvoice = ({ invoiceDetails, totalDetail }) => {
  const dispatch = useDispatch();
  const isPaymentMode = useSelector(getIsPaymentMode);
  const [discountType, setDiscountType] = useState("amount");
  const [discountValue, setDiscountValue] = useState(
    invoiceDetails.adjustmentResponses?.[0]?.amount
  );
  const [adjustmentError, setAdjustmentError] = useState("");

  const validateAdjustment = (value, type) => {
    if (type === "amount") {
      if (value > invoiceDetails.totalAmount) {
        setAdjustmentError("Adjustment can't be more than Amount");
      } else {
        setAdjustmentError("");
        setDiscountValue(value);
        handleSaveAdjustment(value, type);
      }
    } else {
      if (value > 100) {
        setAdjustmentError("Adjustment percentage can't be more than 100");
      } else {
        setAdjustmentError("");
        setDiscountValue(value);
        handleSaveAdjustment(value, type);
      }
    }
  };

  const handleDiscountTypeChange = (newValue) => {
    setDiscountType(newValue);
    setDiscountValue("");
    handleSaveAdjustment("", newValue);
  };

  const handleSaveAdjustment = (discountValue, discountType) => {
    try {
      if (Boolean(adjustmentError)) {
        return;
      }
      let dis = 0;
      if (discountType === "amount") {
        dis = discountValue || 0;
      } else {
        dis = convertIntoTwoDecimal((totalDetail.amount * discountValue) / 100);
      }
      const payload = {
        date: dayjs().format(DATE_FORMAT),
        type: "DISCOUNT",
        amount: dis,
        reason: "Discount Adjustment",
        id: invoiceDetails.adjustmentResponses[0]?.id,
        status: "ACTIVE",
        invoiceId: invoiceDetails?.id,
      };

      dispatch(setPatientInvoiceAdjustments([payload]));
      dispatch(setPatientInvoiceTotal(totalDetail.amount - dis));
    } catch (error) {}
  };

  const handleDiscountValueChange = (e) => {
    validateAdjustment(e.target.value, discountType);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div>
        <Grid
          sx={{
            display: "flex",
            padding: "15px 0px",
            justifyContent: "space-between",
          }}
        >
          <Grid style={{ display: "flex" }}>
            {invoiceDetails.invoiceKey && (
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "#000000",
                  fontStyle: "italic",
                }}
              >
                Invoice No. : &nbsp;
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {invoiceDetails?.invoiceKey}
            </Typography>
          </Grid>

          <Grid style={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 400,
                color: "#000000",
                fontStyle: "italic",
              }}
            >
              Date : &nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {dayjs(invoiceDetails?.issueDate).format("MMMM D, YYYY")}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ borderWidth: "1px" }} />
      </div>
      <div
        className="common-scrollbar"
        style={{ flexGrow: 1, maxHeight: "100%", overflowY: "auto" }}
      >
        <Grid sx={{ padding: "15px 0px" }}>
          <CustomizedTable
            columns={columns}
            tableData={invoiceDetails?.invoiceItems.filter(
              (invoiceItem) => invoiceItem.quantity > 0 && invoiceItem.status === ACTIVE_STATE
            )}
            noPagination={true}
          />
        </Grid>
        <Divider sx={{ borderWidth: "1px" }} />
      </div>
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "end" }}>
        <div style={{ width: "22.28%" }}>
          <p style={{ paddingLeft: "16px", fontWeight: "500" }}>Total</p>
        </div>
        <div style={{ width: "17.22%" }}>
          <p style={{ width: "116px", textAlign: "center", fontWeight: "500" }}>
            {totalDetail.discount}
          </p>
        </div>

        <div style={{ width: "14.76%" }}>
          <p style={{ width: "100px", textAlign: "center", fontWeight: "500" }}>
            {totalDetail.tax}
          </p>
        </div>

        <div style={{ width: "16.21%" }}>
          <p style={{ width: "110px", textAlign: "center", fontWeight: "500" }}>
            {totalDetail.amount}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div>
          {!isPaymentMode &&
            invoiceDetails.invoiceItems.filter(
              (item) => item.quantity > 0 && item.status === ACTIVE_STATE
            ).length > 0 && (
              <div>
                <div>
                  <FormInputField
                    style={{
                      width: "50%",
                      height: "50%",
                      marginRight: "5px",
                    }}
                    label="Adjustment"
                    type={"tel"}
                    name="discountValue"
                    value={discountValue}
                    onChange={handleDiscountValueChange}
                    inputProps={{
                      maxLength: 5,
                    }}
                    error={Boolean(adjustmentError)}
                    errorText={adjustmentError}
                    startAdornment={<Discount />}
                    size={"small"}
                  />

                  <ToggleButtonGroup
                    value={discountType}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleDiscountTypeChange(newValue);
                      }
                    }}
                  >
                    <ToggleButton
                      sx={{
                        padding: "5px 10px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        height: "40px",
                        "&.Mui-selected": {
                          color: "#004c70",
                          backgroundColor: "#def1ee",
                        },
                      }}
                      value="amount"
                    >
                      <CurrencyRupeeOutlined fontSize="small" />
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: "5px 10px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",

                        "&.Mui-selected": {
                          color: "#004c70",
                          backgroundColor: "#def1ee",
                        },
                      }}
                      value="percentage"
                    >
                      <PercentOutlinedIcon fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            )}
        </div>
        <div>
          <Grid
            sx={{
              padding: "2px 0px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
                marginRight: "25px",
              }}
            >
              Adjustments:
            </Typography>
            <CurrencyRupeeOutlined fontSize="small" sx={{ color: "#000000" }} />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {invoiceDetails?.adjustmentResponses?.length > 0
                ? invoiceDetails?.adjustmentResponses?.[0]?.amount
                : 0}
            </Typography>
          </Grid>
          <Grid
            sx={{
              padding: "2px 0px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
                marginRight: "25px",
              }}
            >
              Payable:
            </Typography>
            <CurrencyRupeeOutlined fontSize="small" sx={{ color: "#000000" }} />

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {convertIntoTwoDecimal(invoiceDetails.totalAmount) || 0}
            </Typography>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export const RenderPayment = ({ invoiceDetails, patientDetails, handleClick }) => {
  const [paymentMapper, setpaymentMapper] = useState({
    "Debit/Credit Cards": false,
    Cash: false,
    UPI: false,
  });
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userOrgId = useSelector(getUserOrgId);
  const [openUpiPaymentModal, setOpenUpiPaymentModal] = useState(false);
  const closeUpiPaymentModal = () => {
    setOpenUpiPaymentModal(false);
  };

  const markAsPaid = async () => {
    try {
      await updateBillingInvoices(invoiceDetails?.id, {
        ...invoiceDetails,
        invoiceStatus: "PAID",
      });
      dispatch(
        setPatientInvoiceDetail({
          ...invoiceDetails,
          invoiceStatus: "PAID",
        })
      );
      setPaymentDone(true);
      handleClick("success", "Payment has been successfully received.");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userOrgId) {
          const amenitiesResponse = await getAmenitiesByOrgId(userOrgId);
          const acceptedPayments = amenitiesResponse.data.filter(
            (c) => c.category === "Payment Mode Accepted"
          );
          if (acceptedPayments.length > 0) {
            acceptedPayments.forEach((element) => {
              paymentMapper[element.name] = true;
            });
            setpaymentMapper(paymentMapper);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#004c70",
              }}
            >
              Payment
            </Typography>
          </div>

          <Divider sx={{ marginTop: "8px", borderWidth: "1px" }} />

          <div>
            <FormControl sx={{ marginTop: "13px" }}>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{
                  color: "#000000",
                  fontWeight: 500,
                  marginBottom: "10px",
                }}
              >
                Mode Of Payment
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={paymentMode}
                onChange={(e) => {
                  setPaymentMode(e.target.value);
                }}
              >
                {paymentMapper["Debit/Credit Cards"] === true && (
                  <>
                    <FormControlLabel
                      value="Card"
                      control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                      label={
                        <Grid display={"flex"}>
                          <CreditCardOutlined htmlColor="#000000" />
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 450,
                              color: "#45483C",
                              paddingTop: "2px",
                              marginLeft: "7px",
                            }}
                          >
                            Card
                          </Typography>
                        </Grid>
                      }
                    />
                  </>
                )}
                {paymentMapper["Cash"] === true && (
                  <>
                    <FormControlLabel
                      value="Cash"
                      control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                      label={
                        <Grid display={"flex"}>
                          <img
                            src="/images/cash_payment.svg"
                            alt=""
                            style={{
                              width: "25px",
                              height: "25px",
                              marginTop: "2px",
                              marginLeft: "1px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 450,
                              color: "#45483C",
                              paddingTop: "2px",
                              marginLeft: "6px",
                            }}
                          >
                            Cash
                          </Typography>
                        </Grid>
                      }
                    />
                  </>
                )}
                {paymentMapper["UPI"] === true && (
                  <>
                    <FormControlLabel
                      value="UPI"
                      control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                      label={
                        <Grid display={"flex"}>
                          <img
                            src="/images/upi_logo.svg"
                            alt=""
                            style={{
                              width: "25px",
                              height: "20px",
                              marginTop: "2px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 450,
                              color: "#45483C",
                              paddingTop: "2px",
                              marginLeft: "6px",
                            }}
                          >
                            UPI
                          </Typography>
                        </Grid>
                      }
                    />
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </div>

          {!paymentDone && (
            <CustomButton
              onClick={() => {
                if (paymentMode === "UPI") {
                  setOpenUpiPaymentModal(true);
                } else {
                  markAsPaid();
                }
              }}
              style={{ marginTop: "15px" }}
              disabled={paymentMode ? undefined : true}
              label={paymentMode === "UPI" ? "Proceed" : "Mark as Paid"}
              className={"btn--secondary"}
              startIcon={paymentMode === "UPI" ? <ArrowCircleRightOutlined /> : <DoneAllOutlined />}
            />
          )}

          {paymentDone && (
            <>
              <Divider sx={{ marginTop: "10px", borderWidth: "1px" }} />

              <div sx={{ paddingTop: "12px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  {" "}
                  Transaction
                </Typography>
              </div>

              <div
                sx={{
                  border: "0.5px dashed",
                  display: "flex",
                  borderRadius: "10px",
                  padding: "10px",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/transaction_logo.svg"
                  alt=""
                  style={{
                    width: "35px",
                    height: "35px",
                    marginTop: "2px",
                  }}
                />
                <Grid sx={{ marginLeft: "8px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#29bf91",
                      marginBottom: "3px",
                    }}
                  >
                    {invoiceDetails.totalAmount}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#194B5A",
                    }}
                  >
                    {" "}
                    {patientDetails?.user.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#616B67",
                    }}
                  >
                    {" "}
                    {paymentMode} Payment
                  </Typography>
                </Grid>

                <Grid sx={{ marginLeft: "auto" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#616B67",
                    }}
                  >
                    {" "}
                    21 Jan 2024 | 8:45 AM
                  </Typography>
                </Grid>
              </div>
            </>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {!(invoiceDetails?.invoiceStatus === "PAID") && (
              <CustomButton
                className={"btn--secondary"}
                label={"Edit Invoice"}
                startIcon={<i className="ri-edit-line ri-xl icon-primary-white" />}
                onClick={() => dispatch(setIsPaymentMode(false))}
              />
            )}
          </div>
          <CustomButton
            onClick={() => {
              navigate("/home/dashboard");
            }}
            className={"btn--primary"}
            label={"Save and Exit"}
            startIcon={<DoneAllOutlined />}
          />
        </div>
      </div>
      <ModalUI
        visible={openUpiPaymentModal}
        close={closeUpiPaymentModal}
        title={"UPI Payment"}
        component={
          <UpiPaymentModal
            handleClick={handleClick}
            close={closeUpiPaymentModal}
            total={
              invoiceDetails?.adjustmentAmount
                ? invoiceDetails?.totalAmount - invoiceDetails?.adjustmentAmount
                : invoiceDetails?.totalAmount
            }
            setPaymentDone={setPaymentDone}
          />
        }
      />
    </>
  );
};

const UpiPaymentModal = ({ close, total, handleClick, setPaymentDone }) => {
  const [upiId, setUpiId] = useState("");

  return (
    <Box sx={{ width: "650px", paddingTop: "15px" }}>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 430,
          color: "#45483C",
          paddingBottom: "10px",
        }}
      >
        {" "}
        Note: Please verify the billing details before processing
      </Typography>
      <Divider sx={{ marginTop: "8px", borderWidth: "1px" }} />
      <Grid
        sx={{
          padding: "5px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000",
            marginRight: "25px",
          }}
        >
          Total:
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {total}
        </Typography>
      </Grid>
      <Divider sx={{ borderWidth: "1px" }} />

      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: "#616B67",
          marginTop: "25px",
        }}
      >
        Please enter UPI Id:
      </Typography>

      <Grid
        sx={{
          paddingTop: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormInputField
          style={{ width: "60%" }}
          label="Enter UPI id"
          value={upiId}
          onChange={(e) => {
            setUpiId(e.target.value);
          }}
          startAdornment={<AlternateEmailOutlined />}
          endAdorment={
            <>
              <CustomButton
                label={"verify"}
                textAndIconColor={"#004c70"}
                customBackGroundColor={"transparent"}
              />
            </>
          }
          size={"small"}
        />
        <CustomButton
          label={"Request Payment"}
          className={"btn--secondary"}
          startIcon={<PaymentsOutlined />}
        />
      </Grid>

      <Grid sx={{ marginTop: "30px", display: "flex", justifyContent: "start" }}>
        <CustomButton
          label={"Mark As Paid"}
          className={"btn--primary"}
          startIcon={<DoneAllOutlined />}
          onClick={() => {
            setPaymentDone(true);
            close();
            handleClick("success", "Payment has been successfully received.");
          }}
        />
      </Grid>
    </Box>
  );
};

export const PatientAppointmentDetail = ({ handleClick, appointmentId }) => {
  const [appointmentDetails, setAppointmentDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const appointmentResponse = await getAppointmentById(appointmentId);
        setAppointmentDetails(appointmentResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div>
        <p className="billing-heading">Appointment Details</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Typography>
          {" "}
          Date:&nbsp; {dayjs(appointmentDetails?.appointmentDate).format(DATE_FORMAT_DMY)}
        </Typography>
        <Typography>
          {" "}
          Time:&nbsp; {convertTimeForTimeFormat(appointmentDetails?.appointmentTime)}
        </Typography>
        {appointmentDetails?.type === CLINIC_VISIT ? (
          <>
            {"In-Clinic"}

            <i
              className="ri-walk-fill ri-xl"
              style={{
                width: "18px",
                height: "18px",
                color: "#29bf91",
                marginRight: "5px",
              }}
            />
          </>
        ) : (
          <>
            {"Tele-Consultation"}
            <i
              className="ri-video-chat-line ri-xl"
              style={{
                width: "16px",
                height: "16px",
                color: "#004C70",
                marginRight: "5px",
              }}
            />
          </>
        )}
      </div>
      <Divider />
      <div style={{ marginTop: "10px" }}>
        <p className="billing-heading">Patient Details</p>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <PatientCard
          patientId={appointmentDetails?.patient?.id}
          patientData={appointmentDetails?.patient}
          viewData={{
            basicDetails: true,
            lifeStyle: true,
            chronicDiseases: true,
            lastVisited: true,
          }}
          handleClick={handleClick}
        ></PatientCard>{" "}
      </div>{" "}
      <Divider />
      <div style={{ marginTop: "10px" }}>
        <p className="billing-heading">Doctor Details</p>
      </div>
      <Grid>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#616161",
          }}
        >
          Dr.{appointmentDetails?.doctor?.userResponse.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#616161",
          }}
        >
          {appointmentDetails?.doctor?.specialization?.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#616161",
          }}
        >
          {appointmentDetails?.doctor?.userResponse.mobileNumber}
        </Typography>
      </Grid>
    </div>
  );
};

export const RenderPrescription = ({ appointmentId }) => {
  const [currentPrescription, setCurrentPrescription] = useState({
    symptomsArr: [],
    diagnosisArr: [],
    medicationsArr: [],
    labInvestigationsArr: [],
    advicesArr: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const prescriptionResponse = await getPrescriptionByAppointmentId(appointmentId);
        const prescriptionData = await convertPrescriptionDataToUIFormat(prescriptionResponse.data);
        setCurrentPrescription(prescriptionData.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <div style={{ marginTop: "25px" }}>
      <PrescriptionCard
        key="Cc"
        cardHeaderTitle="Cc"
        values={currentPrescription.symptomsArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Dx"
        cardHeaderTitle="Dx"
        values={currentPrescription.diagnosisArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Rx"
        cardHeaderTitle="Rx"
        cardContentField="Medicines"
        values={currentPrescription.medicationsArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Lab"
        cardHeaderTitle="Lab"
        values={currentPrescription.labInvestigationsArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>

      <PrescriptionCard
        key="Adv"
        cardHeaderTitle="Adv"
        values={currentPrescription.advicesArr}
        onDelete={() => {}}
        isEditable={false}
        hideActions
      ></PrescriptionCard>
    </div>
  );
};
