import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { notificationTypeCreation } from "../Common/ValidationSchema/notificationSchema";
import { Formik, Form } from "formik";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";

const ConfigureNotificationType = ({ submitNotificationType, notificationTypeEdit }) => {
  const [editNotificationType, setEditNotificationType] = useState(false);
  const [notificationTypeId, setNotificationTypeId] = useState();

  useEffect(() => {
    if (notificationTypeEdit) {
      setEditNotificationType(true);
      setNotificationTypeId(notificationTypeEdit.id);
    }
  }, [notificationTypeEdit]);

  const initialValues = notificationTypeEdit
    ? {
        notificationName: notificationTypeEdit.name,
        notificationType: notificationTypeEdit.type,
        notificationDescription: notificationTypeEdit.description,
        email: !!notificationTypeEdit.template.emailTemplateResponse,
        whatsapp: !!notificationTypeEdit.template.whatsappTemplateResponse,
        sms: !!notificationTypeEdit.template.smsTemplateResponse,
        pushNotification: !!notificationTypeEdit.template.pushNotificationTemplateResponse,
        emailContent: {
          subject: notificationTypeEdit.template.emailTemplateResponse?.subject || "",
          body: notificationTypeEdit.template.emailTemplateResponse?.body || "",
          footer: notificationTypeEdit.template.emailTemplateResponse?.footer || "",
        },
        smsContent: {
          msg91TemplateId: notificationTypeEdit.template.smsTemplateResponse?.msg91TemplateId || "",
          msg91ContentWithVariables:
            notificationTypeEdit.template.smsTemplateResponse?.msg91ContentWithVariables || "",
        },
        whatsappContent: {
          phoneNumber: notificationTypeEdit.template.whatsappTemplateResponse?.phoneNumber || "",
          message: notificationTypeEdit.template.whatsappTemplateResponse?.message || "",
        },
        pushNotificationContent: {
          heading: notificationTypeEdit.template.pushNotificationTemplateResponse?.heading || "",
          message: notificationTypeEdit.template.pushNotificationTemplateResponse?.message || "",
        },
      }
    : {
        notificationName: "",
        notificationType: "",
        notificationDescription: "",
        email: false,
        whatsapp: false,
        sms: false,
        pushNotification: false,
        emailContent: { subject: "", body: "", footer: "" },
        smsContent: { msg91TemplateId: "", msg91ContentWithVariables: "" },
        whatsappContent: { phoneNumber: "", message: "" },
        pushNotificationContent: { heading: "", message: "" },
      };

  const extractVariables = (content) => {
    const regex = /##(.*?)##/g;
    const matches = [];
    let match;
    while ((match = regex.exec(content)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { emailContent, smsContent, whatsappContent, pushNotificationContent } = values;
      const emailVariables = emailContent
        ? [
            ...extractVariables(emailContent.subject),
            ...extractVariables(emailContent.body),
            ...extractVariables(emailContent.footer),
          ]
        : [];
      const smsVariables = smsContent ? extractVariables(smsContent.msg91ContentWithVariables) : [];
      const whatsappVariables = whatsappContent ? extractVariables(whatsappContent.message) : [];
      const pushNotificationVariables = pushNotificationContent
        ? extractVariables(pushNotificationContent.message)
        : [];

      const allVariables = [
        ...new Set([
          ...emailVariables,
          ...smsVariables,
          ...whatsappVariables,
          ...pushNotificationVariables,
        ]),
      ];

      const msgData = {
        name: values.notificationName,
        type: values.notificationType,
        description: values.notificationDescription,
        template: {
          emailTemplateResponse: values.email ? values.emailContent : null,
          smsTemplateResponse: values.sms ? values.smsContent : null,
          whatsappTemplateResponse: values.whatsapp ? values.whatsappContent : null,
          pushNotificationTemplateResponse: values.pushNotification
            ? values.pushNotificationContent
            : null,
        },
        params: allVariables,
        status: "ACTIVE",
      };

      submitNotificationType(msgData, notificationTypeId, editNotificationType);
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={notificationTypeCreation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <Box mb={2}>
            <Paper elevation={3}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item sx={{ width: "50%" }}>
                    <FormInputField
                      style={{ width: "100%" }}
                      label="Notification name"
                      name="notificationName"
                      type="text"
                      required
                      shrink={true}
                      value={values.notificationName}
                      error={Boolean(errors.notificationName && touched.notificationName)}
                      errorText={errors.notificationName}
                      onChange={(event) => setFieldValue("notificationName", event.target.value)}
                      size="big"
                    />
                  </Grid>
                  <Grid item sx={{ width: "50%" }}>
                    <FormInputField
                      style={{ width: "100%" }}
                      label="Notification type"
                      name="notificationType"
                      type="text"
                      required
                      shrink={true}
                      value={values.notificationType}
                      error={Boolean(errors.notificationType && touched.notificationType)}
                      errorText={errors.notificationType}
                      onChange={(event) => setFieldValue("notificationType", event.target.value)}
                      size="big"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormInputField
                        label="Notification description"
                        name="notificationDescription"
                        type="text"
                        required
                        shrink={true}
                        value={values.notificationDescription}
                        error={Boolean(
                          errors.notificationDescription && touched.notificationDescription
                        )}
                        errorText={errors.notificationDescription}
                        onChange={(event) =>
                          setFieldValue("notificationDescription", event.target.value)
                        }
                        size="big"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" style={{ fontSize: "15px", marginTop: "8px" }}>
                        Notification Channels
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="email"
                              checked={values.email}
                              onChange={() => setFieldValue("email", !values.email)}
                            />
                          }
                          label="Email"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="whatsapp"
                              checked={values.whatsapp}
                              onChange={() => setFieldValue("whatsapp", !values.whatsapp)}
                            />
                          }
                          label="WhatsApp"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="sms"
                              checked={values.sms}
                              onChange={() => setFieldValue("sms", !values.sms)}
                            />
                          }
                          label="SMS"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="pushNotification"
                              checked={values.pushNotification}
                              onChange={() =>
                                setFieldValue("pushNotification", !values.pushNotification)
                              }
                            />
                          }
                          label="Push Notifications"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {values.email && (
                    <Grid item xs={12}>
                      <Card variant="outlined" sx={{ marginTop: 2 }}>
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            Compose Email
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <FormInputField
                                  label="Subject"
                                  name="emailContent.subject"
                                  type="text"
                                  required
                                  shrink={true}
                                  value={values.emailContent.subject}
                                  error={Boolean(
                                    errors.emailContent?.subject && touched.emailContent?.subject
                                  )}
                                  errorText={errors.emailContent?.subject}
                                  onChange={(event) =>
                                    setFieldValue("emailContent.subject", event.target.value)
                                  }
                                  size="big"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <FormInputField
                                  label="Email Notification Content"
                                  name="emailContent.body"
                                  type="text"
                                  required
                                  shrink={true}
                                  multiline
                                  rows={4}
                                  value={values.emailContent.body}
                                  error={Boolean(
                                    errors.emailContent?.body && touched.emailContent?.body
                                  )}
                                  errorText={errors.emailContent?.body}
                                  onChange={(event) =>
                                    setFieldValue("emailContent.body", event.target.value)
                                  }
                                  size="big"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <FormInputField
                                  label="Footer"
                                  name="emailContent.footer"
                                  type="text"
                                  required
                                  shrink={true}
                                  value={values.emailContent.footer}
                                  error={Boolean(
                                    errors.emailContent?.footer && touched.emailContent?.footer
                                  )}
                                  errorText={errors.emailContent?.footer}
                                  onChange={(event) =>
                                    setFieldValue("emailContent.footer", event.target.value)
                                  }
                                  size="big"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {values.whatsapp && (
                    <Grid item xs={12}>
                      <Card variant="outlined" sx={{ marginTop: 2 }}>
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            WhatsApp Notification
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <FormInputField
                                  label="To"
                                  name="whatsappContent.phoneNumber"
                                  type="text"
                                  required
                                  shrink={true}
                                  value={values.whatsappContent.phoneNumber}
                                  error={Boolean(
                                    errors.whatsappContent?.phoneNumber &&
                                      touched.whatsappContent?.phoneNumber
                                  )}
                                  errorText={errors.whatsappContent?.phoneNumber}
                                  onChange={(event) =>
                                    setFieldValue("whatsappContent.phoneNumber", event.target.value)
                                  }
                                  size="big"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <FormInputField
                                  label="WhatsApp Notification Message"
                                  name="whatsappContent.message"
                                  type="text"
                                  required
                                  shrink={true}
                                  multiline
                                  rows={4}
                                  value={values.whatsappContent.message}
                                  error={Boolean(
                                    errors.whatsappContent?.message &&
                                      touched.whatsappContent?.message
                                  )}
                                  errorText={errors.whatsappContent?.message}
                                  onChange={(event) =>
                                    setFieldValue("whatsappContent.message", event.target.value)
                                  }
                                  size="big"
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {values.sms && (
                    <Grid item xs={12}>
                      <Card variant="outlined" sx={{ marginTop: 2 }}>
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            SMS Notification
                          </Typography>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <FormInputField
                                label="Template ID"
                                name="smsContent.msg91TemplateId"
                                type="text"
                                required
                                shrink={true}
                                value={values.smsContent.msg91TemplateId}
                                error={Boolean(
                                  errors.smsContent?.msg91TemplateId &&
                                    touched.smsContent?.msg91TemplateId
                                )}
                                errorText={errors.smsContent?.msg91TemplateId}
                                onChange={(event) =>
                                  setFieldValue("smsContent.msg91TemplateId", event.target.value)
                                }
                                size="big"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl margin="normal" fullWidth>
                              <FormInputField
                                label="SMS Notification Content"
                                name="smsContent.msg91ContentWithVariables"
                                type="text"
                                required
                                shrink={true}
                                multiline
                                rows={4}
                                value={values.smsContent.msg91ContentWithVariables}
                                error={Boolean(
                                  errors.smsContent?.msg91ContentWithVariables &&
                                    touched.smsContent?.msg91ContentWithVariables
                                )}
                                errorText={errors.smsContent?.msg91ContentWithVariables}
                                onChange={(event) =>
                                  setFieldValue(
                                    "smsContent.msg91ContentWithVariables",
                                    event.target.value
                                  )
                                }
                                size="big"
                              />
                            </FormControl>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {values.pushNotification && (
                    <Grid item xs={12}>
                      <Card variant="outlined" sx={{ marginTop: 2 }}>
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            Push Notification
                          </Typography>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <FormInputField
                                label="Heading"
                                name="pushNotificationContent.heading"
                                type="text"
                                required
                                shrink={true}
                                value={values.pushNotificationContent.heading}
                                error={Boolean(
                                  errors.pushNotificationContent?.heading &&
                                    touched.pushNotificationContent?.heading
                                )}
                                errorText={errors.pushNotificationContent?.heading}
                                onChange={(event) =>
                                  setFieldValue(
                                    "pushNotificationContent.heading",
                                    event.target.value
                                  )
                                }
                                size="big"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl margin="normal" fullWidth>
                              <FormInputField
                                label="Push Notification Message"
                                name="pushNotificationContent.message"
                                type="text"
                                required
                                shrink={true}
                                multiline
                                rows={4}
                                value={values.pushNotificationContent.message}
                                error={Boolean(
                                  errors.pushNotificationContent?.message &&
                                    touched.pushNotificationContent?.message
                                )}
                                errorText={errors.pushNotificationContent?.message}
                                onChange={(event) =>
                                  setFieldValue(
                                    "pushNotificationContent.message",
                                    event.target.value
                                  )
                                }
                                size="big"
                              />
                            </FormControl>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#004c70" }}
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ConfigureNotificationType;
