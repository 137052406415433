import {
  SET_IS_SYNCING,
  SET_SHOW_SYNC_MODAL,
  SET_SYNC_STATUS,
  SET_LAST_SYNC_INFO,
} from "store/Actions/CloudSyncActions";

const initialState = {
  showSyncModal: false,
  isSyncing: false,
  syncStatus: "",
  lastSyncInfo: {
    push: "loading",
    pull: "loading",
  },
};

const cloudSyncReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_IS_SYNCING: {
      return { ...state, isSyncing: action.payload };
    }
    case SET_SHOW_SYNC_MODAL: {
      return { ...state, showSyncModal: action.payload };
    }
    case SET_SYNC_STATUS: {
      return { ...state, syncStatus: action.payload };
    }
    case SET_LAST_SYNC_INFO: {
      return { ...state, lastSyncInfo: action.payload };
    }
    default:
      return state;
  }
};

export default cloudSyncReducer;

export const getIsSyncing = (state) => state.cloudSync.isSyncing;

export const getIsSyncModalOpen = (state) => state.cloudSync.showSyncModal;

export const getSyncStatus = (state) => state.cloudSync.syncStatus;

export const getLastSyncInfo = (state) => state.cloudSync.lastSyncInfo;
