import { Divider } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { deleteDiscount, getAllDiscount, getServiceConfiguration } from "services/BillingService";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import ModalUI from "ui-component/ModalUI";
import Reveal from "views/utilities/Reveal";
import ManageDiscount from "./ManageDiscount";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";
import { DATE_FORMAT_DMY, ORGANIZATION, orgId } from "store/constant";
import dayjs from "dayjs";

const Discounts = () => {
  const { handleClick } = useContext(ToastContext);

  const [discounts, setDiscounts] = useState([]);
  const [selected, setSelected] = useState(null);
  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "description", label: "Description" },
    { field: "amount", label: `Amount` },
    { field: "serviceName", label: "Service" },
    { field: "validFromFormatted", label: "Valid From" },
    { field: "validToFormatted", label: "Valid Till" },
    { field: "discountStatus", label: "Discount Status" },
    { field: "Actions", label: "Actions" },
  ];
  const [openModal, setOpenModal] = useState(false);

  const fetchDiscounts = async () => {
    try {
      const serviceResponse = await getServiceConfiguration(orgId(), null, ORGANIZATION);
      const discountResponse = await getAllDiscount();
      let discountTableData = [];
      discountResponse.data.forEach((el) => {
        const obj = {
          ...el,
          serviceName: serviceResponse?.data.find((e) => e.id === el.applicableServiceId)?.name,
          amount: `${el.value} ${el.discountType === "PERCENTAGE" ? "%" : "Rs."}`,
          validFromFormatted: dayjs(el?.validFrom).format(DATE_FORMAT_DMY),
          validToFormatted: dayjs(el?.validTo).format(DATE_FORMAT_DMY),
        };
        discountTableData.push(obj);
      });
      setDiscounts(discountTableData?.filter((e) => e?.serviceName));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const handleModalClose = () => {
    setOpenModal(false);
    setSelected(null);
  };

  const handleEditDiscount = (discount) => {
    setSelected(discount);
    setOpenModal(true);
  };

  const handleDeleteDiscount = async (discount) => {
    try {
      await deleteDiscount(discount?.id);
      handleClick("success", "Discount deleted successfully");
      fetchDiscounts();
    } catch (e) {
      handleClick("error", "Can not delete Discount");
    }
  };

  return (
    <Reveal>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>Discounts</h2>
      </div>
      <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
      <CustomButton
        className="btn--primary"
        style={{ display: "flex", marginLeft: "auto", marginBottom: "20px" }}
        label={" + Add Discount"}
        onClick={() => setOpenModal(true)}
      />

      <CustomizedTable
        columns={columns}
        tableData={discounts}
        actions={[
          {
            label: "Edit",
            icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
            onClick: handleEditDiscount,
          },
          {
            label: "Delete",
            icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
            onClick: handleDeleteDiscount,
          },
        ]}
      />

      <ModalUI
        visible={openModal}
        close={handleModalClose}
        title={"Add Discount"}
        component={
          <ManageDiscount
            selected={selected}
            setSelected={setSelected}
            setOpenModal={setOpenModal}
            fetchDiscounts={fetchDiscounts}
          />
        }
      />
    </Reveal>
  );
};

export default Discounts;
